import {COLOR_MAGENTA} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "harmonica",
  naam: "wat-is-ondersteuning-op-school",
  titel: "Wat is ondersteuning op school?",
  kleur: COLOR_MAGENTA,
  tekst: "",
  links: [
    'rollen-ondersteuning-op-school',
    'bekostiging-ondersteuning-op-school'
  ]
}
