import React from 'react';
import { ReactComponent as TheSvg} from "./kennis.svg";


const Kennis = () => (
	<div style={{padding: "50px 0"}} className="vis basis">
		<TheSvg />
	</div>
);

export default Kennis;
