import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenGGD = () => (

<div className="vis-geldstromen-ggd svg-container">
<svg
	xmlns="http://www.w3.org/2000/svg"
	preserveAspectRatio="xMidYMin meet"
	viewBox="120 30 520 800">
<g className="geldstromen">
	<path data-tooltip-content="react-tooltip" id="tt4" d="M582,435V418.5c0-10-3.6-25.53-8.21-35.34l-29.72-63.41C538.79,308.49,528,300,519,300H507l6,6-6,6h12c3.34,0,10.53,5,14.21,12.84l29.72,63.41c3.83,8.18,7.07,22,7.07,30.25V435h0l6,6,6-6Z"/>
	<polygon data-tooltip-content="react-tooltip" id="tt1" points="336 273 336 168 330 174 324 168 324 168 324 168 324 273 324 273 324 273 330 279 336 273 336 273"/>
	<path data-tooltip-content="react-tooltip" id="tt2" d="M486,273V256.5c0-10.09-4-25.56-9.15-35.21l-34.11-64.21C437,146.2,425.9,138,417,138H405l6,6-6,6h12c3.42,0,11,4.95,15.15,12.71l34.11,64.21c4.19,7.9,7.74,21.45,7.74,29.58V273h0l6,6,6-6Z"/>
	<polygon data-tooltip-content="react-tooltip" id="tt3" points="456 435 456 327 450 333 444 327 444 327 444 327 444 435 444 435 444 435 450 441 456 435 456 435"/>
</g>

<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-bevolkingsonderzoek', hash: '#Rijk' }} >
	<g className="kader link">
		<rect x="294" y="123" width="108" height="42" rx="9"/>
		<text transform="translate(336 150)">Rijk</text>
	</g>
</Link>

<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-bevolkingsonderzoek', hash: '#Screeningsorganisaties' }} >
	<g className="kader link">
		<rect x="396" y="282" width="108" height="42" rx="9"/>
		<text transform="translate(410 300.63)">Screenings-<tspan x="-4" y="15">organisaties</tspan></text>
	</g>
</Link>

<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-bevolkingsonderzoek', hash: '#RIVM' }} >
	<g className="kader link">
		<rect x="276" y="282" width="108" height="42" rx="9"/>
		<text transform="translate(315 309)">RIVM</text>
	</g>
</Link>

	<g className="kader">
		<rect x="396" y="444" width="108" height="42" rx="9"/>
		<text transform="translate(410 462.75)">Huisartsen-<tspan x="3.85" y="15">praktijken</tspan></text>
	</g>
	<g className="kader">
		<rect x="516" y="444" width="108" height="42" rx="9"/>
		<text transform="translate(530 470)">Laboratoria</text>
	</g>


<g className="tekst links regular">
	<text transform="translate(294 515.56)">
		Rijksbijdrage
		<tspan x="0" y="15">Betaling voor geleverde zorg of diensten</tspan>
		<tspan x="0" y="30">Betaling voor gemaakte uitstrijkjes</tspan>
		<tspan x="0" y="45">Betaling voor beoordeeld testmateriaal</tspan>
	</text>
</g>

<g className="tekstlabels wit">
	<path d="M323.5,201.37a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.17,6.17,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.55,6.55,0,0,1,3.44,0,6.65,6.65,0,0,1,2.88,1.67,6.94,6.94,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.56,6.56,0,0,1-2.34,2.34,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23A6.65,6.65,0,0,1,325.4,206a6.43,6.43,0,0,1-1-1.32,6.78,6.78,0,0,1-.66-1.56A6.11,6.11,0,0,1,323.5,201.37Zm4.47,1.74v1.28h4.29v-1.28H331v-4.86h-1.29l-1.8.64v1.31l1.54-.46v3.37Z" fill="#fff"/>
	<path d="M452.67,201.37a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6.21,6.21,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.43,6.43,0,0,1,1.32,1,6.53,6.53,0,0,1,1.67,6.32,6.37,6.37,0,0,1-.66,1.56,6.8,6.8,0,0,1-1,1.32,7.27,7.27,0,0,1-1.32,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.11,6.11,0,0,1,452.67,201.37Zm6.44-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.57-.45c.56,0,.85.21.85.64a1.3,1.3,0,0,1-.17.59,4.1,4.1,0,0,1-.47.71,11.14,11.14,0,0,1-.79.87l-1.09,1.1v1h4.34v-1.28h-1.47c-.3,0-.61,0-.94,0v0c.37-.34.69-.65,1-.92a7.43,7.43,0,0,0,.68-.79,3,3,0,0,0,.41-.74,2.19,2.19,0,0,0,.13-.77,1.58,1.58,0,0,0-.63-1.31,1.85,1.85,0,0,0-.69-.33A3.37,3.37,0,0,0,459.11,198.16Z" fill="#fff"/>
	<path d="M443.5,379.48a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.78,6.78,0,0,1,1.56-.66A6.49,6.49,0,0,1,450,373a6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6.21,6.21,0,0,1,1.32,1,6.29,6.29,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.58,6.58,0,0,1,0,3.45,6.51,6.51,0,0,1-.65,1.56,6.85,6.85,0,0,1-1,1.32,6.51,6.51,0,0,1-1.32,1,6.08,6.08,0,0,1-1.55.66A6.57,6.57,0,0,1,450,386a6.49,6.49,0,0,1-1.72-.23,6.42,6.42,0,0,1-3.89-3,7,7,0,0,1-.66-1.56A6.15,6.15,0,0,1,443.5,379.48Zm8.73-1.56a1.39,1.39,0,0,0-.59-1.2,2.81,2.81,0,0,0-1.71-.45,4.93,4.93,0,0,0-1.58.26v1.28a4.58,4.58,0,0,1,.66-.19,3.33,3.33,0,0,1,.7-.07,1.42,1.42,0,0,1,.67.14.47.47,0,0,1,.26.44.49.49,0,0,1-.09.3.59.59,0,0,1-.22.19,1.61,1.61,0,0,1-.33.12l-.39,0H449V380h.79a2.17,2.17,0,0,1,.38,0,.94.94,0,0,1,.31.1.67.67,0,0,1,.21.2.71.71,0,0,1,.07.31.58.58,0,0,1-.28.52,1.43,1.43,0,0,1-.83.19,3.28,3.28,0,0,1-.78-.08,3.4,3.4,0,0,1-.63-.19v1.3a4.2,4.2,0,0,0,1.49.26,3.16,3.16,0,0,0,2-.52,1.68,1.68,0,0,0,.67-1.42,1.31,1.31,0,0,0-.28-.88,1.39,1.39,0,0,0-.71-.47v0a1.41,1.41,0,0,0,.65-.57A1.67,1.67,0,0,0,452.23,377.92Z" fill="#fff"/>
	<path d="M559,379.69a6.16,6.16,0,0,1,.24-1.72,6.75,6.75,0,0,1,.65-1.56,6.85,6.85,0,0,1,1-1.32,6.64,6.64,0,0,1,1.32-1,6.44,6.44,0,0,1,1.55-.65,6.23,6.23,0,0,1,1.73-.23,6.55,6.55,0,0,1,1.72.23,6.65,6.65,0,0,1,2.88,1.67,7.27,7.27,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.62,6.62,0,0,1,0,3.46,6.44,6.44,0,0,1-.65,1.55,6.94,6.94,0,0,1-1,1.32A6.44,6.44,0,0,1,567.2,386a6.36,6.36,0,0,1-3.46,0,6.46,6.46,0,0,1-1.55-.66,5.93,5.93,0,0,1-1.32-1,6.56,6.56,0,0,1-1-1.32,6.86,6.86,0,0,1-.65-1.55A6.23,6.23,0,0,1,559,379.69Zm7.11-3h-1.55L563,380.55v1h2.57v1.18h1.48v-1.18h.73v-1.25h-.73v-.91h-1.48v.91h-.23c-.31,0-.59,0-.82,0v0a11.17,11.17,0,0,0,.59-1.27Z" fill="#fff"/>
</g>

<g className="tekstlabels zwart">
	<path d="M276.44,512.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,3.93,3.93,0,0,1,1.14.16,4.55,4.55,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.7,4.7,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.5,4.5,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.14,4.14,0,0,1-.88.68,4.26,4.26,0,0,1-1,.44,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,276.44,512.17Zm3,1.16v.85h2.86v-.85h-.86v-3.24h-.86l-1.2.42v.87l1-.3v2.25Z"/>
	<path d="M276.44,527.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,3.93,3.93,0,0,1,1.14.16,4.55,4.55,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.7,4.7,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.5,4.5,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.14,4.14,0,0,1-.88.68,4.26,4.26,0,0,1-1,.44,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,276.44,527.17Zm4.29-2.14a2.64,2.64,0,0,0-1.22.29v.87a2.49,2.49,0,0,1,1.05-.3c.37,0,.56.15.56.43a.77.77,0,0,1-.11.39,2.51,2.51,0,0,1-.31.47c-.14.18-.32.37-.53.59l-.73.73v.68h2.9v-.85h-1l-.63,0v0l.64-.61a4.9,4.9,0,0,0,.45-.53,2.26,2.26,0,0,0,.28-.49,1.5,1.5,0,0,0,.09-.52,1.07,1.07,0,0,0-.12-.51,1,1,0,0,0-.3-.36,1.36,1.36,0,0,0-.47-.22A2,2,0,0,0,280.73,525Z"/>
	<path d="M276.44,542.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,3.93,3.93,0,0,1,1.14.16,4.55,4.55,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.7,4.7,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.5,4.5,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.14,4.14,0,0,1-.88.68,4.26,4.26,0,0,1-1,.44,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,276.44,542.17Zm5.82-1a.94.94,0,0,0-.39-.8,1.89,1.89,0,0,0-1.14-.3,3.31,3.31,0,0,0-1.06.17v.86a2.23,2.23,0,0,1,.44-.13,2.5,2.5,0,0,1,.47,0,1,1,0,0,1,.45.09.33.33,0,0,1,.17.3.34.34,0,0,1-.06.2.39.39,0,0,1-.15.13.63.63,0,0,1-.22.07,1.09,1.09,0,0,1-.26,0h-.39v.81h.52a1.1,1.1,0,0,1,.26,0,.43.43,0,0,1,.2.07.35.35,0,0,1,.14.13.41.41,0,0,1,.05.21.38.38,0,0,1-.19.34.91.91,0,0,1-.55.13,2.78,2.78,0,0,1-.52,0,2.35,2.35,0,0,1-.42-.13v.86a2.64,2.64,0,0,0,1,.18,2.09,2.09,0,0,0,1.31-.35,1.14,1.14,0,0,0,.44-.94.9.9,0,0,0-.18-.59.89.89,0,0,0-.47-.31v0a1,1,0,0,0,.43-.38A1.12,1.12,0,0,0,282.26,541.13Z"/>
	<path d="M276.44,557.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,3.93,3.93,0,0,1,1.14.16,4.55,4.55,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.7,4.7,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.5,4.5,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.14,4.14,0,0,1-.88.68,4.26,4.26,0,0,1-1,.44,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,276.44,557.17Zm4.74-2h-1l-1,2.59v.65h1.71v.79h1v-.79h.49v-.83h-.49V557h-1v.61h-.15a4.44,4.44,0,0,0-.55,0v0q.14-.24.39-.84Z"/>
</g>
</svg>

		<ReactTooltip anchorId="tt1" place="right" float>
			<span>1. Rijksbijdrage</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Betaling voor geleverde zorg of diensten</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt3" place="right" float>
			<span>3. Betaling voor gemaakte uitstrijkjes</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt4" place="right" float>
			<span>4. Betaling voor beoordeeld testmateriaal</span>
		</ReactTooltip>


	</div>
);

export default GeldstromenGGD;


