import React from 'react';

import SocialeWijkteams from './visualizations/sociale-wijkteams';
import AantalJw from './visualizations/aantal-jw';
import AantalParticipatiewet from "./visualizations/aantal-participatiewet";
import AantalBijstandsuitkering from "./visualizations/aantal-bijstandsuitkering";
import GemeentenUitgaven from "./visualizations/gemeenten-uitgaven";
import GemeentenInkomsten from "./visualizations/gemeenten-inkomsten";
import GemeentenBegroting from "./visualizations/gemeenten-begroting";
import GeldstromenWmo from "./visualizations/geldstromen-wmo";
import Hoofdrolspelers from "./visualizations/hoofdrolspelers";
import Beleidsterreinen from "./visualizations/beleidsterreinen";
import EffectenBetaaldWerk from "./visualizations/effecten-betaald-werk";
import MantelzorgerVrijwilligerAantal from "./visualizations/mantelzorger-vrijwilliger-aantal";
import BeleidStappen from "./visualizations/beleid-stappen";
import AantalHuishoudens from "./visualizations/aantal-huishoudens";
import GeldstromenJeugdwet from "./visualizations/geldstromen-jeugdwet";
import GeldstromenParticipatiewet from "./visualizations/geldstromen-participatiewet";

const vissen = {
  sociale_wijkteams: <SocialeWijkteams/>,
  aantal_jeugdwet: <AantalJw/>,
  aantal_participatiewet: <AantalParticipatiewet/>,
  aantal_bijstandsuitkering: <AantalBijstandsuitkering/>,
  gemeenten_uitgaven: <GemeentenUitgaven/>,
  gemeenten_inkomsten: <GemeentenInkomsten/>,
  gemeenten_begroting: <GemeentenBegroting/>,
  geldstromen_wmo: <GeldstromenWmo/>,
  hoofdrolspelers: <Hoofdrolspelers/>,
  beleidsterreinen: <Beleidsterreinen/>,
  effecten_betaald_werk: <EffectenBetaaldWerk/>,
  mantelzorger_vrijwilliger_aantal: <MantelzorgerVrijwilligerAantal/>,
  beleid_stappen: <BeleidStappen/>,
  aantal_huishoudens: <AantalHuishoudens/>,
  geldstromen_jeugdwet: <GeldstromenJeugdwet/>,
  geldstromen_participatiewet: <GeldstromenParticipatiewet/>,
};

export default vissen;
