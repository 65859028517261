import React from "react";
import LineChart from "../../../../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const GebruikGgz = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);
	const formatValueTooltip = value => formatY(value) + " mensen";

	const data = [
		{
			name: "honderdjarigen",
			values: [
				{ x: 2016, y: 1275 },
				{ x: 2017, y: 1335 },
				{ x: 2018, y: 1370 },
				{ x: 2019, y: 1420 },
				{ x: 2020, y: 1390 },
			]
		}
	];

	return (
		<div className="vis-gebruik-ggz">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[1100, 1500]}
				data={data}
				height={600}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal duizend volwassenen in Nederland dat gebruikmaakt van geestelijke gezondheidszorg in de periode 2016-2020.</p>
				<p>Bron: NZa Kerncijfers ggz, 2022.</p>
			</div>
		</div>
	);
};

export default GebruikGgz;
