function onlyUnique (value, index, self) {
    return self.indexOf(value) === index;
}

const access = (state = [], action) => {
  switch (action.type) {
    case 'ADDACCESS':
    	const newState = state.concat(action.specials)
      return newState.filter( onlyUnique );
    default:
       return state
  }
}

export default access
