import React from 'react';
import Donut from '../../../components/Donut/Donut';


const DienstverbandWerknemers = () => {
	const data = [
			{
	        "label": "Vast contract",
	        "value": 60,
	    },
			{
	        "label": "Flexcontract*",
	        "value": 40,
	    }
	];

	const formatLabel = item => item.label + ': ' + item.percentage + '%';

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0} dy={-15}>421 duizend</tspan>
      <tspan x={0} dy={15}>verplegenden, verzorgenden en</tspan>
       <tspan x={0} dy={15}>thuiszorgmedewerkers</tspan>
    </text>);

	return (
		<div className="vis-dienstverband-werknemers">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				unit="procent"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Aantal zorgprofessionals in het tweede kwartaal van 2019 naar type dienstverband.</p>
				<p>* inclusief andersoortige dienstverbanden, zoals detachering.</p>
				<p>Bron: AZWinfo 2021</p>
			</div>

		</div>
	);
}

export default DienstverbandWerknemers;


