// http://bl.ocks.org/nadinesk/99393098950665c471e035ac517c2224
// http://bl.ocks.org/dbuezas/9306799

import React, { Component } from 'react';
import { select, arc, pie, interpolateObject, pointer, min } from 'd3';
import textSize from 'svg-text-size'

import './Donut.scss';

class Donut extends Component {

	state = {
		width: 0,
		height: 0,
		tooltip: { display: 'none', x: 0, y: 0, maxWidth: 400}
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	componentWillUnmount() {
			window.removeEventListener('resize', this.updateDimensions);
	}

	componentDidUpdate(prevProps, prevState){
		if (this.state.width !== prevState.width || this.state.height !== prevState.height) this.updateChart();
		if (this.props.data) {
			if (this.props.data !== prevProps.data) {
				this.updateDimensions();
				this.updateChart();
			}
		}
	}

	updateDimensions = () => {
		if (!this.container) return;
		const width = min([this.container.clientWidth, this.props.maxWidth]);
		let height = this.props.height || this.container.clientHeight;
		height = min([height,width]);
		if (this.state.width !== width || this.state.height !== height) this.setState({ width, height });
	}

	parseData = data => {
		let total = 0;
		data.forEach(item => total += item.value);
		data.forEach(item => {
			item.total = total
			 item.percentage = item.value/total * 100;
		});
		return data;
	}

	updateChart = () => {
		const props = this.props;
		const { unit } = props;
		const { height, width } = this.state;
		const colors = props.colors ? props.colors : ["#666", "#999", "#ccc"];
		const formatLabel = props.formatLabel ? props.formatLabel : item => item.label;
		const formatTooltip = props.formatTooltip ? props.formatTooltip : item => item.label + ': ' + item.value + ' ' + unit;
		const fontSize = props.fontSize ? props.fontSize : "12px";
		const fontFamily = props.fontFamily ? props.fontFamily : 'sans-serif';
		const textOffset = 6;
		const transitionDuration = props.animation ? 1000 : 0;

		const radius = height > width ? width/2.2 : height/2.2;;

		const container = select(this.container);
		const svg = container.select('svg');

		const data = this.parseData(props.data);

		const pieChart = pie()
				.sort(null)
				.value(d => d.value);

		const innerArcGenerator = arc()
			.outerRadius(radius * 0.8)
			.innerRadius(radius * 0.4);


		const innerLabelArcGenerator = arc()
			.innerRadius(radius * 0.8)
			.outerRadius(radius * 0.95);

		const outerLabelArcGenerator = arc()
			.innerRadius(radius)
			.outerRadius(radius * 0.85);


	/* ------- slices -------*/

		const slice = svg.select(".slices").selectAll("path.slice")
			.data(pieChart(data));

		slice.enter()
			.insert("path")
			.attr("class", (d,i) => "slice slice" + (i+1))
			.attr('stroke', '#fff')
			.attr('stroke-width', '2')
			.attr("fill", (d,i) => colors[i] ? colors[i] : "#efefef")
			.merge(slice)
			.transition().duration(transitionDuration)
			.attrTween("d", d => {
				this.current = this.current || d;
				const interpolate = interpolateObject(this.current, d);
				this.current = interpolate(0);
				return t => innerArcGenerator(interpolate(t));
			});

			svg.select(".slices").selectAll("path.slice")
			.on('mousemove', (event, d) => {
					const pos = pointer(event, this.container);
					this.setState( {
						tooltip: {
							display: 'block',
							x: pos[0] +'px',
							y: pos[1] +'px',
							text: formatTooltip(d.data)
						}
					})
				})
			.on('mouseleave', () => {
					this.setState( {
						tooltip: {
							display: 'none',
							x: 0,
							y: 0,
							text: '',
						}
					})
			});

		slice.exit()
			.remove();

		/* ------- labels -------*/

		const midAngle = d => d.startAngle + (d.endAngle - d.startAngle)/2;

		const text = svg.select(".labels").selectAll("text")
			.data(pieChart(data));

		text.enter()
			.append("text")
			.attr("dy", "-.35em")
			.attr("fontFamily", fontFamily)
			.style('font-size', fontSize)
			.merge(text)
			.text(d => formatLabel(d.data))
			.transition().duration(transitionDuration)
			.attrTween("transform", d => {
				this.current = this.current || d;
				const interpolate = interpolateObject(this.current, d);
				this.current = interpolate(0);
				return t => {
					const d2 = interpolate(t);
					const pos = outerLabelArcGenerator.centroid(d2);
					pos[0] += midAngle(d2) < Math.PI ? textOffset : -textOffset;
					return "translate("+ pos +")";
				};
			})
			.styleTween("text-anchor", function(d){
				this.current = this.current || d;
				const interpolate = interpolateObject(this._current, d);
				this.current = interpolate(0);
				return function(t) {
					const d2 = interpolate(t);
					return midAngle(d2) < Math.PI ? "start":"end";
				};
			});

		text.exit()
			.remove();

		/* ------- pointers -------*/

		const path = svg.select(".labels").selectAll("path")
			.data(pieChart(data));

		path.enter()
			.append("path")
			.attr('class', 'pointer')
			.attr('stroke', '#333')
			.attr('stroke-width', '2')
			.attr('fill', 'none')
			.merge(path)
			.transition().duration(transitionDuration)
			.attrTween("d", d => {
				this.current = this.current || d;
				var interpolate = interpolateObject(this.current, d);
				this.current = interpolate(0);
				return t => {
					const d2 = interpolate(t);
					const pos = outerLabelArcGenerator.centroid(d2);
					const label = formatLabel(d.data);
					const dimensions = textSize(label, { 'font-size': fontSize, 'font-family': fontFamily });
					pos[0] += (midAngle(d2) < Math.PI ? dimensions.width+textOffset : -(dimensions.width+textOffset));
					const point1 = innerLabelArcGenerator.centroid(d2);
					const point2 = outerLabelArcGenerator.centroid(d2);
					const point3 = pos;
					const curveOffset = midAngle(d2) < Math.PI ? 9 : -9;
					return `
						M ${point1[0]} ${point1[1]}
						Q ${point2[0]} ${point2[1]} ${point2[0]+curveOffset} ${point3[1]}
						L ${point3[0]} ${point3[1]}
					`
				};
			});

		path.exit()
			.remove();

		/* ------- bullets -------*/

		const bullet = svg.select(".labels").selectAll("circle")
			.data(pieChart(data));

		bullet.enter()
			.append("circle")
			.attr("class", "bullet")
			.attr("r", 2)
			.attr("fill", "#333")
			.merge(bullet)
			.transition().duration(transitionDuration)
			.attrTween("cx", d => {
				this.current = this.current || d;
				var interpolate = interpolateObject(this.current, d);
				this.current = interpolate(0);
				return t => {
					var d2 = interpolate(t);
					return [innerLabelArcGenerator.centroid(d2)[0]];
				};
			})
			.attrTween("cy", d => {
				this.current = this.current || d;
				var interpolate = interpolateObject(this.current, d);
				this.current = interpolate(0);
				return t => {
					var d2 = interpolate(t);
					return [innerLabelArcGenerator.centroid(d2)[1]];
				};
			});
		bullet.exit()
			.remove();

		/* ------- label groups -------*/

		const labelGroups = svg.select(".labels");
		labelGroups
			.style('opacity', 0)
		.transition().duration(transitionDuration)
			.style('opacity', 1)

	}

	render() {
		const props = this.props;
		const { width, height } = this.state;
		const fontFamily = props.fontFamily ? props.fontFamily : 'sans-serif';
		const unit = props.unit ? props.unit : '';
		const formatLabel = props.formatLabel ? props.formatLabel : item => item.label;
		const data = this.parseData(props.data);
		const total = data[0].total;
		const colors = props.colors ? props.colors : ["#666", "#999", "#ccc"];

		const formatTotal = props.formatTotal
			? props.formatTotal
			: (value) => (
				<text textAnchor="middle" fontFamily={fontFamily}>
					<tspan x={0}>Totaal</tspan>
					<tspan x={0} dy={15}>{value}</tspan>
					<tspan x={0} dy={15}>{unit}</tspan>
				</text>);

		const { display, x, y, text } = this.state.tooltip;

		const tooltipStyle = props.tooltipStyle
			? props.tooltipStyle
			: {
				display: display,
				left: x,
				top: y,
				maxWidth: props.tooltipMaxWidth,
			};

		const rows = data.map((row,i) => (
			<tr key={"legendRow"+i} className={"legend-row"+(i+1)}>
				<td style={{backgroundColor: colors[i]}}></td>
				<td>{formatLabel(row)}</td>
			</tr>
			))

		return (
			<div className="Donut" ref={container => this.container = container} >
				<svg width={width} height={height} >
					<defs>
						<pattern id="hatch" patternUnits="userSpaceOnUse"
							width="10" height="10" x="0" y="0"
						>
							<path
								className="background"
								d="M -10 -10 L 0 10 L 10 10 L 10 -10"
								stroke="none"
							/>
							<path
								className="line"
								d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11"
								stroke="#ccc"
								strokeWidth="3"
							/>
						</pattern>
					</defs>
					<g transform={`translate(${width/2},${height/2})`}>
						<g className="slices" />
						<g className="labels" />
						<g className="total">{formatTotal(total)}</g>
					</g>
				</svg>
				<table className="legend">
					<tbody>
						{rows}
					</tbody>
				</table>
				<div className="tooltip" style={tooltipStyle}><span>{text}</span></div>
			</div>
		);
	}
}

export default Donut;
