import React from 'react';

const PubliekeGezondheidszorg = () => (
<svg viewBox="0 0 100 100" className="af-icon">
	<g id="naarSVG" fill="none" strokeMiterlimit="10" strokeWidth="2.0">
		<circle cx="62.11" cy="22.97" r="6.04" />
		<line x1="56.07" y1="53.17" x2="56.07" y2="41.09" />
		<line x1="68.15" y1="41.09" x2="68.15" y2="53.17" />
		<circle cx="37.89" cy="22.97" r="6.04" />
		<polyline points="43.93 53.17 25.81 53.17 25.81 32.03 49.97 32.03 49.97 45.88" />
		<line x1="31.85" y1="53.17" x2="31.85" y2="41.09" />
		<line x1="43.93" y1="41.09" x2="43.93" y2="53.17" />
		<circle cx="50" cy="51.92" r="6.04" />
		<rect x="37.92" y="60.98" width="24.16" height="21.14" />
		<line x1="43.96" y1="82.12" x2="43.96" y2="70.04" />
		<line x1="56.04" y1="70.04" x2="56.04" y2="82.12" />
		<polyline points="50.03 45.91 50.03 32.03 74.19 32.03 74.19 53.17 56.07 53.17" />
	</g>
</svg>
);

export default PubliekeGezondheidszorg;