import React from 'react';
import BarChart from '../../../../../components/BarChart/BarChart';

const Funds = () => {
  const formatValueTooltip = (val) => val + ' EUR billion';
  const formatValueY = (val) => val;

  const data = [
    { key: 'Medical care', value:  38.1 },
    { key: 'Long-term care', value:  22.2 },
    { key: 'Medications and medical aids', value: 9.4 },
    { key: 'Miscellaneous, including administration and policymaking', value: 3.8 },
    { key: 'Rehabilitation care', value: 3.4 },
    { key: 'Preventive care', value: 2.6 },
    { key: 'Support services', value:  1.6 },
  ];

  return (
    <div className="vis-funds">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 380 }}
        barHeight={30}
        domain={[0,38.1]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <div className="vis-total">
        <p>Total</p>
        <p>81 EUR</p>
        <p>billion</p>
      </div>

      <div className="vis-credit">
        <p>Healthcare expenditure in billion of euros in 2019, based on the internationally accepted definition.</p>
        <p>Source: CBS Statline, 2021. Zorguitgaven.</p>
      </div>

    </div>
  );
}

export default Funds;
