import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../components/BarChart/BarChart';

const KostenZorgtrede = () => {

	const data = [
		{
			key: "Trede 1",
			description: "Gebruik van eerstelijnszorg",
			value: 365000000
		},
		{
			key: "Trede 2",
			description: "Bezoek aan een medisch specialist en/of ziekenhuisopname",
			value: 6700000000
		},
		{
			key: "Trede 3",
			description: "Hulpmiddelen of voorzieningen",
			value: 616000000
		},
		{
			key: "Trede 4",
			description: "Tijdelijke persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis",
			value: 2200000000
		},
		{
			key: "Trede 5",
			description: "Structurele persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis",
			value: 7100000000
		},
		{
			key: "Trede 6",
			description: "Opname in een verzorgings- of verpleeghuis",
			value: 11000000000
		}
	];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val => localeFormatter.format(',.1f')(val/1000000000) + ' miljard';


	return (
		<div className="vis-kosten-zorgtrede">

      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 170 }}
        barHeight={30}
        domain={[0,11000000000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueTooltip}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-120}
        animation={true}
        tooltipPosition="mouse"
      />

			<div className="vis-credit">
				<p>Kosten in euro's per zorgtrede in 2016.</p>
				<p>Bron: Nederlandse Zorgautoriteit, Zorg voor ouderen 2018.</p>
			</div>

		</div>
	);
}

export default KostenZorgtrede;


