import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "intro",
  naam:"mini-huiselijk-geweld",
  titel:"Zó werkt de zorg-mini: Huiselijk Geweld",
  kleur: COLOR_TURQUOISE,
  tekst:
    "In 2021 kwamen er ruim 119 duizend meldingen van huiselijk geweld en/of kindermishandeling binnen bij Veilig Thuis-organisaties in Nederland. Wat is huiselijk geweld en wie spelen een rol in de aanpak ervan? In deze mini beantwoorden we enkele belangrijke vragen.",
  links:
    [
      'wat-is-huiselijk-geweld',
      'hoeveel-meldingen',
      'spelers-aanpak',
      'colofon-huiselijk-geweld'
    ]
}
