import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import {formatLocale} from "d3";

const AantalParticipatiewet = () => {
	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatValueTooltip = (val) => `${localeFormatter.format(',.0f')(val)} mensen`;
	const formatValueY = (val) => `${localeFormatter.format(',.0f')(val)}`;

	const data = [
		{ key: 'Coaching naar werk of participatie', value:  70220 },
		{ key: 'Training, cursus of opleiding', value:  25360 },
		{ key: 'Loonkostensubsidie', value: 22750 },
		{ key: 'Sociale activering', value: 21690 },
		{ key: 'Vrijwilligerswerk', value: 21000 },
		{ key: 'Begeleiding op de werkplek', value: 12770 },
		{ key: 'Beschut werk', value: 4100 },
		{ key: 'Werkplekaanpassing', value: 40 },
	];

	return (
		<div className="vis-aantal-participatiewet">
			<BarChart
				marginBeforeValue={-60}
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 275 }}
				barHeight={30}
				domain={[0,70220]}
				color={ {fill: '#333', stroke: '#fff'} }
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			<div className="vis-credit">
				<p>Aantal mensen dat gebruikmaakt van re-integratievoorzieningen uit de Participatiewet, december 2020. Mensen kunnen van meerdere voorzeiningen tegelijkertijd gebruikmaken.</p>
				<p>Bron: CBS Statline 2021</p>
			</div>

		</div>
	);
}

export default AantalParticipatiewet;
