import React from 'react';
import Donut from '../../../../../../components/Donut/Donut';
import {formatLocale} from 'd3';

const Rijksuitgaven = () => {
  const data = [
    {
      "label": "Onderwijs, cultuur en wetenschap",
      "value": 12.3,
    },
    {
      "label": "Sociale zekerheid",
      "value": 25.7,
    },
    {
      "label": "Gemeenten en provincies",
      "value": 11.3,
    },
    {
      "label": "Justitie en veiligheid",
      "value": 4.4,
    },
    {
      "label": "Overig",
      "value": 19.5,
    },
    {
      "label": "Zorg",
      "value": 26.8,
    },
  ];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatLabel = item => {
    const shortLabel = item.label;
    return `${shortLabel} ${localeFormatter.format(',.1f')(item.value)}%`;
  }

  const formatTotal = () => (
    <text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0}>Totaal</tspan>
      <tspan x={0} dy={15}>395 miljard</tspan>
      <tspan x={0} dy={18}>euro</tspan>
    </text>);

  return (
    <div className="vis-rijksuitgaven">
      <Donut
        data={data}
        height={400}
        fontFamily="din-regular"
        fontSize="15px"
        formatLabel={formatLabel}
        formatTotal={formatTotal}
        unit="%"
        animation={true}
      />

      <div className="vis-credit">
        <p>Uitgaven aan zorg en andere sectoren als percentage van <br/> de totale uitgaven van het Rijk in 2023.</p>
        <p>Bron: Rijksoverheid, 2022.</p>
      </div>
    </div>
  );
}

export default Rijksuitgaven;
