import React from 'react';
import { Link } from 'react-router-dom';

const Hoofdrolspelers = () => (
	<div className="vis-hoofdrolspelers svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 660 600"
			preserveAspectRatio="xMidYMin meet">

			<g className="geldstroom">
				<path d="M348,567c-9.9,0-26.1,0-36,0H81c-9.9,0-18-8.1-18-18v-60c0-9.9,3.09-25.49,6.87-34.64l142.27-344.72 C215.91,100.49,227.1,93,237,93h75c9.9,0,26.1,0,36,0h75c9.9,0,21.09,7.49,24.87,16.64l142.27,344.72 c3.78,9.15,6.87,24.74,6.87,34.64v60c0,9.9-8.1,18-18,18H348z"/>
			</g>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Burgers' }} >
				<g className="kader">
					<path d="M270,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
					<text className="tekst" x="330" y="100">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Zorginkopers' }} >
				<g className="kader">
					<path d="M15,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H15z"/>
					<text className="tekst" x="75" y="525">Zorginkopers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Zorgaanbieders' }} >
				<g className="kader">
					<path d="M525,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H525z"/>
					<text className="tekst" x="585" y="525">Zorgaanbieders</text>
				</g>
			</Link>

		</svg>
	</div>
);

export default Hoofdrolspelers;
