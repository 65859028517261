import React from 'react';
import { Highlight } from 'react-highlighter-ts';

import './IntroHarmonica.scss';

const IntroHarmonica = (props) => {

  const {text, searchstring} = props;

  return (
    	<div className='intro-harmonica'>
    		<Highlight search={searchstring}>{text}</Highlight>
  		</div>
    )
}

export default IntroHarmonica;
