export default {"tekst": "Wat doet de huisarts?","items": [{
    "tekst": "Voorkomen",
    "items": [{
        "tekst": "De huisarts adviseert patiënten over gezond leven en het voorkomen van ziekten."
    }, {
        "tekst": "De huisarts voert landelijke preventieprogramma’s mede uit, zoals griepvaccinatie en bevolkingsonderzoeken."
    }]
}, {
    "tekst": "Beoordelen",
    "items": [{
        "tekst": "De huisarts beoordeelt de urgentie van klachten van patiënten (triage)."
    }, {
        "tekst": "De huisarts beoordeelt zorgvragen van patiënten."
    }, {
        "tekst": "De huisarts stelt diagnoses op basis van het verhaal van de patiënt en onderzoek."
    }, {
        "tekst": "De huisarts beoordeelt bij overlijden van patiënten de doodsoorzaak."
    }]
}, {
    "tekst": "Behandelen",
    "items": [{
        "tekst": "De huisarts biedt een basiszorgaanbod dat voor alle huisartsen gelijk is."
    }, {
        "tekst": "De huisarts betrekt bij de zorgvraag de omstandigheden van patiënten (zoals gezin, afkomst en werk)."
    }, {
        "tekst": "De huisarts kan een aanvullend of bijzonder aanbod bieden, zoals 24-uurs-bloeddrukmeting of echografie."
    }, {
        "tekst": "De huisarts biedt palliatieve zorg en levensbeëindiging (euthanasie)."
    }]
}, {
    "tekst": "Samenwerken",
    "items": [{
        "tekst": "De huisarts is vertrouwenspersoon van haar patiënten."
    }, {
        "tekst": "De huisarts coördineert de zorg die andere professionals verlenen in de huisartsenpraktijk."
    }, {
        "tekst": "De huisarts is de verbindende factor in de samenwerking met andere zorg- en hulpverleners in de zorgketen."
    }, {
        "tekst": "De huisarts verzamelt alle medische informatie over de patiënt in diens dossier."
    }]
}]}