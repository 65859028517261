import React from 'react';
import {Link} from "react-router-dom";

const Gedachte = () => (
	<div className="vis-gedachte svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 660 440">

			<g className="teksten">
				<text transform="translate(22.04 41.48)">De overheid
					<tspan x="0" y="16.05">schept voorwaarden </tspan>
					<tspan x="0" y="32.1">voor een goede </tspan>
					<tspan x="0" y="48.15">volksgezondheid</tspan>
					<tspan x="0" y="64.15">en een zorgsysteem … </tspan>
				</text>
				<text transform="translate(158.46 73.45)">… waarin burgers <tspan x="0" y="16.05">allereerst zichzelf </tspan><tspan x="0" y="32.1">helpen …</tspan></text>
				<text transform="translate(290.88 73.45)">… zo mogelijk <tspan x="0" y="16.05">met hulp uit hun </tspan><tspan x="0" y="32.1">omgeving … </tspan></text>
				<text transform="translate(411.25 89.5)">… zo nodig met <tspan x="0" y="16.05">professionele hulp …</tspan></text>
				<text transform="translate(571.76 89.5)">… zo nodig <tspan x="0" y="16.05">langdurig.</tspan></text>
			</g>

			<Link to={{ pathname: '/stelselwetten', hash: '#Wetpubliekegezondheid' }} >
				<path className="kader" d="M18,366.07H138.4a0,0,0,0,1,0,0v48.15a0,0,0,0,1,0,0H10a0,0,0,0,1,0,0V374.1A8,8,0,0,1,18,366.07Z" strokeWidth="4.01"/>
				<text transform="translate(58.37 398.17)">Wpg</text>
			</Link>

			<Link to={{ pathname: '/stelselwetten', hash: '#Zorgverzekeringswet' }} >
				<path className="kader" d="M393.2,269.77H513.57a8,8,0,0,1,8,8v40.13a0,0,0,0,1,0,0H393.2a0,0,0,0,1,0,0V269.77A0,0,0,0,1,393.2,269.77Z" strokeWidth="4.01"/>
				<text transform="translate(443.23 301.87)">Zvw</text>
			</Link>

			<Link to={{ pathname: '/stelselwetten', hash: '#Wetmaatschappelijkeondersteuning' }} >
				<rect className="kader" x="393.2" y="317.92" width="128.4" height="48.15" fill="none" strokeWidth="4.01"/>
				<text transform="translate(439.06 350.02)">Wmo</text>
			</Link>

			<Link to={{ pathname: '/stelselwetten', hash: '#Jeugdwet' }} >
				<rect className="kader" x="393.2" y="366.07" width="128.4" height="48.15" strokeWidth="4.01"/>
				<text transform="translate(422.57 398.17)">Jeugdwet</text>
			</Link>

			<Link to={{ pathname: '/stelselwetten', hash: '#Wetlangdurigezorg' }} >
				<path className="kader" d="M521.6,366.07H642a8,8,0,0,1,8,8v40.13a0,0,0,0,1,0,0H521.6a0,0,0,0,1,0,0V366.07A0,0,0,0,1,521.6,366.07Z" strokeWidth="4.01"/>
				<text transform="translate(572.55 398.17)">Wlz</text>
			</Link>

			<g className="kaders">
				<path className="kader" d="M264.8,173.47H385.17a8,8,0,0,1,8,8V414.22a0,0,0,0,1,0,0H264.8a0,0,0,0,1,0,0V173.47A0,0,0,0,1,264.8,173.47Z" fill="#fff" strokeWidth="4.01"/>
				<path className="kader" d="M144.42,125.67H256.77a8,8,0,0,1,8,8V414.57a0,0,0,0,1,0,0H136.39a0,0,0,0,1,0,0V133.69a8,8,0,0,1,8-8Z" fill="#fff" strokeWidth="4.01"/>
				<text transform="translate(172.93 201.9)">Burger</text>
				<text transform="translate(297.91 254.07)">Netwerk</text>
			</g>

		</svg>
	</div>
);

export default Gedachte;


