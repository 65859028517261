import React from 'react';

const Mini = () => (
<svg viewBox="0 0 100 100" className="af-icon">
	<g id="naarSVG" stroke="none" fill="000">
		<circle cx="75" cy="70" r="5"/>
		<circle cx="50" cy="70" r="5"/>
		<circle cx="25" cy="70" r="5"/>
	</g>
</svg>
);

export default Mini;