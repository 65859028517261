import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN, COLOR_GREEN, COLOR_MAGENTA, COLOR_PURPLE, COLOR_YELLOW} from "../../vocabulary";

const pages = [
  {
    categorie: "basis",
    template: "specialvoorpagina",
    naam: "ggz",
    titel: "Zó werkt de geestelijke gezondheidszorg",
    kleur: COLOR_PURPLE,
    tekst:
      "Wat is geestelijke gezondheidszorg? Wie krijgt welke zorg, van welke zorgaanbieder en wie betaalt deze zorg? Al deze informatie en meer vind je hieronder.",
    links:
      [
        'wat-is-ggz',
        'wie-is-wie',
        'stelselwetten',
        'wetten-ggz',
        'uitgaven',
        'geldstromen',
        '/gidsen/gids-inkoopproces-ggz'
      ]
  },
  {
    template: "intro",
    naam: "wat-is-ggz",
    titel: "Wat is ggz en wie maken er gebruik van?",
    kleur: COLOR_GREEN,
    tekst:
      "Geestelijke gezondheidszorg is zorg voor mensen met psychische problemen. Deze problemen en zorg bestaan in vele soorten en maten. Met welke psychische problemen helpt ggz zoal, wat zijn de belangrijkste vormen van ggz, en hoeveel mensen maken hier gebruik van?",
    links:
      [
        'afbakening',
        'veelvoorkomende-problemen',
        'tevredenheid',
        'zorgsoorten',
        'hoofdvormen-ggz',
        'jeugd-curatief',
        'aantal-curatief-langdurig',
        'forensisch-vormen',
        'forensisch-aantal',
        'locaties',
        'verzuim'
      ]
  },
  {
    template: "harmonica",
    naam: "afbakening",
    titel: "Wat verstaan we in dit boek onder ggz?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "veelvoorkomende-problemen",
    titel: "Wat zijn veel voorkomende problemen waarbij de ggz helpt?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "tevredenheid",
    titel: "Hoe tevreden zijn mensen met psychische problemen over het leven dat ze leiden?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "zorgsoorten",
    titel: "Welke zorg krijgen mensen met psychische problemen?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "hoofdvormen-ggz",
    titel: "Welke hoofdvormen van ggz onderscheiden we?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "jeugd-curatief",
    titel: "Welke vormen van jeugd en curatieve ggz zijn er?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "aantal-curatief-langdurig",
    titel: "Hoeveel mensen maken gebruik van curatieve of langdurige ggz?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "forensisch-vormen",
    titel: "Welke vormen van forensische ggz zijn er?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "forensisch-aantal",
    titel: "Hoeveel mensen maken gebruik van de forensische ggz?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "locaties",
    titel: "Waar krijgen mensen met psychische problemen zorg?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "verzuim",
    titel: "Hoeveel werkdagen verzuimen mensen bij welke klachten?",
    kleur: COLOR_GREEN,
  },
  {
    template: "intro",
    naam: "wie-is-wie",
    titel: "Wie zijn de belangrijkste spelers in de ggz en wat doen zij?",
    kleur: "1",
    tekst:
      "Welke organisaties en beroepsgroepen zijn zoal actief in de ggz en wat is hun rol? Wie verleent ggz, wie houdt toezicht op de ggz en welke organisaties vertegenwoordigen zorgverleners en patiënten in de ggz?",
    links:
      [
        'opleidingen',
        'vertegenwoordiging-clienten',
        'organisaties-kennisontwikkeling',
        'organisaties-advies',
        'organisaties-toezicht',
        'vertegenwoordiging-beroepsgroepen',
        'vertegenwoordiging-aanbieders',
        'zorgverleners',
        'werkenden-aantal',
        'tien-beroepen',
      ]
  },
  {
    template: "harmonica",
    naam: "opleidingen",
    titel: "Wat zijn de meest relevante opleidingen voor de ggz en hoelang duren die?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "vertegenwoordiging-clienten",
    titel: "Welke organisaties vertegenwoordigen zoal cliënten en naasten in de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "organisaties-kennisontwikkeling",
    titel: "Welke organisaties houden zich bezig met kennisontwikkeling in de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "organisaties-advies",
    titel: "Welke organisaties adviseren over de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "organisaties-toezicht",
    titel: "Welke instanties houden toezicht op de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "vertegenwoordiging-beroepsgroepen",
    titel: "Welke organisaties vertegenwoordigen beroepsgroepen in de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "vertegenwoordiging-aanbieders",
    titel: "Welk organisaties vertegenwoordigen aanbieders van ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "zorgverleners",
    titel: "Welke zorgverleners werken er zoal in de ggz?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "visualisatie",
    naam: "werkenden-aantal",
    titel: "Wat is de ontwikkeling van het aantal werkenden in de ggz?",
    kleur: COLOR_MAGENTA,
    links: ['tien-beroepen']
  },
  {
    template: "visualisatie",
    naam: "tien-beroepen",
    titel: "Hoeveel mensen werken er in tien beroepen in de ggz?",
    kleur: COLOR_MAGENTA,
    links: ['zorgverleners']
  },
  {
    template: "intro",
    naam: "stelselwetten",
    titel: "Wat zijn de stelselwetten in de ggz en hoe werken zij?",
    kleur: "2",
    tekst:
      "De zorg voor mensen met psychische problemen wordt geregeld door vijf stelselwetten: de Wet maatschappelijke ondersteuning, Jeugdwet, Zorgverzekeringswet, de Wet langdurige zorg en de Wet forensische zorg. Wat regelen deze wetten voor de ggz?",
    links:
      [
        'stelselwetten-wmo',
        'stelselwetten-jeugdwet',
        'stelselwetten-zvw',
        'stelselwetten-wlz',
        'stelselwetten-wfz'
      ]
  },
  {
    template: 'harmonica',
    naam: 'stelselwetten-wmo',
    titel: 'Hoe werkt de Wet maatschappelijke ondersteuning (Wmo) en wat is relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'stelselwetten-jeugdwet',
    titel: 'Hoe werkt de Jeugdwet en wat is relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'stelselwetten-zvw',
    titel: 'Hoe werkt de Zorgverzekeringswet (Zvw) en wat is relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'stelselwetten-wlz',
    titel: 'Hoe werkt de Wet langdurige zorg (Wlz) en wat is relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'stelselwetten-wfz',
    titel: 'Hoe werkt de Wet forensische zorg (Wfz) en wat is relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'intro',
    naam: 'wetten-ggz',
    titel: 'Wat zijn de relevante (zorg)wetten in de ggz en wat regelen zij?',
    kleur: COLOR_YELLOW,
    tekst:
      'Naast de vijf stelselwetten spelen andere (zorg)wetten een rol in de ggz, zoals het Wetboek van Strafrecht en de Penitentiaire beginselenwet. Wat regelen deze en andere relevante wetten voor de ggz?',
    links:
      [
        'zorgwetten-ggz',
        'vrijheidsbeperking-onderbreking',
        'tbs-verlof'
      ]
  },
  {
    template: 'harmonica',
    naam: 'zorgwetten-ggz',
    titel: 'Welke (zorg)wetten zijn relevant voor de ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'vrijheidsbeperking-onderbreking',
    titel: 'Hoe werkt de onderbreking van vrijheidsbeperkende maatregelen bij opgelegde ggz?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'harmonica',
    naam: 'tbs-verlof',
    titel: 'Wat zijn de verlofstappen bij een TBS-maatregel?',
    kleur: COLOR_YELLOW,
  },
  {
    template: 'intro',
    naam: "uitgaven",
    titel: "Hoeveel geld geven we uit aan ggz?",
    kleur: COLOR_CYAAN,
    tekst:
      "In 2019 gaven we ruim 7 miljard euro uit aan ggz. Aan welke psychische problemen geven we het meeste geld uit? En wanneer betalen mensen met psychische problemen zelf (bij) voor ggz?",
    links:
      [
        'uitgaven-cbs',
        'begroting-zorg',
        'uitgaven-ggz',
        'uitgaven-curatief-langdurig',
        'wanneer-betalen',
        'uitgaven-problemen'
      ]
  },
  {
    template: 'visualisatie',
    naam: 'uitgaven-cbs',
    titel: 'Waaraan geven we onze zorgeuro\'s uit volgens het CBS?',
    kleur: COLOR_CYAAN,
    links: ['uitgaven-ggz']
  },
  {
    template: 'visualisatie',
    naam: 'begroting-zorg',
    titel: 'Hoeveel geld heeft het Rijk begroot voor zorg?',
    kleur: COLOR_CYAAN,
    links: ['uitgaven-cbs']
  },
  {
    template: 'visualisatie',
    naam: 'uitgaven-ggz',
    titel: 'Hoeveel geld geven we uit aan ggz?',
    kleur: COLOR_CYAAN,
    links: ['uitgaven-curatief-langdurig']
  },
  {
    template: 'visualisatie',
    naam: 'uitgaven-curatief-langdurig',
    titel: 'Hoeveel geld geven we uit aan curatieve en langdurige ggz?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'harmonica',
    naam: 'wanneer-betalen',
    titel: 'Wanneer betalen mensen voor de ggz?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'visualisatie',
    naam: 'uitgaven-problemen',
    titel: 'Aan welke psychische problemen geven we het meeste geld uit?',
    kleur: COLOR_CYAAN,
  },
  {
    template: "intro",
    naam: "geldstromen",
    titel: "Hoe lopen de geldstromen in de ggz?",
    kleur: COLOR_CYAAN,
    tekst:
      "Zorgverleners krijgen geld voor het verlenen van zorg aan mensen met psychische problemen. Welke weg doorloopt het geld voordat het bij de zorgverlener terechtkomt?",
    links:
      [
        'geldstromen-wmo',
        'wie-betaalt-wmo',
        'geldstromen-jeugdwet',
        'wie-betaalt-jw',
        'geldstromen-zvw',
        'wie-betaalt-zvw',
        'geldstromen-wlz',
        'wie-betaalt-wlz',
        'geldstromen-wfz',
        'wie-betaalt-wfz'
      ]
  },
  {
    template: 'visualisatie',
    naam: 'geldstromen-wmo',
    titel: 'Hoe lopen de geldstromen in de Wet maatschappelijke ondersteuning?',
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wmo']
  },
  {
    template: 'harmonica',
    naam: 'wie-betaalt-wmo',
    titel: 'Wie betaalt wat in de Wet maatschappelijke ondersteuning?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'visualisatie',
    naam: 'geldstromen-jeugdwet',
    titel: 'Hoe lopen de geldstromen in de Jeugdwet?',
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-jw']
  },
  {
    template: 'harmonica',
    naam: 'wie-betaalt-jw',
    titel: 'Wie betaalt wat in de Jeugdwet?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'visualisatie',
    naam: 'geldstromen-zvw',
    titel: 'Hoe lopen de geldstromen in de Zorgverzekeringswet?',
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-zvw']
  },
  {
    template: 'harmonica',
    naam: 'wie-betaalt-zvw',
    titel: 'Wie betaalt wat in de Zorgverzekeringswet?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'visualisatie',
    naam: 'geldstromen-wlz',
    titel: 'Hoe lopen de geldstromen in de Wet langdurige zorg?',
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wlz']
  },
  {
    template: 'harmonica',
    naam: 'wie-betaalt-wlz',
    titel: 'Wie betaalt wat in de Wet langdurige zorg?',
    kleur: COLOR_CYAAN,
  },
  {
    template: 'visualisatie',
    naam: 'geldstromen-wfz',
    titel: 'Hoe lopen de geldstromen in de Wet forensische zorg?',
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wfz']
  },
  {
    template: 'harmonica',
    naam: 'wie-betaalt-wfz',
    titel: 'Wie betaalt wat in de Wet forensische zorg?',
    kleur: COLOR_CYAAN,
  },
]

pages.forEach(page => {
  if (!page.hasOwnProperty('categorie')) {
    page.categorie = "ggz"
  }
})

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
