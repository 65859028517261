import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "kosten",
    titel: "Wat kost de zorg?",
    kleur: COLOR_CYAAN,
    tekst: "Het antwoord op de vraag wat de zorg in Nederland kost is op verschillende manieren te beantwoorden. Vast staat dat de uitgaven elk jaar stijgen, ook voor ons als individu.",
    links:
      [
        "zorgeuros",
        "zorguitgaven",
        "rijksbegroting",
        "geneesmiddelen-uitgaven",
        "volwassene-zorguitgaven",
        "eigen-betaling",
      ]
  },
  {
    template: "visualisatie",
    naam: "zorgeuros",
    titel: "Waaraan geven we onze zorgeuro’s uit volgens het CBS?",
    kleur: COLOR_CYAAN,
    links: ['zorguitgaven']
  },
  {
    template: "visualisatie",
    naam: "zorguitgaven",
    titel: "Hoe ontwikkelen de zorguitgaven zich?",
    kleur: COLOR_CYAAN,
    links: ['rijksbegroting']
  },
  {
    template: "visualisatie",
    naam: "rijksbegroting",
    titel: "Hoeveel heeft het Rijk begroot voor zorg?",
    kleur: COLOR_CYAAN,
    links: ['volwassene-zorguitgaven']
  },
  {
    template: "visualisatie",
    naam: "geneesmiddelen-uitgaven",
    titel: "Hoeveel geld geven we uit aan geneesmiddelenzorg?",
    kleur: COLOR_CYAAN,
    links: ['zorgeuros']
  },
  {
    template: "visualisatie",
    naam: "volwassene-zorguitgaven",
    titel: "Hoeveel betaalt een volwassene aan zorg?",
    kleur: COLOR_CYAAN,
    links: ['eigen-betaling']
  },
  {
    template: "harmonica",
    naam: "eigen-betaling",
    titel: "Wanneer betalen mensen voor zorg en ondersteuning?",
    kleur: COLOR_CYAAN,
    links: ['bijzondere-bekostiging']
  }
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
