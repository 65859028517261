import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';

const Verblijftijd = () => {
  const margin = {top: 90, right: 0, bottom: 90, left: 0};
  const formatY = (value, d) => `${d['Aantal'].value}%`;
  const formatValueToolTip = d => (<span>{d.column}<br/>{d.data.value}%</span>);

  const data = [
      {
          "year": "< 3 maanden",
          "Aantal": 30,
          "Overige": 70,
      },
      {
          "year": "3-6 maanden",
          "Aantal": 26,
          "Overige": 74,
      },
      {
          "year": "6-12 maanden",
          "Aantal": 30,
          "Overige": 70,
      },
      {
          "year": "> 12 maanden",
          "Aantal": 13,
          "Overige": 87,
      }
  ];

  return (
    <div className="vis-verblijftijd">
    <ColumnChart
      margin={margin}
      colorBars={["#333","#ddd"]}
      colorAxis="#333"
      labelY="x 100"
      height={450}
      domain={[0, 100]}
      columnPadding={0.5}
      formatY={formatY}
      formatValueToolTip={formatValueToolTip}
      data={data}
      stacked={true}
      disableTooltip
    />

    <div className="vis-credit">
      <p>Verblijftijd in percentage van jongeren in JeugdzorgPlus per drie maanden in 2020</p>
      <p>Plaatsings- en uitstroomgegevens JeugdzorgPlus 2020, Jeugdzorg Nederland</p>
    </div>

  </div>
  );
}
export default Verblijftijd;
