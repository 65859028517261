import React from 'react';
import { Link } from 'react-router-dom';

import './SiteMap.scss';

const SiteMap = (props) => {

	let thisPages = props.pages;

	const createLinks = (page, level) => {
		if (!page.links) return null;
		let thisLevel = level || 0;
		const tree = page.links.map((link,i) => {
			const thisPage = thisPages.filter(page => (page.naam === link))[0];
			if (!thisPage) return null;

			if (thisPage.template === 'specialvoorpagina') thisLevel = 0;

			thisPages = thisPages.filter(page => !(page.naam === thisPage.naam && page.categorie === thisPage.categorie));

			const subs = thisPage && thisPage.links ? <ul>{createLinks(thisPage, thisLevel+1)}</ul> : null;
			const className = (thisPage.categorie === 'basis' && thisPage.template !== 'specialvoorpagina') ? 'basis-level-' : 'special-level-';
			const thisLink = thisPage.template !== 'specialindex' ? (
				<Link to={'/' + thisPage.categorie + '/' + thisPage.naam} >
						{thisPage.titel}
				</Link>) : null;

			return (
				<li
					key={thisPage.naam + i}
					className={className + thisLevel}
				>
					{thisLink}
					{subs}
				</li>
			)
		})
		return <ul>{tree}</ul>
	}

  return (
    <div className='sitemapkolom'>
      <div className='kop'>{props.page.titel}</div>
      {createLinks(thisPages[0])}
	   </div>
    )
}

export default SiteMap;
