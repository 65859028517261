import React from 'react';
//import { Link } from 'react-router-dom';

const SpanningDoelstellingen = () => (
<div className="vis-spanning-doelstellingen svg-container">
<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="120 150 440 600"
		preserveAspectRatio="xMidYMin meet">

<g className="pijlen">
	<path d="M213,387.75c-16.79,0-32.68-6.48-44.73-18.24C156,357.54,149.25,341.38,149.25,324s6.75-33.54,19.02-45.51,c12.06-11.76,27.95-18.24,44.73-18.24c22.91,0,42.79,14.11,65.59,35.29l-1.02,1.1c-22.54-20.94-42.15-34.89-64.57-34.89,c-16.39,0-31.91,6.33-43.69,17.82c-11.97,11.68-18.56,27.46-18.56,44.43s6.59,32.75,18.56,44.43,c11.78,11.49,27.29,17.82,43.69,17.82c23.24,0,43.43-14.76,68.48-38.57l1.03,1.09C257.2,372.84,236.74,387.75,213,387.75z"/>
	<polygon points="279.78,292.63 282,299.77 274.71,298.13 	"/>
	<path d="M459,387.75c-23.74,0-44.2-14.91-69.52-38.98l1.03-1.09c25.05,23.81,45.25,38.57,68.48,38.57,c16.39,0,31.91-6.33,43.69-17.82c11.97-11.68,18.56-27.46,18.56-44.43s-6.59-32.75-18.56-44.43,c-11.78-11.49-27.29-17.82-43.69-17.82c-22.42,0-42.03,13.95-64.57,34.88l-1.02-1.1c22.8-21.18,42.68-35.29,65.59-35.29,c16.79,0,32.67,6.48,44.73,18.24c12.26,11.96,19.02,28.12,19.02,45.51s-6.75,33.54-19.02,45.51,C491.67,381.27,475.79,387.75,459,387.75z"/>
	<polygon points="397.29,298.13 390,299.77 392.22,292.63 	"/>
</g>
<g className="achtbaan">
	<path d="M201,408l9.07-9.07c-18.65-0.72-36.19-8.23-49.66-21.37C145.96,363.46,138,344.44,138,324,s7.96-39.46,22.41-53.56c11.84-11.55,26.83-18.75,42.96-20.81L213,240l-8.6-8.6c-21.25,1.94-41.08,11.05-56.56,26.16,C129.89,275.07,120,298.67,120,324s9.89,48.93,27.84,66.44c16.82,16.41,38.76,25.73,62.09,26.48L201,408z"/>
	<path d="M449.28,398.28c-32.01-4.71-59.16-32.25-92.72-66.31c-1.89-1.92-3.82-3.87-5.76-5.84,c-2.08,2.1-4.18,4.22-6.32,6.35c-2.15,2.15-4.27,4.29-6.37,6.41c1.9,1.92,3.78,3.83,5.63,5.71c35.75,36.28,66.95,67.93,106.7,71.95,L459,408L449.28,398.28z"/>
	<path d="M315.44,316.03c1.89,1.92,3.82,3.87,5.76,5.84c2.08-2.1,4.18-4.22,6.32-6.35c2.15-2.15,4.27-4.29,6.38-6.41,c-1.9-1.92-3.78-3.83-5.63-5.71c-37.38-37.93-69.79-70.79-112.18-72.32L225,240l-9.07,9.07,C250.96,250.61,279.53,279.59,315.44,316.03z"/>
	<path d="M356.54,316.05c2.24-2.27,4.44-4.51,6.63-6.72c0,0,0,0,0,0l0,0c32.87-33.23,60.04-58.81,92.9-60.25L447,240,l8.93-8.93c-39.87,1.43-70.9,30.58-105.55,65.59c0,0,0,0-0.01,0c-2.2,2.22-4.41,4.47-6.66,6.75c-4.56,4.62-9.27,9.4-14.08,14.22,c-4.88,4.88-9.63,9.7-14.22,14.36c-2.3,2.33-4.56,4.63-6.81,6.9l0,0c-30.48,30.81-56.07,54.99-85.88,59.38L213,408l8.56,8.56,c37.18-3.76,66.88-31.7,99.82-64.98c0.01-0.01,0.01-0.01,0.02-0.02c2.25-2.28,4.53-4.59,6.83-6.93c4.57-4.64,9.3-9.44,14.13-14.27,C347.23,325.5,351.96,320.7,356.54,316.05z"/>
	<path d="M524.16,257.56c-15.48-15.1-35.31-24.21-56.56-26.16L459,240l9.63,9.63c16.12,2.06,31.12,9.26,42.96,20.81,C526.04,284.54,534,303.56,534,324s-7.96,39.46-22.41,53.56c-13.47,13.14-31.01,20.66-49.66,21.37L471,408l-8.93,8.93,c23.32-0.76,45.27-10.08,62.09-26.48C542.11,372.93,552,349.33,552,324S542.11,275.07,524.16,257.56z"/>
</g>
<g className="tcpTekst">
	<g>
		<path d="M166.13,289.62c0.79-1.07,1.89-1.31,2.86-0.6c0.62,0.45,0.78,1.17,0.65,1.67c0.51-0.32,1.24-0.43,1.97,0.1,c1.06,0.77,1.09,1.91,0.34,2.94l-1.72,2.34l-5.75-4.2L166.13,289.62z M165.9,291.56l1.65,1.21l0.95-1.3,c0.41-0.56,0.4-1.16-0.13-1.55c-0.53-0.39-1.1-0.22-1.52,0.34L165.9,291.56z M168.33,293.34l1.75,1.28l1.01-1.38,c0.45-0.61,0.33-1.22-0.18-1.6c-0.51-0.37-1.12-0.3-1.57,0.31L168.33,293.34z"/>
		<path d="M172.74,289.18c0.65,0.6,1.35,0.63,1.93,0c0.39-0.42,0.48-0.75,0.46-1.27l0.89-0.07,c0.03,0.74-0.11,1.29-0.71,1.95c-0.87,0.94-2.05,1.24-3.47-0.06c-1.22-1.12-1.33-2.38-0.44-3.35c0.93-1.01,2.19-0.94,3.28,0.06,l0.32,0.29L172.74,289.18z M173.17,286.64c-0.4-0.13-0.83-0.05-1.15,0.31c-0.33,0.36-0.38,0.8-0.22,1.18,c0.09,0.22,0.2,0.35,0.42,0.56l1.56-1.69C173.53,286.81,173.4,286.72,173.17,286.64z"/>
		<path d="M174.96,282.9l0.73-0.67l0.53,0.57l-0.73,0.67l1.92,2.08c0.26,0.28,0.53,0.31,0.81,0.05l0.31-0.29,l0.59,0.64l-0.45,0.42c-0.7,0.65-1.48,0.46-1.99-0.09l-1.95-2.12l-0.43,0.4l-0.53-0.57l0.43-0.4l-0.98-1.07l0.76-0.7L174.96,282.9,z"/>
		<path d="M180.87,280.19l2.09,2.67l-0.79,0.62l-0.28-0.36c-0.07,0.52-0.25,0.86-0.75,1.25,c-0.5,0.39-0.93,0.54-1.36,0.48c-0.37-0.05-0.72-0.27-0.99-0.61c-0.52-0.67-0.43-1.52,0.5-2.25l1.06-0.83l-0.22-0.28,c-0.4-0.51-0.83-0.55-1.47-0.06c-0.43,0.34-0.57,0.62-0.57,1.08l-0.91-0.08c0.03-0.73,0.31-1.18,0.98-1.71,C179.25,279.25,180.17,279.29,180.87,280.19z M179.84,282.46c-0.49,0.39-0.58,0.78-0.29,1.15c0.29,0.37,0.67,0.39,1.21-0.03,c0.29-0.22,0.49-0.44,0.55-0.8c0.03-0.22-0.07-0.44-0.29-0.73l-0.25-0.32L179.84,282.46z"/>
		<path d="M185.33,277.25l1.83,2.86l-0.85,0.54l-0.25-0.39c-0.11,0.51-0.33,0.83-0.87,1.17,c-0.54,0.34-0.97,0.44-1.4,0.35c-0.37-0.09-0.7-0.34-0.93-0.7c-0.46-0.72-0.29-1.55,0.71-2.19l1.13-0.72l-0.19-0.3,c-0.35-0.55-0.78-0.63-1.46-0.2c-0.46,0.29-0.62,0.56-0.67,1.02l-0.9-0.17c0.1-0.73,0.42-1.15,1.14-1.61,C183.8,276.16,184.71,276.29,185.33,277.25z M184.09,279.41c-0.53,0.34-0.65,0.72-0.4,1.12c0.25,0.4,0.63,0.45,1.2,0.08,c0.31-0.2,0.53-0.39,0.63-0.74c0.06-0.21-0.02-0.45-0.22-0.75l-0.22-0.35L184.09,279.41z"/>
		<path d="M188.58,277.49c0.25,0.48,0.46,0.57,0.83,0.38l0.4-0.21l0.43,0.8l-0.5,0.26c-0.87,0.46-1.55,0.21-2.04-0.7,l-2.6-4.9l0.91-0.48L188.58,277.49z"/>
		<path d="M190.28,273.4c0.17-0.55,0.51-0.84,1.01-1.05c0.47-0.2,0.95-0.22,1.32-0.08c0.65,0.26,1.05,0.98,1.37,1.74,c0.33,0.77,0.58,1.55,0.32,2.2c-0.15,0.37-0.5,0.7-0.97,0.91c-0.5,0.21-0.94,0.25-1.48-0.02l0.21,0.5l-0.93,0.4l-2.81-6.54,l0.94-0.41L190.28,273.4z M191.08,275.25c0.36,0.83,0.79,1.5,1.65,1.13c0.85-0.37,0.66-1.14,0.3-1.97,c-0.36-0.83-0.78-1.49-1.64-1.12C190.54,273.66,190.72,274.43,191.08,275.25z"/>
		<path d="M198.52,271.63l1.02,3.23l-0.96,0.31l-0.14-0.44c-0.24,0.46-0.54,0.71-1.14,0.91,c-0.61,0.19-1.05,0.18-1.45-0.02c-0.33-0.18-0.58-0.51-0.71-0.92c-0.26-0.81,0.13-1.57,1.25-1.93l1.28-0.41l-0.11-0.34,c-0.2-0.62-0.58-0.81-1.35-0.57c-0.52,0.16-0.75,0.38-0.92,0.82l-0.82-0.4c0.28-0.68,0.7-1,1.52-1.26,C197.33,270.18,198.17,270.54,198.52,271.63z M196.76,273.39c-0.6,0.19-0.82,0.53-0.67,0.98c0.14,0.45,0.5,0.6,1.14,0.39,c0.35-0.11,0.61-0.24,0.8-0.56c0.11-0.19,0.09-0.44-0.02-0.78l-0.12-0.39L196.76,273.39z"/>
		<path d="M203.73,270.44l0.71,3.32l-0.99,0.21l-0.1-0.45c-0.29,0.44-0.6,0.66-1.22,0.79,c-0.62,0.13-1.07,0.07-1.44-0.16c-0.31-0.21-0.53-0.56-0.62-0.98c-0.18-0.83,0.28-1.55,1.43-1.8l1.31-0.28l-0.08-0.35,c-0.14-0.64-0.5-0.86-1.29-0.7c-0.53,0.11-0.78,0.31-0.99,0.72l-0.78-0.48c0.35-0.65,0.79-0.93,1.63-1.1,C202.68,268.88,203.49,269.32,203.73,270.44z M201.81,272.03c-0.61,0.13-0.86,0.45-0.77,0.91c0.1,0.46,0.44,0.64,1.1,0.5,c0.36-0.08,0.63-0.18,0.85-0.48c0.13-0.18,0.14-0.43,0.06-0.78l-0.09-0.4L201.81,272.03z"/>
		<path d="M208.8,268.5l-0.68,0.86c-0.26-0.2-0.45-0.29-0.8-0.25c-0.55,0.07-0.96,0.56-0.87,1.25l0.37,3.06,l-1.02,0.12l-0.61-5.04l1-0.12l0.07,0.55c0.22-0.39,0.71-0.7,1.28-0.77C208.02,268.11,208.41,268.2,208.8,268.5z"/>
		<path d="M210.83,268.49c0.33-0.39,0.8-0.59,1.33-0.61s0.96,0.14,1.27,0.42c0.4,0.37,0.54,0.84,0.56,1.41l0.09,3.24,l-1.03,0.03l-0.09-3.09c-0.02-0.79-0.49-1.12-1.06-1.1c-0.57,0.02-1.05,0.38-1.03,1.16l0.09,3.09l-1.03,0.03l-0.21-7.12l1.03-0.03,L210.83,268.49z"/>
		<path d="M216.11,270.82c-0.07,0.88,0.34,1.45,1.18,1.52c0.57,0.05,0.89-0.09,1.28-0.42l0.6,0.66,c-0.56,0.48-1.08,0.71-1.97,0.63c-1.28-0.1-2.24-0.85-2.09-2.78c0.13-1.64,1.06-2.51,2.37-2.41c1.37,0.11,2.08,1.15,1.97,2.62,l-0.03,0.43L216.11,270.82z M218.38,269.59c-0.14-0.39-0.47-0.68-0.95-0.72c-0.49-0.04-0.87,0.19-1.07,0.56,c-0.12,0.21-0.15,0.37-0.19,0.68l2.29,0.18C218.47,269.98,218.46,269.82,218.38,269.59z"/>
		<path d="M221.41,273.7l-1.02-0.17l0.84-5l1.01,0.17L221.41,273.7z M222.45,267.72l-1.07-0.18l0.18-1.06l1.07,0.18,L222.45,267.72z"/>
		<path d="M226.68,274.94l-0.98-0.25l0.13-0.52c-0.49,0.35-0.93,0.38-1.46,0.24c-0.51-0.13-0.9-0.41-1.1-0.75,c-0.35-0.61-0.23-1.41-0.02-2.22c0.21-0.8,0.49-1.58,1.09-1.94c0.34-0.2,0.81-0.26,1.31-0.13c0.53,0.14,0.9,0.37,1.15,0.9,l0.64-2.49l1,0.26L226.68,274.94z M224.26,271.7c-0.22,0.87-0.29,1.66,0.61,1.89c0.91,0.23,1.23-0.49,1.46-1.36,c0.23-0.87,0.3-1.67-0.61-1.9C224.81,270.09,224.48,270.82,224.26,271.7z"/>
		<path d="M235.65,273.36c0.21,0.5,0.14,0.99-0.08,1.52l-1.26,2.99l-0.95-0.4l1.2-2.85,c0.31-0.73,0.02-1.22-0.51-1.44c-0.53-0.22-1.11-0.09-1.42,0.63l-1.2,2.85l-0.95-0.4l1.97-4.67l0.93,0.39l-0.2,0.47,c0.47-0.21,1-0.19,1.48,0.01C235.15,272.65,235.49,272.97,235.65,273.36z"/>
		<path d="M237.37,276.88c-0.41,0.78-0.25,1.47,0.5,1.86c0.51,0.26,0.85,0.26,1.34,0.11l0.3,0.84,c-0.71,0.22-1.27,0.23-2.06-0.18c-1.14-0.59-1.73-1.66-0.84-3.37c0.76-1.46,1.96-1.9,3.12-1.29c1.22,0.63,1.47,1.87,0.79,3.18,l-0.2,0.38L237.37,276.88z M239.94,276.64c0.02-0.42-0.17-0.81-0.6-1.03c-0.44-0.23-0.87-0.16-1.2,0.1,c-0.19,0.15-0.28,0.28-0.44,0.55l2.04,1.06C239.87,277.03,239.92,276.88,239.94,276.64z"/>
		<path d="M242.39,279.62c-0.46,0.75-0.35,1.45,0.38,1.89c0.49,0.3,0.83,0.32,1.33,0.2l0.25,0.86,c-0.72,0.17-1.28,0.15-2.05-0.32c-1.1-0.66-1.62-1.77-0.62-3.42c0.86-1.41,2.08-1.77,3.2-1.09c1.17,0.71,1.35,1.96,0.58,3.23,l-0.22,0.37L242.39,279.62z M244.96,279.54c0.05-0.41-0.12-0.82-0.53-1.07c-0.42-0.26-0.86-0.22-1.21,0.02,c-0.2,0.14-0.3,0.26-0.47,0.52l1.97,1.19C244.87,279.93,244.94,279.78,244.96,279.54z"/>
		<path d="M253.51,284.06c0.11,0.54-0.04,1.02-0.37,1.48l-1.85,2.65l-0.84-0.59l1.77-2.53,c0.45-0.65,0.27-1.19-0.2-1.52c-0.46-0.32-1.07-0.32-1.49,0.28l-1.8,2.57l-0.84-0.59l1.77-2.53c0.45-0.65,0.27-1.19-0.2-1.52,c-0.47-0.33-1.07-0.32-1.52,0.32l-1.77,2.53l-0.84-0.59l2.9-4.15l0.83,0.58l-0.29,0.42c0.49-0.11,1.02,0.02,1.44,0.31,c0.52,0.36,0.78,0.85,0.77,1.44c0.62-0.17,1.18-0.08,1.74,0.31C253.14,283.25,253.43,283.65,253.51,284.06z"/>
		<path d="M256.83,286.09l0.79,0.6l-0.47,0.62l-0.79-0.6l-1.72,2.25c-0.23,0.3-0.21,0.58,0.09,0.81l0.34,0.26,l-0.53,0.69l-0.49-0.37c-0.76-0.58-0.71-1.38-0.25-1.97l1.75-2.29l-0.46-0.36l0.47-0.62l0.46,0.36l0.88-1.15l0.82,0.62,L256.83,286.09z"/>
		<path d="M263.64,293.77l-2.17,2.6l-0.77-0.65l0.29-0.35c-0.52,0.04-0.89-0.07-1.38-0.47,c-0.49-0.41-0.72-0.79-0.76-1.23c-0.03-0.38,0.11-0.76,0.39-1.09c0.54-0.65,1.39-0.74,2.3,0.02l1.03,0.86l0.23-0.28,c0.42-0.5,0.37-0.93-0.25-1.45c-0.42-0.35-0.72-0.42-1.18-0.33l-0.11-0.91c0.72-0.13,1.22,0.05,1.87,0.6,C264.22,291.98,264.37,292.89,263.64,293.77z M261.21,293.23c-0.48-0.4-0.89-0.4-1.19-0.04c-0.3,0.36-0.24,0.74,0.28,1.17,c0.28,0.23,0.53,0.39,0.9,0.37c0.22-0.01,0.42-0.16,0.65-0.43l0.26-0.32L261.21,293.23z"/>
		<path d="M268.98,294.6l-0.34-0.3c-0.29-0.25-0.57-0.2-0.81,0.08l-0.39,0.45l0.77,0.67l-0.51,0.59l-0.77-0.67,l-2.81,3.24l-0.78-0.67l2.82-3.24l-0.44-0.38l0.51-0.59l0.44,0.38l0.42-0.48c0.48-0.55,1.26-0.77,1.98-0.14l0.49,0.42,L268.98,294.6z"/>
	</g>
	<g>
		<path d="M404.36,293.22a1.91,1.91,0,0,1,2.9,0,1.61,1.61,0,0,1,.28,1.76,1.7,1.7,0,0,1,1.89.54,1.93,1.93,0,0,1-.28,2.93L407,300.4l-4.7-5.35Zm-.63,1.85,1.35,1.53,1.2-1.06a1,1,0,1,0-1.35-1.53Zm2,2.26,1.43,1.63,1.28-1.13A1.09,1.09,0,1,0,407,296.2Z"/>
		<path d="M411.51,293.5a1.24,1.24,0,0,0,1.9.26,1.54,1.54,0,0,0,.61-1.19l.89.05a2.4,2.4,0,0,1-1,1.83c-1,.81-2.18.95-3.42-.53-1.06-1.27-1-2.54,0-3.37s2.28-.63,3.23.51l.27.33Zm.74-2.45a1.06,1.06,0,0,0-1.18.15,1.08,1.08,0,0,0-.36,1.14,1.51,1.51,0,0,0,.34.61l1.75-1.46A1.8,1.8,0,0,0,412.25,291.05Z"/>
		<path d="M414.78,287.17l.77-.61.48.61-.77.61L417,290a.52.52,0,0,0,.81.11l.32-.26.55.68-.48.38a1.32,1.32,0,0,1-2-.24l-1.78-2.25-.46.36-.48-.62.45-.36-.9-1.13.8-.63Z"/>
		<path d="M421.12,284.44l2,2.72-.81.6-.27-.37a1.69,1.69,0,0,1-.77,1.22,1.81,1.81,0,0,1-1.37.45,1.54,1.54,0,0,1-1-.64c-.51-.68-.4-1.52.54-2.22l1.06-.8-.21-.28c-.39-.52-.82-.58-1.46-.1a1.23,1.23,0,0,0-.59,1.06l-.9-.11a2,2,0,0,1,1-1.67C419.52,283.46,420.44,283.53,421.12,284.44Zm-1.07,2.23c-.5.37-.59.77-.31,1.14s.67.41,1.2,0a1.24,1.24,0,0,0,.57-.79,1,1,0,0,0-.28-.73L421,286Z"/>
		<path d="M425.91,281.12l1.91,2.8-.82.56-.26-.38a1.64,1.64,0,0,1-.83,1.19,1.77,1.77,0,0,1-1.38.39,1.54,1.54,0,0,1-.94-.68c-.48-.7-.34-1.54.63-2.2l1.1-.75-.2-.3c-.37-.53-.8-.61-1.46-.16a1.23,1.23,0,0,0-.63,1l-.9-.15a2,2,0,0,1,1.08-1.63C424.36,280.06,425.27,280.17,425.91,281.12Zm-1.16,2.18c-.51.35-.62.74-.36,1.13s.65.43,1.2.05a1.2,1.2,0,0,0,.6-.76A1,1,0,0,0,426,283l-.23-.34Z"/>
		<path d="M429.49,280.93c.29.46.5.54.85.33l.38-.24.48.77-.47.3a1.35,1.35,0,0,1-2.07-.57l-2.93-4.71.86-.54Z"/>
		<path d="M431.1,276.58a1.56,1.56,0,0,1,.89-1.14,1.7,1.7,0,0,1,1.29-.2,4.28,4.28,0,0,1,2.07,3.76,1.75,1.75,0,0,1-.87,1,1.56,1.56,0,0,1-1.46.12l.26.47-.88.48L429,274.83l.9-.49Zm1,1.77c.44.79.93,1.42,1.75,1s.54-1.2.1-2-.92-1.41-1.73-1S431.64,277.56,432.07,278.35Z"/>
		<path d="M439.23,273.82l1.41,3.09-.91.41-.19-.41a1.68,1.68,0,0,1-1,1,1.82,1.82,0,0,1-1.43.15,1.55,1.55,0,0,1-.82-.83c-.36-.77-.07-1.57,1-2.06l1.21-.55-.15-.33c-.27-.59-.68-.73-1.4-.4a1.24,1.24,0,0,0-.81.92l-.86-.3a2.07,2.07,0,0,1,1.34-1.43C437.87,272.53,438.75,272.79,439.23,273.82Zm-1.52,2c-.56.26-.73.63-.54,1.05s.56.54,1.17.26a1.22,1.22,0,0,0,.72-.65,1,1,0,0,0-.11-.77l-.17-.38Z"/>
		<path d="M444.28,271.94l1.14,3.19-.94.34-.16-.44a1.64,1.64,0,0,1-1.09,1,1.8,1.8,0,0,1-1.44,0,1.48,1.48,0,0,1-.74-.89c-.29-.8.06-1.57,1.16-2l1.25-.44-.12-.34c-.22-.61-.61-.79-1.36-.52a1.23,1.23,0,0,0-.88.84l-.83-.37a2.05,2.05,0,0,1,1.45-1.31C443,270.53,443.89,270.86,444.28,271.94Zm-1.68,1.82c-.58.21-.79.56-.63,1s.52.58,1.15.35a1.2,1.2,0,0,0,.77-.58,1,1,0,0,0-.05-.78l-.14-.39Z"/>
		<path d="M449,269.36l-.55.94a.92.92,0,0,0-.82-.14,1,1,0,0,0-.69,1.36l.77,3-1,.26-1.27-4.92,1-.25.14.54a1.66,1.66,0,0,1,1.15-.93A1.57,1.57,0,0,1,449,269.36Z"/>
		<path d="M450.88,269.13a1.69,1.69,0,0,1,1.23-.76,1.75,1.75,0,0,1,1.3.26,1.84,1.84,0,0,1,.72,1.34l.49,3.2-1,.16-.46-3.06a1,1,0,0,0-1.18-1,1,1,0,0,0-.87,1.27l.46,3.06-1,.15-1.06-7,1-.15Z"/>
		<path d="M456.26,270.83c0,.88.5,1.4,1.34,1.37a1.52,1.52,0,0,0,1.21-.56l.67.59a2.39,2.39,0,0,1-1.87.85c-1.27.05-2.3-.59-2.37-2.52-.06-1.64.76-2.61,2.06-2.66s2.18.9,2.23,2.38l0,.43Zm2.09-1.48a1,1,0,0,0-1-.6,1.07,1.07,0,0,0-1,.67,1.52,1.52,0,0,0-.1.7l2.27-.08A1.71,1.71,0,0,0,458.35,269.35Z"/>
		<path d="M461.91,273.08l-1-.05.24-5.07,1,.05Zm.31-6.07-1.08-.05.05-1.08,1.08.05Z"/>
		<path d="M467.33,273.63l-1-.12.07-.53a1.61,1.61,0,0,1-1.4.43,1.79,1.79,0,0,1-1.18-.6,3.07,3.07,0,0,1-.3-2.2,3.13,3.13,0,0,1,.83-2.06,1.71,1.71,0,0,1,1.28-.29,1.51,1.51,0,0,1,1.25.74l.31-2.55,1,.12Zm-2.8-2.89c-.11.89-.07,1.68.84,1.8s1.15-.65,1.26-1.54.08-1.69-.85-1.81S464.65,269.85,464.53,270.74Z"/>
		<path d="M475.88,270.9a1.86,1.86,0,0,1,.1,1.52l-.89,3.11-1-.28.85-3a1,1,0,0,0-.67-1.37,1,1,0,0,0-1.32.8l-.85,3-1-.28,1.39-4.87,1,.27-.14.49a1.78,1.78,0,0,1,1.46-.17A1.74,1.74,0,0,1,475.88,270.9Z"/>
		<path d="M477.73,274.11a1.24,1.24,0,0,0,.67,1.8,1.48,1.48,0,0,0,1.33,0l.38.81a2.38,2.38,0,0,1-2.06,0c-1.18-.47-1.87-1.47-1.15-3.26.61-1.53,1.75-2.09,3-1.61a2.15,2.15,0,0,1,1.08,3.08l-.16.4Zm2.5-.5a1,1,0,0,0-.69-1,1.07,1.07,0,0,0-1.18.22,1.61,1.61,0,0,0-.37.59l2.12.85A1.86,1.86,0,0,0,480.23,273.61Z"/>
		<path d="M482.56,276.2a1.23,1.23,0,0,0,.49,1.85,1.5,1.5,0,0,0,1.33.11l.3.84a2.41,2.41,0,0,1-2.06-.17c-1.12-.59-1.71-1.65-.82-3.36.76-1.47,1.95-1.91,3.1-1.31s1.46,1.86.78,3.17l-.2.38Zm2.54-.26a1,1,0,0,0-.59-1,1.08,1.08,0,0,0-1.19.1,1.74,1.74,0,0,0-.44.56l2,1.05A1.8,1.8,0,0,0,485.1,275.94Z"/>
		<path d="M493.18,279.89a1.88,1.88,0,0,1-.36,1.49L491,284.05l-.84-.58,1.75-2.55a1,1,0,0,0-.2-1.51,1,1,0,0,0-1.48.3l-1.78,2.58-.84-.57,1.76-2.55a1,1,0,0,0-.2-1.51,1,1,0,0,0-1.51.33l-1.75,2.55L485,280l2.88-4.18.83.57-.29.42a1.83,1.83,0,0,1,1.43.3,1.63,1.63,0,0,1,.77,1.43,1.92,1.92,0,0,1,1.73.29A1.81,1.81,0,0,1,493.18,279.89Z"/>
		<path d="M495.92,281.73l.74.64-.51.59-.74-.64-1.85,2.15a.51.51,0,0,0,0,.81l.31.27-.56.66-.47-.4a1.32,1.32,0,0,1-.13-2l1.88-2.18-.44-.38.51-.59.44.38.95-1.1.77.67Z"/>
		<path d="M500.77,286.48l.66.72-.58.53-.66-.73-2.09,1.91a.51.51,0,0,0-.06.81l.29.31-.65.59-.41-.45a1.32,1.32,0,0,1,.11-2l2.12-1.94-.39-.43.58-.52.39.42,1.07-1,.69.75Z"/>
		<path d="M504.07,291.36a2.63,2.63,0,0,1-1.21,1.69,2.67,2.67,0,0,1-1.95.75,2.22,2.22,0,0,1-1.85-2.4,3.62,3.62,0,0,1,3.16-2.44,2.08,2.08,0,0,1,1.4.83A2.06,2.06,0,0,1,504.07,291.36Zm-1.86-1.42a2,2,0,0,0-1.31.57,2,2,0,0,0-.89,1.13,1,1,0,0,0,.22.77,1.09,1.09,0,0,0,.69.41,2,2,0,0,0,1.32-.57,2,2,0,0,0,.88-1.13,1.09,1.09,0,0,0-.91-1.18Z"/>
		<path d="M503.71,294.87a1.24,1.24,0,0,0-.52,1.85,1.53,1.53,0,0,0,1.09.77l-.17.87a2.37,2.37,0,0,1-1.68-1.19c-.67-1.08-.64-2.29,1-3.31,1.4-.88,2.64-.65,3.33.45s.31,2.34-.95,3.13l-.36.23ZM506,296a1.11,1.11,0,0,0-1.07-1.71,1.5,1.5,0,0,0-.65.26l1.21,1.93A1.83,1.83,0,0,0,506,296Z"/>
	</g>
	<g>
		<path d="M186.49,371.7l.08,5-1.11-.64v-4.05l-1.76.65-1,1.8-.95-.54,3.53-6.18.95.54-1.78,3.11,4.33-1.65,1.15.66Z"/>
		<path d="M189.49,372.56l1,.41-.41,3.68,2.42-2.87.75.3-.25,3.75,2.26-2.93,1,.41-3.38,4.11-.82-.34.27-3.84-2.48,2.95-.81-.33Z"/>
		<path d="M200.77,378.2l-.87,3.28-1-.26.12-.44a1.71,1.71,0,0,1-1.45.15,1.79,1.79,0,0,1-1.21-.8,1.5,1.5,0,0,1-.11-1.15c.22-.83.95-1.26,2.09-1l1.3.34.09-.35c.16-.63-.06-1-.84-1.2a1.22,1.22,0,0,0-1.21.2l-.48-.78a2.06,2.06,0,0,1,2-.25C200.55,376.34,201.06,377.1,200.77,378.2Zm-2.43.55c-.6-.16-1,0-1.09.46s.1.77.75,1a1.21,1.21,0,0,0,1-.05.94.94,0,0,0,.41-.66l.1-.4Z"/>
		<path d="M203.75,380.55c-.1.53,0,.74.42.81l.45.07-.15.9-.56-.1c-1-.16-1.36-.78-1.19-1.79l.94-5.47,1,.17Z"/>
		<path d="M207.66,382.72l-1-.11.53-5,1,.1Zm.67-6-1.08-.11.11-1.08,1.08.12Z"/>
		<path d="M211.68,377.9l1,0,0,.78-1,0-.11,2.83a.52.52,0,0,0,.54.61h.43l0,.87-.61,0a1.32,1.32,0,0,1-1.35-1.46l.11-2.88-.59,0,0-.78.59,0,0-1.45,1,0Z"/>
		<path d="M215.41,380.74a1.24,1.24,0,0,0,1.39,1.34,1.52,1.52,0,0,0,1.2-.6l.69.57a2.41,2.41,0,0,1-1.86.91c-1.28.08-2.34-.53-2.46-2.46-.1-1.64.7-2.63,2-2.71s2.23.84,2.32,2.32l0,.43Zm2.07-1.53a1.12,1.12,0,0,0-2,.12,1.53,1.53,0,0,0-.09.7l2.29-.14A1.83,1.83,0,0,0,217.48,379.21Z"/>
		<path d="M221.36,376.27l-1.08.17-.17-1.07,1.08-.17Zm.92,6-1,.16-.79-5,1-.16Z"/>
		<path d="M224.87,376.55l1-.23.18.76-1,.23.66,2.75a.51.51,0,0,0,.68.44l.42-.09.2.84-.6.15a1.32,1.32,0,0,1-1.69-1l-.67-2.8-.57.14-.18-.76.56-.14-.33-1.41,1-.24Z"/>
		<path d="M234.19,373.82a1.81,1.81,0,0,1,1,1.13l1.22,3-.95.38-1.16-2.86a1,1,0,0,0-1.38-.66,1,1,0,0,0-.56,1.44l1.16,2.87-.95.38-1.91-4.7.94-.38.19.48a1.82,1.82,0,0,1,1-1A1.74,1.74,0,0,1,234.19,373.82Z"/>
		<path d="M238.48,374.5a1.24,1.24,0,0,0,1.81.67,1.54,1.54,0,0,0,.87-1l.86.24a2.43,2.43,0,0,1-1.35,1.58c-1.14.58-2.35.45-3.23-1.27-.75-1.47-.42-2.69.75-3.29a2.15,2.15,0,0,1,3.05,1.2l.2.38Zm1.29-2.22a1.13,1.13,0,0,0-1.81.92,1.58,1.58,0,0,0,.2.67l2.05-1A1.59,1.59,0,0,0,239.77,372.28Z"/>
		<path d="M244.05,371.48a1.24,1.24,0,0,0,1.85.54,1.51,1.51,0,0,0,.79-1.08l.88.18a2.41,2.41,0,0,1-1.23,1.67c-1.1.66-2.32.61-3.32-1-.85-1.41-.6-2.66.52-3.34s2.36-.28,3.13,1l.22.36Zm1.12-2.31a1.05,1.05,0,0,0-1.19,0,1.07,1.07,0,0,0-.54,1.07,1.57,1.57,0,0,0,.24.66l2-1.19A1.58,1.58,0,0,0,245.17,369.17Z"/>
		<path d="M252.93,362.7a1.85,1.85,0,0,1,1.26.86l1.86,2.65-.84.6-1.77-2.54a1,1,0,0,0-1.49-.33,1,1,0,0,0-.25,1.49l1.8,2.58-.84.59-1.77-2.53a1,1,0,0,0-1.49-.34,1,1,0,0,0-.22,1.54L251,369.8l-.84.59-2.91-4.16.82-.58.3.42a1.87,1.87,0,0,1,.78-1.25,1.68,1.68,0,0,1,1.62-.23,1.92,1.92,0,0,1,.89-1.52A1.82,1.82,0,0,1,252.93,362.7Z"/>
		<path d="M255.91,359.92l.78-.6.48.61-.79.61,1.73,2.24a.52.52,0,0,0,.81.12l.33-.26.53.69-.48.38a1.34,1.34,0,0,1-2-.26l-1.75-2.28-.47.35-.47-.61.46-.36-.88-1.15.81-.63Z"/>
		<path d="M261.75,355.22l.76-.64.5.6-.76.64,1.81,2.17a.5.5,0,0,0,.81.09l.32-.27.56.67-.47.4a1.32,1.32,0,0,1-2-.19l-1.84-2.22-.45.38-.5-.6.45-.38-.93-1.11.79-.66Z"/>
		<path d="M267,351.44a3.59,3.59,0,0,1,2.61,3,2.25,2.25,0,0,1-2.31,2,2.65,2.65,0,0,1-1.76-1.12,2.62,2.62,0,0,1-.85-1.9,2.06,2.06,0,0,1,.76-1.45A2.11,2.11,0,0,1,267,351.44Zm-1.33,1.95a3,3,0,0,0,1.82,2.1,1.07,1.07,0,0,0,.76-.27,1,1,0,0,0,.38-.72,2.9,2.9,0,0,0-1.82-2.1,1.14,1.14,0,0,0-1.14,1Z"/>
		<path d="M271.21,350.77a1.23,1.23,0,0,0,1.91.18,1.55,1.55,0,0,0,.58-1.22h.89a2.45,2.45,0,0,1-.89,1.88c-.95.85-2.16,1-3.45-.39-1.11-1.23-1.1-2.5-.13-3.38a2.16,2.16,0,0,1,3.26.37l.29.32Zm.66-2.49a1.05,1.05,0,0,0-1.18.2,1.07,1.07,0,0,0-.32,1.16,1.55,1.55,0,0,0,.36.6l1.71-1.54A1.61,1.61,0,0,0,271.87,348.28Z"/>
	</g>
	<g>
		<path d="M405.42,348.7l-1.45-1.32l-4.14,4.54l-0.8-0.73l4.14-4.54l-1.45-1.32l0.65-0.72l3.69,3.37L405.42,348.7z"/>
		<path d="M407.84,352.98c0.03,0.69-0.3,1.25-0.87,1.89c-0.57,0.65-1.08,1.05-1.77,1.1,c-0.47,0.04-1.02-0.1-1.54-0.55c-0.52-0.46-0.71-0.97-0.74-1.45c-0.03-0.69,0.3-1.25,0.87-1.9c0.56-0.64,1.07-1.04,1.76-1.1,c0.47-0.04,1.01,0.09,1.53,0.55C407.61,351.99,407.82,352.51,407.84,352.98z M405.75,351.94c-0.44,0.03-0.82,0.4-1.18,0.81,s-0.68,0.84-0.66,1.27c0.02,0.27,0.13,0.52,0.36,0.71c0.23,0.2,0.49,0.29,0.76,0.27c0.44-0.03,0.82-0.4,1.18-0.81,s0.68-0.84,0.66-1.28c-0.02-0.27-0.15-0.51-0.37-0.71C406.27,352.01,406.02,351.92,405.75,351.94z"/>
		<path d="M409.01,357c-0.57,0.67-0.57,1.38,0.08,1.92c0.44,0.37,0.77,0.44,1.28,0.39l0.11,0.88,c-0.73,0.06-1.28-0.04-1.96-0.62c-0.97-0.82-1.32-1.98-0.08-3.46c1.06-1.26,2.32-1.44,3.31-0.6c1.04,0.87,1.02,2.13,0.07,3.27,l-0.28,0.33L409.01,357z M411.55,357.31c0.11-0.4,0.01-0.82-0.36-1.13c-0.38-0.31-0.81-0.34-1.18-0.16,c-0.22,0.1-0.34,0.21-0.54,0.44l1.75,1.46C411.4,357.68,411.49,357.54,411.55,357.31z"/>
		<path d="M416.85,360.43l0.33-0.42l0.78,0.61l-3.12,3.96c-0.77,0.97-1.97,1.17-3.03,0.34,c-0.6-0.48-0.88-0.92-0.99-1.63l0.91-0.11c0.07,0.43,0.19,0.73,0.59,1.04c0.68,0.53,1.31,0.26,1.73-0.28l0.4-0.51,c-0.56,0.12-0.98-0.03-1.39-0.36c-0.4-0.32-0.62-0.71-0.67-1.1c-0.08-0.69,0.3-1.3,0.83-1.97c0.53-0.68,1.04-1.18,1.72-1.27,c0.39-0.05,0.83,0.09,1.23,0.41C416.6,359.47,416.84,359.83,416.85,360.43z M414.03,360.62c-0.5,0.63-0.89,1.32-0.17,1.89,c0.72,0.57,1.3,0.02,1.79-0.61c0.5-0.63,0.9-1.32,0.17-1.89C415.11,359.45,414.52,359.99,414.03,360.62z"/>
		<path d="M421.9,365.62l-1.99,2.74l-0.81-0.59l0.27-0.37c-0.52,0.08-0.89,0-1.4-0.37,c-0.51-0.37-0.76-0.74-0.83-1.17c-0.05-0.37,0.06-0.77,0.32-1.12c0.5-0.69,1.33-0.83,2.28-0.15l1.08,0.78l0.21-0.29,c0.38-0.53,0.31-0.95-0.34-1.42c-0.44-0.32-0.75-0.37-1.19-0.25l-0.16-0.9c0.71-0.18,1.21-0.04,1.9,0.46,C422.37,363.81,422.57,364.7,421.9,365.62z M419.46,365.27c-0.5-0.36-0.91-0.34-1.18,0.04c-0.28,0.38-0.19,0.75,0.35,1.15,c0.29,0.21,0.55,0.35,0.92,0.31c0.22-0.03,0.41-0.19,0.62-0.48l0.24-0.33L419.46,365.27z"/>
		<path d="M427.64,367.92c0.12,0.53-0.04,1-0.35,1.48l-1.77,2.71l-0.85-0.56l1.69-2.59c0.43-0.66,0.24-1.19-0.24-1.5,s-1.07-0.28-1.5,0.37l-1.69,2.59l-0.85-0.56l2.77-4.25l0.84,0.55l-0.28,0.43c0.5-0.13,1.02-0.02,1.44,0.26,C427.28,367.13,427.54,367.51,427.64,367.92z"/>
		<path d="M429.96,371.59l2.87-1.07l1.08,0.61L431.4,372l0.21,3.66l-1.1-0.62l-0.09-2.74l-1.07,0.37l-0.73,1.3,l-0.89-0.5l3.5-6.2l0.89,0.5L429.96,371.59z"/>
		<path d="M434.73,374.7c-0.38,0.8-0.2,1.48,0.56,1.84c0.52,0.24,0.86,0.23,1.34,0.05l0.33,0.83,c-0.69,0.25-1.25,0.28-2.06-0.1c-1.15-0.54-1.78-1.58-0.95-3.33c0.71-1.49,1.88-1.98,3.05-1.42c1.23,0.58,1.53,1.81,0.89,3.14,l-0.18,0.39L434.73,374.7z M437.27,374.35c0.01-0.42-0.2-0.8-0.63-1.01c-0.44-0.21-0.87-0.12-1.19,0.15,c-0.18,0.16-0.27,0.29-0.41,0.57l2.06,0.97C437.21,374.74,437.26,374.59,437.27,374.35z"/>
		<path d="M440.48,377.86c-0.19,0.5-0.13,0.72,0.26,0.87l0.42,0.16l-0.32,0.85l-0.52-0.2,c-0.92-0.35-1.17-1.03-0.8-1.99l1.98-5.18l0.95,0.36L440.48,377.86z"/>
		<path d="M443.86,380.73l-0.97-0.3l1.5-4.84l0.97,0.3L443.86,380.73z M445.69,374.94l-1.03-0.32l0.32-1.03,l1.03,0.32L445.69,374.94z"/>
		<path d="M447.27,382.31c-0.17,0.73-0.74,1.26-1.68,1.04l-0.61-0.15l0.2-0.85l0.44,0.11,c0.4,0.1,0.58-0.07,0.67-0.45l1.32-5.5l0.99,0.24L447.27,382.31z M448.86,375.78l-1.05-0.25l0.25-1.05l1.05,0.25L448.86,375.78z"/>
		<path d="M451.42,379.68l2.28-2.04l1.23,0.17l-2.02,1.73l1.53,3.33l-1.25-0.17l-1.08-2.51l-0.86,0.74l-0.21,1.48,l-1.01-0.14l0.99-7.05l1.01,0.14L451.42,379.68z"/>
		<path d="M457.59,378.43c0.34-0.38,0.82-0.56,1.34-0.55c0.52,0.01,0.95,0.18,1.24,0.48,c0.38,0.38,0.5,0.87,0.49,1.44l-0.04,3.24l-1.02-0.01l0.04-3.09c0.01-0.79-0.44-1.13-1.01-1.14c-0.57-0.01-1.05,0.34-1.06,1.12,l-0.04,3.09l-1.02-0.01l0.09-7.12l1.02,0.01L457.59,378.43z"/>
		<path d="M463.76,380.57c0.09,0.88,0.59,1.37,1.43,1.28c0.57-0.06,0.85-0.25,1.17-0.64l0.71,0.54,c-0.46,0.57-0.92,0.89-1.81,0.98c-1.26,0.13-2.34-0.43-2.53-2.35c-0.17-1.64,0.58-2.66,1.88-2.8c1.35-0.14,2.24,0.75,2.39,2.23,l0.04,0.43L463.76,380.57z M465.75,378.96c-0.21-0.36-0.58-0.58-1.06-0.53c-0.49,0.05-0.81,0.34-0.94,0.74,c-0.08,0.23-0.08,0.39-0.06,0.7l2.27-0.23C465.9,379.33,465.87,379.17,465.75,378.96z"/>
		<path d="M469.55,375.88l-1.06,0.2l-0.2-1.06l1.06-0.2L469.55,375.88z M470.66,381.85l-1,0.19l-0.95-4.98l1-0.19,L470.66,381.85z"/>
		<path d="M477.02,380.25l-0.96,0.27l-0.15-0.52c-0.24,0.55-0.61,0.79-1.13,0.94c-0.5,0.14-0.97,0.1-1.32-0.1,c-0.61-0.35-0.91-1.11-1.13-1.91s-0.37-1.61-0.04-2.22c0.19-0.35,0.56-0.63,1.06-0.77c0.52-0.15,0.96-0.14,1.44,0.2l-0.7-2.47,l0.98-0.28L477.02,380.25z M473.32,378.66c0.24,0.87,0.58,1.58,1.47,1.33c0.9-0.25,0.81-1.04,0.56-1.91,c-0.24-0.87-0.58-1.59-1.48-1.34C472.99,377,473.08,377.79,473.32,378.66z"/>
		<path d="M483.96,372.32c0.52,0.17,0.83,0.55,1.08,1.07l1.4,2.92l-0.92,0.44l-1.34-2.79,c-0.34-0.71-0.9-0.82-1.41-0.58s-0.79,0.77-0.46,1.47l1.34,2.79l-0.92,0.44l-2.2-4.57l0.9-0.43l0.22,0.46,c0.15-0.49,0.51-0.88,0.97-1.1C483.11,372.22,483.57,372.19,483.96,372.32z"/>
		<path d="M488.2,372.73c0.47,0.75,1.13,0.96,1.85,0.52c0.48-0.3,0.65-0.6,0.77-1.09l0.87,0.17,c-0.16,0.72-0.44,1.21-1.19,1.68c-1.08,0.67-2.29,0.64-3.31-0.99c-0.88-1.4-0.66-2.64,0.45-3.33c1.15-0.72,2.34-0.31,3.13,0.94,l0.23,0.36L488.2,372.73z M489.27,370.41c-0.35-0.23-0.78-0.27-1.19-0.01c-0.42,0.26-0.57,0.67-0.52,1.08,c0.03,0.24,0.1,0.39,0.26,0.65l1.93-1.21C489.57,370.67,489.47,370.54,489.27,370.41z"/>
		<path d="M493.35,369.25c0.54,0.69,1.23,0.83,1.89,0.31c0.45-0.35,0.58-0.66,0.64-1.17l0.89,0.08,c-0.09,0.73-0.3,1.25-1,1.8c-1,0.79-2.2,0.89-3.4-0.63c-1.02-1.29-0.94-2.56,0.08-3.36c1.07-0.84,2.29-0.56,3.21,0.6l0.27,0.34,L493.35,369.25z M494.16,366.83c-0.37-0.19-0.8-0.18-1.18,0.12c-0.38,0.3-0.5,0.72-0.4,1.13c0.06,0.23,0.14,0.37,0.33,0.62,l1.79-1.41C494.49,367.05,494.38,366.94,494.16,366.83z"/>
		<path d="M500.45,359.37c0.54-0.02,0.99,0.21,1.4,0.6l2.33,2.25l-0.71,0.73l-2.22-2.15,c-0.57-0.55-1.13-0.46-1.52-0.05c-0.38,0.4-0.48,1,0.05,1.51l2.26,2.18l-0.71,0.73l-2.23-2.15c-0.57-0.55-1.13-0.46-1.52-0.05,c-0.4,0.41-0.48,1,0.08,1.54l2.23,2.14l-0.71,0.74l-3.65-3.52l0.69-0.72l0.37,0.35c-0.03-0.5,0.17-1,0.53-1.37,c0.44-0.45,0.96-0.63,1.53-0.52c-0.08-0.64,0.11-1.18,0.57-1.66C499.59,359.6,500.04,359.39,500.45,359.37z"/>
		<path d="M502.43,356.14l0.59-0.78l0.62,0.47l-0.59,0.78l2.26,1.7c0.3,0.23,0.58,0.21,0.81-0.09l0.25-0.34l0.7,0.52,l-0.37,0.49c-0.57,0.76-1.37,0.71-1.96,0.26l-2.3-1.73l-0.35,0.46l-0.62-0.47l0.35-0.46l-1.16-0.87l0.61-0.82L502.43,356.14z"/>
		<path d="M508.99,348.67l2.99,1.6l-0.47,0.88l-0.41-0.22c0.15,0.5,0.12,0.88-0.18,1.44,c-0.3,0.56-0.63,0.86-1.05,0.98c-0.36,0.1-0.77,0.04-1.15-0.16c-0.75-0.4-1.01-1.21-0.46-2.24l0.63-1.17l-0.32-0.17,c-0.57-0.31-0.98-0.17-1.36,0.53c-0.25,0.48-0.26,0.79-0.08,1.21l-0.87,0.28c-0.27-0.68-0.2-1.2,0.2-1.94,C507.13,348.45,507.99,348.13,508.99,348.67z M508.97,351.14c-0.29,0.55-0.21,0.94,0.21,1.17c0.41,0.22,0.77,0.08,1.09-0.51,c0.17-0.32,0.27-0.59,0.18-0.95c-0.06-0.21-0.24-0.38-0.56-0.55l-0.36-0.19L508.97,351.14z"/>
		<path d="M508.16,343.43l-0.17,0.41c-0.15,0.35-0.02,0.6,0.33,0.75l0.54,0.23l0.39-0.93l0.72,0.3l-0.39,0.93,l3.95,1.67l-0.4,0.94l-3.95-1.67l-0.23,0.54l-0.72-0.3l0.23-0.54l-0.59-0.25c-0.67-0.28-1.13-0.95-0.76-1.83l0.25-0.59,L508.16,343.43z"/>
	</g>
</g>
<g className="tcpNummersWit">
	<path d="M228,404.5a7.55,7.55,0,0,1,.28-2.07,7.69,7.69,0,0,1,2-3.45,7.84,7.84,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,7.94,7.94,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.58,8.58,0,0,1,1.87.78,7.83,7.83,0,0,1,3.59,4.67,8.07,8.07,0,0,1,0,4.14,8,8,0,0,1-.79,1.87A7.84,7.84,0,0,1,241.3,410a7.76,7.76,0,0,1-1.58,1.22,8,8,0,0,1-1.87.79,7.84,7.84,0,0,1-2.07.28,7.55,7.55,0,0,1-2.07-.29,8.12,8.12,0,0,1-1.87-.78,7.49,7.49,0,0,1-1.58-1.22,7.84,7.84,0,0,1-1.22-1.58,7.94,7.94,0,0,1-.78-1.87A7.44,7.44,0,0,1,228,404.5Zm5.37,2.09v1.53h5.15v-1.53H237v-5.83h-1.56l-2.16.76v1.58l1.86-.56v4Z"/>
	<path d="M120.69,323.94a7.84,7.84,0,0,1,.28-2.07,8,8,0,0,1,.79-1.87,7.81,7.81,0,0,1,6.74-3.87,8,8,0,0,1,2.07.28,8.07,8.07,0,0,1,1.86.79,7.75,7.75,0,0,1,2.81,2.8,7.63,7.63,0,0,1,.78,1.86,7.83,7.83,0,0,1,0,4.15,7.63,7.63,0,0,1-.78,1.86,7.68,7.68,0,0,1-2.81,2.81,8.07,8.07,0,0,1-1.86.79,7.83,7.83,0,0,1-2.07.27,7.44,7.44,0,0,1-2.07-.28,8,8,0,0,1-1.87-.79,7.45,7.45,0,0,1-1.58-1.21,7.82,7.82,0,0,1-1.22-1.59A8.07,8.07,0,0,1,121,326,8,8,0,0,1,120.69,323.94Zm7.73-3.85a4.82,4.82,0,0,0-2.2.52v1.58a4.39,4.39,0,0,1,1.88-.55q1,0,1,.78a1.54,1.54,0,0,1-.19.7,5.72,5.72,0,0,1-.57.85c-.26.31-.57.66-1,1l-1.31,1.32v1.22h5.21V326h-1.77c-.36,0-.73,0-1.12.05v0c.44-.41.82-.78,1.15-1.11a7.46,7.46,0,0,0,.83-.94,3.86,3.86,0,0,0,.48-.88,2.7,2.7,0,0,0,.17-.94,1.88,1.88,0,0,0-.21-.91,2,2,0,0,0-.55-.66,2.55,2.55,0,0,0-.83-.39A3.77,3.77,0,0,0,128.42,320.09Z"/>
	<path d="M181,243.14a7.55,7.55,0,0,1,.29-2.07,7.69,7.69,0,0,1,2-3.45,7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,7.78,7.78,0,0,1,4.67,3.58,8,8,0,0,1,.79,1.87,8.07,8.07,0,0,1,0,4.14,8,8,0,0,1-.79,1.87,7.84,7.84,0,0,1-1.22,1.58,8.13,8.13,0,0,1-1.58,1.22,8,8,0,0,1-1.87.79,7.84,7.84,0,0,1-2.07.28,7.45,7.45,0,0,1-2.07-.29,7.94,7.94,0,0,1-1.87-.78,7.83,7.83,0,0,1-2.8-2.8,7.94,7.94,0,0,1-.78-1.87A7.45,7.45,0,0,1,181,243.14Zm10.49-1.87a1.69,1.69,0,0,0-.72-1.44,3.34,3.34,0,0,0-2.05-.54,6.18,6.18,0,0,0-1.9.31v1.55a5.29,5.29,0,0,1,.8-.24,4.8,4.8,0,0,1,.85-.08,1.61,1.61,0,0,1,.8.17.55.55,0,0,1,.3.52.61.61,0,0,1-.1.36.64.64,0,0,1-.27.24,1.49,1.49,0,0,1-.39.13,2.42,2.42,0,0,1-.47.05h-.72v1.46h.95a3.23,3.23,0,0,1,.46,0,1.08,1.08,0,0,1,.37.13.62.62,0,0,1,.24.24.68.68,0,0,1,.09.37.71.71,0,0,1-.33.62,1.69,1.69,0,0,1-1,.23,4.13,4.13,0,0,1-.95-.1,3.52,3.52,0,0,1-.75-.23v1.56a4.91,4.91,0,0,0,1.79.31,3.79,3.79,0,0,0,2.35-.62,2,2,0,0,0,.8-1.7,1.62,1.62,0,0,0-.33-1.06,1.66,1.66,0,0,0-.85-.56v0a1.69,1.69,0,0,0,.77-.69A1.92,1.92,0,0,0,191.46,241.27Z"/>
	<path d="M471.21,242.42a8,8,0,0,1,.28-2.07,8.07,8.07,0,0,1,.79-1.86,7.75,7.75,0,0,1,2.8-2.81,7.54,7.54,0,0,1,1.87-.78,7.44,7.44,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.28,8.07,8.07,0,0,1,1.86.79,7.51,7.51,0,0,1,1.59,1.21,8.19,8.19,0,0,1,1.22,1.59,7.63,7.63,0,0,1,.78,1.86,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.84,7.84,0,0,1-1.22,1.58,7.55,7.55,0,0,1-1.59,1.22,7.66,7.66,0,0,1-1.86.79,7.44,7.44,0,0,1-2.07.28A7.84,7.84,0,0,1,477,250a8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,471.21,242.42Zm8.05-1a3.26,3.26,0,0,0-.8.09,3,3,0,0,0-.58.21l0,0a1.86,1.86,0,0,1,.59-1.22,1.89,1.89,0,0,1,1.2-.38,4.34,4.34,0,0,1,.86.09,5.41,5.41,0,0,1,.78.21V238.9a3.29,3.29,0,0,0-.72-.22,4.64,4.64,0,0,0-1-.11,3.61,3.61,0,0,0-1.61.33,2.93,2.93,0,0,0-1.1.89,3.69,3.69,0,0,0-.64,1.32,6.54,6.54,0,0,0-.2,1.62,4.64,4.64,0,0,0,.22,1.52,3.11,3.11,0,0,0,.62,1.09,2.7,2.7,0,0,0,.93.65,3.19,3.19,0,0,0,1.16.21,3.31,3.31,0,0,0,1.13-.18,2.5,2.5,0,0,0,.87-.51,2.21,2.21,0,0,0,.55-.79,2.66,2.66,0,0,0,.2-1,2.35,2.35,0,0,0-.18-.94,2.08,2.08,0,0,0-.5-.7,2.12,2.12,0,0,0-.77-.45A2.86,2.86,0,0,0,479.26,241.44Zm-.31,3.22a.91.91,0,0,1-.43-.1,1,1,0,0,1-.36-.3,1.53,1.53,0,0,1-.23-.48,2.11,2.11,0,0,1-.09-.64,2.62,2.62,0,0,1,.49-.19,1.93,1.93,0,0,1,.53-.07,1.06,1.06,0,0,1,.75.24.79.79,0,0,1,.25.6.94.94,0,0,1-.24.7A.93.93,0,0,1,479,244.66Z"/>
	<path d="M535,323.94a7.45,7.45,0,0,1,.29-2.07,7.8,7.8,0,0,1,5.45-5.46,7.44,7.44,0,0,1,2.07-.28,8,8,0,0,1,2.07.28,8.14,8.14,0,0,1,1.87.79,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15,8.07,8.07,0,0,1-.79,1.86,7.75,7.75,0,0,1-2.8,2.81,8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.44,7.44,0,0,1-2.07-.28,8,8,0,0,1-1.87-.79,7.45,7.45,0,0,1-1.58-1.21,7.82,7.82,0,0,1-1.22-1.59,7.63,7.63,0,0,1-.78-1.86A7.55,7.55,0,0,1,535,323.94Zm7.61-2.1h2.68v-1.53H541.1l-.58,4.11a6.82,6.82,0,0,1,1.18-.11,4.5,4.5,0,0,1,.8.06,1.79,1.79,0,0,1,.51.17.63.63,0,0,1,.28.28.75.75,0,0,1,.09.37.83.83,0,0,1-.11.43.92.92,0,0,1-.31.31,1.17,1.17,0,0,1-.43.17,2.24,2.24,0,0,1-.52.06,4.44,4.44,0,0,1-.84-.08,5.53,5.53,0,0,1-.82-.26v1.58a4.41,4.41,0,0,0,.77.23,5.16,5.16,0,0,0,1,.09,4.4,4.4,0,0,0,1.31-.19,3,3,0,0,0,1-.53,2.37,2.37,0,0,0,.63-.81,2.45,2.45,0,0,0,.22-1,2.43,2.43,0,0,0-.15-.86,1.71,1.71,0,0,0-.46-.69,2.23,2.23,0,0,0-.83-.47,3.83,3.83,0,0,0-1.21-.17h-.21Z"/>
	<path d="M429,404.67a8,8,0,0,1,.28-2.07,8.07,8.07,0,0,1,.79-1.86,7.55,7.55,0,0,1,1.22-1.59,7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.07,8.07,0,0,1,1.86.79,7.77,7.77,0,0,1,1.59,1.21,7.9,7.9,0,0,1,1.22,1.59,8.07,8.07,0,0,1,.79,1.86,8.07,8.07,0,0,1,0,4.14,8,8,0,0,1-.79,1.87,7.75,7.75,0,0,1-2.81,2.8,7.66,7.66,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,429,404.67Zm8.53-3.63H435.7l-1.81,4.67v1.17H437v1.41h1.78v-1.41h.87v-1.5h-.87v-1.09H437v1.09h-.27a9.66,9.66,0,0,0-1,.05v0a13.16,13.16,0,0,0,.71-1.52Z"/>
</g>

<g className="tcpNummersKleur">
	<path d="M129.69,443.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,438.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,443.82Zm5.37,2.09v1.53h5.15v-1.53h-1.54v-5.83h-1.56l-2.16.76v1.58l1.85-.56v4.05Z"/>
	<path d="M129.69,461.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,456.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,461.82Zm7.73-3.85a5,5,0,0,0-2.2.52v1.58a4.5,4.5,0,0,1,1.88-.54c.68,0,1,.26,1,.77a1.51,1.51,0,0,1-.19.7,4.84,4.84,0,0,1-.57.85,13.66,13.66,0,0,1-1,1c-.38.39-.81.83-1.31,1.32v1.22h5.21v-1.53h-1.77a10.94,10.94,0,0,0-1.12.06v0c.44-.41.82-.78,1.15-1.11a7.5,7.5,0,0,0,.83-.95,3.66,3.66,0,0,0,.48-.88,2.62,2.62,0,0,0,.17-.94,1.94,1.94,0,0,0-.21-.91,1.91,1.91,0,0,0-.55-.65,2.37,2.37,0,0,0-.83-.4A4.21,4.21,0,0,0,137.42,458Z"/>
	<path d="M129.69,479.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,474.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,479.82ZM140.18,478a1.71,1.71,0,0,0-.72-1.44,3.36,3.36,0,0,0-2.05-.54,6.14,6.14,0,0,0-1.9.31v1.55a5,5,0,0,1,.8-.24,4.69,4.69,0,0,1,.84-.08,1.67,1.67,0,0,1,.8.17.54.54,0,0,1,.31.52.59.59,0,0,1-.1.36.78.78,0,0,1-.27.24,1.57,1.57,0,0,1-.4.13,2.34,2.34,0,0,1-.47.05h-.71v1.46h.95a3.33,3.33,0,0,1,.46,0,1.35,1.35,0,0,1,.37.13.69.69,0,0,1,.24.24.68.68,0,0,1,.09.37.71.71,0,0,1-.33.62,1.7,1.7,0,0,1-1,.23,4.69,4.69,0,0,1-.94-.09,4.15,4.15,0,0,1-.75-.24v1.56a4.84,4.84,0,0,0,1.79.31,3.72,3.72,0,0,0,2.35-.62,2,2,0,0,0,.8-1.7,1.62,1.62,0,0,0-.33-1.06,1.7,1.7,0,0,0-.85-.56v0a1.63,1.63,0,0,0,.77-.69A1.92,1.92,0,0,0,140.18,478Z"/>
	<path d="M129.69,533.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,528.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,533.82Zm8.05-1a3.26,3.26,0,0,0-.8.09,2,2,0,0,0-.58.21l0,0a1.86,1.86,0,0,1,.59-1.22,1.89,1.89,0,0,1,1.2-.38,4.41,4.41,0,0,1,.86.09,5.41,5.41,0,0,1,.78.21V530.3a3.29,3.29,0,0,0-.72-.22,4.71,4.71,0,0,0-1-.11,3.61,3.61,0,0,0-1.61.33,2.94,2.94,0,0,0-1.11.89,3.85,3.85,0,0,0-.63,1.32,6.54,6.54,0,0,0-.2,1.62,4.64,4.64,0,0,0,.22,1.52,3.07,3.07,0,0,0,.62,1.08,2.6,2.6,0,0,0,.93.66,3.19,3.19,0,0,0,1.16.21,3.31,3.31,0,0,0,1.13-.18,2.5,2.5,0,0,0,.87-.51,2.21,2.21,0,0,0,.55-.79,2.66,2.66,0,0,0,.2-1,2.35,2.35,0,0,0-.18-.94,2.12,2.12,0,0,0-.5-.71,2.25,2.25,0,0,0-.77-.44A2.86,2.86,0,0,0,137.74,532.84Zm-.31,3.22a1,1,0,0,1-.44-.1,1.1,1.1,0,0,1-.35-.3,1.53,1.53,0,0,1-.23-.48,2.11,2.11,0,0,1-.09-.64,2.62,2.62,0,0,1,.49-.19,1.93,1.93,0,0,1,.53-.07,1,1,0,0,1,.74.24.78.78,0,0,1,.26.6.91.91,0,0,1-.25.7A.89.89,0,0,1,137.43,536.06Z"/>
	<path d="M129.69,515.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,510.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,515.82Zm7.6-2.1H140v-1.53h-4.19l-.58,4.12a6.13,6.13,0,0,1,1.19-.11,4.3,4.3,0,0,1,.79.06,1.78,1.78,0,0,1,.52.16.75.75,0,0,1,.28.28.79.79,0,0,1,.09.38.77.77,0,0,1-.12.43.86.86,0,0,1-.3.3,1.5,1.5,0,0,1-.43.18,2.24,2.24,0,0,1-.52.06,3.89,3.89,0,0,1-.85-.09,4,4,0,0,1-.81-.26v1.58a3.59,3.59,0,0,0,.76.23,5.26,5.26,0,0,0,1,.09,4.15,4.15,0,0,0,1.31-.19,3,3,0,0,0,1-.52,2.31,2.31,0,0,0,.62-.81,2.38,2.38,0,0,0,.22-1,2.61,2.61,0,0,0-.14-.85,1.74,1.74,0,0,0-.47-.7,2.23,2.23,0,0,0-.82-.46,3.59,3.59,0,0,0-1.21-.18h-.21Z"/>
	<path d="M129.69,497.82a8,8,0,0,1,.28-2.07,8.14,8.14,0,0,1,.79-1.87A7.76,7.76,0,0,1,132,492.3a7.84,7.84,0,0,1,1.58-1.22,8,8,0,0,1,1.87-.79,7.83,7.83,0,0,1,2.07-.27,7.55,7.55,0,0,1,2.07.28,8.5,8.5,0,0,1,1.86.78,7.75,7.75,0,0,1,2.81,2.8,7.7,7.7,0,0,1,.78,1.87,7.79,7.79,0,0,1,0,4.14,7.54,7.54,0,0,1-.78,1.87,7.75,7.75,0,0,1-2.81,2.8,8.07,8.07,0,0,1-1.86.79,7.79,7.79,0,0,1-4.14,0,8,8,0,0,1-1.87-.79,7.83,7.83,0,0,1-2.8-2.8,8,8,0,0,1-.79-1.87A7.84,7.84,0,0,1,129.69,497.82Zm8.53-3.63h-1.87l-1.82,4.67V500h3.09v1.41h1.77V500h.88v-1.5h-.88v-1.09h-1.77v1.09h-.27a9.66,9.66,0,0,0-1,.05v0a13.16,13.16,0,0,0,.71-1.52Z"/>
</g>
<g className="tekst">
	<text transform="translate(350 447.79)">
		<tspan x="-21" y="0">We stellen hoge eisen aan de kwaliteit van complexere operaties.</tspan>
		<tspan x="-46" y="18">Daarvoor zijn meer (dure) apparatuur en mensen nodig.</tspan>
		<tspan x="-3" y="36">De kwaliteit van deze zorg neemt toe, maar de betaalbaarheid neemt af.</tspan>
		<tspan x="9" y="54">We voeren complexe operaties alleen nog uit op een beperkt aantal plekken.</tspan>
		<tspan x="-74" y="72">Mensen moeten langer reizen voor deze zorg.</tspan>
		<tspan x="3" y="90">De toegankelijkheid van deze zorg neemt af, de betaalbaarheid neemt toe.</tspan>
	</text>
</g>
</svg>
</div>
);

export default SpanningDoelstellingen;
