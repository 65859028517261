import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "visualisatie",
  naam: "aantal-bevallingen",
  titel: "Hoeveel bevallingen vinden plaats in de eerste lijn en hoeveel in de tweede lijn?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'wat-is-geboortezorg',
    'uitgaven-geboortezorg',
  ]
}
