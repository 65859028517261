// a reducer takes in two things:
// 1. the action (info about what happened)
// 2. copy of current state

function pages(state = [], action) {
  //console.log("The page will change");
  return state;
}

export default pages;
