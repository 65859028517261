import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';
import { formatLocale } from "d3";

const Geneesmiddelentekort = () => {

	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: ".",
		"grouping": [3],
		"comma": true,
	});

	const margin = {top: 90, right: 0, bottom: 90, left: 0};
	const formatY = value => localeFormatter.format(",.0f")(value);
	const formatValueToolTip = d => (<span>{d.category}<br/>{localeFormatter.format(",.0f")(d.data.value)}</span>);

	const data = [
		{
		"year": "2004",
		"definitief": 46,
		"tijdelijk": 45
		},
		{
		"year": "2005",
		"definitief": 101,
		"tijdelijk": 78
		},
		{
		"year": "2006",
		"definitief": 79,
		"tijdelijk": 71
		},
		{
		"year": "2007",
		"definitief": 88,
		"tijdelijk": 74
		},
		{
		"year": "2008",
		"definitief": 102,
		"tijdelijk": 88
		},
		{
		"year": "2009",
		"definitief": 115,
		"tijdelijk": 102
		},
		{
		"year": "2010",
		"definitief": 99,
		"tijdelijk": 75
		},
		{
		"year": "2011",
		"definitief": 99,
		"tijdelijk": 143
		},
		{
		"year": "2012",
		"definitief": 89,
		"tijdelijk": 217
		},
		{
		"year": "2013",
		"definitief": 119,
		"tijdelijk": 307
		},
		{
		"year": "2014",
		"definitief": 140,
		"tijdelijk": 391
		},
		{
		"year": "2015",
		"definitief": 134,
		"tijdelijk": 401
		},
		{
		"year": "2016",
		"definitief": 204,
		"tijdelijk": 506
		},
		{
		"year": "2017",
		"definitief": 117,
		"tijdelijk": 615
		},
		{
		"year": "2018",
		"definitief": 128,
		"tijdelijk": 641
		}
	];

	return (
		<div className="vis-geneesmiddelentekort">

		<ColumnChart
			margin={margin}
			colorBars={["#333","#ddd"]}
			colorAxis="#333"
			labelY="x 1000"
			height={450}
			domain={[0, 769]}
			columnPadding={0.25}
			formatY={formatY}
			formatValueToolTip={formatValueToolTip}
			data={data}
			stacked={true}
		/>

		<div className="vis-credit">
			<p>Aantal geneesmiddelen waarvan door apothekers vrijwillig bij KNMP Farmanco de onverkrijgbaarheid is gemeld.</p>
			<p>Bron: KNMP Farmanco.</p>
		</div>

	</div>
	);
}
export default Geneesmiddelentekort;
