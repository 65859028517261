import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const HoeveelJongeren = () => {
    const margin = { top: 60, right: 30, bottom: 90, left: 45 };

    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "comma": true,
    });

    const formatY = (val) => localeFormatter.format(',.0f')(val / 1);

    const formatValueTooltip = (val) => `${localeFormatter.format(',.0f')(val)} jongeren`

    const data = [
        {
            name: "aantal-jongeren",
            values: [
                { x: 2016, y: 2230},
                { x: 2017, y: 2250},
                { x: 2018, y: 2110},
                { x: 2019, y: 2110},
                { x: 2020, y: 1810}
            ]
        },
    ];

    return (
        <div className="vis-hoeveel-jongeren">
            <LineChart
                margin={margin}
                colorLines={["#000"]}
                colorAxis="#333"
                labelY="aantal"
                domainY={[1400, 2800]}
                data={data}
                height={400}
                formatY={formatY}
                formatValueTooltip={formatValueTooltip}
            />

            <div className="vis-credit">
                <p>Het aantal jongeren in JeugdzorgPlus in de periode 2016-2020</p>
                <p>Bron: Plaatsings- en uitstroomgegevens JeugdzorgPlus 2019, Jeugdzorg Nederland </p>
            </div>
        </div>
    );
};

export default HoeveelJongeren;
