import React from 'react';

import Tevredenheid from './visualizations/tevredenheid';
import Verzuim from './visualizations/verzuim';
import AantalCuratiefLangdurig from './visualizations/aantal-curatief-langdurig';
import ForensischAantal from './visualizations/forensisch-aantal';
import WerkendenAantal from "./visualizations/werkenden-aantal";
import TienBeroepen from "./visualizations/tien-beroepen";
import UitgavenCbs from "./visualizations/uitgaven-cbs";
import BegrotingZorg from "./visualizations/begroting-zorg";
import UitgavenGgz from "./visualizations/uitgaven-ggz";
import UitgavenCuratiefLangdurig from "./visualizations/uitgaven-curatief-langdurig";
import UitgavenProblemen from "./visualizations/uitgaven-problemen";
import GeldstromenWmo from "./visualizations/geldstromen-wmo";
import GeldstromenJw from "./visualizations/geldstromen-jeugdwet";
import GeldstromenZvw from "./visualizations/geldstromen-zvw";
import GeldstromenWlz from "./visualizations/geldstromen-wlz";
import GeldstromenWfz from "./visualizations/geldstromen-wfz";

const vissen = {
  tevredenheid: <Tevredenheid/>,
  verzuim: <Verzuim/>,
  aantal_curatief_langdurig: <AantalCuratiefLangdurig/>,
  forensisch_aantal: <ForensischAantal/>,
  werkenden_aantal: <WerkendenAantal/>,
  tien_beroepen: <TienBeroepen/>,
  uitgaven_cbs: <UitgavenCbs/>,
  begroting_zorg: <BegrotingZorg/>,
  uitgaven_ggz: <UitgavenGgz/>,
  uitgaven_curatief_langdurig: <UitgavenCuratiefLangdurig/>,
  uitgaven_problemen: <UitgavenProblemen/>,
  geldstromen_wmo: <GeldstromenWmo/>,
  geldstromen_jeugdwet: <GeldstromenJw/>,
  geldstromen_zvw: <GeldstromenZvw/>,
  geldstromen_wlz: <GeldstromenWlz/>,
  geldstromen_wfz: <GeldstromenWfz/>,
};

export default vissen;
