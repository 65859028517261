import React from 'react';

const Hoofdrolspelers = ({}) => {
	return (
		<div className="vis-hoofdrolspelers">
			<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin slice" viewBox="0 0 660 600">
					<path className="geldstromen" d="M348,567H81a18.05,18.05,0,0,1-18-18V489c0-9.9,3.09-25.49,6.87-34.64L212.13,109.64C215.91,100.49,227.1,93,237,93H423c9.9,0,21.09,7.49,24.87,16.64L590.13,454.36C593.91,463.51,597,479.1,597,489v60a18.05,18.05,0,0,1-18,18Z"/>

					<g className="kaders">
						<path d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
						<text x="330" y="99">Burgers</text>

						<path transform="translate(0 100)" d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
						<text transform="translate(0 100)" x="330" y="99">Rijk</text>

						<path transform="translate(0 200)" d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
						<text transform="translate(0 200)" x="330" y="90">Regionale</text>
						<text transform="translate(0 200)" x="330" y="110">netwerken</text>

						<path transform="translate(-125 310)" d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
						<text transform="translate(-125 310)" x="330" y="90">Vrijwilligers-</text>
						<text transform="translate(-125 310)" x="330" y="110">organisaties</text>

						<path transform="translate(125 310)" d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
						<text transform="translate(125 310)" x="330" y="98">Fibula</text>

						<path d="M513,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30c0,5.79-4.71,10.5-10.5,10.5H513z"/>
						<text x="580" y="525">Zorgaanbieders</text>
						<path className="linksonder" d="M15,547.5c-7.44,0-13.5-6.06-13.5-13.5v-30c0-7.44,6.06-13.5,13.5-13.5h132c7.44,0,13.5,6.06,13.5,13.5v30 c0,7.44-6.06,13.5-13.5,13.5H15z"/>
						<text x="80" y="525">Zorginkopers</text>
					</g>
			</svg>
		</div>
	)
}

export default Hoofdrolspelers;
