import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"dwingende-maatregelen",
  titel:"Welke (dwingende) maatregelen kan de overheid treffen?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links: [
    'wat-is-een-a-infectieziekte',
    'wie-doet-wat',
  ]
}
