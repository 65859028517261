import { COLOR_PURPLE } from "../../vocabulary";
import {
	palliatieveZorg,
	inkoopprocesGgz,
	quickGuide,
	oplossingenArbeidsmarkt
} from "./pages/index";

const pages = [
	{
		categorie: "basis",
		template: "specialvoorpagina",
		naam: "gidsen",
		titel: "Gidsen",
		kleur: COLOR_PURPLE,
		tekst: "In de gidsen van Zó werkt de zorg vind je een bondige weergave van één specifiek onderwerp in de zorg. De visualisaties en belangrijkste informatie uit de gidsen vind je hier.",
		links: [
			'gids-palliatieve-zorg',
			'gids-inkoopproces-ggz',
			'gids-quick-guide',
			'gids-oplossingen-arbeidsmarkt',
		]
	},

	...palliatieveZorg,
	...inkoopprocesGgz,
	...quickGuide,
	...oplossingenArbeidsmarkt
]

pages.map(pagina => {
	if (pagina.categorie === undefined) {
		pagina.categorie = 'gidsen'
	}

	return pagina;
});

export default pages;
