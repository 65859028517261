import React from 'react';

const Logo = () => (
	<svg viewBox="0 0 645 160" className="af-logo">
		<path id="beeldmerk" fill="#fff" d="M424.5,160h-160l1.4-11.2c0.1-0.8,2.5-19.6,13-38.6C293.4,83.9,316.1,70,344.5,70 c21,0,37.1-10,48.1-29.8c4-7.2,6.6-14.5,8.2-20.2H264.5V0h160l-1.4,11.2c-0.1,0.8-2.5,19.6-13,38.6C395.6,76.1,372.9,90,344.5,90 c-21,0-37.1,10-48.1,29.8c-4,7.2-6.6,14.5-8.2,20.2h136.3V160z"/>
		<path fill="#fff" d="M423.8,62.5c3-0.4,6.5-0.8,10.4-0.8c7,0,12,1.6,15.3,4.7c3.4,3.1,5.3,7.4,5.3,13.6c0,6.2-1.9,11.2-5.4,14.7 c-3.5,3.5-9.4,5.4-16.7,5.4c-3.5,0-6.4-0.2-8.8-0.4V62.5z M428.6,95.9c1.2,0.2,3,0.3,4.9,0.3c10.4,0,16.1-5.8,16.1-16 c0.1-8.9-5-14.6-15.3-14.6c-2.5,0-4.4,0.2-5.7,0.5V95.9z M480.6,82.1h-14.7v13.6h16.4v4.1h-21.2V62h20.4v4.1h-15.5V78h14.7V82.1z M497.9,96.9l20.9-30.6v-0.2h-19.2V62h25.5v3l-20.8,30.5v0.2h21.1v4.1h-27.6V96.9z M562.5,80.5c0,13-7.9,19.9-17.5,19.9 c-10,0-17-7.7-17-19.2c0-12,7.4-19.8,17.5-19.8C555.9,61.4,562.5,69.3,562.5,80.5z M533.2,81.1c0,8.1,4.4,15.3,12,15.3 c7.7,0,12.1-7.1,12.1-15.7c0-7.5-3.9-15.3-12-15.3C537.3,65.4,533.2,72.8,533.2,81.1z M568.8,62.5c2.5-0.5,6-0.8,9.4-0.8 c5.2,0,8.6,1,10.9,3.1c1.9,1.7,3,4.3,3,7.2c0,5-3.1,8.3-7.1,9.6v0.2c2.9,1,4.6,3.7,5.5,7.6c1.2,5.3,2.1,8.9,2.9,10.4h-5 c-0.6-1.1-1.5-4.3-2.5-9c-1.1-5.2-3.1-7.2-7.6-7.3h-4.6v16.4h-4.9V62.5z M573.7,79.7h5c5.2,0,8.5-2.9,8.5-7.2c0-4.9-3.5-7-8.7-7.1 c-2.4,0-4,0.2-4.8,0.4V79.7z M627.9,98.1c-2.2,0.8-6.5,2.1-11.6,2.1c-5.7,0-10.4-1.5-14.1-5c-3.2-3.1-5.3-8.2-5.3-14.1 c0.1-11.3,7.8-19.5,20.4-19.5c4.4,0,7.8,1,9.4,1.7l-1.2,4c-2-0.9-4.5-1.6-8.3-1.6c-9.2,0-15.2,5.7-15.2,15.2 c0,9.6,5.8,15.2,14.6,15.2c3.2,0,5.4-0.4,6.5-1V83.9h-7.7v-3.9h12.4V98.1z"/>
		<path fill="#fff" d="M24,96.9L45,66.3v-0.2H25.8V62h25.5v3L30.5,95.5v0.2h21.1v4.1H24V96.9z M88.7,80.5c0,13-7.9,19.9-17.5,19.9 c-10,0-17-7.7-17-19.2c0-12,7.4-19.8,17.5-19.8C82,61.4,88.7,69.3,88.7,80.5z M59.4,81.1c0,8.1,4.4,15.3,12,15.3 c7.7,0,12.1-7.1,12.1-15.7c0-7.5-3.9-15.3-12-15.3C63.4,65.4,59.4,72.8,59.4,81.1z M111,99.8L101.4,62h5.2l4.5,19.1 c1.1,4.7,2.1,9.4,2.8,13h0.1c0.6-3.8,1.8-8.2,3.1-13.1l5-19h5.1l4.6,19.2c1.1,4.5,2.1,9,2.6,12.9h0.1c0.8-4.1,1.8-8.3,3-13l5-19h5 l-10.7,37.7h-5.1L127,80.1c-1.2-4.8-2-8.5-2.5-12.3h-0.1c-0.7,3.8-1.5,7.4-2.9,12.3l-5.4,19.7H111z M171.8,82.1h-14.7v13.6h16.4v4.1 h-21.2V62h20.4v4.1h-15.5V78h14.7V82.1z M179.8,62.5c2.5-0.5,6-0.8,9.4-0.8c5.2,0,8.6,1,10.9,3.1c1.9,1.7,3,4.3,3,7.2 c0,5-3.1,8.3-7.1,9.6v0.2c2.9,1,4.6,3.7,5.5,7.6c1.2,5.3,2.1,8.9,2.9,10.4h-5c-0.6-1.1-1.5-4.3-2.5-9c-1.1-5.2-3.1-7.2-7.6-7.3h-4.6 v16.4h-4.9V62.5z M184.6,79.7h5c5.2,0,8.5-2.9,8.5-7.2c0-4.9-3.5-7-8.7-7.1c-2.4,0-4,0.2-4.8,0.4V79.7z M209.9,62h4.9v18.2h0.2 c1-1.5,2-2.8,3-4L229.4,62h6l-13.7,16l14.7,21.7h-5.8l-12.4-18.5l-3.6,4.1v14.4h-4.9V62z M248.6,66.2h-11.5V62h27.9v4.1h-11.5v33.6 h-4.9V66.2z"/>
		<rect x="3.5" y="40" fill="#fff" width="261" height="10"/>
		<rect x="3.5" y="110" fill="#fff" width="261" height="10"/>
		<rect x="424.5" y="40" fill="#fff" width="216" height="10"/>
		<rect x="424.5" y="110" fill="#fff" width="216" height="10"/>
	</svg>
);

export default Logo;
