import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const VisualisatieGeslacht = () => {
    const data = [
        {
            "label": "Vrouw",
            "value": 57,
        },
        {
            "label": "Man",
            "value": 43,
        },
    ];

    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "comma": true,
    });

    const formatLabel = item => {
        return `${item.label}`
    }


    const formatTooltip = item => {
        return `${item.label} (${localeFormatter.format(',.0f')(item.percentage)}%)`
    }

    return (
        <Donut
            data={data}
            height={200}
            fontFamily="din-regular"
            fontSize="15px"
            formatLabel={formatLabel}
            formatTooltip={formatTooltip}
            formatTotal={() => {}}
            unit="duizend"
            animation={true}
        />
    )
}

const VisualisatieDuurtijd = () => {
    const data = [
        {
            "label": "Tussen 1 en 5 jaar",
            "value": 38,
        },
        {
            "label": "Langer dan 5 jaar",
            "value": 45,
        },
        {
            "label": "Korter dan 1 jaar",
            "value": 17,
        },
    ];

    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "comma": true,
    });

    const formatLabel = item => {
        return `${item.label}`
    }


    const formatTooltip = item => {
        return `${item.label} (${localeFormatter.format(',.0f')(item.percentage)}%)`
    }

    return (
        <Donut
            data={data}
            height={200}
            fontFamily="din-regular"
            fontSize="15px"
            formatLabel={formatLabel}
            formatTooltip={formatTooltip}
            formatTotal={() => {}}
            unit="duizend"
            animation={true}
        />
    )
}

const AantalBijstandsuitkering = () => {
    return (
        <div className="vis-aantal-bijstandsuitkering">
            <VisualisatieGeslacht />
            <VisualisatieDuurtijd />

            <div className="clear"></div>

            <div className="vis-total">
                <p className="groot">428</p>
                <p>duizend</p>
                <p>mensen</p>
            </div>

            <div className="clear"></div>

            <div className="vis-credit">
                <p>Percentage mannen en vrouwen dat een bijstandsuitkering ontvangt, uitgesplitst naar percentage mannen en vrouwen en naar percentage uitkeringsduur in december 2020.</p>
                <p>Bron: CBS, Statline 2021</p>
            </div>

            <div className="clear"></div>
        </div>
    );
}

export default AantalBijstandsuitkering;
