import React from 'react';
import { Link } from 'react-router-dom';

const MarktenZorgverzekering = () => (

<div className="vis-markten-zorgverzekering svg-container">
<svg
xmlns="http://www.w3.org/2000/svg"
preserveAspectRatio="xMidYMin meet"
viewBox="0 0 660 600">

<path className="geldstromen" d="M348,567H81a18.05,18.05,0,0,1-18-18V489c0-9.9,3.09-25.49,6.87-34.64L212.13,109.64C215.91,100.49,227.1,93,237,93H423c9.9,0,21.09,7.49,24.87,16.64L590.13,454.36C593.91,463.51,597,479.1,597,489v60a18.05,18.05,0,0,1-18,18Z"/>

<g className="kaders">
	<rect x="259.5" y="67.5" width="141" height="51" rx="10.5" ry="10.5"/>
	<rect x="4.5" y="490.5" width="141" height="51" rx="13.5" ry="13.5"/>
	<rect x="514.5" y="493.5" width="141" height="51" rx="10.5" ry="10.5"/>
	<text x="330" y="99">Burgers</text>
	<text x="585" y="524">Zorgaanbieders</text>
	<text x="75" y="524">Zorginkopers</text>
</g>

<Link to={{ pathname: '/zorgverzekering/rol-zorgverzekeraar', hash: '#Zorginkoopmarkt' }} >
	<g className="markt">
		<rect x="241.5" y="541.5" width="177" height="51" rx="10.5" ry="10.5"/>
		<text x="330" y="573">
			<tspan x="330">Zorginkoopmarkt</tspan>
		</text>
	</g>
</Link>
<Link to={{ pathname: '/zorgverzekering/rol-zorgverzekeraar', hash: '#Zorgverleningsmarkt' }} >
	<g className="markt">
		<rect x="436.5" y="277.5" width="177" height="51" rx="10.5" ry="10.5"/>
		<text x="525" y="301">
			<tspan x="525">Zorgverlenings-</tspan>
			<tspan x="525" dy="18">markt</tspan>
		</text>
	</g>
</Link>
<Link to={{ pathname: '/zorgverzekering/rol-zorgverzekeraar', hash: '#Zorgverzekeringsmarkt' }} >
	<g className="markt">
		<rect x="46.5" y="277.5" width="177" height="51" rx="10.5" ry="10.5"/>
		<text x="135" y="301">
			<tspan x="135">Zorgverzekerings-</tspan>
			<tspan x="135" dy="18">markt</tspan>
		</text>
	</g>
</Link>

</svg>
</div>
);

export default MarktenZorgverzekering;

