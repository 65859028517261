import React from 'react';

import Gezondheidsrisicos from './visualizations/gezondheidsrisicos.js';
import SpanningDoelstellingen from './visualizations/spanning-doelstellingen.js';
import HoofdrolspelersZorgverzekering from './visualizations/hoofdrolspelers-zorgverzekering.js';
import AndereSpelers from '../basis/visualizations/andere-spelers.js';
import MarktenZorgverzekering from './visualizations/markten-zorgverzekering.js';
import Marktaandeel from './visualizations/marktaandeel.js';
import Zorgkantoorregios from './visualizations/zorgkantoorregios.js';
import EurosVolwassene from '../basis/visualizations/euros-volwassene.js';
import ZorgkostenLeeftijdsgroep from './visualizations/zorgkosten-leeftijdsgroep.js';
import BatenLastenZorgverzekeraars from './visualizations/baten-lasten-zorgverzekeraars.js';
import InkomstenPerPremiebetaler from './visualizations/inkomsten-per-premiebetaler.js';
import BedrijfskostenZorgverzekeraars from './visualizations/bedrijfskosten-zorgverzekeraars.js';
import GeldstromenZvw from  '../basis/visualizations/geldstromen-zvw.js';
import GeldstromenWlz from  '../basis/visualizations/geldstromen-wlz.js';


const vissen = {
	gezondheidsrisicos: <Gezondheidsrisicos />,
	spanning_doelstellingen: <SpanningDoelstellingen />,
	hoofdrolspelers_zorgverzekering: <HoofdrolspelersZorgverzekering />,
	andere_spelers: <AndereSpelers />,
	markten_zorgverzekering: <MarktenZorgverzekering />,
	marktaandeel: <Marktaandeel />,
	zorgkantoorregios: <Zorgkantoorregios />,
	euros_volwassene: <EurosVolwassene />,
	zorgkosten_leeftijdsgroep: <ZorgkostenLeeftijdsgroep />,
	baten_lasten_zorgverzekeraars: <BatenLastenZorgverzekeraars />,
	inkomsten_per_premiebetaler: <InkomstenPerPremiebetaler />,
	bedrijfskosten_zorgverzekeraars: <BedrijfskostenZorgverzekeraars />,
	geldstromen_zvw: <GeldstromenZvw />,
	geldstromen_wlz: <GeldstromenWlz />,
};

export default vissen;