export default {"tekst": "Hoe werkt de Jeugdwet en wat is relevant voor de huisarts?","items": [{
    "tekst": "Uitleg wet",
    "items": [{
        "tekst": "Deze wet regelt de gemeentelijke verantwoordelijkheid voor hulp en zorg aan kinderen, jongeren en hun opvoeders."
    }, {
        "tekst": "Deze wet betreft de hulp bij opgroei- en opvoedproblemen en de psychiatrische zorg voor de jeugd."
    }, {
        "tekst": "Deze wet geeft geen recht op zorg, de Jeugdwet is bedoeld als aanvulling op wat het gezin zelf niet kan."
    }]
}, {
    "tekst": "Huisarts specifiek",
    "items": [{
        "tekst": "De huisarts is aanspreekpunt voor kinderen en opvoeders bij lichamelijke, psychische en gedragsproblemen."
    }, {
        "tekst": "De huisarts mag een jeugdige en de opvoeders doorverwijzen naar alle vormen van jeugdhulp en jeugdzorg."
    }, {
        "tekst": "Gemeenten en huisartsen kunnen afspraken maken over het verwijsbeleid van de huisarts."
    }, {
        "tekst": "De gemeente kan via de huisarts extra begeleiding door de POH-GGZ-jeugd inkopen."
    }]
}]}