import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';


const ZiekenhuisopnameGeneesmiddelen = () => {

	const data = [
		{
			"label": "Kenmerken patiënt",
			"longLabel": "Kenmerken patiënt, zoals geen rekening gehouden met leeftijd, gewicht of ziektestadium van de patiënt bij de dosering of toediening",
			"value": 31,
		},
		{
			"label": "Conditie patiënt",
			"longLabel": "Conditie patiënt: geneesmiddel is ongeschikt voor de conditie van de patiënt",
			"value": 13,
		},
		{
			"label": "Overige redenen",
			"longLabel": "Overige redenen, zoals meerdere bijwerkingen, allergische reactie of onacceptabele interactie met andere geneesmiddelen",
			"value": 30,
		},
			{
			"label": "Onvoldoende voorzorg",
			"longLabel": "Onvoldoende voorzorgsmaatregelen, zoals het ontbreken van een beschermend geneesmiddel (bijvoorbeeld een maagzuurremmer)",
			"value": 27,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
	"comma": true,
	});

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}

	const formatTooltip = item => {
		const value = item.value > 1000 ? localeFormatter.format(',.1f')(item.value/1000) + ' miljoen' : item.value + ' procent';
		return item.longLabel + ': ' + value;
	}
	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular" y={-10}>
			<tspan x={0}>49 duizend</tspan>
			<tspan x={0} dy={16.5}>opnames</tspan>
			<tspan x={0} dy={18}>in 2013</tspan>
		</text>);

	// const tooltipStyle = () => {
	// };

	return (
		<div className="vis-ziekenhuisopname-geneesmiddelen">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
				tooltipMaxWidth={250}
				formatTotal={formatTotal}
				unit="%"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Bron: ministerie van VWS, 2017.</p>
			</div>

		</div>
	);
}

export default ZiekenhuisopnameGeneesmiddelen;


