import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";

const GeldstromenWMO = () => (
	<div className="vis-geldstromen-wmo svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 500 600">

			<g className="geldstromen">
				<path data-tooltip-content="react-tooltip" id="tt1" d="M193.247,24 L165.877,24 C152.441,24 141.548,34.858 141.548,48.252 L141.548,231.152 L147.63,237.215 L153.713,231.152 L153.713,48.252 C153.713,41.555 159.159,36.126 165.877,36.126 L193.247,36.126 L187.165,30.063 L193.247,24 L193.247,24 Z" />
				<polygon data-tooltip-content="react-tooltip" id="tt2" points="255.59 45.221 250.014 50.778 244.439 45.22 243.932 45.22 243.932 75.535 250.014 81.598 256.096 75.535 256.096 45.221" />
				<path data-tooltip-content="react-tooltip" id="tt2" d="M193.507 6h-30.411c-12.296 0-26.356 8.498-32.104 19.351L17.123 241.719C11.973 251.491 8 267.8 8 278.835v60.63l6.082 6.063 6.082-6.063v-60.63c0-9.095 3.558-23.464 7.775-31.477L141.81 30.99c3.557-6.801 13.522-12.864 21.287-12.864h30.41l-6.081-6.063L193.507 6z"/>
				<path data-tooltip-content="react-tooltip" id="tt3" d="M485.89 241.047L372.174 24.378C366.435 13.51 352.395 5 340.115 5h-30.37v.506l5.568 5.565-5.568 5.566v.506h30.37c7.754 0 17.705 6.071 21.259 12.881l113.724 216.67c4.211 8.024 7.754 22.413 7.754 31.52v60.715l6.074 6.071 6.074-6.071v-60.715c0-11.05-4.05-27.382-9.11-37.167z"/>
				<path data-tooltip-content="react-tooltip" id="tt4" d="M255.85 130.291l-5.576 5.558-5.575-5.558h-.507v206.142c0 6.697-5.446 12.126-12.165 12.126H128.63l-6.082 6.063 6.082 6.063h103.397c13.437 0 24.33-10.858 24.33-24.252V130.291h-.508z"/>
				<polygon data-tooltip-content="react-tooltip" id="tt5" points="371.658 378.685 122.288 378.685 122.288 379.19 127.863 384.748 122.288 390.306 122.288 390.811 371.658 390.811 377.74 384.748" />
				<path data-tooltip-content="react-tooltip" id="tt6" d="M354.425,284.709 L348.343,290.772 L348.343,351.402 C348.343,358.099 342.897,363.528 336.178,363.528 L122.288,363.528 L122.288,364.033 L127.863,369.591 L122.288,375.148 L122.288,375.654 L336.178,375.654 C349.615,375.654 360.508,364.796 360.508,351.402 L360.508,290.772 L354.425,284.709 L354.425,284.709 Z" />
				<path data-tooltip-content="react-tooltip" id="tt7" d="M89.849,254 L89.849,254.526 L84.295,260.063 L89.849,265.601 L89.849,266.126 L51.329,266.126 C44.7127879,266.126 39.3303223,271.391731 39.1687362,277.948479 L39.165,278.252 L39.165,338.882 L33.082,344.945 L27.001,338.882 L27.001,278.252 C27.001,264.99194 37.6772293,254.217414 50.9276913,254.003249 L51.33,254 L89.849,254 Z" />
				<path data-tooltip-content="react-tooltip" id="tt8" d="M475.766 338.929v-60.715c0-13.412-10.878-24.285-24.296-24.285h-38.64v.84l5.234 5.231-5.234 5.232v.84h38.64c6.71 0 12.148 5.436 12.148 12.142v60.715l6.074 6.071 6.074-6.071"/>
				<path data-tooltip-content="react-tooltip" id="tt9" d="M337.078 23.214h-21.259l-6.074 6.072 6.074 6.071h21.259c6.709 0 12.148 5.437 12.148 12.143v188.214l6.074-6.071 6.074 6.071V47.5c0-13.413-10.878-24.286-24.296-24.286z"/>
			</g>

			<Link to={{ pathname: '/gehandicaptenzorg/wie-betaalt-wmo', hash: '#Burgers' }} >
				<g className="kader link">
					<rect x="197" y="1" width="108" height="40" rx="8"/>
					<text transform="translate(230 23)">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/gehandicaptenzorg/wie-betaalt-wmo', hash: '#Rijk'}} >
				<g className="kader link">
					<rect x="196.76" y="85.879" width="107.508" height="40.413" rx="8" fill="#fff"/>
					<text transform="translate(240 110)">Rijk</text>
				</g>
			</Link>

			<Link to={{ pathname: '/gehandicaptenzorg/wie-betaalt-wmo', hash: '#Gemeenten' }} >
				<g className="kader link">
					<rect x="1.014" y="349.576" width="116.636" height="40.413" rx="8" fill="#fff"/>
					<text transform="translate(28 375)">Gemeenten</text>
				</g>
			</Link>

			<Link to={{ pathname: '/gehandicaptenzorg/wie-betaalt-wmo', hash: '#CentraalAdministratieKantoor' }} >
				<g className="kader link">
					<rect x="94.323" y="240.46" width="107.508" height="40.413" rx="8" fill="#fff"/>
					<text transform="translate(135 265)">CAK</text>
				</g>
			</Link>

			<Link to={{ pathname: '/gehandicaptenzorg/wie-betaalt-wmo', hash: '#SocialeVerzekeringsbank' }} >
				<g className="kader link">
					<rect x="301.226" y="240.46" width="107.508" height="40.413" rx="8" fill="#fff"/>
					<text transform="translate(345 265)">SVB</text>
				</g>
			</Link>


			<g className="kader">
				<rect x="381.35" y="349.576" width="116.636" height="40.413" rx="8" fill="#fff"/>
				<text transform="translate(400 374)">Zorgaanbieders</text>
			</g>

			<g className="tekstlabels wit" transform="translate(-32,-50)">
				<path transform="translate(0 -30)" d="M173.06,238a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,7,7,0,0,1,1.78,3.07,6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84,7,7,0,0,1-.7,1.66,7.33,7.33,0,0,1-1.08,1.41A7,7,0,0,1,183.5,244a6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-1.41-1.08,7,7,0,0,1-1.78-3.07A7,7,0,0,1,173.06,238Zm4.78,1.86v1.36h4.57V239.9H181v-5.19h-1.38l-1.93.68v1.4l1.65-.49v3.6Z" />
				<path transform="translate(27 -75)" d="M248.06,187a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,7,7,0,0,1,1.78,3.07,6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84,7,7,0,0,1-.7,1.66,7.33,7.33,0,0,1-1.08,1.41A7,7,0,0,1,258.5,193a6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-1.41-1.08,7,7,0,0,1-1.78-3.07A7,7,0,0,1,248.06,187Zm6.87-3.42a4.28,4.28,0,0,0-2,.46v1.4a4,4,0,0,1,1.67-.48q.9,0,.9.69a1.29,1.29,0,0,1-.17.62,3.87,3.87,0,0,1-.51.76,12.36,12.36,0,0,1-.84.93l-1.16,1.17v1.09h4.63V188.9h-1.57a8.69,8.69,0,0,0-1,0v0c.39-.36.73-.69,1-1a6.61,6.61,0,0,0,.73-.84,3,3,0,0,0,.43-.78,2.31,2.31,0,0,0,.15-.83,1.66,1.66,0,0,0-.67-1.39,2.22,2.22,0,0,0-.74-.36A3.87,3.87,0,0,0,254.93,183.62Z" />
				<path transform="translate(-160 30)" d="M248.06,187a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,7,7,0,0,1,1.78,3.07,6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84,7,7,0,0,1-.7,1.66,7.33,7.33,0,0,1-1.08,1.41A7,7,0,0,1,258.5,193a6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-1.41-1.08,7,7,0,0,1-1.78-3.07A7,7,0,0,1,248.06,187Zm6.87-3.42a4.28,4.28,0,0,0-2,.46v1.4a4,4,0,0,1,1.67-.48q.9,0,.9.69a1.29,1.29,0,0,1-.17.62,3.87,3.87,0,0,1-.51.76,12.36,12.36,0,0,1-.84.93l-1.16,1.17v1.09h4.63V188.9h-1.57a8.69,8.69,0,0,0-1,0v0c.39-.36.73-.69,1-1a6.61,6.61,0,0,0,.73-.84,3,3,0,0,0,.43-.78,2.31,2.31,0,0,0,.15-.83,1.66,1.66,0,0,0-.67-1.39,2.22,2.22,0,0,0-.74-.36A3.87,3.87,0,0,0,254.93,183.62Z" />
				<path transform="translate(36 -20)" d="M430.34,238a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7.33,7.33,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.2,7.2,0,0,1,1.66.7A6.95,6.95,0,0,1,444,236.2a6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84,7,7,0,0,1-.7,1.66,7.33,7.33,0,0,1-1.08,1.41,7,7,0,0,1-3.07,1.78,6.93,6.93,0,0,1-1.84.25,7,7,0,0,1-1.84-.25,6.95,6.95,0,0,1-4.85-4.85A7,7,0,0,1,430.34,238Zm9.32-1.67a1.53,1.53,0,0,0-.63-1.28,3.09,3.09,0,0,0-1.83-.47,5.61,5.61,0,0,0-1.68.27v1.38a4,4,0,0,1,.7-.21A3.84,3.84,0,0,1,437,236a1.49,1.49,0,0,1,.71.15.5.5,0,0,1,.27.47.55.55,0,0,1-.09.32.83.83,0,0,1-.24.21,1.6,1.6,0,0,1-.35.12,2,2,0,0,1-.42,0h-.63v1.3h.84a2.63,2.63,0,0,1,.41,0,1,1,0,0,1,.32.11.64.64,0,0,1,.22.21.67.67,0,0,1,.08.33.66.66,0,0,1-.29.56,1.63,1.63,0,0,1-.89.2,4.44,4.44,0,0,1-.84-.08,3.29,3.29,0,0,1-.67-.22v1.39a4.3,4.3,0,0,0,1.6.28,3.41,3.41,0,0,0,2.09-.55,1.81,1.81,0,0,0,.7-1.51,1.39,1.39,0,0,0-1-1.44v0a1.46,1.46,0,0,0,.68-.61A1.75,1.75,0,0,0,439.66,236.37Z" />
				<path transform="translate(-6 -83)" d="M281.15,300.46a6.64,6.64,0,0,1,.25-1.84,7.39,7.39,0,0,1,.7-1.66,7,7,0,0,1,1.08-1.4,6.81,6.81,0,0,1,1.41-1.09,7.2,7.2,0,0,1,1.66-.7,7,7,0,0,1,1.84-.24,6.64,6.64,0,0,1,1.84.25,6.94,6.94,0,0,1,3.06,1.78,6.68,6.68,0,0,1,1.09,1.4,7.39,7.39,0,0,1,.7,1.66,6.9,6.9,0,0,1,0,3.68,7.2,7.2,0,0,1-.7,1.66,6.49,6.49,0,0,1-1.09,1.41,6.83,6.83,0,0,1-3.06,1.79,7,7,0,0,1-1.84.24,6.59,6.59,0,0,1-1.84-.25,7.2,7.2,0,0,1-1.66-.7A6.76,6.76,0,0,1,282.1,304a7.2,7.2,0,0,1-.7-1.66A6.59,6.59,0,0,1,281.15,300.46Zm7.58-3.22h-1.66l-1.62,4.15v1h2.75v1.26h1.58v-1.26h.78V301.1h-.78v-1H288.2v1H288a7.67,7.67,0,0,0-.88,0v0a9.92,9.92,0,0,0,.64-1.35Z"/>
				<path transform="translate(14 55)" d="M263.06,380.54a7,7,0,0,1,.25-1.84A7.2,7.2,0,0,1,264,377a6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,7,7,0,0,1,1.78,3.07,6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84A7,7,0,0,1,276,384a7.33,7.33,0,0,1-1.08,1.41,7,7,0,0,1-1.41,1.08,6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-1.41-1.08,7,7,0,0,1-1.78-3.07A7,7,0,0,1,263.06,380.54Zm6.76-1.87h2.38v-1.36h-3.72L268,381a6.44,6.44,0,0,1,1.06-.1,3.44,3.44,0,0,1,.7.06,1.48,1.48,0,0,1,.46.14.67.67,0,0,1,.25.25.74.74,0,0,1,.08.34.8.8,0,0,1-.1.38.87.87,0,0,1-.27.27,1.22,1.22,0,0,1-.39.15,1.76,1.76,0,0,1-.46.06,3.52,3.52,0,0,1-.75-.08,3.61,3.61,0,0,1-.73-.23v1.41a3.78,3.78,0,0,0,.69.2,4.77,4.77,0,0,0,.92.08,3.89,3.89,0,0,0,1.17-.17,2.59,2.59,0,0,0,.87-.47,2.07,2.07,0,0,0,.56-.72,2.28,2.28,0,0,0,.06-1.67,1.5,1.5,0,0,0-.41-.62,2.14,2.14,0,0,0-.73-.41,3.43,3.43,0,0,0-1.08-.15h-.19Z" />
				<path transform="translate(81 -28)" d="M299.06,380.54a7,7,0,0,1,.25-1.84A7.2,7.2,0,0,1,300,377a6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,7,7,0,0,1,1.66-.7,6.9,6.9,0,0,1,3.68,0,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,7,7,0,0,1,1.78,3.07,6.54,6.54,0,0,1,.25,1.84,6.64,6.64,0,0,1-.25,1.84A7,7,0,0,1,312,384a7.33,7.33,0,0,1-1.08,1.41,7,7,0,0,1-1.41,1.08,6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-1.41-1.08,7,7,0,0,1-1.78-3.07A7,7,0,0,1,299.06,380.54Zm7.16-.88a3,3,0,0,0-.71.08,2.38,2.38,0,0,0-.52.19l0,0a1.64,1.64,0,0,1,.53-1.09,1.68,1.68,0,0,1,1.06-.34,4.2,4.2,0,0,1,.77.08,4.84,4.84,0,0,1,.69.19v-1.34a2.7,2.7,0,0,0-.64-.2,4.26,4.26,0,0,0-.89-.09,3.15,3.15,0,0,0-1.43.29,2.54,2.54,0,0,0-1,.79,3.18,3.18,0,0,0-.56,1.17,5.57,5.57,0,0,0-.18,1.44,4.19,4.19,0,0,0,.19,1.35,2.78,2.78,0,0,0,.56,1,2.24,2.24,0,0,0,.82.58,2.9,2.9,0,0,0,1,.19,3.19,3.19,0,0,0,1-.16,2.44,2.44,0,0,0,.77-.45,2.06,2.06,0,0,0,.49-.71,2.47,2.47,0,0,0,.17-.92,2,2,0,0,0-.16-.83,1.67,1.67,0,0,0-.44-.63,2.12,2.12,0,0,0-.69-.4A2.65,2.65,0,0,0,306.22,379.66Zm-.28,2.87a.86.86,0,0,1-.39-.09,1,1,0,0,1-.31-.27,1.18,1.18,0,0,1-.21-.42,2,2,0,0,1-.08-.57,2.11,2.11,0,0,1,.44-.17,1.89,1.89,0,0,1,.47-.06.93.93,0,0,1,.66.21.71.71,0,0,1,.23.54.83.83,0,0,1-.22.62A.8.8,0,0,1,305.94,382.53Z" />
				<path transform="translate(-198 -73)" d="M257.06,420.12a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7.33,7.33,0,0,1,1.41-1.08,6.91,6.91,0,0,1,5.34-.7,7.2,7.2,0,0,1,1.66.7,6.76,6.76,0,0,1,2.49,2.49,6.88,6.88,0,0,1,.7,1.65,6.93,6.93,0,0,1,0,3.69,6.94,6.94,0,0,1-1.78,3.06,6.49,6.49,0,0,1-1.41,1.09,6.79,6.79,0,0,1-1.66.7,6.9,6.9,0,0,1-3.68,0,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-2.49-2.49,7.39,7.39,0,0,1-.7-1.66A7,7,0,0,1,257.06,420.12Zm7.53-1.87-2.43,5.09h1.72l2.54-5.35v-1.1h-4.64v1.36Z" />
				<path transform="translate(214 -112)" d="M281.06,459.12a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,6.91,6.91,0,0,1,5.34-.7,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,6.77,6.77,0,0,1,1.08,1.41,6.88,6.88,0,0,1,.7,1.65,6.93,6.93,0,0,1,0,3.69,6.94,6.94,0,0,1-1.78,3.06,6.73,6.73,0,0,1-1.41,1.09,6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,6.73,6.73,0,0,1-1.41-1.09,7,7,0,0,1-1.08-1.4,7.39,7.39,0,0,1-.7-1.66A7,7,0,0,1,281.06,459.12ZM288,455.7a3.58,3.58,0,0,0-1,.13,2.19,2.19,0,0,0-.77.36,1.6,1.6,0,0,0-.47.57,1.64,1.64,0,0,0-.17.74,1.45,1.45,0,0,0,.27.89,1.66,1.66,0,0,0,.71.56v0a1.81,1.81,0,0,0-.44.22,1.88,1.88,0,0,0-.35.34,1.39,1.39,0,0,0-.24.45,1.69,1.69,0,0,0-.09.56,1.88,1.88,0,0,0,.17.8,1.79,1.79,0,0,0,.5.6,2.17,2.17,0,0,0,.81.38,4.39,4.39,0,0,0,2.2,0,2.17,2.17,0,0,0,.81-.38,1.91,1.91,0,0,0,.5-.6,1.88,1.88,0,0,0,.17-.8,1.69,1.69,0,0,0-.09-.56,1.39,1.39,0,0,0-.24-.45,1.88,1.88,0,0,0-.35-.34,1.81,1.81,0,0,0-.44-.22v0a1.73,1.73,0,0,0,.71-.56,1.45,1.45,0,0,0,.27-.89,1.64,1.64,0,0,0-.17-.74,1.71,1.71,0,0,0-.47-.57,2.19,2.19,0,0,0-.77-.36A3.58,3.58,0,0,0,288,455.7Zm0,5.44a1,1,0,0,1-.68-.19.62.62,0,0,1-.23-.48.78.78,0,0,1,.27-.61.79.79,0,0,1,1.28,0,.78.78,0,0,1,.27.61.62.62,0,0,1-.23.48A1,1,0,0,1,288,461.14Zm0-2.68a1,1,0,0,1-.59-.34.72.72,0,0,1-.18-.45.61.61,0,0,1,.06-.28.67.67,0,0,1,.17-.2,1,1,0,0,1,.25-.12.92.92,0,0,1,.29,0,1,1,0,0,1,.29,0,.9.9,0,0,1,.24.12.58.58,0,0,1,.18.2.61.61,0,0,1,.06.28.72.72,0,0,1-.18.45A1,1,0,0,1,288,458.46Z" />
				<path transform="translate(-57 -192)" d="M437.06,411.12a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7,7,0,0,1,1.41-1.08,6.91,6.91,0,0,1,5.34-.7,7.39,7.39,0,0,1,1.66.7,7,7,0,0,1,1.41,1.08,6.77,6.77,0,0,1,1.08,1.41,6.88,6.88,0,0,1,.7,1.65,6.93,6.93,0,0,1,0,3.69,6.94,6.94,0,0,1-1.78,3.06,6.73,6.73,0,0,1-1.41,1.09,6.91,6.91,0,0,1-5.34.7,7.39,7.39,0,0,1-1.66-.7,6.73,6.73,0,0,1-1.41-1.09,7,7,0,0,1-1.08-1.4,7.39,7.39,0,0,1-.7-1.66A7,7,0,0,1,437.06,411.12Zm6.24,2a3.9,3.9,0,0,1-.68-.07,3.5,3.5,0,0,1-.78-.23v1.39a4.06,4.06,0,0,0,.69.19,4.59,4.59,0,0,0,.93.09,3.48,3.48,0,0,0,1.53-.3,2.57,2.57,0,0,0,1-.78,3.26,3.26,0,0,0,.54-1.16,5.78,5.78,0,0,0,.16-1.4,3.46,3.46,0,0,0-.71-2.34,2.52,2.52,0,0,0-2-.8,2.58,2.58,0,0,0-.94.16,2.06,2.06,0,0,0-.74.46,2.2,2.2,0,0,0-.49.71,2.29,2.29,0,0,0-.18.92,2,2,0,0,0,.18.89,1.83,1.83,0,0,0,1.14,1,2.66,2.66,0,0,0,.79.12,3.23,3.23,0,0,0,.69-.07,2.4,2.4,0,0,0,.59-.2h0a1.68,1.68,0,0,1-.56,1.11A1.89,1.89,0,0,1,443.3,413.11Zm.82-2.46a.9.9,0,0,1-.64-.21.77.77,0,0,1-.22-.59,1,1,0,0,1,0-.29,1,1,0,0,1,.14-.25.64.64,0,0,1,.25-.18.91.91,0,0,1,.35-.07.85.85,0,0,1,.74.36,1.88,1.88,0,0,1,.3,1l-.21.12-.26.08a1.41,1.41,0,0,1-.27.05Z" />
			</g>

			<g className="tekst links regular">
				<text transform="translate(24 520)">
					<tspan x="0" y="0">Eigen bijdrage voor maatwerkvoorzieningen</tspan>
					<tspan x="0" y="15">Belastingen</tspan>
					<tspan x="0" y="30">Eigen betalingen</tspan>
					<tspan x="0" y="45">Middelen gemeentefonds</tspan>
					<tspan x="0" y="60">Betaling voor geleverde ondersteuning</tspan>
					<tspan x="278.25" y="0">Middelen persoonsgebonden budget</tspan>
					<tspan x="278.25" y="15">Uitbetaling eigen bijdrage</tspan>
					<tspan x="278.25" y="30">Betaling voor ondersteuning</tspan>
					<tspan x="278.25" y="45">Betaling aan naasten</tspan>
				</text>
			</g>

			<g className="tekstlabels zwart">
				<path d="M6.66,514.75A6.62,6.62,0,0,1,6.89,513a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,6.66,514.75Zm4.47,1.74v1.28h4.3v-1.28H14.14v-4.86h-1.3l-1.8.64v1.31l1.54-.46v3.37Z" fill="#1d1d1b"/>
				<path d="M6.66,529.75A6.62,6.62,0,0,1,6.89,528a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,6.66,529.75Zm6.44-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.56-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.78,4.78,0,0,1-.47.71c-.21.26-.48.55-.79.87l-1.1,1.1v1h4.35v-1.28H14c-.29,0-.61,0-.93,0v0c.37-.34.69-.65,1-.92a6.58,6.58,0,0,0,.69-.79,3,3,0,0,0,.4-.74,2.21,2.21,0,0,0,.14-.78,1.63,1.63,0,0,0-.17-.75,1.53,1.53,0,0,0-.46-.55,2,2,0,0,0-.69-.33A3.43,3.43,0,0,0,13.1,526.54Z" fill="#1d1d1b"/>
				<path d="M6.66,544.75A6.62,6.62,0,0,1,6.89,543a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,6.66,544.75Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.45.45,0,0,1,.26.44.56.56,0,0,1-.08.3.9.9,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.45,2.45,0,0,1-.39,0h-.6v1.21H13a2.34,2.34,0,0,1,.38,0,.89.89,0,0,1,.3.11.51.51,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,4,4,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.69,1.69,0,0,0,.66-1.42,1.31,1.31,0,0,0-.28-.88,1.29,1.29,0,0,0-.7-.46v0a1.38,1.38,0,0,0,.64-.57A1.65,1.65,0,0,0,15.4,543.19Z" fill="#1d1d1b"/>
				<path d="M6.66,559.75A6.62,6.62,0,0,1,6.89,558a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,6.66,559.75Zm7.1-3H12.21l-1.52,3.89v1h2.58v1.18h1.48v-1.18h.73v-1.25h-.73v-.91H13.27v.91H13a6.78,6.78,0,0,0-.83,0v0a9.34,9.34,0,0,0,.6-1.27Z" fill="#1d1d1b"/>
				<path d="M6.66,574.75A6.62,6.62,0,0,1,6.89,573a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,6.66,574.75ZM13,573h2.24v-1.28H11.74l-.49,3.43a5.69,5.69,0,0,1,1-.09,4,4,0,0,1,.66.05,1.36,1.36,0,0,1,.43.14.59.59,0,0,1,.24.23.79.79,0,0,1,.07.32.65.65,0,0,1-.1.36.75.75,0,0,1-.25.25,1.18,1.18,0,0,1-.36.14,1.54,1.54,0,0,1-.43.05,3.23,3.23,0,0,1-.71-.07,4,4,0,0,1-.68-.21v1.31a3.44,3.44,0,0,0,.64.2,5.37,5.37,0,0,0,.87.07,3.33,3.33,0,0,0,1.09-.16,2.3,2.3,0,0,0,.82-.44,1.87,1.87,0,0,0,.52-.67,2,2,0,0,0,.19-.86,2.08,2.08,0,0,0-.12-.71,1.74,1.74,0,0,0-.39-.58,2.08,2.08,0,0,0-.69-.39,3.28,3.28,0,0,0-1-.14h-.17Z" fill="#1d1d1b"/>
				<path d="M281.89,514.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,294,518a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,281.89,514.75Zm6.71-.82a2.76,2.76,0,0,0-.67.07,2.2,2.2,0,0,0-.48.18l0,0a1.51,1.51,0,0,1,.5-1,1.53,1.53,0,0,1,1-.32,3.69,3.69,0,0,1,.72.08,4,4,0,0,1,.65.17v-1.26a3.18,3.18,0,0,0-.6-.18,3.84,3.84,0,0,0-.83-.09,3.1,3.1,0,0,0-1.35.27,2.58,2.58,0,0,0-.92.74,3,3,0,0,0-.52,1.1,5,5,0,0,0-.17,1.36,4,4,0,0,0,.18,1.26,2.68,2.68,0,0,0,.52.91,2.11,2.11,0,0,0,.78.54,2.49,2.49,0,0,0,1,.18,2.77,2.77,0,0,0,.94-.15,2,2,0,0,0,.73-.43,1.83,1.83,0,0,0,.46-.66,2.35,2.35,0,0,0,.16-.86,2,2,0,0,0-.15-.78,1.77,1.77,0,0,0-.42-.59,1.92,1.92,0,0,0-.64-.37A2.39,2.39,0,0,0,288.6,513.93Zm-.26,2.69a.74.74,0,0,1-.36-.09.78.78,0,0,1-.29-.25,1.16,1.16,0,0,1-.2-.4,1.78,1.78,0,0,1-.08-.53,2.05,2.05,0,0,1,.41-.16,1.72,1.72,0,0,1,.44-.06.92.92,0,0,1,.63.2.66.66,0,0,1,.21.5.79.79,0,0,1-.21.59A.76.76,0,0,1,288.34,516.62Z" fill="#1d1d1b"/>
				<path d="M281.89,529.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,294,533a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,281.89,529.75Zm7.05-1.75-2.27,4.77h1.61l2.39-5v-1h-4.35V528Z" fill="#1d1d1b"/>
				<path d="M281.89,544.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,294,548a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,281.89,544.75Zm6.51-3.21a3.52,3.52,0,0,0-1,.12,2.2,2.2,0,0,0-.72.35,1.63,1.63,0,0,0-.45.53,1.58,1.58,0,0,0-.15.69,1.32,1.32,0,0,0,.25.83,1.71,1.71,0,0,0,.67.54v0a2.1,2.1,0,0,0-.42.22,1.6,1.6,0,0,0-.33.31,1.53,1.53,0,0,0-.22.42,1.51,1.51,0,0,0-.08.53,1.65,1.65,0,0,0,.16.75,1.6,1.6,0,0,0,.47.57,2.23,2.23,0,0,0,.76.35,4.11,4.11,0,0,0,2.05,0,2.23,2.23,0,0,0,.76-.35,1.6,1.6,0,0,0,.47-.57,1.65,1.65,0,0,0,.16-.75,1.78,1.78,0,0,0-.08-.53,1.53,1.53,0,0,0-.22-.42,1.6,1.6,0,0,0-.33-.31,2.32,2.32,0,0,0-.41-.22v0a1.68,1.68,0,0,0,.66-.54,1.32,1.32,0,0,0,.25-.83,1.58,1.58,0,0,0-.15-.69,1.52,1.52,0,0,0-.45-.53,2.1,2.1,0,0,0-.72-.35A3.5,3.5,0,0,0,288.4,541.54Zm0,5.11a1,1,0,0,1-.64-.19.56.56,0,0,1-.21-.44.72.72,0,0,1,.24-.57,1.41,1.41,0,0,1,.61-.31,1.43,1.43,0,0,1,.6.31.72.72,0,0,1,.24.57.56.56,0,0,1-.21.44A1,1,0,0,1,288.4,546.65Zm0-2.52a1,1,0,0,1-.56-.31.74.74,0,0,1-.17-.43.53.53,0,0,1,.06-.26.6.6,0,0,1,.16-.19.7.7,0,0,1,.23-.11,1,1,0,0,1,.55,0,.7.7,0,0,1,.23.11.6.6,0,0,1,.16.19.53.53,0,0,1,.06.26.74.74,0,0,1-.17.43A1,1,0,0,1,288.4,544.13Z" fill="#1d1d1b"/>
				<path d="M281.89,559.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,294,563a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,281.89,559.75Zm5.85,1.87a4.29,4.29,0,0,1-.63-.07,3.15,3.15,0,0,1-.74-.22v1.3a3.59,3.59,0,0,0,.64.19,5,5,0,0,0,.88.08,3.27,3.27,0,0,0,1.44-.28,2.53,2.53,0,0,0,.93-.74,3,3,0,0,0,.5-1.08,5.53,5.53,0,0,0,.15-1.31,3.19,3.19,0,0,0-.67-2.19,2.32,2.32,0,0,0-1.86-.76,2.35,2.35,0,0,0-.89.16,2,2,0,0,0-.69.43,1.92,1.92,0,0,0-.46.66,2.2,2.2,0,0,0-.16.86,2,2,0,0,0,.16.84,1.68,1.68,0,0,0,.44.57,1.61,1.61,0,0,0,.63.34,2.65,2.65,0,0,0,.74.11,3.39,3.39,0,0,0,.64-.06,2,2,0,0,0,.56-.2l0,0a1.61,1.61,0,0,1-.52,1A1.84,1.84,0,0,1,287.74,561.62Zm.77-2.31a.85.85,0,0,1-.6-.2.71.71,0,0,1-.21-.55.84.84,0,0,1,0-.27.92.92,0,0,1,.13-.24.63.63,0,0,1,.23-.16.67.67,0,0,1,.33-.07.8.8,0,0,1,.69.34,1.74,1.74,0,0,1,.29.89.67.67,0,0,1-.2.12l-.25.08a1.09,1.09,0,0,1-.25,0Z" fill="#1d1d1b"/>
			</g>
		</svg>

		<ReactTooltip anchorId="tt1" place="right" float>
			<span>1. Eigen bijdrage voor maatwerkvoorzieningen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Belastingen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt3" place="left" float>
			<span>3. Eigen betalingen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt4" place="right" float>
			<span>4. Middelen gemeentefonds</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt5" place="right" float>
			<span>5. Betaling voor geleverde ondersteuning</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt6" place="right" float>
			<span>6. Middelen persoonsgebonden budget</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt7" place="right" float>
			<span>7. Uitbetaling eigen bijdrage</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt8" place="right" float>
			<span>8. Betaling voor geleverde ondersteuning</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt9" place="left" float>
			<span>9. Betaling aan naasten die ondersteuning verlenen</span>
		</ReactTooltip>
	</div>
)

export default GeldstromenWMO;
