import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';


const Inkomen = () => {

	const margin = {top: 90, right: 0, bottom: 90, left: 0};
	const formatY = value => value;
	const formatValueToolTip = data => data.data.value + ' duizend euro';

	const data = [
			{
	        "year": "2010",
	        "inkomen": 121,
	    },
			{
	        "year": "2011",
	        "inkomen": 125,
	    },
	    {
	        "year": "2012",
	        "inkomen": 117,
	    },
	    {
	        "year": "2013",
	        "inkomen": 111,
	    },
	    {
	        "year": "2014",
	        "inkomen":113,
	    },
	    {
	        "year": "2015",
	        "inkomen":108,
	    }
	];

	return (
		<div className="vis-inkomen">

		<ColumnChart
			margin={margin}
			colorBars={["#333","#ddd"]}
			colorAxis="#333"
			labelY="x 1000"
			height={450}
			domain={[0, 125]}
			formatY={formatY}
			columnPadding={0.15}
			formatValueToolTip={formatValueToolTip}
			data={data}
		/>

		<div className="vis-credit">
			<p>Gemiddeld jaarinkomen van zelfstandig gevestigde huisartsen vóór</p>
			<p>loonbelasting x 1.000 euro.</p>
			<p>Bron: CBS Statline 2018</p>
		</div>

	</div>
	);
}

export default Inkomen;


