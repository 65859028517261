export default {"tekst": "Hoe werkt de Zorgverzekeringswet en wat is relevant voor de huisarts?","items": [{
    "tekst": "Uitleg wet",
    "items": [{
        "tekst": "Deze wet regelt de basisaanspraken van burgers op zorg."
    }, {
        "tekst": "Deze wet verplicht burgers tot het sluiten van een basiszorgverzekering."
    }, {
        "tekst": "Deze wet regelt dat zorgverzekeraars iedereen moeten toelaten tot de basiszorgverzekering."
    }, {
        "tekst": "Deze wet regelt de financiering van de basiszorgverzekering en het eigen risico van volwassen patiënten."
    }, {
        "tekst": "Deze wet verplicht zorgverzekeraars voldoende zorg in te kopen."
    }]
}, {
    "tekst": "Huisarts specifiek",
    "items": [{
        "tekst": "Deze wet regelt dat patiënten zelf hun huisarts mogen kiezen."
    }, {
        "tekst": "De huisartsenzorg zit in het basispakket en valt niet onder het eigen risico van de patiënt."
    }, {
        "tekst": "Kosten voor aanvullend onderzoek en medicatie, een labtest of een scan, vallen wel onder het eigen risico."
    }, {
        "tekst": "Deze wet regelt dat de huisarts poortwachter is en een patiënt mag doorverwijzen naar andere zorgverleners."
    }]
}]}