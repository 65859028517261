import React from 'react';

const Gehandicaptenzorg = () => (
<svg viewBox="0 0 133 133" className="af-icon">
		<path d="M53.112 22c6.434 0 11.667 5.233 11.667 11.666 0 6.434-5.233 11.667-11.667 11.667-6.433 0-11.666-5.233-11.666-11.667C41.446 27.233 46.679 22 53.112 22zm0 3.333c-4.593 0-8.333 3.739-8.333 8.333 0 4.595 3.739 8.334 8.333 8.334 4.595 0 8.334-3.739 8.334-8.334 0-4.593-3.74-8.333-8.334-8.333zM57.127 110.817c-5.77 0-11.322-1.988-15.858-5.724-5.202-4.282-8.446-10.352-9.134-17.088-1.126-11.049 4.894-21.429 14.98-25.829l1.334 3.055c-8.754 3.818-13.976 12.836-12.996 22.436.597 5.856 3.414 11.132 7.934 14.853 4.515 3.719 10.188 5.435 15.975 4.85 9.523-.971 17.405-8.29 19.17-17.798l3.277.61c-2.032 10.951-11.126 19.384-22.108 20.502-.862.09-1.72.132-2.574.132"/>
		<path d="M89.947 111.08l-3.81-21.666 3.283-.579 3.232 18.384 5.423-.956-4.73-26.83-36.44 6.745-6.257-35.47 12.043-2.382 4.33 24.414-3.283.582-3.735-21.067-5.504 1.09 5.104 28.942 36.439-6.745 5.894 33.424z"/>
</svg>
);

export default Gehandicaptenzorg;
