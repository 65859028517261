import * as harmonicas from './harmonicas';
import vissen from './visualizations';

const pages = [
	{
		categorie:"basis",
		template:"specialvoorpagina",
		naam:"geneesmiddelenzorg",
		titel:"Zó werkt de geneesmiddelenzorg",
		kleur: "5",
		tekst:
			"Hoe werkt de geneesmiddelenzorg? Hier vind je alles over de productie, verstrekking en veiligheid van geneesmiddelen, over spelers en organisaties, wetten en financiering.",
		links: 
			['geneesmiddelen-ontwikkeling',
			'verstrekkers-gebruikers-geneesmiddelen',
			'veiligheid',
			'wie-is-wie',
			'stelselwetten',
			'wetten-geneesmiddelenzorg',
			'uitgaven',
			'geldstromen']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"geneesmiddelen-ontwikkeling",
		titel:"Wat zijn geneesmiddelen en hoe worden ze ontwikkeld?",
		kleur:"6",
		tekst:
			"Geneesmiddelen zijn er in verschillende soorten en maten. Wat is een geneesmiddel? Welke effecten hebben geneesmiddelen op de gezondheid? En hoe komen nieuwe geneesmiddelen eigenlijk tot stand?",
		links: 
			['geneesmiddelen',
			'soorten-geneesmiddelen',
			'effecten-geneesmiddelen',
			'stappen-nieuw-geneesmiddel']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"geneesmiddelen",
		titel:"Wat is een geneesmiddel?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"soorten-geneesmiddelen",
		titel:"Welke soorten geneesmiddelen onderscheiden we?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"effecten-geneesmiddelen",
		titel:"Wat zijn effecten van geneesmiddelen op de gezondheid van patiënten?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"stappen-nieuw-geneesmiddel",
		titel:"Welke stappen doorloopt een nieuw, innovatief geneesmiddel meestal?",
		kleur:"6",
		tekst: "",
		links: 
			['uitleg-nieuw-geneesmiddel',
			'geneesmiddelenfabrikanten']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"uitleg-nieuw-geneesmiddel",
		titel:"Hoe zien de stappen eruit die een nieuw, innovatief geneesmiddel doorloopt?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"geneesmiddelenfabrikanten",
		titel:"Wie maken geneesmiddelen?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"verstrekkers-gebruikers-geneesmiddelen",
		titel:"Wie verstrekken geneesmiddelen en wie gebruiken ze?",
		kleur:"6",
		tekst:
			"Mensen kunnen op meerdere plaatsen terecht voor hun geneesmiddelen. Wie mogen eigenlijk geneesmiddelen verstrekken of voorschrijven? En welke mensen gebruiken welke geneesmiddelen?",
		links: 
			['voorschrijven-verstrekken',
			'plaats-verstrekking',
			'hoeveel-gebruikers',
			'wie-gebruikt-geneesmiddelen']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"voorschrijven-verstrekken",
		titel:"Wie mogen geneesmiddelen voorschrijven en verstrekken?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"plaats-verstrekking",
		titel:"Waar verkrijgen patiënten voorgeschreven geneesmiddelen?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"hoeveel-gebruikers",
		titel:"Hoeveel mensen gebruiken extramurale geneesmiddelen?",
		kleur:"6",
		tekst: "",
		links: 
			['meestgebruikt']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"meestgebruikt",
		titel:"Welke extramurale geneesmiddelen gebruiken we het meest?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"wie-gebruikt-geneesmiddelen",
		titel:"Wie gebruikt extramurale geneesmiddelen?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"veiligheid",
		titel:"Hoe is de veiligheid van een geneesmiddelen geregeld?",
		kleur:"6",
		tekst:
			"In de geneesmiddelenzorg is veel aandacht voor de veiligheid van geneesmiddelen. Hoe wordt de veiligheid geregeld? En wat draagt bij aan de goede werking van geneesmiddelen?",
		links: 
			['veiligheid-geregistreerd-geneesmiddel',
			'incidenten',
			'ziekenhuisopname-geneesmiddelen',
			'goede-werking-geneesmiddelen']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"veiligheid-geregistreerd-geneesmiddel",
		titel:"Hoe is de veiligheid van een geregistreerd geneesmiddel geregeld?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"incidenten",
		titel:"Wat gebeurt er als er een incident is met een geneesmiddel?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"ziekenhuisopname-geneesmiddelen",
		titel:"Wat is de reden van ziekenhuisopname door geneesmiddelen bij 65-plussers?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"goede-werking-geneesmiddelen",
		titel:"Wat draagt bij aan goede werking van een geneesmiddel?",
		kleur:"6",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"wie-is-wie",
		titel:"Wie zijn belangrijke spelers in de geneesmiddelenzorg en wat doen zij?",
		kleur:"1",
		tekst:
			"Geneesmiddelenfabrikanten, apothekers en voorschrijvers, veel partijen en organisaties houden zich bezig met de geneesmiddelenzorg. Wie zijn zij en wat doen ze?",
		links: 
			['werknemers-geneesmiddelenzorg',
			'opleidingen-geneesmiddelenzorg',
			'organisaties-werknemers',
			'organisaties-kwaliteit',
			'organisaties-kennis',
			'organisaties-registratie']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"werknemers-geneesmiddelenzorg",
		titel:"Wie werken er zoal in de geneesmiddelenzorg?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"opleidingen-geneesmiddelenzorg",
		titel:"Wat zijn de meest voorkomende opleidingen in de geneesmiddelenzorg?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"organisaties-werknemers",
		titel:"Welke organisaties vertegenwoordigen mensen in de geneesmiddelenzorg?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"organisaties-kwaliteit",
		titel:"Welke organisaties houden zich bezig met kwaliteit van de geneesmiddelenzorg?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"organisaties-kennis",
		titel:"Welke organisaties houden zich bezig met kennis en advies van de geneesmiddelenzorg?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"organisaties-registratie",
		titel:"Welke organisaties houden zich bezig met registratie, vergoeding, prijsafspraken en inkoop van geneesmiddelen?",
		kleur:"1",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"stelselwetten",
		titel:"Hoe werken de stelselwetten voor de geneesmiddelenzorg?",
		kleur:"2",
		tekst:
			"Twee belangrijke wetten voor de geneesmiddelenzorg zijn de Zorgverzekeringswet en de Wet langdurige zorg. Wat regelen deze wetten? Hoe wordt bepaald welke geneesmiddelen worden vergoed? En wat gebeurt er als geneesmiddelen niet beschikbaar zijn?",
		links: 
			['stelselwetten-zvw',
			'stelselwetten-wlz',
			'criteria-beoordeling',
			'vergoeding-geneesmiddel',
			'preferentiebeleid',
			'geneesmiddelentekort']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"stelselwetten-zvw",
		titel:"Hoe werkt de Zorgverzekeringswet (Zvw) en wat is relevant voor de geneesmiddelenzorg?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"stelselwetten-wlz",
		titel:"Hoe werkt de Wet langdurige zorg (Wlz) en wat is relevant voor de geneesmiddelenzorg?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"criteria-beoordeling",
		titel:"Welke criteria en vragen hanteert het Zorginstituut bij het beoordelen van geneesmiddelen?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"vergoeding-geneesmiddel",
		titel:"Hoe is de vergoeding van een geneesmiddel geregeld?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"preferentiebeleid",
		titel:"Wat houdt preferentiebeleid in?",
		kleur:"2",
		tekst: "",
		links: 
			['preferentie-per-verzekeraar']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"preferentie-per-verzekeraar",
		titel:"Hoeveel preferente geneesmiddelen zijn er per verzekeraar?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"geneesmiddelentekort",
		titel:"Van hoeveel geneesmiddelen is een tekort gemeld?",
		kleur:"2",
		tekst: "",
		links: 
			['oplossingen-tekort']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"oplossingen-tekort",
		titel:"Hoe lossen apothekers een tekort aan een geneesmiddel op?",
		kleur: "2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"wetten-geneesmiddelenzorg",
		titel:"Wat zijn de relevante (zorg)wetten voor de geneesmiddelenzorg?",
		kleur:"2",
		tekst:
			"Behalve de Zorgverzekeringswet en de Wet langdurge zorg, regelen verschillende andere wetten regelen de geneesmiddelenzorg. Welke wetten zijn dit en wat doen ze?",
		links: 
			[ 'zorgwetten-geneesmiddelenzorg']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"zorgwetten-geneesmiddelenzorg",
		titel:"Wat zijn de relevante (zorg)wetten voor de geneesmiddelenzorg?",
		kleur:"2",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"uitgaven",
		titel:"Hoeveel geven we uit aan geneesmiddelenzorg?",
		kleur:"3",
		tekst:
			"Het CBS en het rijk hanteren verschillende definities en bedragen. Met ruim 6 miljard euro per jaar gaat er in elk geval een flink bedrag om de de geneesmiddelenzorg. Hoeveel geven we uit in vergelijking met andere landen? En hoe zit het met zogenaamde 'dure' geneesmiddelen?",
		links: 
			[
				'/basis/euros-cbs',
				'/basis/euros-vws',
				'uitgaven-geneesmiddelen',
				'dure-geneesmiddelen',
				'kosten-dure-geneesmiddelen',
				'kosten-internationaal'
			]
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"uitgaven-cbs",
		titel:"Waaraan geven we onze zorgeuro’s uit volgens het CBS?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"uitgaven-vws",
		titel:"Hoeveel geld heeft het Rijk begroot voor de zorg?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"uitgaven-geneesmiddelen",
		titel:"Hoeveel geld geven we uit aan geneesmiddelenzorg?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"dure-geneesmiddelen",
		titel:"Wanneer is een geneesmiddel ‘duur’ volgens de NZa, VWS en het Zorginstituut?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"kosten-dure-geneesmiddelen",
		titel:"Welk deel van de kosten van medisch-specialistische zorg bestaat uit dure geneesmiddelen?",
		kleur:"3",
		tekst: "",
		links: 
			['dure-geneesmiddelen']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"kosten-internationaal",
		titel:"Welk deel van het zorgbudget besteden landen aan extramurale geneesmiddelen?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"intro",
		naam:"geldstromen",
		titel:"Hoe lopen de geldstromen in de geneesmiddelenzorg?",
		kleur:"3",
		tekst:
			"De financiering van geneesmiddelenzorg is best ingewikkeld. Welke partijen betalen wat? En hoeveel betalen mensen eigenlijk zelf nog voor hun geneesmiddelen?",
		links: 
			['totstandkoming-prijzen',
			'geldstromen-zvw',
			'eigen-betaling']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"totstandkoming-prijzen",
		titel:"Hoe komt de prijs van een nieuw geneesmiddel tot stand?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"visualisatie",
		naam:"geldstromen-zvw",
		titel:"Hoe lopen de geldstromen voor de geneesmiddelenzorg in de Zorgverzekeringswet?",
		kleur:"3",
		tekst: "",
		links: 
			['wie-betaalt-zvw']
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"wie-betaalt-zvw",
		titel:"Wie betaalt wat voor de geneesmiddelenzorg in de Zorgverzekeringswet?",
		kleur:"3",
		tekst: "",
		links: []
	},
	{
		categorie:"geneesmiddelenzorg",
		template:"harmonica",
		naam:"eigen-betaling",
		titel:"Wanneer betalen patiënten voor geneesmiddelenzorg?",
		kleur:"3",
		tekst: "",
		links: []
	}
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
	if (pagina.template === "harmonica") {
		const harmonicaNaam = pagina.naam.replace(/-/g, "_");
		pagina.harmonica = harmonicas[harmonicaNaam];
	}
	if (pagina.template === "visualisatie") {
		const visualisatieNaam = pagina.naam.replace(/-/g, "_");
		pagina.vis = vissen[visualisatieNaam];
	}
	return pagina;
});
export default pages;