import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "intro",
  naam:"mini-geboortezorg",
  titel:"Zó werkt de zorg-mini: Geboortezorg",
  kleur: COLOR_TURQUOISE,
  tekst:
    "In 2018 werden bijna 170 duizend kinderen geboren, waarvan dertien procent thuis. Het aantal bevallingen in het ziekenhuis neemt al jaren toe. Waar de bevalling ook plaatsvindt, de zorg voor moeder en kind tijdens en na de zwangerschap noemen we geboortezorg. Wat dit zoal inhoudt, lees je in deze mini.",
  links:
    [
      'wat-is-geboortezorg',
      'aantal-bevallingen',
      'uitgaven-geboortezorg',
      'colofon-geboortezorg'
    ]
}
