// import React from 'react';

// import harmonicas
import * as harmonicas from "./harmonicas";
import vissen from "./visualizations";

const pages = [
	{
		categorie:"basis",
		template:"specialvoorpagina",
		naam:"publieke-gezondheidszorg",
		titel:"Zó werkt publieke gezondheidszorg",
		kleur:"5",
		tekst:
			"Hoe werkt publieke gezondheidszorg? Vind hier alles over de kerntaken van publieke gezondheidzorg, spelers en organisaties, wetten en financiering.",
		links: [
			"rol-publieke-gezondheidszorg",
			"wie-is-wie",
			"organisaties",
			"stelselwetten",
			"wetten-publieke-gezondheidszorg",
			"uitgaven",
			"geldstromen"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"rol-publieke-gezondheidszorg",
		titel:"Wat is de rol van publieke gezondheidszorg in het zorgstelsel?",
		kleur:"6",
		tekst:
			"Wat verstaan we onder publieke gezondheidszorg en wat zijn de negen kerntaken hiervan? En welke vormen van preventie kennen we?",
		links: [
			"gezonde-levensverwachting",
			"kerntaken-publieke-gezondheidszorg",
			"soorten-preventie"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"gezonde-levensverwachting",
		titel:"Hoelang leven we en hoelang voelen we ons gezond?",
		kleur:"6",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"kerntaken-publieke-gezondheidszorg",
		titel:"Wat zijn de negen kerntaken van publieke gezondheidszorg?",
		kleur:"6",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"soorten-preventie",
		titel:"Welke vormen van preventie zijn er in de zorg?",
		kleur:"6",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"wie-is-wie",
		titel:"Wie zijn de hoofdrolspelers in publieke gezondheidszorg en wie doet wat?",
		kleur:"1",
		tekst:
			"In publieke gezondheidszorg spelen verschillende nationale, regionale en lokale organisaties een belangrijke rol. Wie doet wat om de volksgezondheid te beschermen en te bevorderen?",
		links: [
			"hoofdrolspelers",
			"wat-doen-hoofdrolspelers",
			"beleid-publieke-gezondheidszorg",
			"ggd-regios",
			"werknemers-publieke-gezondheidszorg"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"hoofdrolspelers",
		titel:"Wie zijn de hoofdrolspelers in publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: [
			"wat-doen-hoofdrolspelers"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"wat-doen-hoofdrolspelers",
		titel:"Wat doen de hoofdrolspelers in publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"beleid-publieke-gezondheidszorg",
		titel:"In welke stappen komt beleid voor publieke gezondheidszorg tot stand?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"ggd-regios",
		titel:"In welk 25 GGD-regio's is Nederland ingedeeld?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"werknemers-publieke-gezondheidszorg",
		titel:"Welke mensen werken zoal in publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"organisaties",
		titel:"Wie zijn andere belangrijke spelers in publieke gezondheidszorg en wat doen zij?",
		kleur:"1",
		tekst:
			"Verschillende organisaties adviseren, ontwikkelen kennis over en houden toezicht op publieke gezondheidszorg. Wie zijn zij? En wie vertegenwoordigt werknemers in publieke gezondheidszorg?",
		links: [
			"advies-publieke-gezondheidszorg",
			"toezicht-publieke-gezondheidszorg",
			"organisaties-werknemers",
			"kennis-publieke-gezondheidszorg"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"advies-publieke-gezondheidszorg",
		titel:"Welke organisaties adviseren de overheid over publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"toezicht-publieke-gezondheidszorg",
		titel:"Welke organisaties houden toezicht op publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"organisaties-werknemers",
		titel:"Welke organisaties vertegenwoordigen de mensen in publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"kennis-publieke-gezondheidszorg",
		titel:"Welke organisaties houden zich bezig met kennis over publieke gezondheidszorg?",
		kleur:"1",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"stelselwetten",
		titel:"Hoe werken de stelselwetten voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"De stelselwetten zijn: Wet publieke gezondheid, Wet maatschappelijke ondersteuning, Jeugdwet, Zorgverzekeringswet en Wet langdurige Zorg. Wat houden deze wetten in en wat is hun relevantie voor publieke gezondheidszorg?",
		links: [
			"stelselwetten-wpg",
			"taken-ggd",
			"stelselwetten-wmo",
			"stelselwetten-zvw",
			"stelselwetten-wlz"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"stelselwetten-wpg",
		titel:"Hoe werkt de Wet publieke gezondheid en wat is relevant voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"taken-ggd",
		titel:"Welke taken moet de GGD uitvoeren op basis van de Wet publieke gezondheid?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"stelselwetten-wmo",
		titel:"Hoe werkt de Wet maatschappelijke ondersteuning en wat is relevant voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"stelselwetten-zvw",
		titel:"Hoe werkt de Zorgverzekeringswet en wat is relevant voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"stelselwetten-wlz",
		titel:"Hoe werkt de Wet langdurige zorg en wat is relevant voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"wetten-publieke-gezondheidszorg",
		titel:"Wat zijn de relevante (zorg)wetten voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"Verschillende zorgwetten en algemene wetten regelen publieke gezondheidszorg. Welke wetten zijn dit en wat doen ze?",
		links: [
			"zorgwetten-publieke-gezondheidszorg",
			"overige-wetten"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"zorgwetten-publieke-gezondheidszorg",
		titel:"Wat zijn relevante (zorg)wetten voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"overige-wetten",
		titel:"Welke wetten over leefomgeving en consumptie zijn relevant voor publieke gezondheidszorg?",
		kleur:"2",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"uitgaven",
		titel:"Hoeveel geven we uit aan publieke gezondheidszorg?",
		kleur:"3",
		tekst:
			"Hoeveel geld geven we uit aan zorg in Nederland volgens het CBS en de Rijksbegroting? Een beperkt deel van de uitgaven is voor publieke gezondheidszorg.",
		links: [
			"/euros-cbs",
			"/euros-vws",
			"euros-wpg"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"euros-wpg",
		titel:"Hoeveel geld begroot het ministerie van VWS voor publieke gezondheidszorg?",
		kleur:"3",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"intro",
		naam:"geldstromen",
		titel:"Hoe lopen de geldstromen in publieke gezondheidszorg?",
		kleur:"3",
		tekst:
			"Publieke gezondheidszorg omvat verschillende facetten waar we als burger mee te maken kunnen krijgen. Hoe lopen de geldstromen?",
		links: [
			"geldstromen-wpg",
			"geldstromen-ggd",
			"geldstromen-rijksvaccinatieprogramma",
			"geldstromen-bevolkingsonderzoek"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"geldstromen-wpg",
		titel:"Hoe lopen de geldstromen in de Wet publieke gezondheidzorg?",
		kleur:"3",
		tekst:
			"",
		links: [
			"wie-betaalt-wpg"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"wie-betaalt-wpg",
		titel:"Wie betaalt wat in de Wet publieke gezondheidszorg?",
		kleur:"3",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"geldstromen-ggd",
		titel:"Hoe lopen de geldstromen voor de taken van de GGD?",
		kleur:"3",
		tekst:
			"",
		links: [
			"wie-betaalt-ggd"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"wie-betaalt-ggd",
		titel:"Wie betaalt wat voor de taken van de GGD?",
		kleur:"3",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"geldstromen-rijksvaccinatieprogramma",
		titel:"Hoe lopen de geldstromen voor het Rijksvaccinatieprogramma?",
		kleur:"3",
		tekst:
			"",
		links: [
			"wie-betaalt-rijksvaccinatieprogramma"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"wie-betaalt-rijksvaccinatieprogramma",
		titel:"Wie betaalt wat voor het Rjksvaccinatieprogramma?",
		kleur:"3",
		tekst:
			"",
		links: []
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"visualisatie",
		naam:"geldstromen-bevolkingsonderzoek",
		titel:"Hoe lopen de geldstromen in de bevolkingsonderzoeken naar kanker?",
		kleur:"3",
		tekst:
			"",
		links: [
			"wie-betaalt-bevolkingsonderzoek"
		]
	},
	{
		categorie:"publieke-gezondheidszorg",
		template:"harmonica",
		naam:"wie-betaalt-bevolkingsonderzoek",
		titel:"Wie betaalt wat voor de bevolkingsonderzoeken naar kanker?",
		kleur:"3",
		tekst:
			"",
		links: []
	}
];

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
	if (pagina.template === "harmonica") {
		const harmonicaNaam = pagina.naam.replace(/-/g, "_");
		pagina.harmonica = harmonicas[harmonicaNaam];
	}
	if (pagina.template === "visualisatie") {
		const visualisatieNaam = pagina.naam.replace(/-/g, "_");
		pagina.vis = vissen[visualisatieNaam];
	}
	return pagina;
});
export default pages;
