import React from 'react';

import AantalSEHafdelingen from './visualizations/aantal-seh-afdelingen.js';
import RijtijdSEH from './visualizations/rijtijd-seh.js';
import ZorguitgavenGgz from './visualizations/zorguitgaven-ggz.js';
import AantalGgzBedden from './visualizations/aantal-ggz-bedden.js';
import ToezichtOuderenzorg from './visualizations/toezicht-ouderenzorg.js';
import GeldOuderenzorg from './visualizations/geld-ouderenzorg.js';
import AantalBevallingen from './visualizations/aantal-bevallingen';
import UitgavenGeboortezorg from './visualizations/uitgaven-geboortezorg';
import SoortenInformeleZorg from './visualizations/soorten-informele-zorg';
import OntvangersInformeleZorg from './visualizations/ontvangers-informele-zorg';
import BekendheidClientondersteuner from './visualizations/bekendheid-clientondersteuner';
import HoeveelJongeren from './visualizations/hoeveel-jongeren';
import Verblijftijd from './visualizations/verblijftijd';
import HoeveelMeldingen from './visualizations/hoeveel-meldingen';
import Bereidheid from './visualizations/bereidheid';

const vissen = {
	aantalSEHafdelingen: <AantalSEHafdelingen />,
	rijtijdSEH: <RijtijdSEH />,
	zorguitgaven_ggz: <ZorguitgavenGgz />,
	aantal_ggz_bedden: <AantalGgzBedden />,
	toezicht_ouderenzorg: <ToezichtOuderenzorg />,
	geld_ouderenzorg: <GeldOuderenzorg />,
	aantal_bevallingen: <AantalBevallingen />,
	uitgaven_geboortezorg: <UitgavenGeboortezorg />,
	soorten_informele_zorg: <SoortenInformeleZorg />,
	ontvangers_informele_zorg: <OntvangersInformeleZorg />,
	bekendheid_clientondersteuner: <BekendheidClientondersteuner />,
	hoeveel_jongeren: <HoeveelJongeren />,
	verblijftijd: <Verblijftijd />,
	hoeveel_meldingen: <HoeveelMeldingen />,
	bereidheid: <Bereidheid />,
};

export default vissen;
