import React from 'react';

const Twitter = () => (
	<svg viewBox="0 0 44 44" className="icon">
		<path
			d="M10.5,11.5v17a7,7,0,0,0,7,7h12a3,3,0,0,0,3-3v-1a3,3,0,0,0-3-3h-8c-3,0-4-1-4-4v-4h12a3,3,0,0,0,3-3v-1a3,3,0,0,0-3-3h-12v-2a3,3,0,0,0-3-3h-1A3,3,0,0,0,10.5,11.5Z"
			fill="none"
			strokeMiterlimit="10"
			/>
	</svg>
);

export default Twitter;

