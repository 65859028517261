import React from 'react';

import SchattingenDoelgroep from './visualizations/schattingen-doelgroep';
import Participatie from './visualizations/participatie';
import WerknemersGehandicaptenzorg from './visualizations/werknemers-gehandicaptenzorg';
import OntwikkelingWerknemers from './visualizations/ontwikkeling-werknemers';
import OntwikkelingUitgaven from "./visualizations/ontwikkeling-uitgaven";
import UitgavenWlz from "./visualizations/uitgaven-wlz";
import GeldstromenWLZ from "./visualizations/geldstromen-wlz";
import GeldstromenZVW from "./visualizations/geldstromen-zvw";
import GeldstromenWMO from "./visualizations/geldstromen-wmo";
import GeldstromenJeugdwet from "./visualizations/geldstromen-jeugdwet";

const vissen = {
  schattingen_doelgroep: <SchattingenDoelgroep />,
  participatie: <Participatie />,
  werknemers_gehandicaptenzorg: <WerknemersGehandicaptenzorg />,
  ontwikkeling_werknemers: <OntwikkelingWerknemers />,
  ontwikkeling_uitgaven: <OntwikkelingUitgaven />,
  uitgaven_wlz: <UitgavenWlz />,
  geldstromen_wlz: <GeldstromenWLZ />,
  geldstromen_zvw: <GeldstromenZVW />,
  geldstromen_wmo: <GeldstromenWMO />,
  geldstromen_jeugdwet: <GeldstromenJeugdwet />,
};

export default vissen;
