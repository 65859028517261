import React from "react";
import { ReactComponent as TheSvg} from "./inkoop-zorgverzekeraars.svg";

const InkoopZorgverzekeraars = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />
        </div>
    )
};

export default InkoopZorgverzekeraars;
