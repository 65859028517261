import React from 'react';
import { ReactComponent as TheSvg } from './effecten-betaald-werk-aangepast.svg'

const EffectenBetaaldWerk = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />
            <div className="vis-euros-vws">
                <div className="vis-credit">
                    <p>Gemiddelde scores van vijf indicatoren voor gezondheid en welzijn na langdurige werkloosheid en na 2 jaar betaald werk van 32 uur per week of meer.</p>
                    <p>Bron: Erasmus UMC, 2017</p>
                </div>
            </div>
        </div>
    );
}

export default EffectenBetaaldWerk;
