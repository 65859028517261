import React from 'react';
import { ReactComponent as TheSvg } from './sociaal-domein-aangepast.svg'

const Hoofdrolspelers = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />

            <div className="vis-euros-vws">
                <div className="vis-credit">
                    <p>Onder het sociaal domein vallen: maatschappelijke ondersteuning, jeugdzorg en ondersteuning bij werk en inkomen.</p>
                </div>
            </div>
        </div>
    );
}

export default Hoofdrolspelers;
