import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "harmonica",
  naam: "spelers-aanpak",
  titel: "Wat zijn belangrijke spelers in de aanpak van huiselijk geweld en kindermishandeling?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'wat-is-huiselijk-geweld',
    'hoeveel-meldingen',
  ]
}
