import React from 'react';

import Beroepsbevolking from './visualizations/beroepsbevolking';
import Rijksuitgaven from './visualizations/rijksuitgaven';
import Aantal80Jaar from './visualizations/aantal-80-jaar';
import ChronischeZiektes from './visualizations/chronische-ziektes';
import GebruikGgz from './visualizations/gebruik-ggz';

const vissen = {
	beroepsbevolking: <Beroepsbevolking />,
	rijksuitgaven: <Rijksuitgaven />,
	aantal_80_jaar: <Aantal80Jaar />,
	chronische_ziektes: <ChronischeZiektes />,
	gebruik_ggz: <GebruikGgz />,
};

export default vissen;
