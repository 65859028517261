import React from "react";
import BarChart from "../../../../../components/BarChart/BarChart";

const Beroepsbevolking = () => {
	const formatValueTooltip = val => val + "%";
	const formatValueY = val => Math.round(val) + "%";

	const data = [
		{ key: "Gezondheids- & welzijnszorg*", value: 17, highlight: true },
		{ key: "Handel", value: 15 },
		{ key: "Zakelijke diensten", value: 13 },
		{ key: "Industrie", value: 8 },
		{ key: "Onderwijs", value: 8 },
		{ key: "Horeca, cultuur & recreatie", value: 7 },
		{ key: "Bouw", value: 4 },
		{ key: "Vervoer & opslag", value: 5 },
		{ key: "Communicatie", value: 4 },
		{ key: "Financiële dienstverlening", value: 3 },
		{ key: "Landbouw & energie", value: 3 },
		{ key: "Overig & onbekend", value: 6 },
	];

	return (
		<div className="vis-beroepsbevolking">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 239 }}
				barHeight={30}
				domain={[0, 17.2]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			<div className="vis-credit">
				<p>Beroepsbevolking per sector als deel van de totale beroepsbevolking in 2021.</p>
				<p>*Exclusief kinderopvang.</p>
				<p>Bron: CBS Statline, 2022.</p>
			</div>
		</div>
	);
};

export default Beroepsbevolking;
