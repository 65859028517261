import React from 'react';
import LineChart from '../../../components/LineChart/LineChart';
import { formatLocale } from 'd3';

const OntwikkelingWerknemers = () => {
	const margin = {top: 60, right: 30, bottom: 90, left: 45};

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => `${localeFormatter.format(',.0f')(val)}`;
	const formatYTooltip = (val) => `${localeFormatter.format(',.0f')(val)} duizend werknemers`;

	const data = [
			{
				name: 'gebruikers',
				values: [
					{ x: 2015, y: 163.3 },
					{ x: 2016, y: 163.8 },
					{ x: 2017, y: 168.8 },
					{ x: 2018, y: 168.6 },
					{ x: 2019, y: 180.5 },
				]
			}
		];


	return (
		<div className="vis-ontwikkeling-werknemers">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="x 1000"
				domainY={[100, 200]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatYTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal duizend zorgprofessionals in de gehandicaptenzorg, van 2015 tot en met 2019.</p>
				<p>Bron: AZW Statline, 2020</p>
			</div>
		</div>
);
}

export default OntwikkelingWerknemers;


