import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"wat-is-geboortezorg",
  titel:"Wat is geboortezorg en uit welke vormen bestaat het?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'aantal-bevallingen',
    'uitgaven-geboortezorg',
  ]
}
