import React from 'react';
import BarChart from '../../../../../components/BarChart/BarChart';
import {formatLocale} from "d3";

const Score = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatValueTooltip = (val) => `${localeFormatter.format(',.0f')(val * 100000)}`;
  const formatValueY = (val) => `${val}`;

  const data = [
    { key: 'Iceland', value: 62 },
    { key: 'Norway', value: 62 },
    { key: 'France', value: 63 },
    { key: 'Italy', value: 67 },
    { key: 'Spain', value: 67 },
    { key: 'Sweden', value: 68 },
    { key: 'Netherlands', value: 69, highlight: true },
    {key: 'Luxembourg', value: 	71},
    {key: 'Cyprus', value: 	71},
    {key: 'Belgium', value: 71},
    {key: 'Denmark', value: 76},
    {key: 'Finland', value: 77},
    {key: 'Austria', value: 78},
    {key: 'Slovenia', value: 80},
    {key: 'Ireland', value: 80},
    {key: 'Germany', value: 87},
    {key: 'Malta', value: 87},
    {key: 'Portugal', value: 89},
    {key: 'United Kingdom', value:  90},
    {key: 'EU', value: 93, highlight: true},
    {key: 'Greece', value: 95},
    {key: 'Czechia', value: 128},
    {key: 'Poland', value: 130},
    {key: 'Croatia', value: 140},
    {key: 'Estonia', value: 143},
    {key: 'Slovakia', value: 168},
    {key: 'Hungary', value: 176},
    {key: 'Bulgaria', value: 194},
    {key: 'Latvia', value: 203},
    {key: 'Lithuania', value: 206},
    {key: 'Romania', value: 208},
  ];

  return (
    <div className="vis-score">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 200 }}
        barHeight={30}
        domain={[0,208]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <div className="vis-credit">
        <p>Age-standardised mortality rates per 100 000 population in European countries in 2016.</p>
        <p>Source: OECD/European Observatory on Health Systems and Policies (2019).</p>
      </div>
    </div>
  );
}

export default Score;
