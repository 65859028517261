import {COLOR_MAGENTA} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "harmonica",
  naam: "bekostiging-ondersteuning-op-school",
  titel: "Hoe is de bekostiging van ondersteuning op school geregeld?",
  kleur: COLOR_MAGENTA,
  tekst: "",
  links: [
    'wat-is-ondersteuning-op-school',
    'rollen-ondersteuning-op-school'
  ]
}
