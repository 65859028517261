import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
    template: "intro",
    naam: "mini-medische-gegevens",
    titel: "Zó werkt de zorg-mini: Medische gegevens",
    kleur: COLOR_YELLOW,
    tekst:
        "Medische gegevens zijn heel persoonlijk. Het verzamelen en delen van deze gegevens ligt dan ook gevoelig. In 2011 is een initiatief voor een landelijk elektronisch patiëntendossier (epd) onder maatschappelijke druk verworpen. Elke zorgverlener houdt dus een eigen medisch dossier bij van zijn patiënten. Wat zijn medische gegevens? Wat mag wel en niet met medische gegevens? En in hoeverre zijn Nederlanders bereid toestemming te geven voor het delen van hun medische gegevens? Je leest het in deze mini. Waar patiënt staat wordt ook cliënt bedoeld.",
    links: [
        'verzamelen',
        'delen',
        'bereidheid',
        'colofon-medische-gegevens'
    ]
}
