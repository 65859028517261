import React from 'react';

import HoeveelOuderen from './visualizations/hoeveel-ouderen.js';
import AchtergrondOuderen from './visualizations/achtergrond-ouderen.js';
import BevolkingVerpleeghuis from './visualizations/bevolking-verpleeghuis.js';
import FysiekeBeperkingen from './visualizations/fysieke-beperkingen.js';
import Mantelzorgers from './visualizations/mantelzorgers.js';
import LeeftijdMantelzorgers from './visualizations/leeftijd-mantelzorgers.js';
import Zorgtreden from  './visualizations/zorgtreden.js';
import OuderenPerZorgtrede from './visualizations/ouderen-per-zorgtrede.js';
import Zorgroutes from './visualizations/zorgroutes.js';
import HoeveelWerknemers from './visualizations/hoeveel-werknemers.js';
import DienstverbandWerknemers from './visualizations/dienstverband-werknemers.js';
import FunctiesOuderenzorg from './visualizations/functies-ouderenzorg.js';
import Studenten from './visualizations/studenten.js';
import UitgavenLeeftijdsgroep from  './visualizations/uitgaven-leeftijdsgroep.js';
import KostenZorgtreden from  './visualizations/kosten-zorgtreden.js';
import UitgavenZorgvormen from  './visualizations/uitgaven-zorgvormen.js';
import GeldstromenWLZ from '../basis/visualizations/geldstromen-wlz.js';
import GeldstromenWMO from '../basis/visualizations/geldstromen-wmo.js';
import GeldstromenZVW from '../basis/visualizations/geldstromen-zvw.js';

const vissen = {
	hoeveel_ouderen: <HoeveelOuderen />,
	achtergrond_ouderen: <AchtergrondOuderen />,
	bevolking_verpleeghuis: <BevolkingVerpleeghuis />,
	fysieke_beperkingen: <FysiekeBeperkingen />,
	mantelzorgers: <Mantelzorgers />,
	leeftijd_mantelzorgers: <LeeftijdMantelzorgers />,
	zorgtreden: <Zorgtreden />,
	ouderen_per_zorgtrede: <OuderenPerZorgtrede />,
	zorgroutes: <Zorgroutes />,
	hoeveel_werknemers: <HoeveelWerknemers />,
	dienstverband_werknemers: <DienstverbandWerknemers />,
	functies_ouderenzorg: <FunctiesOuderenzorg />,
	studenten: <Studenten />,
	uitgaven_leeftijdsgroep: <UitgavenLeeftijdsgroep />,
	kosten_zorgtreden: <KostenZorgtreden />,
	uitgaven_zorgvormen: <UitgavenZorgvormen />,
	geldstromen_wlz: <GeldstromenWLZ />,
	geldstromen_wmo: <GeldstromenWMO />,
	geldstromen_zvw: <GeldstromenZVW />
};

export default vissen;
