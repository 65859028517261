export default {"tekst": "Hoe ziet de opleiding tot huisarts eruit?","items": [{
    "tekst": "Geneeskunde",
    "items": [{
        "tekst": "Huisartsen in spe volgen eerst de 6-jarige geneeskundeopleiding (waarvoor selectie plaatsvindt)."
    }, {
        "tekst": "Na afronding van de geneeskundeopleiding zijn de afgestudeerden basisarts."
    }]
}, {
    "tekst": "Specialisatie",
    "items": [{
        "tekst": "Een basisarts wordt (na selectie) aangenomen op de specialisatieopleiding Huisartsgeneeskunde."
    }, {
        "tekst": "De specialisatieopleiding Huisartsgeneeskunde duurt drie jaar."
    }, {
        "tekst": "Een arts in opleiding tot specialist, zoals Huisartsgeneeskunde, heet aios."
    }, {
        "tekst": "De aios werkt het grootste deel van de tijd in de praktijk en heeft terugkomdagen op het opleidingsinstituut."
    }, {
        "tekst": "De aios werkt voornamelijk op de praktijk, maar ook in een ziekenhuis, verpleeghuis of ggz-instelling."
    }, {
        "tekst": "De aios wordt in de praktijk begeleid door één à twee vaste huisartsopleiders."
    }]
}, {
    "tekst": "(Her)registratie",
    "items": [{
        "tekst": "Na afronding van de huisartsopleiding volgt vermelding in het BIG-register als huisarts."
    }, {
        "tekst": "De huisarts moet zich elke vijf jaar opnieuw laten registreren als bevoegd huisarts."
    }, {
        "tekst": "Voor herregistratie gelden voorwaarden zoals voldoende uren als huisarts werken en nascholing volgen."
    }]
}]}