import React from 'react';
import LineChart from '../../../components/LineChart/LineChart';


const KostenPerPersoon = () => {

	const margin = {top: 60, right: 30, bottom: 90, left: 45};

	const formatY = value => value;
	const formatValueTooltip = value => value + ' euro';


	const data = [
			{
				name: 'gebruikers',
				values: [
					{ x: 2009, y: 130 },
					{ x: 2010, y: 131 },
					{ x: 2011, y: 142 },
					{ x: 2012, y: 136 },
					{ x: 2013, y: 139 },
					{ x: 2014, y: 149 },
					{ x: 2015, y: 149 },
					{ x: 2016, y: 152 }
				]
			}
		];


	return(
		<div className="vis-kosten-per-persoon">

			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="euro"
				domainY={[120, 150]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Jaarlijkse kosten aan huisartsenzorg in euro</p>
				<p>Bron: CBS Statline 2018</p>
			</div>
		</div>
	);
}

export default KostenPerPersoon;


