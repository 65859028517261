import React from "react";
import Links from "../Links/Links";
import { Highlight } from 'react-highlighter-ts';
import codes from "../../data/codes.js";
import inputCodeToEncryptedCode from './input-code-to-encrypted-code'

import "./SpecialIndex.scss";

const SpecialIndex = (props) => {
  const { title, text, links, pages, searchstring } = props;

  const handleChange = event => {
    const thisCode = inputCodeToEncryptedCode(event.target.value)
    const hits = codes.filter(item => thisCode === item.code);
    if (hits.length > 0) {
      const { specials, organisation, url } = hits[0];
      props.addaccess(specials);
      props.setorganisation({ name: organisation, url });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  const onKeyPress = event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  const showForm = false;
  const loginForm = showForm ? (
    <form onSubmit={handleSubmit} onKeyPress={onKeyPress}>
      <input
        aria-label="input code"
        className="specialindex-input"
        type="text"
        onChange={handleChange}
        placeholder="code..."
      />
    </form>
  ) : null;

  return (
    <div className="specialindex">
      <div className="specialindex-title">
        <Highlight search={searchstring}>{title}</Highlight>
      </div>
      <div className="specialindex-text">
        <Highlight search={searchstring}>{text}</Highlight>
      </div>
      {loginForm}
      <Links
        categorie="basis"
        specialindex={true}
        links={links}
        pages={pages}
        access={props.access}
      />
    </div>
  );
};

export default SpecialIndex;
