import React from 'react';

import Beroepsbevolking from './visualizations/beroepsbevolking';
import WerkendenZorg from './visualizations/werkenden-zorg';
import OpenstaandeVacatures from './visualizations/openstaande-vacatures';
import BenodigdeZorgmedewerkers from './visualizations/benodigde-zorgmedewerkers';
import Stijging90Plussers from './visualizations/stijging-90-plussers';
import Dementie from './visualizations/dementie';
import EersteHulp from './visualizations/eerste-hulp';

const vissen = {
  beroepsbevolking: <Beroepsbevolking/>,
  werkenden_zorg: <WerkendenZorg/>,
  openstaande_vacatures: <OpenstaandeVacatures/>,
  benodigde_zorgmedewerkers: <BenodigdeZorgmedewerkers/>,
  stijging_90_plussers: <Stijging90Plussers/>,
  dementie: <Dementie/>,
  eerste_hulp: <EersteHulp/>,
};

export default vissen;
