import React from 'react';
import Donut from '../../../components/Donut/Donut';
import {formatLocale} from 'd3';

const ForensischAantal = () => {
  const data = [
    {
      "label": "Ambulant",
      "value": 12734,
    },
    {
      "label": "Beschermd wonen",
      "value": 1876,
    },
    {
      "label": "Klinisch",
      "value": 1082,
    },
    {
      "label": "Tbs",
      "value": 1329,
    },
  ];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatLabel = item => {
    const shortLabel = item.label;
    return `${shortLabel} ${localeFormatter.format(',.0f')(item.value)}`;
  }

  const formatTotal = () => (
    <text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0}>Totaal</tspan>
      <tspan x={0} dy={15}>17 duizend</tspan>
      <tspan x={0} dy={18}>mensen</tspan>
    </text>);

  return (
    <div className="vis-forensisch-aantal">
      <Donut
        data={data}
        height={400}
        fontFamily="din-regular"
        fontSize="15px"
        formatLabel={formatLabel}
        formatTotal={formatTotal}
        unit="mensen"
        animation={true}
      />

      <div className="vis-credit">
        <p>Aantal mensen dat in 2019 gebruik maakt van een vorm van forensische ggz.</p>
        <p>Bron: DJI, 2020</p>
      </div>
    </div>
  );
}

export default ForensischAantal;
