import intro from './intro'
import watIs from './wat-is'
import bekostiging from './bekostiging'
import rollen from './rollen'
import colofon from './colofon'

export default {
  intro,
  watIs,
  bekostiging,
  rollen,
  colofon,
}
