import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import {formatLocale} from 'd3';

const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
});

const HoeveelMeldingen = () => {
    const formatValueY = val => `${localeFormatter.format(',.0f')(val)}`
	const formatValueTooltip = val => localeFormatter.format(',.0f')(val)

  const data2021 = [
      { key: 'Kindermishandeling', value: 60905 },
      { key: '(Ex-)Partnergeweld', value: 45810 },
      { key: 'Andere problematiek', value: 26310 },
      { key: 'Huiselijk geweld overig', value: 14200 },
      { key: 'Geweld tegen ouders', value: 6050 },
      { key: 'Ouderenmishandeling (65+ jaar)', value: 2370 },
      { key: 'Onbekend', value: 3430 },
  ];

	return (
		<div className="vis-hoeveel-meldingen">

      <h4>2021</h4>
      <BarChart
        data={data2021}
        margin={{ top: 10, right: 0, bottom: 10, left: 300 }}
        barHeight={30}
        domain={[0,60905]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-80}
      />

      <div className="vis-credit">
        <p>Aantal meldingen per soort huiselijk geweld bij Veilig Thuis in 2021. Het aantal meldingen is niet hetzelfde als het aantal slachtoffers, omdat niet bij ieder slachtoffer een melding bij Veilig Thuis is gedaan en omdat voor één slachtoffer meerdere meldingen kunnen bestaan.</p>
        <p>CBS Statline, cijfers 2021</p>
      </div>

      <br/>
	</div>
	)
}

export default HoeveelMeldingen;
