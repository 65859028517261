import React from 'react';

import "./Visualization.scss";
import "./basis.scss";
import "./huisartsen.scss";
import "./ouderenzorg.scss";
import "./publiekeGezondheidszorg.scss";
import "./zorgverzekering.scss";
import "./mini.scss";
import "./gidsen.scss";
import "./geneesmiddelenzorg.scss";
import "./gehandicaptenzorg.scss";
import "./ggz.scss";
import "./sociaal-domein.scss";

const Visualization = (props) => {
	if (!props.children) return <div className='placeholder'>Hier komt een visualisatie.</div>

	return <div className={'vis ' + props.category + ' ' + props.name}>{props.children}</div>
}

export default Visualization;
