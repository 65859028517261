import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../components/BarChart/BarChart';

const OuderenPerZorgtrede = () => {

	const data = [
		{
			key: "Trede 1",
			description: "Gebruik van eerstelijnszorg",
			value: 748
		},
		{
			key: "Trede 2",
			description: "Bezoek aan een medisch specialist en/of ziekenhuisopname",
			value: 1600
		},
		{
			key: "Trede 3",
			description: "Hulpmiddelen of voorzieningen",
			value: 96
		},
		{
			key: "Trede 4",
			description: "Tijdelijke persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis",
			value: 172
		},
		{
			key: "Trede 5",
			description: "Structurele persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis",
			value: 329
		},
		{
			key: "Trede 6",
			description: "Opname in een verzorgings- of verpleeghuis",
			value: 189
		}
	];


  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val => localeFormatter.format(',.0f')(val) + ' duizend';


	return (
		<div className="vis-ouderen-per-zorgtrede">

      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 170 }}
        barHeight={30}
        domain={[0,1600]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueTooltip}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-120}
        animation={true}
        tooltipPosition="mouse"
      />

			<div className="vis-credit">
				<p>Aantal personen van 65 jaar of ouder dat in 2016 zorg kreeg volgens een zorgtrede.</p>
				<p>Bron: Nederlandse Zorgautoriteit, Zorg voor ouderen 2018.</p>
			</div>




		</div>
	);
}

export default OuderenPerZorgtrede;


