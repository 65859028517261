import React from 'react';

import Hoofdrolspelers from './visualizations/hoofdrolspelers';
import Zorginkopers from './visualizations/zorginkoper';
import Markten from './visualizations/markten';
import AndereSpelers from './visualizations/andere-spelers';

const vissen = {
	hoofdrolspelers: <Hoofdrolspelers />,
	zorginkopers: <Zorginkopers />,
	markten: <Markten />,
	andere_spelers: <AndereSpelers />,
};

export default vissen;
