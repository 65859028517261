import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_GREEN} from "../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "gids-inkoopproces-ggz",
    titel: "Inkoopproces ggz",
    kleur: COLOR_GREEN,
    tekst:
      "Bij de inkoop van ggz-behandelingen zijn allerlei partijen betrokken en het inkoopproces speelt zich over meerdere jaren af. Wie doet wat wanneer om tot een inkoopcontract te komen? En met welke contracten heb je als aanbieder op één moment te maken? Het gehele inkoopproces vind je nu in een vijftal bondige en heldere visualisaties.",
    links:
      [
        'kaders',
        'wie-wat-wanneer',
        'wie-wat',
        '/basis/ggz',
      ]
  },
  {
    template: "visualisatie",
    naam: "kaders",
    titel: "Binnen welke kaders vindt het inkoopproces van ggz-behandelingen uit de Zorgverzekeringswet plaats?",
    kleur: COLOR_GREEN,
    links: [
        'wie-wat-wanneer'
    ]
  },
  {
    template: "visualisatie",
    naam: "wie-wat-wanneer",
    titel: "Wie doet wat en wanneer om tot inkoopcontracten voor ggz-behandelingen te komen en deze te beheren?",
    kleur: COLOR_GREEN,
    links: [
      'kaders'
    ]
  },
  {
    template: "harmonica",
    naam: "wie-wat",
    titel: "Wie doet wat om tot inkoopcontracten voor ggz-behandelingen te komen en deze te beheren?",
    kleur: COLOR_GREEN,
    links: [
        'kaders'
    ]
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
