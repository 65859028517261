import React from 'react';

const Zorgverzekering = () => (
<svg viewBox="0 0 100 100" className="af-icon">
	<g id="naarSVG" stroke="none">
		<rect x="48.75" y="55" width="2.5" height="25"/>
		<path d="M81.25,49.5a31.25,31.25,0,0,0-62.5,0h0a4.19,4.19,0,0,0,0,.5h2.5a3.75,3.75,0,0,1,7.5,0h2.5a3.75,3.75,0,0,1,7.5,0h2.5a3.75,3.75,0,0,1,7.5,0h2.5a3.75,3.75,0,0,1,7.5,0h2.5a3.75,3.75,0,0,1,7.5,0h2.5a3.75,3.75,0,0,1,7.5,0h2.5a4.19,4.19,0,0,0,0-.5ZM75,43.75a6.22,6.22,0,0,0-5,2.52,6.22,6.22,0,0,0-10,0,6.22,6.22,0,0,0-10,0,6.22,6.22,0,0,0-10,0,6.22,6.22,0,0,0-10,0,6.22,6.22,0,0,0-8.35-1.54,28.75,28.75,0,0,1,56.7,0A6.2,6.2,0,0,0,75,43.75Z"/>
	</g>
</svg>
);

export default Zorgverzekering;