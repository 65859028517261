import gedachteDoelenPages from './gedachte-doelen/pages'
import cijfersPages from './cijfers/pages'
import kennisKwaliteitPages from './kennis-kwaliteit/pages'

const pages = [
    {
        categorie: "basis",
        template: "intro",
        naam: "zorg",
        titel: "Wat is zorg?",
        kleur: 6,
        tekst:
            "De zorgsector is groot en complex. Wat is de gedachte achter het huidige zorgstelsel? En hoe zorgen we dat we goede zorg kunnen bieden aan iedereen die dit nodig heeft? Dit en meer lees je hieronder.",
        links: [
            "gedachte-doelen",
            "cijfers",
            "kennis-kwaliteit",
        ]
    },
    ...gedachteDoelenPages,
    ...cijfersPages,
    ...kennisKwaliteitPages,
]

export default pages
