export default {"tekst": "Wie is medisch actief in de huisartsenpraktijk?",
"items": [{
    "tekst": "Doktersassistent",
    "items": [{
        "tekst": "De doktersassistent plant afspraken in na een inschatting van de ernst van het probleem (triage)."
    }, {
        "tekst": "De doktersassistent verricht handelingen zoals oren uitspuiten en bloeddruk meten."
    }]
}, {
    "tekst": "Praktijkondersteuner (POH)",
    "items": [{
        "tekst": "De POH ondersteunt de huisarts door het begeleiden van bepaalde groepen patiënten."
    }, {
        "tekst": "De POH-GGZ richt zich op kortdurende begeleiding van mensen met psychische problemen."
    }, {
        "tekst": "De POH-Somatiek begeleidt patiënten met een chronische lichamelijke aandoening, zoals diabetes, astma of COPD."
    }]
}, {
    "tekst": "Physician assistant",
    "items": [{
        "tekst": "De physician assistant stelt diagnoses en doet laag-complexe medische handelingen."
    }, {
        "tekst": "De physician assistant is medisch breed inzetbaar."
    }]
}, {
    "tekst": "Verpleegkundig specialist",
    "items": [{
        "tekst": "De verpleegkundig specialist stelt diagnoses en handelt niet-complexe zorgvragen af."
    }, {
        "tekst": "De verpleegkundig specialist richt zich op een bepaalde patiëntengroep."
    }]
}, {
    "tekst": "Arts in opleiding tot specialist (aios)",
    "items": [{
        "tekst": "Een aios is een basisarts die in opleiding is tot specialist, zoals specialist Huisartsgeneeskunde."
    }]
}, {
    "tekst": "Co-assistent",
    "items": [{
        "tekst": "De co-assistent is een geneeskundestudent, die ervaring opdoet in de praktijk."
    }]
}]}
