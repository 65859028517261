import React from 'react';
import LineChart from '../../../components/LineChart/LineChart';
import { formatLocale } from 'd3';

const UitgavenGgz = () => {
	const margin = {top: 60, right: 30, bottom: 90, left: 45};

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => `${localeFormatter.format(',.1f')(val)}`;
	const formatYTooltip = (val) => `${localeFormatter.format(',.1f')(val)} miljard euro`;

	const data = [
			{
				name: 'uitgaven',
				values: [
					{ x: 2014, y: 6.8 },
					{ x: 2015, y: 6.3 },
					{ x: 2016, y: 6.5 },
					{ x: 2017, y: 6.5 },
					{ x: 2018, y: 6.8 },
					{ x: 2019, y: 7.1 }
				]
			}
		];

	return (
		<div className="vis-uitgaven-ggz">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="x 1000"
				domainY={[5, 10]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatYTooltip}
			/>

			<div className="vis-credit">
				<p>Uitgaven aan geestelijke gezondheidszorg in miljard euro.</p>
				<p>Bron: CBS Statline, 2020.</p>
			</div>

		</div>
);
}

export default UitgavenGgz;
