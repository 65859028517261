import kostenPages from './kosten/pages'
import bekostigingPages from './bekostiging/pages'
import inkoopPages from './inkoop/pages'
import {COLOR_CYAAN} from "../../../../vocabulary";

const pages = [
    {
        categorie: "basis",
        template: "intro",
        naam: "geld",
        titel: "Hoe en hoeveel betalen we voor zorg?",
        kleur: COLOR_CYAAN,
        tekst:
            "Goede gezondheid is ons veel waard. Hier betalen we met zijn allen aan mee. Hoe werkt dit precies? Wie betaalt voor welke zorg en hoeveel?",
        links: [
            "kosten",
            "bekostiging",
            "inkoop"
        ]
    },
    ...kostenPages,
    ...bekostigingPages,
    ...inkoopPages,
]

export default pages
