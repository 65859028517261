import React from 'react';
import { Helmet } from 'react-helmet';

const SocialHeader = props => {

  const {template, titel, naam } = props;
  const baseurl = process.env.REACT_APP_ENV === 'development' ? 'https://dev.zwdz.nl' : 'https://app.zwdz.nl';
  const category = props.category || 'basis';
  let socialTitle = 'Zó werkt de zorg';
  let socialUrl = baseurl + '/' + naam + ".html";
  let socialImage = baseurl + '/img/zo-werkt-de-zorg.png';
  if (template === 'visualisatie' || template === 'harmonica') socialTitle = 'Zó werkt de zorg | ' + titel;
  if (template === 'visualisatie') socialImage = baseurl +  '/img/' + category + '/' + naam + '.png';
  const robots = process.env.REACT_APP_ENV === 'development' ? 'noindex, nofollow' : 'index, follow';

  return (
      <Helmet
        title={socialTitle}
        link={[
          {"rel": "canonical", "href": socialUrl }
          ]}
        meta={[
          { name: 'robots', content: robots},
          { property: 'og:title', content: 'Zó werkt de zorg' },
          { property: 'og:description', content: titel },
          { property: 'og:url', content: socialUrl },
          { property: 'og:image', content: socialImage},
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@ZoWerktDeZorg'},
          { name: 'twitter:creator', content:'@ZoWerktDeZorg'},
          { name: 'twitter:title', content:'Zó werkt de zorg'},
          { name: 'twitter:description', content: titel + ' ' + socialUrl },
          { name: 'twitter:image', content: socialImage },
          ]}
      />
    )
}

export default SocialHeader;
