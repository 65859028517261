import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Highlight } from 'react-highlighter-ts';

import Menu from '../Menu/Menu';
import Colophon from '../Colophon/Colophon';
import About from '../About/About';
import SiteMap from '../SiteMap/SiteMap';
import Glossary from '../Glossary/Glossary';
import SearchResults from '../SearchResults/SearchResults';
import Tiles from '../Tiles/Tiles';
import SpecialFrontpage from '../SpecialFrontpage/SpecialFrontpage';
import Intro from '../Intro/Intro';
import Harmonica from '../Harmonica/Harmonica';
import IntroHarmonica from '../IntroHarmonica/IntroHarmonica';
import Visualization from '../Visualization/Visualization';
import SpecialIndex from '../SpecialIndex/SpecialIndex';
import Links from '../Links/Links';
import ShareButtons from '../ShareButtons/ShareButtons';
import icons from '../Icons/icons';
import {useLocation} from "react-router";

const CentraleVraag = ({titel, searchstring, category}) => {
  const icon = category === 'basis' ? null :
    <Link to={'/' + category} title={'Voorpagina ' + category}>{icons[category]}</Link>;

  const titleClass = category !== 'basis' ? 'title special' : 'title';
  return (
    <div className={titleClass}>
      <Highlight search={searchstring}>
        {titel}
      </Highlight>
        {icon}
    </div>
  )
}

const PageContent = (props) => {
    const location = useLocation()

    const { page, searchstring, menuopen } = props;

    useEffect(() => {
        if (searchstring.length > 2 && page.template !== 'zoeken' && !location.state.fromSearchResults) {
            props.search('')
        }
    }, [])

    if (menuopen) {
        return <Menu {...props} />
    }

    switch (page.template) {
      case 'tegels':
        return (<Tiles {...props} />)
      case 'specialvoorpagina':
        return (
          <SpecialFrontpage
            title={page.titel}
            icoon={page.icoon}
            text={page.tekst}
            categorie={page.naam}
            links={page.links}
            pages={props.pages}
            searchstring={props.searchstring}
            {...props}
            />
          )
      case 'zoeken':
        return (<SearchResults {...props} />)
      case 'colofon':
        return (<Colophon {...props} />)
      case 'platform':
        return (<About {...props} />)
      case 'sitemap':
        return (<SiteMap {...props} />)
      case 'begrippen':
        return (<Glossary {...props} />)
      case 'intro':
        return (
          <Intro titel={page.titel} icoon={page.icoon} text={page.tekst} categorie={page.categorie} links={page.links} pages={props.pages} searchstring={props.searchstring} />
          )
      case 'visualisatie':
        return (
          <div className="visualisatie">
            <CentraleVraag titel={page.titel} searchstring={props.searchstring} category={page.categorie} />
            <Visualization category={page.categorie} name={page.naam} >{page.vis}</Visualization>
            <Links categorie={page.categorie} links={page.links} pages={props.pages} />
            <ShareButtons category={page.categorie} text={page.titel} />
          </div>
          )
      case 'harmonica':
        return (
          <div className="page-harmonica">
            <CentraleVraag titel={page.titel} searchstring={props.searchstring} category={page.categorie} />
            <Harmonica data={page.harmonica} searchstring={props.searchstring} open={location.hash.substring(1)} />
            <Links categorie={page.categorie} links={page.links} pages={props.pages} />
            <ShareButtons category={page.categorie} text={page.titel} />
          </div>
          )
      case 'specialindex':
        return (
          <SpecialIndex
            title={page.titel}
            text={page.tekst}
            links={page.links}
            pages={props.pages}
            searchstring={props.searchstring}
            {...props}
          />
          )
      case 'harmonica-met-intro':
        return (
          <div className="page-harmonica met-intro">
            <CentraleVraag titel={page.titel} searchstring={props.searchstring} category={page.categorie} />
            <IntroHarmonica text={page.tekst} searchstring={props.searchstring} />
            <Harmonica data={page.harmonica} searchstring={props.searchstring} open={location.hash.substring(1)} />
            <ShareButtons category={page.categorie} text={page.titel} />
          </div>
          )

      default:
        return null
    }
}


export default PageContent;
