import React from 'react';
import { ReactComponent as TheSvg } from './mantelzorger-vrijwilliger-aantal-aangepast.svg'

const EffectenBetaaldWerk = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />
            <div className="vis-euros-vws">
                <div className="vis-credit">
                    <p>Aantal Nederlanders van 16 jaar of ouder dat in 2019 actief was als mantelzorger of vrijwilliger in zorg en ondersteuning.</p>
                    <p>Bron: SCP, 2020</p>
                </div>
            </div>
        </div>
    );
}

export default EffectenBetaaldWerk;
