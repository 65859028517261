import React from 'react';
import { Link } from 'react-router-dom';

const AndereSpelers = () => (
	<div className="vis-andere-spelers svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 660 600">

			<path className="geldstroom" d="M348,567c-9.9,0-26.1,0-36,0H81c-9.9,0-18-8.1-18-18v-60c0-9.9,3.1-25.5,6.9-34.6l142.3-344.7 c3.8-9.2,15-16.6,24.9-16.6h75c9.9,0,26.1,0,36,0h75c9.9,0,21.1,7.5,24.9,16.6l142.3,344.7c3.8,9.2,6.9,24.7,6.9,34.6v60 c0,9.9-8.1,18-18,18H348z"/>

			<Link to={{ pathname: '/rol-andere-spelers', hash: '#Overheid' }} >
				<path className="ruit" d="M330,141c0-4.9,2.5-5.8,5.5-1.9l126.9,162.8c3,3.9,1.5,7.1-3.5,7.1H339c-5,0-9-4-9-9V141z"/>
			</Link>

			<Link to={{ pathname: '/rol-andere-spelers', hash: '#Toezichthouders' }} >
				<path className="ruit" d="M459,309c5,0,6.5,3.2,3.5,7.1L335.5,478.9c-3,3.9-5.5,3-5.5-1.9V318c0-4.9,4-9,9-9H459z"/>
			</Link>

			<Link to={{ pathname: '/rol-andere-spelers', hash: '#Uitvoerendeinstanties' }} >
				<path className="ruit" d="M321,309c5,0,9,4.1,9,9v159c0,5-2.6,5.9-5.8,2.1L185.8,315.9c-3.2-3.8-1.8-6.9,3.2-6.9H321z"/>
			</Link>

			<Link to={{ pathname: '/rol-andere-spelers', hash: '#Adviesorganen' }} >
				<path className="ruit" d="M324.2,138.9c3.2-3.8,5.8-2.8,5.8,2.1v159c0,5-4,9-9,9H189c-4.9,0-6.4-3.1-3.2-6.9L324.2,138.9z"/>
			</Link>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Burgers' }} >
				<g className="kader">
					<path d="M270,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
					<text className="tekst" x="330" y="99">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Zorginkopers' }} >
				<g className="kader">
					<path d="M15,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H15z"/>
					<text className="tekst" x="75" y="525">Zorginkopers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/rol-hoofdrolspelers', hash: '#Zorgaanbieders' }} >
				<g className="kader">
					<path d="M525,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H525z"/>
					<text className="tekst" x="585" y="525">Zorgaanbieders</text>
				</g>
			</Link>

			<g className="tekst wit">
				<text x="315" y="275" className="rechts">
					<tspan x="315">Advies-</tspan>
					<tspan x="315" dy="21">organen</tspan>
				</text>
				<text x="315" y="335" className="rechts">
					<tspan x="315">Uitvoerende</tspan>
					<tspan x="315" dy="21">instanties</tspan>
				</text>
				<text x="345" y="275" className="links">
					<tspan x="345">Rijks-</tspan>
					<tspan x="345" dy="21">overheid</tspan>
				</text>
				<text x="345" y="335" className="links">
					<tspan x="345">Toezicht-</tspan>
					<tspan x="345" dy="21">houders</tspan>
				</text>
			</g>

		</svg>
	</div>
);

export default AndereSpelers;


