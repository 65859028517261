import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';


const KostenSegmenten = () => {

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val => localeFormatter.format(',.0f')(val) + ' miljoen euro';
	const formatValueY = val => localeFormatter.format(',.0f')(val);

	const data = [
      { key: 'Segment 1', value: 2044 },
      { key: 'Segment 2', value: 573 },
      { key: 'Segment 3', value: 191 },
      { key: 'Buitensegment', value: 386 },
	];


	return (
		<div className="vis-kosten-segmenten">

      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 170 }}
        barHeight={30}
        domain={[0,2044]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-60}
      />

			<div className="vis-credit">
				<p>Gerealiseerde kosten in miljoen euro</p>
				<p>op basis van voorlopige declaraties over 2017</p>
				<p>Bron: Vektis 2018</p>
			</div>

	</div>
	);
}

export default KostenSegmenten;
