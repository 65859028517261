import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../components/BarChart/BarChart';

const UitgavenZorgvormen = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValue = val => localeFormatter.format(',.1f')(val/1000);
  const formatTooltip = val => localeFormatter.format(',.1f')(val/1000) + ' miljard euro';

  const data = [
    { key: "Langdurige zorg", value: 10502 },
    { key: "Medisch-specialistische zorg", value: 9266 },
    { key: "Wijkverpleging", value: 2689 },
    { key: "Farmaceutische zorg", value: 1987 },
    { key: "Wmo-zorg", value: 1137 },
    { key: "Huisartsenzorg", value: 998 },
    { key: "Paramedische zorg", value: 576 },
    { key: "Eerstelijns diagnostiek", value: 414 },
    { key: "Geestelijke gezondheidzorg", value: 342 }
  ];


	return (
		<div className="vis-uitgaven-zorgvormen">
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0,10502]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatTooltip}
        formatValueY={formatValue}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-45}
      />


      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">28</p>
        <p>miljard euro in 2016</p>
      </div>

      <div className="vis-credit">
        <p>Uitgaven aan ouderenzorg per zorgvrom in 2016 in miljard euro.</p>
        <p>Bron: Nederlandse Zorgautoriteit (2018)</p>
      </div>
	</div>
	)
}

export default UitgavenZorgvormen;
