import React from 'react';
import './About.scss';
import partners from './platformpartners.png';

const About = (props) => {

		return (
			<div className="colofonkolom">
				<div className="kop">Over het Platform Zó werkt de zorg</div>
				<p className="introductie">Deze app is een product van Platform Zó werkt de zorg. Dit platform is een samenwerkingsverband van partijen uit de zorg. Voor alle betrokkenen is het doel van het platform inzichtelijk maken hoe het Nederlandse zorgstelsel werkt. Heldere en neutrale informatie over de zorg, dat is de missie.</p>

				<h4>Wie zijn partner van Platform Zó werkt de zorg?</h4>
				<p>Deelname aan het platform staat in principe open voor elke partij die de missie van Zó werkt de zorg onderschrijft. Alle partners vinden het noodzakelijk dat het platform een breed en neutraal platform is waarin de belangrijke spelers in de zorgsector zijn vertegenwoordigd. Het platform bestaat uit een goede mix van private partijen, waaronder zorgaanbieders, zorginkopers en vertegenwoordigers van de burgers, publieke partijen en kennisorganisaties. De partners van Platform Zó werkt de zorg zijn gezamenlijk opdrachtgever van uitvoerend partner De&nbsp;Argumentenfabriek. Op dit moment zijn de volgende partijen partner:</p>

				<img src={partners} alt="Platformpartners" />

				<h4>Wat is de rol van de Platformpartners?</h4>
				<p>De partners maken het platform mogelijk, zowel financieel als inhoudelijk. Zij zijn gezamenlijk opdrachtgever van uitvoerend partner De&nbsp;Argumentenfabriek. De&nbsp;Argumentenfabriek is de enige producent van Zó werkt de zorg-producten en is vanuit die rol ook als enige verantwoordelijk voor de inhoud en bewaakt de juistheid, de onafhankelijkheid en de helderheid van de informatie. Voor praktische vragen over het platform kan eenieder altijd bij de <a href="https://www.zowerktdezorg.nl/#contact" target="_blank" rel="noopener noreferrer">chef Zorg</a> van De&nbsp;Argumentenfabriek terecht.</p>

				<h4>Wie verdient er aan Zó werkt de zorg?</h4>
				<p>Het Platform Zó werkt de zorg heeft geen winstdoelstelling. De&nbsp;Argumentenfabriek krijgt als uitvoerder betaald voor het werk dat de fabriekers doen voor het platform. Opbrengsten, bijvoorbeeld uit de verkoop van boeken en online-producten, herinvesteert het platform in de verdere ontwikkeling van het Platform Zó werkt de zorg.</p>

				<p>Meer weten over Platform Zó werkt de zorg? Bezoek <a href="https://www.zowerktdezorg.nl/" target="_blank" rel="noopener noreferrer">onze website</a></p>
			</div>
			)
}

export default About;
