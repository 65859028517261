import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"wie-doet-wat",
  titel:"Wie doet wat in de bestrijding?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links:
    [
      'wat-is-een-a-infectieziekte',
      'dwingende-maatregelen',
    ]
}
