// import React from 'react';

// import harmonicas
import * as harmonicas from './harmonicas';
import vissen from './visualizations';

const pages = [
{
    categorie:"basis",
    template:"specialvoorpagina",
    naam:"zorgverzekering",
    titel:"Zó werkt de zorgverzekering",
    kleur:"5",
    tekst:
        "Hoe werkt het verzekeren van zorg? Vind hier alles over de verschillende soorten zorgverzekeringen, spelers en organisaties, wetten en financiering.",
    links: [
    'verzekeren-van-zorg',
    'waarom-verzekeren',
    'organisaties',
    'rol-zorgverzekeraars',
    'stelselwetten',
    'wetten-zorgverzekering',
    'uitgaven',
    'geldstromen'
    ]
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"verzekeren-van-zorg",
    titel:"Hoe werkt het verzekeren van zorg?",
    kleur:"6",
    tekst:
        "Alle Nederlanders zijn verplicht tot het afsluiten van een zorgverzekering. Maar hoe werkt verzekeren eigenlijk? En welke soorten zorgverzekeringen zijn er?",
    links: [
    'hoe-werkt-verzekeren',
    'gezondheidsrisicos',
    'soorten-zorgverzekeringen'
    ]
},

{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"hoe-werkt-verzekeren",
    titel:"Hoe werkt verzekeren?",
    kleur:"6",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"gezondheidsrisicos",
    titel:"Hoe zijn verschillende gezondheidsrisico's verzekerd?",
    kleur:"6",
    tekst:
        "",
    links:[
    'soorten-zorgverzekeringen'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"soorten-zorgverzekeringen",
    titel:"Welke verzekeringen voor zorg kennen we in Nederland?",
    kleur:"6",
    tekst:
        "",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"waarom-verzekeren",
    titel:"Waarom verzekeren we zorg?",
    kleur:"6",
    tekst:
        "Welke doelstellingen liggen ten grondslag aan het verzekeren van zorg? En op welke punten knelt het tussen deze doelstellingen?",
    links:[
    'doelstellingen-verzekeren',
    'spanning-doelstellingen',
    'elementen-solidariteit'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"doelstellingen-verzekeren",
    titel:"Welke doelstellingen hebben we met het verzekeren van zorg?",
    kleur:"6",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"spanning-doelstellingen",
    titel:
        "Welke spanning bestaat er tussen kwaliteit, betaalbaarheid en toegankelijkheid van complexe operaties?",
     kleur:"6",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"elementen-solidariteit",
    titel:"Welke elementen maken de zorgverzekering solidair?",
    kleur:"6",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"organisaties",
    titel:"Wie zijn belangrijke partijen bij het verzekeren van zorg en wat doen zij?",
    kleur:"1",
    tekst:
        "Burgers, zorgaanbieders en zorginkopers spelen een hoofdrol bij de zorgverzekering. Welke andere spelers zijn betrokken bij het verzekeren van zorg?",
    links:[
    'hoofdrolspelers-zorgverzekering',
    'andere-spelers',
    'uitvoering-zorgverzekering',
    'toezicht-zorgverzekering'
    ]
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"hoofdrolspelers-zorgverzekering",
    titel:"Wie zijn de hoofdrolspelers in de zorgverzekering?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"andere-spelers",
    titel:"Wie zijn andere belangrijke spelers in de zorgverzekering?",
    kleur:"1",
    tekst:"",
    links:[
    'uitvoering-zorgverzekering',
    'toezicht-zorgverzekering'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"uitvoering-zorgverzekering",
    titel:"Welke organisaties zijn betrokken bij de uitvoering van de zorgverzekeringen en wat doen ze?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"toezicht-zorgverzekering",
    titel:"Welke organisaties houden formeel en informeel toezicht op de zorgverzekeringen en wat doen ze?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"rol-zorgverzekeraars",
    titel:"Hoe werken zorgverzekeraars?",
    kleur:"1",
    tekst:
    "Zorgverzekeraars bewegen zich op de zorgverzekerings-, zorgverlenings- en zorginkoopmarkt. Welke rol spelen ze op deze markten? En welke processen liggen hieraan ten grondslag?",
    links:[
    'markten-zorgverzekering',
    'marktaandeel',
    'merken',
    'organisatie-zorgverzekeraar',
    'zorgkantoorregios',
    'inkoopproces']
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"markten-zorgverzekering",
    titel:"Welke markten zijn er in de Zvw en Wlz?",
    kleur:"1",
    tekst:"",
    links:[
    'rol-zorgverzekeraar'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"rol-zorgverzekeraar",
    titel:"Wat is de rol van de zorgverzekeraar op de drie zorgmarkten?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"merken",
    titel:"Welke merken voeren de zorgverzekeraars?",
    kleur:"1",
    tekst:"",
    links:[]
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"marktaandeel",
    titel:"Welke zorgverzekeraars zijn er wat is hun marktaandeel?",
    kleur:"1",
    tekst:"",
    links:['merken']
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"organisatie-zorgverzekeraar",
    titel:"Hoe ziet de organisatie van een zorgverzekeraar eruit?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"zorgkantoorregios",
    titel:"Welke zorgverzekeraars hebben zorgkantoren in welke zorgkantoorregio's?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"inkoopproces",
    titel:"Hoe ziet het inkoopproces van zorgverzekeraars eruit?",
    kleur:"1",
    tekst:"",
    links:[
    'inkoop-ziekenhuiszorg'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"inkoop-ziekenhuiszorg",
    titel:"Hoe werkt de inkoop van ziekenhuiszorg?",
    kleur:"1",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"stelselwetten",
    titel:"Hoe werken de verzekeringen van de verschillende stelselwetten?",
    kleur:"2",
    tekst:
        "De Zorgverzekeringswet en Wet langdurige zorg spelen een belangrijke rol bij het verzekeren van zorg? Hoe ziet de zorgverzekering er per stelselwet uit? En hoe zit het met de aanvullende verzekering?",
    links:[
    'zorgverzekering-zvw',
    'aanvullende-verzekering',
    'zorgverzekering-wlz'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"zorgverzekering-zvw",
    titel:"Hoe werkt de zorgverzekering van de Zorgverzekeringswet?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"aanvullende-verzekering",
    titel:"Hoe werkt de aanvullende verzekering?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"zorgverzekering-wlz",
    titel:"Hoe werkt de zorgverzekering van de Wet langdurige zorg?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"wetten-zorgverzekering",
    titel:"Wat zijn de relevante (zorg)wetten voor het verzekeren van zorg?",
    kleur:"2",
    tekst:
        "Verschillende zorgwetten en algemene wetten regelen het verzekeren van zorg. Welke wetten zijn dit en wat doen ze?",
    links:[
    'algemene-wetten',
    'inhoud-wmg',
    'overige-wetten'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"algemene-wetten",
    titel:"Wat zijn voor zorgverzekeraars belangrijke algemene wetten?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"inhoud-wmg",
    titel:"Wat betekent de Wet marktordening gezondheidszorg voor zorgverzekeraars?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"overige-wetten",
    titel:"Wat zijn voor zorgverzekeraars overige belangrijke zorgwetten?",
    kleur:"2",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"uitgaven",
    titel:"Hoeveel geld gaat er om in het verzekeren van zorg?",
    kleur:"3",
    tekst:"In de rijksbegroting wordt er bijna 80 miljard euro begroot voor de zorg. Hoeveel geld gaat er om in het verzekeren van zorg?",
    links:[
    '/basis/euros-vws',
    'euros-volwassene',
    'zorgkosten-leeftijdsgroep',
    'baten-lasten-zorgverzekeraars',
    'inkomsten-per-premiebetaler',
    'bedrijfskosten-zorgverzekeraars'
    ]
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"euros-volwassene",
    titel:"Hoeveel betaalt een volwassene per saldo aan zorg?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"zorgkosten-leeftijdsgroep",
    titel:"Wat zijn de gemiddelde zorgkosten per leeftijdsgroep?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"baten-lasten-zorgverzekeraars",
    titel:"Wat zijn de belangrijkste baten en lasten van zorgverzekeraars?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"inkomsten-per-premiebetaler",
    titel:"Wat zijn de inkomsten van zorgverzekeraars per premiebetaler?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"bedrijfskosten-zorgverzekeraars",
    titel:"Waaruit bestaan de bedrijfskosten van zorgverzekeraars?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"intro",
    naam:"geldstromen",
    titel:"Hoe lopen de geldstromen bij het verzekeren van zorg?",
    kleur:"3",
    tekst:
        "Een volwassene in Nederland betaalt gemiddeld meer dan 5000 euro per jaar aan zorg. Hoe lopen de geldstromen in de Zorgverzekeringswet en de Wet langdurige zorg? En wie bepaalt wie wat betaalt?",
    links:[
    'geldstromen-zvw',
    'geldstromen-wlz',
    'wie-bepaalt'
    ]
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"geldstromen-zvw",
    titel:"Hoe lopen de geldstromen in de Zorgverzekeringswet?",
    kleur:"3",
    tekst:"",
    links:[
    'wie-betaalt-zvw'
    ]
},
{
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"wie-betaalt-zvw",
    titel:"Wie betaalt wat in de Zorgverzekeringswet?",
    kleur:"3",
    tekst:"",
    links:""
},
{
    categorie:"zorgverzekering",
    template:"visualisatie",
    naam:"geldstromen-wlz",
    titel:"Hoe lopen de geldstromen in de Wet langdurige zorg?",
    kleur:"3",
    tekst:"",
    links:[
    'wie-betaalt-wlz'
    ]
},
{   
    categorie:"zorgverzekering",
    template:"harmonica",
    naam:"wie-betaalt-wlz",
    titel:"Wie betaalt wat in de Wet langdurige zorg?",
    kleur:"3",
    tekst:"",
    links:""
},
{   categorie:"zorgverzekering",
    template:"harmonica",
    naam:"wie-bepaalt",
    titel:"Wie bepaalt wie wat betaalt?",
    kleur:"3",
    tekst:"",
    links:""
}]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
    if (pagina.template === "harmonica") {
        const harmonicaNaam = pagina.naam.replace(/-/g, "_");
        pagina.harmonica = harmonicas[harmonicaNaam];
    }
    if (pagina.template === "visualisatie") {
        const visualisatieNaam = pagina.naam.replace(/-/g, "_");
        pagina.vis = vissen[visualisatieNaam];
    }
    return pagina;
});
export default pages;