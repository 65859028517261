import React from 'react';
import BarChart from '../../../../../../components/BarChart/BarChart';

const PlekWerkenden = () => {
    const formatValueTooltip = (val) => val + ' duizend werkenden';
    const formatValueY = (val) => val;

    const data = [
        { key: 'Verpleging, verzorging, thuiszorg', value:  454 },
        { key: 'Algemene en overige ziekenhuizen', value:  259 },
        { key: 'Gehandicaptenzorg', value: 166 },
        { key: 'Geestelijke gezondheidszorg', value: 95 },
        { key: 'Sociaal werk', value: 75 },
        { key: 'Universitaire medische centra', value: 86 },
        { key: 'Huisartsenzorg en gezondheidscentra', value: 45 },
        { key: 'Jeugdzorg', value: 34 },
        { key: 'Overige zorg en welzijn', value:  236 },
    ];

    return (
        <div className="vis-plek-werkenden">
            <BarChart
                data={data}
                margin={{ top: 30, right: 0, bottom: 10, left: 300 }}
                barHeight={30}
                domain={[0,460]}
                color={ {fill: '#333', stroke: '#fff'} }
                formatValueTooltip={formatValueTooltip}
                formatValueY={formatValueY}
                padding={0.2}
                ticks={0}
            />

            <div className="vis-total">
                <p>1,45</p>
                <p>miljoen</p>
                <p>werkenden</p>
                <p>in en om de zorg</p>
            </div>

            <div className="vis-credit">
                <p>Aantal duizend werkenden per deelsector in de zorg in 2021.</p>
                <p>Bron: CBS Statline, 2022. </p>
            </div>
        </div>
    );
}

export default PlekWerkenden;
