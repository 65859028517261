import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_TURQUOISE} from "../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "gids-oplossingen-arbeidsmarkt",
    titel: "Oplossingen voor tekort arbeidsmarkt",
    kleur: COLOR_TURQUOISE,
    tekst:
      "De sector zorg en welzijn kampt met een tekort aan personeel en de komende decennia zal dit tekort verder toenemen. Wat zijn de trends en ontwikkelingen van de arbeidsmarkt in de zorg? En wat zijn mogelijke oplossingen om de zorg anders te organiseren zodat de toenemende zorgvraag opgevangen kan worden?  Al deze informatie en meer vind je hieronder.",
    links:
      [
        'trends-arbeidsmarkt',
        'prognoses-zorg',
        'zes-rapporten',
      ]
  },
  {
    template: "intro",
    naam: "trends-arbeidsmarkt",
    titel: "Wat zijn de trends en ontwikkelingen van de arbeidsmarkt in de zorg?",
    kleur: COLOR_TURQUOISE,
    tekst:
      "Eén op de zes werkenden in Nederland werkt in de sector zorg en welzijn. Om aan de zorgvraag te voldoen moet dit aantal bij onwijzigd beleid stijgen naar één op de vier medewerkers in 2040. Welke trends en ontwikkelingen zorgen hiervoor?",
    links:
      [
        "beroepsbevolking",
        "werkenden-zorg",
        "openstaande-vacatures",
        "benodigde-zorgmedewerkers"
      ]
  },
  {
    template: "visualisatie",
    naam: "beroepsbevolking",
    titel: "Welk deel van de beroepsbevolking werkt in de zorg?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "visualisatie",
    naam: "werkenden-zorg",
    titel: "Hoeveel mensen werken in de zorg?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "visualisatie",
    naam: "openstaande-vacatures",
    titel: "Hoeveel openstaande vacatures zijn er in de zorg?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "visualisatie",
    naam: "benodigde-zorgmedewerkers",
    titel: "Hoe ontwikkelt de beroepsbevolking zich en welk deel van de werkenden is in de zorg nodig?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "intro",
    naam: "prognoses-zorg",
    titel: "Wat zijn relevante prognoses voor de vraag naar zorg?",
    kleur: COLOR_TURQUOISE,
    tekst:
        "De vraag naar zorgpersoneel zal de komende decennia verder stijgen door demografische ontwikkelingen. Wat zijn relevante prognoses voor de vraag naar zorg?",
    links:
        [
          "stijging-90-plussers",
          "dementie",
          "eerste-hulp"
        ]
  },
  {
    template: "visualisatie",
    naam: "stijging-90-plussers",
    titel: "Wat is de stijging van het aantal 90 plussers?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "visualisatie",
    naam: "dementie",
    titel: "Hoeveel mensen met dementie hebben een indicatie voor verpleeghuiszorg?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "visualisatie",
    naam: "eerste-hulp",
    titel: "Hoeveel 85-plussers bezoeken de eerste hulp?",
    kleur: COLOR_TURQUOISE,
    links: []
  },
  {
    template: "harmonica",
    naam: "zes-rapporten",
    titel: "Wat zijn de mogelijkheden om het personeelstekort in de zorg te verkleinen?",
    kleur: COLOR_TURQUOISE,
  }
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }

  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
