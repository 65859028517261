import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const SamenwerkingTweedeLijn = () => {

	const formatValueTooltip = (val) => val + '%';
	const formatValueY = (val) => Math.round(val)+'%';

	const data = [
			{ key: 'Geestelijke gezondheidszorg', value: 14 },
			{ key: 'Verpleeghuis', value: 13 },
			{ key: 'Inwendige specialismen', value: 10},
			{ key: 'Medisch coördinerend centrum', value: 7 },
			{ key: 'Dermatologie, allergologie', value: 7 },
			{ key: 'Cardiologie', value: 7 },
			{ key: 'Gynaecologie', value: 7 },
			{ key: 'Orthopedie', value: 6 },
			{ key: 'Chirurgie', value: 5 },
			{ key: 'Oogheelkunde', value: 5 },
			{ key: 'Neurologie', value: 5 },
			{ key: 'Keel-neus-oorheelkunde', value: 5 },
			{ key: 'Urologie', value: 5 },
			{ key: 'Gespecialiseerde jeugdhulp', value: 3 },
	];

	return (
		<div className="vis-samenwerking-tweede-lijn">

			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 265 }}
				barHeight={30}
				domain={[0,90]}
				color={ {fill: '#333', stroke: '#fff'} }
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			<div className="vis-credit">
				<p>Percentage van huisartsen dat in 2014 structureel of regulier overlegt met</p>
				<p>tweedelijns zorgaanbieders.</p>
				<p>Bron: NIVEL 2015</p>
			</div>

		</div>)
};

export default SamenwerkingTweedeLijn;
