import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "visualisatie",
  naam: "uitgaven-geboortezorg",
  titel: "Hoeveel geld geven we uit aan geboortezorg?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'wat-is-geboortezorg',
    'aantal-bevallingen',
  ]
}
