import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const SamenwerkingEersteLijn = () => {

	const formatValueTooltip = (val) => val + '%';
	const formatValueY = (val) => Math.round(val)+'%';

	const data = [
			{ key: 'Apotheek', value: 90 },
			{ key: 'Huisartsenpost', value: 74 },
			{ key: 'Wijkverpleging & thuiszorg', value: 64},
			{ key: 'Zorggroep', value: 63 },
			{ key: 'Fysiotherapie', value: 55 },
			{ key: 'Eerstelijns psycholoog', value: 54 },
			{ key: 'Diëtetiek', value: 37 },
			{ key: 'Algemeen maatschappelijk werk', value: 28 },
			{ key: 'Jeugdgezondsheidzorg', value: 16 },
			{ key: 'Oefentherapie', value: 16 },
			{ key: 'Diagnostisch centrum', value: 13 },
			{ key: 'Verloskunde', value: 13 },
			{ key: 'Medewerkers gemeente', value: 12 },
			{ key: 'Podotherapie', value: 12 },
			{ key: 'Consultatiebureau', value: 11 },
			{ key: 'Logopedie', value: 11 }
	];

	return (
		<div className="vis-samenwerking-eerste-lijn">

			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 265 }}
				barHeight={30}
				domain={[0,90]}
				color={ {fill: '#333', stroke: '#fff'} }
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			<div className="vis-credit">
				<p>Percentage van huisartsen dat in 2014 structureel of regulier overlegt met</p>
				<p>eerstelijns zorgaanbieders.</p>
				<p>Bron: NIVEL 2015</p>
			</div>

		</div>)
};

export default SamenwerkingEersteLijn;


