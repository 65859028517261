import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';


const OplossingenTekort = () => {

	const data = [
		{
			"label": "Ander geneesmiddel",
			"longLabel": "Een ander geneesmiddel met dezelfde werkzame stof gebruiken",
			"value": 73,
		},
		{
			"label": "Importeren",
			"longLabel": "Zelf het geneesmiddel importeren",
			"value": 4,
		},
		{
			"label": "Zelf bereiden",
			"longLabel": "Zelf een geneesmiddel bereiden",
			"value": 2,
		},
			{
			"label": "Andere werkzame stof",
			"longLabel": "Geneesmiddel met een andere werkzame stof gebruiken",
			"value": 20,
		},
			{
			"label": "Geen vervanging gevonden",
			"longLabel": "Geen vervanging gevonden",
			"value": 1,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
	"comma": true,
	});

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + item.percentage + '%';
	}

	const formatTooltip = item => {
		const value = item.value > 1000 ? localeFormatter.format(',.1f')(item.value/1000) + ' miljoen' : item.value + ' procent';
		return item.longLabel + ': ' + value;
	}
	const formatTotal = () => (null);

	// const tooltipStyle = () => {
	// };

	return (
		<div className="vis-oplossingen-tekort">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
				tooltipMaxWidth={250}
				formatTotal={formatTotal}
				unit="%"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Bron: KNMP Farmanco, 2019.</p>
			</div>

		</div>
	);
}

export default OplossingenTekort;


