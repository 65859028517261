import React from 'react';

import Zorgeuros from './visualizations/zorgeuros';
import Zorguitgaven from './visualizations/zorguitgaven';
import Rijksbegroting from './visualizations/rijksbegroting';
import GeneesmiddelenUitgaven from './visualizations/geneesmiddelen-uitgaven';
import VolwasseneZorguitgaven from './visualizations/volwassene-zorguitgaven';

const vissen = {
	zorgeuros: <Zorgeuros />,
	zorguitgaven: <Zorguitgaven />,
	rijksbegroting: <Rijksbegroting />,
	geneesmiddelen_uitgaven: <GeneesmiddelenUitgaven />,
	volwassene_zorguitgaven: <VolwasseneZorguitgaven />,
};

export default vissen;
