import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const VisualisatieBeperking = () => {
	const data = [
		{
			"label": "Lichamelijke beperking",
			"value": 1.0,
		},
		{
			"label": "Verstandelijke beperking",
			"value": 7.7,
		},
		{
			"label": "Zintuiglijke beperking",
			"value": 0.3,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		return `${item.label}`
	}


	const formatTooltip = item => {
		return `${item.label} (${localeFormatter.format(',.0f')(item.percentage)}%)`
	}

	return (
		<Donut
			data={data}
			height={800}
			fontFamily="din-regular"
			fontSize="10px"
			formatLabel={formatLabel}
			formatTooltip={formatTooltip}
			formatTotal={() => {}}
			unit="duizend"
			animation={true}
		/>
	)
}

const VisualisatieLeveringsvorm = () => {
	const data = [
		{
			"label": "Volledig pakket thuis",
			"value": 0.2,
		},
		{
			"label": "Verblijf in instelling",
			"value": 6.7,
		},
		{
			"label": "Persoonsgebonden budget",
			"value": 1.7,
		},
		{
			"label": "Modulair pakket thuis",
			"value": 0.4,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		return `${item.label}`
	}


	const formatTooltip = item => {
		return `${item.label} (${localeFormatter.format(',.0f')(item.percentage)}%)`
	}

	return (
		<Donut
			data={data}
			height={800}
			fontFamily="din-regular"
			fontSize="10px"
			formatLabel={formatLabel}
			formatTooltip={formatTooltip}
			formatTotal={() => {}}
			unit="duizend"
			animation={true}
		/>
	)
}

const WerknemersGehandicaptenzorg = () => {
	return (
		<div className="vis-uitgaven-wlz">
			<VisualisatieLeveringsvorm />
			<VisualisatieBeperking />

			<div className="clear"></div>

      <div className="vis-total">
        <p className="groot">9,0</p>
        <p>miljard euro</p>
      </div>

			<div className="clear"></div>

			<div className="vis-credit">
				<p>Uitgaven aan gehandicaptenzorg uit de Wlz in miljard euro in 2018, uitgesplitst naar beperking en leveringsvorm.</p>
				<p>Bron: Nza monitor</p>
			</div>

			<div className="clear"></div>

		</div>
	);
}

export default WerknemersGehandicaptenzorg;
