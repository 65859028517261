import React from 'react';
import LineChart from '../../../components/LineChart/LineChart';
import {formatLocale} from 'd3';

const WerkendenAantal = () => {
  const margin = {top: 60, right: 30, bottom: 90, left: 45};

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatY = (val) => val;
  const formatYTooltip = (val) => `${localeFormatter.format(',.0f')(val)} duizend zorgprofessionals`;

  const data = [
    {
      name: 'werknemers',
      values: [
        {x: 2010, y: 74},
        {x: 2011, y: 57},
        {x: 2012, y: 71},
        {x: 2013, y: 89},
        {x: 2014, y: 90},
        {x: 2015, y: 82},
        {x: 2016, y: 81},
        {x: 2017, y: 79},
        {x: 2018, y: 84},
        {x: 2019, y: 89},
      ]
    },
    {
      name: 'zelfstandigen',
      values: [
				{x: 2010, y: 9},
				{x: 2011, y: 20},
				{x: 2012, y: 9},
				{x: 2013, y: 13},
				{x: 2014, y: 14},
				{x: 2015, y: 14},
				{x: 2016, y: 14},
				{x: 2017, y: 14},
				{x: 2018, y: 14},
				{x: 2019, y: 15},
      ]
    },

    // {
    //   name: 'totaal',
    //   values: [
		// 		{x: 2010, y: 83},
		// 		{x: 2011, y: 67},
		// 		{x: 2012, y: 80},
		// 		{x: 2013, y: 102},
		// 		{x: 2014, y: 103},
		// 		{x: 2015, y: 95},
		// 		{x: 2016, y: 95},
		// 		{x: 2017, y: 93},
		// 		{x: 2018, y: 98},
		// 		{x: 2019, y: 104},
    //   ]
    // }
  ];


  return (
    <div className="vis-werkenden-aantal">
      <LineChart
        margin={margin}
        colorLines={["#000"]}
        colorAxis="#333"
        labelY="x 1000"
        domainY={[-10, 110]}
        data={data}
        height={500}
        formatY={formatY}
        formatValueTooltip={formatYTooltip}
      />

      <div className="vis-credit">
        <p>Aantal duizend zorgprofessionals in de geestelijke gezondheidszorg van 2010 tot 2020.</p>
        <p>Bron: AZW Statline, 2021</p>
      </div>
    </div>
  );
}

export default WerkendenAantal;


