import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"wat-is-huiselijk-geweld",
  titel:"Wat verstaan we onder huiselijk geweld en wie zijn slachtoffers en plegers?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'hoeveel-meldingen',
    'spelers-aanpak',
  ]
}
