import React, {useEffect, useState} from 'react';

import './Searchbar.scss';
import {useNavigate} from "react-router";

const Searchbar = (props) => {
  const [focus, setFocus] = useState(false)
  const navigate = useNavigate()

  const { page } = props

  useEffect(() => {
    if (page.naam === 'zoeken') {
      setFocus(true)
    }
  }, [page.naam])

  const handleChange = (event) => {
    const searchstring = event.target.value;
    props.search(searchstring);

    if (searchstring.length > 2 && props.page.naam !== 'zoeken') {
      navigate('/zoeken')
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
      navigate('/zoeken')
    }
  }

  const handleFocus = (event) => {
    event.target.value = '';
    event.target.value = props.searchstring;
  }

  return (
      <form className='page-search-bar' onSubmit={handleSubmit} onKeyPress={onKeyPress}>
        <input
            aria-label='searchbar'
            type='text'
            autoFocus={focus}
            onFocus={handleFocus}
            value={props.searchstring}
            onChange={handleChange}
            placeholder='Zoek in de app...' />
      </form>
  );
}

export default Searchbar;

