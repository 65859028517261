import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const AantalSEHafdelingen = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);
	
	const formatValueTooltip = value => formatY(value) + " afdelingen";

	const data = [	
		{
			name: "dummyData", //deze naam is verplicht als labels van deze lijn niet getoond moeten worden
			values: [
				{ x: 2011, y: 98, showTooltip: 0},
				{ x: 2012, y: 96, showTooltip: 0},
				{ x: 2013, y: 94, showTooltip: 0},
				{ x: 2014, y: 91, showTooltip: 0},
				{ x: 2015, y: 90.5, showTooltip: 0},
				{ x: 2016, y: 90, showTooltip: 0},
				{ x: 2017, y: 89, showTooltip: 0},
				{ x: 2018, y: 84, showTooltip: 0},
				{ x: 2019, y: 83, showTooltip: 0}
			]
		},
		{
			name: "SEH",
			values: [
				{ x: 2011, y: 98, showTooltip: 1},
				{ x: 2012, y: undefined, showTooltip: 0},
				{ x: 2013, y: 94, showTooltip: 1},
				{ x: 2014, y: 91, showTooltip: 1},
				{ x: 2015, y: undefined, showTooltip: 0},
				{ x: 2016, y: 90, showTooltip: 1},
				{ x: 2017, y: 89, showTooltip: 1},
				{ x: 2018, y: 84, showTooltip: 1},
				{ x: 2019, y: 83, showTooltip: 1}
			]
		},
	];

	return (
		<div className="vis-aantal-seh">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[50, 100]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal SEH-afdelingen</p>
				<p>Gegevens van 2012 en van 2015 ontbreken.</p>
				<p>Bron: volksgezondheidenzorg.info</p>
			</div>
		</div>
	);
};

export default AantalSEHafdelingen;
