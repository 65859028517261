import React from 'react';

const Geneesmiddelenzorg = () => (
<svg viewBox="0 0 100 100" className="af-icon">
	<g id="naarSVG" fill="none" strokeMiterlimit="10" strokeWidth="2.5">
		<rect x="15" y="37.14" width="70" height="25" rx="12.5" transform="translate(-20.39 46.54) rotate(-42.27)"/>
		<polygon points="56.39 60.7 40 42.09 43.61 38.7 60 57.32 56.39 60.7"/>
	</g>
</svg>
);

export default Geneesmiddelenzorg;
