import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

class Zorgkantoorregios extends Component {

state = {concern: "zk"};

handleClick = (event) => {
	this.setState({concern: event.target.id});

}

render() {
	const { concern } = this.state;
	const regios = [
		{id: "ZKR01", naam: "Groningen", conc: "men", className: 'zorgkantoorRegio'},
		{id: "ZKR02", naam: "Friesland", conc: "fr", className: 'zorgkantoorRegio'},
		{id: "ZKR03", naam: "Drenthe", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR04", naam: "Noord-Holland Noord", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR05", naam: "Flevoland", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR06", naam: "Zwolle", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR07", naam: "Zaanstreek / Waterland", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR08", naam: "Kennemerland", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR09", naam: "Amsterdam", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR10", naam: "‚Äôt Gooi", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR11", naam: "Twente", conc: "men", className: 'zorgkantoorRegio'},
		{id: "ZKR12", naam: "Midden-IJssel", conc: "eno", className: 'zorgkantoorRegio'},
		{id: "ZKR13", naam: "Amstelland en De Meerlanden", conc: "zz", className: 'zorgkantoorRegio'},
		{id: "ZKR14", naam: "Apeldoorn / Zutphen", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR15", naam: "Zuid-Holland Noord", conc: "zz", className: 'zorgkantoorRegio'},
		{id: "ZKR16", naam: "Utrecht", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR17", naam: "Haaglanden", conc: "cz", className: 'zorgkantoorRegio'},
		{id: "ZKR18", naam: "Arnhem", conc: "men", className: 'zorgkantoorRegio'},
		{id: "ZKR19", naam: "Westland Schieland Delfland", conc: "dsw", className: 'zorgkantoorRegio'},
		{id: "ZKR20", naam: "Midden-Holland", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR21", naam: "Rotterdam", conc: "zk", className: 'zorgkantoorRegio'},
		{id: "ZKR22", naam: "Nieuwe Waterweg Noord", conc: "dsw", className: 'zorgkantoorRegio'},
		{id: "ZKR23", naam: "Waardenland", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR24", naam: "Nijmegen", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR25", naam: "Zuid-Hollandse Eilanden", conc: "cz", className: 'zorgkantoorRegio'},
		{id: "ZKR26", naam: "Noord-Oost Brabant", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR27", naam: "Midden-Brabant", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR28", naam: "West-Brabant", conc: "cz", className: 'zorgkantoorRegio'},
		{id: "ZKR29", naam: "Zeeland", conc: "cz", className: 'zorgkantoorRegio'},
		{id: "ZKR30", naam: "Zuid-Oost Brabant", conc: "cz", className: 'zorgkantoorRegio'},
		{id: "ZKR31", naam: "Noord- en Midden-Limburg", conc: "vgz", className: 'zorgkantoorRegio'},
		{id: "ZKR32", naam: "Zuid-Limburg", conc: "cz", className: 'zorgkantoorRegio'},
	];

	const selectRegios = () => {
		regios.forEach(function(item){
			if(item.conc === concern) {
				item.className = "highlight";
			} else {
				item.className = "zorgkantoorRegio";
			}
		})
	}
	selectRegios();

	return(
	<div className="vis-zorgkantoorregios">
	<svg
	xmlns="http://www.w3.org/2000/svg"
	preserveAspectRatio="xMidYMin slice"
	viewBox="0 0 550 650"
	className="kaart">

		<g className="waterkering">
			<path d="M228,144l33.88-33.88A8.33,8.33,0,0,1,267,108h0a8.33,8.33,0,0,0,5.12-2.12L288,90"/>
		</g>
		<g id="regios" className="zorgkantoorRegio">
			<g data-tooltip-content="react-tooltip" id="ZKR32">
				<path id="ZKR32" className={regios[31].className} d="M348,540l.63.26A7.42,7.42,0,0,0,348,543v6a3,3,0,0,0,3,3h18a3,3,0,0,1,3,3v30a8.33,8.33,0,0,1-2.12,5.12l-7.75,7.76A8.42,8.42,0,0,1,357,600H327a3,3,0,0,1-3-3v-6a8.31,8.31,0,0,0-2.12-5.12l-7.75-7.76A8.34,8.34,0,0,1,312,573v-6a3,3,0,0,1,3-3h6a3,3,0,0,0,3-3v-6a8.32,8.32,0,0,1,2.13-5.12l7.75-7.76a7.58,7.58,0,0,0,1.5-2.38l.62.26Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR31">
				<path id="ZKR31" className={regios[30].className} d="M360.63,395.74a7.58,7.58,0,0,0,1.5,2.38l7.75,7.76,4.25,4.24,19.75,19.76A8.31,8.31,0,0,1,396,435v30a8.35,8.35,0,0,1-2.11,5.12l-7.77,7.79-4.24,4.24-7.75,7.73A8.34,8.34,0,0,0,372,495v6a3,3,0,0,0,3,3h6a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3h-6a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76-4.25,4.24-7.75,7.76a7.46,7.46,0,0,0-1.5,2.38L348,540H336l-.62-.26A7.6,7.6,0,0,0,336,537V507a3,3,0,0,0-3-3h-6a8.39,8.39,0,0,1-5.12-2.12L312,492l24-24h12V432l-12-12h36l-24-24h12Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR30">
				<path id="ZKR30" className={regios[29].className} d="M348,432v36H336l-24,24-9.87-9.88A8.36,8.36,0,0,0,297,480h-6a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.42,8.42,0,0,1,273,492h-6a8.39,8.39,0,0,1-5.12-2.12l-7.75-7.76-4.25-4.24-7.75-7.76A8.34,8.34,0,0,1,240,465v-9h24V420h72Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR29">
				<path id="ZKR29" className={regios[28].className} d="M117,468c1.65,0,2,.95.87,2.12L98.13,489.88A8.42,8.42,0,0,1,93,492l-18,0a8.37,8.37,0,0,1-5.13-2.13L50.13,470.12A8.36,8.36,0,0,0,45,468H39a3,3,0,0,0-3,3v6a3,3,0,0,1-3,3H15a3,3,0,0,1-3-3V459a3,3,0,0,1,3-3H57a8.36,8.36,0,0,1,5.13,2.12l7.75,7.76A8.39,8.39,0,0,0,75,468h6a8.42,8.42,0,0,0,5.13-2.12l7.75-7.76a3,3,0,0,1,4.25,0l7.75,7.76A8.39,8.39,0,0,0,111,468Zm15-27a3,3,0,0,1-3,3H111a8.39,8.39,0,0,1-5.12-2.12L74.13,410.12A8.36,8.36,0,0,0,69,408H39a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.32,8.32,0,0,0,24,423v6a8.34,8.34,0,0,0,2.13,5.12l7.75,7.76A8.39,8.39,0,0,0,39,444H57a8.36,8.36,0,0,1,5.13,2.12l7.75,7.76A8.39,8.39,0,0,0,75,456h6a3,3,0,0,0,3-3v-6a3,3,0,0,1,3-3h6a8.36,8.36,0,0,1,5.13,2.12l7.75,7.76A8.39,8.39,0,0,0,111,456h21V441Zm.2-33.2-.89-.89A3,3,0,0,1,129,408h-6a8.39,8.39,0,0,1-5.12-2.12L98.13,386.12A8.36,8.36,0,0,0,93,384H63a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a8.36,8.36,0,0,1,5.13,2.12l31.75,31.76A8.39,8.39,0,0,0,111,432h18a3,3,0,0,1,3,3V408Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR28">
				<path id="ZKR28" className={regios[27].className} d="M228,444l.88.88A3,3,0,0,0,228,447v6a3,3,0,0,1-3,3H207a3,3,0,0,1-3-3V435a3,3,0,0,0-3-3h-6.21a3,3,0,0,0-3,3l.16,18a3,3,0,0,1-3,3h-6a8.39,8.39,0,0,1-5.12-2.12l-7.75-7.76A8.36,8.36,0,0,0,165,444h-6a3,3,0,0,0-3,3v18a3,3,0,0,1-3,3h-6a8.39,8.39,0,0,1-5.12-2.12l-7.75-7.76a8.48,8.48,0,0,0-4.81-2.11H132V408l.2-.2L144,396h36l24-24h36l-24,24v36Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR27">
				<path id="ZKR27" className={regios[26].className} d="M264,420v36H240v-9a3,3,0,0,0-3-3h-6a3,3,0,0,0-2.12.88L228,444l-12-12V396l24-24,12,12v24Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR26">
				<polygon id="ZKR26" className={regios[25].className} points="348 396 372 420 336 420 264 420 252 408 252 384 240 372 240 372 288 372 324 372 336 384 348 384 348 396"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR25">
				<path id="ZKR25" className={regios[24].className} d="M144,396l-11.8,11.8-.89-.89A3,3,0,0,0,132,405v-6a3,3,0,0,0-3-3h-6a8.39,8.39,0,0,1-5.12-2.12L98.13,374.12A8.36,8.36,0,0,0,93,372H87a3,3,0,0,1-3-3v-6a3,3,0,0,1,3-3h18c1.65,0,2-1,.88-2.12l-7.75-7.76A7.36,7.36,0,0,1,96.74,348H108l12,12h60v36Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR24">
				<path id="ZKR24" className={regios[23].className} d="M336,360h39a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.32,8.32,0,0,0,360,375v18a7.42,7.42,0,0,0,.63,2.74L360,396H348V384H336l-12-12H228V360h12l12-12,12-12h48Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR23">
				<polygon id="ZKR23" className={regios[22].className} points="252 348 240 360 228 360 228 372 204 372 180 396 180 360 180 348 216 348 228 336 240 336 252 348"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR22">
				<polygon id="ZKR22" className={regios[21].className} points="156 336 156 348 132 348 120 336 156 336"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR21">
				<path id="ZKR21" className={regios[20].className} d="M105,336a7.7,7.7,0,0,0,3.09-.78V336H120l12,12h24V336h24v24H120l-12-12H96.74a7.77,7.77,0,0,1-.74-3v-6a3,3,0,0,1,3-3Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR20">
				<polygon id="ZKR20" className={regios[19].className} points="216 348 180 348 180 336 180 324 192 312 204 312 204 336 216 348"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR19">
				<path id="ZKR19" className={regios[18].className} d="M110.13,333.88,120,324h60v12H108.09v-.78A7,7,0,0,0,110.13,333.88Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR18">
				<path id="ZKR18" className={regios[17].className} d="M468,300l-9.87,9.88A8.32,8.32,0,0,0,456,315v6a3,3,0,0,0,3,3h6a3,3,0,0,1,3,3v6a8.33,8.33,0,0,1-2.12,5.12l-19.75,19.76A8.39,8.39,0,0,1,441,360H336l-24-24V312H300V300l12-12,12-12,36,36h60V288h12l12,12Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR17">
				<polygon id="ZKR17" className={regios[16].className} points="180 324 120 324 132 312 144 300 156 300 180 323.99 180 324"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR16">
				<polygon id="ZKR16" className={regios[15].className} points="312 312 312 336 264 336 252 348 240 336 228 336 216 348 204 336 204 312 216 300 204 288 228 264 240 264 240 276 240 288 264 288 264 276 276 264 288 276 300 276 312 288 300 300 300 312 312 312"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR15">
				<path id="ZKR15" className={regios[14].className} d="M216,300l-12,12H192l-12,12h0l-24-24H144c8.05-8.05,17.05-22,24-35.94l0-.06H180v24h24Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR14">
				<polygon id="ZKR14" className={regios[13].className} points="420 288 420 312 360 312 324 276 336 264 360 240 372 240 372 264 372 300 384 300 384 288 396 276 408 276 420 288"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR13">
				<polygon id="ZKR13" className={regios[12].className} points="228 264 204 288 180 288 180 264 192 252 204 264 228 264"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR12">
				<polygon id="ZKR12" className={regios[11].className} points="408 240 408 276 396 276 384 288 384 300 372 300 372 264 372 240 408 240"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR11">
				<path id="ZKR11" className={regios[10].className} d="M504,243v18a8.33,8.33,0,0,1-2.12,5.12L468,300H444l-12-12H420l-12-12V240l12-12h69a8.36,8.36,0,0,1,5.13,2.12l7.75,7.76A8.31,8.31,0,0,1,504,243Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR10">
				<path id="ZKR10" className={regios[9].className} d="M243,252h6a8.42,8.42,0,0,0,5.13-2.12L264,240h12v24l-12,12v12H240V249A3,3,0,0,0,243,252Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR09">
				<polygon id="ZKR09" className={regios[8].className} points="240 249 240 264 228 264 204 264 192 252 192 240 240 240 240 249"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR08">
				<path id="ZKR08" className={regios[7].className} d="M204,204v12l-12,12v24l-12,12H168.07c7-13.93,11.93-27.88,11.93-36V204Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR07">
				<path id="ZKR07" className={regios[6].className} d="M240,204h3a3,3,0,0,0-3,3v33H192V228l12-12V204Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR06">
				<path id="ZKR06" className={regios[5].className} d="M456,225a3,3,0,0,0,3,3H420l-12,12H360l-24,24-12,12-12,12-12-12V264l24-24h12l12-12V204l12-12V180l-24-24h36l12,12v24h12l12,12h12l12-12h27a3,3,0,0,0-3,3v30Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR05">
				<path id="ZKR05" className={regios[4].className} d="M309,156h27l24,24v12l-12,12v24l-12,12H324l-24,24v12H288l-12-12V240H264l33.87-33.88A8.33,8.33,0,0,1,303,204h18c1.65,0,2.05-1,.88-2.12l-7.75-7.76A8.34,8.34,0,0,1,312,189V159A3,3,0,0,0,309,156Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR04">
				<path id="ZKR04" className={regios[3].className} d="M225.88,93.88a3,3,0,0,1,0,4.24l-19.75,19.76A8.42,8.42,0,0,1,201,120h-6a3,3,0,0,1-3-3V99a8.32,8.32,0,0,1,2.13-5.12l7.75-7.76A8.33,8.33,0,0,1,207,84h6a8.36,8.36,0,0,1,5.13,2.12ZM261,168H243a3,3,0,0,1-3-3V135a3,3,0,0,0-3-3l-6,0a8.24,8.24,0,0,0-5.12,2.11l-7.81,7.83A8.37,8.37,0,0,1,213,144h-6a3,3,0,0,1-3-3v-6a3,3,0,0,0-3-3h-6a3,3,0,0,0-3,3v18a8.33,8.33,0,0,1-2.12,5.12l-7.75,7.76A8.32,8.32,0,0,0,180,171v33h69a8.42,8.42,0,0,0,5.13-2.12l7.75-7.76A8.33,8.33,0,0,0,264,189V171A3,3,0,0,0,261,168Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR03">
				<path id="ZKR03" className={regios[2].className} d="M492,168v21a3,3,0,0,1-3,3H432l-12,12H408l-12-12H384V168l-12-12,24-24h12V108L396,96l24-24,24,24h12l36,36v36Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR02">
				<path id="ZKR02" className={regios[1].className} d="M381,12c1.65,0,2.05.95.88,2.12l-7.75,7.76C373,23,372,22.65,372,21V15a3,3,0,0,1,3-3Zm-36,0H327a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.32,8.32,0,0,0,312,27v6c0,1.65,1,2,2.13.88l7.75-7.76A8.33,8.33,0,0,1,327,24h18a3,3,0,0,0,3-3V15A3,3,0,0,0,345,12ZM291,24a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.42,8.42,0,0,1,273,36H255a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h18a8.42,8.42,0,0,0,5.13-2.12l19.75-19.76C299.05,25,298.65,24,297,24ZM231,60a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76C217,71,217.35,72,219,72h6a8.42,8.42,0,0,0,5.13-2.12l7.75-7.76C239.05,61,238.65,60,237,60ZM396,96H384V60l12-12H384L372,36H339a8.33,8.33,0,0,0-5.12,2.12l-7.75,7.76A8.42,8.42,0,0,1,321,48h-6a8.33,8.33,0,0,0-5.12,2.12L290.13,69.88l-4.25,4.24-7.75,7.76A8.32,8.32,0,0,0,276,87v54a8.34,8.34,0,0,0,2.13,5.12l7.75,7.76A8.39,8.39,0,0,0,291,156h81l24-24h12V108Z"/>	
			</g>
			<g data-tooltip-content="react-tooltip" id="ZKR01">
				<path id="ZKR01" className={regios[0].className} d="M494.13,153.88A8.32,8.32,0,0,0,492,159V132L456,96H444L420,72,396,96H384V60l12-12H384L372,36h21a8.42,8.42,0,0,0,5.13-2.12l7.75-7.76A8.33,8.33,0,0,1,411,24h54a3,3,0,0,1,3,3v6a8.34,8.34,0,0,0,2.13,5.12l7.75,7.76,4.25,4.24,7.75,7.76A8.39,8.39,0,0,0,495,60h6a8.36,8.36,0,0,1,5.13,2.12l7.75,7.76A8.31,8.31,0,0,1,516,75v54a8.33,8.33,0,0,1-2.12,5.12l-7.75,7.76-4.25,4.24Z"/>
			</g>
		</g>
	</svg>

	<ul className="knoppen">
		<li id="zk" className={(concern === 'zk') && "active"} onClick={this.handleClick}>Zilveren Kruis</li>
		<li id="vgz" className={(concern === 'vgz') && "active"} onClick={this.handleClick}>VGZ</li>
		<li id="cz" className={(concern === 'cz') && "active"} onClick={this.handleClick}>CZ</li>
		<li id="men" className={(concern === 'men') && "active"} onClick={this.handleClick}>Menzis</li>
		<li id="dsw" className={(concern === 'dsw') && "active"} onClick={this.handleClick}>DSW</li>
		<li id="zz" className={(concern === 'zz') && "active"} onClick={this.handleClick}>Zorg en Zekerheid</li>
		<li id="eno" className={(concern === 'eno') && "active"} onClick={this.handleClick}>Eno Zorgverzekeringen</li>
		<li id="fr" className={(concern === 'fr') && "active"} onClick={this.handleClick}>De Friesland</li>
	</ul>

	<ReactTooltip anchorId="ZKR01" place="left" float><div style={ {textAlign: "left"} }>{regios[0].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR02" place="left" float><div style={ {textAlign: "left"} }>{regios[1].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR03" place="left" float><div style={ {textAlign: "left"} }>{regios[2].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR04" place="left" float><div style={ {textAlign: "left"} }>{regios[3].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR05" place="left" float><div style={ {textAlign: "left"} }>{regios[4].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR06" place="left" float><div style={ {textAlign: "left"} }>{regios[5].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR07" place="left" float><div style={ {textAlign: "left"} }>{regios[6].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR08" place="left" float><div style={ {textAlign: "left"} }>{regios[7].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR09" place="left" float><div style={ {textAlign: "left"} }>{regios[8].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR10" place="left" float><div style={ {textAlign: "left"} }>{regios[9].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR11" place="left" float><div style={ {textAlign: "left"} }>{regios[10].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR12" place="left" float><div style={ {textAlign: "left"} }>{regios[11].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR13" place="left" float><div style={ {textAlign: "left"} }>{regios[12].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR14" place="left" float><div style={ {textAlign: "left"} }>{regios[13].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR15" place="left" float><div style={ {textAlign: "left"} }>{regios[14].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR16" place="left" float><div style={ {textAlign: "left"} }>{regios[15].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR17" place="left" float><div style={ {textAlign: "left"} }>{regios[16].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR18" place="left" float><div style={ {textAlign: "left"} }>{regios[17].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR19" place="left" float><div style={ {textAlign: "left"} }>{regios[18].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR20" place="left" float><div style={ {textAlign: "left"} }>{regios[19].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR21" place="left" float><div style={ {textAlign: "left"} }>{regios[20].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR22" place="left" float><div style={ {textAlign: "left"} }>{regios[21].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR23" place="left" float><div style={ {textAlign: "left"} }>{regios[22].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR24" place="left" float><div style={ {textAlign: "left"} }>{regios[23].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR25" place="left" float><div style={ {textAlign: "left"} }>{regios[24].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR26" place="left" float><div style={ {textAlign: "left"} }>{regios[25].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR27" place="left" float><div style={ {textAlign: "left"} }>{regios[26].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR28" place="left" float><div style={ {textAlign: "left"} }>{regios[27].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR29" place="left" float><div style={ {textAlign: "left"} }>{regios[28].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR30" place="left" float><div style={ {textAlign: "left"} }>{regios[29].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR31" place="left" float><div style={ {textAlign: "left"} }>{regios[30].naam}</div></ReactTooltip>
	<ReactTooltip anchorId="ZKR32" place="left" float><div style={ {textAlign: "left"} }>{regios[31].naam}</div></ReactTooltip>


	<p className="vis-credit">Bron: Zorgverzekeraars Nederland.</p>
	</div>
	)
	}
}

export default Zorgkantoorregios;
