import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../components/BarChart/BarChart';

const FunctiesOuderenzorg = () => {

	const dataVerpleging = [
		{ key: "Zorghulp", value: 11160 },
		{ key: "Helpende", value: 17644 },
		{ key: "Verzorgende", value: 96515 },
		{ key: "Verpleegkundige", value: 19906 },
		{ key: "Agogische of sociaal-wetenschappelijke functie", value: 12969 },
		{ key: "Medische of behandelondersteunende functie", value: 15985 },
		{ key: "Leerling verpleegkundige, verzorgend en agogisch", value: 12064 },
		{ key: "Hotelfunctie (zoals eten bereiden)", value: 17946 },
		{ key: "Algemeen personeel", value: 33026 }
	];

	const dataThuiszorg = [
		{ key: "Thuishulp en verzorgingshulp", value: 6166 },
		{ key: "Verzorgenden", value: 74381 },
		{ key: "Verpleegkundige in de wijk", value: 21967 },
		{ key: "Wijkverpleegkundige (hbo)", value: 12340 },
		{ key: "Gespecialiseerd en ondersteunend (b.v. teamleiders)", value: 7129 },
		{ key: "Algemeen personeel", value: 7515 }
	];

	const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val => localeFormatter.format(',.0f')(val/1000) + ' duizend';
	const formatValueY = val => localeFormatter.format(',.0f')(val/1000);

	const createTable = (data,className) => (
		<table className="tabel">
			<tbody>
				{data.map(row => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
			</tbody>
		</table>);


	return (
		<div className="vis-functies-ouderenzorg">

			<h4>Verpleging en verzorging 237</h4>

      <BarChart
        data={dataVerpleging}
        margin={{ top: 10, right: 0, bottom: 10, left: 340 }}
        barHeight={30}
        domain={[0,100000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-36}
        animation={true}
      />

      {createTable(dataVerpleging)}

			<h4>Thuiszorg 130</h4>

      <BarChart
        data={dataThuiszorg}
        margin={{ top: 10, right: 0, bottom: 10, left: 340 }}
        barHeight={30}
        domain={[0,100000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-36}
        animation={true}
      />

			{createTable(dataThuiszorg)}

			<div className="vis-credit">
				<p>Aantal werkenden in de ouderenzorg maal 1.000 in 2016 naar functie.</p>
				<p>Bron: aanhetwerkvoorouderen.nl</p>
			</div>

		</div>
	);
}

export default FunctiesOuderenzorg;


