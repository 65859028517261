import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const Praktijkvormen = () => {

	const data = [
			{
	        "label": "Solopraktijk",
	        "value": 1741,
	    },
			{
	        "label": "Duopraktijk",
	        "value": 3949,
	    },
	    {
	        "label": "Groepspraktijk",
	        "value": 4108,
	    },
	];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });


	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}
	const formatTooltip = item => item.label + ': ' + localeFormatter.format(',.0f')(item.value) + ' huisartsen';

	const formatTotal = (d) => {
		return (
			<text textAnchor="middle" fontFamily="din-regular">
			 	<tspan x={0} dy={-7.5}>Totaal</tspan>
	      <tspan x={0} dy={15}>{localeFormatter.format(',.0f')(d)}</tspan>
	      <tspan x={0} dy={15}>huisartsen</tspan>
	    </text>);
		}


	return (
		<div className="vis-praktijkvormen">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				formatTooltip={formatTooltip}
				unit="huisartsen"
				animation={true}
			/>


			<div className="vis-credit">
				<p>Percentage huisartsen per praktijkvorm in 2016.</p>
				<p>Bron: NIVEL, 2017</p>
			</div>

		</div>
	);
}

export default Praktijkvormen;


