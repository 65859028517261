import * as harmonicas from './harmonicas';
import vissen from './visualizations';

const pages = [
  {
    categorie:"basis",
    template:"specialvoorpagina",
    naam:"gehandicaptenzorg",
    titel:"Zó werkt de gehandicaptenzorg",
    kleur: "5",
    tekst:
      "Hoe werkt de gehandicaptenzorg? Hier vind je alles over de zorg voor en participatie van mensen met een beperking, over spelers en organisaties, wetten en financiering.",
    links:
      [
        'mensen-met-beperking',
        'wie-is-wie',
        'stelselwetten',
        'wetten-gehandicaptenzorg',
        'uitgaven',
        'geldstromen'
      ]
  },
  {
    template:"intro",
    naam:"mensen-met-beperking",
    titel:"Wat is gehandicaptenzorg en wie maken er gebruik van?",
    kleur:"6",
    tekst:
      "Gehandicaptenzorg is de zorg en ondersteuning voor mensen die blijvende problemen ervaren door een of meer lichamelijke en/of verstandelijke en/of zintuiglijke beperkingen, die niet het gevolg zijn van ouderdom. Welke zorg en ondersteuning krijgen zij?",
    links:
      [
        'hoofdgroepen-beperking',
        'schattingen-doelgroep',
        'participatie',
        'hoofdvormen-zorg',
        'woonvormen',
        'wettelijke-vertegenwoordiging'
      ]
  },
  {
    template:"harmonica",
    naam:"hoofdgroepen-beperking",
    titel:"Welke drie hoofdgroepen beperkingen onderscheiden we?",
    kleur:"6",
  },
  {
    template:"visualisatie",
    naam:"schattingen-doelgroep",
    titel:"Welke schattingen zijn er bekend over het aantal mensen met een beperking in Nederland?",
    kleur:"6",
    links:
      [
        'participatie'
      ]
  },
  {
    template:"visualisatie",
    naam:"participatie",
    titel:"Wat is de participatie van mensen met een beperking in vergelijking met de rest van de Nederlandse bevolking?",
    kleur:"6",
    links: []
  },
  {
    template:"harmonica",
    naam:"hoofdvormen-zorg",
    titel:"Wat zijn hoofdvormen van zorg en ondersteuning voor mensen met een beperking?",
    kleur:"6",
  },
  {
    template:"harmonica",
    naam:"woonvormen",
    titel:"Waar wonen mensen met een beperking zoal en welke zorg en ondersteuning zijn er aanwezig?",
    kleur:"6",
  },
  {
    template:"harmonica",
    naam:"wettelijke-vertegenwoordiging",
    titel:"Wat zijn vormen van wettelijke vertegenwoordiging voor mensen met een beperking?",
    kleur:"6",
  },
  {
    template:"intro",
    naam:"wie-is-wie",
    titel:"Wie zijn de belangrijkste spelers in de gehandicaptenzorg en wat doen zij?",
    kleur:"1",
    tekst:
      "Welke zorgverleners zorgen er voor mensen met een beperking en door welke partijen worden zij vertegenwoordigd? En wie houden zich bezig met kennis over en toezicht op de gehandicaptenzorg?",
    links:
      [
        'werknemers-gehandicaptenzorg',
        'ontwikkeling-werknemers',
        'organisaties-werknemers',
        'organisaties-werkgevers',
        'organisaties-kennis',
        'organisaties-advies',
        'organisaties-toezicht'
      ]
  },
  {
    template:"visualisatie",
    naam:"werknemers-gehandicaptenzorg",
    titel:"Hoeveel mensen werken in de gehandicaptenzorg en welke kenmerken hebben zij?",
    kleur:"1",
  },
  {
    template:"visualisatie",
    naam:"ontwikkeling-werknemers",
    titel:"Wat is de ontwikkeling van het aantal werknemers in de gehandicaptenzorg?",
    kleur:"1",
  },
  {
    template:"harmonica",
    naam:"organisaties-werknemers",
    titel:"Welke organisaties vertegenwoordigen zoal zorgverleners in de gehandicaptenzorg?",
    kleur:"1",
  },
  {
    template:"harmonica",
    naam:"organisaties-werkgevers",
    titel:"Welke organisaties vertegenwoordigen zoal werkgevers in de gehandicaptenzorg?",
    kleur:"1",
  },
  {
    template:"harmonica",
    naam:"organisaties-kennis",
    titel:"Welke organisaties houden zich bezig met kennis in de gehandicaptenzorg? ",
    kleur:"1",
  },
  {
    template:"harmonica",
    naam:"organisaties-advies",
    titel:"Welke instanties adviseren de overheid over beleid van de gehandicaptenzorg? ",
    kleur:"1",
  },
  {
    template:"harmonica",
    naam:"organisaties-toezicht",
    titel:"Welke organisaties houden toezicht op de gehandicaptenzorg?",
    kleur:"1",
  },
  {
    template:"intro",
    naam:"stelselwetten",
    titel:"Hoe werken de stelselwetten in de gehandicaptenzorg?",
    kleur:"2",
    tekst:
      "De zorg voor mensen met een beperking wordt geregeld door maar liefst vijf stelselwetten: de Wet publieke gezondheid, Wet maatschappelijke ondersteuning, Jeugdwet, Zorgverzekeringswet en Wet langdurige zorg. Wat regelen deze wetten en wat is relevant voor de gehandicaptenzorg?",
    links:
      [
        'stelselwetten-wpg',
        'stelselwetten-wmo',
        'stelselwetten-jeugdwet',
        'stelselwetten-zvw',
        'stelselwetten-wlz',
        'hulpmiddelen-per-wet'
      ]
  },
  {
    template:"harmonica",
    naam:"stelselwetten-wpg",
    titel:"Hoe werkt de Wet publieke gezondheid en wat is relevant voor de gehandicaptenzorg? ",
    kleur:"2",
  },
  {
    template:"harmonica",
    naam:"stelselwetten-wmo",
    titel:"Hoe werkt de Wet maatschappelijke ondersteuning en wat is relevant voor de gehandicaptenzorg?",
    kleur:"2",
  },
  {
    template:"harmonica",
    naam:"stelselwetten-jeugdwet",
    titel:"Hoe werkt de Jeugdwet en wat is relevant voor de gehandicaptenzorg?",
    kleur:"2",
  },
  {
    template:"harmonica",
    naam:"stelselwetten-zvw",
    titel:"Hoe werkt de Zorgverzekeringswet en wat is relevant voor de gehandicaptenzorg?",
    kleur:"2",
  },
  {
    template:"harmonica",
    naam:"stelselwetten-wlz",
    titel:"Hoe werkt de Wet langdurige zorg en wat is relevant voor de gehandicaptenzorg?",
    kleur:"2",
  },
  {
    template:"harmonica",
    naam:"hulpmiddelen-per-wet",
    titel:"Welke hulpmiddelen uit de gehandicaptenzorg komen uit welke wet?",
    kleur:"2",
  },
  {
    template:"intro",
    naam:"wetten-gehandicaptenzorg",
    titel:"Wat zijn de relevante (zorg)wetten in de gehandicaptenzorg?",
    kleur:"2",
    tekst:
      "Niet alleen de vijf stelselwetten zijn belangrijk voor de gehandicaptenzorg. Ook andere (zorg)wetten spelen een rol in de gehandicaptenzorg, zoals de Wet zorg en dwang? Wat regelt deze wet en welke wetten zijn nog meer relevant?",
    links:
      [
        'zorgwetten-gehandicaptenzorg'
      ]
  },
  {
    template:"harmonica",
    naam:"zorgwetten-gehandicaptenzorg",
    titel:"Wat zijn de relevante (zorg)wetten in de gehandicaptenzorg?",
    kleur:"2",
  },
  {
    template:"intro",
    naam:"uitgaven",
    titel:"Hoeveel geld geven we uit aan gehandicaptenzorg?",
    kleur:"3",
    tekst:
      "In 2019 gaven we meer dan 11 miljard euro uit aan de zorg voor mensen met een beperking. Hoe ontwikkelen deze uitgaven zich? En wat moeten mensen met een beperking eigenlijk zelf (bij)betalen?",
    links:
      [
        '/basis/euros-cbs',
        '/basis/euros-vws',
        'ontwikkeling-uitgaven',
        'uitgaven-wlz',
        'eigen-betaling'
      ]
  },
  {
    template:"visualisatie",
    naam:"ontwikkeling-uitgaven",
    titel:"Hoe ontwikkelen de uitgaven aan gehandicaptenzorg zich?",
    kleur:"3",
  },
  {
    template:"visualisatie",
    naam:"uitgaven-wlz",
    titel:"Wat zijn de uitgaven aan gehandicaptenzorg uit de Wlz, uitgesplitst naar beperking en leveringsvorm?",
    kleur:"3",
  },
  {
    template:"harmonica",
    naam:"eigen-betaling",
    titel:"Wanneer betalen mensen met een beperking bij voor hun zorg en ondersteuning?",
    kleur:"3",
  },
  {
    template:"intro",
    naam:"geldstromen",
    titel:"Hoe lopen de geldstromen in de gehandicaptenzorg?",
    kleur:"3",
    tekst:
      "Mensen met een beperking krijgen zorg vanuit verschillende stelselwetten. Hoe komt het geld dat we uitgeven aan gehandicaptenzorg - van burger naar Rijksoverheid naar zorgverzekeraar, zorgkantoor of gemeente - uiteindelijk bij de zorgverlener terecht?",
    links:
      [
        'geldstromen-wmo',
        'geldstromen-jeugdwet',
        'geldstromen-zvw',
        'geldstromen-wlz',
      ]
  },
  {
    template:"visualisatie",
    naam:"geldstromen-wmo",
    titel:"Hoe lopen de geldstromen voor gehandicaptenzorg in de Wet maatschappelijke ondersteuning?",
    kleur:"3",
    links: [
      'wie-betaalt-wmo'
    ]
  },
  {
    template:"visualisatie",
    naam:"geldstromen-zvw",
    titel:"Hoe lopen de geldstromen voor gehandicaptenzorg in de Zorgverzekeringswet?",
    kleur:"3",
    links: ['wie-betaalt-zvw']
  },
  {
    template:"visualisatie",
    naam:"geldstromen-jeugdwet",
    titel:"Hoe lopen de geldstromen voor gehandicaptenzorg in de Jeugdwet?",
    kleur:"3",
    links: ['wie-betaalt-jeugdwet']
  },
  {
    template:"visualisatie",
    naam:"geldstromen-wlz",
    titel:"Hoe lopen de geldstromen voor gehandicaptenzorg in de Wet langdurige zorg?",
    kleur:"3",
    links: ['wie-betaalt-wlz']
  },
  {
    template:"harmonica",
    naam:"wie-betaalt-wmo",
    titel:"Hoe lopen de geldstromen voor gehandicaptenzorg in de Wet maatschappelijke ondersteuning?",
    kleur:"3",
  },
  {
    template:"harmonica",
    naam:"wie-betaalt-jeugdwet",
    titel:"Wie betaalt wat voor gehandicaptenzorg in de Jeugdwet?",
    kleur:"3",
  },
  {
    template:"harmonica",
    naam:"wie-betaalt-zvw",
    titel:"Wie betaalt wat voor gehandicaptenzorg in de Zorgverzekeringswet?",
    kleur:"3",
  },
  {
    template:"harmonica",
    naam:"wie-betaalt-wlz",
    titel:"Wie betaalt wat voor gehandicaptenzorg in de Wet langdurige zorg?",
    kleur:"3",
  },
]

pages.forEach(page => {
  if (!page.hasOwnProperty('categorie')) {
    page.categorie = "gehandicaptenzorg"
  }
})

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
	if (pagina.template === "harmonica") {
		const harmonicaNaam = pagina.naam.replace(/-/g, "_");
		pagina.harmonica = harmonicas[harmonicaNaam];
	}
	if (pagina.template === "visualisatie") {
		const visualisatieNaam = pagina.naam.replace(/-/g, "_");
		pagina.vis = vissen[visualisatieNaam];
	}
	return pagina;
});

export default pages;
