import React from 'react';
import { format } from 'd3'
import BarChart from '../../../components/BarChart/BarChart';

const SchattingenDoelgroep = () => {
  const formatValueTooltip = val =>
    val < 1 ? `${val * 1000} duizend` : `${val} miljoen`

  const createTable = (data) => (
    <table className="tabel">
      <tbody>
      {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueTooltip(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
    { key: 'Verstandelijke beperking', value: 1.1 },
    { key: 'Lichamelijke beperking*', value: 1.5 },
    { key: 'Zintuiglijke beperking**', value:  0.234 },
  ];

  return (
    <div className="vis-schattingen-doelgroep">
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0,1.5]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={format(',.1f')}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-credit">
        <p>Schatting van het aantal miljoen mensen met een verstandelijke, lichamelijke of zintuiglijke beperking.</p>
        <p>*Vanaf 12 jaar tot overlijden, inclusief ouderdoms-gerelateerde beperkingen</p>
        <p>**Bij geboorte</p>
        <p>Bron: CBS (2020), SCP (2019), VGN (2019), FODOK-FOSS (2020), Bartiméus (2020)</p>
      </div>

    </div>
  )
}

export default SchattingenDoelgroep;
