import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const SoortenInformeleZorg = () => {
	const formatValue = val => val + '%';
  const formatTooltip = val => val + '%';

  const data = [
    { key: "Gezelschap en emotionele ondersteuning", value: 80 },
    { key: "Ondersteuning bij vervoer of artsenbezoek", value: 52 },
    { key: "Administratieve ondersteuning", value: 42 },
    { key: "Persoonlijke verzorging", value: 12 },
    { key: "Verpleegkundige hulp", value: 10 }
  ];

	return (
		<div className="vis-soorten-informele-zorg">
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, bottom: 10, left: 350 }}
        barHeight={30}
        domain={[0,100]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatTooltip}
        formatValueY={formatValue}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-60}
      />

      <div className="vis-credit">
        <p>Soorten gegeven hulp en ondersteuning door mantelzorgers in 2019.</p>
        <p>Bron: Blijvende bron van zorg, Sociaal en Cultureel Planbureau, 2020</p>
      </div>

      <br/>
	</div>
	)
}

export default SoortenInformeleZorg;
