import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"colofon-a-infectieziekte",
  titel:"Colofon",
  kleur:COLOR_YELLOW,
  tekst: "",
  links: []
}
