const searchstring = (state = '', action) => {
  switch (action.type) {
    case 'SEARCH':
      return action.searchstring
    default:
       return state
  }
}

export default searchstring
