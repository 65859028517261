import React from "react";
import LineChart from "../../../../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const LevensVerwachting = () => {
	const margin = { top: 0, right: 30, bottom: 25, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);
	const formatValueTooltip = value => formatY(value) + " mensen";

	const data = [
		{
			name: "honderdjarigen",
			values: [
				{ x: 2000, y: 500000 },
				{ x: 2020, y: 822000 },
				{ x: 2040, y: 1603000 },
			]
		}
	];

	return (
		<div className="vis-aantal-80-jaar">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[0, 2900000]}
				data={data}
				height={280}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal mensen in Nederland van (naar verwachting) 80 jaar of ouder.</p>
				<p>Bron: CBS Statline, 2022.</p>
			</div>
		</div>
	);
};

export default LevensVerwachting;
