import React, {Component} from 'react';
import Twitter from '../Icons/Twitter';
import Facebook from '../Icons/Facebook';
import Mail from '../Icons/Mail';
import LinkedIn from '../Icons/LinkedIn';

import restricted from '../../data/restricted';

import './ShareButtons.scss';

class ShareButtons extends Component {

  constructor(props) {
    super(props);
    this.state = {message: ''};
  }

	render(){

		const { text, category } = this.props;
		let url = window.location.href;
		if (url.indexOf('.html') === -1) url = url.concat('.html');

		const clickHandler = (event) => {
			//event.preventDefault();
			//this.setState({message: 'In de testversie is het delen van de app nog niet mogelijk.'})
		}

		if (restricted.indexOf(category) > -1) return null;

		return (
			<div className='social'>

				<a
						onClick={clickHandler}
						href={'https://facebook.com/sharer/sharer.php?u=' + url}
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina op Facebook.'>
				    <Facebook/>
				</a>

				<a
						onClick={clickHandler}
						href={'https://twitter.com/intent/tweet/?text=' + encodeURIComponent(text) + '%20%23zowerktdezorg&url=' + url}
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina via Twitter.'>
						<Twitter />
				</a>

				<a
						onClick={clickHandler}
						href={'mailto:?subject=' + encodeURIComponent(text) + '&body=' + encodeURIComponent('Kijk op ' + url + ' voor het antwoord.')}
						target='_self'
						aria-label='Deel deze pagina via e-mail.'>
					<Mail />
				</a>

				<a
						onClick={clickHandler}
						href={'https://www.linkedin.com/shareArticle?mini=true&amp;url=' + url + '&amp;title=Zó%20werkt%20de%20zorg&amp;summary=' + encodeURIComponent(text) }
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina via LinkedIn'>
				  		<LinkedIn />
		  	 </a>

				<div className='message'>{this.state.message}</div>

			</div>
	  )
	}
}

export default ShareButtons;
