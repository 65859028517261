import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"colofon-huiselijk-geweld",
  titel:"Colofon",
  kleur:COLOR_TURQUOISE,
  tekst: "",
  links: []
}
