// import React from 'react';

// import harmonicas
import * as harmonicas from './harmonicas';
import vissen from './visualizations';

const pages = [{
        "categorie": "basis",
        "template": "specialvoorpagina",
        "naam": "huisartsenzorg",
        "titel": "Zó werkt de huisartsenzorg",
        "kleur": 5,
        "tekst": "Hoe werkt de huisartsenzorg? Vind hier alles over spelers en organisaties, wetten en financiering.",
        "links": ["rol-zorgstelsel", "wie-is-wie","wetten","zorgwetten-huisarts","uitgaven", "geldstromen"]
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "rol-zorgstelsel",
        "titel": "Wat is de rol van de huisarts in het zorgstelsel?",
        "kleur": 1,
        "tekst": "De huisarts is de spin in het web, vanwege haar samenwerking met andere zorgverleners. Sinds 2016 zijn er meer vrouwelijke dan mannelijke huisartsen. Daarom spreken we in deze app gemakshalve van een ‘zij’.",
        "links": ['rol', 'waar-wanneer', 'wat-doet-huisarts', 'samenwerking-eerste-lijn', 'samenwerking-tweede-lijn']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "rol",
        "titel": "Welke rol heeft de huisarts in het zorgstelsel?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "waar-wanneer",
        "titel": "Waar en wanneer vindt huisartsenzorg plaats?",
        "kleur": 1,
        "teksten": ["Waar? Wanneer?", "Praktijk, Huisartsenpost, Huisbezoek"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "wat-doet-huisarts",
        "titel": "Wat doet de huisarts?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "samenwerking-eerste-lijn",
        "titel": "Met wie werkt de huisarts samen in de eerste lijn?",
        "kleur": 1,
        "teksten": ["Apotheek, Huisartsenpost, Wijkverpleging & thuiszorg, Zorggroep, Fysiotherapie, Eerstelijns psycholoog, Diëtetiek, Algemeen maatschappelijk werk, Jeugdgezondsheidzorg, Oefentherapie, Diagnostisch centrum, Verloskunde, Medewerkers gemeente, Podotherapie, Consultatiebureau, Logopedie","Percentage van huisartsen dat in 2014 structureel of regulier overlegt met eerstelijns zorgaanbieders. Bron: NIVEL 2015"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "samenwerking-tweede-lijn",
        "titel": "Met wie werkt de huisarts samen in de tweede lijn?",
        "kleur": 1,
        "teksten": ["Geestelijke gezondheidszorg, Verpleeghuis, Inwendige specialismen, Medisch coördinerend centrum, Dermatologie, allergologie, Cardiologie, Gynaecologie, Orthopedie, Chirurgie, Oogheelkunde, Neurologie, Keel-neus-oorheelkunde, Urologie, Gespecialiseerde jeugdhulp","Percentage van huisartsen dat in 2014 structureel of regulier overlegt met tweedelijns zorgaanbieders. Bron: NIVEL 2015"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "wie-is-wie",
        "titel": "Wie is wie en wie doet wat in de huisartsenzorg?",
        "kleur": 1,
        "tekst": "Wie zegt “ik moet naar de huisarts” bedoelt meestal niet één persoon. In veel gevallen gaat het om een huisartsenpraktijk waar meer mensen werken, zoals de doktersassistent en de praktijkondersteuner.",
        "links": ['hoeveel-huisartsen','vrouwelijke-huisartsen','praktijkvormen','medisch-actief', 'organisaties', 'opleiding']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "hoeveel-huisartsen",
        "titel": "Hoeveel huisartsen werken er in Nederland?",
        "kleur": 1,
        "teksten": ["Aantal regulier gevestigde huisartsen. Bron: NIVEL 2015"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "vrouwelijke-huisartsen",
        "titel": "Waar is het aandeel vrouwelijke huisartsen het grootst?",
        "kleur": 1,
        "tekst": ["Aandeel vrouwelijke huisartsen per ROS-regio in 2015. Bron: NIVEL 2016"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "praktijkvormen",
        "titel": "In welke praktijkvormen werken huisartsen?",
        "kleur": 1,
        "teksten": ["Duopraktijk, Groepspraktijk, Solopraktijk", "Percentage huisartsen per praktijkvorm in 2015. Bron: NIVEL 2016"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "medisch-actief",
        "titel": "Wie is medisch actief in de huisartsenpraktijk?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "organisaties",
        "titel": "Wat zijn de belangrijkste organisaties waar de huisarts mee te maken heeft?",
        "kleur": 1,
        "tekst": "Tientallen organisaties zijn betrokken bij de huisartsenzorg. Wat doen organisaties behorend bij afkortingen zoals NHG, NPA, CGS, LHV, NVDA, V&VN, LAD, DTO, en Wagro?",
        "links": ['organisaties-kwaliteit', 'organisaties-vertegenwoordigen', 'samenwerking-verbanden']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "organisaties-kwaliteit",
        "titel": "Welke organisaties houden zich bezig met de kwaliteit van huisartsenzorg?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "organisaties-vertegenwoordigen",
        "titel": "Welke organisaties vertegenwoordigen landelijk de mensen in de huisartsenzorg?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "samenwerking-verbanden",
        "titel": "In welke verbanden werken huisartsen lokaal of in de regio met elkaar samen?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "opleiding",
        "titel": "Hoe werkt de opleiding van huisartsen?",
        "kleur": 1,
        "tekst": "Om huisarts te worden, moet je eerst de studie Geneeskunde afronden en je vervolgens specialiseren tot huisarts. In 2016 volgden bijna 2.100 basisartsen de huisartsopleiding.",
        "links": ['opleiding-huisarts', 'opleiding-organisaties']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "opleiding-huisarts",
        "titel": "Hoe ziet de opleiding tot huisarts eruit?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "opleiding-organisaties",
        "titel": "Welke organisaties zijn betrokken bij het opleiden van huisartsen?",
        "kleur": 1,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "wetten",
        "titel": "Hoe werken de stelselwetten voor de huisartsenzorg?",
        "kleur": 2,
        "tekst": "Nederland kent vier stelselwetten die betrekking hebben op de huisartsenzorg. Dat zijn de Zorgverzekeringswet, de Wet langdurige zorg, de Wet maatschappelijke Ondersteuning en de Jeugdwet. Wat houden deze wetten in en wat is relevant voor de huisarts?",
        "links": ['stelselwetten-zvw', 'stelselwetten-wlz', 'stelselwetten-wmo', 'stelselwetten-jw']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "stelselwetten-zvw",
        "titel": "Hoe werkt de Zorgverzekeringswet en wat is relevant voor de huisarts?",
        "kleur": 2,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "stelselwetten-wlz",
        "titel": "Hoe werkt de Wet langdurige zorg en wat is relevant voor de huisarts?",
        "kleur": 2,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "stelselwetten-wmo",
        "titel": "Hoe werkt de Wet maatschappelijke ondersteuning en wat is relevant voor de huisarts?",
        "kleur": 2,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "stelselwetten-jw",
        "titel": "Hoe werkt de Jeugdwet en wat is relevant voor de huisarts?",
        "kleur": 2,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "zorgwetten-huisarts",
        "titel": "Wat zijn relevante (zorg)wetten voor de huisarts?",
        "kleur": 2,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "financiering",
        "titel": "Hoe werkt de financiering van huisartsenzorg?",
        "kleur": 3,
        "tekst": "We bezoeken onze huisarts gemiddeld vier keer per jaar. We hoeven hier niet los voor te betalen, maar we bekostigen huisartsenzorg gezamenlijk. De minister van Volksgezondheid, Welzijn en Sport heeft ruim 2,8 miljard euro uitgetrokken voor de huisartsenzorg in 2017. Hoe komt dit geld – van burger via Rijk en verzekeraar – uiteindelijk bij de huisarts terecht? ",
        "links": ['kosten', 'geldstromen', 'segmentering', 'controle-declaraties', 'inkomen']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "uitgaven",
        "titel": "Hoeveel geven we uit aan huisartsenzorg?",
        "kleur": 3,
        "tekst": "Het CBS en het ministerie van VWS hanteren verschillende definities en bedragen. De kosten voor de huisartsenzorg zijn met 2,8 miljard euro in ieder geval maar een klein deel van alle zorgkosten.",
        "links": [
            '/basis/euros-vws',
            '/basis/euros-cbs',
            'kosten-per-persoon'
        ]
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "kosten-per-persoon",
        "titel": "Wat betaalt de Nederlander gemiddeld aan huisartsenzorg per jaar?",
        "kleur": 3,
        "teksten": ["Jaarlijkse kosten aan huisartsenzorg in euro. Bron: CBS Statline 2017"],
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "bekostiging-huisartsenpost",
        "titel": "Hoe worden huisartsenposten bekostigd?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "geldstromen",
        "titel": "Hoe lopen de geldstromen voor de huisartsenzorg?",
        "kleur": 3,
        "tekst": "Voor een bezoekje aan de huisarts hoef je niet los te betalen. We bekostigen huisartsenzorg gezamenlijk. Hoe komt dit geld – van burger via Rijk en verzekeraar – uiteindelijk bij de huisarts terecht?",
        "links": ['wie-betaalt-wat', 'geldstromen-zvw', 'segmentering', 'bekostiging-huisartsenpost', 'controle-declaraties', 'inkomen']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "wie-betaalt-wat",
        "titel": "Wie betaalt wat voor de huisartsenzorg in de Zorgverzekeringswet?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "geldstromen-zvw",
        "titel": "Hoe lopen de geldstromen voor de huisartsenzorg in de Zorgverzekeringswet?",
        "kleur": 3,
        "teksten": ["Burgers, Rijk, Zorginstituut Nederland, Zorgverzekeraars, Huisartsen, Huisartsenposten, Zorggroepen", "Eigen betalingen, Rijksbijdrage, Rijksbijdrage, Risicoverevening, Zorgtoeslag, Inkomensafhankelijke bijdrage, Nominale premie, Kinderpremie, Segment 1, Buitensegment, Vergoeding buitensegment, Segment 2, Vergoeding segment 2, Segment 3"],
        "links": ["wie-betaalt-wat"]
    },
    {
        "categorie": "huisartsenzorg",
        "template": "intro",
        "naam": "segmentering",
        "titel": "Waaruit bestaat de omzet van huisartsen?",
        "kleur": 3,
        "tekst": "De omzet van de huisartsenpraktijk komt uit verschillende geldstromen die in het jargon de ‘segmenten’ heten. Er zijn vier segmenten die gezamenlijk grotendeels de taken van de huisarts afdekken.",
        "links": ['kosten-segmenten', 'segmentering-1', 'segmentering-2', 'segmentering-3', 'segmentering-buiten']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "kosten-segmenten",
        "titel": "Hoe zijn de huisartskosten verdeeld over de segmenten?",
        "kleur": 3,
        "teksten": ["Segment 1, Segment 2, Segment 3, Buitensegment", "Gerealiseerde kosten in miljoen euro op basis van voorlopige declaraties over 2016. Bron: Vektis 2017"],
        "links": ['segmentering-1', 'segmentering-2', 'segmentering-3', 'segmentering-buiten']
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "segmentering-1",
        "titel": "Waaruit bestaat de geldstroom in segment 1 en wat doet de huisarts hiervoor?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "segmentering-2",
        "titel": "Waaruit bestaat de geldstroom in segment 2 en wat doet de huisarts hiervoor?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "segmentering-3",
        "titel": "Waaruit bestaat de geldstroom in segment 3 en wat doet de huisarts hiervoor?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "segmentering-buiten",
        "titel": "Waaruit bestaat de geldstroom in het buitensegment en wat doet de huisarts hiervoor?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "harmonica",
        "naam": "controle-declaraties",
        "titel": "Hoe ziet de controle van huisartsdeclaraties eruit?",
        "kleur": 3,
        "tekst": "",
        "links": ""
    },
    {
        "categorie": "huisartsenzorg",
        "template": "visualisatie",
        "naam": "inkomen",
        "titel": "Wat is het gemiddelde inkomen van de huisarts vóór loonbelasting?",
        "kleur": 3,
        "teksten": ["Gemiddeld jaarinkomen van zelfstandig gevestigde huisartsen vóór loonbelasting x 1.000 euro. Bron: CBS Statline 2017"],
        "links": ""
    }
]

// loop over pages to add harmonicas and visualizations
pages.map((pagina) => {
    if (pagina.template === "harmonica") {
        const harmonicaNaam = pagina.naam.replace(/-/g, '_');
        pagina.harmonica = harmonicas[harmonicaNaam];
    }
    if (pagina.template === "visualisatie") {
        const visualisatieNaam = pagina.naam.replace(/-/g, '_');
        pagina.vis = vissen[visualisatieNaam];
    }
    return pagina;
});
export default pages;
