import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const OntvangersInformeleZorg = () => {
	const formatValueY = val => val + '%';
	const formatValueTooltip = val => val + '%';

  const data2019 = [
    { key: 'Langdurig lichamelijk', value: 48 },
    { key: 'Tijdelijk lichamelijk', value: 21 },
    { key: 'Dementie of geheugenproblemen', value: 17 },
    { key: 'Psychische problemen', value: 16 },
    { key: 'Ouderdom', value: 11 },
    { key: 'Psychosociale problemen', value: 8 },
    { key: 'Terminale ziekte', value: 6 },
    { key: 'Verstandelijke beperking', value: 5 },
  ];

	return (
		<div className="vis-ontvangers-informele-zorg">

      <h4>2019</h4>
      <BarChart
        data={data2019}
        margin={{ top: 10, right: 0, bottom: 10, left: 270 }}
        barHeight={30}
        domain={[0,85]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        parts={[]}
      />

      <div className="vis-credit">
        <p>Type beperking waarvoor mensen mantelzorg ontvangen in 2019.</p>
        <p>Bron: Blijvende bron van zorg, Sociaal en Cultureel Planbureau, 2020</p>
      </div>

      <br/>
	</div>
	)
}

export default OntvangersInformeleZorg;
