import React from 'react';

const Euro = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<g fill="none" fill-rule="evenodd"><path stroke="#FFF" stroke-width="2" d="M48.48 76H24V24h21.67"/><path fill="#FFF" fill-rule="nonzero" d="m43.84 70.27-.95 1.03 5.05 4.7-5.05 4.7.95 1.02L50 76z"/><path stroke="#FFF" stroke-width="2" d="M51.52 24H76v52H54.33"/><path fill="#FFF" fill-rule="nonzero" d="m56.16 29.73.95-1.03-5.05-4.7 5.05-4.7-.95-1.02L50 24zM61.05 43.18h-3.2c-.78-3.61-3.28-5.9-6.93-5.9-1.97 0-3.69.7-5.04 2.05-1.35 1.39-1.8 2.87-1.97 5.86h8.98v2.05h-9.02v3.98h9.02v2.05h-9.02c.16 3.16.62 4.67 2.01 6.07 1.35 1.35 3.08 2.05 5.04 2.05 3.65 0 6.15-2.3 6.93-5.9h3.2c-1.02 5.45-4.88 8.69-10.13 8.69-3.03 0-5.49-1.07-7.3-2.87-2.13-2.13-2.75-4.14-2.87-8.04H38v-2.05h2.75v-3.98H38v-2.05h2.75c.16-3.77.78-5.74 2.87-7.83 1.8-1.8 4.26-2.87 7.3-2.87 5.29 0 9.1 3.24 10.13 8.69Z"/></g>
	</svg>
);

export default Euro;



