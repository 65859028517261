// import React from 'react';

// import harmonicas
import * as harmonicas from "./harmonicas";
import vissen from "./visualizations";

const pages = [
    {
        categorie: "basis",
        template: "specialvoorpagina",
        naam: "ouderenzorg",
        titel: "Zó werkt de ouderenzorg",
        kleur: 5,
        tekst:
            "Hoe werkt de ouderenzorg? Vind hier alles over de vormen van zorg, spelers en organisaties, wetten en financiering.",
        links: [
            "ouderen-en-ondersteuning",
            "vormen-van-zorg",
            "arbeidsmarkt",
            "organisaties",
            "stelselwetten",
            "zorgwetten-ouderenzorg",
            "uitgaven",
            "geldstromen"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "ouderen-en-ondersteuning",
        titel: "Wat zijn ouderen en wie ondersteunen hen?",
        kleur: 6,
        tekst:
            "Onder 'ouderen' verstaan we alle Nederlanders die 65 jaar of ouder zijn. Maar hoe oud iemand zich voelt verschilt vaak per persoon. Hoe ziet de 'oudere' in Nederland er uit?",
        links: [
            "hoeveel-ouderen",
            "achtergrond-ouderen",
            "bevolking-verpleeghuis",
            "fysieke-beperkingen",
            "mantelzorgers",
            "leeftijd-mantelzorgers"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "hoeveel-ouderen",
        titel: "Hoeveel ouderen zijn er in Nederland?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "achtergrond-ouderen",
        titel: "Welk percentage ouderen heeft een migratie-achtergrond?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "bevolking-verpleeghuis",
        titel: "Hoe is de bevolking van verpleeghuizen samengesteld?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "fysieke-beperkingen",
        titel:
            "In welke mate ervaren bewoners van verpleeghuizen fysieke beperkingen?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "mantelzorgers",
        titel: "Hoeveel mensen zijn actief als mantelzorger of vrijwilliger?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "leeftijd-mantelzorgers",
        titel:
            "Welk deel van de mantelzorgers en vrijwilligers is 65 jaar of ouder?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "vormen-van-zorg",
        titel: "Welke vormen van zorg krijgen ouderen?",
        kleur: 6,
        tekst:
            "Ouderen krijgen verschillende vormen van zorg, afhankelijk van hun situatie. Hoeveel ouderen krijgen welke vormen en wel zwaarte van zorg? En hoe lopen de zorgroutes voor ouderen?",
        links: [
            "zorgtreden",
            "ouderen-per-zorgtrede",
            "zorgvormen",
            "zorgroutes"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "zorgtreden",
        titel: "Welke zorg wordt geleverd op de zes zorgtreden?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "ouderen-per-zorgtrede",
        titel: "Hoeveel ouderen krijgen zorg volgens de zes zorgtreden?",
        kleur: 6,
        tekst: "",
        links: ["kosten-zorgtreden"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "zorgvormen",
        titel: "Wat zijn de tien vormen van zorg die ouderen kunnen krijgen?",
        kleur: 6,
        tekst: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "zorgroutes",
        titel: "Hoe lopen de zorgroutes voor ouderen?",
        kleur: 6,
        tekst: "",
        links: ["welke-zorgroutes"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "welke-zorgroutes",
        titel: "Welke zorgroutes voor ouderen zijn er?",
        kleur: 6,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "arbeidsmarkt",
        titel: "Hoe zien de arbeidsmarkt en opleidingen in de ouderenzorg eruit?",
        kleur: 4,
        tekst:
            "Als we spreken over ouderenzorg hebben we het niet over één vorm van zorg. Ouderen krijgen bijvoorbeeld ondersteuning in huis, zorg in de wijk of specialistische zorg in het ziekenhuis. Wie werken er in de ouderenzorg?",
        links: [
            "hoeveel-werknemers",
            "dienstverband-werknemers",
            "functies-ouderenzorg",
            "studenten",
            "zorgvraag"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "hoeveel-werknemers",
        titel: "Hoeveel mensen werken in de ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "dienstverband-werknemers",
        titel: "In welk soort dienstverband werken mensen in de ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "functies-ouderenzorg",
        titel: "In welke functies werken mensen in de ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ["soorten-werknemers"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "soorten-werknemers",
        titel: "Welke zorgprofessionals werken zoal in de ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "studenten",
        titel:
            "Hoeveel studenten volgen een opleiding voor een beroep in de ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ["opleidingen"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "opleidingen",
        titel: "Wat zijn veel voorkomende opleidingen voor ouderenzorg?",
        kleur: 4,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "zorgvraag",
        titel:
            "Hoe verandert de zorgvraag en wat vraagt dit van de zorgverlener?",
        kleur: 4,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "organisaties",
        titel:
            "Wie zijn belangrijke partijen in de ouderenzorg en wat doen zij?",
        kleur: 1,
        tekst:
            "Verschillende organisaties ondersteunen en vertegenwoordigen ouderen, mantelzorgers, werknemers en werkgevers? Wie zijn zij en wat doen ze? En wie houdt er toezicht op en adviseert er over de ouderenzorg?",
        links: [
            "organisaties-ouderen",
            "organisaties-werknemers",
            "organisaties-werkgevers",
            "organisaties-kwaliteit",
            "organisaties-advies",
            "organisaties-toezicht"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-ouderen",
        titel:
            "Welke organisaties vertegenwoordigen de ouderen en hun mantelzorgers in de ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-werknemers",
        titel:
            "Welke organisaties vertegenwoordigen de werknemers in de ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-werkgevers",
        titel:
            "Welke organisaties vertegenwoordigen de werkgevers in de ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-kwaliteit",
        titel:
            "Welke organisaties houden zich bezig met de kennis en kwaliteit van ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-advies",
        titel: "Welke instanties adviseren over de ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "organisaties-toezicht",
        titel: "Welke instanties houden toezicht op de ouderenzorg?",
        kleur: 1,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "stelselwetten",
        titel: "Hoe werken de stelselwetten voor de ouderenzorg?",
        kleur: 2,
        tekst:
            "Nederland kent vier stelselwetten die betrekking hebben op de ouderenzorg. Dat zijn de Zorgverzekeringswet, de Wet langdurige zorg, de Wet maatschappelijke Ondersteuning en de Wet publieke gezondheid. Wat houden deze wetten in en wat is relevant voor de ouderenzorg?",
        links: [
            "stelselwetten-wmo",
            "stelselwetten-zvw",
            "stelselwetten-wlz",
            "stelselwetten-wpg",
            "zorgvormen-stelselwetten"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "stelselwetten-wmo",
        titel: "Hoe werkt de Wmo en wat is relevant voor de ouderenzorg?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "stelselwetten-zvw",
        titel: "Hoe werkt de Zvw en wat is relevant voor de ouderenzorg?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "stelselwetten-wlz",
        titel: "Hoe werkt de Wlz en wat is relevant voor de ouderenzorg?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "stelselwetten-wpg",
        titel: "Hoe werkt de Wpg en wat is relevant voor de ouderenzorg?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "zorgvormen-stelselwetten",
        titel: "Waar kunnen ouderen welke vorm van zorg krijgen?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "zorgwetten-ouderenzorg",
        titel: "Wat zijn de relevante (zorg)wetten voor de ouderenzorg?",
        kleur: 2,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "uitgaven",
        titel: "Hoeveel geven we uit aan ouderenzorg?",
        kleur: 3,
        tekst:
            "Het Centraal Bureau voor Statistiek en de Nederlandse Zorgautoriteit hanteren verschillende definities en bedragen. Duidelijk is dat er in Nederland veel geld wordt uitgegeven aan de zorg voor ouderen.",
        links: [
            // "/basis/euros-cbs",
            // "/basis/euros-vws",
            "uitgaven-leeftijdsgroep",
            "kosten-zorgtreden",
            "uitgaven-zorgvormen"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "uitgaven-leeftijdsgroep",
        titel: "Wat zijn de uitgaven aan ouderenzorg per leeftijdsgroep?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "kosten-zorgtreden",
        titel: "Wat zijn de kosten van ouderenzorg volgens de zes zorgtreden?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "uitgaven-zorgvormen",
        titel: "Wat zijn de uitgaven aan ouderenzorg per zorgvorm?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "intro",
        naam: "geldstromen",
        titel: "Hoe lopen de geldstromen in de ouderenzorg?",
        kleur: 3,
        tekst:
            "Ouderen krijgen verschillende vormen van zorg vanuit verschillende stelselwetten. Hoe komt het geld dat we uitgeven aan ouderenzorg - van burger naar Rijksoverheid naar zorgverzekeraar of gemeente - uiteindelijk bij de zorgverlener terecht?",
        links: [
            "geldstromen-wmo",
            "geldstromen-zvw",
            "geldstromen-wlz",
            "uitgaven-ouderen"
        ]
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "geldstromen-wmo",
        titel: "Hoe lopen de geldstromen in de Wmo?",
        kleur: 3,
        tekst: "",
        links: ["wie-betaalt-wmo"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "wie-betaalt-wmo",
        titel: "Wie betaalt wat in de Wmo?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "geldstromen-zvw",
        titel: "Hoe lopen de geldstromen in de Zvw?",
        kleur: 3,
        tekst: "",
        links: ["wie-betaalt-zvw"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "wie-betaalt-zvw",
        titel: "Wie betaalt wat in de Zvw?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "visualisatie",
        naam: "geldstromen-wlz",
        titel: "Hoe lopen de geldstromen in de Wlz?",
        kleur: 3,
        tekst: "",
        links: ["wie-betaalt-wlz"]
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "wie-betaalt-wlz",
        titel: "Wie betaalt wat in de Wlz?",
        kleur: 3,
        tekst: "",
        links: ""
    },
    {
        categorie: "ouderenzorg",
        template: "harmonica",
        naam: "uitgaven-ouderen",
        titel:
            "Wat betalen ouderen zelf voor zorg vanuit de drie stelselwetten?",
        kleur: 3,
        tekst: "",
        links: ""
    }
];

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
    if (pagina.template === "harmonica") {
        const harmonicaNaam = pagina.naam.replace(/-/g, "_");
        pagina.harmonica = harmonicas[harmonicaNaam];
    }
    if (pagina.template === "visualisatie") {
        const visualisatieNaam = pagina.naam.replace(/-/g, "_");
        pagina.vis = vissen[visualisatieNaam];
    }
    return pagina;
});
export default pages;
