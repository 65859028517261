import React from "react";
import { Link } from "react-router-dom";

import Pijl from "../Icons/Pijl";
import icons from "../Icons/icons";

import restricted from '../../data/restricted';

import "./Links.scss";

const isLinkToAnotherCategory = (link) =>
	link.startsWith('/')

const getPageForLink = (categorie, link, pages) => {
	return pages
		.filter((pagina) => {
			if (isLinkToAnotherCategory(link)) {
				const linkParts = link.substring(1).split('/')
				const lastPart = linkParts[linkParts.length - 1]
				return pagina.naam === lastPart && pagina.categorie === linkParts[0]
			} else {
				return pagina.naam === link && pagina.categorie === categorie
			}
		})[0]
}

const pathForCategorie = (categorie) =>
	categorie === "basis" ? "/" : "/" + categorie + "/";

const LinkWithAccess = ({ i, title, categorie, page, link, icon, ...props}) => {
	const path = page.categorie === categorie ?
		pathForCategorie(categorie) : ''

		return (
			<li className={`link-${categorie}-${link} link-color-${page.kleur}`} {...props}>
				<Link to={path + link}>
						{icon}
					<span>{title}</span>
					<Pijl />
				</Link>
			</li>
		)
}

const LinkWithoutAccess = ({ categorie, link, i, icon, title, ...props }) =>
	<li className={"link-" + categorie + "-" + link + " noaccess"} {...props}>
		{icon}
		{title}
		<Pijl />
	</li>

const Links = ({ categorie, links, pages, access, specialindex }) => {
	if (!links) return null;

	const classNames = specialindex
		? "page-links specialindex-links"
		: "page-links";

	return (
		<ul className={classNames}>
			{
				links.map((link, i) => {
					console.log(link, categorie, pages)
					const page = getPageForLink(categorie, link, pages)
					const title = page.titel;
					const auth = !(specialindex && restricted.indexOf(link) > -1 && access.indexOf(link) === -1);
					const icon = icons[link] ? icons[link] : null;

					return auth ?
						<LinkWithAccess key={i}
														categorie={categorie}
														page={page}
														link={link}
														title={title}
														icon={icon}
						/> :
						<LinkWithoutAccess key={i}
															 categorie={categorie}
															 link={link}
															 icon={icon}
															 title={title}
						/>
				})
			}
		</ul>
	)
};

export default Links;
