import {COLOR_MAGENTA} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "harmonica",
  naam: "rollen-ondersteuning-op-school",
  titel: "Wie heeft welke rol bij ondersteuning op school?",
  kleur: COLOR_MAGENTA,
  tekst: "",
  links: [
    'wat-is-ondersteuning-op-school',
    'bekostiging-ondersteuning-op-school'
  ]
}
