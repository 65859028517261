import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';

const AantalCuratiefLangdurig = () => {
  const data = [
    {
      "label": "POH-GGZ",
      "value": 605514,
    },
    {
      "label": "Generalistische basis-ggz",
      "value": 235553,
    },
    {
      "label": "Specialistische ggz",
      "value": 558067,
    },
    {
      "label": "Langdurige ggz en Wlz",
      "value": 5159,
    },
  ];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatLabel = item => {
    const shortLabel = item.label;
    return `${shortLabel} ${localeFormatter.format(',.0f')(item.value)}`;
  }

  const formatTotal = () => (
    <text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0}>1,4 miljoen</tspan>
      <tspan x={0} dy={15}>mensen</tspan>
    </text>);

  return (
    <div className="vis-aantal-curatief-langdurig">
      <Donut
        data={data}
        height={400}
        fontFamily="din-regular"
        fontSize="15px"
        formatLabel={formatLabel}
        formatTotal={formatTotal}
        unit="mensen"
        animation={true}
      />

      <div className="vis-credit">
        <p>
          Aantal mensen dat in 2019 gebruikmaakt van een vorm van curatieve of landurige ggz.
          Omdat mensen van meerdere vormen gebruik kunnen maken, is er sprake van dubbeltellingen.
        </p>
        <p>Bron: Trimbos Instituut, 2021</p>
      </div>

    </div>
  );
}

export default AantalCuratiefLangdurig;
