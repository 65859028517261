import React from 'react';

const Huisarts = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<polygon points="20 80 20 45 50 20 80 45 80 80 20 80" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
		<line x1="50" y1="33" x2="50" y2="73" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
		<path d="M50,58a5,5,0,0,1,0,10H45" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
		<path d="M50,58a5,5,0,0,1,0-10" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
		<path d="M50,48a5,5,0,0,0,0-10H45" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
	</svg>
);

export default Huisarts;

