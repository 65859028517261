import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';


const AantalHuishoudens = () => {
	const data = [
			{
	        "label": "Eén voorziening",
	        "value": 819560,
	    },
	    {
	        "label": "Twee voorzieningen",
	        "value": 544260,
	    },
	    {
	        "label": "Drie voorzieningen",
	        "value": 199905,
	    },
			{
	        "label": "Vier voorzieningen",
	        "value": 107205,
	    },
		{
			"label": "Vijf of meer voorzieningen",
			"value": 146465,
		},
	];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ' ' + localeFormatter.format(',.0f')(item.value);
	}

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0} dy={-15}>Totaal</tspan>
      <tspan x={0} dy={20}>1,8 miljoen</tspan>
      <tspan x={0} dy={22}>Nederlanders</tspan>
    </text>);

	return (
		<div className="vis-aantal-huishoudens">

			<Donut
				data={data}
				height={300}
				fontFamily="din-regular"
				fontSize="13px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				formatTooltip={formatLabel}
				unit="miljoen"
				animation={true}
			/>


			<div className="vis-credit">
				<p>Aantal huishoudens dat gebruik maakt van één of meerdere sociaaldomeinvoorzieningen in de eerste helft van 2020.</p>
				<p>Bron: CBS, Monitor Sociaal Domein WMO, 2021</p>
			</div>

		</div>
	);
}

export default AantalHuishoudens;


