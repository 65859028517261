import React from 'react';
import { Link } from 'react-router-dom';

const Gezondheidsrisicos = () => (
<div className="vis-gezondheidsrisicos svg-container">
<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 100 420 600"
		preserveAspectRatio="xMidYMin meet">

	<g className="kruis">
		<polygon points="205 435.26 202 432 202 318 208 318 208 432 205 435.26"/>
		<polygon points="205 200.74 208 204 208 318 202 318 202 204 205 200.74"/>
		<polygon points="88 318 91.26 315 205 315 205 321 91.26 321 88 318"/>
		<polygon points="322.26 318 319 321 205 321 205 315 319 315 322.26 318"/>
	</g>

	<g>
		<Link to={{ pathname: '/zorgverzekering/soorten-zorgverzekeringen', hash: '#Aanvullendeverzekering' }} >
			<rect className="kader" x="97" y="210" width="96" height="96"/>
			<text className="tekstWit" transform="translate(112 254.17)">Aanvullende<tspan x="1.21" y="15">verzekering</tspan></text>
		</Link>
		<Link to={{ pathname: '/zorgverzekering/soorten-zorgverzekeringen', hash: '#Zorgverzekeringswet' }} >
			<rect className="kader" x="217" y="210" width="96" height="96"/>
			<text className="tekstWit" transform="translate(255 254.17)">Zorg-<tspan x="-25" y="15">verzekerings</tspan><tspan x="2" y="30">wet</tspan></text>
		</Link>
		<rect className="kader" x="97" y="330" width="96" height="96"/>
		<Link to={{ pathname: '/zorgverzekering/soorten-zorgverzekeringen', 	hash: '#Wetlangdurigezorg' }} >
			<rect className="kader" x="217" y="330" width="96" height="96"/>
			<text className="tekstWit" transform="translate(254.28 368.17)">Wet<tspan x="-20" y="15">langdurige</tspan><tspan x="-5" y="30">zorg</tspan></text>
		</Link>
	</g>

	<g className="tekstZwart">
		<text transform="translate(135 192)">Privaat verzekerde risico’s</text>
		<text transform="translate(125 455.29)">Niet-privaat verzekerde risico’s</text>
		<text transform="translate(331 318.15)">Noodzakelijke<tspan x="0" y="15">zorg</tspan></text>
		<text transform="translate(56.36 306.15)">Niet-<tspan x="-53.23" y="15">noodzakelijke</tspan><tspan x="-1.73" y="30">zorg</tspan></text>
	</g>
		<g className="tekstWit">
		<text transform="translate(134.42 375.67)">Zelf<tspan x="-10" y="15">betalen</tspan></text>
		</g>

</svg>
</div>
);

export default Gezondheidsrisicos;
