import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_GREEN} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "kennis-kwaliteit",
    titel: "Hoe komen kennis en kwaliteit tot stand?",
    kleur: COLOR_GREEN,
    tekst: "",
    links:
      [
        'kennis',
        'kwaliteit',
        'goede-zorg',
      ]
  },
  {
    template: "visualisatie",
    naam: "kennis",
    titel: "Hoe ziet de kenniscyclus in de zorg eruit?",
    kleur: COLOR_GREEN,
    links: ['kwaliteit']
  },
  {
    template: "harmonica",
    naam: "kwaliteit",
    titel: "Hoe komt kwaliteit van zorg tot stand?",
    kleur: COLOR_GREEN,
    links: ['goede-zorg']
  },
  {
    template: "harmonica",
    naam: "goede-zorg",
    titel: "Wat is kwalitatief goede zorg volgens de Wet kwaliteit, klachten en geschillen zorg (Wkkgz)?",
    kleur: COLOR_GREEN,
    links: ['zorgwetten-1']
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
