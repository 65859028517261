import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';
import { formatLocale } from "d3";

const InkomstenPerPremiebetaler = () => {

	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: ".",
		"grouping": [3],
		"comma": true,
	});

	const margin = {top: 90, right: 0, bottom: 90, left: 0};
	const formatY = value => localeFormatter.format(",.0f")(value);
	const formatValueToolTip = d => (<span>{d.category}<br/>{localeFormatter.format(",.0f")(d.data.value) + ' euro'}</span>);

	const data = [
			{
		"year": "2014",
		"Nominale premie": 1063,
		"Inkomensafhankelijke bijdrage en rijksbijdrage": 1616
		},
		{
		"year": "2015",
		"Nominale premie": 1128,
		"Inkomensafhankelijke bijdrage en rijksbijdrage": 1695
		},
		{
		"year": "2016",
		"Nominale premie": 1169,
		"Inkomensafhankelijke bijdrage en rijksbijdrage": 1641
		},
		{
		"year": "2017",
		"Nominale premie": 1251,
		"Inkomensafhankelijke bijdrage en rijksbijdrage": 1651
		}
	];

	return (
		<div className="vis-inkomsten-per-premiebetaler">

		<ColumnChart
			margin={margin}
			colorBars={["#333","#ddd"]}
			colorAxis="#333"
			labelY="x 1000"
			height={450}
			domain={[0, 2902]}
			columnPadding={0.5}
			formatY={formatY}
			formatValueToolTip={formatValueToolTip}
			data={data}
			stacked={true}
		/>

		<div className="vis-credit">
			<p>Jaarlijkse inkomsten in euro per premiebetaler.</p>
			<p>Bron: Ministerie VWS, 2018.</p>
		</div>

	</div>
	);
}
export default InkomstenPerPremiebetaler;
