import React from 'react';

import BarChart from '../../../components/BarChart/BarChart';

const ToezichtOuderenzorg = () => {
	const data = [
		{
			"key": "2020",
			"value": 138000,
		},
		{
			"key": "2030",
			"value": 189000,
		},
		{
			"key": "2040",
			"value": 261000,
		},
	];

	const formatNumber = value =>
		value.toLocaleString(
			'nl', // leave undefined to use the browser's locale,
			// or use a string like 'en-US' to override it.
			{ minimumFractionDigits: 0 }
		)

	const formatValueTooltip = val => `${formatNumber(val)} bewoners`
	const formatValueY = val => formatNumber(val);

	return (
		<div className="vis mini">
			<div className="vis-toezicht-ouderenzorg">
				<BarChart
					data={data}
					margin={{ top: 30, right: 0, bottom: 10, left: 140 }}
					barHeight={30}
					domain={[0,261000]}
					color={ {fill: '#333', stroke: '#fff'} }
					formatValueTooltip={formatValueTooltip}
					formatValueY={formatValueY}
					padding={0.2}
					ticks={0}
					marginBeforeValue={-100}
					animation={true}
				/>

				<div className="vis-credit">
					<p>Het aantal ouderen dat bij ongewijzigd beleid 24 uur per dag zorg en toezicht nodig heeft, gebaseerd op de huidige zorgvraag van ouderen.</p>
					<p>Bron: TNO Rapport prognose capaciteitsontwikkeling verpleeghuiszorg</p>
				</div>
			</div>
		</div>
	);
}

export default ToezichtOuderenzorg;
