import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN, COLOR_GREEN, COLOR_PURPLE, COLOR_TURQUOISE} from "../../vocabulary";
import {aInfectieziekte, geboortezorg, huiselijkGeweld, medischeGegevens, ondersteuningOpSchool} from './pages/index'

const pages = [
{
	categorie:"basis",
	template:"specialvoorpagina",
	naam:"mini",
	titel:"Mini's",
	kleur: COLOR_PURPLE,
	tekst:
		"Helder overzicht bij actuele en interessante thema's, dat is het doel van onze Zó werkt de zorg-mini's. De heldere informatie die je van Zó werkt de zorg gewend bent, maar dan in het kort.",
	links: [
		'mini-ondersteuning-op-school',
		'mini-medische-gegevens',
		'mini-huiselijk-geweld',
		'mini-jeugdzorg-plus',
		'mini-onafhankelijke-clientondersteuning',
		'mini-informele-zorg',
		'mini-acute-zorg',
		// 'mini-ggz',
		'mini-ouderenzorg',
		'mini-a-infectieziekte',
		'mini-geboortezorg',
		]
	},
	{
		template: "intro",
		naam: "mini-jeugdzorg-plus",
		titel: "Zó werkt de zorg-mini: JeugdzorgPlus",
		kleur: COLOR_GREEN,
		tekst:
			"Jeugdzorg streeft naar passende hulp voor jongeren waarbij deze zo min mogelijk uit huis geplaatst hoeven worden. Toch komt het nog altijd voor dat er jongeren in JeugdzorgPlus worden opgenomen. Wat is JeugdzorgPlus precies en om hoeveel jongeren gaat het?",
		links: [
			'wat-is-jeugdzorgplus',
			'hoeveel-jongeren',
			'verblijftijd',
			'colofon-jeugdzorgplus'
		]
	},
	{
		template:"harmonica",
		naam:"wat-is-jeugdzorgplus",
		titel:"Wat is JeugdzorgPlus, om wie gaat het en wat doet JeugdzorgPlus zoal?",
		kleur: COLOR_GREEN,
		links: [
			'hoeveel-jongeren',
			'verblijftijd'
		]
	},
	{
		template: "visualisatie",
		naam:"hoeveel-jongeren",
		titel:"Hoeveel jongeren krijgen zorg in de JeugdzorgPlus?",
		kleur: COLOR_GREEN,
		links: [
			'wat-is-jeugdzorgplus',
			'verblijftijd'
		]
	},
	{
		template: "visualisatie",
		naam:"verblijftijd",
		titel:"Hoe lang verbleven jongeren in JeugdzorgPlus in 2020?",
		kleur: COLOR_GREEN,
		links: [
			'wat-is-jeugdzorgplus',
			'hoeveel-jongeren'
		]
	},
	{
		template:"harmonica",
		naam:"colofon-jeugdzorgplus",
		titel:"Colofon",
		kleur: COLOR_GREEN,
	},
	{
		template:"intro",
		naam:"mini-onafhankelijke-clientondersteuning",
		titel:"Zó werkt de zorg-mini: Cliëntondersteuning",
		kleur: COLOR_TURQUOISE,
		tekst:
			"Mensen die zorg of ondersteuning nodig hebben, kunnen hulp krijgen van een onafhankelijke cliëntondersteuner bij het regelen ervan. Gemeenten en zorgkantoren moeten mensen hierop wijzen. Dat is wettelijk geregeld. Toch weten veel mensen niet dat zij recht hebben op cliëntondersteuning. Wat cliëntondersteuning inhoudt en hoe het wordt geregeld, lees je in deze mini.",
		links: [
			'onafhankelijke-clientondersteuning-feiten',
			'taken-clientondersteuner',
			'bekendheid-clientondersteuner',
			'colofon-onafhankelijke-clientondersteuning'
		]
	},
	{
		template:"harmonica",
		naam:"onafhankelijke-clientondersteuning-feiten",
		titel:"Wat is onafhankelijke cliëntondersteuning en wat zijn relevante feiten?",
		kleur: COLOR_TURQUOISE,
		links: [
			'taken-clientondersteuner',
			'bekendheid-clientondersteuner'
		]
	},
	{
		template:"harmonica",
		naam:"taken-clientondersteuner",
		titel:"Wat doet de onafhankelijke cliëntondersteuner?",
		kleur: COLOR_TURQUOISE,
		links: [
			'onafhankelijke-clientondersteuning-feiten',
			'bekendheid-clientondersteuner'
		]
	},
	{
		template: "visualisatie",
		naam:"bekendheid-clientondersteuner",
		titel:"Hoeveel mensen die zorg krijgen uit de Wmo weten dat zij hulp kunnen krijgen van een onafhankelijke cliëntondersteuner?",
		kleur: COLOR_TURQUOISE,
		links: [
			'onafhankelijke-clientondersteuning-feiten',
			'taken-clientondersteuner'
		]
	},
	{
		template:"harmonica",
		naam:"colofon-onafhankelijke-clientondersteuning",
		titel:"Colofon",
		kleur: COLOR_TURQUOISE,
	},
	{
		template:"intro",
		naam:"mini-informele-zorg",
		titel:"Zó werkt de zorg-mini: Informele zorg",
		kleur: COLOR_PURPLE,
		tekst:
			"De behoefte aan informele zorg zal de komende jaren toenemen doordat steeds meer mensen langer (alleen) thuis wonen en het aantal hulpbehoevende ouderen snel stijgt. Wat is informele zorg, hoeveel mensen helpen anderen, wat doen ze en wie helpen ze?",
		links: [
			'informele-zorg',
			'soorten-informele-zorg',
			'ontvangers-informele-zorg',
			'colofon-informele-zorg'
		]
	},
	{
		template:"harmonica",
		naam:"informele-zorg",
		titel:"Wat is informele zorg en hoeveel mensen helpen anderen hoe lang?",
		kleur: COLOR_PURPLE,
		links: [
			'soorten-informele-zorg',
			'ontvangers-informele-zorg'
		]
	},
	{
		template: "visualisatie",
		naam:"soorten-informele-zorg",
		titel:"Welke soorten ondersteuning geven mantelzorgers?",
		kleur: COLOR_PURPLE,
		links: [
			'informele-zorg',
			'ontvangers-informele-zorg'
		]
	},
	{
		template: "visualisatie",
		naam:"ontvangers-informele-zorg",
		titel:"Voor welk type beperking krijgen mensen mantelzorg?",
		kleur: COLOR_PURPLE,
		links: [
			'informele-zorg',
			'soorten-informele-zorg',
		]
	},
	{
		template:"harmonica",
		naam:"colofon-informele-zorg",
		titel:"Colofon",
		kleur: COLOR_PURPLE,
		links: []
	},
{
	template:"intro",
	naam:"mini-acute-zorg",
	titel:"Zó werkt de zorg-mini: Acute zorg",
	kleur: 6,
	tekst:
		"In 2019 sloten meerdere ziekenhuizen hun spoedeisende hulp (SEH)-afdeling, wat leidde tot een discussie over de toegankelijkheid van acute zorg. Wat acute zorg precies is en hoe het zit met de toegang, lees je in deze mini.",
	links:[
		'wat-is-acute-zorg',
		'rijtijdSEH',
		'aantalSEHafdelingen',
		'colofon-acute-zorg'
		]
},
{
	template:"harmonica",
	naam:"wat-is-acute-zorg",
	titel:"Wat is acute zorg en wie doet wat?",
	kleur: 6,
	tekst: "",
	links: [
		'rijtijdSEH',
		'aantalSEHafdelingen'
		]
},
{
	template:"visualisatie",
	naam:"rijtijdSEH",
	titel:"Hoe ver is het naar de meest nabije SEH-afdeling?",
	kleur: 6,
	tekst: "",
	links: [
		'wat-is-acute-zorg',
		'aantalSEHafdelingen'
		]
},
{
	template:"visualisatie",
	naam:"aantalSEHafdelingen",
	titel:"Hoeveel SEH-afdelingen zijn er?",
	kleur: 6,
	tekst: "",
	links: [
		'wat-is-acute-zorg',
		'rijtijdSEH'
		]
},
{
	template:"harmonica",
	naam:"colofon-acute-zorg",
	titel:"Colofon",
	kleur: 6,
	tekst: "",
	links: []
},
	{
		template:"intro",
		naam:"mini-ggz",
		titel:"Zó werkt de zorg-mini: Ggz",
		kleur:1,
		tekst:
			"De geestelijke gezondheidszorg (Ggz) staat volop in de aandacht, mede door een discussie over de beschikbaarheid van zorg voor hoogcomplexe patiënten. Maar hoe is de ggz eigenlijk geregeld? Hoeveel geven we in Nederland uit aan geestelijke gezondheidszorg? En hoe staat het met het aantal 'bedden' in ggz-instellingen, nadat in 2012 werd afgesproken om zorg zoveel mogelijk thuis (ambulant) te bieden? Je leest het in deze mini.",
		links:
			['wat-is-ggz',
			'zorguitgaven-ggz',
			'aantal-ggz-bedden',
			'colofon-ggz']
	},
	{
		template:"harmonica",
		naam:"wat-is-ggz",
		titel:"Wat is de ggz en welke wet regelt wat?",
		kleur:1,
		tekst: "",
		links:
			[ 'zorguitgaven-ggz',
			'aantal-ggz-bedden']
	},
	{
		template:"visualisatie",
		naam:"zorguitgaven-ggz",
		titel:"Hoeveel geld geven we in Nederland uit aan geestelijke gezondheidszorg?",
		kleur:1,
		tekst: "",
		links:
			['wat-is-ggz',
			'aantal-ggz-bedden']
	},
	{
		template:"visualisatie",
		naam:"aantal-ggz-bedden",
		titel:"Hoeveel bedden hebben ggz-instellingen in Nederland beschikbaar voor geestelijke gezondheidszorg?",
		kleur:1,
		tekst: "",
		links:
			['wat-is-ggz',
			'zorguitgaven-ggz']
	},
	{
		template:"harmonica",
		naam:"colofon-ggz",
		titel:"Colofon",
		kleur:1,
		tekst: "",
		links: []
	},
	{
		template:"intro",
		naam:"mini-ouderenzorg",
		titel:"Zó werkt de zorg-mini: Toekomst ouderenzorg",
		kleur:3,
		tekst:
			"De komende decennia moeten we 'woekeren met schaarse middelen' in de ouderenzorg, aldus de commissie Toekomst zorg thuiswonende ouderen. Waarom deze noodkreet? De feiten over de ouderenzorg - bij ongewijzigd beleid - op een rij.",
		links:
			[
				'wat-is-ouderenzorg',
				'toezicht-ouderenzorg',
				'geld-ouderenzorg',
				'colofon-ouderenzorg'
			]
	},
	{
		template:"harmonica",
		naam:"wat-is-ouderenzorg",
		titel:"Wat is ouderenzorg en wat zijn relevante trends?",
		kleur: COLOR_CYAAN,
		tekst: "",
		links:
			[
				'toezicht-ouderenzorg',
				'geld-ouderenzorg'
			]
	},
	{
		template: "visualisatie",
		naam: "toezicht-ouderenzorg",
		titel: "Hoeveel ouderen hebben blijvend 24 uur per dag zorg en toezicht nodig?",
		kleur: COLOR_CYAAN,
		tekst: "",
		links:
			[
				'wat-is-ouderenzorg',
				'geld-ouderenzorg'
			]
	},
	{
		template: "visualisatie",
		naam: "geld-ouderenzorg",
		titel: "Hoeveel geld gaat naar de ouderenzorg?",
		kleur: COLOR_CYAAN,
		tekst: "",
		links:
			[
				'wat-is-ouderenzorg',
				'toezicht-ouderenzorg'
			]
	},
	{
		template:"harmonica",
		naam:"colofon-ouderenzorg",
		titel:"Colofon",
		kleur:COLOR_CYAAN,
		tekst: "",
		links: []
	},

	aInfectieziekte.intro,
	aInfectieziekte.watIsEenAInfectieziekte,
	aInfectieziekte.wieDoetWat,
	aInfectieziekte.dwingendeMaatregelen,
	aInfectieziekte.colofon,

	geboortezorg.intro,
	geboortezorg.watIsGeboortezorg,
	geboortezorg.aantalBevallingen,
	geboortezorg.uitgavenGeboortezorg,
	geboortezorg.colofon,

	huiselijkGeweld.intro,
	huiselijkGeweld.watIsHuiselijkGeweld,
	huiselijkGeweld.hoeveelMeldingen,
	huiselijkGeweld.spelersAanpak,
	huiselijkGeweld.colofon,

	medischeGegevens.intro,
	medischeGegevens.delen,
	medischeGegevens.bereidheid,
	medischeGegevens.verzamelen,
	medischeGegevens.colofon,

	ondersteuningOpSchool.intro,
	ondersteuningOpSchool.watIs,
	ondersteuningOpSchool.rollen,
	ondersteuningOpSchool.bekostiging,
	ondersteuningOpSchool.colofon
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
	if (pagina.categorie === undefined) {
		pagina.categorie = 'mini'
	}

	if (pagina.template === "harmonica") {
		const harmonicaNaam = pagina.naam.replace(/-/g, "_");
		pagina.harmonica = harmonicas[harmonicaNaam];
	}
	if (pagina.template === "visualisatie") {
		const visualisatieNaam = pagina.naam.replace(/-/g, "_");
		pagina.vis = vissen[visualisatieNaam];
	}
	return pagina;
});

export default pages;
