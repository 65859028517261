import React, {Component} from 'react';
import { Highlight } from 'react-highlighter-ts';

import Kruis from '../Icons/Kruis';

import './Harmonica.scss';


class Harmonica extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount(){
    const props = this.props,
          items = props.data.items,
          level = props.level ? props.level : 0,
          expandable = (items && level > 1 && level < 3) || (level === 1 && !items.items),
          searchstring = props.searchstring.toLowerCase();

    const checkHighlights = (items, searchstring) => {
      let hits = false;
        items.map((item) => {
          if (item.tekst.toLowerCase().indexOf(searchstring) > -1) hits = true;
          if (item.items) checkHighlights(item.items, searchstring);
          return item;
        });
        return hits;
    }

    const highlights = (searchstring !== '' && searchstring.length > 2 && items) ?
        checkHighlights(items, searchstring) :
        false;

    if (props.open !== '') {
      props.open === props.data.tekst.replace(/\s/g, '') || props.statusParent === 'initialOpen' ?
        this.setState({ status: 'initialOpen'}) :
        this.setState({ status: 'initialClosed'});
    }
    else {
      items && !highlights && !(props.first && level === 1)  ?
        this.setState({ status: 'initialClosed'}) :
        this.setState({ status: 'initialOpen'});
    }

    if (!expandable) this.setState({ status: ''});

  }

  clickHandler = () => {
      this.state.status === 'closed' || this.state.status === "initialClosed" ? this.setState({ status: 'open'}) : this.setState({ status: 'closed'});
  }

  render(){

    const props = this.props,
          {tekst, items} = props.data,
          level = props.level ? props.level : 0,
          expandable = (items && level > 1 && level < 3) || (level === 1 && !items.items) ? 'expandable' : '',
          last = items ? '' : 'last';

    const children = items ? items.map((item, i) => (
          <Harmonica
            key={level+'-'+i}
            first={i===0}
            data={item}
            level={level+1}
            searchstring={props.searchstring}
            open={props.open}
            statusParent={this.state.status}
            />
        )) : null;

    const toelichting = tekst.indexOf('Toelichting:') === 0 ? 'toelichting' : '';

    const icon = expandable ? <Kruis /> : null;
    const baseClass = ' level' + level + ' ' + expandable + ' ' + this.state.status + ' ' + last + ' ' + toelichting;
    const thisClass = 'harmonica' + baseClass;
    const thisTextClass = 'harmonica-text' + baseClass;
    const thisContentClass = 'harmonica-children' + baseClass;
    const childBox = children ? <div className={thisContentClass}>{children}</div> : null;

    return (
      <div className={thisClass} >
        <div className={thisTextClass} onClick={expandable ? this.clickHandler : undefined }>
          <Highlight search={props.searchstring}>{tekst}</Highlight>
          {icon}
        </div>
        {childBox}
      </div>
    );
  };
};

export default Harmonica;
