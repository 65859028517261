import React from "react";
import LineChart from "../../../../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';


// middelste cijfer zichtbaar, zie https://app.zwdz.nl/geneesmiddelenzorg/hoeveel-gebruikers

const Zorguitgaven = () => {
    const margin = { top: 60, right: 30, bottom: 90, left: 45 };

    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "comma": true,
    });

    const formatY = (val) => localeFormatter.format(',.0f')(val);
    const formatValueTooltip = value => formatY(value) + " miljard euro";

    const data = [
        {
            name: "aantal vacatures",
            values: [
                { x: 2012, y: 78 },
                { x: 2013, y: 79 },
                { x: 2014, y: 80.1 },
                { x: 2015, y: 80.1 },
                { x: 2016, y: 82 },
                { x: 2017, y: 83.9 },
                { x: 2018, y: 87.3 },
                { x: 2019, y: 92.8 },
                { x: 2020, y: 100.1 },
                { x: 2021, y: 108 },
            ]
        }
    ];

    return (
        <div className="vis-zorguitgaven">
            <LineChart
                margin={margin}
                colorLines={["#000"]}
                colorAxis="#333"
                labelY="aantal"
                domainY={[40, 110]}
                data={data}
                height={600}
                formatY={formatY}
                formatValueTooltip={formatValueTooltip}
            />

            <div className="vis-credit">
                <p>De ontwikkeling van de zorguitgaven in miljard euro in de periode 2012-2021.</p>
                <p>* De cijfers voor 2021 zijn voorlopig.</p>
                <p>Bron: CBS Statline, 2022.</p>
            </div>
        </div>
    );
};

export default Zorguitgaven;
