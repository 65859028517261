import React from 'react';
//import { Link } from 'react-router-dom';

const Stappen = () => (
<div className="vis-stappen-nieuw-geneesmiddel svg-container">
	<svg viewBox="0 0 660 780" preserveAspectRatio="xMidYMin meet">
	<g className="tijdlijn">
		<polyline points="93 78.48 105 78.48 105 702.48 93 702.48"/>
		<line x1="105" y1="270.48" x2="93" y2="270.48"/>
		<line x1="105" y1="318.48" x2="93" y2="318.48"/>
		<line x1="105" y1="414.48" x2="93" y2="414.48"/>
		<line x1="105" y1="510.48" x2="93" y2="510.48"/>
		<line x1="105" y1="606.48" x2="93" y2="606.48"/>
		<line x1="105" y1="654.48" x2="93" y2="654.48"/>
	</g>
	<g className="balk">
		<rect x="165" y="162.48" width="192" height="24" transform="translate(435.48 -86.52) rotate(90)"/>
		<rect x="237" y="282.48" width="48" height="24" transform="translate(555.48 33.48) rotate(90)"/>
		<rect x="117" y="450.48" width="288" height="24" transform="translate(723.48 201.48) rotate(90)"/>
		<rect x="237" y="618.48" width="48" height="24" transform="translate(891.48 369.48) rotate(90)"/>
		<rect x="237" y="666.48" width="48" height="24" transform="translate(939.48 417.48) rotate(90)"/>
	</g>
	<g className="aanpijlen">
		<g>
			<path d="M240.32,603.48H224.91a2.92,2.92,0,0,1-2.91-2.91V84.39a2.92,2.92,0,0,1,2.91-2.91h15.41"/>
			<path className="aanpijlBol" d="M240.18,600.66a2.82,2.82,0,1,1-2.82,2.82A2.82,2.82,0,0,1,240.18,600.66Z"/>
			<path className="aanpijlBol" d="M240.18,84.3a2.82,2.82,0,1,0-2.82-2.82A2.82,2.82,0,0,0,240.18,84.3Z"/>
		</g>
		<g>
			<path d="M240.32,652.5H224.91a2.92,2.92,0,0,1-2.91-2.91V613.41a2.92,2.92,0,0,1,2.91-2.91h15.41"/>
			<path className="aanpijlBol" d="M240.18,649.68a2.82,2.82,0,1,1-2.82,2.82A2.82,2.82,0,0,1,240.18,649.68Z"/>
			<path className="aanpijlBol" d="M240.18,613.32a2.82,2.82,0,1,0-2.82-2.82A2.82,2.82,0,0,0,240.18,613.32Z"/>
		</g>
		<g>
			<path d="M240.32,702H224.91a2.92,2.92,0,0,1-2.91-2.91V662.91a2.92,2.92,0,0,1,2.91-2.91h15.41"/>
			<path className="aanpijlBol" d="M240.18,699.18a2.82,2.82,0,1,1-2.82,2.82A2.82,2.82,0,0,1,240.18,699.18Z"/>
			<path className="aanpijlBol" d="M240.18,662.82a2.82,2.82,0,1,0-2.82-2.82A2.82,2.82,0,0,0,240.18,662.82Z"/>
		</g>
		<g>
			<path d="M281.68,321.48h36.41a2.92,2.92,0,0,1,2.91,2.91V357"/>
			<path className="aanpijlBol" d="M281.82,324.3a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,281.82,324.3Z"/>
		</g>
		<g>
			<path d="M281.68,411.48h36.41a2.92,2.92,0,0,0,2.91-2.91V375"/>
			<circle className="aanpijlBol" cx="281.82" cy="411.48" r="2.82"/>
		</g>
		<g>
			<path d="M281.68,414.48h36.41a2.92,2.92,0,0,1,2.91,2.91V453"/>
			<path className="aanpijlBol" d="M281.82,417.3a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,281.82,417.3Z"/>
		</g>
		<g>
			<path d="M281.68,507.48h36.41a2.92,2.92,0,0,0,2.91-2.91V471"/>
			<path className="aanpijlBol" d="M281.82,510.3a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,281.82,510.3Z"/>
		</g>
		<g>
			<path d="M281.68,510.48h36.41a2.92,2.92,0,0,1,2.91,2.91V549"/>
			<path className="aanpijlBol" d="M281.82,513.3a2.82,2.82,0,1,1,2.82-2.82A2.82,2.82,0,0,1,281.82,513.3Z"/>
		</g>
		<g>
			<path d="M281.68,606.48h36.41a2.92,2.92,0,0,0,2.91-2.91V567"/>
			<circle className="aanpijlBol" cx="281.82" cy="606.48" r="2.82"/>
		</g>
	</g>
  <g className="tekst">
    <g className="tijdbalk">
      <text transform="translate(55 707.76)">na 13 jaar</text>
      <text transform="translate(55 659.76)">na 12 jaar</text>
      <text transform="translate(55 611.77)">na 11 jaar</text>
      <text transform="translate(56 515.76)">na 9 jaar</text>
      <text transform="translate(56 419.76)">na 7 jaar</text>
      <text transform="translate(56 320.77)">na 5 jaar</text>
      <text transform="translate(70 80.76)">start</text>
      <text transform="translate(56 272.77)">na 4 jaar</text>
    </g>
    <g className="fase">
      <text transform="translate(302 564)">Fase 3</text>
      <text transform="translate(302 468)">Fase 2</text>
      <text transform="translate(302 372)">Fase 1</text>
    </g>
    <g className="onderzoek">
      <text transform="translate(360 459)">Klinisch<tspan x="10" y="18">onderzoek</tspan></text>
      <text transform="translate(360 300)">Preklinisch onderzoek</text>
      <text transform="translate(372 178.98)">Fundamenteel onderzoek</text>
    </g>
    <g className="hoofdfase">
      <text transform="translate(170 639)">Registratie</text>
      <text transform="translate(170 681.86)">Vergoeding</text>
      <text transform="translate(170 346.71)">Onderzoek</text>
    </g>
  </g>
	</svg>
</div>
);

export default Stappen;
