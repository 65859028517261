import React from 'react';

const Hamer = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<path className="hamer" d="M61.2,81.2H18.8v-7.5h42.5V81.2z M21.2,78.8h37.5v-2.5H21.2V78.8z M61.2,81.2H18.8v-7.5h42.5V81.2z M21.2,78.8 h37.5v-2.5H21.2V78.8z M81.2,43.8h-35v-2.5v-2.5v-5H28.8v5v2.5v2.5h-5v7.5h5v2.5v2.5v5h17.5v-5v-2.5v-2.5h35V43.8z M31.2,36.2h12.5 v2.5H31.2V36.2z M26.2,48.8v-2.5h2.5v2.5H26.2z M43.8,58.8H31.2v-2.5h12.5V58.8z M43.8,53.8H31.2v-2.5v-7.5v-2.5h12.5v2.5v7.5V53.8 z M78.8,48.8H46.2v-2.5h32.5V48.8z"/>
	</svg>
);

export default Hamer;


