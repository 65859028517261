import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const GemeentenUitgaven = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.1f')(val);
	const formatValueTooltip = value => `${formatY(value)} miljard euro`;

	const data = [
		{
			name: "honderdjarigen",
			values: [
				{ x: 2014, y: 16.578 },
				{ x: 2015, y: 23.997 },
				{ x: 2016, y: 23.844 },
				{ x: 2017, y: 22.853 },
				{ x: 2018, y: 23.235 },
				{ x: 2019, y: 24.315 },
				{ x: 2020, y: 25.390 },
				{ x: 2021, y: 26.216 }
			]
		}
	];

	return (
		<div className="vis-gemeenten-uitgaven">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[12, 30]}
				data={data}
				height={350}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Begrote uitgaven van gemeenten aan het sociaal domein in miljard euro.</p>
				<p>Bron: CBS Statline, 2021</p>
			</div>
		</div>
	);
};

export default GemeentenUitgaven;
