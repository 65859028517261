import React from 'react';

const Ouderenzorg = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<path d="M39.75,64.16H30v-34H41m19,34H70v-34H58.75M71.31,68.75H65V86.3M50,16A10,10,0,1,0,60,26,10,10,0,0,0,50,16ZM40,50V85H60V50" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
	</svg>
);

export default Ouderenzorg;

