import React from 'react';
import { Link } from 'react-router-dom';
import { Highlight } from 'react-highlighter-ts';

import icons from '../Icons/icons';

import './SearchResults.scss';


const loopOverHarmonica = (data, searchstring, fragments) => {
    if (data.tekst && data.tekst.toLowerCase().indexOf(searchstring) > -1) {
        fragments.push(data.tekst);
        return fragments;
    }
    if (data.items) {
        data.items.map((data) => loopOverHarmonica(data, searchstring, fragments));
        return fragments;
    }
}

const checkVisTeksten = (teksten, searchstring) => {
    var fragments = teksten.filter((tekst) => tekst.toLowerCase().indexOf(searchstring) > -1);
    return fragments;
}

const SearchResults = (props) => {
    if (props.page.naam !== 'zoeken') return null;

    const { pages, access } = props;
    const searchstring = props.searchstring.toLowerCase();

    let results = [], resultText;

    if (searchstring.length > 2) {
        results = pages.filter((page) => {
            page.fragments = [];
            // searchstring in title?
            if (page.titel &&
                page.titel !== 'Zoekresultaten' &&
                page.titel !=='Zó werkt de zorg') {
                    page.inTitle =  page.titel.toLowerCase().indexOf(searchstring) > -1;
                }

            // searchstring in text?
            if (page.tekst &&
                page.tekst.toString().toLowerCase().indexOf(searchstring) > -1) {
                    page.fragments.push(page.tekst);
            }

            // maybe this text is in jsx?
            if (page.tekst && typeof page.tekst === 'object' && page.tekst.$$typeof){
              let textInJSX = "";
              // console.log("page.tekst.props.children" + page.tekst.props.children[0]);
              //oorspronkelijke functie van BasB gaf foutmelding
              // page.tekst.props.children.forEach(child => {
              //   if (typeof child === 'string') textInJSX += " " + child
              // });
              Array.prototype.forEach.call(page.tekst.props.children, child => {
                if (typeof child === 'string') textInJSX += " " + child
              });
              if (textInJSX.toLowerCase().indexOf(searchstring) > -1) {
                     page.fragments.push(textInJSX);
                 }
            }

            // searchstring in harmonica?
            if (page.harmonica) {
                var harmonicaFragments = loopOverHarmonica(page.harmonica, searchstring, []);
                if (harmonicaFragments.length > 0) page.fragments = page.fragments.concat(harmonicaFragments)
            }
            // searchstring in vis?
            if (page.template === 'visualisatie' && page.teksten) {
                var visFragments = checkVisTeksten(page.teksten, searchstring);
                if (visFragments.length > 0) page.fragments = page.fragments.concat(visFragments)
            }
            return (page.fragments.length > 0 || page.inTitle);
        })
    }

    const resultPages = results.map((page, i) => {
        const linkPath = (page.categorie === 'basis') ? '/' + page.naam : '/' + page.categorie + '/' + page.naam;

        const specialname  = (page.template === 'specialvoorpagina') ? page.naam : page.categorie;
        const iconClass = (access.indexOf(specialname) < 0) ? 'noauth' : 'auth';
        const icon = (specialname !== 'basis') ? <span className={iconClass} >{icons[specialname]}</span> : null;

        return (
            <Link
                to={{ pathname: linkPath }}
                state={{ fromSearchResults: true }}
                key={'result-' + page.categorie + '-'+ page.naam}
            >
                <li>
                    <Highlight search={searchstring}>{page.titel}</Highlight>
                    <ul className='fragmenten'>
                    {page.fragments.map((fragment,i) =>
                        <li key={'fragment' + page.id + i} >
                        <Highlight search={searchstring}>{fragment}</Highlight>
                        </li> )}
                    </ul>
                    {icon}
                </li>
            </Link>
            )
    })

    switch (resultPages.length) {
      case 0:
        resultText = 'Geen resultaten';
        break;
      case 1:
        resultText = '1 resultaat';
        break;
      default:
        resultText = resultPages.length + ' resultaten';
    }

    return (
        <div className='searchresults'>
            <p>{resultText}</p>
            <ul className='resultpages'> { resultPages } </ul>

        </div>
    );

}

export default SearchResults;
