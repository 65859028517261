import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenJeugdwet = () => (
	<div className="vis-geldstromen-participatiewet svg-container">
		<svg viewBox="0 0 595 650" preserveAspectRatio="xMidYMin meet" style={{"marginTop": 40}}>

			<g className="geldstromen">
				<path data-tooltip-content="react-tooltip" id="tt1" d="M404,142 L411,136 L433.507289,136 C439.387025,136 444.852364,129.013468 444.997061,119.701783 L445,119.32302 L445,52 L451,58 L457,52 L457,119.32302 C457,134.713907 447.077146,147.737146 433.90736,147.996072 L433.507289,148 L411,148 L404,142 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<path data-tooltip-content="react-tooltip" id="tt1" d="M493,52 L493,122.999983 C492.993438,131.367468 491.887652,139.696106 489.704424,147.798859 L489.208243,149.568501 L477.544524,190.059476 L471.803373,209.926363 L465.137082,232.942111 L457.627057,258.79519 L448.969248,288.48206 L439.656113,320.233576 L433.737436,340.27426 L428.125131,359.131858 L424.25001,372.032168 L421.053265,382.564169 L419.074644,389.011096 L418.661598,390.347823 C413.810181,406.036363 399.43241,416.783392 383.08423,417 L301,417 L294,411 L301,405 L382.588235,405 C393.874208,405 403.849849,397.627428 407.197519,386.801711 L409.126414,380.531535 L412.511001,369.395462 L416.058178,357.5986 L420.29609,343.388288 L423.918038,331.169381 L429.998627,310.542112 L435.026118,293.404038 L442.419359,268.096818 L448.902034,245.823607 L454.560318,226.329115 L461.232049,203.286193 L477.882801,145.530948 C479.855487,138.51502 480.900905,131.270853 480.993309,123.98854 L481,122.995279 L481,52 L487,58 L493,52 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<path data-tooltip-content="react-tooltip" id="tt2" d="M214.001762,379 L214.002204,163.737333 C213.807446,147.687293 226.491897,134.478358 242.522905,134.010274 L243.009707,134 L273.999991,134 L266.971967,140.028033 L273.937991,146 L243.088043,145.999489 C233.645121,146.122786 226.05901,153.816418 226.000778,163.335571 L225.999991,379 L219.999991,386 L214.001762,379 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<polygon data-tooltip-content="react-tooltip" id="tt3" id="Path" fill="#0088B1" fillRule="nonzero" points="459 422 459 422.068 465.963805 428.036195 458.963805 434.036195 458.963 434 294.004967 434 300.971967 428.028033 293.999967 422.052 294 422" />
				<path data-tooltip-content="react-tooltip" id="tt4" d="M468,51 L474,58 L474,119.443192 C473.995029,127.437485 472.884767,135.390954 470.702884,143.074528 L470.42457,144.033553 L400.178787,381.409072 C397.284275,391.166643 387.973555,397.833804 377.405828,398 L294.004967,398 L300.971967,392.028033 L293.999967,386.052 L293.999967,386 L377.008959,386 C382.444971,386 387.110159,382.780891 388.589827,378.26713 L388.673174,378.000136 L458.918678,140.625555 C460.869072,134.046724 461.903403,127.230935 461.993561,120.374644 L462,119.439463 L462,58 L468,51 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<path data-tooltip-content="react-tooltip" id="tt5" d="M55,52 L61,59 L60.9460584,59 L60.9460583,123.718906 C60.9470764,130.996311 61.8965067,138.240872 63.7695182,145.269846 L64.0306512,146.226989 L133.795873,396.388096 C135.255897,401.430034 139.814069,404.914736 145.058596,405.009292 L162.989,405.01 L157,411 L162.999,417.000572 L145.403304,417.000572 C134.857271,417.072768 125.531076,410.19135 122.419203,400.048961 L122.308136,399.67645 L52.5271327,149.459851 C50.2814926,141.41677 49.0979979,133.113987 49.0058343,124.763758 L49,123.719748 L49,59 L55,52 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<path data-tooltip-content="react-tooltip" id="tt6" d="M269,151 L276,157 L269,163 L269,162.949153 L248.441385,162.949153 C246.604159,162.949153 245.10312,164.382899 245.005092,166.188236 L245,166.376277 L244.999,385.714 L239,378 L232.999,385.714 L233,166.376277 C233,157.989079 239.743652,151.170226 248.126278,151.003138 L248.441385,151 L269,151 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
				<polygon data-tooltip-content="react-tooltip" id="tt7" fill="#0088B1" fillRule="nonzero" points="395 20 402 26 395 32 129.11 32 135.053824 26.0549451 129.001 20" />
				<path data-tooltip-content="react-tooltip" id="tt8" d="M67,52 L73,59 L78.999,52.001 L79,101.478673 C79,110.727979 84.6086372,117.811491 90.8893642,117.996298 L91.14094,118 L269,118 L269,118.031 L275.963805,124 L269,129.967 L269,130 L91.14094,130 C77.6281167,130 67.2101482,117.236259 67.0031402,101.942873 L67,101.478673 L67,52 Z" id="Path" fill="#0088B1" fillRule="nonzero" />
			</g>

			<Link to={{ pathname: '/sociaal-domein/wie-betaalt-participatiewet', hash: '#Burgers'}} >
				<g className="kader link">
					<rect x="405" y="04" width="106" height="40" rx="8"/>
					<text transform="translate(438 28)">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/sociaal-domein/wie-betaalt-participatiewet', hash: '#Werkgevers'}} >
				<g className="kader link">
					<rect x="15" y="04" width="106" height="40" rx="8"/>
					<text transform="translate(38 28)">Werkgevers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/sociaal-domein/wie-betaalt-participatiewet', hash: '#Rijk'}} >
				<g className="kader link">
					<rect x="280" y="120" width="120" height="45" rx="8"/>
					<text transform="translate(325 146)">Rijk</text>
				</g>
			</Link>

			<Link to={{ pathname: '/sociaal-domein/wie-betaalt-participatiewet', hash: '#Gemeenten'}} >
				<g className="kader link">
					<rect x="169" y="389" width="120" height="45" rx="8"/>
					<text transform="translate(200 416)">Gemeenten</text>
				</g>
			</Link>

			<Link to={{ pathname: '/sociaal-domein/wie-betaalt-participatiewet', hash: '#Aanbieders'}} >
				<g className="kader link">
					<rect x="470" y="389" width="120" height="45" rx="8"/>
					<text transform="translate(500 416)">Aanbieders</text>
				</g>
			</Link>

			<g className="tekstlabels wit">
				<path transform="translate(-110 -50)" d="M319.59,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S319.59,309.31,319.59,308.35z M329.89,303.22c-1.05,0.01-2.03,0.24-2.93,0.69v2.11 c0.99-0.48,1.83-0.73,2.51-0.73c0.91,0,1.36,0.34,1.36,1.03c0,0.29-0.09,0.6-0.26,0.94s-0.43,0.71-0.76,1.13s-0.76,0.88-1.26,1.4 s-1.09,1.1-1.75,1.76v1.63h6.95v-2.04h-2.36c-0.48,0-0.98,0.02-1.5,0.07l-0.01-0.04c0.59-0.55,1.1-1.04,1.54-1.48 s0.81-0.86,1.1-1.26s0.51-0.79,0.65-1.18s0.22-0.8,0.22-1.25c0-0.46-0.09-0.87-0.27-1.21s-0.43-0.64-0.74-0.87 s-0.68-0.41-1.11-0.53S330.38,303.22,329.89,303.22z"/>
				<path transform="translate(-108 -215)" d="M548.63,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S548.63,309.31,548.63,308.35z M555.79,311.14v2.04h6.87v-2.04h-2.06v-7.78h-2.07 l-2.88,1.02v2.1l2.47-0.74v5.4H555.79z"/>
				<path transform="translate(-108 -50)" d="M548.63,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S548.63,309.31,548.63,308.35z M555.79,311.14v2.04h6.87v-2.04h-2.06v-7.78h-2.07 l-2.88,1.02v2.1l2.47-0.74v5.4H555.79z"/>
				<path transform="translate(-57 -246)" d="M475.5,504.48c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11 c-0.63,0.63-1.34,1.18-2.11,1.63s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05 s-1.48-0.99-2.11-1.62c-0.63-0.63-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S475.5,505.43,475.5,504.48z M486.87,499.64h-2.48 l-2.43,6.22v1.56h4.11v1.89h2.37v-1.89h1.17v-1.99h-1.17v-1.46h-2.37v1.46h-0.36c-0.51,0-0.95,0.02-1.32,0.06l-0.01-0.04 c0.23-0.38,0.55-1.06,0.95-2.03L486.87,499.64z"/>
				<path transform="translate(-510 -200)" d="M584.12,445.78c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S584.12,446.73,584.12,445.78z M594.25,442.98h3.57v-2.04h-5.59l-0.77,5.48 c0.54-0.09,1.07-0.14,1.58-0.14c0.42,0,0.78,0.03,1.06,0.08s0.51,0.12,0.69,0.22s0.3,0.22,0.38,0.37s0.12,0.32,0.12,0.5 c0,0.22-0.05,0.41-0.15,0.57s-0.24,0.3-0.4,0.4s-0.36,0.18-0.58,0.23s-0.45,0.08-0.69,0.08c-0.4,0-0.77-0.04-1.12-0.11 s-0.71-0.19-1.09-0.35v2.11c0.3,0.12,0.64,0.23,1.03,0.3s0.85,0.12,1.39,0.12c0.65,0,1.23-0.08,1.75-0.25s0.95-0.4,1.31-0.7 s0.64-0.66,0.83-1.08s0.29-0.87,0.29-1.37c0-0.41-0.06-0.79-0.19-1.14s-0.34-0.66-0.62-0.93s-0.65-0.47-1.1-0.62 s-0.98-0.23-1.62-0.23c-0.05,0-0.15,0-0.28,0.01L594.25,442.98z"/>
				<path transform="translate(80 -152)" d="M319.59,579.47c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S319.59,580.43,319.59,579.47z M333.57,576.98c0-0.8-0.32-1.45-0.96-1.92 s-1.55-0.71-2.74-0.71c-0.82,0-1.66,0.14-2.53,0.41v2.06c0.34-0.13,0.69-0.24,1.06-0.31s0.75-0.11,1.13-0.11 c0.44,0,0.79,0.07,1.07,0.22s0.41,0.38,0.41,0.7c0,0.19-0.04,0.35-0.13,0.47s-0.21,0.23-0.36,0.32s-0.33,0.14-0.53,0.18 s-0.41,0.06-0.63,0.06h-0.95v1.95h1.27c0.22,0,0.42,0.02,0.61,0.05s0.35,0.09,0.49,0.17s0.25,0.19,0.33,0.32s0.12,0.29,0.12,0.5 c0,0.35-0.15,0.63-0.44,0.83s-0.74,0.3-1.33,0.3c-0.45,0-0.87-0.04-1.26-0.13s-0.73-0.19-1-0.32v2.09 c0.72,0.27,1.52,0.41,2.39,0.41c1.38,0,2.43-0.28,3.13-0.83s1.06-1.31,1.06-2.27c0-0.59-0.15-1.06-0.44-1.42s-0.67-0.61-1.13-0.74 v-0.05c0.47-0.22,0.81-0.52,1.03-0.91S333.57,577.47,333.57,576.98z"/>
				<path transform="translate(-242 -50)" d="M471.07,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S471.07,309.31,471.07,308.35z M481.8,307.04c-0.4,0-0.75,0.04-1.07,0.12 s-0.57,0.17-0.77,0.28l-0.04-0.04c0.11-0.74,0.38-1.28,0.8-1.62s0.95-0.51,1.59-0.51c0.37,0,0.75,0.04,1.15,0.12 s0.75,0.17,1.04,0.28v-2.02c-0.21-0.1-0.53-0.2-0.96-0.29s-0.88-0.14-1.34-0.14c-0.84,0-1.55,0.14-2.15,0.43s-1.09,0.68-1.47,1.18 s-0.66,1.09-0.84,1.76s-0.27,1.4-0.27,2.16c0,0.77,0.1,1.45,0.3,2.03s0.47,1.06,0.83,1.45s0.77,0.68,1.24,0.87 s0.99,0.29,1.54,0.29c0.56,0,1.07-0.08,1.52-0.24s0.83-0.39,1.15-0.68s0.57-0.65,0.74-1.06s0.26-0.87,0.26-1.37 c0-0.46-0.08-0.88-0.24-1.25s-0.38-0.69-0.67-0.94s-0.63-0.46-1.03-0.6S482.28,307.04,481.8,307.04z M481.39,311.34 c-0.21,0-0.41-0.05-0.59-0.14s-0.34-0.23-0.47-0.4s-0.24-0.38-0.32-0.64s-0.12-0.54-0.12-0.85c0.2-0.11,0.42-0.2,0.66-0.26 s0.47-0.09,0.7-0.09c0.44,0,0.77,0.11,1,0.32s0.34,0.48,0.34,0.81c0,0.41-0.11,0.72-0.33,0.93S481.76,311.34,481.39,311.34z"/>
				<path transform="translate(0 -394)" d="M257.06,420.12a7,7,0,0,1,.25-1.84,7.2,7.2,0,0,1,.7-1.66,6.77,6.77,0,0,1,1.08-1.41,7.33,7.33,0,0,1,1.41-1.08,6.91,6.91,0,0,1,5.34-.7,7.2,7.2,0,0,1,1.66.7,6.76,6.76,0,0,1,2.49,2.49,6.88,6.88,0,0,1,.7,1.65,6.93,6.93,0,0,1,0,3.69,6.94,6.94,0,0,1-1.78,3.06,6.49,6.49,0,0,1-1.41,1.09,6.79,6.79,0,0,1-1.66.7,6.9,6.9,0,0,1-3.68,0,7.39,7.39,0,0,1-1.66-.7,7,7,0,0,1-2.49-2.49,7.39,7.39,0,0,1-.7-1.66A7,7,0,0,1,257.06,420.12Zm7.53-1.87-2.43,5.09h1.72l2.54-5.35v-1.1h-4.64v1.36Z" />
			</g>

			<g className="tekstlabels zwart" transform="translate(0 -40)">
				<path transform="translate(0 0)" d="M3.66,514.75A6.62,6.62,0,0,1,3.89,513a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,521a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,514.75Zm4.47,1.74v1.28h4.3v-1.28H11.14v-4.86H9.84l-1.8.64v1.31l1.54-.46v3.37Z" fill="#1d1d1b"/>
				<path transform="translate(0 0)" d="M3.66,529.75A6.62,6.62,0,0,1,3.89,528a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,536a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,529.75Zm6.44-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.56-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.78,4.78,0,0,1-.47.71c-.21.26-.48.55-.79.87l-1.1,1.1v1h4.35v-1.28H11c-.29,0-.61,0-.93,0v0c.37-.34.69-.65,1-.92a6.58,6.58,0,0,0,.69-.79,3,3,0,0,0,.4-.74,2.21,2.21,0,0,0,.14-.78,1.63,1.63,0,0,0-.17-.75,1.53,1.53,0,0,0-.46-.55,2,2,0,0,0-.69-.33A3.43,3.43,0,0,0,10.1,526.54Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M3.66,544.75A6.62,6.62,0,0,1,3.89,543a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,551a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,544.75Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.45.45,0,0,1,.26.44.56.56,0,0,1-.08.3.9.9,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.45,2.45,0,0,1-.39,0h-.6v1.21H10a2.34,2.34,0,0,1,.38,0,.89.89,0,0,1,.3.11.51.51,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,4,4,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.69,1.69,0,0,0,.66-1.42,1.31,1.31,0,0,0-.28-.88,1.29,1.29,0,0,0-.7-.46v0a1.38,1.38,0,0,0,.64-.57A1.65,1.65,0,0,0,12.4,543.19Z" fill="#1d1d1b"/>
				<path  transform="translate(0 15)" d="M3.66,559.75A6.62,6.62,0,0,1,3.89,558a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,566a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,559.75Zm7.1-3H9.21l-1.52,3.89v1h2.58v1.18h1.48v-1.18h.73v-1.25h-.73v-.91H10.27v.91H10a6.78,6.78,0,0,0-.83,0v0a9.34,9.34,0,0,0,.6-1.27Z" fill="#1d1d1b"/>
				<path transform="translate(275 -60)" d="M3.66,574.75A6.62,6.62,0,0,1,3.89,573a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,581a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,574.75ZM10,573h2.24v-1.28H8.74l-.49,3.43a5.69,5.69,0,0,1,1-.09,4,4,0,0,1,.66.05,1.36,1.36,0,0,1,.43.14.59.59,0,0,1,.24.23.79.79,0,0,1,.07.32.65.65,0,0,1-.1.36.75.75,0,0,1-.25.25,1.18,1.18,0,0,1-.36.14,1.54,1.54,0,0,1-.43.05,3.23,3.23,0,0,1-.71-.07,4,4,0,0,1-.68-.21v1.31a3.44,3.44,0,0,0,.64.2,5.37,5.37,0,0,0,.87.07,3.33,3.33,0,0,0,1.09-.16,2.3,2.3,0,0,0,.82-.44,1.87,1.87,0,0,0,.52-.67,2,2,0,0,0,.19-.86,2.08,2.08,0,0,0-.12-.71,1.74,1.74,0,0,0-.39-.58,2.08,2.08,0,0,0-.69-.39,3.28,3.28,0,0,0-1-.14H9.86Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,514.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,518a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,514.75Zm6.71-.82a2.76,2.76,0,0,0-.67.07,2.2,2.2,0,0,0-.48.18l0,0a1.51,1.51,0,0,1,.5-1,1.53,1.53,0,0,1,1-.32,3.69,3.69,0,0,1,.72.08,4,4,0,0,1,.65.17v-1.26a3.18,3.18,0,0,0-.6-.18,3.84,3.84,0,0,0-.83-.09,3.1,3.1,0,0,0-1.35.27,2.58,2.58,0,0,0-.92.74,3,3,0,0,0-.52,1.1,5,5,0,0,0-.17,1.36,4,4,0,0,0,.18,1.26,2.68,2.68,0,0,0,.52.91,2.11,2.11,0,0,0,.78.54,2.49,2.49,0,0,0,1,.18,2.77,2.77,0,0,0,.94-.15,2,2,0,0,0,.73-.43,1.83,1.83,0,0,0,.46-.66,2.35,2.35,0,0,0,.16-.86,2,2,0,0,0-.15-.78,1.77,1.77,0,0,0-.42-.59,1.92,1.92,0,0,0-.64-.37A2.39,2.39,0,0,0,285.6,513.93Zm-.26,2.69a.74.74,0,0,1-.36-.09.78.78,0,0,1-.29-.25,1.16,1.16,0,0,1-.2-.4,1.78,1.78,0,0,1-.08-.53,2.05,2.05,0,0,1,.41-.16,1.72,1.72,0,0,1,.44-.06.92.92,0,0,1,.63.2.66.66,0,0,1,.21.5.79.79,0,0,1-.21.59A.76.76,0,0,1,285.34,516.62Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,529.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,533a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,529.75Zm7.05-1.75-2.27,4.77h1.61l2.39-5v-1h-4.35V528Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,544.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,548a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,544.75Zm6.51-3.21a3.52,3.52,0,0,0-1,.12,2.2,2.2,0,0,0-.72.35,1.63,1.63,0,0,0-.45.53,1.58,1.58,0,0,0-.15.69,1.32,1.32,0,0,0,.25.83,1.71,1.71,0,0,0,.67.54v0a2.1,2.1,0,0,0-.42.22,1.6,1.6,0,0,0-.33.31,1.53,1.53,0,0,0-.22.42,1.51,1.51,0,0,0-.08.53,1.65,1.65,0,0,0,.16.75,1.6,1.6,0,0,0,.47.57,2.23,2.23,0,0,0,.76.35,4.11,4.11,0,0,0,2.05,0,2.23,2.23,0,0,0,.76-.35,1.6,1.6,0,0,0,.47-.57,1.65,1.65,0,0,0,.16-.75,1.78,1.78,0,0,0-.08-.53,1.53,1.53,0,0,0-.22-.42,1.6,1.6,0,0,0-.33-.31,2.32,2.32,0,0,0-.41-.22v0a1.68,1.68,0,0,0,.66-.54,1.32,1.32,0,0,0,.25-.83,1.58,1.58,0,0,0-.15-.69,1.52,1.52,0,0,0-.45-.53,2.1,2.1,0,0,0-.72-.35A3.5,3.5,0,0,0,285.4,541.54Zm0,5.11a1,1,0,0,1-.64-.19.56.56,0,0,1-.21-.44.72.72,0,0,1,.24-.57,1.41,1.41,0,0,1,.61-.31,1.43,1.43,0,0,1,.6.31.72.72,0,0,1,.24.57.56.56,0,0,1-.21.44A1,1,0,0,1,285.4,546.65Zm0-2.52a1,1,0,0,1-.56-.31.74.74,0,0,1-.17-.43.53.53,0,0,1,.06-.26.6.6,0,0,1,.16-.19.7.7,0,0,1,.23-.11,1,1,0,0,1,.55,0,.7.7,0,0,1,.23.11.6.6,0,0,1,.16.19.53.53,0,0,1,.06.26.74.74,0,0,1-.17.43A1,1,0,0,1,285.4,544.13Z" fill="#1d1d1b"/>
			</g>

			<g className="tekst links regular">
				<text transform="translate(22 480)">
					<tspan x="0" y="0">Belastingen</tspan>
					<tspan x="0" y="15">Middelen gemeentefonds en</tspan>
					<tspan x="0" y="30">specifieke uitkeringen</tspan>
					<tspan x="0" y="45">Betaling voor geleverde ondersteuning</tspan>
					<tspan x="0" y="60">Bijstandsuitkering</tspan>
					<tspan x="278.25" y="0">Loonkostensubsidie</tspan>
					<tspan x="278.25" y="15">Belastingen</tspan>
					<tspan x="278.25" y="30">Loon</tspan>
					<tspan x="278.25" y="45">Belastingen</tspan>
				</text>
			</g>
		</svg>


		<ReactTooltip anchorId="tt1" place="left" float>
			<span>1. Belastingen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Middelen gemeentefonds en specifieke uitkeringen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt3" place="right" float>
			<span>3. Betaling voor geleverde ondersteuning</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt4" place="right" float>
			<span>4. Bijstandsuitkering</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt5" place="left" float>
			<span>5. Loonkostensubsidie</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt6" place="left" float>
			<span>6. Belastingen</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt7" place="left" float>
			<span>7. Loon</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt8" place="left" float>
			<span>8. Belastingen</span>
		</ReactTooltip>

	</div>
);

export default GeldstromenJeugdwet;


