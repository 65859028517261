import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const LeeftijdMantelzorgers = () => {

	const formatValueTooltip = val => val + '%';
	const formatValueY = val => val === 100.01 ? '19%' : '30%';

	const data = [
      { key: 'Mantelzorgers', value: 100.01 },
      { key: 'Vrijwilligers', value: 100.02 },
	];

	const dataParts = [
		{ key: 'Mantelzorgers', tooltip: 'Ouder dan 65: ', value: 19 },
    { key: 'Vrijwilligers', tooltip: 'Ouder dan 65: ', value: 30 },
	];


	return (
		<div className="vis-leeftijd-mantelzorgers">

      <BarChart
        data={data}
        margin={{ top: 10, right: 0, bottom: 10, left: 150 }}
        barHeight={45}
        domain={[0,100]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.3}
        ticks={0}
        parts={dataParts}
      />

      <div className="vis-credit">
        <p>Deel van alle mantelzorgers en vrijwilliger dat 65 jaar of ouder was in 2016</p>
        <p>Bron: Sociaal en Cultureel Planbureau (2017)</p>
      </div>

	</div>
	)
}

export default LeeftijdMantelzorgers;
