import {createStore} from 'redux';
import rootReducer from './reducers/';
import pagesBasis from './data/basis/pages';
import pagesHuisartsen from './data/huisartsen/pages';
import pagesOuderenzorg from './data/ouderenzorg/pages';
import pagesPubliekeGezondheidszorg from './data/publiekeGezondheidszorg/pages';
import pagesZorgverzekering from './data/zorgverzekering/pages';
import pagesMini from './data/mini/pages';
import pagesGidsen from './data/gidsen/pages.js';
import pagesGeneesmiddelenzorg from './data/geneesmiddelenzorg/pages';
import pagesGehandicaptenzorg from './data/gehandicaptenzorg/pages';
import pagesGgz from './data/ggz/pages';
import pagesSociaalDomein from './data/sociaal-domein/pages';

const pages = pagesBasis.concat(
  pagesSociaalDomein,
  pagesGgz,
  pagesGehandicaptenzorg,
  pagesGeneesmiddelenzorg,
  pagesHuisartsen,
  pagesOuderenzorg,
  pagesPubliekeGezondheidszorg,
  pagesZorgverzekering,
  pagesMini,
  pagesGidsen,
);

// create an object for the default data
let defaultState = {pages};
const store = createStore(rootReducer, defaultState);

export default store;
