export default {"tekst": "","items": [{
    "tekst": "Wetenschap en kwaliteit",
    "items": [{
        "tekst": "Nederlands Huisartsengenootschap (NHG)",
        "items": [{
            "tekst": "Het NHG vertaalt wetenschappelijk onderzoek in richtlijnen en standaarden voor de beroepsgroep."
        }]
    }, {
        "tekst": "Universitaire Medische Centra (UMC's)",
        "items": [{
            "tekst": "UMC’s doen onderzoek naar medisch-specialistische zorg en vertalen dit naar behandelingen."
        }]
    }, {
        "tekst": "Koninklijke Nederlandsche Maatschappij tot bevordering der Geneeskunst (KNMG)",
        "items": [{
            "tekst": "De KNMG is de federatie van medische beroepsorganisaties en maakt ethische en juridische richtlijnen."
        }]
    }]
}, {
    "tekst": "(Na)scholing",
    "items": [{
        "tekst": "Toelichting: Huisartsen moeten zich bijscholen op uiteenlopende thema’s. Hieronder noemen wij enkele aanbieders."
    }, {
        "tekst": "Nederlands Huisartsen Genootschap (NHG)",
        "items": [{
            "tekst": "Het NHG verzorgt onder meer opleidingen tot kaderarts en nascholing."
        }]
    }, {
        "tekst": "Landelijk implementatie netwerk kwaliteitsbeleid huisartsenzorg (LINKH)",
        "items": [{
            "tekst": "LINKH verbindt nascholingsorganisaties en huisartsen en organiseert kwaliteitsbevorderende activiteiten. "
        }]
    }, {
        "tekst": "Werkgroep Deskundigheidsbevordering Huisartsen (WDH)",
        "items": [{
            "tekst": "Een (regionale) WDH ontwikkelt gezamenlijk kwaliteitsbeleid in de regio. "
        }]
    }, {
        "tekst": "PROFclass ",
        "items": [{
            "tekst": "PROFclass is een post-specialistische opleiding en richt zich op praktijkmanagement, regievoering en ondernemerschap. "
        }]
    }, {
        "tekst": "Huisartsen Advies Groepen ",
        "items": [{
            "tekst": "Huisartsen Advies Groepen zoals voor Diabetes (DiHAG) en COPD & Astma (CAHAG). "
        }, {
            "tekst": "Expertgroepen bestaan uit (kader)artsen die elkaar nascholing geven op specifieke onderwerpen. "
        }]
    }, {
        "tekst": "LHV-academie ",
        "items": [{
            "tekst": "De opleidingstak van de Landelijke Huisartsen Vereniging met cursussen over praktijkvoering en management. "
        }]
    }, {
        "tekst": "Huisartsopleiding Nederland ",
        "items": [{
            "tekst": "Netwerkorganisatie van de acht opleidingsinstituten Huisartsgeneeskunde en de huisartsopleiders."
        }]
    }]
}, {
    "tekst": "(Her)registratie",
    "items": [{
        "tekst": "College Geneeskundige Specialismen (CGS)",
        "items": [{
            "tekst": "Het CGS stelt regels vast over opleidingen, opleiders en (her)registratie van specialisten. "
        }]
    }, {
        "tekst": "Centraal Informatiepunt Beroepen Gezondheidszorg (CIBG)",
        "items": [{
            "tekst": "Het CIBG is een uitvoeringsorganisatie van ministerie van VWS voor onder andere het BIG-register. "
        }, {
            "tekst": "Het openbare BIG-register geeft duidelijkheid over de bevoegdheid van een zorgverlener. "
        }]
    }, {
        "tekst": "College voor Huisartsen met Bijzondere Bekwaamheden (CHBB)",
        "items": [{
            "tekst": "Het CHBB registreert huisartsen met een bijzondere bekwaamheid (bijvoorbeeld verloskunde of oogheelkunde) en kaderhuisartsen (gespecialiseerd in bijvoorbeeld astma, COPD of diabetes)."
        }]
    }]
}, {
    "tekst": "Certificering van praktijk",
    "items": [{
        "tekst": "Toelichting: Deze lijst bevat organisaties die vaak de kwaliteit van managment- systemen testen, ook buiten de huisartsenzorg. Deze lijst is niet uitputtend."
    }, {
        "tekst": "NHG Praktijk Accreditering (NPA)",
        "items": [{
            "tekst": "NPA is een onafhankelijk kwaliteitsinstituut van de NHG en certificeert huisartsenpraktijken. "
        }]
    }, {
        "tekst": "HaZo24 ",
        "items": [{
            "tekst": "HaZo24 toetst en certificeert de kwaliteit van de huisartsen- praktijk, de ketenzorg en de huisartsenpost. "
        }]
    }, {
        "tekst": "DEKRA ",
        "items": [{
            "tekst": "DEKRA toetst en certificeert onder andere de kwaliteit van huisartsenpraktijken. "
        }]
    }, {
        "tekst": "TÜV ",
        "items": [{
            "tekst": "TÜV toetst en certificeert onder andere de kwaliteit van apotheekhoudende huisartsenpraktijken. "
        }]
    }, {
        "tekst": "Kiwa ",
        "items": [{
            "tekst": "Kiwa toetst en certificeert onder andere het managementsysteem van huisartsenpraktijken. "
        }]
    }, {
        "tekst": "Lloyd’s Register ",
        "items": [{
            "tekst": "Lloyd’s Register toetst en certificeert onder andere de kwaliteit van huisartsenpraktijken. "
        }]
    }, {
        "tekst": "Certificatiebureau CGD ",
        "items": [{
            "tekst": "CGD toetst en certificeert onder andere de kwaliteit van huisartsenpraktijken. "
        }]
    }, {
        "tekst": "DNV GL ",
        "items": [{
            "tekst": "DNV GL toetst en certificeert onder andere de kwaliteit van huisartsenpraktijken. "
        }]
    }, {
        "tekst": "Keurmerkinstituut ",
        "items": [{
            "tekst": "Keurmerkeninstituut toetst en certificeert de kwaliteit van huisartsenpraktijken."
        }]
    }]
}, {
    "tekst": "Toezicht",
    "items": [{
        "tekst": "Inspectie voor de Gezondheidszorg (IGZ)",
        "items": [{
            "tekst": "De IGZ houdt toezicht op de kwaliteit en veiligheid van de zorg. "
        }]
    }, {
        "tekst": "Autoriteit Consument en Markt (ACM)",
        "items": [{
            "tekst": "De ACM bestrijdt oneerlijke concurrentie in zorg en welzijn in het belang van patiënten en verzekerden. "
        }]
    }, {
        "tekst": "Registratiecommissie Geneeskundig Specialisten ",
        "items": [{
            "tekst": "De RGS voert de regels uit van het CGS, waaronder het toezicht op de regels voor de huisartsopleiding."
        }]
    }]
}]}