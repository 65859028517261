import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';

const GemeentenBegroting = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
  });

  const formatValueTooltip = val => localeFormatter.format(',.1f')(val) + ' miljard euro';
  const formatValueY = val => localeFormatter.format(',.1f')(val);
  
  const createTable = (data,className) => (
    <table className="tabel">
      <tbody>
        {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
      { key: 'Ondersteuning bij werk en inkomen', value: 11.586 },
      { key: 'Maatschappelijke ondersteuning', value: 6.433 },
      { key: 'Jeugdhulp', value: 4.992 },
      { key: 'Basisvoorzieningen', value: 3.204 },
  ];

  return (
    <div className="vis-gemeenten-begroting">
      <BarChart
        data={data}
        marginBeforeValue={0}
        margin={{ top: 30, right: 0, bottom: 10, left: 280 }}
        barHeight={30}
        domain={[0,12]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">26,2</p>
        <p>miljard euro in 2021</p>
      </div>

      <div className="vis-credit">
        <p>Begrote uitgaven van gemeenten aan ondersteuning en zorg uit het sociaal domein voor 2021 in miljard euro.</p>
        <p>Bron: CBS Statline, 2021.</p>
      </div>
    </div>
  )
}

export default GemeentenBegroting;
