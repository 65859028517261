import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN} from "../../../../../vocabulary";

const pages = [
    {
        categorie: "basis",
        template: "intro",
        naam: "inkoop",
        titel: "Hoe werkt inkoop van zorg?",
        kleur: COLOR_CYAAN,
        tekst:
            "Zorgverzekeraars kopen curatieve zorg in voor hun verzekerden. Hoe werkt dit precies? En wat gebeurt er als iemand niet verzekerd is of zijn premie niet betaalt?",
        links: [
            "inkoop-zorgverzekeraars",
            "inkoop-ziekenhuiszorg",
            "bijzondere-bekostiging",
        ]
    },
    {
        template: "visualisatie",
        naam: "inkoop-zorgverzekeraars",
        titel: "Hoe ziet het inkoopproces van zorgverzekeraars eruit?",
        kleur: COLOR_CYAAN,
        links: ['inkoop-ziekenhuiszorg']
    },
    {
        template: "harmonica",
        naam: "inkoop-ziekenhuiszorg",
        titel: "Hoe werkt inkoop van ziekenhuiszorg?",
        kleur: COLOR_CYAAN,
        links: ['inkoop-zorgverzekeraars']
    },
    {
        template: "harmonica",
        naam: "bijzondere-bekostiging",
        titel: "Voor welke groepen bestaan bijzondere regels voor vergoeding van zorg?",
        kleur: COLOR_CYAAN,
        links: ['volwassene-zorguitgaven']
    },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
    pagina.categorie = 'basis'

    if (pagina.template === "harmonica") {
        const harmonicaNaam = pagina.naam.replace(/-/g, "_");
        pagina.harmonica = harmonicas[harmonicaNaam];
    }
    if (pagina.template === "visualisatie") {
        const visualisatieNaam = pagina.naam.replace(/-/g, "_");
        pagina.vis = vissen[visualisatieNaam];
    }
    return pagina;
});


export default pages
