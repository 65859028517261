import React from 'react';

import BarChart from '../../../components/BarChart/BarChart';

const ToezichtOuderenzorg = () => {
	const data = [
		{
			"key": "2015",
			"value": 17,
		},
		{
			"key": "2040",
			"value": 43,
		},
	];

	const formatValueTooltip = val => `${val} miljard euro`
	const formatValueY = val => `${val} miljard euro`

	return (
		<div className="vis mini">
			<div className="vis-geld-ouderenzorg">
				<BarChart
					data={data}
					margin={{ top: 30, right: 0, bottom: 10, left: 220 }}
					barHeight={30}
					domain={[0,50]}
					color={ {fill: '#333', stroke: '#fff'} }
					formatValueTooltip={formatValueTooltip}
					formatValueY={formatValueY}
					padding={0.2}
					ticks={0}
					marginBeforeValue={-165}
					animation={true}
				/>

				<div className="vis-credit">
					<p>De kosten van de ouderenzorg stijgen bij ongewijzigd beleid met 157% in de periode '15-'40.</p>
					<p>Bron: VTV 2018</p>
				</div>
			</div>
		</div>
	);
}

export default ToezichtOuderenzorg;
