import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN, COLOR_GREEN} from "../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "gids-palliatieve-zorg",
    titel: "Geldstromen in de palliatieve zorg",
    kleur: COLOR_CYAAN,
    tekst:
      "Voor ongeveer 70 procent van de volwassenen die overlijden, komt het sterven niet onverwacht. Voor deze mensen en hun naasten is er palliatieve zorg. Wat is palliatieve zorg? Wie koopt welke zorgvorm in en hoe lopen de geldstromen? Al deze informatie en meer vind je hieronder.",
    links:
      [
        'wat-is',
        'bekostiging'
      ]
  },
  {
    template: "intro",
    naam: "wat-is",
    titel: "Wat is palliatieve zorg en wie zijn de hoofdspelers?",
    kleur: COLOR_GREEN,
    tekst:
      "Palliatieve zorg begint zodra duidelijk is dat iemand niet meer beter wordt. Wat palliatieve zorg precies inhoudt en wie de belangrijkste spelers zijn lees je in dit hoofdstuk.",
    links:
      [
        'definitie',
        'hoofdrolspelers'
      ]
  },
  {
    template: "harmonica",
    naam: "definitie",
    titel: "Wat is palliatieve zorg?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "hoofdrolspelers",
    titel: "Wie zijn de hoofdrolspelers in palliatieve zorg?",
    kleur: COLOR_GREEN,
    links: [
        'geldstromen'
    ]
  },
  {
    template: "intro",
    naam: "bekostiging",
    titel: "Wie bekostigt palliatieve zorg en hoe lopen de geldstromen?",
    kleur: COLOR_CYAAN,
    tekst:
        "Welke weg doorloopt geld voordat het van de burger bij de zorgverlener terechtkomt? En wie betaalt voor welke vorm van palliatieve zorg?",
    links: [
      'zorginkopers',
      'subsidie',
      'wanneer-betalen',
      'wie-betaalt',
      'geldstromen'
    ]
  },
  {
    template: "harmonica",
    naam: "zorginkopers",
    titel: "Welke zorginkoper koopt welke palliatieve zorg in bij welke zorgaanbieder?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "harmonica",
    naam: "subsidie",
    titel: "Welke organisaties krijgen subsidie van het ministerie van VWS voor palliatieve zorg?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "harmonica",
    naam: "wanneer-betalen",
    titel: "Wanneer betalen mensen voor palliatieve zorg?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "harmonica",
    naam: "wie-betaalt",
    titel: "Wie betaalt wat voor palliatieve zorg?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "visualisatie",
    naam: "geldstromen",
    titel: "Hoe lopen de geldstromen in de palliatieve zorg?",
    kleur: COLOR_CYAAN,
    links: [
        'wie-betaalt'
    ]
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
