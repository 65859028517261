import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_GREEN} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "cijfers",
    titel: "Wat zijn relevante cijfers voor de zorg?",
    kleur: COLOR_GREEN,
    tekst: "",
    links:
      [
        "beroepsbevolking",
        "rijksuitgaven",
        "aantal-80-jaar",
        "chronische-ziektes",
        "gebruik-ggz"
      ]
  },
  {
    template: "visualisatie",
    naam: "beroepsbevolking",
    titel: "Welk deel van de beroepsbevolking werkt in de zorg?",
    kleur: COLOR_GREEN,
    links: ['arbeidsmarkt']
  },
  {
    template: "visualisatie",
    naam: "rijksuitgaven",
    titel: "Welk deel van de rijksuitgaven gaat naar zorg?",
    kleur: COLOR_GREEN,
    links: ['kosten']
  },
  {
    template: "visualisatie",
    naam: "aantal-80-jaar",
    titel: "Hoeveel mensen in Nederland zijn 80 jaar of ouder?",
    kleur: COLOR_GREEN,
    links: ['chronische-ziektes']
  },
  {
    template: "visualisatie",
    naam: "chronische-ziektes",
    titel: "Welk deel van de mensen heeft twee of meer chronische ziekten?",
    kleur: COLOR_GREEN,
    links: ['gebruik-ggz']
  },
  {
    template: "visualisatie",
    naam: "gebruik-ggz",
    titel: "Hoeveel duizend volwassenen maken gebruik van geestelijke gezondheidszorg?",
    kleur: COLOR_GREEN,
    links: ['aantal-80-jaar']
  }
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
