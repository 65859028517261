import React from 'react';

const Kraam = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<g fill="#FFF" fill-rule="nonzero"><path d="M83 44.5v11H72v-11h11m2-2H70v15h15v-15ZM28 44.5v11H17v-11h11m2-2H15v15h15v-15ZM63 37v26H37V37h26m2-2H35v30h30V35Z"/></g>
	</svg>
);

export default Kraam;

