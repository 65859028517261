import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';
import { formatLocale } from "d3";

const UitgavenGeneesmiddelen = () => {

	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: ".",
		"grouping": [3],
		"comma": true,
	});

	const margin = {top: 90, right: 0, bottom: 90, left: 0};
	const formatY = value => localeFormatter.format(",.1f")(value);
	const formatValueToolTip = d => (<span>{d.category}<br/>{localeFormatter.format(",.1f")(d.data.value)} miljard euro</span>);

	const data = [
		{
"year": "2012",
"extramuraal": 4.608,
"intramuraal": 1.166
},
{
"year": "2013",
"extramuraal": 4.301,
"intramuraal": 1.532
},
{
"year": "2014",
"extramuraal": 4.328,
"intramuraal": 1.663
},
{
"year": "2015",
"extramuraal": 4.463,
"intramuraal": 1.792
},
{
"year": "2016",
"extramuraal": 4.549,
"intramuraal": 1.892
},
{
"year": "2017",
"extramuraal": 4.576,
"intramuraal": 2.088
}
	];

	return (
		<div className="vis-euros-geneesmiddelen">

		<ColumnChart
			margin={margin}
			colorBars={["#333","#ddd"]}
			colorAxis="#333"
			labelY="x 1000"
			height={450}
			domain={[0, 6.7]}
			columnPadding={0.5}
			formatY={formatY}
			formatValueToolTip={formatValueToolTip}
			data={data}
			stacked={true}
		/>

		<div className="vis-credit">
			<p>Uitgaven aan geneesmiddelenzorg (x miljard euro) in 2017.</p>
			<p>Bron: GIPdatabank, 2019.</p>
		</div>

	</div>
	);
}
export default UitgavenGeneesmiddelen;
