export default {"tekst": "Welke organisaties vertegenwoordigen landelijk de mensen in de huisartsenzorg?","items": [{
    "tekst": "Huisartsen",
    "items": [{
        "tekst": "Landelijke Huisartsen Vereniging (LHV)",
        "items": [{
            "tekst": "De LHV behartigt de belangen van huisartsen. "
        }]
    }, {
        "tekst": "Vereniging Praktijkhoudende Huisartsen (VPH)",
        "items": [{
            "tekst": "De VPH behartigt de belangen van praktijkhoudende huisartsen. "
        }]
    }, {
        "tekst": "Landelijke Vereniging van Artsen in Dienstverband (LAD)",
        "items": [{
            "tekst": "De LAD behartigt de belangen van (huis)artsen in dienstverband. "
        }]
    }, {
        "tekst": "Landelijke Organisatie van Aspirant Huisartsen (LOVAH)",
        "items": [{
            "tekst": "De LOVAH behartigt de belangen van huisartsen in opleiding. "
        }]
    }, {
        "tekst": "Landelijke Huisartsen Opleiders Vereniging (LHOV)",
        "items": [{
            "tekst": "De LHOV is de beroeps- en belangenvereniging voor opleiders Huisartsgeneeskunde. "
        }]
    }, {
        "tekst": "InEen ",
        "items": [{
            "tekst": "InEen is de belangenbehartiger van de georganiseerde eerstelijnszorg (eerstelijns diagnostische centra, gezondheidscentra, huisartsenposten, Regionale Ondersteuningsstructuur (ROS’en) en zorggroepen)."
        }]
    }]
}, {
    "tekst": "Medewerkers",
    "items": [{
        "tekst": "Nederlandse Vereniging van Doktersassistenten (NVDA)",
        "items": [{
            "tekst": "De NVDA behartigt de belangen van doktersassistenten."
        }]
    }, {
        "tekst": "Nederlandse Associatie Physician Assistants (NAPA)",
        "items": [{
            "tekst": "De NAPA is de beroepsvereniging voor physician assistants."
        }]
    }, {
        "tekst": "Landelijke Vereniging POH-GGZ (LV POH-GGZ)",
        "items": [{
            "tekst": "De LV POH-GGZ behartigt de belangen van praktijkondersteuners ggz."
        }]
    }, {
        "tekst": "Verpleegkundigen & Verzorgenden Nederland (V&VN)",
        "items": [{
            "tekst": "De V&VN is de beroepsvereniging voor verpleegkundigen, verzorgenden en verpleegkundig specialisten."
        }]
    }, {
        "tekst": "Nederlandse Vereniging van Praktijkondersteuners (NVvPO)",
        "items": [{
            "tekst": "De NVvPO is de belangenbehartigingsorganisatie voor alle praktijkondersteuners."
        }]
    }]
}]}