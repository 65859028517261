import React from "react";
import LineChart from "../../../../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const AantalWerkenden = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatY = (val) => localeFormatter.format(',.0f')(val);
	const formatValueTooltip = value => formatY(value) + " duizend mensen";

	const data = [
		{
			name: "aantal werkenden x1000",
			values: [
				{ x: 2017, y: 1213 },
				{ x: 2018, y: 1254 },
				{ x: 2019, y: 1304 },
				{ x: 2020, y: 1332 },
				{ x: 2021, y: 1450 },
			]
		}
	];

	return (
		<div className="vis-aantal-werkenden">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[1000, 1600]}
				data={data}
				height={600}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal werkenden in de zorg maal duizend.</p>
				<p>Bron: CBS Statline, 2022.</p>
			</div>
		</div>
	);
};

export default AantalWerkenden;
