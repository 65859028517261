import React from 'react';
import BarChart from '../../../../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';

const Rijksbegroting = () => {
    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
    });

    const formatValueTooltip = val => localeFormatter.format(',.1f')(val) + ' miljard euro';
    const formatValueY = val => localeFormatter.format(',.1f')(val);

    const createTable = (data, className) => (
        <table className="tabel">
            <tbody>
            {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
            </tbody>
        </table>
    );

    const data = [
        { key: 'Zorgverzekeringswet', value: 64.2 },
        { key: 'Wet langdurige zorg', value: 36.8 },
        { key: 'Wet publieke gezondheid*', value: 1.7 },
        { key: 'Wet maatschappelijke ondersteuning**', value: 1.6 },
        { key: 'Overig zorgbreedbeleid**', value: 0.8 },
    ];

    return (
        <div className="vis-rijksbegroting">
            <BarChart
                data={data}
                margin={{ top: 30, right: 0, bottom: 10, left: 290 }}
                barHeight={30}
                domain={[0,60]}
                color={ {fill: '#333', stroke: '#fff'} }
                formatValueTooltip={formatValueTooltip}
                formatValueY={formatValueY}
                padding={0.2}
                ticks={0}
            />

            {createTable(data)}

            <div className="vis-total">
                <p>Totaal</p>
                <p className="groot">105,1</p>
                <p>miljard euro in 2024</p>
            </div>

            <div className="vis-credit">
                <p>Begrote uitgaven aan zorg voor 2024 in miljard euro.</p>
                <p>* Hiervan zijn 0,4 miljard coronagerelateerde uitgaven.</p>
                <p>** Wmo Beschermd wonen.</p>
            </div>
        </div>
    )
}

export default Rijksbegroting;
