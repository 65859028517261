import React from "react";
import { Link } from "react-router-dom";

import "./Tiles.scss";

import Mens from "../Icons/Mens";
import Hamer from "../Icons/Hamer";
import Kraam from "../Icons/Kraam";
import Euro from "../Icons/Euro";
import Ster from "../Icons/Ster";
import Appel from "../Icons/Appel";

const icons = [<Appel />, <Mens />, <Hamer />, <Euro />, <Kraam />, <Ster />];

const Tiles = ({ page, pages }) => {
	return (
		<div className="page-tiles">
			{
				page.links.map((link, i) => {
					const titel = pages.filter(pagina => pagina.naam === link)[0].titel;
					return (
						<Link to={`/${link}`} key={i} className={"tile-link tile-link-" + (i + 1)}>
							<div className={"tile tile-" + (i + 1)}>
								<div className="tile-content">
									{icons[i]}
									<h2>{titel}</h2>
								</div>
							</div>
						</Link>
					);
				})
			}
		</div>
	)
};

export default Tiles;
