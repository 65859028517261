import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const WieGebruiktGeneesmiddelen = () => {
	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: "."
	});

	const formatValueTooltip = val =>
		localeFormatter.format(",.1f")(val) + " procent";
	const formatValueY = val => Math.round(val) + "%";

	const createTable = (data,className) => (
		<table className="tabel">
			<tbody>
				{data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
			</tbody>
		</table>
	);

	const data = [
		{ key: "0 tot 5 jaar", value: 52.2 },
		{ key: "5 tot 15 jaar", value: 43.0 },
		{ key: "15 tot 25 jaar", value: 56.0 },
		{ key: "25 tot 45 jaar", value: 57.9 },
		{ key: "45 tot 65 jaar", value: 72.7 },
		{ key: "65 tot 75 jaar", value: 90.5 },
		{ key: "75 jaar of ouder", value: 100.0 },
	];

	return (
		<div className="vis-gebruik-geneesmiddelen">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 155 }}
				barHeight={30}
				domain={[0, 100]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				marginBeforeValue={-50}
				padding={0.2}
				ticks={0}
			/>

			{createTable(data)}

			<div className="vis-credit">
				<p>Deel van de bevolking in een leeftijdsgroep dat in 2017 voorgeschreven geneesmiddelen verstrekt kreeg door een openbare apotheek.</p>
				<p>Bron: CBS, GIPDatabank, 2019.</p>
			</div>
		</div>
	);
};

export default WieGebruiktGeneesmiddelen;
