export default {"tekst": "Hoe werkt de Wet maatschappelijke ondersteuning en wat is relevant voor de huisarts?","items": [{
    "tekst": "Uitleg wet",
    "items": [{
        "tekst": "Deze wet regelt de gemeentelijke verantwoordelijkheid voor maatschappelijke ondersteuning."
    }, {
        "tekst": "Deze wet regelt dagbesteding, begeleiding en kortdurend verblijf in een zorginstelling."
    }, {
        "tekst": "Deze wet regelt beschermd wonen voor mensen met psychosociale problemen."
    }, {
        "tekst": "Deze wet regelt opvang voor mensen die dak- en thuisloos zijn."
    }, {
        "tekst": "Deze wet geeft geen recht op zorg, maar is bedoeld als aanvulling op wat de burger niet zelf kan."
    }]
}, {
    "tekst": "Huisarts specifiek",
    "items": [{
        "tekst": "De huisarts kan patiënten verwijzen naar de gemeente voor hulp en ondersteuning."
    }, {
        "tekst": "Gemeenten willen huisartsen betrekken bij de zorg die wijkverpleegkundigen en wijkteams bieden."
    }, {
        "tekst": "De huisarts mag alleen met toestemming van de patiënt gegevens verstrekken aan de gemeente."
    }, {
        "tekst": "De huisarts mag alleen gegevens verstrekken op basis van een gerichte schriftelijke vraag van de gemeente."
    }]
}]}