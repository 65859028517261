import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';


const Mantelzorgers = () => {

	const data = [
			{
	        "label": "Geen informele hulp",
	        "value": 8852685,
	    },
	    {
	        "label": "Alleen vrijwilligerswerk",
	        "value": 553293,
	    },
	    {
	        "label": "Mantelzorg en vrijwilligerswerk",
	        "value": 691616,
	    },
			{
	        "label": "Alleen mantelzorg",
	        "value": 3873050,
	    },
	];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0}>17 miljoen</tspan>
      <tspan x={0} dy={15}>Nederlanders</tspan>
    </text>);

	const formatTooltip = item => item.label + ': ' + localeFormatter.format(',.1f')(item.value/1000000) + ' miljoen';

	return (
		<div className="vis-mantelzorgers">

			<Donut
				data={data}
				height={300}
				fontFamily="din-regular"
				fontSize="13px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				formatTooltip={formatTooltip}
				unit="miljoen"
				animation={true}
			/>

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">5,1 miljoen</p>
        <p>mantelzorgers en vrijwiligers</p>
      </div>

			<div className="vis-credit">
				<p>Bevolking naar leeftijd op 1 januari 2017</p>
				<p>Bron: CBS Statline</p>
			</div>

		</div>
	);
}

export default Mantelzorgers;


