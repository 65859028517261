import React from "react";
import BarChart from "../../../../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const Zorgeuros = () => {
    const localeFormatter = formatLocale({
        decimal: ",",
        thousands: "."
    });

    const formatValueTooltip = val =>
        localeFormatter.format(",.1f")(val) + " miljard euro";
    const formatValueY = val => localeFormatter.format(",.1f")(val);

    const createTable = (data,className) => (
        <table className="tabel">
            <tbody>
            {data.map(row => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
            </tbody>
        </table>);

    const data = [
        { key: "Ziekenhuiszorg", value:  33 },
        { key: "Verpleging en verzorging", value:  23.8},
        { key: "Gehandicaptenzorg", value: 13.5},
        { key: "Geestelijke gezondheidszorg", value: 8.3},
        { key: "Preventieve diensten", value: 4.2},
        { key: "Geneesmiddelen", value:  6.1},
        { key: "Huisartsenzorg", value: 5.1},
        { key: "Therapeutische middelen ", value:  3.6},
        { key: "Mondzorg", value:  3},
        { key: "Paramedische zorg", value: 2.5},
        { key: "Overige zorg", value:  2.1},
        { key: "Ondersteunende diensten", value: 2.4},
    ];

    return (
        <div className="vis-euros-cbs">
            <BarChart
                data={data}
                margin={{ top: 30, right: 0, bottom: 10, left: 240 }}
                barHeight={30}
                domain={[0, 27]}
                color={{ fill: "#333", stroke: "#fff" }}
                formatValueTooltip={formatValueTooltip}
                formatValueY={formatValueY}
                padding={0.2}
                ticks={0}
            />

            {createTable(data)}

            <div className="vis-total">
                <p>Totaal</p>
                <p className="groot">107,6</p>
                <p>miljard euro in 2022</p>
            </div>

            <div className="vis-credit">
                <p>Uitgaven aan geneeskundige en langdurige zorg in miljard euro in 2022.</p>
                <p>Bron: CBS Statline, 2023.</p>
            </div>
        </div>
    );
};

export default Zorgeuros;
