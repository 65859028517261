import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";

const GeldstromenWMO = () => (
	<div className="vis-geldstromen-wmo svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 660 760">

			<g className="geldstromen" transform="translate(0,45)">
				<path data-tooltip-content="react-tooltip" id="tt1" d="M252,84H228c-14.34,0-27.49,11.07-29.93,25.21L162.2,316.85a253.46,253.46,0,0,0-3.2,35.71V369h0l9,9,9-9h0V352.56a235.79,235.79,0,0,1,2.93-32.65L215.8,112.27c.95-5.47,6.64-10.27,12.2-10.27h24l-9-9Z" transform="translate(-3 -66)"/>
				<path data-tooltip-content="react-tooltip" id="tt2" d="M129,402H81a27,27,0,0,0-27,27v78h0l9,9,9-9h0V429a9,9,0,0,1,9-9h48l-9-9Z" transform="translate(-3 -66)"/>
				<path data-tooltip-content="react-tooltip" id="tt3" d="M330,249l-9-9h0V513a9,9,0,0,1-9,9H162l-9,9,9,9H312a27,27,0,0,0,27-27V240h0Z" transform="translate(-3 -66)"/>
				<polygon data-tooltip-content="react-tooltip" id="tt4" points="495 504 495 504 150 504 159 513 150 522 150 522 495 522 495 522 504 513 495 504"/>
				<path data-tooltip-content="react-tooltip" id="tt5" d="M444,453h18v84a27,27,0,0,1-27,27H153l9-9-9-9H435a9,9,0,0,0,9-9Zm9-9-9,9h18Z" transform="translate(-3 -66)"/>
				<path data-tooltip-content="react-tooltip" id="tt6" d="M579,402H531l9,9-9,9h48a9,9,0,0,1,9,9v78h0l9,9,9-9h0V429A27,27,0,0,0,579,402Z" transform="translate(-3 -66)"/>
			</g>

			<Link to={{ pathname: '/wie-betaalt-wmo', hash: '#Burgers'}} >
				<g className="kader link">
					<rect x="256.5" y="46.5" width="141" height="51" rx="10.5" ry="10.5"/>
					<text className="tekst centreer" x="330" y="78">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/wie-betaalt-wmo', hash: '#Rijk'}} >
				<g className="kader link">
					<rect x="256.5" y="160.5" width="141" height="51" rx="10.5" ry="10.5"/>
					<text className="tekst centreer" x="330" y="192">Rijk</text>
				</g>
			</Link>

			<Link to={{ pathname: '/wie-betaalt-wmo', hash: '#CentraalAdministratieKantoor'}} >
				<g className="kader link">
					<rect x="133.5" y="364.5" width="141" height="51" rx="10.5" ry="10.5"/>
					<text className="tekst centreer" x="202" y="398">CAK</text>
				</g>
			</Link>

			<Link to={{ pathname: '/wie-betaalt-wmo', hash: '#SocialeVerzekeringsbank'}} >
				<g className="kader link">
					<rect x="379.5" y="364.5" width="141" height="51" rx="10.5" ry="10.5"/>
					<text className="tekst centreer" x="453" y="398">SVB</text>
				</g>
			</Link>

			<Link to={{ pathname: '/wie-betaalt-wmo', hash: '#Gemeenten'}} >
				<g className="kader link">
					<rect x="1.5" y="502.5" width="141" height="63" rx="10.5" ry="10.5"/>
					<text className="tekst centreer" x="72" y="541">Gemeenten</text>
				</g>
			</Link>

			<g className="kader">
				<rect x="511.5" y="502.5" width="141" height="63" rx="10.5" ry="10.5"/>
				<text className="tekst centreer" x="585" y="541">Zorgaanbieders</text>
			</g>

			<g className="tekst links regular">
				<text x="45" y="618">
					<tspan x="45">Eigen bijdrage voor maatwerk-</tspan>
					<tspan x="45" dy="16">voorziening</tspan>
				</text>
				<text x="45" y="656">
					<tspan x="45">Uitbetaling eigen bijdrage voor</tspan>
					<tspan x="45" dy="16">maatwerkvoorziening</tspan>
				</text>
				<text x="366" y="618">
					<tspan x="366">Middelen maatschappelijke</tspan>
					<tspan x="366" dy="16">ondersteuning</tspan>
				</text>
				<text x="366" y="656">Betaling geleverde ondersteuning</text>
				<text x="366" y="675">Middelen persoonsgebonden budget</text>
				<text x="366" y="694">Betaling geleverde ondersteuning</text>
			</g>

			<g className="tekstlabels wit" transform="translate(-3,-20)">
				<path d="M443.1,499.5a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,443.1,499.5Zm10.14-2.8h3.57v-2h-5.59l-.77,5.48A9.22,9.22,0,0,1,452,500a6.19,6.19,0,0,1,1.06.08,2.17,2.17,0,0,1,.69.22.92.92,0,0,1,.38.37,1.15,1.15,0,0,1,0,1.08,1.25,1.25,0,0,1-.4.4,1.88,1.88,0,0,1-.58.23,3,3,0,0,1-.69.08,5.44,5.44,0,0,1-1.12-.11,6.14,6.14,0,0,1-1.09-.35v2.11a5.41,5.41,0,0,0,1,.3,7.11,7.11,0,0,0,1.39.12,5.62,5.62,0,0,0,1.75-.25,3.9,3.9,0,0,0,1.31-.7,3.11,3.11,0,0,0,.83-1.08,3.18,3.18,0,0,0,.29-1.37,3.33,3.33,0,0,0-.19-1.14,2.31,2.31,0,0,0-.62-.93,3.06,3.06,0,0,0-1.1-.62,5,5,0,0,0-1.62-.23H453Z"/>
				<path d="M587.19,459a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,587.19,459Zm10.73-1.31a4.41,4.41,0,0,0-1.07.12,3.37,3.37,0,0,0-.77.28l0,0a2.22,2.22,0,0,1,2.39-2.13,6,6,0,0,1,1.15.12,6.85,6.85,0,0,1,1,.28v-2a4.62,4.62,0,0,0-1-.29,6.25,6.25,0,0,0-1.34-.14,4.9,4.9,0,0,0-2.15.43,4,4,0,0,0-1.47,1.18,5,5,0,0,0-.84,1.76,8.39,8.39,0,0,0-.27,2.16,6.23,6.23,0,0,0,.3,2,4.11,4.11,0,0,0,.83,1.45,3.35,3.35,0,0,0,1.24.87,4.08,4.08,0,0,0,1.54.29,4.5,4.5,0,0,0,1.52-.24,3.36,3.36,0,0,0,1.15-.68,3,3,0,0,0,.74-1.06,3.54,3.54,0,0,0,.26-1.37,3.12,3.12,0,0,0-.24-1.25,2.69,2.69,0,0,0-.67-.94,3,3,0,0,0-1-.6A3.92,3.92,0,0,0,597.92,457.66Zm-.41,4.3a1.25,1.25,0,0,1-.59-.14,1.42,1.42,0,0,1-.47-.4,2,2,0,0,1-.32-.64,2.88,2.88,0,0,1-.12-.85,3,3,0,0,1,.66-.26,2.72,2.72,0,0,1,.7-.09,1.41,1.41,0,0,1,1,.32,1.06,1.06,0,0,1,.34.81,1.25,1.25,0,0,1-.33.93A1.2,1.2,0,0,1,597.51,462Z"/>
				<path d="M52.71,459a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53A10.42,10.42,0,0,1,65.89,469a10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,52.71,459ZM63,453.84a6.52,6.52,0,0,0-2.93.69v2.11a6,6,0,0,1,2.51-.73q1.36,0,1.36,1a2.07,2.07,0,0,1-.26.94,6.83,6.83,0,0,1-.76,1.13q-.5.63-1.26,1.4t-1.75,1.76v1.63h6.95v-2H64.52q-.71,0-1.5.07v0q.88-.82,1.54-1.48a10.69,10.69,0,0,0,1.1-1.26,5,5,0,0,0,.65-1.18,3.54,3.54,0,0,0,.22-1.25,2.61,2.61,0,0,0-.27-1.21,2.53,2.53,0,0,0-.74-.87,3.27,3.27,0,0,0-1.11-.53A5.17,5.17,0,0,0,63,453.84Z"/>
				<path d="M162.48,308.35a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,162.48,308.35Zm7.16,2.79v2h6.87v-2h-2.06v-7.78h-2.07l-2.88,1v2.1l2.47-.74v5.4Z"/>
				<path transform="translate(-1,0)" d="M320.27,578.31a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1A10.25,10.25,0,0,1,338,571a10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,320.27,578.31Zm11.37-4.84h-2.48l-2.43,6.22v1.56h4.11v1.89h2.37v-1.89h1.17v-2h-1.17V577.8h-2.37v1.46h-.36a13,13,0,0,0-1.32.06v0a16.07,16.07,0,0,0,.95-2Z"/>
				<path transform="translate(-1,0)" d="M320.27,308.35a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1A10.25,10.25,0,0,1,338,301a10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,320.27,308.35Zm14-2.5a2.28,2.28,0,0,0-1-1.92,4.5,4.5,0,0,0-2.74-.71,8.4,8.4,0,0,0-2.53.41v2.06a6.17,6.17,0,0,1,1.06-.31,5.75,5.75,0,0,1,1.13-.11,2.23,2.23,0,0,1,1.07.22.74.74,0,0,1,.41.7.81.81,0,0,1-.13.47,1,1,0,0,1-.36.32,1.86,1.86,0,0,1-.53.18,3.25,3.25,0,0,1-.63.06h-.95v1.95h1.27a3.71,3.71,0,0,1,.61,0,1.44,1.44,0,0,1,.49.17.91.91,0,0,1,.33.32,1,1,0,0,1,.12.5,1,1,0,0,1-.44.83,2.35,2.35,0,0,1-1.33.3,5.91,5.91,0,0,1-1.26-.13,5,5,0,0,1-1-.32V313a6.68,6.68,0,0,0,2.39.41,5,5,0,0,0,3.13-.83,2.72,2.72,0,0,0,1.06-2.27,2.17,2.17,0,0,0-.44-1.42,2.21,2.21,0,0,0-1.13-.74v0a2.2,2.2,0,0,0,1-.91A2.66,2.66,0,0,0,334.25,305.86Z"/>
			</g>

			<g className="tekstlabels zwart">
				<path d="M24.7,612.57a6.73,6.73,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.74,6.74,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.14,7.14,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,612.57Zm4.77,1.86v1.36h4.58v-1.36H32.68v-5.19H31.3l-1.92.68v1.4l1.65-.49v3.6Z"/>
				<path d="M24.7,650A6.73,6.73,0,0,1,25,648.2a7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.74,6.74,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.14,7.14,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,650Zm6.87-3.42a4.35,4.35,0,0,0-2,.46v1.41a4,4,0,0,1,1.67-.48q.91,0,.91.69a1.39,1.39,0,0,1-.17.63,4.58,4.58,0,0,1-.51.75q-.34.42-.84.93l-1.17,1.17v1.09h4.63V651.9H32.57q-.48,0-1,0v0q.59-.55,1-1a7.15,7.15,0,0,0,.73-.84,3.36,3.36,0,0,0,.43-.79,2.36,2.36,0,0,0,.14-.83,1.74,1.74,0,0,0-.18-.81,1.7,1.7,0,0,0-.49-.58,2.18,2.18,0,0,0-.74-.35A3.45,3.45,0,0,0,31.57,646.62Z"/>
				<path d="M344.2,613a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,613Zm9.32-1.66a1.52,1.52,0,0,0-.64-1.28,3,3,0,0,0-1.82-.48,5.59,5.59,0,0,0-1.69.27v1.38a4.11,4.11,0,0,1,.71-.21,3.81,3.81,0,0,1,.75-.07,1.49,1.49,0,0,1,.71.15.5.5,0,0,1,.27.47.54.54,0,0,1-.09.32.68.68,0,0,1-.24.21,1.25,1.25,0,0,1-.35.12,2.15,2.15,0,0,1-.42,0h-.63v1.3h.84a2.51,2.51,0,0,1,.41,0,1,1,0,0,1,.33.11.61.61,0,0,1,.22.21.69.69,0,0,1-.21.89,1.56,1.56,0,0,1-.89.2,3.92,3.92,0,0,1-.84-.09,3.38,3.38,0,0,1-.67-.21v1.39a4.45,4.45,0,0,0,1.59.27,3.36,3.36,0,0,0,2.09-.55,1.81,1.81,0,0,0,.71-1.51,1.45,1.45,0,0,0-.29-.95,1.48,1.48,0,0,0-.75-.49v0a1.46,1.46,0,0,0,.69-.61A1.78,1.78,0,0,0,353.52,611.38Z"/>
				<path d="M344.2,650a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,650Zm7.58-3.23h-1.66L348.51,651v1h2.74v1.26h1.58V652h.78v-1.33h-.78v-1h-1.58v1H351a8.56,8.56,0,0,0-.88,0v0a10.71,10.71,0,0,0,.63-1.35Z"/>
				<path d="M344.2,669a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,669Zm6.76-1.87h2.38v-1.36h-3.73l-.52,3.66a6.15,6.15,0,0,1,1.05-.09,4.1,4.1,0,0,1,.71.05,1.44,1.44,0,0,1,.46.15.62.62,0,0,1,.25.25.77.77,0,0,1,0,.72.83.83,0,0,1-.27.27,1.27,1.27,0,0,1-.39.16,2,2,0,0,1-.46.05,3.6,3.6,0,0,1-.75-.07,4.09,4.09,0,0,1-.73-.23v1.41a3.61,3.61,0,0,0,.68.2,4.77,4.77,0,0,0,.93.08,3.74,3.74,0,0,0,1.16-.17,2.61,2.61,0,0,0,.88-.47,2.07,2.07,0,0,0,.55-.72,2.13,2.13,0,0,0,.2-.91,2.22,2.22,0,0,0-.13-.76,1.54,1.54,0,0,0-.41-.62,2,2,0,0,0-.73-.41,3.35,3.35,0,0,0-1.08-.15h-.19Z"/>
				<path d="M344.2,688a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,688Zm7.16-.87a3,3,0,0,0-.71.08,2.23,2.23,0,0,0-.52.19l0,0A1.48,1.48,0,0,1,351.7,686a4,4,0,0,1,.77.08,4.51,4.51,0,0,1,.7.19v-1.34a3.11,3.11,0,0,0-.64-.2,4.16,4.16,0,0,0-.89-.09,3.27,3.27,0,0,0-1.43.29,2.68,2.68,0,0,0-1,.79,3.32,3.32,0,0,0-.56,1.18,5.57,5.57,0,0,0-.18,1.44,4.15,4.15,0,0,0,.2,1.35,2.74,2.74,0,0,0,.55,1,2.24,2.24,0,0,0,.83.58,2.71,2.71,0,0,0,1,.19,3,3,0,0,0,1-.16,2.23,2.23,0,0,0,.77-.45,2,2,0,0,0,.49-.71,2.37,2.37,0,0,0,.17-.91,2.08,2.08,0,0,0-.16-.83,1.79,1.79,0,0,0-.45-.63,2,2,0,0,0-.68-.4A2.61,2.61,0,0,0,351.36,687.16Zm-.27,2.87a.83.83,0,0,1-.39-.09,1,1,0,0,1-.31-.27,1.32,1.32,0,0,1-.21-.43,1.93,1.93,0,0,1-.08-.57,2,2,0,0,1,.44-.17,1.8,1.8,0,0,1,.47-.06.94.94,0,0,1,.66.21.7.7,0,0,1,.23.54.83.83,0,0,1-.22.62A.8.8,0,0,1,351.09,690Z"/>
			</g>

		</svg>

		<ReactTooltip anchorId="tt1" place="right" float>
			<span>1. Eigen bijdrage voor maatwerkvoorziening</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Uitbetaling eigen bijdrage voor maatwerkvoorziening</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt3" place="right" float>
			<span>3. Middelen maatschappelijke ondersteuning</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt4" place="right" float>
			<span>4. Betaling geleverde ondersteuning</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt5" place="right" float>
			<span>5. Middelen persoonsgebonden budget</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt6" place="left" float>
			<span>6. Betaling geleverde ondersteuning</span>
		</ReactTooltip>

	</div>
);

export default GeldstromenWMO;


