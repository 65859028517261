import React from 'react';

const LogoVVAA = () => (
	<svg viewBox="0 0 138 30" className="logo-partner logo-vvaa">
  <path d="M124,28s-.19-.42,0-.47c.79-.15,2.85,0,2.85.47h.47c.22,0-.18-.39,0-.39.67,0,3-.19,3.67-.19.48,0-.1.58.32.58h2a1.83,1.83,0,0,0,1.82-1.82v-24A1.83,1.83,0,0,0,133.41.33h-5.84c0,.85-4.22,1.24-4.24,0h-14a1.82,1.82,0,0,0-1.81,1.82v24A1.82,1.82,0,0,0,109.36,28Z" fill="#f18a00" fillRule="evenodd"/>
  <path d="M98.12.48V3.09a14.34,14.34,0,1,0-8.88,25.6,14.25,14.25,0,0,0,8.88-3.08v2.6h6.37V.48Zm-8.88,22.4a8.53,8.53,0,1,1,8.53-8.53A8.53,8.53,0,0,1,89.24,22.88Z" fill="#54565a"/>
  <path d="M68.71.48V3.09a14.34,14.34,0,1,0-8.88,25.6,14.25,14.25,0,0,0,8.88-3.08v2.6h6.37V.48Zm-8.88,22.4a8.53,8.53,0,1,1,8.53-8.53A8.53,8.53,0,0,1,59.83,22.88Z" fill="#f18a00"/>
  <path d="M25.77,11.3H31.5l4.31,8.81,4.32-8.81h5.72L37.79,27.77l-.22.45H34.05L25.77,11.3Z" fill="#a1a1a4"/>
  <polygon points="18 28.19 31.45 0.67 24.38 0.67 15.73 18.35 7.08 0.67 0 0.67 13.47 28.2 13.51 28.18 17.97 28.18 18 28.19" fill="#54565a"/>
  <path d="M130.12,25.19a17.1,17.1,0,0,0-4.31-3.32c-1.31-.76-2.56-1.5-2.67-2.05l-.09-.18a4.2,4.2,0,0,1-.14-.59l0-.21a.62.62,0,0,1,.08-.24l.09-.15c0-.15.18-.19.52-.47l.09-.13-.07-4.24V13l-.15-.26c-.24.1-.48.22-.63.3A16.47,16.47,0,0,0,121,14.35a6.27,6.27,0,0,0-2.2,3.12,4.7,4.7,0,0,0-.14,1.44l0,.12a5.13,5.13,0,0,0,.57,2.28v.12a10.93,10.93,0,0,0,4.55,4l.12.08a14.48,14.48,0,0,1,3.5,2.16l.11.12a.74.74,0,0,1,.16.2h3.78a4.46,4.46,0,0,0-1.05-2.54Zm-2-21a2.94,2.94,0,0,1-1.48-.72,3,3,0,0,0-2.19-.84h0a8.11,8.11,0,0,0-1.56.19c-.59.12-1.25.16-1.77.25a2.17,2.17,0,0,0-.68.2l0-.06c-.19.1-.37,0-.37.28l-.05.17a2.2,2.2,0,0,0,.18.43c0,.13-.06,0-.06,0l0,0c0,.11.1.08,0,.18l-.07.14c-.11.08-.23,0-.22.31l0,.15a2.77,2.77,0,0,0,.1.26c0,.32.15.2.46.29l.21,0c.33.1.48.16,1,.38l.33.09a9.3,9.3,0,0,0,2.77,1l.32.07c.12,0,.1.07.23.06l.2-.1a7.11,7.11,0,0,1,.9,0h.31c2,0,2.59,1.07,2.6,2a2.39,2.39,0,0,1-1,1.84,2.74,2.74,0,0,1-.41.22l-.17.07,0,.36-.14,4.27v.22a2.56,2.56,0,0,1,.24-.11l.13-.06a3.36,3.36,0,0,1,.33-.16l.13,0a8.89,8.89,0,0,0,4.23-3.68l.14-.17a4.37,4.37,0,0,0,.65-2.34l0-.12C133.34,7.16,132,5,128.08,4.17Z" fill="#fff"/>
  <path d="M127.65.41a.22.22,0,0,0,0-.08h-4.36l.08,2.3.13.15,1.14-.06.36.08,1,.39.26.28.31.27.55.27.3.15.15,0,.1-3.63ZM126.57,7h-2.32l-.67-.13-.14-.15.36,13.82.11.2,1.11.89,1.43.82.51.32h.16l.36-15.53-.15-.1ZM126.63,27l-.85-.55-.64-.36L124,25.57l-.11-.06,0,2.5h3v-.64l-.1-.17Z" fill="#fff"/>
  <path d="M123.42.37s0,0,0,0h-.35s0,0,0,0l.06,2.36.35,0Zm4.42,0h-.35s0,0,0,.06l-.09,3.7.35.11.09-3.81S127.84.35,127.84.33ZM127,22.74l.34.23.38-15.86L127.34,7ZM126.85,28h.34l0-.49-.35-.27S126.86,27.52,126.85,28Zm-3.19-2.6.07,2.6h.34l0-2.41Zm-.09-18.66-.35-.1.33,13.79.35.26Z" fill="#f18a00"/>
  <path d="M123.76,25.23c-1.45-.86-3.34-1.69-4.46-4a5.35,5.35,0,0,1-.51-2.26,5.17,5.17,0,0,1,.22-1.49,5.83,5.83,0,0,1,2.08-2.93A15.67,15.67,0,0,1,123,13.25c.1-.06.23-.12.38-.19l.07,0v-.39a5.3,5.3,0,0,0-.63.31,16.33,16.33,0,0,0-1.91,1.28,6.32,6.32,0,0,0-2.2,3.12,5.81,5.81,0,0,0-.23,1.58,5.71,5.71,0,0,0,.54,2.41c1.18,2.47,3.19,3.35,4.61,4.19a15.26,15.26,0,0,1,3.55,2.18,1.66,1.66,0,0,1,.25.3h.4a1.58,1.58,0,0,0-.4-.54A15.08,15.08,0,0,0,123.76,25.23ZM128,4a3.1,3.1,0,0,1-1.52-.74,3.13,3.13,0,0,0-2.24-.87h0a7.79,7.79,0,0,0-1.6.21c-.6.12-1.27.15-1.81.24a2.34,2.34,0,0,0-.69.21.61.61,0,0,0-.34.53.92.92,0,0,0,.07.31,1,1,0,0,1,.07.29c0,.11,0,.12-.15.22a.65.65,0,0,0-.29.52s0,.07,0,.1c.05.33.34.43.66.52s.79.21,1.36.43a9.08,9.08,0,0,0,3.44,1.16,2.68,2.68,0,0,0,.4,0,8.54,8.54,0,0,1,1-.06c2.06,0,2.76.89,2.77,1.83A2.45,2.45,0,0,1,128,10.79a2.49,2.49,0,0,1-.42.22l-.17.07,0,.37.18-.08a3,3,0,0,0,.58-.3,2.75,2.75,0,0,0,1.2-2.16,1.89,1.89,0,0,0-.75-1.53,3.81,3.81,0,0,0-2.37-.65,9.22,9.22,0,0,0-1,.06,2.17,2.17,0,0,1-.36,0,8.87,8.87,0,0,1-3.31-1.13,11.25,11.25,0,0,0-1.39-.44c-.33-.1-.42-.18-.41-.23s0,0,0-.06,0-.15.15-.24a.6.6,0,0,0,.28-.5,1,1,0,0,0-.09-.41.54.54,0,0,1,0-.19c0-.1,0-.15.16-.22a13.06,13.06,0,0,1,2.4-.42,8.13,8.13,0,0,1,1.53-.2,2.73,2.73,0,0,1,2,.78,3.48,3.48,0,0,0,1.68.82c3.9.87,5.22,2.9,5.22,4.87a5,5,0,0,1-.67,2.44A8.6,8.6,0,0,1,128,15.47l-.39.21-.31.15v.38l.47-.22.39-.21a9,9,0,0,0,4.55-3.93,5.28,5.28,0,0,0,.72-2.61C133.45,7.1,132,4.9,128,4Zm-2.22,17.72c-1.31-.76-2.43-1.66-2.54-2.21a3,3,0,0,1-.1-.58.42.42,0,0,1,0-.2c.05-.15.21-.32.55-.61l0-.42a2.74,2.74,0,0,0-.84.9.88.88,0,0,0-.06.33,3.22,3.22,0,0,0,.11.66c.21.8,1.37,1.64,2.7,2.43a17,17,0,0,1,4.43,3.2A4.72,4.72,0,0,1,131.19,28h.36a5.15,5.15,0,0,0-1.31-3A17.31,17.31,0,0,0,125.73,21.75Z" fill="#f18a00"/>
  <path d="M124.05,4.35s-1.15-1.14-1.55-.27A4.74,4.74,0,0,0,124.05,4.35Z" fill="#f18a00"/>
  <path d="M122.84,4a.2.2,0,0,0,.4,0,.2.2,0,0,0-.4,0Z" fill="#f58220" stroke="#fff" strokeWidth="0.19"/>
  <path d="M118.3,4.27a5,5,0,0,0-1.24-.21h0a2.52,2.52,0,0,0-.63.09h0l-.51.18a.18.18,0,0,1-.16,0h0a.09.09,0,0,1,0-.13h0s.39-.28.57-.37h0l.29-.13h0l-.33,0H116l-1.1.09h0c-.06,0-.13,0-.14-.08h0s0-.1.08-.11h0l.83-.19h0a3.83,3.83,0,0,1,.83-.09h0a5.18,5.18,0,0,1,.63,0h0a6.28,6.28,0,0,1,1.86.47h0a5.8,5.8,0,0,0,1.88.42h0l.17,0h0s.07,0,.09.1h0c0,.09-.08.08-.09.09h-.47a12,12,0,0,1-2.25-.17Z" fill="#fff"/>
	</svg>
);

export default LogoVVAA;
