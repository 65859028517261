import React, { Component } from 'react';
import {
	select,
	scaleBand,
	scaleLinear,
	scaleOrdinal,
	axisBottom,
	axisLeft,
	min,
	max,
	pointer
} from 'd3';

import './ColumnChart.scss';

class ColumnChart extends Component {

	state = {
		width: 0,
		height: 0,
		tooltip: { display: 'none', x: 0, y: 0 }
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	componentWillUnmount() {
			window.removeEventListener('resize', this.updateDimensions);
	}

  componentDidUpdate(prevProps, prevState){
    if (this.state.width !== prevState.width || this.state.height !== prevState.height) this.updateChart();
    if (this.props.data) {
      if (this.props.data !== prevProps.data) {
        this.updateDimensions();
        this.updateChart();
      }
    }
  }

	updateDimensions = () => {
		if (!this.container) return;
		const width = this.container.clientWidth,
					height = this.props.height || this.container.clientHeight;
		if (this.state.width !== width || this.state.height !== height) this.setState({ width, height });
	}

	updateChart= () => {
		const props = this.props;
		const { data, colorAxis, margin, showLegend, stacked, animation } = props;
		const { width, height } = this.state;
		const transitionDuration = (animation === false || animation === 'undefined') ? 0 : 1000;

		const chartWidth = width - margin.left - margin.right;
		const chartHeight = height - margin.top - margin.bottom;

		const container = select(this.container);
		const svg = container.select('svg');

		const categories = [];
		let xLabel;

		data.forEach(d => {
			for(var key in d) {
					if (typeof(d[key]) !== 'string' && categories.indexOf(key) === -1) categories.push(key);
					if (typeof(d[key]) === 'string' && d[key] !== 'total') xLabel = key;
			}
		});

		// copy data
		const chartData = [];
		data.forEach(group => {
			const obj = { total: 0 };
			obj[xLabel] = group[xLabel];
			categories.forEach(category => {
				obj[category] = { value: +group[category] };
				obj.total += group[category];
				obj[category].yStart = obj.total;
			})
			chartData.push(obj)
		})

		const minY = min(data, d => min(categories, key => d[key]));
		const maxY = max(data, d => max(categories, key => d[key]));
		const domainY = props.domain || [minY, maxY];
		const formatY = props.formatY || (value => value);
		const formatValueToolTip = props.formatValueToolTip || (d => (<span>{d.category}<br/>{d.data.value}</span>));
		const colorBars = props.colorBars ? props.colorBars : ["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"];

		const xScale0 = scaleBand().rangeRound([0, chartWidth]).padding(props.columnPadding ? props.columnPadding : 0.1).domain(chartData.map( d => d[xLabel] ));
		const xScale1 = scaleBand().padding(props.columnPadding ? props.columnPadding : 0.05).domain(categories).rangeRound([0, xScale0.bandwidth()]);
		const yScale = scaleLinear().rangeRound([chartHeight, 0]).domain(domainY).nice();
		const colorScale = scaleOrdinal().range(colorBars);

		svg
			.select('.x.axis')
			.attr('transform', 'translate(0,' + chartHeight + ')')
			.call(axisBottom(xScale0).tickSize(0).tickPadding(6));

		svg
			.select('.baseline')
			.attr('x2', 0)
			.attr('x2', chartWidth+100);

		svg.select('.axis.y').call(axisLeft(yScale).ticks(6))

		svg.select('.af-columnchart').selectAll('.box').remove();

		const boxes = svg.select('.af-columnchart').selectAll('.box').data(chartData);

		boxes
			.enter()
			.append("g")
			.attr('class', (d,i) => 'box box' + i)
			.attr("transform", d => "translate(" + xScale0(d[xLabel]) + ",0)")
			.selectAll("rect")
			.data(d =>  categories.map(key => ({ category: key, data: d[key], column: d[xLabel] } ) ))
			.enter()
			.append("rect")
			.attr('class', d => 'column ' + d.category.replace(/\s/g, '').toLowerCase())
			.attr("x", d => stacked ?  0 : xScale1(d.category))
			.attr("y", d => yScale(0))
			.attr("width", stacked ? xScale0.bandwidth() : xScale1.bandwidth())
			.attr("fill", d => colorScale(d.category) )
			.attr("height", 0)
			.on('mousemove', (event, d) => {
				const { tooltip } = this.state;
				const m = pointer(event, container.node());
				tooltip.x = m[0] + 12;
				tooltip.y = m[1] + 12;
				if (!this.props.disableTooltip) {
					tooltip.display = 'block';
				}
				tooltip.text = formatValueToolTip(d);
				this.setState({tooltip})
			})
			.on('mouseleave', () => {
				const tooltip = { display: 'none', x: 0, y: 0 };
				this.setState({tooltip});
			})
			.transition()
			.duration(transitionDuration)
			.attr("height", d => Math.abs(yScale(d.data.value) - yScale(0)) )
			.attr("y", d => {
				if (stacked) return	d.data.value > 0 ? yScale(d.data.yStart) : yScale(0)
				else return	d.data.value > 0 ? yScale(d.data.value) : yScale(0)
			})

		// numbers
		if (stacked) {
			svg.selectAll('.box')
				.append('text')
				.attr('class', 'column-value')
				.attr('text-anchor', 'middle')
				.attr('x', d => xScale0.bandwidth()/2)
				.attr("y", d => yScale(d.total))
				.attr("dy", d => d.total >= 0 ? -9 : 18)
				.text(d => formatY(d.total, d));
		}
		else {
			svg.selectAll('.box')
				.selectAll('text')
				.data(d =>  categories.map(key => ({ category: key, data: d[key] } ) ))
				.enter()
				.append('text')
				.attr('class', 'column-value')
				.attr('text-anchor', 'middle')
				.attr('x', d => xScale1(d.category) + xScale1.bandwidth()/2)
				.attr("y", d => yScale(d.data.value))
				.attr("dy", d => d.data.value >= 0 ? -9 : 18)
				.text(d => formatY(d.data.value));
		}

			if(showLegend){
				const legendItems = container.select('.af-legend');

					legendItems.selectAll('li').remove();

					legendItems.selectAll('li')
								.data(categories)
								.enter()
								.append('li');

					legendItems.selectAll('li')
						.append('span')
							 	.style('width', '18px')
							 	.style('height', '18px')
							 	.style('margin-right', '9px')
							 	.style('background-color', d => colorScale(d));

					legendItems.selectAll('li').append('span').text(d => d).style('margin-right', '12px');
			}

		const axis = svg.selectAll('.axis');
		axis.selectAll('path').style('stroke', colorAxis);
		axis.selectAll('line').style('stroke', colorAxis);
		axis.selectAll('text').style('fill', colorAxis).attr('font-size', '13');


	}

	render() {
		const { width, height, tooltip } = this.state;
		const { margin, labelY } = this.props;
		const { display, x, y, text } = tooltip;

    const containerStyle = {
      position: 'relative'
    }
    const tooltipStyle = {
      display: display,
      position: 'absolute',
      left: x,
      top: y
    }

		return (
			<div className="ColumnChart" ref={container => this.container = container} style={containerStyle}>
				<svg width={width} height={height} >
					<g className="af-columnchart" transform={"translate(" + margin.left + "," + margin.top + ")"}>
						<g className="x axis">
							<line className="baseline" />
						</g>
						<g className="y axis">
							<text className="labelY" dy="-12" textAnchor="end">{labelY}</text>
						</g>
						<g className="catchers" />
					</g>
				</svg>
				<ul className="af-legend" style={ {marginLeft: margin.left } } />
				<div className="tooltip" style={tooltipStyle}>
					{text}
				</div>

			</div>
		);
	}
}

export default ColumnChart;
