import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';

const BekendheidClientondersteuner = () => {
  const margin = {top: 90, right: 0, bottom: 90, left: 0};
  const formatY = (value, d) => `${d['Wel bekend'].value}%`;
  const formatValueToolTip = d => (<span>{d.category}<br/>{d.data.value}%</span>);

  const data = [
      {
          "year": "2016",
          "Wel bekend": 27,
          "Niet bekend": 73,
      },
      {
          "year": "2017",
          "Wel bekend": 29,
          "Niet bekend": 71,
      },
      {
          "year": "2018",
          "Wel bekend": 30,
          "Niet bekend": 70,
      },
      {
          "year": "2019",
          "Wel bekend": 33,
          "Niet bekend": 67,
      },
      {
        "year": "2020",
        "Wel bekend": 34,
        "Niet bekend": 66,
      },

  ];

  return (
    <div className="vis-bekendheid-clientondersteuner">
    <ColumnChart
      margin={margin}
      colorBars={["#333","#ddd"]}
      colorAxis="#333"
      labelY="x 100"
      height={450}
      domain={[0, 100]}
      columnPadding={0.5}
      formatY={formatY}
      formatValueToolTip={formatValueToolTip}
      data={data}
      stacked={true}
    />

    <div className="vis-credit">
      <p>Percentage mensen met een maatwerkvoorziening uit de Wmo dat wel of niet weet gebruik te kunnen maken van een onafhankelijke cliëntondersteuner.</p>
      <p>Bron: Landelijke benchmark: Ervaringen van gebruikers Wmo in Nederlandse gemeenten 2020. ZorgfocuZ.</p>
    </div>

  </div>
  );
}
export default BekendheidClientondersteuner;
