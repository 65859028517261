import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';


const HoeveelOuderen = () => {
	const data = [
			{
	        "label": "64 jaar of jonger",
	        "value": 14,
	    },
			{
	        "label": "65-79 jaar",
	        "value": 2.6,
	    },
	    {
	        "label": "80 jaar of ouder",
	        "value": 0.8,
	    },
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0}>17 miljoen</tspan>
      <tspan x={0} dy={15}>Nederlanders</tspan>
    </text>);

	return (
		<div className="vis-hoeveel-ouderen">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				unit="miljoen"
				animation={true}
			/>

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">3,4</p>
        <p>miljoen</p>
        <p>ouderen</p>
      </div>

			<div className="vis-credit">
				<p>Bevolking naar leeftijd op 1 januari 2020</p>
				<p>Bron: CBS Statline</p>
			</div>

		</div>
	);
}

export default HoeveelOuderen;


