import React from 'react';

import AantalWerkenden from './visualizations/aantal-werkenden';
import PlekWerkenden from './visualizations/plek-werkenden';
import Vacatures from './visualizations/vacatures';

const vissen = {
	aantal_werkenden: <AantalWerkenden />,
	plek_werkenden: <PlekWerkenden />,
	vacatures: <Vacatures />,
};

export default vissen;
