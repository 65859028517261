import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenRijksvaccinatieprogramma = () => (

<div className="vis-geldstromen-rvp svg-container">
<svg
	xmlns="http://www.w3.org/2000/svg"
	preserveAspectRatio="xMidYMin meet"
	viewBox="95 30 525 800">
	<g className="geldstromen">
		<path data-tooltip-content="react-tooltip" id="tt1" d="M291,138H274.5c-11.38,0-24.4,8.64-29.65,19.68L143.31,371.17c-4.66,9.8-8.31,26-8.31,36.83v27h0l6,6,6-6h0V408c0-9,3.27-23.53,7.15-31.68L255.69,162.83c3.25-6.83,12-12.83,18.81-12.83H291l-6-6,6-6Z"/>
		<polygon data-tooltip-content="react-tooltip" id="tt3" points="489 465 483 459 483 459 483 459 207 459 207 459 213 465 207 471 207 471 483 471 483 471 483 471 489 465"/>
		<polygon data-tooltip-content="react-tooltip" id="tt2" points="354 276 354 168 348 174 342 168 342 168 342 168 342 276 342 276 342 276 348 282 354 276 354 276"/>
	</g>

	<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-rijksvaccinatieprogramma', hash: '#Rijk' }} >
		<g className="kader link">
			<rect x="294" y="123" width="108" height="42" rx="9" fill="#fff"/>
			<text className="tekst centreer" transform="translate(350 150)">Rijk</text>
		</g>
	</Link>

		<g className="kader">
			<rect x="294" y="285" width="108" height="42" rx="9" fill="#fff"/>
			<text className="tekst centreer" transform="translate(350 312.5)">RIVM</text>
		</g>

	<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-rijksvaccinatieprogramma', hash: '#Gemeenten' }} >
		<g className="kader link">
			<rect x="96" y="444" width="108" height="42" rx="9" fill="#fff"/>
			<text className="tekst centreer" transform="translate(150 472)">Gemeenten</text>
		</g>
	</Link>
		<g className="kader">
			<rect x="492" y="444" width="124" height="42" rx="9" fill="#fff"/>
			<text className="tekst centreer" transform="translate(553 462)">Jeugdgezondheids-<tspan x="4" y="15">zorgorganisaties</tspan></text>
		</g>

<g className="tekst links regular">
	<text transform="translate(114 515.56)">
		Stortingen in Gemeentefonds
		<tspan x="0" y="15">Rijksbijdrage</tspan>
		<tspan x="0" y="30">Betaling voor toegediende vaccinaties</tspan>
	</text>
</g>

<g className="tekstlabels wit">
	<path d="M341.5,226.35a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,5.93,5.93,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.55,6.55,0,0,1,3.44,0,6.65,6.65,0,0,1,2.88,1.67,6.64,6.64,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.56,6.56,0,0,1-2.34,2.34,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23A6.65,6.65,0,0,1,343.4,231a6.43,6.43,0,0,1-1-1.32,6.78,6.78,0,0,1-.66-1.56A6.11,6.11,0,0,1,341.5,226.35Zm6.43-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.57-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.41,4.41,0,0,1-.48.7,11,11,0,0,1-.78.88l-1.1,1.1v1h4.34v-1.28h-1.47c-.3,0-.61,0-.94,0v0l1-.92a6.43,6.43,0,0,0,.68-.79,3,3,0,0,0,.41-.74,2.21,2.21,0,0,0,.14-.78,1.58,1.58,0,0,0-.63-1.3,2,2,0,0,0-.7-.33A3.32,3.32,0,0,0,347.93,223.14Z" fill="#fff"/>
	<path d="M211.92,226.35a6.23,6.23,0,0,1,.24-1.73,6.86,6.86,0,0,1,.65-1.55,6.29,6.29,0,0,1,1-1.32,6,6,0,0,1,1.32-1,6.44,6.44,0,0,1,1.55-.65,6.23,6.23,0,0,1,1.73-.23,6.15,6.15,0,0,1,1.72.23,6.65,6.65,0,0,1,2.88,1.67,6.64,6.64,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.56,6.56,0,0,1-2.34,2.34,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.62,6.62,0,0,1-1.73-.23,6.89,6.89,0,0,1-1.55-.66,6.24,6.24,0,0,1-1.32-1,6.85,6.85,0,0,1-1-1.32,6.75,6.75,0,0,1-.65-1.56A6.11,6.11,0,0,1,211.92,226.35Zm4.48,1.74v1.28h4.29v-1.28H219.4v-4.86h-1.29l-1.81.64v1.31l1.55-.46v3.37Z" fill="#fff"/>
	<path d="M341.5,465.24a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,5.93,5.93,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.78,6.78,0,0,1,1.56-.66,6.49,6.49,0,0,1,1.72-.23,6.11,6.11,0,0,1,1.72.24,6.34,6.34,0,0,1,1.56.65,6.47,6.47,0,0,1,1.32,1,6.64,6.64,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.58,6.58,0,0,1,0,3.45,6.51,6.51,0,0,1-.65,1.56,7.27,7.27,0,0,1-1,1.32,6.8,6.8,0,0,1-1.32,1,6.08,6.08,0,0,1-1.55.66,6.57,6.57,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23,6.65,6.65,0,0,1-2.88-1.67,6.43,6.43,0,0,1-1-1.32,7,7,0,0,1-.66-1.56A6.15,6.15,0,0,1,341.5,465.24Zm8.73-1.56a1.42,1.42,0,0,0-.59-1.2,2.81,2.81,0,0,0-1.71-.45,5,5,0,0,0-1.59.26v1.28a4.72,4.72,0,0,1,.67-.19,3.26,3.26,0,0,1,.7-.07,1.42,1.42,0,0,1,.67.14.47.47,0,0,1,.26.44.49.49,0,0,1-.09.3.59.59,0,0,1-.22.19,1.61,1.61,0,0,1-.33.12,2.6,2.6,0,0,1-.4,0H347v1.22h.79a2.17,2.17,0,0,1,.38,0,.94.94,0,0,1,.31.1.67.67,0,0,1,.21.2.71.71,0,0,1,.07.31.58.58,0,0,1-.28.52,1.43,1.43,0,0,1-.83.19,3.22,3.22,0,0,1-.78-.08,3.4,3.4,0,0,1-.63-.19v1.3a4.2,4.2,0,0,0,1.49.26,3.16,3.16,0,0,0,2-.52,1.71,1.71,0,0,0,.67-1.42,1.36,1.36,0,0,0-.28-.88,1.39,1.39,0,0,0-.71-.47v0a1.37,1.37,0,0,0,.65-.58A1.72,1.72,0,0,0,350.23,463.68Z" fill="#fff"/>
</g>

<g className="tekstlabels zwart">
		<path d="M96.44,512.17A4,4,0,0,1,96.6,511a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,512.17Zm3,1.16v.85h2.86v-.85h-.86v-3.24h-.86l-1.2.42v.87l1-.3v2.25Z" fill="#1d1d1b"/>
		<path d="M96.44,527.17A4,4,0,0,1,96.6,526a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,527.17Zm4.29-2.14a2.67,2.67,0,0,0-1.22.29v.87a2.49,2.49,0,0,1,1-.3c.37,0,.56.15.56.43a.77.77,0,0,1-.11.39,2.51,2.51,0,0,1-.31.47c-.14.18-.32.37-.53.59l-.73.73v.68h2.9v-.85h-1l-.63,0v0l.64-.61a6.65,6.65,0,0,0,.46-.53,2.19,2.19,0,0,0,.27-.49,1.5,1.5,0,0,0,.09-.52,1.07,1.07,0,0,0-.12-.51,1,1,0,0,0-.3-.36,1.4,1.4,0,0,0-.46-.22A2.15,2.15,0,0,0,100.73,525Z" fill="#1d1d1b"/>
		<path d="M96.44,542.17A4,4,0,0,1,96.6,541a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,542.17Zm5.82-1a.94.94,0,0,0-.39-.8,1.89,1.89,0,0,0-1.14-.3,3.31,3.31,0,0,0-1.06.17v.86a2.23,2.23,0,0,1,.44-.13,2.61,2.61,0,0,1,.48,0,.93.93,0,0,1,.44.09.33.33,0,0,1,.17.3.34.34,0,0,1-.06.2.39.39,0,0,1-.15.13.63.63,0,0,1-.22.07,1.09,1.09,0,0,1-.26,0h-.39v.81h.52a1.06,1.06,0,0,1,.26,0,.43.43,0,0,1,.2.07.35.35,0,0,1,.14.13.41.41,0,0,1,.05.21.39.39,0,0,1-.18.34,1,1,0,0,1-.56.13,2.87,2.87,0,0,1-.52,0,2.35,2.35,0,0,1-.42-.13v.86a2.64,2.64,0,0,0,1,.18,2.09,2.09,0,0,0,1.31-.35,1.11,1.11,0,0,0,.44-.94.9.9,0,0,0-.18-.59.89.89,0,0,0-.47-.31v0a1,1,0,0,0,.43-.38A1.12,1.12,0,0,0,102.26,541.13Z" fill="#1d1d1b"/>
</g>

</svg>

		<ReactTooltip anchorId="tt1" place="right" float>
			<span>1. Stortingen in Gemeentefonds</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Rijksbijdrage</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt3" place="right" float>
			<span>3. Betaling voor toegediende vaccinaties</span>
		</ReactTooltip>

	</div>
);

export default GeldstromenRijksvaccinatieprogramma;


