import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';


const BevolkingVerpleeghuis = () => {

	const data = [
			{
	        "label": "Mannen (Gemiddelde leeftijd 82 jaar)",
	        "value": 27,
	    },
	    {
	        "label": "Vrouwen (Gemiddelde leeftijd 87 jaar)",
	        "value": 73,
	    },
	];
	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		const shortLabel = item.label.split(' ')[0];
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}

	const formatTotal = () => null;

	return (
		<div className="vis-bevolking-verpleeghuis">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				unit="%"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Bewoners van verpleeghuizen naar geslacht en leeftijd in 2016.</p>
				<p>Bron: Sociaal en Cultureel Planbureau (2017)</p>
			</div>

		</div>
	);
}

export default BevolkingVerpleeghuis;


