import React from 'react';
//import { Link } from 'react-router-dom';

const BatenLastenZorgverzekeraars = () => (
<div className="vis-baten-lasten-zorgverzekeraars svg-container">
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 120 320 700"
		preserveAspectRatio="xMidYMin meet">

		<g className="staaf1">
			<path d="M24,208v99.19H2V208H24m1-1H1V308.18H25V207Z"/>
			<path d="M60,194.74V307.23H38V194.74H60m1-1H37V308.23H61V193.74Z"/>
			<path d="M96,190.74V307.08H74V190.74H96m1-1H73V308.08H97V189.74Z"/>
			<path d="M132,187V307.14H110V187h22m1-1H109V308.14h24V186Z"/>
			<path d="M132,382.29v66.65H110V382.29h22m1-1H109v68.65h24V381.29Z"/>
			<path d="M96,379.54v66.74H74V379.54H96m1-1H73v68.74H97V378.54Z"/>
			<path d="M60,373.33v68H38v-68H60m1-1H37v70H61v-70Z"/>
			<path d="M24,365.45v64.3H2v-64.3H24m1-1H1v66.3H25v-66.3Z"/>
		</g>
		<g className="staaf2">
			<rect x="1.47" y="308.68" width="23" height="1.42"/>
			<rect x="37.47" y="308.73" width="23" height="2.77"/>
			<rect x="73.47" y="308.58" width="23" height="2.92"/>
			<rect x="109.47" y="308.64" width="23" height="2.86"/>
			<rect x="109.47" y="328.39" width="23" height="52.4"/>
			<rect x="73.47" y="330.85" width="23" height="47.19"/>
			<rect x="37.47" y="325.92" width="23" height="45.91"/>
			<rect x="1.47" y="324.5" width="23" height="39.45"/>
		</g>
		<g className="staaf3">
			<rect x="1.47" y="311.11" width="23" height="0.39"/>
			<rect x="109.47" y="324.5" width="23" height="2.89"/>
			<rect x="73.47" y="324.5" width="23" height="5.35"/>
			<rect x="37.47" y="324.5" width="23" height="0.42"/>
		</g>
		<g className="aanpijlenCirkel">
			<path d="M137.94,252.22a2,2,0,1,1,2-2A2,2,0,0,1,137.94,252.22Z"/>
			<path d="M137.94,309a2,2,0,1,1,2-2A2,2,0,0,1,137.94,309Z"/>
			<path d="M137.94,358.47a2,2,0,1,1,2-2A2,2,0,0,1,137.94,358.47Z"/>
			<path d="M137.94,419.69a2,2,0,1,1,2-2A2,2,0,0,1,137.94,419.69Z"/>
			<path className="oranje" d="M137.94,331.44a2,2,0,1,1,2-2A2,2,0,0,1,137.94,331.44Z"/>
		</g>
		<g className="aanpijlenLijn">
			<line x1="137.85" y1="250.25" x2="159.97" y2="250.25"/>
			<line x1="137.85" y1="307" x2="159.97" y2="307"/>
			<line x1="137.85" y1="356.5" x2="159.97" y2="356.5"/>
			<line x1="137.85" y1="417.72" x2="159.97" y2="417.72"/>
			<line className="oranje" x1="137.85" y1="329.46" x2="159.97" y2="329.46"/>
		</g>
		<g className="kader">
			<rect x="159.97" y="240" width="130" height="18" rx="4.5"/>
			<rect className="cyaan" x="159.97" y="297.73" width="130" height="18" rx="4.5"/>
			<rect className="oranje" x="159.97" y="320.46" width="130" height="18" rx="4.5"/>
			<rect className="cyaan" x="159.97" y="346" width="130" height="18" rx="4.5"/>
			<rect x="159.97" y="400" width="130" height="33" rx="4.5"/>
		</g>
		<g className="label">
			<text transform="translate(200 252.8)">Zorgkosten</text>
			<text className="cyaan" transform="translate(195 311)">Bedrijfskosten</text>
			<text className="oranje" transform="translate(205 334)">Resultaat</text>
			<text className="cyaan" transform="translate(185 359.05)">Nominale premie</text>
			<text transform="translate(175 414.27)">Inkomensafhankelijke <tspan x="-6" y="12">bijdrage en rijksbijdrage</tspan></text>
		</g>
		<g className="jaarLabel">
			<text transform="translate(2 322)">2014</text>
			<text transform="translate(38 322)">2015</text>
			<text transform="translate(74 322)">2016</text>
			<text transform="translate(110 322)">2017</text>
		</g>
		<g className="cijfer">
			<text className="oranje" transform="translate(2 305)">+ 0,4</text>
			<text transform="translate(4 201.94)">35,4</text>
			<text transform="translate(40 188.73)">38,5</text>
			<text transform="translate(76 184.83)">39,9</text>
			<text transform="translate(112 181.38)">41,0</text>
			<text transform="translate(4 443.09)">35,8</text>
			<text transform="translate(40 454.85)">38,0</text>
			<text transform="translate(76 459.88)">38,0</text>
			<text transform="translate(112 462.44)">39,7</text>
			<text className="wit" transform="translate(38 336.82)">– 0,5</text>
			<text className="wit" transform="translate(74 342.54)">– 1,9</text>
			<text className="wit" transform="translate(110 339.8)">– 1,3</text>
		</g>
		<g className="titel">
			<text transform="translate(159.72 183)">Lasten</text>
			<text transform="translate(159.72 463)">Baten</text>
		</g>

	</svg>


	<div className="vis-credit">
		<p>Jaarlijkse inkomsten en uitgaven van de zorgverzekeraars in miljard euro.</p>
		<p>Bron: Ministerie VWS, 2018.</p>
	</div>
</div>
);

export default BatenLastenZorgverzekeraars;
