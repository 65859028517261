import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "visualisatie",
  naam: "bereidheid",
  titel: "Met welke organisaties zijn patiënten bereid om gegevens of lichaamsmateriaal te delen?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links: [
    'delen',
    'verzamelen',
  ]
}
