import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const HoeveelOuderen = () => {

	const data = [
			{
	        "label": "Nederlandse achtergrond",
	        "value": 2917,
	    },
	    {
	        "label": "Westerse migratie-achtergrond",
	        "value": 329,
	    },
	    {
	        "label": "Niet-westerse migratie-achtergrond",
	        "value": 145,
	    },
	];


  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatLabel = item => {
		const shortLabel = item.label.split(' ')[0].slice(0, -1);
		return shortLabel + ': ' + localeFormatter.format(',.0f')(item.percentage) + '%';
	}


  const formatTooltip = item => {
  	const value = item.value > 1000 ? localeFormatter.format(',.1f')(item.value/1000) + ' miljoen' : item.value + ' duizend';
  	return item.label + ': ' + value;
  }


	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular" y={-15}>
      <tspan x={0}>3,4 miljoen</tspan>
      <tspan x={0} dy={15}>Nederlanders</tspan>
      <tspan x={0} dy={15}>65 jaar of ouder</tspan>
    </text>);

	return (
		<div className="vis-achtergrond-ouderen">

			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
				formatTotal={formatTotal}
				unit="duizend"
				animation={true}
			/>

      <div className="vis-total">
        <p className="groot">475</p>
        <p>duizend</p>
        <p>met een</p>
        <p>migratie-achtergrond</p>
      </div>

			<div className="vis-credit">
				<p>Bevolking naar leeftijd op 1 januari 2020. Bron: CBS Statline</p>
				<p>Ouderen van Japanse of Indonesische herkomst worden tot de groep met een westerse migratie-achtergrond gerekend.</p>
			</div>

		</div>
	);
}

export default HoeveelOuderen;


