import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const GezondeLevensverwachting = () => {

	const formatValueTooltip = val => val + ' jaar';
	const formatValueY = val => val;

	const dataMen = [
      { key: 'Laag onderwijsniveau', tooltip: 'totale levensverwachting: ', value: 76.8 },
      { key: 'Middelbaar onderwijsniveau', tooltip: 'totale levensverwachting: ', value: 79.9 },
      { key: 'Hoog onderwijsniveau', tooltip: 'totale levensverwachting: ', value:  83.3 },
	];

	const partsMen = [
		{ key: 'Laag onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 57.4 },
    { key: 'Middelbaar onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 65.4 },
    { key: 'Hoog onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 71.6 },
	];

  const dataWomen = [
      { key: 'Laag onderwijsniveau', tooltip: 'totale levensverwachting: ', value: 81.1 },
      { key: 'Middelbaar onderwijsniveau', tooltip: 'totale levensverwachting: ', value: 84.4 },
      { key: 'Hoog onderwijsniveau', tooltip: 'totale levensverwachting: ', value:  86.5 },
  ];

  const partsWomen = [
    { key: 'Laag onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 56.7 },
    { key: 'Middelbaar onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 65.6 },
    { key: 'Hoog onderwijsniveau', tooltip: 'waarvan in als goed ervaren gezondheid: ', value: 72.2 },
  ];

	return (
		<div className="vis-gezonde-levensverwachting">

      <h4>Mannen</h4>

      <BarChart
        data={dataMen}
        margin={{ top: 10, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0,85]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        parts={partsMen}
      />

      <h4>Vrouwen</h4>

      <BarChart
        data={dataWomen}
        margin={{ top: 10, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0,85]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        parts={partsWomen}
      />

      <div className="vis-credit">
        <p>Levensverwachting in goed ervaren gezondheid, per onderwijsniveau in 2016</p>
        <p>Bron: Staat van Volksgezondheid en Zorg</p>
      </div>

	</div>
	)
}

export default GezondeLevensverwachting;
