import React from 'react';

import Huisarts from './Huisarts';
import Ouderenzorg from './Ouderenzorg';
import PubliekeGezondheidszorg from './PubliekeGezondheidszorg';
import Zorgverzekering from './Zorgverzekering';
import Geneesmiddelenzorg from './Geneesmiddelenzorg';
import Gehandicaptenzorg from './Gehandicaptenzorg';
import Mini from './Mini';
import Gidsen from './Gidsen';
import Hart from './Hart';
import LogoFabriek from './LogoFabriek';
import LogoVVAA from './LogoVVAA';
import LogoIBMG from './LogoIBMG';
import Ggz from "./Ggz";
import PalliatieveZorg from "./PalliatieveZorg";
import SociaalDomein from "./SociaalDomein";

export default {
	gehandicaptenzorg: <Gehandicaptenzorg />,
	huisartsenzorg: <Huisarts />,
	ouderenzorg: <Ouderenzorg />,
	"publieke-gezondheidszorg": <PubliekeGezondheidszorg />,
	zorgverzekering: <Zorgverzekering />,
	geneesmiddelenzorg: <Geneesmiddelenzorg />,
	ggz: <Ggz />,
	"palliatieve-zorg": <PalliatieveZorg />,
	mini: <Mini />,
	gidsen: <Mini />,
	waarden: <Hart />,
	"mythenkaart-zorg": <Mini />,
	fabriek: <LogoFabriek />,
	vvaa: <LogoVVAA />,
	ibmg: <LogoIBMG />,
	"sociaal-domein": <SociaalDomein />
};
