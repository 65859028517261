import React from "react";
import BarChart from "../../../../../components/BarChart/BarChart";

const Size = () => {
	const formatValueTooltip = val => val + "%";
	const formatValueY = val => val + "%";

	const data = [
		{ key: "Trade/commerce", value: 16.6 },
		{ key: "Healthcare & welfare work", value: 16.4 },
		{ key: "Business services", value: 12.9 },
		{ key: "Manufacturing/industrial", value: 9.2 },
		{ key: "Miscellaneous & unknown", value: 8.6 },
		{ key: "Hospitality, culture & recreation", value: 6.9 },
		{ key: "Education", value: 6.3 },
		{ key: "Government", value: 6.2 },
		{ key: "Transport & storage", value: 4.6 },
		{ key: "Construction", value: 3.9 },
		{ key: "Communications", value: 3.3 },
		{ key: "Financial services", value: 3.1 },
		{ key: "Agriculture & energy", value: 1.6 }
	];

	return (
		<div className="vis-size">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 239 }}
				barHeight={30}
				domain={[0, 16.6]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			<div className="vis-credit">
				<p>Professional population broken down by sector as a share of the total professional population in 2019.</p>
				<p>Source: CBS StatLine (2021). Werkzame beroepsbevolking.</p>
			</div>
		</div>
	);
};

export default Size;
