export default {"tekst": "Welke rol heeft de huisarts in het zorgstelsel?","items": [{
    "tekst": "Eerste aanspreekpunt",
    "items": [{
        "tekst": "Voor patiënten",
        "items": [{
            "tekst": "De huisarts is het eerste aanspreekpunt voor mensen met vragen over gezondheid en ziekte."
        }, {
            "tekst": "Een bezoek aan de huisarts is laagdrempelig en wordt vergoed via het basispakket."
        }, {
            "tekst": "Een bezoek aan de huisarts valt buiten het eigen risico."
        }]
    }, {
        "tekst": "Voor zorgpartners",
        "items": [{
            "tekst": "De huisarts is het aanspreekpunt voor patiëntenvragen van zorgverleners zoals wijkverpleegkundigen en specialisten."
        }, {
            "tekst": "De huisarts is aanspreekpunt voor patiëntenvragen van organisaties zoals gemeenten en GGD’en."
        }]
    }]
}, {
    "tekst": "24/7 zorgverlener",
    "items": [{
        "tekst": "De huisarts garandeert 24-uurszorg."
    }]
}, {
    "tekst": "Poortwachter",
    "items": [{
        "tekst": "De huisarts diagnostiseert fysieke, psychische en sociale problemen en verwijst door."
    }, {
        "tekst": "De huisarts zorgt voor de juiste zorg op de juiste plek en behandelt indien mogelijk zelf."
    }, {
        "tekst": "De huisarts verwijst indien nodig patiënten door naar andere hulpverleners in de eerste en/of tweede lijn."
    }]
}]}