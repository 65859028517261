import React from "react";
import { ReactComponent as TheSvg } from './preventie-wetten.svg'


const PreventieWetten = () => {
	return (
		<div style={{padding: "50px 0"}} className="vis basis">
			<TheSvg />
		</div>
	)
};

export default PreventieWetten;
