import React from 'react';
import Donut from '../../../components/Donut/Donut';
import { formatLocale } from 'd3';


const KostenDureGeneesmiddelen = () => {

	const data2012 = [
		{
			"label": "Dure middelen",
			"longLabel": "Waarvan dure geneesmiddelen",
			"value": 1404,
		},
		{
			"label": "Andere kosten",
			"longLabel": "Overige kosten voor medisch-specialistische zorg",
			"value": 19347,
		},
	];

	const data2017 = [
		{
			"label": "Dure middelen",
			"longLabel": "Waarvan dure geneesmiddelen",
			"value": 2078,
		},
		{
			"label": "Andere kosten",
			"longLabel": "Overige kosten voor medisch-specialistische zorg",
			"value": 21420,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ': ' + localeFormatter.format(',.1f')(item.percentage) + '%';
	}
	const formatTooltip = item => {
		const value = item.value > 1000 ? localeFormatter.format(',.1f')(item.value/1000) + ' miljard euro' : item.value + ' miljard euro';
		return item.longLabel + ': ' + value;
	}

	const formatTotal2012 = () => (
		<text textAnchor="middle" fontFamily="din-regular" y={-10}>
			<tspan x={0}>Totale kosten</tspan>
			<tspan x={0} dy={18}> 20,7 miljard</tspan>
			<tspan x={0} dy={18}>euro in 2012</tspan>
		</text>
	);
	
	const formatTotal2017 = () => (
		<text textAnchor="middle" fontFamily="din-regular" y={-10}>
			<tspan x={0}>Totale kosten</tspan>
			<tspan x={0} dy={18}> 23,5 miljard</tspan>
			<tspan x={0} dy={18}>euro in 2017</tspan>
		</text>
	);

	// const tooltipStyle = () => {};

	return (
		<div className="vis-kosten-dure-geneesmiddelen">

			<Donut
				data={data2012}
				height={330}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
				tooltipMaxWidth={250}
				formatTotal={formatTotal2012}
				unit="%"
				animation={true}
			/>

			<Donut
				data={data2017}
				height={352}
				fontFamily="din-regular"
				fontSize="15px"
				formatLabel={formatLabel}
				formatTooltip={formatTooltip}
				tooltipMaxWidth={250}
				formatTotal={formatTotal2017}
				unit="%"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Kosten van medisch-specialistische zorg en dure geneesmiddelen</p>
				<p>(inclusief extramurale uitgaven). </p>
				<p>Bron: NZa Monitor, 2019.</p>
			</div>

		</div>
	);
}

export default KostenDureGeneesmiddelen;


