export const changePage = (id) => {
  return {
    type: 'CHANGEPAGE',
    id
  }
}

export const toggleMenu = () => {
  return {
    type: 'MENU'
  }
}

export const search = (searchstring) => {
  return {
    type: 'SEARCH',
    searchstring
  }
}

export const addaccess = (specials) => {
  return {
    type: 'ADDACCESS',
    specials
  }
}

export const setorganisation = (organisation) => {
  return {
    type: 'SETORGANISATION',
    organisation
  }
}
