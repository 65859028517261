import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';

const UitgavenProblemen = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
  });

  const formatValueTooltip = val => {
      if (val < 1) {
          return localeFormatter.format(',.1f')(val) + ' miljard euro';
      } else {
          return localeFormatter.format(',.1f')(val * 1000) + ' miljoen euro';
      }
  }
  const formatValueY = val => localeFormatter.format(',.1f')(val);
  
  const createTable = (data, className) => (
    <table className="tabel">
      <tbody>
        {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
      { key: 'Depressieve stoornissen', value: 1.1 },
      { key: 'Afhankelijkheid van alcohol en drugs', value: 0.82 },
      { key: 'Angststoornissen', value: 0.774 },
      { key: 'Persoonlijkheidsstoornissen', value: 0.678 },
      { key: 'Schizofrenie', value: 0.416 },
  ];

  return (
    <div className="vis-uitgaven-problemen">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 210 }}
        barHeight={30}
        domain={[0,1.2]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-credit">
        <p>Meest kostende psychische aandoeningen en hun kosten in 2017.</p>
        <p>Bron: Volksgezondheidenzorg.info</p>
      </div>
    </div>
  )
}

export default UitgavenProblemen;
