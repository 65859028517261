import React from 'react';

const LogoFabriek = () => (
	<svg viewBox="0 0 700 100" className="logo-fabriek">
 <g>
    <rect x="31.2" y="31.2" width="94.88" height="68.61" fill="#009de0"/>
    <circle cx="31.2" cy="31.2" r="31.2" fill="#222221"/>
    <circle cx="31.2" cy="31.2" r="15.6" fill="#fff"/>
  </g>
  <path d="M88.31,82.21H76.23l-1.76,7.86H63.22L74.94,43H89.72l11.61,47H89.84ZM82.1,53.6,77.88,73.77h8.44L82.1,53.6Z" fill="#fff"/>
  <g>
    <path d="M140,46.44c3.62,0,7.35-.11,10.85-.11,11.79,0,18.67,4,18.67,20.88,0,14.59-6.65,20.66-19.72,20.66-3.62,0-6.88,0-9.8-.12V46.44Zm10.62,34.43c8.52,0,10.15-4.55,10.15-13.66,0-9.45-1.28-14.12-10.15-14.12h-2.45V80.52A6.22,6.22,0,0,0,150.62,80.87Z" fill="#222221"/>
    <path d="M186.33,55.66c5.48,0,11.78,3.27,11.78,14.24,0,1.63-.11,2.68-.11,4.66H181.54c0,4.67,1.29,7,5.25,7a41.55,41.55,0,0,0,9.69-1.64l1.63,5.6a31.38,31.38,0,0,1-12.37,2.8c-8.52,0-12.48-4.55-12.48-16.1,0-12.13,5.13-16.57,13.07-16.57Zm-4.79,12.6h0l8.52.12v-.12c0-3.26-1.05-6.18-4.2-6.18S181.54,64.88,181.54,68.26Z" fill="#222221"/>
    <path d="M223.9,80.28l-1.75,7.35h-8.63l10.73-41.42H235.8l10.62,41.42h-9l-1.75-7.35Zm5.84-26.14L225.3,73.51h8.75l-4.31-19.37Z" fill="#222221"/>
    <path d="M257.74,87.63h-8.05V56h6.65l.7,3.85c3.5-3.5,5.14-4.43,7.24-4.43h1.28v8.4h-1.63a8.66,8.66,0,0,0-6.19,2.1v21.7Z" fill="#222221"/>
    <path d="M280.61,99.07a57.29,57.29,0,0,1-8.05-.58l-1.16-6.3h7.81c6,0,6.31-2.81,6.31-6.31V83.2c-3.27,2.45-5.49,3.5-8.06,3.5-6.3,0-9.68-5.72-9.68-15.17,0-10.15,4.2-16,10.62-16,2.45,0,4.67,1.05,7.82,3.62l.7-3.38h6.65v30c0,10-5,13.3-13,13.3Zm-4.55-27.89c0,6.19,1.29,8.4,4,8.4,1.75,0,3.5-1,5.14-2.68V65c-1.64-1.64-2.92-2.45-4.67-2.45-2.69.35-4.44,2.33-4.44,8.63Z" fill="#222221"/>
    <path d="M311.07,81.1c1.75,0,3.39-1,5.25-2.68V56.24h8.05V87.75h-6.65l-.7-3.5c-3.61,2.92-6.18,3.85-9,3.85-5.49,0-8.29-4-8.29-10.15V56.24h8.05V74.92c.24,4.55,1.17,6.18,3.27,6.18Z" fill="#222221"/>
    <path d="M371.52,65.81V87.75h-8V69.08c0-4.9-.94-6.3-3.27-6.3-1.75,0-3.39.93-5.14,2.68V87.75H347V69.08c0-4.9-.93-6.3-3.26-6.3-1.76,0-3.39.93-5.14,2.68V87.75h-8.05V56.24h6.65l.7,3.5c3.62-2.91,6.19-3.85,8.75-3.85,3.39,0,5.72,1.29,6.89,4.21,4.2-3.27,6.42-4.21,9.22-4.21,6.06-.23,8.75,3.74,8.75,9.92Z" fill="#222221"/>
    <path d="M389.37,55.66c5.49,0,11.79,3.27,11.79,14.24,0,1.63-.12,2.68-.12,4.66H384.59c0,4.67,1.28,7,5.25,7a41.48,41.48,0,0,0,9.68-1.64l1.64,5.6a31.41,31.41,0,0,1-12.37,2.8c-8.52,0-12.49-4.55-12.49-16.1.12-12.13,4.9-16.57,13.07-16.57Zm-4.78,12.6h0l8.52.12v-.12c0-3.26-1.05-6.18-4.2-6.18s-4.32,2.8-4.32,6.18Z" fill="#222221"/>
    <path d="M419.71,62.9c-1.75,0-3.38.93-5.13,2.68V87.75h-8.05V56.24h6.65l.7,3.5c3.62-2.8,6.18-3.85,9-3.85,5.49,0,8.29,4,8.29,10.16V88h-8V69.2c-.24-4.79-1.17-6.3-3.39-6.3Z" fill="#222221"/>
    <path d="M437.33,79.82V62.43h-3.61v-6.3h3.61v-8.4l8.05-1v9.8h6v6.3h-6v16c0,2.8.7,3.5,5.49,2.68l.7,6.18a35.19,35.19,0,0,1-6.42,1.05C441.18,88.22,437.33,86.82,437.33,79.82Z" fill="#222221"/>
    <path d="M466.86,55.66c5.48,0,11.78,3.27,11.78,14.24,0,1.63-.11,2.68-.11,4.66H462.07c0,4.67,1.29,7,5.25,7A41.63,41.63,0,0,0,477,79.93l1.63,5.6a31.38,31.38,0,0,1-12.37,2.8c-8.52,0-12.48-4.55-12.48-16.1.11-12.13,5-16.57,13.07-16.57Zm-4.67,12.6h0l8.52.12v-.12c0-3.26-1-6.18-4.2-6.18s-4.32,2.8-4.32,6.18Z" fill="#222221"/>
    <path d="M497.08,62.9c-1.75,0-3.38.93-5.14,2.68V87.75h-8.05V56.24h6.65l.7,3.5c3.62-2.8,6.19-3.85,9-3.85,5.48,0,8.29,4,8.29,10.16V88h-7.94V69.2C500.35,64.41,499.41,62.9,497.08,62.9Z" fill="#222221"/>
    <path d="M534.3,63.13V70H523.45V87.75h-8.28V46.33h20.07v6.88h-11.9V63h10.85v.24h.11Z" fill="#222221"/>
    <path d="M548.19,88.22c-6.3,0-9.68-6.42-9.68-16,0-10.15,4.2-16.57,10.61-16.57,2.45,0,4.67,1.17,7.94,3.85l.7-3.38h6.42V87.75h-6.65l-.7-3.38c-3.74,2.8-5.84,3.85-8.64,3.85Zm-1.4-16.11c0,6.19,1.28,9,4,9,1.75,0,3.5-1,5.13-2.68V65.35c-1.63-1.64-2.91-2.45-4.67-2.45-2.68,0-4.43,2.68-4.43,9.21Z" fill="#222221"/>
    <path d="M596.38,71.53c0,10.15-4.2,16.57-10.26,16.57-2.45,0-4.67-1.17-7.94-3.62l-.93,3.39h-6.42V44.22l8-1.16v16.1c2.92-2.45,5.25-3.38,7.94-3.38,6.18-.12,9.56,6.3,9.56,15.75Zm-8.51.23c0-6.18-1.29-9-4-9-1.75,0-3.5,1-5.14,2.68V78.53c1.64,1.64,2.92,2.45,4.67,2.45,2.8.12,4.44-2.68,4.44-9.22Z" fill="#222221"/>
    <path d="M609.45,87.63H601.4V56h6.65l.7,3.85c3.5-3.5,5.14-4.43,7.24-4.43h1.28v8.4h-1.63a8.66,8.66,0,0,0-6.19,2.1v21.7Z" fill="#222221"/>
    <path d="M621.36,51.46V43.52h8v7.94Zm0,36.17V56h8V87.63Z" fill="#222221"/>
    <path d="M647.61,55.66c5.49,0,11.79,3.27,11.79,14.24,0,1.63-.12,2.68-.12,4.66H642.83c0,4.67,1.28,7,5.25,7a41.63,41.63,0,0,0,9.69-1.64l1.63,5.6A31.41,31.41,0,0,1,647,88.33c-8.52,0-12.49-4.55-12.49-16.1.24-12.13,5.14-16.57,13.07-16.57Zm-4.78,12.6h0l8.52.12v-.12c0-3.26-1.05-6.18-4.2-6.18C644.23,62.08,642.83,64.88,642.83,68.26Z" fill="#222221"/>
    <path d="M672.7,73.16V87.75h-8.05V44.22l8.05-1.16V68.5h0l6.19-12.37h9.68L679.82,69.2l10.27,18.32h-9.8L672.7,73.16Z" fill="#222221"/>
  </g>
	</svg>
);

export default LogoFabriek;
