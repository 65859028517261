import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import store from "./store";
import SocialHeader from "./components/SocialHeader/SocialHeader";
import 'react-tooltip/dist/react-tooltip.css'
import "./index.scss";
import "./styles/fonts.scss";
import App from "./components/App/App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const router = (
	<Provider store={store}>
		<Router>
			<div>
				<Routes>
					<Route path="/" element={<App />} />
					<Route exact path="/:category/:pageName" element={<App />} />
					<Route exact path="/empty" element={<SocialHeader />} />
					<Route exact path="/:pageName" element={<App />} />
				</Routes>
			</div>
		</Router>
	</Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(router);
serviceWorkerRegistration.unregister()
