import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const UitgavenCbs = () => {
	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: "."
	});

	const formatValueTooltip = val =>
		localeFormatter.format(",.1f")(val) + " miljard euro";
	const formatValueY = val => localeFormatter.format(",.1f")(val);

	const createTable = (data, className) => (
		<table className="tabel">
			<tbody>
				{data.map(row => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
			</tbody>
		</table>);

	const data = [
		{ key: "Ziekenhuiszorg", value:  29.1 },
		{ key: "Verpleging en verzorging", value:  20.2},
		{ key: "Gehandicaptenzorg", value: 11.4},
		{ key: "Geestelijke gezondheidszorg", value: 7.1},
		{ key: "Geneesmiddelen", value:  5.5},
		{ key: "Huisartsenpraktijken", value: 4.4},
		{ key: "Therapeutische middelen", value: 3.1},
		{ key: "Mondzorg", value:  3},
		{ key: "Paramedische zorg", value: 2.3},
		{ key: "Preventieve diensten", value:  2.2},
		{ key: "Ondersteuning", value: 1.5},
		{ key: "Overige zorg", value:  1.8},
	];

	return (
		<div className="vis-uitgaven-cbs">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 240 }}
				barHeight={30}
				domain={[0, 27]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
			/>

			{createTable(data)}

			<div className="vis-total">
				<p>Totaal</p>
				<p className="groot">91,6</p>
				<p>miljard euro in 2019</p>
			</div>

			<div className="vis-credit">
				<p>Uitgaven aan geneeskundige en langdurige zorg in miljard euro in 2019.</p>
				<p>Bron: CBS Statline, 2020</p>
			</div>
		</div>
	);
};

export default UitgavenCbs;
