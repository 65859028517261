import React from 'react';

// import * as harmonicas from "./harmonicas";
// import vissen from "./visualizations";

import waardenkaartzorg from '../../images/waardenkaart-zorg.png';
import mythenkaartZorg from '../../images/mythenkaart-zorg.png';
import zorgPages from './pages/zorg/pages'
import spelersPages from './pages/spelers/pages'
import wettenPages from './pages/wetten/pages'
import geldPages from './pages/geld/pages'
import Pijl from "../../components/Icons/Pijl";

const pages = [
	{
		categorie: "basis",
		template: "tegels",
		naam: "zo-werkt-de-zorg",
		titel: "Zó werkt de zorg",
		links: [
			"zorg",
			"spelers",
			"wetten",
			"geld",
			// "zorgmarkt",
			"specials",
			"zorgthemas-kort"
		],
		kleur: 0
	},
	{
		categorie: "basis",
		template: "menu",
		naam: "menu",
		titel: "Menu",
		kleur: 0,
		links: ["platform", "sitemap", "colofon"]
	},
	{
		categorie: "basis",
		template: "zoeken",
		naam: "zoeken",
		titel: "Zoekresultaten",
		kleur: 0
	},
	{
		categorie: "basis",
		template: "colofon",
		naam: "colofon",
		titel: "Colofon",
		kleur: 0
	},
	{
		categorie: "basis",
		template: "platform",
		naam: "platform",
		titel: "Over het platform",
		kleur: 0
	},
	{
		categorie: "basis",
		template: "sitemap",
		naam: "sitemap",
		titel: "Welke vragen beantwoordt deze app?",
		kleur: 0
	},
	{
		categorie: "basis",
		template: "begrippen",
		naam: "begrippen",
		titel: "Wat betekenen de vele afkortingen in de zorg?",
		kleur: 0
	},
	...zorgPages,
	...spelersPages,
	...wettenPages,
	...geldPages,
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "aantal-honderdjarigen",
		titel: "Hoeveel mensen worden ouder dan 99 jaar?",
		kleur: 6,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "gezonde-levensverwachting",
		titel: "Hoe lang leven we en hoe lang voelen we ons gezond?",
		kleur: 6,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "levensverwachting-regio",
		titel: "Hoe verschilt de gezonde levensverwachting tussen regio's?",
		kleur: 6,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "chronische-ziekte",
		titel: "Hoeveel mensen hebben één of meer chronische ziekten?",
		kleur: 6,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "intro",
		naam: "wie-doet-wat",
		titel: "Wie doet wat in de zorg?",
		kleur: 1,
		tekst:
			"Zorgaanbieders, zorginkopers, toezichthouders, adviesorganen, gezondheidsfondsen: tal van spelers zijn actief in de gezondheidszorg. De zorg biedt werk aan circa 1,2 miljoen mensen.",
		links: [
			"sectoren",
			"werknemers",
			// "hoofdrolspelers",
			// "andere-spelers",
			"ziekenhuizen-klinieken",
			"wie-doet-wat-kennis"
		]
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "sectoren",
		titel: "Hoe groot is de zorgsector?",
		kleur: 1,
		tekst: "",
		links: ["werknemers"]
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "werknemers",
		titel: "Waar werken de werknemers in de zorg?",
		kleur: 1,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "visualisatie",
	// 	naam: "hoofdrolspelers",
	// 	titel: "Wie zijn de hoofdrolspelers in de zorg?",
	// 	kleur: 1,
	// 	tekst: "",
	// 	links: ["wat-doen-hoofdrolspelers"]
	// },
	{
		categorie: "basis",
		template: "harmonica",
		naam: "wat-doen-hoofdrolspelers",
		titel: "Wat doen de hoofdrolspelers?",
		kleur: 1,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "visualisatie",
	// 	naam: "andere-spelers",
	// 	titel: "Wie zijn andere belangrijkse spelers in de zorg?",
	// 	kleur: 1,
	// 	tekst: "",
	// 	links: ["wat-doen-andere-spelers"]
	// },
	{
		categorie: "basis",
		template: "harmonica",
		naam: "wat-doen-andere-spelers",
		titel: "Wat doen de andere spelers?",
		kleur: 1,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "ziekenhuizen-klinieken",
		titel: "Hoeveel ziekenhuizen en klinieken zijn er in Nederland?",
		kleur: 1,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "wie-doet-wat-kennis",
		titel: "Wie doet wat aan kennisontwikkeling in de zorg?",
		kleur: 1,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "intro",
	// 	naam: "wetten",
	// 	titel: "Welke wetten regelen de zorg?",
	// 	kleur: 2,
	// 	tekst:
	// 		"Tientallen wetten regelen hoe de zorg wordt vormgegeven. Van specifiek toegesneden wetten tot algemene wetten die ook de zorg aangaan. Vijf stelselwetten bepalen hoe het zorgstelsel werkt.",
	// 	links: [
	// 		"achtergrond-stelselwetten",
	// 		"stelselwetten",
	// 		"welke-wetten",
	// 		"zorgwetten",
	// 		"algemene-wetten",
	// 		// "toezicht"
	// 	]
	// },
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "achtergrond-stelselwetten",
		titel: "Wat is de gedachte achter de stelselwetten?",
		kleur: 2,
		tekst: "",
		links: ["stelselwetten", "publieke-gezondheid", "zorg-wpg"]
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "publieke-gezondheid",
		titel: "Wat doet publieke gezondheid?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "zorg-wpg",
		titel: "Welke zorg wordt gegeven vanuit de Wet publieke gezondheid?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "stelselwetten",
	// 	titel: "Wat zijn de vijf stelselwetten?",
	// 	kleur: 2,
	// 	tekst: "",
	// 	links: ""
	// },
	{
		categorie: "basis",
		template: "harmonica",
		naam: "welke-wetten",
		titel: "Uit welke wetten krijgen burgers zorg?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "zorgwetten",
		titel: "Wat zijn belangrijke zorgwetten?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "algemene-wetten",
	// 	titel: "Wat zijn voor de zorg belangrijke algemene wetten?",
	// 	kleur: 2,
	// 	tekst: "",
	// 	links: ""
	// },
	// {
	// 	categorie: "basis",
	// 	template: "intro",
	// 	naam: "toezicht",
	// 	titel: "Hoe is het toezicht in de zorg geregeld?",
	// 	kleur: 2,
	// 	tekst:
	// 		"Op de zorg wordt toezicht gehouden. Met ‘toezicht’ bedoelen we hier meer dan de formele toezichtszaken. Denk bijvoorbeeld ook aan controle van kwaliteitsstandaarden of klachtenprocedures. Hoe werkt dit toezicht op de zorg die geleverd wordt vanuit de vijf stelselwetten?",
	// 	links: [
	// 		"toezicht-zvw",
	// 		"toezicht-wlz",
	// 		"toezicht-wmo",
	// 		"toezicht-jeugdwet",
	// 		"toezicht-wpg"
	// 	]
	// },
	{
		categorie: "basis",
		template: "harmonica",
		naam: "toezicht-zvw",
		titel: "Wie doet wat in het toezicht op zorg vanuit de Zvw?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "toezicht-wlz",
		titel: "Wie doet wat in het toezicht op zorg vanuit de Wlz?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "toezicht-wmo",
		titel: "Wie doet wat in het toezicht op zorg vanuit de Wmo?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "toezicht-jeugdwet",
		titel: "Wie doet wat in het toezicht op zorg vanuit de Jeugdwet?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "toezicht-wpg",
		titel: "Wie doet wat in het toezicht op zorg vauit de Wpg?",
		kleur: 2,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "intro",
	// 	naam: "kosten",
	// 	titel: "Wat kost de zorg?",
	// 	kleur: 3,
	// 	tekst:
	// 		"Goede gezondheid is ons veel waard. Elk jaar geven we meer geld uit aan gezondheidszorg. Hierdoor is er veel discussie over het betaalbaar houden van de zorg.",
	// 	links: ["uitgaven", "wie-betaalt", "geldstromen-ziekenhuis"]
	// },
	{
		categorie: "basis",
		template: "intro",
		naam: "uitgaven",
		titel: "Hoeveel geven we uit aan zorg?",
		kleur: 3,
		tekst:
			"Op de vraag hoeveel we uitgeven aan zorg zijn meerdere juiste antwoorden mogelijk. Het Centraal Bureau voor de Statistiek (CBS) en het ministerie van Volksgezondheid, Welzijn en Sport (VWS) hanteren verschillende definities van ‘zorguitgaven’. Het CBS verstaat onder zorg zowel gezondheidszorg als welzijnszorg. VWS gaat in de begroting alleen uit van zorg gefinancierd uit publieke middelen.",
		links: ["euros-vws", "euros-cbs", "euros-volwassene"]
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "euros-vws",
		titel: "Hoeveel geld heeft het Rijk begroot voor de zorg?",
		kleur: 3,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "euros-cbs",
		titel: "Waaraan geven we onze zorgeuro's uit volgens het CBS?",
		kleur: 3,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "euros-volwassene",
		titel: "Hoeveel betaalt een volwassene per saldo aan zorg?",
		kleur: 3,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "intro",
		naam: "wie-betaalt",
		titel: "Wie betaalt wat per stelselwet?",
		kleur: 3,
		tekst:
			"Met zijn allen betalen we het zorgstelsel. Onder andere via onze zorgverzekering en eigen bijdragen, maar ook via inkomensafhankelijke bijdragen. Daarnaast krijgen sommige mensen zorgtoeslag. Hoe komt het geld nu van burgers en het Rijk bij de zorgaanbieders? Welke instanties zijn hierbij betrokken en wie betaalt wie?",
		links: [
			// "geldstromen-zvw",
			// "geldstromen-wlz",
			"geldstromen-wmo",
			"geldstromen-jeugdwet",
			"geldstromen-wpg"
		]
	},
	{
		categorie: "basis",
		template: "visualisatie",
		// naam: "geldstromen-zvw",
		titel: "Hoe lopen de geldstromen in de Zorgverzekeringswet?",
		kleur: 3,
		tekst: "",
		links: [
			// "wie-betaalt-zvw",
		]
	},
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "wie-betaalt-zvw",
	// 	titel: "Wie betaalt wat in de Zorgverzekeringswet?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ""
	// },
	// {
	// 	categorie: "basis",
	// 	template: "visualisatie",
	// 	naam: "geldstromen-wlz",
	// 	titel: "Hoe lopen de geldstromen in de Wet langdurige zorg?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: [
	// 		// "wie-betaalt-wlz"
	// 	]
	// },
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "wie-betaalt-wlz",
	// 	titel: "Wie betaalt wat in de Wet langdurige zorg?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ""
	// },
	// {
	// 	categorie: "basis",
	// 	template: "visualisatie",
	// 	naam: "geldstromen-wmo",
	// 	titel:
	// 		"Hoe lopen de geldstromen in de Wet maatschappelijke ondersteuning?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: [
	// 		// "wie-betaalt-wmo",
	// 	]
	// },
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "wie-betaalt-wmo",
	// 	titel: "Wie betaalt wat in de Wet maatschappelijke ondersteuning?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ""
	// },
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "geldstromen-jeugdwet",
		titel: "Hoe lopen de geldstromen in de Jeugdwet?",
		kleur: 3,
		tekst: "",
		links: ["wie-betaalt-jeugdwet"]
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "wie-betaalt-jeugdwet",
		titel: "Wie betaalt wat in de Jeugdwet?",
		kleur: 3,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "visualisatie",
	// 	naam: "geldstromen-wpg",
	// 	titel: "Hoe lopen de geldstromen in de Wet publieke gezondheid?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ["wie-betaalt-wpg"]
	// },
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "wie-betaalt-wpg",
	// 	titel: "Wie betaalt wat in de Wet publieke gezondheid?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ""
	// },
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "geldstromen-ziekenhuis",
		titel: "Hoe lopen de geldstromen bij de inkoop van ziekenhuiszorg?",
		kleur: 3,
		tekst: "",
		links: [
			"wie-betaalt-ziekenhuiszorg",
			// "inkoop-ziekenhuiszorg"
		]
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "wie-betaalt-ziekenhuiszorg",
		titel: "Wie betaalt wat bij inkoop van ziekenhuiszorg?",
		kleur: 3,
		tekst: "",
		links: ""
	},
	// {
	// 	categorie: "basis",
	// 	template: "harmonica",
	// 	naam: "inkoop-ziekenhuiszorg",
	// 	titel: "Hoe werkt de inkoop van ziekenhuiszorg?",
	// 	kleur: 3,
	// 	tekst: "",
	// 	links: ""
	// },
	// {
	// 	categorie: "basis",
	// 	template: "intro",
	// 	naam: "zorgmarkt",
	// 	titel: "Hoe werkt de zorgmarkt?",
	// 	kleur: 4,
	// 	tekst:
	// 		"Het Nederlandse zorgstelsel kent marktwerking met concurrentie tussen zorgverzekeraars en zorgaanbieders. De overheid heeft de markt afgebakend met spelregels.",
	// 	links: [
	// 		"marktwerking",
	// 		"argumenten-marktwerking",
	// 		"zorginkoper",
	// 		"vergoeding",
	// 		"markten-curatieve-zorg"
	// 	]
	// },
	{
		categorie: "basis",
		template: "harmonica",
		naam: "marktwerking",
		titel: "Wat is marktwerking in de zorg?",
		kleur: 4,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "argumenten-marktwerking",
		titel: "Wat zijn argumenten voor en tegen marktwerking?",
		kleur: 4,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "zorginkoper",
		titel: "Wie is de zorginkoper per wet?",
		kleur: 4,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "harmonica",
		naam: "vergoeding",
		titel: "Wie bepaalt welke zorg vergoed wordt?",
		kleur: 4,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "visualisatie",
		naam: "markten-curatieve-zorg",
		titel: "Welke markten zijn er in de curatieve zorg?",
		kleur: 4,
		tekst: "",
		links: ""
	},
	{
		categorie: "basis",
		template: "intro",
		naam: "waarden",
		titel: "Waardenkaart",
		kleur: 5,
		tekst: <span><a href="https://waardenkaartgezondheidszorg.argumentenfabriek.nl" target="_blank" rel="noopener noreferrer"><img src={waardenkaartzorg} alt="Waardenkaart zorg" width="100%" /></a><p>De gezondheidszorg is een van de populairste onderwerpen van het maatschappelijk debat in Nederland. Maar wat zijn de onderliggende waarden op basis waarvan we debatteren?<br />In de <a href="https://waardenkaartgezondheidszorg.argumentenfabriek.nl" target="_blank" rel="noopener noreferrer">Waardenkaart van de gezondheidszorg</a> brengen we negen waardenclusters met in totaal 29 waarden in kaart. De waardenkaart is ook beschikbaar als <a href="https://etalage.argumentenfabriek.nl/waardenkaart-zorg/WaardenkaartZorg.pdf" target="_blank" rel="noopener noreferrer">digitaal boek</a>.</p></span>,
		links: ""
	},
	{
		categorie: "basis",
		template: "intro",
		naam: "mythenkaart-zorg",
		titel: "Mythenkaart over de zorg",
		kleur: 5,
		tekst: <span>
			<a href=" https://www.zowerktdezorg.nl/nieuws/mythen-in-de-zorg-ontkracht/" target="_blank" rel="noopener noreferrer">
				<img src={mythenkaartZorg} alt="Mythenkaart zorg" width="100%" />
			</a>
			<p>In de zorg gaan de nodige mythen rond, veelal doordat het zorgstelsel complex is en omdat de organisatie en het speelveld van de zorg in weinig opleidingen aan bod komt. De partners van Platform Zó werkt de zorg hebben met elkaar veelgehoorde mythen op een rij gezet met hierbij de feiten en verwijzingen naar openbare bronnen.</p>
			<ul className={"page-links"}>
				<li className={"link-color-1"}><a href="https://www.zowerktdezorg.nl/wp-content/uploads/2023/03/Mythenkaart-over-de-zorg-DEF.pdf" target="_blank" rel="noopener noreferrer"><span>Mythenkaart</span><Pijl /></a></li>
				<li className={"link-color-1"}><a href="https://www.zowerktdezorg.nl/wp-content/uploads/2024/02/Mythenkaart-2-3-test.pdf" target="_blank" rel="noopener noreferrer"><span>Mythenkaart 2</span><Pijl /></a></li>
			</ul>
		</span>,
	},
	{
		categorie: "basis",
		template: "specialindex",
		naam: "specials",
		titel: "Hoe werken specifieke delen van de zorg?",
		tekst:
			<span><p>In de Zó werkt de zorg-app brengen we in kaart hoe het Nederlandse zorgstelsel feitelijk in elkaar zit. De specials in deze tegel zoomen in op specifieke thema’s binnen de zorg.<br/><a href='/instructie-update-app-zwdz.pdf' target='_blank' rel='noopener noreferrer'>In deze pdf</a> lees je meer over het gebruik van de app.</p></span>,
		links: [
			"sociaal-domein",
			"ggz",
			"geneesmiddelenzorg",
			"gehandicaptenzorg",
			"zorgverzekering",
			"publieke-gezondheidszorg",
			"ouderenzorg",
			"huisartsenzorg",
			// "waarden",
		],
		kleur: 5
	},
	{
		categorie: "basis",
		template: "specialindex",
		naam: "zorgthemas-kort",
		titel: "Zorgthema's in het kort",
		tekst:
			<span><p>Helder overzicht bij actuele en interessante thema's, dat is het doel van onze Zó werkt de zorg-mini's en gidsen. De heldere informatie die je van Zó werkt de zorg gewend bent, maar dan in het kort.</p></span>,
		links: [
			"mini",
			"gidsen",
			"waarden",
			"mythenkaart-zorg"
		],
		kleur: 5
	}
];

// loop over pages to add harmonicas and visualizations
// pages.map(pagina => {
// 	pagina.categorie = 'basis'
//
// 	if (pagina.template === "harmonica" || pagina.template === "harmonica-met-intro") {
// 		const harmonicaNaam = pagina.naam.replace(/-/g, "_");
// 		pagina.harmonica = harmonicas[harmonicaNaam];
// 	}
// 	if (pagina.template === "visualisatie") {
// 		const visualisatieNaam = pagina.naam.replace(/-/g, "_");
// 		pagina.vis = vissen[visualisatieNaam];
// 	}
// 	return pagina;
// });

export default pages;
