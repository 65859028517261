import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import {formatLocale} from 'd3';

const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
});

const Bereidheid = () => {
    const formatValueY = val => `${localeFormatter.format(',.0f')(val)}%`
	const formatValueTooltip = val => `${localeFormatter.format(',.0f')(val)}%`

    const createTable = (data,className) => (
        <table className="tabel">
            <tbody>
            {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
            </tbody>
        </table>
    );

  const data2021 = [
      { key: 'Organisatie die medisch-wetenschappelijk onderzoek doen', value: 92 },
      { key: 'Organisaties die medische producten ontwikkelen', value: 62 },
      { key: 'Organisaties die niet-medische producten ontwikkelen', value: 18 },
      { key: 'Niemand', value: 3 },
  ];

	return (
		<div className="vis-bereidheid">
            <br/>
      <BarChart
        data={data2021}
        margin={{ top: 10, right: 0, bottom: 10, left: 420 }}
        barHeight={30}
        domain={[0,100]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-45}
      />

            {createTable(data2021)}

      <div className="vis-credit">
        <p>Percentage mensen dat aangeeft gegevens of lichaamsmateriaal te willen delen met bepaalde organisaties</p>
        <p>Monitor ‘delen van data in de gezondheidszorg’ van de Patiëntenfederatie.</p>
      </div>

      <br/>
	</div>
	)
}

export default Bereidheid;
