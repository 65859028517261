import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN, COLOR_GREEN, COLOR_MAGENTA, COLOR_PURPLE, COLOR_YELLOW} from "../../vocabulary";

const pages = [
  {
    categorie: "basis",
    template: "specialvoorpagina",
    naam: "sociaal-domein",
    titel: "Zó werkt het sociaal domein",
    kleur: COLOR_PURPLE,
    tekst:
      "Ruim twee miljoen mensen in Nederland krijgen hulp uit gemeentelijke voorzieningen. Deze gemeentelijke voorzieningen heten samen 'het sociaal domein'. Wat is de rol van de gemeente precies? Wie spelen nog meer een rol in het sociaal domein? Welke wetten zijn relevant voor het sociaal domein? En hoe wordt ondersteuning uit het sociaal domein betaald? Dit en meer vind je hieronder. ",
    links:
      [
        'wat-is',
        'belangrijkste-spelers',
        'andere-spelers',
        'wetten',
        'uitgaven',
      ]
  },
  {
    template: "intro",
    naam: "wat-is",
    titel: "Wat is het sociaal domein?",
    kleur: COLOR_GREEN,
    tekst:
      "Het sociaal domein raakt aan vele beleidsterreinen en biedt een breed scala aan ondersteuningsvormen. Wat zijn de belangrijkste?",
    links:
        [
          'afbakening',
          'beleidsterreinen',
          'ondersteuning-hoofdvormen',
          'effecten-betaald-werk'
        ]
  },
  {
    template: "harmonica",
    naam: "afbakening",
    titel: "Wat is het sociaal domein?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "beleidsterreinen",
    titel: "Welke beleidsterreinen raken aan het sociaal domein?",
    kleur: COLOR_GREEN,
    links: [
        'ondersteuning-hoofdvormen'
    ]
  },
  {
    template: "harmonica",
    naam: "ondersteuning-hoofdvormen",
    titel: "Wat zijn hoofdvormen van ondersteuning in het sociaal domein?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "effecten-betaald-werk",
    titel: "Wat zijn de effecten van betaald werk op gezondheid en welzijn?",
    kleur: COLOR_GREEN,
    links: [
      'ondersteuning-hoofdvormen'
    ]
  },
  {
    template: "intro",
    naam: "belangrijkste-spelers",
    titel: "Wie zijn de hoofdrolspelers in het sociaal domein en wat doen zij?",
    kleur: COLOR_MAGENTA,
    tekst:
        "Naast de gemeenten speelt nog een aantal partijen een belangrijke rol in het sociaal domein. Wie zijn dit en wat doen zij?",
    links:
        [
          'hoofdrolspelers',
          'hoofdrolspelers-taken',
          'mantelzorger-vrijwilliger-aantal',
          'spelers-gemeente',
          'sociale-wijkteams',
          'aanbieders',
          'professionals',
          'onafhankelijke-clientondersteuner',
          'beleid-stappen'
        ]
  },
  {
    template: "visualisatie",
    naam: "hoofdrolspelers",
    titel: "Wie zijn de hoofdrolspelers in het sociaal domein?",
    kleur: COLOR_MAGENTA,
    links: [
      'hoofdrolspelers-taken'
    ]
  },
  {
    template: "harmonica",
    naam: "hoofdrolspelers-taken",
    titel: "Wat doen de hoofdrolspelers in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "visualisatie",
    naam: "mantelzorger-vrijwilliger-aantal",
    titel: "Wie zijn de hoofdrolspelers in het sociaal domein?",
    kleur: COLOR_MAGENTA,
    links: [
      'hoofdrolspelers'
    ]
  },
  {
    template: "harmonica",
    naam: "spelers-gemeente",
    titel: "Wie spelen bij de gemeente een rol in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "visualisatie",
    naam: "sociale-wijkteams",
    titel: "Welke taken voeren sociale (wijk)teams uit?",
    kleur: COLOR_MAGENTA,
    links: [
      'spelers-gemeente'
    ]
  },
  {
    template: "harmonica",
    naam: "aanbieders",
    titel: "Welke aanbieders werken zoal in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "professionals",
    titel: "Welke professionals werken zoal in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "onafhankelijke-clientondersteuner",
    titel: "Wat is een onafhankelijke cliëntondersteuner?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "visualisatie",
    naam: "beleid-stappen",
    titel: "In welke stappen komt sociaaldomeinbeleid tot stand?",
    kleur: COLOR_MAGENTA,
    links: [
      'beleidsterreinen'
    ]
  },
  {
    template: "intro",
    naam: "andere-spelers",
    titel: "Wie spelen nog meer een rol in het sociaal domein en wat doen zij?",
    kleur: COLOR_MAGENTA,
    tekst:
        "Verschillende organisaties dragen bij aan de kwaliteit van ondersteuning in het sociaal domein. Door te adviseren, kennis te delen, of toezicht te houden. Wie zijn deze organisaties en wat is hun rol?",
    links:
        [
          'wettelijke-vertegenwoordiging',
          'vertegenwoordiging-clienten',
          'vertegenwoordiging-aanbieders-gemeenten',
          'organisaties-kennis',
          'organisaties-advies-overheid',
          'toezicht'
        ]
  },
  {
    template: "harmonica",
    naam: "wettelijke-vertegenwoordiging",
    titel: "Wat zijn vormen van wettelijke vertegenwoordiging?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "vertegenwoordiging-clienten",
    titel: "Welke organsiaties vertegenwoordigen zoal burgers en professionals in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "vertegenwoordiging-aanbieders-gemeenten",
    titel: "Welke organisaties vertegenwoordigen zoal aanbieders en gemeenten in het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "organisaties-kennis",
    titel: "Welke organisaties ontwikkelen zoal kennis over het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "organisaties-advies-overheid",
    titel: "Welke organisaties adviseren de overheid zoal over het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "harmonica",
    naam: "toezicht",
    titel: "Wie houden toezicht op het sociaal domein?",
    kleur: COLOR_MAGENTA,
  },
  {
    template: "intro",
    naam: "wetten",
    titel: "Wat zijn de relevante wetten in het sociaal domein en wat regelen zij?",
    kleur: COLOR_YELLOW,
    tekst:
        "Ondersteuning vanuit het sociaal domein wordt geregeld door drie stelselwetten: de Wet maatschappelijke ondersteuning, de Jeugdwet en de Participatiewet. Wat regelen deze wetten voor het sociaal domein en wat zijn de doelen?",
    links:
        [
          'stelselwetten-doelen',
          'wijzigingen-18-jaar',
          'aantal-jeugdwet',
          'aantal-participatiewet',
          'aantal-bijstandsuitkering',
          'route',
          'aantal-huishoudens',
          'wetten-overig'
        ]
  },
  {
    template: "harmonica",
    naam: "stelselwetten-doelen",
    titel: "Wat zijn de doelen van de sociaaldomeinwetten en wat regelen ze?",
    kleur: COLOR_YELLOW,
  },
  {
    template: "harmonica",
    naam: "wijzigingen-18-jaar",
    titel: "Wat zijn voor kwetsbare jongeren belangrijke veranderingen als ze 18 worden?",
    kleur: COLOR_YELLOW,
  },
  {
    template: "visualisatie",
    naam: "aantal-jeugdwet",
    titel: "Hoeveel kinderen en jongeren maken gebruik van een vorm van jeugdzorg uit de Jeugdwet?",
    kleur: COLOR_YELLOW,
    links: [
      'wijzigingen-18-jaar'
    ]
  },
  {
    template: "visualisatie",
    naam: "aantal-participatiewet",
    titel: "Hoeveel mensen krijgen ondersteuning bij werk uit de Participatiewet?",
    kleur: COLOR_YELLOW,
    links: [
      'stelselwetten-doelen'
    ]
  },
  {
    template: "visualisatie",
    naam: "aantal-bijstandsuitkering",
    titel: "Hoeveel mensen ontvangen een bijstandsuitkering, uitgesplitst naar geslacht en uitkeringsduur?",
    kleur: COLOR_YELLOW,
    links: [
      'aantal-participatiewet'
    ]
  },
  {
    template: "harmonica",
    naam: "route",
    titel: "Welke route doorlopen mensen naar ondersteuning en zorg uit het sociaal domein?",
    kleur: COLOR_YELLOW,
  },
  {
    template: "visualisatie",
    naam: "aantal-huishoudens",
    titel: "Hoeveel duizend huishoudens maken gebruik van voorzieningen uit één of meerdere sociaaldomeinwetten?",
    kleur: COLOR_YELLOW,
    links: [
      'stelselwetten-doelen'
    ]
  },
  {
    template: "harmonica",
    naam: "wetten-overig",
    titel: "Welke andere wetten zijn relevant voor het sociaal domein?",
    kleur: COLOR_YELLOW,
  },
  {
    template: "intro",
    naam: "uitgaven",
    titel: "Wie betaalt wat in het sociaal domein?",
    kleur: COLOR_CYAAN,
    tekst:
        "Voor 2021 hebben gemeenten 26,2 miljard voor sociaaldomeintaken begroot. Waar komt dit geld vandaan en hoe verdelen gemeenten dit geld over maatschappelijke ondersteuning, jeugdzorg en ondersteuning bij werk en inkomen?",
    links:
        [
          'gemeenten-uitgaven',
          'gemeenten-inkomsten',
          'gemeenten-begroting',
          'geldstromen-wmo',
          'wie-betaalt-wmo',
          'geldstromen-jeugdwet',
          'wie-betaalt-jw',
          'geldstromen-participatiewet',
          'wie-betaalt-participatiewet',
          'wanneer-betalen-ondersteuning'
        ]
  },
  {
    template: "visualisatie",
    naam: "gemeenten-uitgaven",
    titel: "Hoeveel geld geven gemeenten uit aan het sociaal domein?",
    kleur: COLOR_CYAAN,
    links: [
      'gemeenten-begroting'
    ]
  },
  {
    template: "visualisatie",
    naam: "gemeenten-inkomsten",
    titel: "Welke inkomstenbronnen hebben gemeenten?",
    kleur: COLOR_CYAAN,
    links: [
      'gemeenten-uitgaven'
    ]
  },
  {
    template: "visualisatie",
    naam: "gemeenten-begroting",
    titel: "Hoeveel geld hebben gemeenten begroot voor het sociaal domein?",
    kleur: COLOR_CYAAN,
    links: [
      'gemeenten-inkomsten'
    ]
  },
  {
    template: "visualisatie",
    naam: "geldstromen-wmo",
    titel: "Hoe lopen de geldstromen in de Wet maatschappelijke ondersteuning?",
    kleur: COLOR_CYAAN,
    links: [
      'wie-betaalt-wmo'
    ]
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-wmo",
    titel: "Wie betaalt wat in de Wet maatschappelijke ondersteuning?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-jw",
    titel: "Wie betaalt wat in de Jeugdwet?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "visualisatie",
    naam: "geldstromen-jeugdwet",
    titel: "Hoe lopen de geldstromen in de Jeugdwet?",
    kleur: COLOR_CYAAN,
    links: [
      'wie-betaalt-jw'
    ]
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-participatiewet",
    titel: "Wie betaalt wat in de Participatiewet?",
    kleur: COLOR_CYAAN,
  },
  {
    template: "visualisatie",
    naam: "geldstromen-participatiewet",
    titel: "Hoe lopen de geldstromen in de Participatiewet?",
    kleur: COLOR_CYAAN,
    links: [
      'wie-betaalt-participatiewet'
    ]
  },
  {
    template: "harmonica",
    naam: "wanneer-betalen-ondersteuning",
    titel: "Wanneer betalen mensen voor ondersteuning uit het sociaal domein?",
    kleur: COLOR_CYAAN,
  },
]

pages.forEach(page => {
  if (!page.hasOwnProperty('categorie')) {
    page.categorie = "sociaal-domein"
  }
})

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
