import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "harmonica",
  naam: "verzamelen",
  titel: "Wat zijn medische gegevens en wat zijn de regels en rechten voor zorgverleners en patiënten omtrent het verzamelen hiervan?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links: [
    'delen',
    'bereidheid'
  ]
}
