import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/';
import Page from '../Page/Page';

function mapStateToProps(state) {
  return {
    pages: state.pages,
    menuopen: state.menuopen,
    searchstring: state.searchstring,
    searchresults: state.searchresults,
    register: state.register,
    login: state.login,
    access: state.access,
    organisation: state.organisation
  }
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispachToProps)(Page)
