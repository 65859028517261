import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';

const BegrotingZorg = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
  });

  const formatValueTooltip = val => localeFormatter.format(',.1f')(val) + ' miljard euro';
  const formatValueY = val => localeFormatter.format(',.1f')(val);
  
  const createTable = (data, className) => (
    <table className="tabel">
      <tbody>
        {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
      { key: 'Zorgverzekeringswet', value: 50.9 },
      { key: 'Wet langdurige zorg', value: 27.6 },
      { key: 'Wmo*', value: 1.5 },
      { key: 'Overig**', value: 6.7 },
  ];

  return (
    <div className="vis-begroting-zorg">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 210 }}
        barHeight={30}
        domain={[0,47]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">86,7</p>
        <p>miljard euro in 2021</p>
      </div>

      <div className="vis-credit">
        <p>Begrote uitgaven aan zorg voor 2021 in miljard euro. (bron: Rijksbegroting VWS en Miljoenennota)</p>
        <p>* Wmo Beschermd wonen, de bijdragen van het Rijk aan gemeenten voor uitvoering van de Wet maatschappelijke ondersteuning en Jeugdwet zijn naar het Gemeentefonds overgeheveld en vallen niet onder 'zorg' op de Rijksbegroting.</p>
        <p>** Waaronder uitgaven voor publieke gezondheid en zorgbreed beleid.</p>
      </div>
    </div>
  )
}

export default BegrotingZorg;

