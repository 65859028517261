import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const PreferentiePerVerzekeraar = () => {
	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: "."
	});

	const formatValueTooltip = val =>
		localeFormatter.format(",.0f")(val) + " geneesmiddelen";
	const formatValueY = val => Math.round(val);

	const createTable = (data,className) => (
		<table className="tabel">
			<tbody>
				{data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
			</tbody>
		</table>
	);

	const data = [
		{ key: "Zilveren Kruis", value: 290 },
		{ key: "VGZ", value: 248 },
		{ key: "Eno *", value: 248 },
		{ key: "Zorg en Zekerheid", value: 248 },
		{ key: "CZ", value: 98 },
		{ key: "Menzis", value: 90 },
		{ key: "a.s.r. **", value: 0 },
		{ key: "DSW **", value: 0 },
		{ key: "iptiQ **", value: 0 },
		{ key: "ONVZ ***", value: 0 },
	];

	return (
		<div className="vis-preferentie-per-verzekeraar">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 170 }}
				barHeight={30}
				domain={[0, 290]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				// marginBeforeValue={-50}
				padding={0.2}
				ticks={0}
			/>

			{createTable(data)}

			<div className="vis-credit">
				<p>* Zorg en Zekerheid en Eno volgen het preferentiebeleid van VGZ.</p>
				<p>** a.s.r., DSW en iptiQ werken met een laagsteprijsgarantie.</p>
				<p>*** ONVZ heeft geen preferentiebeleid.</p>
				<p>Aantal geneesmiddelen dat onder het preferentiebeleid van zorgverzekeraars valt.</p>
				<p>Bron: Z-index, 2019.</p>
			</div>
		</div>
	);
};

export default PreferentiePerVerzekeraar;
