import intro from './intro'
import watIsHuiselijkGeweld from './wat-is-huiselijk-geweld'
import hoeveelMeldingen from './hoeveel-meldingen'
import spelersAanpak from './spelers-aanpak'
import colofon from './colofon'

export default {
  intro,
  watIsHuiselijkGeweld,
  hoeveelMeldingen,
  spelersAanpak,
  colofon,
}
