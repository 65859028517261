import React from 'react';
import {formatLocale} from 'd3'
import BarChart from '../../../components/BarChart/BarChart';

const TienBeroepen = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatValueTooltip = (val) => `${localeFormatter.format(',.0f')(val)} zorgprofessionals`;

  const createTable = (data) => (
    <table className="tabel">
      <tbody>
      {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueTooltip(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
    { key: 'Verpleegkundige in ggz', value: 18500 },
    { key: 'Huisarts', value: 12447 },
    { key: 'Gz-psycholoog (in ggz)', value: 10649 },
    { key: 'POH-GGZ', value: 7384 },
    { key: 'Psychiater', value: 3527 },
    { key: 'Klinisch psycholoog', value: 2069 },
    { key: 'Psychotherapeut', value: 1913 },
    { key: 'Verpleegkundig specialist ggz', value: 954 },
    { key: 'Klinisch geriater', value: 285 },
    { key: 'Klinisch neuropsycholoog', value: 139 },
  ];

  return (
    <div className="vis-tien-beroepen">
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, bottom: 10, left: 250 }}
        barHeight={30}
        domain={[0,18500]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={val => localeFormatter.format(',.1f')(val / 1000)}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-credit">
        <p>Aantal duizend zorgprofessionals in de geestelijke gezondheidszorg naar beroep in 2018.</p>
        <p>Bron: Capaciteitsorgaan, NIVEL, CBS, StaatVenZ</p>
      </div>

    </div>
  )
}

export default TienBeroepen;
