import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"wat-is-een-a-infectieziekte",
  titel:"Wat is een A-infectieziekte?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links: [
    'wie-doet-wat',
    'dwingende-maatregelen',
  ]
}
