import React from 'react';
import { formatLocale } from 'd3';
import Donut from '../../../components/Donut/Donut';
import BarChart from '../../../components/BarChart/BarChart';

const BedrijfskostenZorgverzekeraars = () => {

	const localeFormatter = formatLocale({
	"decimal": ",",
	"thousands": ".",
	"grouping": [3],
	"comma": true,
	});

	const dataTotaal = [
		{ key: "Zorgkosten", value: 39.8 },
		{ key: "Bedrijfskosten", value: 1.2 }
	];

	const dataBedrijfskosten = [
		{key: "Reclame", value: 2},
		{key: "Niet-verhaalbare zorgkosten", value: 6},
		{key: "Schadebehandelingskosten", value: 17},
		{key: "Vergoedingen aan intermediairs", value: 17},
		{key: "Overige kosten, zoals personeel, huisvesting en automatisering", value: 59}
	];

	const formatDonutTotal = item => {
		let total = 0;
		dataTotaal.forEach(function(item) {
			total += item.value;
			//console.log("total: " + total);
			return total;
		}) 
	};
	const formatDonutLabel = item => {
		const shortLabel = item.key.split(' ')[0];
		return shortLabel + ': (' + item.value + ' miljard)';
	}
	const formatDonutTT = d => localeFormatter.format(',.1f')(d.value) + ' miljard euro';
	// const formatDonutVal = val => localeFormatter.format(',.1f')(val) + ' miljard';

	const barChartMargin = {top: 0, right: 0, bottom: 0, left: 465};
	const formatBarChartTT = val => localeFormatter.format(',.0%')(val/100);
	const formatBarChartY = val => localeFormatter.format(',.0%')(val/100);

	const createBarTable = (data) => (
		<table className="tabel">
			<tbody>
				{data.map(row => <tr key={row.key}><td>{row.key}</td><td>{formatBarChartY(row.value)}</td></tr>)}
			</tbody>
		</table>);

	return (
		<div className="vis-bedrijfskosten-zorgverzekeraars">
		<h4>Alle uitgaven 41,0 miljard euro</h4>
		<Donut
			data={dataTotaal}
			height={400}
			fontFamily="din-regular"
			fontSize="15px"
			formatLabel={formatDonutLabel}
			formatTotal={formatDonutTotal}
			formatTooltip={formatDonutTT}
			unit="%"
			animation={true}
		/>

		<h4>Bedrijfskosten</h4>

      <BarChart
        data={dataBedrijfskosten}
        margin={barChartMargin}
        barHeight={30}
        domain={[0,59]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatBarChartTT}
        formatValueY={formatBarChartY}
        padding={0.2}
        ticks={0}
      />

		{createBarTable(dataBedrijfskosten, formatBarChartY)}

		<div className="vis-credit">
			<p>Kosten in euros in 2017.</p>
			<p>Bron: Ministerie VWS, 2017.</p>
		</div>

		</div>
	);
}

export default BedrijfskostenZorgverzekeraars;


