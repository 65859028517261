import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_YELLOW} from "../../../../vocabulary";

const pages = [
    {
        categorie: "basis",
        template: "intro",
        naam: "wetten",
        titel: "Welke wetten regelen de zorg?",
        kleur: COLOR_YELLOW,
        tekst:
            "Tientallen wetten regelen hoe de zorg wordt vormgegeven. Van specifieke zorgwetten tot algemene wetten die ook de zorg aangaan. Vijf stelselwetten bepalen hoe het zorgstelsel werkt.",
        links: [
            "stelselwetten",
            "wat-is-publieke-gezondheidszorg",
            "vergoeding-per-wet",
            "zorg-per-wet",
            "preventie-wetten",
            "algemene-wetten",
            "zorgwetten-1",
            "zorgwetten-2",
        ]
    },
    {
        template: "harmonica",
        naam: "stelselwetten",
        titel: "Wat zijn de vijf stelselwetten en wat regelen ze?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "wat-is-publieke-gezondheidszorg",
        titel: "Wat is publieke gezondheidszorg?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "vergoeding-per-wet",
        titel: "Wie bepaalt welke zorg per wet vergoed wordt?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "zorg-per-wet",
        titel: "Hoe krijgen mensen zorg uit welke wet?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "visualisatie",
        naam: "preventie-wetten",
        titel: "Welke wet regelt welke vorm van preventie?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "algemene-wetten",
        titel: "Wat zijn voor de zorg belangrijke algemene wetten?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "zorgwetten-1",
        titel: "Welke zorgwetten regelen beroepsuitoefening, organisatie en marktwerking in de zorg?",
        kleur: COLOR_YELLOW,
        links: []
    },
    {
        template: "harmonica",
        naam: "zorgwetten-2",
        titel: "Welke zorgwetten regelen bijzondere situaties?",
        kleur: COLOR_YELLOW,
        links: []
    },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
    pagina.categorie = 'basis'

    if (pagina.template === "harmonica") {
        const harmonicaNaam = pagina.naam.replace(/-/g, "_");
        pagina.harmonica = harmonicas[harmonicaNaam];
    }
    if (pagina.template === "visualisatie") {
        const visualisatieNaam = pagina.naam.replace(/-/g, "_");
        pagina.vis = vissen[visualisatieNaam];
    }
    return pagina;
});


export default pages
