import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenZvw = () => (
	<div className="vis-geldstromen-zvw-huisartsen svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 660 852">
			<g className="geldstromen">
				<path data-tooltip-content="react-tooltip" id="tt1" d="M554.63,466.91l-1.54-1.54C544.33,456.6,528.39,450,516,450H147c-12,0-28.46,6.3-37.4,14.35l-3.24,2.92C97,475.66,90,491.45,90,504v18l9-9,9,9V504c0-7.32,5-18.45,10.4-23.35l3.24-2.92C127.3,472.64,139.39,468,147,468H516c7.47,0,19.08,4.81,24.37,10.09l1.54,1.54C547.59,485.32,552,495,552,499.5V513h0l9,9,9-9h0V499.5C570,489.68,563.4,475.67,554.63,466.91Z"/>
				<path data-tooltip-content="react-tooltip" id="tt25" d="M507,477H153a27,27,0,0,0-27,27v18l9-9,9,9V504a9,9,0,0,1,9-9H507c5.5,0,9,3,9,4.5V513h0l9,9,9-9h0V499.5C534,487.09,521.89,477,507,477Z"/>
				<g data-tooltip-content="react-tooltip" id="tt3">
					<polygon points="486 546 486 546 165 546 174 555 165 564 165 564 486 564 486 564 495 555 486 546"/>
					<path d="M339,516l-9-9-9,9h0v21a9,9,0,0,1-9,9H258v18h54a27,27,0,0,0,27-27V516h0Z"/>
				</g>
				<g data-tooltip-content="react-tooltip" id="tt4">
					<path d="M606,597l-9-9-9,9h0v78a9,9,0,0,1-9,9H99a9,9,0,0,1-9-9V588h0l-9,9-9-9h0v87a27,27,0,0,0,27,27H579a27,27,0,0,0,27-27V597h0Z"/>
					<path d="M339,654l-9-9-9,9h0v21a9,9,0,0,1-9,9H258v18h54a27,27,0,0,0,27-27V654h0Z"/>
				</g>
				<path data-tooltip-content="react-tooltip" id="tt6" d="M570,597l-9-9-9,9h0v13.5c0,1.54-3.5,4.5-9,4.5H330v18H543c14.89,0,27-10.09,27-22.5V597h0Z"/>
				<path  data-tooltip-content="react-tooltip" id="tt37" d="M632.87,450.88,457.18,105c-6.07-12-20.12-21-32.68-21H408l9,9-9,9h16.5c5.75,0,13.67,5.3,16.63,11.12L616.82,459c3.82,7.53,7.18,21.53,7.18,30v24h0l9,9,9-9h0V489C642,477.7,638,461,632.87,450.88Z"/>
				<path data-tooltip-content="react-tooltip" id="tt8" d="M243,93l9-9H235.5c-12.56,0-26.6,9-32.68,21L27.13,450.88C22,461,18,477.7,18,489v24h0l9,9,9-9h0V489c0-8.44,3.35-22.45,7.18-30l175.7-345.9c3-5.82,10.88-11.12,16.63-11.12H252Z"/>
				<polygon data-tooltip-content="react-tooltip" id="tt9" points="327 126 318 135 309 126 309 126 309 165 309 165 318 174 327 165 327 165 327 126 327 126"/>
				<polygon data-tooltip-content="react-tooltip" id="tt10" points="350.2 135 341.2 126 332.2 135 332.2 135 332.2 135 332.2 174 341.2 165 350.2 174 350.2 135 350.2 135 350.2 135"/>
				<polygon data-tooltip-content="react-tooltip" id="tt11" points="339 240 330 249 321 240 321 240 321 279 321 279 330 288 339 279 339 279 339 240 339 240"/>
				<path data-tooltip-content="react-tooltip" id="tt12" d="M595.59,450.92l-137.69-233C451.2,206.56,436.83,198,424.5,198H408l9,9-9,9h16.5c5.95,0,14.48,5.28,17.91,11.08l137.69,233c4.21,7.13,7.91,20.64,7.91,28.92v24h0l9,9,9-9h0V489C606,477.53,601.43,460.8,595.59,450.92Z"/>
				<path data-tooltip-content="react-tooltip" id="tt1314" d="M243,321l9-9H235.5c-11.47,0-26.83,6.44-35.71,15l-130,125c-9,8.63-15.74,24.52-15.74,37v24h0l9,9,9-9h0V489c0-7.42,4.87-18.85,10.21-24l130-125c5.42-5.21,16.5-10,23.24-10H252Z"/>


			</g>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Burgers'}} >
				<g className="kader link">
					<path  d="M270,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
					<text className="tekst centreer" x="330" y="100">Burgers</text>
				</g>
			</Link>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Rijk'}} >
				<g className="kader link">
					<path  d="M270,232.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
					<text className="tekst centreer" x="330" y="214">Rijk</text>
				</g>
			</Link>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Zorginstituut'}} >
				<g className="kader link">
					<path  d="M270,346.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
					<text className="tekst centreer">
						<tspan x="330" y="319">Zorginstituut</tspan>
						<tspan x="330" y="337">Nederland</tspan>
					</text>
				</g>
			</Link>


			<g className="kader">
				<path  d="M513,580.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H513z"/>
				<text  className="tekst centreer" x="580" y="561">Huisartsen</text>
			</g>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Zorggroepen'}} >
				<g className="kader link">
					<path  d="M258,499.5c-5.79,0-10.5-4.71-10.5-10.5v-6c0-5.79,4.71-10.5,10.5-10.5h144c5.79,0,10.5,4.71,10.5,10.5v6 c0,5.79-4.71,10.5-10.5,10.5H258z"/>
					<text className="tekst centreer" x="330" y="492">Zorggroepen</text>
				</g>
			</Link>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Huisartsenposten'}} >
				<g className="kader link">
					<path  d="M258,637.5c-5.79,0-10.5-4.71-10.5-10.5v-6c0-5.79,4.71-10.5,10.5-10.5h144c5.79,0,10.5,4.71,10.5,10.5v6 c0,5.79-4.71,10.5-10.5,10.5H258z"/>
					<text className="tekst centreer" x="330" y="630">Huisartsenposten</text>
				</g>
			</Link>

			<Link to={{ pathname: '/huisartsenzorg/wie-betaalt-wat', hash: '#Zorgverzekeraars'}} >
				<g className="kader link">
					<path  d="M15,580.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H15z"/>
					<text className="tekst centreer" x="80" y="561">Zorgverzekeraars</text>
				</g>
			</Link>

			<g className="tekst links regular">
				<text transform="matrix(1 0 0 1 45.0001 732.0099)">
					<tspan x="0" y="0">Segment 1</tspan>
					<tspan x="0" y="18">Segment 2</tspan>
					<tspan x="0" y="36">Segment 3</tspan>
					<tspan x="0" y="54">Buitensegment</tspan>
					<tspan x="0" y="72">Vergoeding segment 2</tspan>
					<tspan x="0" y="90">Vergoeding buitensegment</tspan>
					<tspan x="0" y="108">Eigen betalingen</tspan>
					<tspan x="319.5" y="0">Nominale premie</tspan>
					<tspan x="319.5" y="18">Inkomensafhankelijke bijdrage</tspan>
					<tspan x="319.5" y="36">Zorgtoeslag</tspan>
					<tspan x="319.5" y="54">Rijksbijdrage</tspan>
					<tspan x="319.5" y="72">Rijksbijdrage</tspan>
					<tspan x="319.5" y="90">Kinderpremie</tspan>
					<tspan x="319.5" y="108">Risicoverevening</tspan>
				</text>
			</g>
			<g className="tekstlabels wit">
				<path d="M526.6,282.24a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,526.6,282.24Zm11.29-2.8-3.63,7.63h2.58l3.81-8V277.4h-7v2Z"/>
				<path d="M532.07,379a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,532.07,379Zm3.77,2.79v2H542v-2h-1.76V374h-2l-2.54,1v2.1l2.16-.74v5.4Zm9.87-7.92a6.19,6.19,0,0,0-2.81.69v2.11a5.7,5.7,0,0,1,2.41-.73q1.3,0,1.3,1a2.15,2.15,0,0,1-.25.94,6.53,6.53,0,0,1-.73,1.13q-.49.62-1.21,1.4t-1.68,1.76v1.63h6.67v-2h-2.26q-.68,0-1.44.07v0q.84-.83,1.48-1.49a10.83,10.83,0,0,0,1.05-1.25,4.87,4.87,0,0,0,.63-1.18,3.72,3.72,0,0,0,.21-1.25,2.7,2.7,0,0,0-.26-1.21,2.49,2.49,0,0,0-.71-.87,3.1,3.1,0,0,0-1.06-.53A4.76,4.76,0,0,0,545.71,373.88Z"/>
				<path d="M148.27,379a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,148.27,379Zm4.13,2.79v2h6.14v-2h-1.76V374h-2l-2.54,1v2.1l2.16-.74v5.4Zm13-5.29a2.31,2.31,0,0,0-.91-1.92,4.18,4.18,0,0,0-2.62-.71,7.71,7.71,0,0,0-2.43.41v2.06a5.66,5.66,0,0,1,1-.31,5.29,5.29,0,0,1,1.08-.11,2.07,2.07,0,0,1,1,.22.75.75,0,0,1,.39.7.84.84,0,0,1-.13.47,1,1,0,0,1-.35.32,1.71,1.71,0,0,1-.5.18,3,3,0,0,1-.6.06h-.91v1.95h1.22a3.43,3.43,0,0,1,.59,0,1.31,1.31,0,0,1,.47.17.89.89,0,0,1,.31.32,1,1,0,0,1,.11.5,1,1,0,0,1-.42.83,2.16,2.16,0,0,1-1.27.3,5.47,5.47,0,0,1-1.21-.13,4.76,4.76,0,0,1-1-.32v2.09a6.22,6.22,0,0,0,2.3.41,4.7,4.7,0,0,0,3-.83,2.76,2.76,0,0,0,1-2.27,2.24,2.24,0,0,0-.42-1.42,2.12,2.12,0,0,0-1.09-.74v0a2.12,2.12,0,0,0,1-.91A2.78,2.78,0,0,0,165.41,376.52Z"/>
				<path d="M129.68,397.6a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,129.68,397.6Zm3.95,2.79v2h6.14v-2H138v-7.78h-2l-2.54,1v2.1l2.16-.74v5.4Zm11.48-7.63h-2.38L140.41,399v1.56h3.95v1.89h2.27v-1.89h1.13v-2h-1.12v-1.46h-2.27v1.46H144a11.89,11.89,0,0,0-1.27.06v0a16.09,16.09,0,0,0,.91-2Z"/>
				<path d="M112.74,282.24a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,112.74,282.24Zm10.41-5.13a5.66,5.66,0,0,0-1.55.19,3.41,3.41,0,0,0-1.15.55,2.41,2.41,0,0,0-.71.86,2.47,2.47,0,0,0-.25,1.11,2.19,2.19,0,0,0,.4,1.33A2.66,2.66,0,0,0,121,282v0a2.9,2.9,0,0,0-.66.34,2.42,2.42,0,0,0-.53.5,2.27,2.27,0,0,0-.36.67,2.64,2.64,0,0,0-.13.85,2.7,2.7,0,0,0,.26,1.19,2.5,2.5,0,0,0,.75.91,3.53,3.53,0,0,0,1.21.57,6.9,6.9,0,0,0,3.29,0,3.53,3.53,0,0,0,1.21-.57,2.5,2.5,0,0,0,.75-.91,2.7,2.7,0,0,0,.26-1.19,2.64,2.64,0,0,0-.13-.85,2.27,2.27,0,0,0-.36-.67,2.42,2.42,0,0,0-.53-.5,2.9,2.9,0,0,0-.66-.34v0a2.66,2.66,0,0,0,1.07-.85,2.19,2.19,0,0,0,.4-1.33,2.47,2.47,0,0,0-.25-1.11,2.41,2.41,0,0,0-.71-.86,3.41,3.41,0,0,0-1.15-.55A5.66,5.66,0,0,0,123.14,277.11Zm0,8.17a1.53,1.53,0,0,1-1-.29.9.9,0,0,1-.35-.71,1.14,1.14,0,0,1,.4-.91,1.18,1.18,0,0,1,1.92,0,1.14,1.14,0,0,1,.4.91.9.9,0,0,1-.35.71A1.53,1.53,0,0,1,123.14,285.28Zm0-4a1.61,1.61,0,0,1-.89-.51,1.1,1.1,0,0,1-.27-.67.88.88,0,0,1,.1-.43.91.91,0,0,1,.26-.3,1.11,1.11,0,0,1,.37-.18,1.63,1.63,0,0,1,.87,0,1.11,1.11,0,0,1,.37.18.91.91,0,0,1,.26.3.88.88,0,0,1,.1.43,1.1,1.1,0,0,1-.27.67A1.61,1.61,0,0,1,123.14,281.26Z"/>
				<path d="M448.77,486.8a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,448.77,486.8Zm10.14-2.8h3.57v-2h-5.59l-.77,5.48a9.22,9.22,0,0,1,1.58-.14,6.19,6.19,0,0,1,1.06.08,2.17,2.17,0,0,1,.69.22.92.92,0,0,1,.38.37,1.15,1.15,0,0,1,0,1.08,1.25,1.25,0,0,1-.4.4,1.88,1.88,0,0,1-.58.23,3,3,0,0,1-.69.08,5.44,5.44,0,0,1-1.12-.11,6.14,6.14,0,0,1-1.09-.35v2.11a5.41,5.41,0,0,0,1,.3,7.11,7.11,0,0,0,1.39.12,5.62,5.62,0,0,0,1.75-.25,3.9,3.9,0,0,0,1.31-.7,3.11,3.11,0,0,0,.83-1.08,3.18,3.18,0,0,0,.29-1.37,3.33,3.33,0,0,0-.19-1.14,2.31,2.31,0,0,0-.62-.93,3.06,3.06,0,0,0-1.1-.62,5,5,0,0,0-1.62-.23h-.28Z"/>
				<path d="M448.77,624a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,448.77,624Zm10.73-1.31a4.41,4.41,0,0,0-1.07.12,3.37,3.37,0,0,0-.77.28l0,0a2.22,2.22,0,0,1,2.39-2.13,6,6,0,0,1,1.15.12,6.85,6.85,0,0,1,1,.28v-2a4.62,4.62,0,0,0-1-.29,6.25,6.25,0,0,0-1.34-.14,4.9,4.9,0,0,0-2.15.43,4,4,0,0,0-1.47,1.18,5,5,0,0,0-.84,1.76,8.39,8.39,0,0,0-.27,2.16,6.23,6.23,0,0,0,.3,2,4.11,4.11,0,0,0,.83,1.45,3.35,3.35,0,0,0,1.24.87,4.08,4.08,0,0,0,1.54.29,4.5,4.5,0,0,0,1.52-.24,3.36,3.36,0,0,0,1.15-.68,3,3,0,0,0,.74-1.06,3.54,3.54,0,0,0,.26-1.37,3.12,3.12,0,0,0-.24-1.25,2.69,2.69,0,0,0-.67-.94,3,3,0,0,0-1-.6A3.92,3.92,0,0,0,459.5,622.71Zm-.41,4.3a1.25,1.25,0,0,1-.59-.14,1.42,1.42,0,0,1-.47-.4,2,2,0,0,1-.32-.64,2.88,2.88,0,0,1-.12-.85,3,3,0,0,1,.66-.26,2.72,2.72,0,0,1,.7-.09,1.41,1.41,0,0,1,1,.32,1.06,1.06,0,0,1,.34.81,1.25,1.25,0,0,1-.33.93A1.2,1.2,0,0,1,459.09,627Z"/>
				<path d="M307.39,148.49a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,307.39,148.49Zm9.36,3a6.17,6.17,0,0,1-1-.11,5.28,5.28,0,0,1-1.18-.35v2.09a5.45,5.45,0,0,0,1,.29,7.2,7.2,0,0,0,1.4.13,5.34,5.34,0,0,0,2.3-.44,4,4,0,0,0,1.49-1.18,4.66,4.66,0,0,0,.8-1.73,8.85,8.85,0,0,0,.24-2.09,5.18,5.18,0,0,0-1.06-3.51,3.75,3.75,0,0,0-3-1.21,3.94,3.94,0,0,0-1.42.25,3.31,3.31,0,0,0-1.11.69,3.15,3.15,0,0,0-.73,1.07,3.47,3.47,0,0,0-.26,1.37,3.12,3.12,0,0,0,.26,1.34,2.63,2.63,0,0,0,.7.93,2.91,2.91,0,0,0,1,.54,4,4,0,0,0,1.18.18,5,5,0,0,0,1-.11,3.31,3.31,0,0,0,.88-.3l0,0a2.49,2.49,0,0,1-.83,1.66A2.87,2.87,0,0,1,316.76,151.48Zm1.22-3.69a1.34,1.34,0,0,1-1-.32,1.15,1.15,0,0,1-.33-.88,1.36,1.36,0,0,1,.07-.43,1.17,1.17,0,0,1,.21-.38,1,1,0,0,1,.36-.27,1.42,1.42,0,0,1,1.63.44,2.83,2.83,0,0,1,.45,1.43,1.22,1.22,0,0,1-.32.18l-.39.13-.4.08Z"/>
				<path d="M195.59,459.46a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62A10.42,10.42,0,0,1,216,456.7a10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,195.59,459.46Zm7.16,2.79v2h6.87v-2h-2.06v-7.78h-2.07l-2.88,1v2.1l2.47-.74v5.4Z"/>
				<path d="M195.59,486.8A10.12,10.12,0,0,1,196,484a10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62A10.42,10.42,0,0,1,216,484a10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,195.59,486.8Zm10.3-5.13a6.52,6.52,0,0,0-2.93.69v2.11a6,6,0,0,1,2.51-.73q1.36,0,1.36,1a2.07,2.07,0,0,1-.26.94,6.83,6.83,0,0,1-.76,1.13q-.5.63-1.26,1.4T202.8,490v1.63h6.95v-2h-2.36q-.71,0-1.5.07v0q.88-.82,1.54-1.48a10.69,10.69,0,0,0,1.1-1.26,5,5,0,0,0,.65-1.18,3.54,3.54,0,0,0,.22-1.25,2.61,2.61,0,0,0-.27-1.21,2.53,2.53,0,0,0-.74-.87,3.27,3.27,0,0,0-1.11-.53A5.17,5.17,0,0,0,205.89,481.67Z"/>
				<path d="M195.59,555.14a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,195.59,555.14Zm14-2.5a2.28,2.28,0,0,0-1-1.92,4.5,4.5,0,0,0-2.74-.71,8.4,8.4,0,0,0-2.53.41v2.06a6.17,6.17,0,0,1,1.06-.31,5.75,5.75,0,0,1,1.13-.11,2.23,2.23,0,0,1,1.07.22.74.74,0,0,1,.41.7.81.81,0,0,1-.13.47,1,1,0,0,1-.36.32,1.86,1.86,0,0,1-.53.18,3.25,3.25,0,0,1-.63.06h-.95V556h1.27a3.71,3.71,0,0,1,.61,0,1.44,1.44,0,0,1,.49.17.91.91,0,0,1,.33.32,1,1,0,0,1,.12.5,1,1,0,0,1-.44.83,2.35,2.35,0,0,1-1.33.3,5.91,5.91,0,0,1-1.26-.13,5,5,0,0,1-1-.32v2.09a6.68,6.68,0,0,0,2.39.41,5,5,0,0,0,3.13-.83,2.72,2.72,0,0,0,1.06-2.27,2.17,2.17,0,0,0-.44-1.42,2.21,2.21,0,0,0-1.13-.74v0a2.2,2.2,0,0,0,1-.91A2.66,2.66,0,0,0,209.57,552.64Z"/>
				<path d="M195.59,693.46a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,195.59,693.46ZM207,688.62h-2.48l-2.43,6.22v1.56h4.11v1.89h2.37V696.4h1.17v-2h-1.17v-1.46h-2.37v1.46h-.36a13,13,0,0,0-1.32.06v0a16.07,16.07,0,0,0,.95-2Z"/>
				<path d="M330.59,148.49a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,330.59,148.49Zm4,2.79v2h6.14v-2H339V143.5h-2l-2.54,1v2.1l2.16-.74v5.4Zm6.63-2.84a8.43,8.43,0,0,0,.26,2.23,4.64,4.64,0,0,0,.74,1.59,3.11,3.11,0,0,0,1.14.95,3.52,3.52,0,0,0,2.92,0,3.11,3.11,0,0,0,1.14-.95,4.64,4.64,0,0,0,.74-1.59,9.63,9.63,0,0,0,0-4.48,4.64,4.64,0,0,0-.74-1.59,3.06,3.06,0,0,0-1.14-.94,3.58,3.58,0,0,0-2.92,0,3.06,3.06,0,0,0-1.14.94,4.64,4.64,0,0,0-.74,1.59A8.49,8.49,0,0,0,341.23,148.44Zm4.77,0a11.51,11.51,0,0,1-.06,1.2,4.47,4.47,0,0,1-.19,1,1.68,1.68,0,0,1-.35.63.79.79,0,0,1-1.11,0,1.68,1.68,0,0,1-.35-.63,4.47,4.47,0,0,1-.19-1,12.4,12.4,0,0,1,0-2.41,4.46,4.46,0,0,1,.19-.95,1.68,1.68,0,0,1,.35-.63.79.79,0,0,1,1.11,0,1.68,1.68,0,0,1,.35.63,4.46,4.46,0,0,1,.19.95A11.46,11.46,0,0,1,346,148.44Z"/>
				<path d="M319.39,262.63a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,319.39,262.63Zm4.11,2.79v2h6.14v-2h-1.76v-7.78h-2l-2.54,1v2.1l2.14-.74v5.4Zm6.75,0v2h6.14v-2h-1.75v-7.78h-2l-2.54,1v2.1l2.14-.74v5.4Z"/>
				<path d="M501.5,233.19a10.12,10.12,0,0,1,.38-2.76,10.74,10.74,0,0,1,1-2.49,10.25,10.25,0,0,1,1.62-2.11,10.39,10.39,0,0,1,4.6-2.68,10.27,10.27,0,0,1,2.76-.37,10.12,10.12,0,0,1,2.76.38,10.74,10.74,0,0,1,2.49,1,10.25,10.25,0,0,1,2.11,1.62,10.42,10.42,0,0,1,2.68,4.59,10.52,10.52,0,0,1,0,5.53,10.42,10.42,0,0,1-7.27,7.28,10.25,10.25,0,0,1-2.77.37,10.12,10.12,0,0,1-2.76-.37,10.74,10.74,0,0,1-2.49-1,10.3,10.3,0,0,1-3.73-3.73,10.74,10.74,0,0,1-1-2.49A10.12,10.12,0,0,1,501.5,233.19Zm14-2.5a2.28,2.28,0,0,0-1-1.92,4.5,4.5,0,0,0-2.74-.71,8.4,8.4,0,0,0-2.53.41v2.06a6.17,6.17,0,0,1,1.06-.31,5.75,5.75,0,0,1,1.13-.11,2.23,2.23,0,0,1,1.07.22.74.74,0,0,1,.41.7.81.81,0,0,1-.13.47,1,1,0,0,1-.36.32,1.86,1.86,0,0,1-.53.18,3.25,3.25,0,0,1-.63.06h-.95V234h1.27a3.71,3.71,0,0,1,.61,0,1.44,1.44,0,0,1,.49.17.91.91,0,0,1,.33.32,1,1,0,0,1,.12.5,1,1,0,0,1-.44.83,2.35,2.35,0,0,1-1.33.3,5.91,5.91,0,0,1-1.26-.13,5,5,0,0,1-1-.32v2.09a6.68,6.68,0,0,0,2.39.41,5,5,0,0,0,3.13-.83,2.72,2.72,0,0,0,1.06-2.27,2.17,2.17,0,0,0-.44-1.42,2.21,2.21,0,0,0-1.13-.74v0a2.2,2.2,0,0,0,1-.91A2.66,2.66,0,0,0,515.48,230.69Z"/>
			</g>
			<g className="tekstlabels zwart">
				<g>
				<path d="M24.7,726.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,726.58Zm4.77,1.86v1.36h4.58v-1.36H32.68v-5.19H31.3l-1.92.68v1.4l1.65-.49v3.6Z"/>
				<path d="M24.7,744.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,744.58Zm6.87-3.42a4.36,4.36,0,0,0-2,.46V743a4,4,0,0,1,1.67-.48q.91,0,.91.69a1.38,1.38,0,0,1-.17.63,4.54,4.54,0,0,1-.51.75q-.34.42-.84.93l-1.17,1.17v1.09h4.63v-1.36H32.57q-.48,0-1,0v0q.59-.55,1-1a7.15,7.15,0,0,0,.73-.84,3.36,3.36,0,0,0,.43-.79,2.36,2.36,0,0,0,.14-.83,1.74,1.74,0,0,0-.18-.81,1.7,1.7,0,0,0-.49-.58,2.18,2.18,0,0,0-.74-.35A3.45,3.45,0,0,0,31.57,741.16Z"/>
				<path d="M24.7,762.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,762.58ZM34,760.92a1.52,1.52,0,0,0-.64-1.28,3,3,0,0,0-1.82-.48,5.62,5.62,0,0,0-1.69.27v1.38a4.19,4.19,0,0,1,.71-.21,3.88,3.88,0,0,1,.75-.07,1.49,1.49,0,0,1,.71.15.5.5,0,0,1,.27.47.54.54,0,0,1-.09.32.69.69,0,0,1-.24.21,1.22,1.22,0,0,1-.35.12,2.15,2.15,0,0,1-.42,0h-.63v1.3h.84a2.51,2.51,0,0,1,.41,0,1,1,0,0,1,.33.11.61.61,0,0,1,.22.21.69.69,0,0,1-.21.89,1.56,1.56,0,0,1-.89.2,3.92,3.92,0,0,1-.84-.09,3.23,3.23,0,0,1-.67-.21v1.39a4.44,4.44,0,0,0,1.59.27,3.36,3.36,0,0,0,2.09-.55,1.81,1.81,0,0,0,.71-1.51,1.45,1.45,0,0,0-.29-.95,1.48,1.48,0,0,0-.75-.49v0a1.47,1.47,0,0,0,.69-.61A1.78,1.78,0,0,0,34,760.92Z"/>
				<path d="M24.7,780.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,780.58Zm7.58-3.23H30.63L29,781.5v1h2.74v1.26h1.58v-1.26h.78v-1.33h-.78v-1H31.75v1h-.24c-.34,0-.63,0-.88,0v0a10.64,10.64,0,0,0,.63-1.35Z"/>
				<path d="M24.7,798.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,798.58Zm6.76-1.87h2.38v-1.36H30.12L29.6,799a6.15,6.15,0,0,1,1.05-.09,4.2,4.2,0,0,1,.71.05,1.48,1.48,0,0,1,.46.15.62.62,0,0,1,.25.25.77.77,0,0,1,0,.72.84.84,0,0,1-.27.27,1.25,1.25,0,0,1-.39.16,2,2,0,0,1-.46.05,3.6,3.6,0,0,1-.75-.07,4,4,0,0,1-.73-.23v1.41a3.53,3.53,0,0,0,.68.2,4.69,4.69,0,0,0,.93.08,3.74,3.74,0,0,0,1.16-.17,2.58,2.58,0,0,0,.88-.47,2.07,2.07,0,0,0,.55-.72,2.13,2.13,0,0,0,.2-.91,2.22,2.22,0,0,0-.13-.76,1.55,1.55,0,0,0-.41-.62,2.05,2.05,0,0,0-.73-.41,3.35,3.35,0,0,0-1.08-.15h-.19Z"/>
				<path d="M24.7,816.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,816.58Zm7.16-.87a3,3,0,0,0-.71.08,2.3,2.3,0,0,0-.52.19l0,0a1.48,1.48,0,0,1,1.59-1.42,4,4,0,0,1,.77.08,4.74,4.74,0,0,1,.7.19v-1.34a3.11,3.11,0,0,0-.64-.2,4.17,4.17,0,0,0-.89-.09,3.27,3.27,0,0,0-1.43.29,2.69,2.69,0,0,0-1,.79,3.31,3.31,0,0,0-.56,1.18,5.58,5.58,0,0,0-.18,1.44,4.15,4.15,0,0,0,.2,1.35,2.74,2.74,0,0,0,.55,1,2.22,2.22,0,0,0,.83.58,2.71,2.71,0,0,0,1,.19,3,3,0,0,0,1-.16,2.24,2.24,0,0,0,.77-.45,2,2,0,0,0,.49-.71,2.36,2.36,0,0,0,.17-.91,2.09,2.09,0,0,0-.16-.83,1.8,1.8,0,0,0-.45-.63,2,2,0,0,0-.68-.4A2.61,2.61,0,0,0,31.86,815.7Zm-.27,2.87a.83.83,0,0,1-.39-.09.93.93,0,0,1-.31-.27,1.31,1.31,0,0,1-.21-.43,1.91,1.91,0,0,1-.08-.57A2.06,2.06,0,0,1,31,817a1.84,1.84,0,0,1,.47-.06.94.94,0,0,1,.66.21.7.7,0,0,1,.23.54.83.83,0,0,1-.22.62A.8.8,0,0,1,31.59,818.57Z"/>
				<path d="M24.7,834.58a6.74,6.74,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.13,7.13,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,834.58Zm7.52-1.87L29.8,837.8h1.72l2.54-5.35v-1.09H29.42v1.36Z"/>
				<path d="M344.2,726.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,726.58Zm6.94-3.42a3.77,3.77,0,0,0-1,.13,2.29,2.29,0,0,0-.77.37,1.62,1.62,0,0,0-.48.57,1.65,1.65,0,0,0-.16.74,1.46,1.46,0,0,0,.27.89,1.76,1.76,0,0,0,.71.57v0a2,2,0,0,0-.44.23,1.61,1.61,0,0,0-.36.34,1.53,1.53,0,0,0-.24.45,1.75,1.75,0,0,0-.09.57,1.8,1.8,0,0,0,.17.79,1.66,1.66,0,0,0,.5.61,2.34,2.34,0,0,0,.81.38,4.6,4.6,0,0,0,2.2,0,2.34,2.34,0,0,0,.81-.38,1.66,1.66,0,0,0,.5-.61,1.8,1.8,0,0,0,.17-.79,1.75,1.75,0,0,0-.09-.57,1.53,1.53,0,0,0-.24-.45,1.61,1.61,0,0,0-.36-.34,2,2,0,0,0-.44-.23v0a1.76,1.76,0,0,0,.71-.57,1.46,1.46,0,0,0,.27-.89,1.65,1.65,0,0,0-.16-.74,1.62,1.62,0,0,0-.48-.57,2.29,2.29,0,0,0-.77-.37A3.77,3.77,0,0,0,351.14,723.16Zm0,5.45a1,1,0,0,1-.68-.2.6.6,0,0,1-.23-.48.76.76,0,0,1,.27-.61.78.78,0,0,1,1.28,0,.76.76,0,0,1,.27.61.6.6,0,0,1-.23.48A1,1,0,0,1,351.14,728.6Zm0-2.68a1.07,1.07,0,0,1-.59-.34.73.73,0,0,1-.18-.45.59.59,0,0,1,.07-.29.62.62,0,0,1,.17-.2.76.76,0,0,1,.25-.12,1.09,1.09,0,0,1,.58,0,.76.76,0,0,1,.25.12.62.62,0,0,1,.17.2.59.59,0,0,1,.07.29.73.73,0,0,1-.18.45A1.07,1.07,0,0,1,351.14,725.92Z"/>
				<path d="M344.2,744.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,744.58Zm6.24,2a4.11,4.11,0,0,1-.68-.07,3.52,3.52,0,0,1-.79-.23v1.39a3.56,3.56,0,0,0,.69.2,4.78,4.78,0,0,0,.93.09,3.56,3.56,0,0,0,1.53-.29,2.63,2.63,0,0,0,1-.79,3.09,3.09,0,0,0,.54-1.16,5.91,5.91,0,0,0,.16-1.39,3.45,3.45,0,0,0-.71-2.34,2.5,2.5,0,0,0-2-.81,2.63,2.63,0,0,0-.95.16,2.22,2.22,0,0,0-.74.46,2.11,2.11,0,0,0-.49.71,2.32,2.32,0,0,0-.18.91,2.08,2.08,0,0,0,.18.89,1.75,1.75,0,0,0,.47.62,1.93,1.93,0,0,0,.68.36,2.68,2.68,0,0,0,.79.12,3.36,3.36,0,0,0,.68-.07,2.2,2.2,0,0,0,.59-.2l0,0a1.66,1.66,0,0,1-.55,1.11A1.92,1.92,0,0,1,350.45,746.57Zm.81-2.46a.89.89,0,0,1-.64-.21.77.77,0,0,1-.22-.59.91.91,0,0,1,0-.29.79.79,0,0,1,.14-.25.67.67,0,0,1,.24-.18.86.86,0,0,1,.35-.07.85.85,0,0,1,.74.36,1.89,1.89,0,0,1,.3,1,.81.81,0,0,1-.21.12l-.26.09-.27.05Z"/>
				<path d="M344.2,762.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,762.58Zm2.67,1.86v1.36H351v-1.36H349.8v-5.19h-1.33l-1.7.68v1.4l1.44-.49v3.6Zm4.42-1.89a5.62,5.62,0,0,0,.18,1.49,3.09,3.09,0,0,0,.5,1.06,2.08,2.08,0,0,0,.76.63,2.35,2.35,0,0,0,1.95,0,2.08,2.08,0,0,0,.76-.63,3.09,3.09,0,0,0,.5-1.06,6.42,6.42,0,0,0,0-3,3.1,3.1,0,0,0-.5-1.06,2.05,2.05,0,0,0-.76-.63,2.39,2.39,0,0,0-1.95,0,2.05,2.05,0,0,0-.76.63,3.1,3.1,0,0,0-.5,1.06A5.66,5.66,0,0,0,351.3,762.55Zm3.18,0a7.57,7.57,0,0,1,0,.8,3,3,0,0,1-.13.64,1.11,1.11,0,0,1-.23.42.53.53,0,0,1-.74,0,1.11,1.11,0,0,1-.23-.42,3,3,0,0,1-.13-.64,8.27,8.27,0,0,1,0-1.61,3,3,0,0,1,.13-.63,1.11,1.11,0,0,1,.23-.42.53.53,0,0,1,.74,0,1.11,1.11,0,0,1,.23.42,3,3,0,0,1,.13.63A7.57,7.57,0,0,1,354.48,762.55Z"/>
				<path d="M344.2,780.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,780.58Zm2.74,1.86v1.36H351v-1.36h-1.17v-5.19h-1.32l-1.7.68v1.4l1.43-.49v3.6Zm4.5,0v1.36h4.09v-1.36h-1.16v-5.19H353l-1.7.68v1.4l1.43-.49v3.6Z"/>
				<path d="M344.2,798.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,798.58Zm2.52,1.86v1.36h4.09v-1.36h-1.17v-5.19h-1.33l-1.7.68v1.4l1.44-.49v3.6Zm6.58-5.28a4.13,4.13,0,0,0-1.87.46V797a3.81,3.81,0,0,1,1.61-.48q.87,0,.87.69a1.43,1.43,0,0,1-.16.63,4.32,4.32,0,0,1-.49.75q-.32.41-.8.93t-1.12,1.18v1.09h4.45v-1.36h-1.51q-.45,0-1,0v0l1-1a7.17,7.17,0,0,0,.7-.84,3.24,3.24,0,0,0,.42-.79,2.49,2.49,0,0,0,.14-.83,1.81,1.81,0,0,0-.17-.81,1.66,1.66,0,0,0-.47-.58,2.07,2.07,0,0,0-.71-.35A3.17,3.17,0,0,0,353.3,795.16Z"/>
				<path d="M344.2,816.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,816.58Zm2.75,1.86v1.36H351v-1.36h-1.17v-5.19h-1.33l-1.7.68v1.4l1.44-.49v3.6Zm8.67-3.52a1.54,1.54,0,0,0-.61-1.28,2.79,2.79,0,0,0-1.75-.48,5.16,5.16,0,0,0-1.62.27v1.38a3.85,3.85,0,0,1,.68-.21,3.57,3.57,0,0,1,.72-.07,1.38,1.38,0,0,1,.68.15.5.5,0,0,1,.26.47.56.56,0,0,1-.09.32.67.67,0,0,1-.23.21,1.12,1.12,0,0,1-.34.12,2,2,0,0,1-.4,0h-.61v1.3h.81a2.32,2.32,0,0,1,.39,0,.89.89,0,0,1,.31.11.59.59,0,0,1,.21.21.66.66,0,0,1,.07.33.65.65,0,0,1-.28.55,1.44,1.44,0,0,1-.84.2,3.63,3.63,0,0,1-.81-.09,3.08,3.08,0,0,1-.64-.21v1.39a4.14,4.14,0,0,0,1.53.27,3.13,3.13,0,0,0,2-.55,1.84,1.84,0,0,0,.68-1.51,1.49,1.49,0,0,0-.28-.95,1.42,1.42,0,0,0-.73-.49v0a1.41,1.41,0,0,0,.66-.61A1.86,1.86,0,0,0,355.63,814.92Z"/>
				<path d="M344.2,834.58a6.74,6.74,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.77,6.77,0,0,1,1.84.25,7.21,7.21,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.15,7.15,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,834.58Zm2.63,1.86v1.36h4.09v-1.36h-1.17v-5.19h-1.33l-1.7.68v1.4l1.44-.49v3.6Zm7.66-5.09h-1.59l-1.55,4.15v1H354v1.26h1.52v-1.26h.75v-1.33h-.75v-1H354v1h-.23a8.09,8.09,0,0,0-.84,0v0a10.57,10.57,0,0,0,.61-1.35Z"/>
				</g>
			</g>
		</svg>

		<ReactTooltip anchorId="tt1" place="left" float>
			<span>1. Segment 1</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt25" place="left" float>
			<div style={ {textAlign: 'left'} }>
			2. Segment 2<br />
			5. Vergoeding Segment 2
			</div>
		</ReactTooltip>

		<ReactTooltip anchorId="tt3" place="left" float>
			<span>3. Segment 3</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt4" place="left" float>
			<span>4. Buitensegment</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt6" place="left" float>
			<span>6. Vergoeding buitensegment</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt37" place="left" float>
			<div style={ {textAlign: 'left'} }>
			3. Segment 3<br />
			7. Eigen betalingen
			</div>
		</ReactTooltip>

		<ReactTooltip anchorId="tt8" place="right" float>
			<span>8. Nominale premie</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt9" place="right" float>
			<span>9. Inkomensafhankelijke bijdrage</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt10" place="right" float>
			<span>10. Zorgtoeslag</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt11" place="right" float>
			<span>11. Rijksbijdrage</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt12" place="left" float>
			<span>12. Rijksbijdrage</span>
		</ReactTooltip>

		<ReactTooltip anchorId="tt1314" place="right" float>
			<div style={ {textAlign: 'left'} }>
			13. Kinderpremie<br />
			14. Risicoverevening
			</div>
		</ReactTooltip>

	</div>
);

export default GeldstromenZvw;
