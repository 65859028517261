import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const EurosWPG = () => {
  const localeFormatter = formatLocale({
    decimal: ",",
    thousands: "."
  });

  const formatValueTooltip = val =>
    localeFormatter.format(",.1f")(val) + " miljoen euro";
  const formatValueY = val => localeFormatter.format(",.0f")(val);

  const data = [
    { key: "Ziektepreventie", value: 521 },
    { key: "Gezondheidsbescherming", value: 125},
    { key: "Gezondheidsbevordering", value: 65}
  ];

  return (
    <div className="vis-euros-wpg">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0, 521]}
        color={{ fill: "#333", stroke: "#fff" }}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">711</p>
        <p>miljoen euro in 2019</p>
      </div>

      <div className="vis-credit">
        <p>Begrote uitgaven aan publieke gezondheidszorg voor 2019 in miljoen euro volgens het ministerie van VWS.</p>
        <p>Bron: Rijksbegroting 2019.</p>
      </div>
    </div>
  );
};

export default EurosWPG;
