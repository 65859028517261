import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';

const Participatie = () => {
  const margin = {top: 90, right: 0, bottom: 90, left: 0};
  const formatY = value => value + ' %';
  const formatValueToolTip = d => (<span>{d.column}</span>);

  const createTable = (data) => (
    <table className="tabel">
      <tbody>
      {data.map((row, i) => <tr key={row.key}><td>{row.key}</td><td>{formatY(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
      {
          key: "Lichamelijke beperking",
          value: 31,
      },
      {
          key: "Verstandelijke beperking",
          value: 35,
      },
      {
          key: "Geen",
          value: 76,
      }
  ];

  return (
    <div className="vis-participatie">
      <h4>Betaald werk</h4>

      <ColumnChart
        margin={margin}
        height={450}
        domain={[0, 100]}
        columnPadding={0.2}
        formatY={formatY}
        formatValueToolTip={formatValueToolTip}
        data={data}
      />

      {createTable(data)}

      <div className="vis-credit">
        <p>Participatie van mensen met een beperking, afgezet tegen de rest van de Nederlandse bevolking.</p>
        <p>Bron: NIVEL, 2019</p>
      </div>
    </div>
  );
}
export default Participatie;
