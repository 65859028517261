import intro from './intro'
import verzamelen from './verzamelen'
import delen from './delen'
import bereidheid from './bereidheid'
import colofon from './colofon'

export default {
  intro,
  verzamelen,
  delen,
  bereidheid,
  colofon,
}
