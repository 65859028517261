import React, { Component } from 'react';
import { max, select, scaleBand, scaleLinear, axisBottom, axisLeft, format, pointer } from 'd3';

import './BarChart.scss';

class BarChart extends Component {
  state = {
    width: 0,
    height: 0,
    tooltip: { display: 'none', x: 0, y: 0 }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState){
    if (this.state.width !== prevState.width || this.state.height !== prevState.height) this.updateChart();
    if (this.props.data) {
      if (this.props.title !== prevProps.title) {
        this.updateDimensions();
        this.updateChart();
      }
    }
  }

  updateDimensions = () => {
    if (!this.container || !this.props.data) return;
    const { height, barHeight, data, margin } = this.props;
    const width = this.container.clientWidth;
    let newHeight = this.container.clientHeight;
    if (height) newHeight = height;
    if (barHeight) newHeight = (barHeight * data.length) + margin.top + margin.bottom;
    if (this.state.width !== width || this.state.height !== height) this.setState({ width, height: newHeight });
  }

  updateChart = () => {
    const { data, margin, color, parts, animation } = this.props;
    const { width, height } = this.state;

    const transitionDuration = (animation === false || animation === 'undefined') ? 0 : 1000;
    const widthChart = width - margin.left - margin.right;
    const heightChart = height - margin.top - margin.bottom;
    const maxValue = max(data.map(item => item.value));
    const categories = data.map(item => item.key);
    const domain = this.props.domain || [0, maxValue];
    const formatValueTooltip = this.props.formatValueTooltip || (value => value);
    const formatValueY = this.props.formatValueY || format(',.1f');
    const padding = this.props.padding || 0.3;
    const ticks = this.props.ticks || 0;

    const xScale = scaleLinear().range([0, widthChart]).domain(domain);
    const yScale = scaleBand().range([0, heightChart]).domain(categories).paddingInner(padding);

    const xAxis = axisBottom(xScale).ticks(ticks).tickSizeInner(heightChart);
    const yAxis = axisLeft(yScale).ticks().tickValues(categories).tickSizeOuter(0);

    const tooltipPosition = this.props.tooltipPosition || 'end';
    const marginBeforeValue = this.props.marginBeforeValue || -45;

    const cleanString = str => str.replace(/\s/g,'').toLowerCase();

    const addValue = categories => {
      categories.each(function(category) {
        const text = select(this);
        const value = data.filter(item => item.key === category)[0].value;
        text.text(null)
        if (value < 0) {
          text.append('tspan').attr('x', 9).text(formatValueY(value));
          text.append('tspan').attr('x', -marginBeforeValue).text(category);
          text.attr('text-anchor', 'start')
        }
        else {
          text.append('tspan').attr('x', marginBeforeValue).text(category);
          text.append('tspan').attr('x', -9).text(formatValueY(value));
        }
      })
    }

    const chart = select(this.container).select('.chart');

    chart.select('.x.axis').call(xAxis);
    chart.select('.y.axis').call(yAxis)
      .selectAll(".tick text")
      .call(addValue);
    chart.select('.baseline')
      .attr('x1', 0).attr('x2', 0)
      .attr('y1', -4.5).attr('y2', heightChart+4.5);

    const bars = chart.select('.bars').selectAll('.bar').data(data);

    bars.exit().remove();

    bars
      .enter()
        .append('rect').attr('class', d => d.highlight ? 'bar highlight ' + cleanString(d.key) : 'bar ' + cleanString(d.key))
      .merge(bars)
        .attr('fill', color.fill)
        .attr('stroke', color.stroke)
        .attr('stroke-width', 2)
        .attr('height', yScale.bandwidth())
        .attr('width', 0)
        .attr('x', 0)
        .attr('y', d => yScale(d.key))
      .transition().duration(transitionDuration)
        .attr('x', d => d.value < 0 ? -xScale(Math.abs(d.value)) : 0)
        .attr('width', d => xScale(Math.abs(d.value)))


    const catchers = chart.select('.catchers').selectAll('.catcher').data(data);

    catchers.exit().remove();

    catchers
      .enter()
        .append('rect').attr('class', 'catcher')
      .merge(catchers)
        .attr('fill', '#fff')
        .attr('stroke', '#fff')
        .attr('height', heightChart/categories.length)
        .attr('width', widthChart)
        .attr('x', d => d.value < 0 ? -widthChart : 0)
        .attr('y', d => yScale(d.key))
        .style('opacity', 0)
        .on('mousemove', (event, d) => {
            let xPos, yPos, location;
            if (tooltipPosition === 'end') {
              xPos =
                d.value < 0
                  ? xScale(0) - xScale(d.value) + 12
                  : xScale(d.value) + margin.left + 12;
              yPos = yScale(d.key) + margin.top + 6 - (0.5 * yScale.bandwidth())
              location = d.value < 0 ? 'left' : 'right';
            }

            if (tooltipPosition === 'mouse') {
              const pos = pointer(event, this.container)
              xPos = pos[0] + 12;
              yPos = pos[1] + 12;
              location = '';
            }

            this.setState( {
              tooltip: {
                display: 'block',
                x: xPos,
                y: yPos,
                text: `${d.tooltip || ''}${formatValueTooltip(d.value)}`,
                description: d.description,
                style: tooltipPosition,
                location
              }
          })
        })
      .on('mouseleave', () => {
          this.setState( {
            tooltip: {
              display: 'none',
              x: 0,
              y: 0,
              text: '',
              location: ''
            }
          })
      });

      if (parts){
        const barParts = chart.select('.parts').selectAll('.bar-part').data(parts);
        barParts.exit().remove();
        barParts
          .enter()
            .append('rect').attr('class', 'bar-part')
          .merge(barParts)
            .attr('fill', color.fill)
            .attr('stroke', color.stroke)
            .attr('stroke-width', 2)
            .attr('height', yScale.bandwidth())
            .attr('width', d => xScale(d.value))
            .attr('x', 0)
            .attr('y', d => yScale(d.key))
            .attr('width', 0)
              .transition().duration(transitionDuration)
            .attr('width', d => xScale(Math.abs(d.value)))

          chart.select('.parts').selectAll('.bar-part')
            .on('mouseover', (event, d) => {
                this.setState( {
                  tooltip: {
                    display: 'block',
                    x: xScale(d.value) + margin.left + 12,
                    y: yScale(d.key) + margin.top + 6 - (0.5 * yScale.bandwidth()),
                    text: d.tooltip + formatValueTooltip(d.value)
                  }
                })
              })
            .on('mouseleave', () => {
                this.setState( {
                  tooltip: {
                    display: 'none',
                    x: 0,
                    y: 0,
                    text: ''
                  }
                })
            });
      }

  }

  render() {
    const { width, height } = this.state;
    const { margin } = this.props;
    const { display, x, y, text, description, style, location } = this.state.tooltip;

    const containerStyle = {
      position: 'relative'
    }
    const tooltipStyle = {
      display: display,
      position: 'absolute',
      left: x,
      top: y
    }

    return (
      <div className="BarChart" ref={container => this.container = container} style={containerStyle}>
        <svg
          width={width}
          height={height} >
          <g className="chart" transform={"translate(" + margin.left + "," + margin.top + ")"}>
            <g className="bars" />
            <g className="catchers" />
            <g className="parts" />
            <g className="x axis"></g>
            <g className="y axis"></g>
            <line className="baseline" />
          </g>
        </svg>
        <div className={"tooltip " + location + " " + style } style={tooltipStyle}><span>{description ? description + ': ' : ''}{text}</span></div>

      </div>
    );
  }
}

export default BarChart;
