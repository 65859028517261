import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const AantalGgzBedden = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);
	
	const formatValueTooltip = value => formatY(value) + " per 100.000 inwoners";

	const data = [
		{
			name: "dummyData", //deze naam is verplicht als labels van deze lijn niet getoond moeten worden
			values: [
				{ x: 2006, y: 136.73, showTooltip: 0 },
				{ x: 2007, y: 134.72, showTooltip: 0 },
				{ x: 2008, y: 137.36, showTooltip: 0 },
				{ x: 2009, y: 139.26, showTooltip: 0 },
				{ x: 2010, y: 134.91, showTooltip: 0 },
				{ x: 2011, y: 130.56, showTooltip: 0 },
				{ x: 2012, y: 126.21, showTooltip: 0 },
				{ x: 2013, y: 126.63, showTooltip: 0 },
				{ x: 2014, y: 112.69, showTooltip: 0 },
				{ x: 2015, y: 98.74, showTooltip: 0 },
				{ x: 2016, y: 97.50, showTooltip: 0 },
				{ x: 2017, y: 90.72, showTooltip: 0 },
			]
		},
		{
			name: "Nederland",
			values: [
				{ x: 2006, y: 136.73, showTooltip: 1 },
				{ x: 2007, y: 134.72, showTooltip: 1 },
				{ x: 2008, y: 137.36, showTooltip: 1 },
				{ x: 2009, y: 139.26, showTooltip: 1 },
				{ x: 2010, y: undefined, showTooltip: 0 },
				{ x: 2011, y: undefined, showTooltip: 0 },
				{ x: 2012, y: 126.21, showTooltip: 1 },
				{ x: 2013, y: 126.63, showTooltip: 1 },
				{ x: 2014, y: undefined, showTooltip: 0 },
				{ x: 2015, y: 98.74, showTooltip: 1 },
				{ x: 2016, y: 97.50, showTooltip: 1 },
				{ x: 2017, y: 90.72, showTooltip: 1 },
			]
		},
		{
			name: "EU",
			values: [
				{ x: 2006, y: 77.94, showTooltip: 1 },
				{ x: 2007, y: 76.95, showTooltip: 1 },
				{ x: 2008, y: 76.56, showTooltip: 1 },
				{ x: 2009, y: 76.09, showTooltip: 1 },
				{ x: 2010, y: 75.94, showTooltip: 1 },
				{ x: 2011, y: 75.88, showTooltip: 1 },
				{ x: 2012, y: 75.65, showTooltip: 1 },
				{ x: 2013, y: 75.63, showTooltip: 1 },
				{ x: 2014, y: 75.45, showTooltip: 1 },
				{ x: 2015, y: 74.49, showTooltip: 1 },
				{ x: 2016, y: 73.89, showTooltip: 1 },
				{ x: 2017, y: 73.33, showTooltip: 1 },
			]
		},
	];

	return (
		<div className="vis-aantal-ggz-bedden">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[0, 140]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>De bovenste lijn toont het aantal bedden in Nederland, de onderste lijn het gemiddeld aantal bedden in de EU. Aantal beschikbare plaatsen in ggz-instellingen per 100.000 inwoners. Cijfers voor Nederland over 2010, 2011 en 2014 ontbreken.</p>
				<p>Bron: Eurostat, 2020.</p>
			</div>
		</div>
	);
};

export default AantalGgzBedden