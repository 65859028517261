import React from 'react';

const WaarWanneer = () => (
	<div className="vis-waar-waneer svg-container">
		<svg
		  xmlns="http://www.w3.org/2000/svg"
	    preserveAspectRatio="xMidYMin meet"
			viewBox="75 0 500 500">

			<g className="tabelkop">
		  	<rect x="78" y="63" width="144" height="27"/>
		    <rect x="226" y="63" width="344" height="27"/>
		    <text transform="translate(132 83)">Waar?</text>
	  		<text transform="translate(370 83)">Wanneer?</text>
	    </g>
			<g className="tabel">
				<rect x="78" y="96" width="492" height="303"/>
				<rect x="78" y="194" width="492" height="106"/>
			</g>
			<g className="icons">
			  <line x1="149" y1="334.97" x2="149" y2="354.97"/>
			  <line x1="139" y1="344.97" x2="159" y2="344.97"/>
			  <rect x="119" y="324.97" width="60" height="40"/>
			  <rect x="134" y="314.97" width="30" height="10"/>
			  <rect x="119" y="329.97" width="60" height="30"/>
			  <polygon points="179 214.97 149 214.97 149 209.97 119 209.97 119 269.97 179 269.97 179 214.97"/>
			  <rect x="129" y="259.97" width="10" height="10"/>
			  <rect x="149" y="259.97" width="5" height="5"/>
			  <rect x="159" y="259.97" width="5" height="5"/>
			  <rect x="169" y="259.97" width="5" height="5"/>
			  <rect x="149" y="249.97" width="5" height="5"/>
			  <rect x="159" y="249.97" width="5" height="5"/>
			  <rect x="169" y="249.97" width="5" height="5"/>
			  <rect x="149" y="239.97" width="5" height="5"/>
			  <rect x="159" y="239.97" width="5" height="5"/>
			  <rect x="169" y="239.97" width="5" height="5"/>
			  <rect x="149" y="229.97" width="5" height="5"/>
			  <rect x="159" y="229.97" width="5" height="5"/>
			  <rect x="169" y="229.97" width="5" height="5"/>
			  <rect x="149" y="219.97" width="5" height="5"/>
			  <rect x="159" y="219.97" width="5" height="5"/>
			  <rect x="169" y="219.97" width="5" height="5"/>
			  <line x1="134" y1="219.97" x2="134" y2="239.97"/>
			  <line x1="124" y1="229.97" x2="144" y2="229.97"/>
			  <polygon points="119 167.97 119 132.97 149 107.97 179 132.97 179 167.97 119 167.97"/>
			  <line x1="149" y1="120.97" x2="149" y2="160.97"/>
			  <path d="M149,146a5,5,0,0,1,0,10h-5"/>
			  <path d="M149,146a5,5,0,0,1,0-10"/>
			  <path d="M149,136a5,5,0,1,0,0-10h-5"/>
		    <text transform="translate(150 183)">Praktijk</text>
				<text transform="translate(150 286)">Huisartsenpost</text>
				<text transform="translate(150 380)">Huisbezoek</text>
		  </g>
			<g className="tijdbalken">
			  <rect className="vol" x="446" y="136.67" width="81.94" height="12"/>
			  <rect className="vol" x="251.94" y="136.67" width="95.06" height="12"/>
			  <rect x="347.94" y="136.67" width="98.06" height="12"/>
			  <line x1="251.94" y1="136.67" x2="251.94" y2="152.67"/>
			  <line x1="347.94" y1="136.67" x2="347.94" y2="152.67"/>
			  <line x1="443.94" y1="136.67" x2="443.94" y2="152.67"/>
			  <line x1="527.94" y1="136.67" x2="527.94" y2="152.67"/>
			  <text transform="translate(239.77 163.67)">00:00</text>
			  <text transform="translate(515.77 163.67)">24:00</text>
			  <text transform="translate(431.77 163.67)">17:00</text>
			  <text transform="translate(335.77 163.67)">08:00</text>

			 	<rect x="251.94" y="239.97" width="96" height="12"/>
			  <rect x="443.94" y="239.97" width="84" height="12"/>
			  <rect className="vol" x="347.94" y="239.97" width="96" height="12"/>

			  <line x1="251.94" y1="239.97" x2="251.94" y2="255.97"/>
			  <line x1="347.55" y1="239.98" x2="348.34" y2="255.96"/>
			  <line x1="443.94" y1="239.97" x2="443.94" y2="255.97"/>
			  <line x1="527.94" y1="239.97" x2="527.94" y2="255.97"/>
			  <text transform="translate(239.77 266.97)">00:00</text>
			  <text transform="translate(515.77 266.97)">24:00</text>
			  <text transform="translate(431.77 266.97)">17:00</text>
			  <text transform="translate(335.77 266.97)">08:00</text>

				<rect x="251.94" y="338.97" width="276" height="12"/>
			  <line x1="251.94" y1="338.97" x2="251.94" y2="354.97"/>
			  <line x1="347.94" y1="350.97" x2="347.94" y2="354.97"/>
			  <line x1="443.94" y1="350.97" x2="443.94" y2="354.97"/>
			  <line x1="527.94" y1="338.97" x2="527.94" y2="354.97"/>
			  <text transform="translate(239.77 365.97)">00:00</text>
			  <text transform="translate(515.77 365.97)">24:00</text>
			  <text transform="translate(431.77 365.97)">17:00</text>
			  <text transform="translate(335.77 365.97)">08:00</text>

			  <polygon className="cls-1" points="251 230.97 255.04 230.97 253.02 234.47 251 237.97 248.98 234.47 246.96 230.97 251 230.97"/>
			  <polygon className="cls-1" points="347 230.97 351.04 230.97 349.02 234.47 347 237.97 344.98 234.47 342.96 230.97 347 230.97"/>
			  <polygon className="cls-1" points="444.04 230.97 448.08 230.97 446.06 234.47 444.04 237.97 442.02 234.47 440 230.97 444.04 230.97"/>
			  <polygon className="cls-1" points="348.04 127.97 352.08 127.97 350.06 131.47 348.04 134.97 346.02 131.47 344 127.97 348.04 127.97"/>
			  <polygon className="cls-1" points="444.04 127.97 448.08 127.97 446.06 131.47 444.04 134.97 442.02 131.47 440 127.97 444.04 127.97"/>
			  <polygon className="cls-1" points="528 230.97 532.04 230.97 530.02 234.47 528 237.97 525.98 234.47 523.96 230.97 528 230.97"/>
			  <polygon className="cls-1" points="251 329.97 255.04 329.97 253.02 333.47 251 336.97 248.98 333.47 246.96 329.97 251 329.97"/>
			  <polygon className="cls-1" points="528.04 329.47 532.08 329.47 530.06 332.97 528.04 336.47 526.02 332.97 524 329.47 528.04 329.47"/>
		  </g>
		</svg>
	</div>
);

export default WaarWanneer;


