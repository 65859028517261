import React, { Component } from 'react';

class HoofdrolspelersZorgverzekering extends Component {

  state = {wet: "zvw"};

	handleClick = (event) => {
		this.setState({wet: event.target.id});
	}

  render() {

  	const { wet } = this.state;

		const ZvwNaam = (
		  <text x="330" y="315">
		  	<tspan x="330">Zorg-</tspan>
		  	<tspan x="330" dy="24">verzekerings-</tspan>
		  	<tspan x="330" dy="24">wet</tspan>
		  </text>
		);

		const WlzNaam = (
		  <text x="330" y="315">
		  	<tspan x="330">Wet</tspan>
		  	<tspan x="330" dy="24">langdurige</tspan>
		  	<tspan x="330" dy="24">zorg</tspan>
		  </text>
		);

		const wetten = {
			zvw: {
				naam: ZvwNaam,
				inkoper: "Zorgverzekeraars"
			},
			wlz: {
				naam: WlzNaam,
				inkoper: "Zorgkantoren"
			}
		}

		return(
			<div className="vis-hoofdrolspelers-zorgverzekering svg-container">
				<svg
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMidYMin slice"
					viewBox="0 0 660 600">

						<path className="geldstromen" d="M348,567H81a18.05,18.05,0,0,1-18-18V489c0-9.9,3.09-25.49,6.87-34.64L212.13,109.64C215.91,100.49,227.1,93,237,93H423c9.9,0,21.09,7.49,24.87,16.64L590.13,454.36C593.91,463.51,597,479.1,597,489v60a18.05,18.05,0,0,1-18,18Z"/>

						<g className="kaders">
							<path d="M264,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H264z"/>
							<path className="linksonder" d="M15,547.5c-7.44,0-13.5-6.06-13.5-13.5v-30c0-7.44,6.06-13.5,13.5-13.5h132c7.44,0,13.5,6.06,13.5,13.5v30 c0,7.44-6.06,13.5-13.5,13.5H15z"/>
							<path d="M513,544.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h132c5.79,0,10.5,4.71,10.5,10.5v30c0,5.79-4.71,10.5-10.5,10.5H513z"/>
							<text x="330" y="99">Burgers</text>
							<text x="580" y="525">Zorgaanbieders</text>
							<text className="inkoper" x="80" y="525">{wetten[this.state.wet].inkoper}</text>
						</g>

						<g className="wet">
							<circle cx="330" cy="330" r="81" />
							{wetten[wet].naam}
						</g>

				</svg>

				<ul className="knoppen">
					<li id="zvw" className={(wet === 'zvw') && "active"} onClick={this.handleClick}>Zvw</li>
					<li id="wlz" className={(wet === 'wlz') && "active"} onClick={this.handleClick}>Wlz</li>
				</ul>
			</div>
		)
	}
}

export default HoofdrolspelersZorgverzekering;
