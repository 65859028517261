import React from 'react';

import GeldstromenJW from './visualizations/geldstromen-jw';
import GeldstromenWLZ from './visualizations/geldstromen-wlz';
import GeldstromenWMO from './visualizations/geldstromen-wmo';
import GeldstromenWPG from './visualizations/geldstromen-wpg';
import GeldstromenZVW from './visualizations/geldstromen-zvw';

const vissen = {
	geldstromen_jw: <GeldstromenJW />,
	geldstromen_wlz: <GeldstromenWLZ />,
	geldstromen_wmo: <GeldstromenWMO />,
	geldstromen_wpg: <GeldstromenWPG />,
	geldstromen_zvw: <GeldstromenZVW />,
};

export default vissen;
