import React from 'react';


const Zorgtreden = () => (
	<div className="vis-zorgtreden">

		<div className="trap">
				<div className="trede trede6">
					<h4>Trede 6</h4>
					<h5>Opname in een verzorgings- of verpleeghuis</h5>
					<p>Ouderen die zijn opgenomen in een instelling of die een ‘volledig pakket thuis’ ontvangen.</p>
				</div>

				<div className="trede trede5">
					<h4>Trede 5</h4>
					<h5>Structurele persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis</h5>
					<p>Ouderen die langer dan drie maanden gebruikmaken van zorg zoals wijkverpleging.</p>
				</div>

				<div className="trede trede4">
					<h4>Trede 4</h4>
					<h5>Tijdelijke persoonlijke verzorging of verpleging thuis en/of korte opvang in een tehuis</h5>
					<p>Ouderen die korter dan drie maanden gebruikmaken van zorg zoals wijkverpleging of eerstelijnsverblijf.</p>
				</div>

				<div className="trede trede3">
					<h4>Trede 3</h4>
					<h5>Hulpmiddelen en voorzieningen</h5>
					<p>Ouderen die gebruikmaken van Wmo-zorg en licht afhankelijk zijn van zorg of ondersteuning.</p>
				</div>

				<div className="trede trede2">
					<h4>Trede 2</h4>
					<h5>Bezoek aan een medisch specialist en/of ziekenhuisopname</h5>
					<p>Zelfstandig thuiswonende ouderen die gebruikmaken van medisch-specialistische zorg door een acute zorgvraag of chronische ziekte.</p>
				</div>

				<div className="trede trede1">
					<h4>Trede 1</h4>
					<h5>Gebruik van eerstelijnszorg</h5>
					<p>Zelfstandig thuiswonende ouderen die lichte beperkingen hebben en af en toe naar de huisarts gaan.</p>
				</div>
		</div>

	</div>
);

export default Zorgtreden;


