import React from 'react';
import ColumnChart from '../../../../../../components/ColumnChart/ColumnChart';
import { formatLocale } from "d3";

const GeneesmiddelenUitgaven = () => {
	const localeFormatter = formatLocale({
		decimal: ",",
		thousands: ".",
		"grouping": [3],
		"comma": true,
	});

	const margin = {top: 90, right: 0, bottom: 90, left: 0};
	const formatY = value => localeFormatter.format(",.1f")(value);
	const formatValueToolTip = d => (<span>{d.category}<br/>{localeFormatter.format(",.1f")(d.data.value)} miljard euro</span>);

	const data = [
		{
"year": "2017",
"extramuraal": 4.6,
"intramuraal": 2.1,
},
{
"year": "2018",
"extramuraal": 4.6,
"intramuraal": 2.2,
},
{
"year": "2019",
"extramuraal": 4.8,
"intramuraal": 2.3,
},
{
"year": "2020",
"extramuraal": 4.9,
"intramuraal": 2.5,
},
{
"year": "2021",
"extramuraal": 4.8,
"intramuraal": 2.7,
}
];

	return (
		<div className="vis-geneesmiddelen-uitgaven">

		<ColumnChart
			margin={margin}
			colorBars={["#333","#ddd"]}
			colorAxis="#333"
			labelY="x 1000"
			height={450}
			domain={[0, 7.5]}
			columnPadding={0.5}
			formatY={formatY}
			formatValueToolTip={formatValueToolTip}
			data={data}
			stacked={true}
		/>

		<div className="vis-credit">
			<p>Uitgaven aan geneesmiddelenzorg in miljard euro, uitgesplitst naar extra- en intramurale geneesmiddelen.</p>
			<p>Bron: GIPdatabank, 2022.</p>
		</div>

	</div>
	);
}
export default GeneesmiddelenUitgaven;
