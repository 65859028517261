export default {"tekst": "Hoe werkt de Wet langdurige zorg (Wlz) en wat is relevant voor de huisarts?","items": [{
    "tekst": "Uitleg wet",
    "items": [{
        "tekst": "Deze wet regelt de zorg voor patiënten die blijvend 24-uurs zorg en toezicht nodig hebben"
    }, {
        "tekst": "Deze wet regelt de verzorging en verpleging van patiënten die via Centrum indicatiestelling zorg een indicatie hebben gekregen."
    }, {
        "tekst": "Deze wet regelt de betaling van deze zorg, in natura of via een persoonsgebondenbudget (pgb)."
    }, {
        "tekst": "Deze wet verplicht zorgkantoren voldoende zorg in te kopen of pgb’s te verstrekken."
    }]
}, {
    "tekst": "Huisarts specifiek",
    "items": [{
        "tekst": "De huisarts levert algemeen medische zorg aan patiënten die thuis wonen met een Wlz-indicatie."
    }, {
        "tekst": "De huisarts heeft de regie op zorg voor de thuiswonende patiënt."
    }, {
        "tekst": "Bij specifieke zorg heeft de specialist ouderengeneeskunde of de arts voor verstandelijk gehandicapten de regie."
    }, {
        "tekst": "De huisarts levert in principe geen algemeen medische zorg aan de patiënt die in een Wlz-instelling verblijft."
    }, {
        "tekst": "De Wlz-instelling, zoals een verpleeghuis, kan de huisarts inhuren voor algemeen medische zorg."
    }]
}]}