import React from 'react';
//import { Link } from 'react-router-dom';

const Stappen = () => (
<div className="vis-plaats-verstrekking svg-container">
	<svg viewBox="0 -50 660 500" preserveAspectRatio="xMidYMin meet">
	<g className="thema">
		<polygon points="353.71 125.12 353.71 243.66 353.71 354.29 353.71 378 654 378 654 125.12 353.71 125.12"/>
		<rect x="6" y="268.17" width="442.54" height="94.83"/>
		<path d="M181.67,319H246c0,11.2-7.41,26.41-16.09,32.18H197.76C189.09,345.38,181.67,330.17,181.67,319Zm72.72-37.72a8,8,0,0,0-11.37,0L213.2,311.07H236l18.44-18.44A8.05,8.05,0,0,0,254.39,281.25Z"/>
		<polygon points="497.6 289.1 497.6 257.82 466.32 257.82 466.32 245.31 497.6 245.31 497.6 214.02 510.11 214.02 510.11 245.31 541.39 245.31 541.39 257.82 510.11 257.82 510.11 289.1 497.6 289.1"/>
	</g>
	<g className="tekst">
		<g className="tekst">
			<text transform="translate(505 60.17)">Een patiënt krijgt geneesmiddelen in
				<tspan x="3" y="18">het ziekenhuis als onderdeel van een </tspan>
				<tspan x="10" y="36">behandeling en soms ook voor gebruik </tspan>
				<tspan x="-110" y="54">thuis.</tspan></text>
			<text transform="translate(140 183.55)">Een patiënt kan voorgeschreven 
				<tspan x="-2" y="18">geneesmiddelen ophalen bij de </tspan>
				<tspan x="-42" y="36">openbare apotheek.</tspan>
				<tspan x="10" y="54">Een openbare apotheek kan ook in </tspan>
				<tspan x="-10" y="72">een ziekenhuis gevestigd zijn.</tspan></text>
		</g>
		<g className="subtitel">
			<text transform="translate(415 36)">Intramuraal</text>
			<text transform="translate(70 157.82)">Extramuraal</text>
			<text transform="translate(420 150)">Ziekenhuis</text>
			<text transform="translate(115 291)">Openbare apotheek</text>
		</g>
	</g>
	<g className="tekstBolletje">
		<circle cx="9.51" cy="177.14" r="4.5"/>
		<circle cx="357.22" cy="55.32" r="4.5"/>
		<circle cx="9.51" cy="232.5" r="4.5"/>
	</g>
	</svg>
</div>
);

export default Stappen;
