import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const ZorguitgavenGgz = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.1f')(val);
	
	const formatValueTooltip = value => formatY(value) + " miljard euro";

	const data = [
		{
			name: "zorguitgaven-ggz",
			values: [
				{ x: 2012, y: 6.616},
				{ x: 2013, y: 6.699},
				{ x: 2014, y: 6.745},
				{ x: 2015, y: 6.283},
				{ x: 2016, y: 6.450},
				{ x: 2017, y: 6.511},
				{ x: 2018, y: 6.641}
			]
		},
	];

	return (
		<div className="vis-zorguitgaven-ggz">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[5.5, 8]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Uitgaven aan geestelijke gezondheidszorg in miljard euro, inclusief forensische zorg.</p>
				<p>Bron: CBS Statline.</p>
			</div>
		</div>
	);
};

export default ZorguitgavenGgz;
