import belangrijkstePages from './belangrijkste/pages'
import arbeidsmarktPages from './arbeidsmarkt/pages'
import {COLOR_MAGENTA} from "../../../../vocabulary";

const pages = [
    {
        categorie: "basis",
        template: "intro",
        naam: "spelers",
        titel: "Wie doet wat in de zorg?",
        kleur: COLOR_MAGENTA,
        tekst:
            "Het zorgstelsel is een samenspel van vele partijen. Publiek en privaat. Zorgverlenend en toezichthoudend. Welke spelers dit zijn, wat zij doen en hoe zij zich tot elkaar verhouden, lees je hieronder.",
        links: [
            "belangrijkste",
            "arbeidsmarkt",
        ]
    },
    ...belangrijkstePages,
    ...arbeidsmarktPages,
]

export default pages
