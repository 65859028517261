import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenWFZ = () => (

<div className="vis-geldstromen-wfz svg-container">
	<svg
	xmlns="http://www.w3.org/2000/svg"
	preserveAspectRatio="xMidYMin meet"
	viewBox="0 40 500 600">

	<g className="geldstromen">
		<polygon data-tooltip-content="react-tooltip" transform="translate(-38 -85)" id="tt2" points="295 261 295 225 289 231 283 225 283 225 283 261 283 261 289 267 295 261 295 261"/>
		<polygon data-tooltip-content="react-tooltip" transform="translate(0 -80)" id="tt7" points="256 351 256 315 250 321 244 315 244 315 244 351 244 351 250 357 256 351 256 351"/>
		<path data-tooltip-content="react-tooltip" transform="translate(-23 24)" id="tt10" d="M110,402 L104,408 L98,402 L98,298 C98,284.77 108.09,274 120.5,274 L215,274 L209,280 L215,286 L120.5,286 C114.71,286 110,291.38 110,298 L110,402 Z" />
		<polygon data-tooltip-content="react-tooltip" transform="translate(139 452)" id="tt8" points="2.67234151e-15 0 6 6 2.67234151e-15 12 231 12 237 6 231 0"/>
	</g>

	<Link to={{ pathname: '/ggz/wie-betaalt-wfz', hash: '#Burgers' }} >
		<g className="kader link">
			<rect x="197" y="94" width="106" height="40" rx="8"/>
			<text transform="translate(230 117)">Burgers</text>
		</g>
	</Link>

	<Link to={{ pathname: '/ggz/wie-betaalt-wfz', hash: '#Rijk' }} >
		<g className="kader link">
			<rect x="197" y="188" width="106" height="40" rx="8"/>
			<text transform="translate(240 212)">Rijk</text>
		</g>
	</Link>

	<Link to={{ pathname: '/ggz/wie-betaalt-wfz', hash: '#ZorginstituutNederland' }} >
		<g className="kader link">
			<rect x="197" y="283" width="106" height="40" rx="8"/>
			<text transform="translate(207 308)">Ministerie JenV</text>
		</g>
	</Link>

	<Link to={{ pathname: '/ggz/wie-betaalt-wfz', hash: '#Zorgverzekeraars' }} >
		<g className="kader link" transform="translate(15 0)">
			<rect x="5" y="439" width="115" height="40" rx="8"/>
			<text transform="translate(55 462)">DJI</text>
		</g>
	</Link>

	<Link to={{ pathname: '/ggz/wie-betaalt-wfz', hash: '#NederlandseZorgautoriteit' }} >
		<g className="kaderLicht link" transform="translate(-25 -48)">
			<rect x="4" y="391" width="115" height="40" rx="8" fill="#fff"/>
			<text transform="translate(52.01 414)">NZa</text>
		</g>
	</Link>

	<g className="kader">
		<rect x="380" y="439" width="115" height="40" rx="8"/>
		<text transform="translate(397 462)">Zorgaanbieders</text>
	</g>

	<g className="tekst links regular">
		<text transform="translate(21 520)">
			<tspan x="0" y="0">Belasting</tspan>
			<tspan x="0" y="15">Betaling voor geleverde zorg</tspan>
			<tspan x="278.25" y="0">Budget forenische zorg</tspan>
		</text>
	</g>

	<g className="tekstlabels wit">			
		<path data-number="1"transform="translate(150 -72)" d="M94.25,232.48a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1A6.78,6.78,0,0,1,99,226.2a6.49,6.49,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6.21,6.21,0,0,1,1.32,1,6,6,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.58,6.58,0,0,1,0,3.45,6.68,6.68,0,0,1-.65,1.56,6.43,6.43,0,0,1-2.34,2.33,6.08,6.08,0,0,1-1.55.66,6.57,6.57,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23,6.42,6.42,0,0,1-3.89-3,7.17,7.17,0,0,1-.66-1.56A6.15,6.15,0,0,1,94.25,232.48Zm4.47,1.74v1.27H103v-1.27h-1.29v-4.86h-1.29l-1.8.63v1.31l1.54-.46v3.38Z" />
		<path data-number="2" transform="translate(-208 120)" d="M282.58,242.75a6.16,6.16,0,0,1,.24-1.72,6.68,6.68,0,0,1,.65-1.56,6.15,6.15,0,0,1,1-1.31,6,6,0,0,1,1.32-1,6.08,6.08,0,0,1,1.55-.66,6.62,6.62,0,0,1,1.73-.23,6.16,6.16,0,0,1,1.72.24,6.34,6.34,0,0,1,1.56.65,6.47,6.47,0,0,1,1.32,1,6.87,6.87,0,0,1,1,1.31,6.56,6.56,0,0,1,.88,3.28,6.56,6.56,0,0,1-.88,3.29,6.87,6.87,0,0,1-1,1.31,6.58,6.58,0,0,1-2.87,1.68,6.62,6.62,0,0,1-1.73.23,6.23,6.23,0,0,1-1.73-.24,6.44,6.44,0,0,1-1.55-.65,6,6,0,0,1-1.32-1,6.15,6.15,0,0,1-1-1.31,6.68,6.68,0,0,1-.65-1.56A6.23,6.23,0,0,1,282.58,242.75Zm6.44-3.2a4,4,0,0,0-1.83.43v1.32a3.65,3.65,0,0,1,1.57-.46c.56,0,.85.22.85.65a1.37,1.37,0,0,1-.16.58,4.44,4.44,0,0,1-.48.71c-.21.26-.47.55-.79.88l-1.09,1.09v1h4.34V244.5H290c-.3,0-.61,0-.94,0v0c.37-.34.69-.65,1-.93a7.4,7.4,0,0,0,.68-.78,3.24,3.24,0,0,0,.41-.74,2.2,2.2,0,0,0,.13-.78,1.67,1.67,0,0,0-.16-.76,1.53,1.53,0,0,0-.47-.54,1.85,1.85,0,0,0-.69-.33A2.94,2.94,0,0,0,289,239.55Z" />
		<path data-number="3" transform="translate(160 205)" d="M86.65,253.27a6.62,6.62,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.51,6.51,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,5.93,5.93,0,0,1,1.32,1,6.21,6.21,0,0,1,1,1.32,6.89,6.89,0,0,1,.66,1.55,6.62,6.62,0,0,1,0,3.46,6.89,6.89,0,0,1-.66,1.55,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.58,6.58,0,0,1-3.45,0,6.37,6.37,0,0,1-1.56-.66,6.3,6.3,0,0,1-2.33-2.33,6.89,6.89,0,0,1-.66-1.55A6.62,6.62,0,0,1,86.65,253.27Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.48.48,0,0,1,.26.44.56.56,0,0,1-.08.3.74.74,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.58,2.58,0,0,1-.39,0h-.6v1.21H93a2.38,2.38,0,0,1,.39,0,.89.89,0,0,1,.3.11.58.58,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,3.91,3.91,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.68,1.68,0,0,0,.66-1.41,1.32,1.32,0,0,0-1-1.35v0a1.43,1.43,0,0,0,.64-.57A1.65,1.65,0,0,0,95.39,251.71Z" />
	</g>

	<g className="tekstlabels zwart">
		<path d="M3.66,514.75A6.62,6.62,0,0,1,3.89,513a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,521a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,514.75Zm4.47,1.74v1.28h4.3v-1.28H11.14v-4.86H9.84l-1.8.64v1.31l1.54-.46v3.37Z" fill="#1d1d1b"/>
		<path transform="translate(275 -15)" d="M3.66,529.75A6.62,6.62,0,0,1,3.89,528a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,536a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,529.75Zm6.44-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.56-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.78,4.78,0,0,1-.47.71c-.21.26-.48.55-.79.87l-1.1,1.1v1h4.35v-1.28H11c-.29,0-.61,0-.93,0v0c.37-.34.69-.65,1-.92a6.58,6.58,0,0,0,.69-.79,3,3,0,0,0,.4-.74,2.21,2.21,0,0,0,.14-.78,1.63,1.63,0,0,0-.17-.75,1.53,1.53,0,0,0-.46-.55,2,2,0,0,0-.69-.33A3.43,3.43,0,0,0,10.1,526.54Z" fill="#1d1d1b"/>
		<path transform="translate(0 -14)" d="M3.66,544.75A6.62,6.62,0,0,1,3.89,543a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,551a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,544.75Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.45.45,0,0,1,.26.44.56.56,0,0,1-.08.3.9.9,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.45,2.45,0,0,1-.39,0h-.6v1.21H10a2.34,2.34,0,0,1,.38,0,.89.89,0,0,1,.3.11.51.51,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,4,4,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.69,1.69,0,0,0,.66-1.42,1.31,1.31,0,0,0-.28-.88,1.29,1.29,0,0,0-.7-.46v0a1.38,1.38,0,0,0,.64-.57A1.65,1.65,0,0,0,12.4,543.19Z" fill="#1d1d1b"/>
	</g>
	</svg>

	<ReactTooltip anchorId="tt2" place="right" float>
		<span>1. Belasting</span>
	</ReactTooltip>

	<ReactTooltip anchorId="tt10" place="right" float>
		<span>2. Budget forensische zorg</span>
	</ReactTooltip>

	<ReactTooltip anchorId="tt8" place="right" float>
		<span>3. Betaling voor geleverde zorg</span>
	</ReactTooltip>

</div>
);

export default GeldstromenWFZ;


