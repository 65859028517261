import React from 'react';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import {useNavigate, useParams} from "react-router";

import SocialHeader from '../SocialHeader/SocialHeader';
import Header from '../Header/Header';
import PageContent from './PageContent';
import icons from '../Icons/icons';
import restricted from '../../data/restricted';

import './Page.scss';

const Page = (props) => {
    const params = useParams()
    const navigate = useNavigate()

    const { organisation, menuopen, pages, access } = props

    const category = params.category || 'basis';
    const auth = restricted.indexOf(category) === -1 || access.indexOf(category) > -1;

    if (!auth) {
        navigate('/specials');
    }

    const pageName = params.pageName ? params.pageName.replace(".html","") : null;

    const matchedPage = pages.filter((pagina) => pagina.categorie === category && pagina.naam === pageName);
    const thisPage = (pageName && matchedPage.length === 1) ? matchedPage[0] : pages[0];

    const withLogo = !!(organisation && icons[organisation]);

    let paginaKlasse = "pagina kleur" + thisPage.kleur + " " + thisPage.naam + " " + thisPage.template + "-template";
    if (withLogo) paginaKlasse = paginaKlasse + " met-logo";
    if (menuopen) paginaKlasse = "pagina menu";

    const organisationLogo = organisation && icons[organisation.name] ?
        <span className="tab-logo-partner">
          <a href={organisation.url} target="_blank" rel="noopener noreferrer">
            {icons[organisation.name]}
          </a>
        </span> : null;

    return (
      <div className={paginaKlasse} role="main" >

        <SocialHeader template={thisPage.template} naam={thisPage.naam} titel={thisPage.titel} category={thisPage.categorie} />
        <Header page={thisPage} {...props}  />

        <ScrollToTop>
          <PageContent key={pageName} page={thisPage} withLogo={withLogo} {...props}  />
        </ScrollToTop>

        {organisationLogo}
      </div>
    )
}

export default Page;
