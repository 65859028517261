import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const GeldstromenJeugdwet = () => (
    <div className="vis-geldstromen-jeugdwet svg-container">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMin meet"
            viewBox="0 0 660 760">

            <g className="geldstromen">
                <path data-tooltip-content="react-tooltip" id="tt1" d="M642,489v18l-9,9l-9-9v-18c0-8.55-3.1-22.88-6.63-30.67L455.24,101.11C452.6,95.29,444.39,90,438,90h-30l9-9 l-9-9h30c13.35,0,28.12,9.52,33.63,21.67l162.13,357.22C638.38,461.08,642,477.81,642,489z"/>
                <path data-tooltip-content="react-tooltip" id="tt2" d="M339,240v273c0,14.89-12.11,27-27,27H162l-9-9l9-9h150c4.96,0,9-4.04,9-9V240l9,9L339,240z"/>
                <polygon data-tooltip-content="react-tooltip" id="tt3" points="507,579 498,588 153,588 162,579 153,570 498,570 	"/>
                <path data-tooltip-content="react-tooltip" id="tt4" d="M495,453v84c0,14.89-12.11,27-27,27H153l9-9l-9-9h315c4.96,0,9-4.04,9-9v-84l9-9L495,453z"/>
                <path data-tooltip-content="react-tooltip" id="tt5" d="M618,489v18l-9,9l-9-9v-18c0-5.36-1.94-14.33-4.16-19.2l-20.07-44.15c-1.27-2.8-5.7-5.65-8.77-5.65h-36l9-9 l-9-9h36c10.14,0,20.96,6.96,25.16,16.2l20.07,44.15C615.46,469.47,618,481.18,618,489z"/>
                <path data-tooltip-content="react-tooltip" id="tt6" d="M492.68,319.57l-37.65-204.4C453.04,104.42,442.93,96,432,96h-15l-9,9l9,9h15c2.27,0,4.91,2.19,5.32,4.43 l37.65,204.4c1.1,5.96,2.03,16.11,2.03,22.17v33h0l9-9l9,9h0v-33C495,337.81,493.98,326.64,492.68,319.57z"/>
            </g>

            <Link to={{ pathname: '/wie-betaalt-jw', hash: '#Burgers'}} >
                <g className="kader link">
                    <path d="M270,118.5c-5.79,0-10.5-4.71-10.5-10.5V78c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
                    <text className="tekst centreer" x="330" y="100">Burgers</text>
                </g>
            </Link>

            <Link to={{ pathname: '/wie-betaalt-jw', hash: '#Rijk'}} >
                <g className="kader link">
                    <path d="M270,232.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H270z"/>
                    <text className="tekst centreer" x="330" y="213">Rijk</text>
                </g>
            </Link>

            <Link to={{ pathname: '/wie-betaalt-jw', hash: '#Gemeenten'}} >
                <g className="kader link">
                    <path d="M15,586.5c-5.79,0-10.5-4.71-10.5-10.5v-42c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v42 c0,5.79-4.71,10.5-10.5,10.5H15z"/>
                    <text className="tekst centreer" x="75" y="561">Gemeenten</text>
                </g>
            </Link>

            <Link to={{ pathname: '/wie-betaalt-jw', hash: '#SocialeVerzekeringsbank'}} >
                <g className="kader link">
                    <path d="M462,436.5c-5.79,0-10.5-4.71-10.5-10.5v-30c0-5.79,4.71-10.5,10.5-10.5h48c5.79,0,10.5,4.71,10.5,10.5v30 c0,5.79-4.71,10.5-10.5,10.5H462z"/>
                    <text className="tekst centreer" x="485" y="417">SVB</text>
                </g>
            </Link>

            <g className="kader">
                <path d="M525,586.5c-5.79,0-10.5-4.71-10.5-10.5v-42c0-5.79,4.71-10.5,10.5-10.5h120c5.79,0,10.5,4.71,10.5,10.5v42 c0,5.79-4.71,10.5-10.5,10.5H525z"/>
                <text className="tekst centreer">
                    <tspan x="585" y="553">Aanbieders</tspan>
                    <tspan x="585" y="571">jeugdzorg</tspan>
                </text>
            </g>

            <g className="tekst links regular">
                <text transform="matrix(1 0 0 1 45 618.0001)">
                    <tspan x="0" y="0">Eigen betalingen</tspan>
                    <tspan x="0" y="18">Rijksbijdrage</tspan>
                    <tspan x="0" y="36">Betaling geleverde zorg</tspan>
                    <tspan x="319.5" y="0">Middelen persoonsgebonden budget</tspan>
                    <tspan x="319.5" y="18">Betaling geleverde zorg</tspan>
                    <tspan x="319.5" y="36">Betaling aan ouders/verzorgers die zelf</tspan>
                    <tspan x="319.5" y="54">de zorg verlenen aan hun kind</tspan>
                </text>
            </g>

            <g className="tekstlabels wit">
                <path d="M584.12,445.78c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S584.12,446.73,584.12,445.78z M594.25,442.98h3.57v-2.04h-5.59l-0.77,5.48 c0.54-0.09,1.07-0.14,1.58-0.14c0.42,0,0.78,0.03,1.06,0.08s0.51,0.12,0.69,0.22s0.3,0.22,0.38,0.37s0.12,0.32,0.12,0.5 c0,0.22-0.05,0.41-0.15,0.57s-0.24,0.3-0.4,0.4s-0.36,0.18-0.58,0.23s-0.45,0.08-0.69,0.08c-0.4,0-0.77-0.04-1.12-0.11 s-0.71-0.19-1.09-0.35v2.11c0.3,0.12,0.64,0.23,1.03,0.3s0.85,0.12,1.39,0.12c0.65,0,1.23-0.08,1.75-0.25s0.95-0.4,1.31-0.7 s0.64-0.66,0.83-1.08s0.29-0.87,0.29-1.37c0-0.41-0.06-0.79-0.19-1.14s-0.34-0.66-0.62-0.93s-0.65-0.47-1.1-0.62 s-0.98-0.23-1.62-0.23c-0.05,0-0.15,0-0.28,0.01L594.25,442.98z"/>
                <path d="M319.59,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S319.59,309.31,319.59,308.35z M329.89,303.22c-1.05,0.01-2.03,0.24-2.93,0.69v2.11 c0.99-0.48,1.83-0.73,2.51-0.73c0.91,0,1.36,0.34,1.36,1.03c0,0.29-0.09,0.6-0.26,0.94s-0.43,0.71-0.76,1.13s-0.76,0.88-1.26,1.4 s-1.09,1.1-1.75,1.76v1.63h6.95v-2.04h-2.36c-0.48,0-0.98,0.02-1.5,0.07l-0.01-0.04c0.59-0.55,1.1-1.04,1.54-1.48 s0.81-0.86,1.1-1.26s0.51-0.79,0.65-1.18s0.22-0.8,0.22-1.25c0-0.46-0.09-0.87-0.27-1.21s-0.43-0.64-0.74-0.87 s-0.68-0.41-1.11-0.53S330.38,303.22,329.89,303.22z"/>
                <path d="M548.63,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S548.63,309.31,548.63,308.35z M555.79,311.14v2.04h6.87v-2.04h-2.06v-7.78h-2.07 l-2.88,1.02v2.1l2.47-0.74v5.4H555.79z"/>
                <path d="M475.5,504.48c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11 c-0.63,0.63-1.34,1.18-2.11,1.63s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05 s-1.48-0.99-2.11-1.62c-0.63-0.63-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S475.5,505.43,475.5,504.48z M486.87,499.64h-2.48 l-2.43,6.22v1.56h4.11v1.89h2.37v-1.89h1.17v-1.99h-1.17v-1.46h-2.37v1.46h-0.36c-0.51,0-0.95,0.02-1.32,0.06l-0.01-0.04 c0.23-0.38,0.55-1.06,0.95-2.03L486.87,499.64z"/>
                <path d="M319.59,579.47c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S319.59,580.43,319.59,579.47z M333.57,576.98c0-0.8-0.32-1.45-0.96-1.92 s-1.55-0.71-2.74-0.71c-0.82,0-1.66,0.14-2.53,0.41v2.06c0.34-0.13,0.69-0.24,1.06-0.31s0.75-0.11,1.13-0.11 c0.44,0,0.79,0.07,1.07,0.22s0.41,0.38,0.41,0.7c0,0.19-0.04,0.35-0.13,0.47s-0.21,0.23-0.36,0.32s-0.33,0.14-0.53,0.18 s-0.41,0.06-0.63,0.06h-0.95v1.95h1.27c0.22,0,0.42,0.02,0.61,0.05s0.35,0.09,0.49,0.17s0.25,0.19,0.33,0.32s0.12,0.29,0.12,0.5 c0,0.35-0.15,0.63-0.44,0.83s-0.74,0.3-1.33,0.3c-0.45,0-0.87-0.04-1.26-0.13s-0.73-0.19-1-0.32v2.09 c0.72,0.27,1.52,0.41,2.39,0.41c1.38,0,2.43-0.28,3.13-0.83s1.06-1.31,1.06-2.27c0-0.59-0.15-1.06-0.44-1.42s-0.67-0.61-1.13-0.74 v-0.05c0.47-0.22,0.81-0.52,1.03-0.91S333.57,577.47,333.57,576.98z"/>
                <path d="M471.07,308.35c0-0.95,0.12-1.87,0.38-2.76s0.6-1.72,1.05-2.49s0.99-1.48,1.62-2.11s1.34-1.18,2.11-1.63 s1.6-0.8,2.49-1.05s1.81-0.37,2.76-0.37s1.87,0.12,2.76,0.38s1.72,0.6,2.49,1.05s1.48,0.99,2.11,1.62s1.18,1.34,1.63,2.11 s0.8,1.6,1.05,2.48s0.37,1.8,0.37,2.77c0,0.95-0.12,1.87-0.37,2.76s-0.6,1.72-1.05,2.49s-1,1.48-1.63,2.11s-1.34,1.18-2.11,1.63 s-1.6,0.8-2.48,1.05s-1.8,0.37-2.77,0.37c-0.95,0-1.87-0.12-2.76-0.38s-1.72-0.6-2.49-1.05s-1.48-0.99-2.11-1.62 s-1.17-1.34-1.62-2.11s-0.8-1.6-1.05-2.49S471.07,309.31,471.07,308.35z M481.8,307.04c-0.4,0-0.75,0.04-1.07,0.12 s-0.57,0.17-0.77,0.28l-0.04-0.04c0.11-0.74,0.38-1.28,0.8-1.62s0.95-0.51,1.59-0.51c0.37,0,0.75,0.04,1.15,0.12 s0.75,0.17,1.04,0.28v-2.02c-0.21-0.1-0.53-0.2-0.96-0.29s-0.88-0.14-1.34-0.14c-0.84,0-1.55,0.14-2.15,0.43s-1.09,0.68-1.47,1.18 s-0.66,1.09-0.84,1.76s-0.27,1.4-0.27,2.16c0,0.77,0.1,1.45,0.3,2.03s0.47,1.06,0.83,1.45s0.77,0.68,1.24,0.87 s0.99,0.29,1.54,0.29c0.56,0,1.07-0.08,1.52-0.24s0.83-0.39,1.15-0.68s0.57-0.65,0.74-1.06s0.26-0.87,0.26-1.37 c0-0.46-0.08-0.88-0.24-1.25s-0.38-0.69-0.67-0.94s-0.63-0.46-1.03-0.6S482.28,307.04,481.8,307.04z M481.39,311.34 c-0.21,0-0.41-0.05-0.59-0.14s-0.34-0.23-0.47-0.4s-0.24-0.38-0.32-0.64s-0.12-0.54-0.12-0.85c0.2-0.11,0.42-0.2,0.66-0.26 s0.47-0.09,0.7-0.09c0.44,0,0.77,0.11,1,0.32s0.34,0.48,0.34,0.81c0,0.41-0.11,0.72-0.33,0.93S481.76,311.34,481.39,311.34z"/>
            </g>

            <g className="tekstlabels zwart">
                <path d="M24.7,612.57a6.73,6.73,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.74,6.74,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.14,7.14,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,612.57Zm4.77,1.86v1.36h4.58v-1.36H32.68v-5.19H31.3l-1.92.68v1.4l1.65-.49v3.6Z"/>
                <path d="M24.7,630.57a6.73,6.73,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.74,6.74,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.14,7.14,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,630.57Zm6.87-3.42a4.35,4.35,0,0,0-2,.46V629a4,4,0,0,1,1.67-.48q.91,0,.91.69a1.39,1.39,0,0,1-.17.63,4.58,4.58,0,0,1-.51.75q-.34.42-.84.93l-1.17,1.17v1.09h4.63v-1.36H32.57q-.48,0-1,0v0q.59-.55,1-1a7.15,7.15,0,0,0,.73-.84,3.36,3.36,0,0,0,.43-.79,2.36,2.36,0,0,0,.14-.83,1.74,1.74,0,0,0-.18-.81,1.7,1.7,0,0,0-.49-.58,2.18,2.18,0,0,0-.74-.35A3.45,3.45,0,0,0,31.57,627.15Z"/>
                <path d="M24.7,648.57a6.73,6.73,0,0,1,.25-1.84,7.14,7.14,0,0,1,.7-1.66,6.83,6.83,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.74,6.74,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.2,7.2,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.14,7.14,0,0,1-.7-1.66A6.73,6.73,0,0,1,24.7,648.57ZM34,646.91a1.52,1.52,0,0,0-.64-1.28,3,3,0,0,0-1.82-.48,5.59,5.59,0,0,0-1.69.27v1.38a4.1,4.1,0,0,1,.71-.21,3.81,3.81,0,0,1,.75-.07,1.49,1.49,0,0,1,.71.15.5.5,0,0,1,.27.47.54.54,0,0,1-.09.32.69.69,0,0,1-.24.21,1.25,1.25,0,0,1-.35.12,2.15,2.15,0,0,1-.42,0h-.63v1.3h.84a2.51,2.51,0,0,1,.41,0,1,1,0,0,1,.33.11.61.61,0,0,1,.22.21.69.69,0,0,1-.21.89,1.56,1.56,0,0,1-.89.2,3.92,3.92,0,0,1-.84-.09,3.35,3.35,0,0,1-.67-.21v1.39a4.44,4.44,0,0,0,1.59.27,3.36,3.36,0,0,0,2.09-.55,1.81,1.81,0,0,0,.71-1.51,1.45,1.45,0,0,0-.29-.95,1.48,1.48,0,0,0-.75-.49v0a1.47,1.47,0,0,0,.69-.61A1.78,1.78,0,0,0,34,646.91Z"/>
                <path d="M344.2,613a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,613Zm7.58-3.23h-1.66L348.51,614v1h2.74v1.26h1.58V615h.78v-1.33h-.78v-1h-1.58v1H351a8.56,8.56,0,0,0-.88,0v0a10.71,10.71,0,0,0,.63-1.35Z"/>
                <path d="M344.2,631a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,631Zm6.76-1.87h2.38v-1.36h-3.73l-.52,3.66a6.15,6.15,0,0,1,1.05-.09,4.1,4.1,0,0,1,.71.05,1.44,1.44,0,0,1,.46.15.62.62,0,0,1,.25.25.77.77,0,0,1,0,.72.83.83,0,0,1-.27.27,1.27,1.27,0,0,1-.39.16,2,2,0,0,1-.46.05,3.6,3.6,0,0,1-.75-.07,4.09,4.09,0,0,1-.73-.23v1.41a3.61,3.61,0,0,0,.68.2,4.77,4.77,0,0,0,.93.08,3.74,3.74,0,0,0,1.16-.17,2.61,2.61,0,0,0,.88-.47,2.07,2.07,0,0,0,.55-.72,2.13,2.13,0,0,0,.2-.91,2.22,2.22,0,0,0-.13-.76,1.54,1.54,0,0,0-.41-.62,2,2,0,0,0-.73-.41,3.35,3.35,0,0,0-1.08-.15h-.19Z"/>
                <path d="M344.2,649a6.73,6.73,0,0,1,.25-1.84,7.16,7.16,0,0,1,.7-1.66,6.85,6.85,0,0,1,1.08-1.41,6.93,6.93,0,0,1,3.07-1.79,6.84,6.84,0,0,1,1.84-.25,6.73,6.73,0,0,1,1.84.25,7.16,7.16,0,0,1,1.66.7,6.85,6.85,0,0,1,1.41,1.08,6.94,6.94,0,0,1,1.79,3.06,7,7,0,0,1,0,3.68,6.95,6.95,0,0,1-4.85,4.85,7,7,0,0,1-3.68,0,7.21,7.21,0,0,1-1.66-.7,6.87,6.87,0,0,1-2.49-2.49,7.16,7.16,0,0,1-.7-1.66A6.73,6.73,0,0,1,344.2,649Zm7.16-.87a3,3,0,0,0-.71.08,2.23,2.23,0,0,0-.52.19l0,0A1.48,1.48,0,0,1,351.7,647a4,4,0,0,1,.77.08,4.51,4.51,0,0,1,.7.19v-1.34a3.11,3.11,0,0,0-.64-.2,4.16,4.16,0,0,0-.89-.09,3.27,3.27,0,0,0-1.43.29,2.68,2.68,0,0,0-1,.79,3.32,3.32,0,0,0-.56,1.18,5.57,5.57,0,0,0-.18,1.44,4.15,4.15,0,0,0,.2,1.35,2.74,2.74,0,0,0,.55,1,2.24,2.24,0,0,0,.83.58,2.71,2.71,0,0,0,1,.19,3,3,0,0,0,1-.16,2.23,2.23,0,0,0,.77-.45,2,2,0,0,0,.49-.71,2.37,2.37,0,0,0,.17-.91,2.08,2.08,0,0,0-.16-.83,1.79,1.79,0,0,0-.45-.63,2,2,0,0,0-.68-.4A2.61,2.61,0,0,0,351.36,648.16Zm-.27,2.87a.83.83,0,0,1-.39-.09,1,1,0,0,1-.31-.27,1.32,1.32,0,0,1-.21-.43,1.93,1.93,0,0,1-.08-.57,2,2,0,0,1,.44-.17,1.8,1.8,0,0,1,.47-.06.94.94,0,0,1,.66.21.7.7,0,0,1,.23.54.83.83,0,0,1-.22.62A.8.8,0,0,1,351.09,651Z"/>
            </g>

        </svg>

        <Tooltip id="tt1" place="left" float>
            <span>1. Eigen betalingen</span>
        </Tooltip>

        <Tooltip id="tt2" place="right" float>
            <span>2. Rijksbijdrage</span>
        </Tooltip>

        <Tooltip id="tt3" place="right" float>
            <span>3. Betaling geleverde zorg</span>
        </Tooltip>

        <Tooltip id="tt4" place="right" float>
            <span>4. Middelen persoonsgebonden budget</span>
        </Tooltip>

        <Tooltip id="tt5" place="left" float>
            <span>5. Betaling geleverde zorg</span>
        </Tooltip>

        <Tooltip id="tt6" place="left" float>
            <span>6. Betaling aan ouders/verzorgers die<br />zelf de zorg verlenen aan hun kind</span>
        </Tooltip>
    </div>
);

export default GeldstromenJeugdwet;
