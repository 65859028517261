import React from 'react';
import './Glossary.scss';

const Glossary = (props) => {
return (
	<div className="begrippenkolom">
		<div className="kop">{props.page.titel}</div>
		<dl>
			<dt>ACM</dt><dd>Autoriteit Consument & Markt</dd>
			<dt>AFM</dt><dd>Autoriteit Financiële Markten</dd>
			<dt>aios </dt><dd>arts in opleiding tot specialist </dd>
			<dt>AVG</dt><dd>Algemene Verordening Gegevensbescherming</dd>
			<dt>bbp</dt><dd>bruto binnenlands product</dd>
			<dt>BIG</dt><dd>Beroepen in de individuele gezondheidszorg</dd>
			<dt>BKZ</dt><dd>Budgettair Kader Zorg</dd>
			<dt>BoZ</dt><dd>Brancheorganisaties Zorg</dd>
			<dt>CAHAG</dt><dd>Huisartsen Advies Groep voor COPD en Astma</dd>
			<dt>CAK</dt><dd>Centraal Administratie Kantoor</dd>
			<dt>CBS</dt><dd>Centraal Bureau voor de Statistiek</dd>
			<dt>CBP</dt><dd>Centraal Planbureau</dd>
			<dt>CGS</dt><dd>College Geneeskundige Specialismen</dd>
			<dt>CHBB</dt><dd>College voor Huisartsen met Bijzondere Bekwaamheden</dd>
			<dt>CHO</dt><dd>Consilium Huisartsopleiding</dd>
			<dt>CIBG</dt><dd>Centraal Informatiepunt Beroepen Gezondheidszorg</dd>
			<dt>CIZ</dt><dd>Centrum Indicatiestelling Zorg</dd>
			<dt>COPD</dt><dd>longziekte, Chronic Obstructive Pulmonary Disease</dd>
			<dt>DBC</dt><dd>diagnose-behandelcombinatie, vervangen door DOT</dd>
			<dt>DHW</dt><dd>Drank- en Horecawet</dd>
			<dt>DiHAG</dt><dd>Huisartsen Advies Groep voor Diabetes</dd>
			<dt>dip</dt><dd>deskundige infectiepreventie</dd>
			<dt>DNB</dt><dd>De Nederlandsche Bank</dd>
			<dt>DOT</dt><dd>DBC's op weg naar transparantie</dd>
			<dt>DTO</dt><dd>Diagnostisch toets overleg</dd>
			<dt>Flz</dt><dd>Fonds Langdurige zorg</dd>
			<dt>FMS</dt><dd>Federatie voor Medisch Specialisten</dd>
			<dt>FTO</dt><dd>Farmacotherapeutisch overleg</dd>
			<dt>GEZ</dt><dd>geïntegreerde eerstelijnszorg</dd>
			<dt>ggd</dt><dd>gemeentelijke gezondheidsdienst</dd>
			<dt>ggz</dt><dd>geestelijke gezondheidszorg</dd>
			<dt>GHOR</dt><dd>Gemeentelijke Hulpverleningsorganisatie in de Regio</dd>
			<dt>GIDS</dt><dd>Programma 'Gezond in de Stad'</dd>
			<dt>GLI</dt><dd>Geneeskundige Leefstijlinterventie</dd>
			<dt>HAG</dt><dd>Huisartsen Advies Groep</dd>
			<dt>Hagro</dt><dd>Huisartsengroep</dd>
			<dt>HAP </dt><dd>huisartsenpost</dd>
			<dt>Hid </dt><dd>huisarts in dienst van een instelling</dd>
			<dt>Hidha </dt><dd>huisarts in dienst van een huisarts</dd>
			<dt>iBMG</dt><dd>instituut Beleid & Management Gezondheidszorg</dd>
			<dt>IGJ</dt><dd>Inspectie Gezondheidszorg en Jeugd</dd>
			<dt>IGZ</dt><dd>Inspectie voor de Gezondheidszorg (nu IGJ)</dd>
			<dt>IJZ</dt><dd>Inspectie Jeugdzorg (nu IGJ)</dd>
			<dt>IOH</dt><dd>Interfacultair Overleg Huisartsgeneeskunde</dd>
			<dt>IPSE</dt><dd>Instituut voor Publieke Sector Efficiëntie Studies</dd>
			<dt>JOGG</dt><dd>Jongeren op Gezond Gewicht</dd>
			<dt>KAMG</dt><dd>Koepel Artsen Maatschappij en Gezondheid</dd>
			<dt>KING</dt><dd>Kwaliteitsinstituut Nederlandse Gemeenten</dd>
			<dt>KNAW</dt><dd>Koninklijke Nederlandse Akademie van Wetenschappen</dd>
			<dt>KNGF</dt><dd>Koninklijk Nederlands Genootschap voor Fysiotherapie</dd>
			<dt>KNMG</dt><dd>Koninklijke Nederlandsche Maatschappij tot bevordering der Geneeskunst</dd>
			<dt>KNMP</dt><dd>Koninklijke Nederlandse Maatschappij ter bevordering der Pharmacie</dd>
			<dt>KNMT</dt><dd>Koninklijke Nederlandse Maatschappij ter bevordering der Tandheelkunde</dd>
			<dt>LAD</dt><dd>Landelijke Vereniging van Artsen in Dienstverband</dd>
			<dt>LHOV</dt><dd>Landelijke Huisartsen Opleiders Vereniging</dd>
			<dt>LHV</dt><dd>Landelijke Huisartsen Vereniging</dd>
			<dt>LINKH</dt><dd>Landelijk implementatie netwerk kwaliteitsbeleid huisartsenzorg</dd>
			<dt>LOVAH</dt><dd>Landelijke Organisatie van Aspirant Huisartsen</dd>
			<dt>LV POH-GGZ</dt><dd>Landelijke Vereniging POH-GGZ</dd>
			<dt>M&I Verrichtingen</dt><dd>Modernisering & Innovatie Verrichtingen</dd>
			<dt>MPT</dt><dd>Modulair Pakket Thuis</dd>
			<dt>NAPA</dt><dd>Nederlandse Associatie Physician Assistants</dd>
			<dt>NCJ</dt><dd>Nederlands Centrum Jeugdgezondheid</dd>
			<dt>NFU</dt><dd>Nederlandse Federatie van Universitair Medische Centra</dd>
			<dt>NHG</dt><dd>Nederlands Huisartsengenootschap</dd>
			<dt>NJi</dt><dd>Nederlands Jeugdinstituut</dd>
			<dt>NKI</dt><dd>Nederlands Kanker Instituut</dd>
			<dt>NPA</dt><dd>NHG Praktijk Accreditering</dd>
			<dt>NPP</dt><dd>Nationale Programma Preventie</dd>
			<dt>NVDA</dt><dd>Nederlandse Vereniging van Doktersassistenten</dd>
			<dt>NVK</dt><dd>Nederlandse Vereniging voor Kindergeneeskunde</dd>
			<dt>NVU</dt><dd>Nederlandse Vereniging voor Urologie</dd>
			<dt>NVvPO</dt><dd>Nederlandse Vereniging van Praktijkondersteuners</dd>
			<dt>NVZ</dt><dd>Nederlandse Vereniging van Ziekenhuizen</dd>
			<dt>NVWA</dt><dd>Nederlandse Voedsel en Warenautoriteit</dd>
			<dt>NZa</dt><dd>Nederlandse Zorgautoriteit</dd>
			<dt>pgb</dt><dd>persoonsgebonden budget</dd>
			<dt>POH </dt><dd>praktijkondersteuner</dd>
			<dt>PREM</dt><dd>Patient Reported Experience Measures</dd>
			<dt>PROM</dt><dd>Patient Reported Outcome Measures</dd>
			<dt>RGS</dt><dd>Registratiecommissie Geneeskundig Specialisten</dd>
			<dt>RAV's</dt><dd>Regionale Ambulancevoorzieningen</dd>
			<dt>RIVM</dt><dd>Rijksinstituut voor Volksgezondheid en Milieu</dd>
			<dt>RMO</dt><dd>Raad voor Maatschappelijke Ontwikkeling</dd>
			<dt>ROS </dt><dd>regionale ondersteuningsstructuur</dd>
			<dt>RVP</dt><dd>Rijksvaccinatieprogramma</dd>
			<dt>RVS</dt><dd>Raad voor Volkgezondheid en Samenleving</dd>
			<dt>RVZ</dt><dd>Raad voor Volksgezondheid en Zorg</dd>
			<dt>SCEN-arts</dt><dd>arts Steun en Consultatie bij Euthanasie in Nederland</dd>
			<dt>SCP</dt><dd>Sociaal en Cultureel Planbureau</dd>
			<dt>SEH</dt><dd>Spoedeisende Hulp</dd>
			<dt>SGF</dt><dd>Samenwerkende Gezondheidsfondsen</dd>
			<dt>SKGZ</dt><dd>Stichting Klachten en Geschillen Zorgverzekeringen</dd>
			<dt>SOGEON</dt><dd>Stichting Sociaal Geneeskundige Opleidingen Nederland</dd>
			<dt>SVB</dt><dd>Sociale Verzekeringbank</dd>
			<dt>TAJ</dt><dd>Transitie Autoriteit Jeugd</dd>
			<dt>UMC</dt><dd>Universitair Medisch Centrum</dd>
			<dt>V&VN</dt><dd>Verpleegkundigen & Verzorgenden Nederland</dd>
			<dt>VGN</dt><dd>Vereniging Gehandicaptenzorg Nederland</dd>
			<dt>VNG</dt><dd>Vereniging van Nederlandse Gemeenten</dd>
			<dt>VOG</dt><dd>Verklaring Omtrent het Gedrag</dd>
			<dt>VPH </dt><dd>Vereniging Praktijkhoudende Huisartsen</dd>
			<dt>VPT</dt><dd>Volledig Pakket Thuis</dd>
			<dt>VTV</dt><dd>Volksgezondheid Toekomst Verkenning</dd>
			<dt>VWS</dt><dd>Ministerie van Volksgezondheid, Welzijn en Sport</dd>
			<dt>Wagro</dt><dd>Waarneemgroep</dd>
			<dt>Wbmv</dt><dd>Wet op bijzondere medische verrichtingen</dd>
			<dt>Wbo</dt><dd>Wet op het bevolkingsonderzoek</dd>
			<dt>Wbp</dt><dd>Wet bescherming persoonsgegevens</dd>
			<dt>WDH</dt><dd>Werkgroep Deskundigheidsbevordering Huisartsen</dd>
			<dt>Wet BIG</dt><dd>Wet op de beroepen in de individuele gezondheidszorg</dd>
			<dt>Wet bopz</dt><dd>Wet bijzondere opnemingen in psychiatrische ziekenhuizen</dd>
			<dt>Wgbo</dt><dd>Wet op de geneeskundige behandelingsovereenkomst</dd>
			<dt>Wkkgz</dt><dd>Wet kwaliteit, klachten en geschillen zorg</dd>
			<dt>Wlz </dt><dd>Wet langdurige zorg</dd>
			<dt>Wmg</dt><dd>Wet marktordening gezondheidszorg</dd>
			<dt>Wmo</dt><dd>Wet maatschappelijke ondersteuning </dd>
			<dt>Wpg</dt><dd>Wet publieke gezondheid</dd>
			<dt>WRR</dt><dd>Wetenschappelijke Raad voor het Regeringsbeleid</dd>
			<dt>Wtzi</dt><dd>Wet toelating zorginstellingen</dd>
			<dt>Wvggz</dt><dd>Wet verplichte ggz</dd>
			<dt>Wvr</dt><dd>Wet veiligheidsregio’s</dd>
			<dt>Wzd</dt><dd>Wet zorg en dwang</dd>
			<dt>zbc</dt><dd>zelfstandig behandelcentrum</dd>
			<dt>ZIS</dt><dd>Ziekenhuisinformatiesysteem</dd>
			<dt>ZKN</dt><dd>Zelfstandige Klinieken Nederland</dd>
			<dt>ZN</dt><dd>Zorgverzekeraars Nederland</dd>
			<dt>ZonMw</dt><dd>ZorgOnderzoek Nederland/Medische Wetenschappen</dd>
			<dt>Zvf</dt><dd>Zorgverzekeringsfonds</dd>
			<dt>Zvw </dt><dd>Zorgverzekeringswet</dd>
		</dl>
	</div>
	)
}

export default Glossary;
