import React from 'react';

const Hart = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
	<path d="M68.46,34v6.54H75v2.61H68.46v6.54H65.85V43.15H59.31V40.54h6.54V34h2.61m2.15-2.15H63.7v6.54H57.16V45.3H63.7v6.54h6.91V45.3h6.54V38.39H70.61V31.85Z" strokeWidth=".5" />
  <path d="M50,34.44c-10-19.62-35-9-35,10.6S50,84,50,84,85,64.65,85,45,60,14.82,50,34.44Z" fill="none" strokeMiterlimit="10" strokeWidth="2.5"/>
  </svg>
);

export default Hart;


