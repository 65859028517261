import React from 'react';
import Donut from '../../../components/Donut/Donut';
import {formatLocale} from "d3";

const GemeentenInkomsten = () => {
	const data = [
			{
	        "label": "Overige middelen",
	        "labelFull": "Zoals gemeentelijke heffingen, grondexploitatie en specifieke uitkeringen van het Rijk",
	        "value": 32.1,
	    },
			{
	        "label": "Stortingen van het Rijk",
	        "labelFull": "Stortingen van het Rijk in het Gemeentefonds",
	        "value": 32.5,
	    }
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => item.label + ': ' + localeFormatter.format(',.1f')(item.value);

	const formatTooltip = item => item.labelFull;

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
      <tspan x={0} dy={-15}>Totale inkomsten</tspan>
      <tspan x={0} dy={15}>64,6 miljard</tspan>
       <tspan x={0} dy={15}>euro</tspan>
    </text>);

	return (
		<div className="vis-gemeenten-inkomsten">
			<Donut
				data={data}
				height={400}
				fontFamily="din-regular"
				fontSize="14px"
				formatTooltip={formatTooltip}
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				unit="procent"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Totale inkomsten van gemeenten in 2021 in miljard euro.</p>
				<p>Bron: CBS Statline, 2021. Rijksbegroting, 2021.</p>
			</div>

		</div>
	);
}

export default GemeentenInkomsten;


