import React from 'react';

import Rationale from './visualizations/rationale';
import KeyPlayers from './visualizations/key-players';
import Funds from './visualizations/funds';
import Size from './visualizations/size';
import Employment from './visualizations/employment';
import Score from './visualizations/score';
import AgeHealth from './visualizations/age-health';
import Average from './visualizations/average';

const vissen = {
  rationale: <Rationale/>,
  key_players: <KeyPlayers/>,
  funds: <Funds/>,
  size: <Size/>,
  employment: <Employment/>,
  score: <Score/>,
  age_health: <AgeHealth/>,
  average: <Average/>,
};

export default vissen;
