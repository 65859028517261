import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../components/BarChart/BarChart';

const UitgavenLeeftijdsgroep = () => {


  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValue1 = val => localeFormatter.format(',.0f')(val);
  const formatValue2 = val => localeFormatter.format(',.1f')(val/1000000000) + ' miljard';
  const formatValue3 = val => localeFormatter.format(',.0f')(Math.round(val/1000)*1000);

  const formatTooltip2 = val => localeFormatter.format(',.1f')(val/1000000000) + ' miljard euro';
  const formatTooltip3 = val => localeFormatter.format(',.0f')(Math.round(val/1000)*1000) + ' euro';

	const data1 = [
      { key: '65-74 jarigen', value: 1800000 },
      { key: '75-84-jarigen', value: 961000 },
      { key: '85-plussers', value:  356000 },
	];


  const data2 = [
      { key: '65-74 jarigen', value: 9600000000 },
      { key: '75-84-jarigen', value: 10000000000 },
      { key: '85-plussers', value:  8300000000 },
  ];

  const data3 = [
      { key: '65-74 jarigen', value: 5333 },
      { key: '75-84-jarigen', value: 10405 },
      { key: '85-plussers', value:  23314 },
  ];


	return (
		<div className="vis-uitgaven-leeftijdsgroep">

      <h4>Aantal ouderen per leeftijdsgroep</h4>

      <BarChart
        data={data1}
        margin={{ top: 10, right: 0, bottom: 10, left: 190 }}
        barHeight={30}
        domain={[0,1800000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValue1}
        formatValueY={formatValue1}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-100}
      />

      <h4>Zorgkosten per leeftijdsgroep</h4>

      <BarChart
        data={data2}
        margin={{ top: 10, right: 0, bottom: 10, left: 190 }}
        barHeight={30}
        domain={[0,10000000000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatTooltip2}
        formatValueY={formatValue2}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-100}
      />

      <h4>Kosten per oudere per leeftijdsgroep</h4>

      <BarChart
        data={data3}
        margin={{ top: 10, right: 0, bottom: 10, left: 190 }}
        barHeight={30}
        domain={[0,23000]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatTooltip3}
        formatValueY={formatValue3}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-100}
      />

      <div className="vis-credit">
        <p>Uitgaven aan ouderenzorg in 2016 naar leeftijdsgroep.</p>
        <p>Bron: Nederlandse Zorgautoriteit (2018)</p>
      </div>

	</div>
	)
}

export default UitgavenLeeftijdsgroep;
