import React, { Component } from 'react';
import {
	select,
	scaleBand,
	scaleLinear,
	scaleOrdinal,
	axisBottom,
	axisLeft,
	line,
	min,
	max,
	pointer,
	interpolateString
} from 'd3';

import './LineChart.scss';

class LineChart extends Component {

	state = {
		width: 0,
		height: 0,
		tooltip: { display: 'none', x: 0, y: 0 }
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();
	}

	componentWillUnmount() {
			window.removeEventListener('resize', this.updateDimensions);
	}

  componentDidUpdate(prevProps, prevState){
    if (this.state.width !== prevState.width || this.state.height !== prevState.height) this.updateChart();
    if (this.props.data) {
      if (this.props.data !== prevProps.data) {
        this.updateDimensions();
        this.updateChart();
      }
    }
  }

	updateDimensions = () => {
		if (!this.container) return;
		const width = this.container.clientWidth,
					height = this.props.height || this.container.clientHeight;
		if (this.state.width !== width || this.state.height !== height) this.setState({ width, height });
	}


	updateChart= () => {
		const props = this.props;
		const { data, colorAxis, margin, showLegend, startForecast, animation } = props;
		const { width, height } = this.state;
		const transitionDuration = (animation === false || animation === 'undefined') ? 0 : 1500;

		const chartWidth = width - margin.left - margin.right;
		const chartHeight = height - margin.top - margin.bottom;

		const container = select(this.container);
		const svg = container.select('svg');

		const categories = data.map(d => d.name);
		const allX = [];
		const allY = [];
		const allPoints = [];
		const definedPoints = [];
		const lookup = {}; //lookup is een lijst van alle datapunten met hun attributen: x, y, categorie (=lijn waar ze deel van uitmaken) etc.
		data.map(category =>
			category.values.map(entry => {
				if (allX.indexOf(entry.x) === -1) allX.push(entry.x);
				allY.push(entry.y);
				if (!lookup[entry.x]) lookup[entry.x] = {};
				lookup[entry.x][category.name] = entry.y;
				const obj = entry;
				obj.category = category.name;
				allPoints.push(obj);
				//console.log("all" + allPoints);
				if (!obj.hasOwnProperty("showTooltip") || obj.showTooltip === 1) {definedPoints.push(obj);}
				//console.log("defined" + definedPoints);
				return entry;
			})
		);
		const minY = min(allY);
		const maxY = max(allY);

		const domainX = data[0].values.map(d => d.x);
		const domainY = props.domainY || [minY, maxY];
		const formatY = props.formatY || (value => value);
		const formatValueTooltip = props.formatValueTooltip || (value => value);
		const colorLines = props.colorLines ? props.colorLines : ["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"];

		const xScale = scaleBand().rangeRound([0, chartWidth]).padding(0).domain(domainX);
		const yScale = scaleLinear().rangeRound([chartHeight, 0]).domain(domainY).nice();
		const colorScale = scaleOrdinal().range(colorLines);

		const lineGenerator = line()
			// .defined(d => {return d.y !== null})
			.defined(d => !isNaN(d.y))
			.x(d => xScale(d.x))
			.y(d => yScale(d.y));

	  const tweenDash = function() {
	      var l = this.getTotalLength(),
	          i = interpolateString("0," + l, l + "," + l);
	      return t => i(t);
	  }

	  const animateLine = path => {
	     path.transition()
        .duration(transitionDuration)
        .attrTween("stroke-dasharray", tweenDash);
    }


		svg.select('.x.axis')
			.attr('transform', 'translate(0,' + chartHeight + ')')
			.call(axisBottom(xScale).tickSizeOuter(0));

		svg.select('.axis.y').call(axisLeft(yScale).ticks(4))

		svg.select('.lines').selectAll('.line').remove();

		const lines = svg.select('.lines').selectAll('.line').data(data);

		lines
			.enter()
				.append("g")
				.attr('class', (d, i) => 'line line' + (i+1))
			.merge(lines)
			.attr('transform', 'translate('+ xScale.bandwidth()/2 + ',0)')
			.append("path")
				.datum(d => d.values)
				.attr("fill", "none")
				.attr("stroke", (d,i) => colorLines[i])
				.attr("stroke-linejoin", "round")
				.attr("stroke-linecap", "round")
				.attr("stroke-width", 5)
				.attr("d", lineGenerator)
				.call(animateLine);


		const markupTooltip = xValue => {
			const data = lookup[xValue];
			// const lineLabels = categories.map( 
			// 		cat => <p key={'tt'+cat}>{cat}: {formatValueTooltip(data[cat])}</p>
			// 	)
			const lineLabels = categories.map( 
					(cat) => {
						if(cat !== "dummyData" && data[cat] !== undefined){
							return(<p key={'tt'+cat}>{cat}: {formatValueTooltip(data[cat])}</p>)
						} else {
							return(null);
						}
					}
				)
			const xLabel = startForecast && xValue > startForecast ? xValue + ' (prognose)' : xValue;
			if (lineLabels.length === 1)
				return <div>{xLabel}: {formatValueTooltip(data[categories[0]])}</div>
			else
				return <div><p>{xLabel}</p>{lineLabels}</div>
		}

		// mouse catchers
		svg.select('.af-linechart').select('.af-catchers').remove();

		svg.select('.af-linechart').append('g').attr('class', 'af-catchers')
			.selectAll('.af-catcher').data(allX)
			.enter()
			.append('g')
				.attr('class', d => d > startForecast ? 'af-catcher forecast' : 'af-catcher' )
				.datum(d => d)
				.on('mousemove', (event, d) => {
					const m = pointer(event, this.container);
					const xValue = d;
					svg.select('.points').selectAll('circle').filter(d => d.x === xValue).style('display', 'block');
          this.setState( {
            tooltip: {
              display: 'block',
              x: m[0] -75,
              y: m[1] -60,
              text: markupTooltip(xValue)
            }
          })
        })
      .on('mouseleave', (d,i,arr) => {
      		svg.select('.points').selectAll('circle').style('display', 'none');
          this.setState( {
            tooltip: {
              display: 'none',
              x: 0,
              y: 0,
              text: ''
            }
          })
        })
			.append('rect')
				.attr('x', d => xScale(d))
				.attr('width', xScale.bandwidth() )
				.attr('height', chartHeight)
				.attr('fill', '#fff')
				.attr('stroke', '#333')
				.attr('opacity', 0)

		// labels
		svg.select('.labels')
			.selectAll('text')
			.data(definedPoints)
			.enter()
			.append('text')
			.attr('class', d => 'af-label x' + d.x + ' ' + d.category)
			.style('display', 'none')
			.attr('text-anchor', 'middle')
			.attr('font-size', 14)
			.attr('fill', colorAxis)
			.attr('x', d => xScale(d.x) + xScale.bandwidth()/2)
			.attr("y", d => yScale(d.y))
			.attr("dy", d => d.category === categories[0] ? -18 : 36)
			.text(d => formatY(d.y));

		// points
		svg.select('.points')
			.selectAll('circle').data(definedPoints) //draws circle only when point has data
			.enter()
			.append('circle')
			.attr('class', 'af-point')
			.attr('cx', d => xScale(d.x) + xScale.bandwidth()/2)
			.attr("cy", d => yScale(d.y))
			.attr('r', 6.75)
			.attr('fill', '#333')
			.attr('stroke', '#fff')
			.attr('stroke-width', 3)
			.attr('display', 'none');

		if (showLegend) {
			const legendItems = container.select('.af-legend');
			legendItems.selectAll('li').remove();
			legendItems.selectAll('li')
						.data(categories)
						.enter()
						.append('li');
			legendItems.selectAll('li')
				.append('span')
					 	.style('width', '18px')
					 	.style('height', '2px')
					 	.style('margin-right', '9px')
					 	.style('margin-bottom', '3px')
					 	.style('background-color', d => colorScale(d));
			legendItems.selectAll('li').append('span').text(d => d).style('margin-right', '12px');
		}

		const axis = svg.selectAll('.axis');
		axis.selectAll('path').style('stroke', colorAxis);
		axis.selectAll('line').style('stroke', colorAxis);
		axis.selectAll('text').style('fill', colorAxis).attr('font-size', '13');
		svg.select('.x.axis').selectAll('.tick').each(function(d) { select(this).attr('class', 'tick x' + d) })

	}

	render() {
		const { width, height } = this.state;
		const { margin, labelY } = this.props;
		const { display, x, y, text } = this.state.tooltip;

    const containerStyle = {
      position: 'relative'
    }

    const tooltipStyle = {
      display: display,
      position: 'absolute',
      left: x,
      top: y,
      width: '150px'
    }

		return (
			<div className="LineChart" ref={container => this.container = container} style={containerStyle}>
				<svg width={width} height={height} >
					<g className="af-linechart" transform={"translate(" + margin.left + "," + margin.top + ")"}>
						<g className="x axis" />
						<g className="y axis" fontSize="18">
							<text className="labelY" dy="-12" textAnchor="end">{labelY}</text>
						</g>
						<g className="lines" />
						<g className="points" />
						<g className="labels" />
					</g>
				</svg>
				<ul className="af-legend" style={ {marginLeft: margin.left } } />
				<div className="tooltip" style={tooltipStyle}>{text}</div>
			</div>
		);
	}
}

export default LineChart;
