import React from 'react';
import { ReactComponent as TheSvg } from './hoofdrolspelers-aangepast.svg'

const Hoofdrolspelers = () => {
    return (
        <div style={{padding: "50px 0"}}>
            <TheSvg />
        </div>
    );
}

export default Hoofdrolspelers;
