import React from 'react';

const Mail = () => (
	<svg viewBox="0 0 44 44" className="icon">
		<rect x="8" y="12" width="28" height="20" fill="none" strokeMiterlimit="10" />
		<polyline points="7.5 12 22 22 36.5 12" fill="none" strokeMiterlimit="10" />
	</svg>
);

export default Mail;

