import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const Marktaandeel = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val =>
		localeFormatter.format(",.1%")(val) + "%";
	const formatValueY = val => localeFormatter.format(",.1%")(val);

	const data = [
{key:"Zilveren Kruis",value:.299},
{key:"VGZ",value:.223},
{key:"CZ",value:.212},
{key:"Menzis",value:.131},
{key:"DSW",value:.038},
{key:"Zorg en Zekerheid",value:.028},
{key:"ONVZ",value:.028},
{key:"ASR",value:.02},
{key:"iptiQ",value:.012},
{key:"Eno",value:.009}
	];

	return (
		<div className="vis-marktaandeel">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 180 }}
				barHeight={30}
				domain={[0, .3]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
				marginBeforeValue={-60}
			/>

			<div className="vis-credit">
				<p>
					Marktaandeel in 2018.
				</p>
				<p>Bron: Vektis, 2018.</p>
			</div>
		</div>
	);
};

export default Marktaandeel;
