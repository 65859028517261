import {COLOR_TURQUOISE} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "visualisatie",
  naam: "hoeveel-meldingen",
  titel: "Hoeveel meldingen van huiselijk geweld en kindermishandeling ontvangt Veilig Thuis en wat is hun aard?",
  kleur: COLOR_TURQUOISE,
  tekst: "",
  links: [
    'wat-is-huiselijk-geweld',
    'spelers-aanpak',
  ]
}
