import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import {formatLocale} from 'd3';

const AantalBevallingen = () => {
  const margin = {top: 60, right: 30, bottom: 90, left: 45};

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

  const formatY = (val) => localeFormatter.format(',.1f')(val);

  const formatValueTooltip = value => formatY(value) + "%";

  const data = [

    {
      name: "Totaal tweede lijn",
      values: [
        { x: 2000, y: 63, showTooltip: 1 },
        { x: 2001, y: 63.8, showTooltip: 1 },
        { x: 2002, y: 62.8, showTooltip: 1 },
        { x: 2003, y: 63.3, showTooltip: 1 },
        { x: 2004, y: 64.3, showTooltip: 1 },
        { x: 2005, y: 64.3, showTooltip: 1 },
        { x: 2006, y: 65.4, showTooltip: 1 },
        { x: 2007, y: 65.9, showTooltip: 1 },
        { x: 2008, y: 66.5, showTooltip: 1 },
        { x: 2009, y: 67.3, showTooltip: 1 },
        { x: 2010, y: 70.9, showTooltip: 1 },
        { x: 2011, y: 69.9, showTooltip: 1 },
        { x: 2012, y: 69, showTooltip: 1 },
        { x: 2013, y: 71, showTooltip: 1 },
        { x: 2014, y: 71, showTooltip: 1 },
        { x: 2015, y: 70.6, showTooltip: 1 },
        { x: 2016, y: 70, showTooltip: 1 },
        { x: 2017, y: 71.5, showTooltip: 1 },
        { x: 2018, y: 71, showTooltip: 1 },
      ]
    },
    {
      name: "Totaal eerste lijn",
      values: [
        {x: 2000, y: 36.6, showTooltip: 1},
        {x: 2001, y: 35.6, showTooltip: 1},
        {x: 2002, y: 36.7, showTooltip: 1},
        {x: 2003, y: 36.2, showTooltip: 1},
        {x: 2004, y: 35, showTooltip: 1},
        {x: 2005, y: 35, showTooltip: 1},
        {x: 2006, y: 34.1, showTooltip: 1},
        {x: 2007, y: 33.4, showTooltip: 1},
        {x: 2008, y: 32.7, showTooltip: 1},
        {x: 2009, y: 31.6, showTooltip: 1},
        {x: 2010, y: 28.5, showTooltip: 1},
        {x: 2011, y: 29.3, showTooltip: 1},
        {x: 2012, y: 29.9, showTooltip: 1},
        {x: 2013, y: 28.2, showTooltip: 1},
        {x: 2014, y: 27.9, showTooltip: 1},
        {x: 2015, y: 28.3, showTooltip: 1},
        {x: 2016, y: 30, showTooltip: 1},
        {x: 2017, y: 27.8, showTooltip: 1},
        {x: 2018, y: 27.9, showTooltip: 1},
      ]
    }
  ];

  return (
    <div className="vis-aantal-bevallingen">
      <LineChart
        margin={margin}
        colorLines={["#000"]}
        colorAxis="#333"
        labelY="aantal"
        domainY={[0, 100]}
        data={data}
        height={400}
        formatY={formatY}
        formatValueTooltip={formatValueTooltip}
      />

      <div className="vis-credit">
        <p>Percentage bevallingen in de eerste en tweede lijn in de periode 2000-2018. De bovenste lijn toont het aantal tweedelijns bevallingen (in het ziekenhuis). De onderste lijn toont het aantal eerstelijns bevallingen (thuis, in een geboortecentrum of in de polikliniek).</p>
        <p>Bron: Bevallingen naar tijd en plaats, Volksgezondheidenzorg.info, 2020</p>
      </div>
    </div>
  );
};

export default AantalBevallingen