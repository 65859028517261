import React from 'react';

const Ggz = () => (
	<svg viewBox="0 0 100 100" className="af-icon" xmlns="http://www.w3.org/2000/svg">
		<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="48.25 55.25 48.25 18.46 80.75 18.46 80.75 49.54 53.25 49.54 53.25 24.18 75.75 24.18 75.75 43.82 58.25 43.82 58.25 29.89 70.75 29.89 70.75 38.11 64.5 38.11 64.5 35.61 68.25 35.61 68.25 32.39 60.75 32.39 60.75 41.32 73.25 41.32 73.25 26.68 55.75 26.68 55.75 47.04 78.25 47.04 78.25 20.96 50.75 20.96 50.75 52.75 83.27 52.75 83.73 15.25 55 15.25 55 12.75 86.27 12.75 85.73 55.25"></polygon>
			<polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="73.75 65.57 60.81 69.48 60.81 80.75 40.66 80.75 40.66 69.16 30.69 69.16 29.81 65.5 38 65.5 38 62.5 29.09 62.5 27.46 55.67 22.38 55.67 29.91 44.77 32.79 29.8 43.73 21.25 45.5 21.25 45.5 18.75 42.86 18.75 30.51 28.41 27.56 43.78 17.62 58.17 25.48 58.17 28.72 71.66 38.16 71.66 38.16 83.25 63.31 83.25 63.31 71.34 76.25 67.43 76.25 58 73.75 58"></polygon>
		</g>
	</svg>
);

export default Ggz;
