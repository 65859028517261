import React from 'react';
//import { Link } from 'react-router-dom';

const Meestgebruikt = () => (
<div className="vis-meest-gebruikt svg-container">
	<svg viewBox="0 0 660 500" preserveAspectRatio="xMidYMin meet">
		<g className="gridPrimair">
			<line x1="6" y1="43" x2="640" y2="43"/>
			<line x1="6" y1="348" x2="640" y2="348"/>
		</g>
		<g className="kop">
			<text transform="translate(295 33)">Toepassing</text>
			<text transform="translate(534.55 33)">gebruikers</text>
			<text transform="translate(534.55 12)">Aantal</text>
			<text transform="translate(40 33)">Werkzame stof (werking)</text>
		</g>
		<g className="tekst">
			<text transform="translate(126 66)">Macrogol (laxeermiddel)</text>
			<text transform="translate(135 96)">Amoxicilline (antibioticum)</text>
			<text transform="translate(117 126)">Diclofenac (pijnstiller)</text>
			<text transform="translate(138 156)">Colecalciferol (vitamine D3)</text>
			<text transform="translate(150 186)">Omeprazol (maagzuurremmer)</text>
			<text transform="translate(164 216)">Simvastatine (cholesterolverlager)</text>
			<text transform="translate(131 246)">Metoprolol (bèta-blokker)</text>
			<text transform="translate(124 276)">Basiscrèmes en -zalven</text>
			<text transform="translate(155 306)">Pantoprazol (maagzuurremmer)</text>
			<text transform="translate(155 336)">Salbutamol (luchtwegverwijder)</text>

			<text transform="translate(585 66)">1,20 miljoen</text>
			<text transform="translate(585 96)">1,13 miljoen</text>
			<text transform="translate(585 126)">1,11 miljoen</text>
			<text transform="translate(585 156)">1,10 miljoen</text>
			<text transform="translate(585 186)">1,04 miljoen</text>
			<text transform="translate(585 216)">1,03 miljoen</text>
			<text transform="translate(585 246)">1,01 miljoen</text>
			<text transform="translate(585 276)">0,97 miljoen</text>
			<text transform="translate(585 306)">0,90 miljoen</text>
			<text transform="translate(585 336)">0,87 miljoen</text>

			<text transform="translate(405 66)">bij verstopping van de darmen</text>
			<text transform="translate(373 96)">bij bacteriële infectie</text>
			<text transform="translate(381 126)">bij pijn en ontstekingen</text>
			<text transform="translate(418 156)">als preventie tegen botontkalking</text>
			<text transform="translate(380 186)">bij brandend maagzuur</text>
			<text transform="translate(384 216)">bij verhoogd cholesterol</text>
			<text transform="translate(400 246)">o.a. bij verhoogde bloeddruk</text>
			<text transform="translate(402 276)">o.a. bij droge huid en eczeem</text>
			<text transform="translate(380 306)">bij brandend maagzuur</text>
			<text transform="translate(378 336)">o.a. bij astma en COPD</text>
		</g>
		<g className="vis-credit">
			<text transform="translate(205 378)">Top-10 van de werkzame stof in extramurale geneesmiddelen</text>
			<text transform="translate(315 396)">die het meest voorgeschreven werden in 2018.</text>
			<text transform="translate(284 414)">Bron: Stichting Farmaceutische Kengetallen, 2019.</text>

		</g>
		<g className="nummers">
			<path d="M13.2,60.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79A8,8,0,0,1,21,52.49a7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,67a8,8,0,0,1-1.87.79A7.83,7.83,0,0,1,21,68.1a7.55,7.55,0,0,1-2.07-.28A8.58,8.58,0,0,1,17.06,67a7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,60.3Zm5.37,2.09v1.53h5.15V62.39H22.17V56.55H20.61l-2.16.77v1.57l1.86-.55v4Z" />
			<path d="M13.2,90.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79A8,8,0,0,1,21,82.49a7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,97a8,8,0,0,1-1.87.79A7.83,7.83,0,0,1,21,98.1a7.55,7.55,0,0,1-2.07-.28A8.58,8.58,0,0,1,17.06,97a7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,90.3Zm7.72-3.85a5,5,0,0,0-2.2.52v1.58A4.58,4.58,0,0,1,20.6,88q1,0,1,.78a1.51,1.51,0,0,1-.19.7,5.24,5.24,0,0,1-.57.85c-.25.31-.57.66-.95,1s-.81.83-1.31,1.32v1.22h5.21V92.39H22.05c-.36,0-.74,0-1.13,0v0c.44-.41.83-.78,1.16-1.11a9.16,9.16,0,0,0,.82-1,3.72,3.72,0,0,0,.49-.88,2.61,2.61,0,0,0,.16-.94,2,2,0,0,0-.2-.91,1.85,1.85,0,0,0-.56-.65,2.37,2.37,0,0,0-.83-.4A3.77,3.77,0,0,0,20.92,86.45Z" />
			<path d="M13.2,120.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,127a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,120.3Zm10.48-1.87A1.69,1.69,0,0,0,23,117a3.34,3.34,0,0,0-2.05-.54,6.18,6.18,0,0,0-1.9.31v1.54a5.26,5.26,0,0,1,.8-.23,4.8,4.8,0,0,1,.85-.08,1.7,1.7,0,0,1,.8.16.57.57,0,0,1,.3.53.61.61,0,0,1-.1.36.69.69,0,0,1-.27.23,1.13,1.13,0,0,1-.39.14,2.42,2.42,0,0,1-.47,0h-.72v1.45h1a3.15,3.15,0,0,1,.46,0,.91.91,0,0,1,.37.13.66.66,0,0,1,.24.23.74.74,0,0,1,.09.38.71.71,0,0,1-.33.62,1.76,1.76,0,0,1-1,.23,4.13,4.13,0,0,1-1-.1,3.52,3.52,0,0,1-.75-.23v1.56a4.91,4.91,0,0,0,1.79.31,3.79,3.79,0,0,0,2.35-.62,2,2,0,0,0,.8-1.7,1.56,1.56,0,0,0-1.18-1.62v0a1.69,1.69,0,0,0,.77-.69A1.92,1.92,0,0,0,23.68,118.43Z" />
			<path d="M13.2,150.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,157a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,150.3Zm8.52-3.63H19.86L18,151.34v1.16h3.08v1.42H22.9V152.5h.88V151H22.9v-1.1H21.12V151h-.27c-.38,0-.71,0-1,0v0a14,14,0,0,0,.71-1.52Z" />
			<path d="M13.2,180.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,187a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,180.3Zm7.6-2.1h2.68v-1.53H19.29l-.58,4.11a6.88,6.88,0,0,1,1.18-.1,5.88,5.88,0,0,1,.8,0,1.79,1.79,0,0,1,.51.17.69.69,0,0,1,.28.28.79.79,0,0,1,.09.38.89.89,0,0,1-.11.43,1,1,0,0,1-.31.3,1.39,1.39,0,0,1-.43.18,3,3,0,0,1-.52.05,3.74,3.74,0,0,1-.84-.08,4.56,4.56,0,0,1-.82-.26v1.58a3.69,3.69,0,0,0,.77.23,5.23,5.23,0,0,0,1,.09,4.1,4.1,0,0,0,1.31-.19,2.88,2.88,0,0,0,1-.53,2.28,2.28,0,0,0,.63-.81,2.45,2.45,0,0,0,.22-1,2.43,2.43,0,0,0-.15-.86,1.68,1.68,0,0,0-.46-.69,2.37,2.37,0,0,0-.83-.47,3.83,3.83,0,0,0-1.21-.17h-.21Z" />
			<path d="M13.2,210.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,217a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,210.3Zm8.05-1a3.2,3.2,0,0,0-.8.09,2.55,2.55,0,0,0-.58.21l0,0a1.67,1.67,0,0,1,1.79-1.6,4.26,4.26,0,0,1,.86.09,3.93,3.93,0,0,1,.79.21v-1.52a5,5,0,0,0-.72-.22,4.81,4.81,0,0,0-1-.1,3.79,3.79,0,0,0-1.61.32,3,3,0,0,0-1.1.89,3.64,3.64,0,0,0-.63,1.33,6,6,0,0,0-.21,1.62,4.67,4.67,0,0,0,.23,1.52,3,3,0,0,0,.62,1.08,2.41,2.41,0,0,0,.93.65,2.94,2.94,0,0,0,1.15.22,3.41,3.41,0,0,0,1.14-.18,2.55,2.55,0,0,0,.86-.51,2.27,2.27,0,0,0,.56-.8,2.63,2.63,0,0,0,.19-1,2.34,2.34,0,0,0-.18-.93,2,2,0,0,0-.5-.71,2.34,2.34,0,0,0-.77-.45A3.1,3.1,0,0,0,21.25,209.31Zm-.31,3.23a.93.93,0,0,1-.44-.11,1.09,1.09,0,0,1-.35-.29,1.54,1.54,0,0,1-.24-.48,2.15,2.15,0,0,1-.09-.64,2.08,2.08,0,0,1,.49-.19,2,2,0,0,1,.53-.07,1.1,1.1,0,0,1,.75.23.79.79,0,0,1,.25.61,1,1,0,0,1-.24.7A.92.92,0,0,1,20.94,212.54Z" />
			<path d="M13.2,240.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,247a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,240.3Zm8.46-2.1-2.73,5.72h1.94l2.85-6v-1.23H18.5v1.53Z" />
			<path d="M13.2,270.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,277a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,270.3Zm7.8-3.85a4.55,4.55,0,0,0-1.16.14A2.56,2.56,0,0,0,19,267a1.83,1.83,0,0,0-.54.64,1.87,1.87,0,0,0-.18.83,1.67,1.67,0,0,0,.3,1,1.94,1.94,0,0,0,.8.63v0a2.34,2.34,0,0,0-.5.25,2.4,2.4,0,0,0-.4.38,1.75,1.75,0,0,0-.26.51,1.81,1.81,0,0,0-.1.63,2,2,0,0,0,.19.9,1.94,1.94,0,0,0,.57.68,2.48,2.48,0,0,0,.91.43,5.12,5.12,0,0,0,2.46,0,2.48,2.48,0,0,0,.91-.43,1.94,1.94,0,0,0,.57-.68,2,2,0,0,0,.19-.9,1.81,1.81,0,0,0-.1-.63,1.75,1.75,0,0,0-.26-.51,2.4,2.4,0,0,0-.4-.38,2.34,2.34,0,0,0-.5-.25v0a1.94,1.94,0,0,0,.8-.63,1.67,1.67,0,0,0,.3-1,1.87,1.87,0,0,0-.18-.83A1.83,1.83,0,0,0,23,267a2.56,2.56,0,0,0-.86-.42A4.55,4.55,0,0,0,21,266.45Zm0,6.13a1.19,1.19,0,0,1-.76-.22.7.7,0,0,1-.26-.54.85.85,0,0,1,.3-.68,1.91,1.91,0,0,1,.72-.38,1.91,1.91,0,0,1,.72.38.85.85,0,0,1,.3.68.7.7,0,0,1-.26.54A1.19,1.19,0,0,1,21,272.58Zm0-3a1.24,1.24,0,0,1-.67-.38.85.85,0,0,1-.2-.51.66.66,0,0,1,.07-.32.7.7,0,0,1,.2-.22.84.84,0,0,1,.27-.13,1.11,1.11,0,0,1,.66,0,.84.84,0,0,1,.27.13.7.7,0,0,1,.2.22.66.66,0,0,1,.07.32.85.85,0,0,1-.2.51A1.24,1.24,0,0,1,21,269.56Z" />
			<path d="M13.2,300.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,307a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,300.3Zm7,2.24a5,5,0,0,1-.77-.08,3.87,3.87,0,0,1-.88-.26v1.56a3.85,3.85,0,0,0,.77.22,5.06,5.06,0,0,0,1.05.1,4,4,0,0,0,1.73-.33,3,3,0,0,0,1.11-.89,3.46,3.46,0,0,0,.6-1.3A6.62,6.62,0,0,0,24,300a3.88,3.88,0,0,0-.79-2.63,2.81,2.81,0,0,0-2.24-.91,3,3,0,0,0-1.06.18,2.76,2.76,0,0,0-.84.52,2.42,2.42,0,0,0-.74,1.83,2.35,2.35,0,0,0,.19,1,2.09,2.09,0,0,0,.53.7,2.31,2.31,0,0,0,.76.4,3,3,0,0,0,.89.13,3.36,3.36,0,0,0,.77-.08,2.69,2.69,0,0,0,.66-.22h0a1.87,1.87,0,0,1-.63,1.25A2.1,2.1,0,0,1,20.22,302.54Zm.91-2.77a1,1,0,0,1-.71-.24.82.82,0,0,1-.25-.65.91.91,0,0,1,0-.33.83.83,0,0,1,.16-.29.79.79,0,0,1,.27-.2,1.08,1.08,0,0,1,1.22.33,2.09,2.09,0,0,1,.34,1.07.74.74,0,0,1-.23.14l-.3.1-.3.05A1.14,1.14,0,0,1,21.13,299.77Z" />
			<path d="M13.2,330.3a7.44,7.44,0,0,1,.28-2.07,7.94,7.94,0,0,1,.78-1.87,7.84,7.84,0,0,1,1.22-1.58,7.76,7.76,0,0,1,1.58-1.22,8.14,8.14,0,0,1,1.87-.79,8,8,0,0,1,2.07-.28,7.55,7.55,0,0,1,2.07.29,8.12,8.12,0,0,1,1.87.78,7.76,7.76,0,0,1,1.58,1.22,7.84,7.84,0,0,1,1.22,1.58,8.07,8.07,0,0,1,.79,1.86,8.11,8.11,0,0,1,0,4.15A7.83,7.83,0,0,1,24.94,337a8,8,0,0,1-1.87.79,7.83,7.83,0,0,1-2.07.27,7.55,7.55,0,0,1-2.07-.28,8.58,8.58,0,0,1-1.87-.78,7.73,7.73,0,0,1-3.58-4.67A7.55,7.55,0,0,1,13.2,330.3Zm3,2.09v1.53h4.61v-1.53H19.49v-5.84H18l-1.9.77v1.57l1.61-.55v4.05Zm5-2.13a6.48,6.48,0,0,0,.19,1.68,3.65,3.65,0,0,0,.56,1.19,2.33,2.33,0,0,0,.86.71,2.45,2.45,0,0,0,1.09.24,2.5,2.5,0,0,0,1.1-.24,2.3,2.3,0,0,0,.85-.71,3.46,3.46,0,0,0,.56-1.19,7.16,7.16,0,0,0,0-3.36,3.46,3.46,0,0,0-.56-1.19,2.3,2.3,0,0,0-.85-.71,2.64,2.64,0,0,0-1.1-.23,2.59,2.59,0,0,0-1.09.23,2.33,2.33,0,0,0-.86.71,3.65,3.65,0,0,0-.56,1.19A6.48,6.48,0,0,0,21.18,330.26Zm3.57,0a8.59,8.59,0,0,1,0,.91,3.27,3.27,0,0,1-.15.71,1.11,1.11,0,0,1-.26.47.54.54,0,0,1-.42.17.5.5,0,0,1-.41-.17,1.24,1.24,0,0,1-.27-.47,3.3,3.3,0,0,1-.14-.71,8.46,8.46,0,0,1,0-.91,8.27,8.27,0,0,1,0-.9,3.18,3.18,0,0,1,.14-.71,1.24,1.24,0,0,1,.27-.47.51.51,0,0,1,.41-.18.54.54,0,0,1,.42.18,1.11,1.11,0,0,1,.26.47,3.15,3.15,0,0,1,.15.71A8.4,8.4,0,0,1,24.75,330.26Z" />
		</g>
	</svg>
</div>
);

export default Meestgebruikt;
