import React from 'react';
import { ReactComponent as TheSvg } from './beleid-stappen-aangepast.svg'

const BeleidStappen = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />
        </div>
    );
}

export default BeleidStappen;
