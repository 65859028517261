import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const VisualisatieGeslacht = () => {
	const data = [
		{
			"label": "Vrouw",
			"value": 81,
		},
		{
			"label": "Man",
			"value": 19,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		return `${item.label}`
	}


	const formatTooltip = item => {
		return `${item.label} (${localeFormatter.format(',.0f')(item.percentage)}%)`
	}

	return (
		<Donut
			data={data}
			height={200}
			fontFamily="din-regular"
			fontSize="15px"
			formatLabel={formatLabel}
			formatTooltip={formatTooltip}
			formatTotal={() => {}}
			unit="duizend"
			animation={true}
		/>
	)
}

const VisualisatieLeeftijd = () => {
	const data = [
		{
			"label": "55 jaar of ouder",
			"value": 21,
		},
		{
			"label": "Tussen 35 en 55 jaar",
			"value": 40,
		},
		{
			"label": "Jonger dan 35 jaar",
			"value": 39,
		},
	];

	const localeFormatter = formatLocale({
		"decimal": ",",
		"thousands": ".",
		"grouping": [3],
		"comma": true,
	});

	const formatLabel = item => {
		return `${item.label}`
	}


	const formatTooltip = item => {
		return `${localeFormatter.format(',.0f')(item.percentage)}%`
	}

	return (
		<Donut
			data={data}
			height={200}
			fontFamily="din-regular"
			fontSize="15px"
			formatLabel={formatLabel}
			formatTooltip={formatTooltip}
			formatTotal={() => {}}
			unit="duizend"
			animation={true}
		/>
	)
}

const WerknemersGehandicaptenzorg = () => {
	return (
		<div className="vis-werknemers-gehandicaptenzorg">
			<VisualisatieLeeftijd />
			<VisualisatieGeslacht />

			<div className="clear"></div>

      <div className="vis-total">
        <p className="groot">181</p>
        <p>duizend</p>
        <p>werknemers</p>
      </div>

			<div className="clear"></div>

			<div className="vis-credit">
				<p>Percentage werkzame mensen in de gehandicaptenzorg in 2019, naar geslacht en leeftijd.</p>
				<p>Bron: AZW Statline, 2020</p>
			</div>

			<div className="clear"></div>

		</div>
	);
}

export default WerknemersGehandicaptenzorg;
