const organisation = (state = '', action) => {
  switch (action.type) {
    case 'SETORGANISATION':
      return action.organisation
    default:
       return state
  }
}

export default organisation
