import intro from './intro'
import watIsEenAInfectieziekte from './wat-is-een-a-infectieziekte'
import wieDoetWat from './wie-doet-wat'
import dwingendeMaatregelen from './dwingende-maatregelen'
import colofon from './colofon'

export default {
  intro,
  watIsEenAInfectieziekte,
  wieDoetWat,
  dwingendeMaatregelen,
  colofon,
}
