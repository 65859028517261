import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const GeldstromenZVW = () => (

	<div className="vis-geldstromen-palliatieve-zorg svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 40 500 600">

			<g className="geldstromen">
				<path data-tooltip-content="react-tooltip" id="tt1" transform="translate(42 110)" d="M153,0 L129.37,0 C112.75,0 99.63,9.3 93.37,25.51 C93.3643651,25.5464502 93.3643651,25.5835498 93.37,25.62 L6.48,256 C2.84,266.08 0,282.32 0,293 L0,320 L6,326 L12,320 L12,293 C12,283.81 14.64,268.75 17.76,260.11 L104.62,29.76 C109.05,18.31 117.83,12 129.37,12 L153,12 L147,6 L153,0 Z" />
				<polygon data-tooltip-content="react-tooltip" transform="translate(-38 -88)" id="tt2" points="295 261 295 225 289 231 283 225 283 225 283 261 283 261 289 267 295 261 295 261"/>
				<path data-tooltip-content="react-tooltip" id="tt3" d="M451,429V411.75c0-10.32-3.12-26.22-7.11-36.19L345.17,128.49C340.58,117,329.5,108,320,108H307l6,6-6,6h13c4.2,0,11.1,5.53,14.06,12.94L432.75,380c3.39,8.48,6.25,23,6.25,31.74V429h0l6,6,6-6Z"/>
				<path data-tooltip-content="react-tooltip" id="tt4" transform="translate(42 110)" d="M153,77 L121.37,77 C104.75,77 91.63,86.3 85.37,102.51 C85.3643651,102.54645 85.3643651,102.58355 85.37,102.62 L25.48,256 C21.84,266.08 19,282.32 19,293 L19,320 L25,326 L31,320 L31,293 C31,283.81 33.64,268.75 36.76,260.11 L96.62,106.76 C101.05,95.31 109.83,89 121.37,89 L153,89 L147,83 L153,77 Z" />
				<path data-tooltip-content="react-tooltip" id="tt5" transform="translate(42 110)" d="M114,230 L108,236 L102,230 L102,116 C102,102.77 112.09,92 124.5,92 L153,92 L147,98 L153,104 L124.5,104 C118.71,104 114,109.38 114,116 L114,230 Z" />
				<polygon data-tooltip-content="react-tooltip" id="tt6" transform="translate(-38 0)" points="295 261 295 225 289 231 283 225 283 225 283 261 283 261 289 267 295 261 295 261"/>
				<path data-tooltip-content="react-tooltip" id="tt7" transform="translate(332, 271) scale(-1, 1) translate(-278.500000, -160.000000)" d="M265,230 L259,236 L253,230 L253,108 C253,94.77 263.09,84 275.5,84 L304,84 L298,90 L304,96 L275.5,96 C269.71,96 265,101.38 265,108 L265,230 Z"/>
				<polygon transform="translate(90 168)" data-tooltip-content="react-tooltip" id="tt7b" points="295 261 295 225 289 231 283 225 283 225 283 261 283 261 289 267 295 261 295 261"/>
				<polygon transform="translate(124 452)" data-tooltip-content="react-tooltip" id="tt8" points="0 0 6 6 0 12 227 12 233 6 227 0"/>
			</g>

			<Link to={{ pathname: '/palliatieve-zorg/wie-betaalt', hash: '#Burgers' }} >
				<g className="kader link">
					<rect x="197" y="94" width="106" height="40" rx="8"/>
					<text transform="translate(230 118)">Burgers</text>
				</g>
			</Link>
			<Link to={{ pathname: '/palliatieve-zorg/wie-betaalt', hash: '#Rijk' }} >
				<g className="kader link">
					<rect x="197" y="181" width="106" height="40" rx="8"/>
					<text transform="translate(240 205)">Rijk</text>
				</g>
			</Link>

			<g className="kader">
				<rect x="197" y="271" width="106" height="40" rx="8"/>
				<text style={{fontSize:'10px'}} transform="translate(203 288)">Regionale netwerken</text>
				<text style={{fontSize:'10px'}} transform="translate(218 300)">palliatieve zorg</text>
			</g>

			<g className="kader">
				<rect x="100" y="350" width="106" height="40" rx="8"/>
				<text style={{fontSize:'9px'}} transform="translate(130 364)">Vrijwilligers-</text>
				<text style={{fontSize:'9px'}} transform="translate(105 374)">organisaties palliatieve</text>
				<text style={{fontSize:'9px'}} transform="translate(125 384)">terminale zorg</text>
			</g>

			<g className="kader">
				<rect x="300" y="350" width="106" height="40" rx="8"/>
				<text transform="translate(338 374)">Fibula</text>
			</g>

			<Link to={{ pathname: '/palliatieve-zorg/wie-betaalt', hash: '#Zorginkopers' }} >
				<g className="kader link">
					<rect x="5" y="439" width="115" height="40" rx="8"/>
					<text transform="translate(25 464)">Zorginkopers</text>
				</g>
			</Link>

			<g className="kader">
				<rect x="360" y="439" width="115" height="40" rx="8"/>
				<text transform="translate(377 462)">Zorgaanbieders</text>
			</g>

			<g className="tekst links regular">
				<text transform="translate(21 520)">
					<tspan x="0" y="0">Premie zorgverzekering wettelijke eigen</tspan>
					<tspan x="0" y="15">bijdragen en eigen risico</tspan>
					<tspan x="0" y="30">Belastingen en inkomensafhankelijke premies</tspan>
					<tspan x="0" y="45">Verblijfsvergoeding hospice, eigen betalingen</tspan>
					<tspan x="0" y="60">Rijksbijdragen</tspan>

					<tspan x="278.25" y="0">Subsidie</tspan>
					<tspan x="278.25" y="15">Subsidie</tspan>
					<tspan x="278.25" y="30">Vergoeding voor consultatie</tspan>
					<tspan x="278.25" y="45">Betaling voor zorg en ondersteuning</tspan>
				</text>
			</g>

			<g className="tekstlabels wit">
				<path transform="translate(-8 30)" data-testid="1" d="M94.25,232.48a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1A6.78,6.78,0,0,1,99,226.2a6.49,6.49,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6.21,6.21,0,0,1,1.32,1,6,6,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.58,6.58,0,0,1,0,3.45,6.68,6.68,0,0,1-.65,1.56,6.43,6.43,0,0,1-2.34,2.33,6.08,6.08,0,0,1-1.55.66,6.57,6.57,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23,6.42,6.42,0,0,1-3.89-3,7.17,7.17,0,0,1-.66-1.56A6.15,6.15,0,0,1,94.25,232.48Zm4.47,1.74v1.27H103v-1.27h-1.29v-4.86h-1.29l-1.8.63v1.31l1.54-.46v3.38Z"/>
				<path transform="translate(31 0)" data-testid="2" d="M213.58,158.75a6.16,6.16,0,0,1,.24-1.72,6.68,6.68,0,0,1,.65-1.56,6.15,6.15,0,0,1,1-1.31,6,6,0,0,1,1.32-1,6.08,6.08,0,0,1,1.55-.66,6.62,6.62,0,0,1,1.73-.23,6.16,6.16,0,0,1,1.72.24,6.34,6.34,0,0,1,1.56.65,6.47,6.47,0,0,1,1.32,1,6.87,6.87,0,0,1,1,1.31,6.56,6.56,0,0,1,.88,3.28,6.56,6.56,0,0,1-.88,3.29,6.87,6.87,0,0,1-1,1.31,6.58,6.58,0,0,1-2.87,1.68,6.62,6.62,0,0,1-1.73.23,6.23,6.23,0,0,1-1.73-.24,6.44,6.44,0,0,1-1.55-.65,6,6,0,0,1-1.32-1,6.15,6.15,0,0,1-1-1.31,6.68,6.68,0,0,1-.65-1.56A6.23,6.23,0,0,1,213.58,158.75Zm6.44-3.2a4,4,0,0,0-1.83.43v1.32a3.65,3.65,0,0,1,1.57-.46c.56,0,.85.22.85.65a1.37,1.37,0,0,1-.16.58,4.44,4.44,0,0,1-.48.71c-.21.26-.47.55-.79.88l-1.09,1.09v1h4.34V160.5H221c-.3,0-.61,0-.94,0v0c.37-.34.69-.65,1-.93a7.4,7.4,0,0,0,.68-.78,3.24,3.24,0,0,0,.41-.74,2.2,2.2,0,0,0,.13-.78,1.67,1.67,0,0,0-.16-.76,1.53,1.53,0,0,0-.47-.54,1.85,1.85,0,0,0-.69-.33A2.94,2.94,0,0,0,220,155.55Z"/>
				<path transform="translate(295 0)" data-testid="3" d="M86.65,253.27a6.62,6.62,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.51,6.51,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,5.93,5.93,0,0,1,1.32,1,6.21,6.21,0,0,1,1,1.32,6.89,6.89,0,0,1,.66,1.55,6.62,6.62,0,0,1,0,3.46,6.89,6.89,0,0,1-.66,1.55,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.58,6.58,0,0,1-3.45,0,6.37,6.37,0,0,1-1.56-.66,6.3,6.3,0,0,1-2.33-2.33,6.89,6.89,0,0,1-.66-1.55A6.62,6.62,0,0,1,86.65,253.27Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.48.48,0,0,1,.26.44.56.56,0,0,1-.08.3.74.74,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.58,2.58,0,0,1-.39,0h-.6v1.21H93a2.38,2.38,0,0,1,.39,0,.89.89,0,0,1,.3.11.58.58,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,3.91,3.91,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.68,1.68,0,0,0,.66-1.41,1.32,1.32,0,0,0-1-1.35v0a1.43,1.43,0,0,0,.64-.57A1.65,1.65,0,0,0,95.39,251.71Z"/>
				<path transform="translate(85 -81)" data-testid="4" d="M79.05,273.83a6.55,6.55,0,0,1,.23-1.72,7.17,7.17,0,0,1,.66-1.56,6.5,6.5,0,0,1,2.33-2.33,6.15,6.15,0,0,1,1.56-.66,6.55,6.55,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.24,6.68,6.68,0,0,1,1.56.65,6.72,6.72,0,0,1,2.33,2.33,7,7,0,0,1,.66,1.56,6.49,6.49,0,0,1,.23,1.72,6.62,6.62,0,0,1-.23,1.73,7.17,7.17,0,0,1-.66,1.56,6.72,6.72,0,0,1-2.33,2.33,6.71,6.71,0,0,1-1.56.66,6.57,6.57,0,0,1-1.73.23,6.16,6.16,0,0,1-1.72-.24,6.47,6.47,0,0,1-3.89-3,7.17,7.17,0,0,1-.66-1.56A6.62,6.62,0,0,1,79.05,273.83Zm7.1-3H84.6l-1.51,3.89v1h2.57v1.18h1.48v-1.18h.73v-1.24h-.73v-.92H85.66v.92h-.23c-.32,0-.59,0-.83,0v0a9.34,9.34,0,0,0,.6-1.27Z"/>
				<path transform="translate(-230 20)" data-testid="5" d="M373.55,232.48a6.23,6.23,0,0,1,.23-1.73,6.46,6.46,0,0,1,.66-1.55,5.93,5.93,0,0,1,1-1.32,6.21,6.21,0,0,1,1.32-1,6.89,6.89,0,0,1,1.55-.66,6.62,6.62,0,0,1,1.73-.23,6.11,6.11,0,0,1,1.72.24,6.34,6.34,0,0,1,1.56.65,6.47,6.47,0,0,1,1.32,1,6.64,6.64,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.58,6.58,0,0,1,0,3.45,6.68,6.68,0,0,1-.65,1.56,7.27,7.27,0,0,1-1,1.32,6.8,6.8,0,0,1-1.32,1,6.08,6.08,0,0,1-1.55.66,6.62,6.62,0,0,1-3.46,0,6.89,6.89,0,0,1-1.55-.66,6.51,6.51,0,0,1-1.32-1,6.43,6.43,0,0,1-1-1.32,6.71,6.71,0,0,1-.66-1.56A6.15,6.15,0,0,1,373.55,232.48Zm6.33-1.75h2.23v-1.28h-3.49l-.48,3.43a5.52,5.52,0,0,1,1-.09,4,4,0,0,1,.66.05,1.36,1.36,0,0,1,.43.14.63.63,0,0,1,.23.23.65.65,0,0,1,.08.31.69.69,0,0,1-.1.36.75.75,0,0,1-.25.25,1.14,1.14,0,0,1-.36.15,2.12,2.12,0,0,1-.44.05,3.14,3.14,0,0,1-.7-.07A3.83,3.83,0,0,1,378,234v1.32a3.24,3.24,0,0,0,.64.19,4.37,4.37,0,0,0,.87.07,3.68,3.68,0,0,0,1.09-.15,2.57,2.57,0,0,0,.82-.44,1.93,1.93,0,0,0,.52-.68,2,2,0,0,0,.18-.85,2.08,2.08,0,0,0-.12-.71,1.42,1.42,0,0,0-.39-.58,1.79,1.79,0,0,0-.68-.39,3.33,3.33,0,0,0-1-.14h-.18Z"/>
				<path transform="translate(49 60)" data-testid="6" d="M195.42,185.76a6.57,6.57,0,0,1,.23-1.73,7.17,7.17,0,0,1,.66-1.56,6.5,6.5,0,0,1,2.33-2.33,6.43,6.43,0,0,1,3.28-.89,6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6,6,0,0,1,1.32,1,6.15,6.15,0,0,1,1,1.31,7.17,7.17,0,0,1,.66,1.56,6.58,6.58,0,0,1,0,3.45,7.17,7.17,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.24,6.24,0,0,1-1.32,1,6.46,6.46,0,0,1-1.55.66,6.58,6.58,0,0,1-3.45,0,7,7,0,0,1-1.56-.66,6.3,6.3,0,0,1-2.33-2.33,7.17,7.17,0,0,1-.66-1.56A6.55,6.55,0,0,1,195.42,185.76Zm6.71-.82a2.76,2.76,0,0,0-.67.07,2.92,2.92,0,0,0-.48.17l0,0a1.54,1.54,0,0,1,.49-1,1.58,1.58,0,0,1,1-.32,3.65,3.65,0,0,1,.72.07,4.29,4.29,0,0,1,.65.18v-1.26a2.5,2.5,0,0,0-.6-.19,4.58,4.58,0,0,0-.83-.08,3.07,3.07,0,0,0-1.35.27,2.58,2.58,0,0,0-.92.74,3.22,3.22,0,0,0-.53,1.1,5.32,5.32,0,0,0-.16,1.35,4,4,0,0,0,.18,1.27,2.63,2.63,0,0,0,.52.9,2.18,2.18,0,0,0,.77.55,2.93,2.93,0,0,0,1.91,0,2.14,2.14,0,0,0,.72-.42,1.74,1.74,0,0,0,.47-.66,2.39,2.39,0,0,0,.16-.86A1.94,1.94,0,0,0,204,186a1.67,1.67,0,0,0-.42-.59,1.77,1.77,0,0,0-.64-.37A2.39,2.39,0,0,0,202.13,184.94Zm-.26,2.68a.86.86,0,0,1-.36-.08,1,1,0,0,1-.3-.25,1.14,1.14,0,0,1-.19-.4,1.78,1.78,0,0,1-.08-.53,1.59,1.59,0,0,1,.41-.16,1.72,1.72,0,0,1,.44-.06.87.87,0,0,1,.62.19.65.65,0,0,1,.22.51.76.76,0,0,1-.21.58A.73.73,0,0,1,201.87,187.62Z"/>
				<path transform="translate(101 -82)" data-testid="7" d="M243.47,335.17a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.55,6.55,0,0,1,3.44,0,6.37,6.37,0,0,1,1.56.66,6.17,6.17,0,0,1,1.32,1,6.29,6.29,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.85,6.85,0,0,1-1,1.32,6.94,6.94,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23,6.53,6.53,0,0,1-4.55-4.55A6.11,6.11,0,0,1,243.47,335.17Zm7.05-1.75-2.27,4.77h1.61l2.38-5v-1h-4.35v1.28Z" />
				<path transform="translate(129 78)" data-testid="7b" d="M243.47,335.17a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.55,6.55,0,0,1,3.44,0,6.37,6.37,0,0,1,1.56.66,6.17,6.17,0,0,1,1.32,1,6.29,6.29,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.85,6.85,0,0,1-1,1.32,6.94,6.94,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.49,6.49,0,0,1-1.72-.23,6.53,6.53,0,0,1-4.55-4.55A6.11,6.11,0,0,1,243.47,335.17Zm7.05-1.75-2.27,4.77h1.61l2.38-5v-1h-4.35v1.28Z" />
				<path transform="translate(0 -1)" data-testid="8" d="M237.5,459.1a6.18,6.18,0,0,1,.23-1.73,7.17,7.17,0,0,1,.66-1.56,6.5,6.5,0,0,1,2.33-2.33,6.37,6.37,0,0,1,1.56-.66,6.49,6.49,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6.21,6.21,0,0,1,1.32,1,6.49,6.49,0,0,1,1,1.31,6.68,6.68,0,0,1,.65,1.56,6.58,6.58,0,0,1,0,3.45,6.68,6.68,0,0,1-.65,1.56,6.85,6.85,0,0,1-1,1.32,6.53,6.53,0,0,1-6.32,1.67,6.42,6.42,0,0,1-3.89-3,7.17,7.17,0,0,1-.66-1.56A6.15,6.15,0,0,1,237.5,459.1Zm6.5-3.21a3.63,3.63,0,0,0-1,.12,2,2,0,0,0-.71.34,1.45,1.45,0,0,0-.45.54,1.55,1.55,0,0,0-.15.69,1.4,1.4,0,0,0,.24.83,1.69,1.69,0,0,0,.67.53v0a2,2,0,0,0-.41.21,1.44,1.44,0,0,0-.33.32,1.42,1.42,0,0,0-.23.42,1.74,1.74,0,0,0-.08.53,1.78,1.78,0,0,0,.16.74,1.53,1.53,0,0,0,.47.57,2.14,2.14,0,0,0,.76.36,4.48,4.48,0,0,0,2.06,0,2.14,2.14,0,0,0,.76-.36,1.53,1.53,0,0,0,.47-.57,1.78,1.78,0,0,0,.16-.74,1.74,1.74,0,0,0-.08-.53,1.42,1.42,0,0,0-.23-.42,1.44,1.44,0,0,0-.33-.32,2,2,0,0,0-.41-.21v0a1.69,1.69,0,0,0,.67-.53,1.41,1.41,0,0,0,.25-.83,1.56,1.56,0,0,0-.16-.69,1.52,1.52,0,0,0-.44-.54A2.18,2.18,0,0,0,245,456,3.63,3.63,0,0,0,244,455.89Zm0,5.1a1,1,0,0,1-.63-.18.55.55,0,0,1-.22-.45.72.72,0,0,1,.25-.57,1.71,1.71,0,0,1,.6-.31,1.55,1.55,0,0,1,.6.31.72.72,0,0,1,.25.57.55.55,0,0,1-.22.45A1,1,0,0,1,244,461Zm0-2.51a1,1,0,0,1-.56-.32.67.67,0,0,1-.16-.42.6.6,0,0,1,.06-.27.46.46,0,0,1,.16-.18.61.61,0,0,1,.23-.11.93.93,0,0,1,.54,0,.55.55,0,0,1,.23.11.46.46,0,0,1,.16.18.6.6,0,0,1,.07.27.69.69,0,0,1-.17.42A1,1,0,0,1,244,458.48Z"/>
			</g>

			<g className="tekstlabels zwart">
				<path d="M3.66,514.75A6.62,6.62,0,0,1,3.89,513a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,521a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,514.75Zm4.47,1.74v1.28h4.3v-1.28H11.14v-4.86H9.84l-1.8.64v1.31l1.54-.46v3.37Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M3.66,529.75A6.62,6.62,0,0,1,3.89,528a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,536a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,529.75Zm6.44-3.21a4.18,4.18,0,0,0-1.83.43v1.32a3.76,3.76,0,0,1,1.56-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.78,4.78,0,0,1-.47.71c-.21.26-.48.55-.79.87l-1.1,1.1v1h4.35v-1.28H11c-.29,0-.61,0-.93,0v0c.37-.34.69-.65,1-.92a6.58,6.58,0,0,0,.69-.79,3,3,0,0,0,.4-.74,2.21,2.21,0,0,0,.14-.78,1.63,1.63,0,0,0-.17-.75,1.53,1.53,0,0,0-.46-.55,2,2,0,0,0-.69-.33A3.43,3.43,0,0,0,10.1,526.54Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M3.66,544.75A6.62,6.62,0,0,1,3.89,543a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,551a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,544.75Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.81,2.81,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.17,4.17,0,0,1,.71-.07,1.4,1.4,0,0,1,.66.14.45.45,0,0,1,.26.44.56.56,0,0,1-.08.3.9.9,0,0,1-.23.2,1.17,1.17,0,0,1-.33.11,2.45,2.45,0,0,1-.39,0h-.6v1.21H10a2.34,2.34,0,0,1,.38,0,.89.89,0,0,1,.3.11.51.51,0,0,1,.21.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.54,1.54,0,0,1-.83.19,4,4,0,0,1-.79-.08,3.35,3.35,0,0,1-.63-.2v1.3a4.22,4.22,0,0,0,1.5.26,3.14,3.14,0,0,0,2-.52,1.69,1.69,0,0,0,.66-1.42,1.31,1.31,0,0,0-.28-.88,1.29,1.29,0,0,0-.7-.46v0a1.38,1.38,0,0,0,.64-.57A1.65,1.65,0,0,0,12.4,543.19Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M3.66,559.75A6.62,6.62,0,0,1,3.89,558a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,566a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,559.75Zm7.1-3H9.21l-1.52,3.89v1h2.58v1.18h1.48v-1.18h.73v-1.25h-.73v-.91H10.27v.91H10a6.78,6.78,0,0,0-.83,0v0a9.34,9.34,0,0,0,.6-1.27Z" fill="#1d1d1b"/>
				<path transform="translate(275 -60)" d="M3.66,574.75A6.62,6.62,0,0,1,3.89,573a6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.85,6.85,0,0,1,1.32-1,6.51,6.51,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23A6.55,6.55,0,0,1,8.44,581a7,7,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.55,6.55,0,0,1,3.66,574.75ZM10,573h2.24v-1.28H8.74l-.49,3.43a5.69,5.69,0,0,1,1-.09,4,4,0,0,1,.66.05,1.36,1.36,0,0,1,.43.14.59.59,0,0,1,.24.23.79.79,0,0,1,.07.32.65.65,0,0,1-.1.36.75.75,0,0,1-.25.25,1.18,1.18,0,0,1-.36.14,1.54,1.54,0,0,1-.43.05,3.23,3.23,0,0,1-.71-.07,4,4,0,0,1-.68-.21v1.31a3.44,3.44,0,0,0,.64.2,5.37,5.37,0,0,0,.87.07,3.33,3.33,0,0,0,1.09-.16,2.3,2.3,0,0,0,.82-.44,1.87,1.87,0,0,0,.52-.67,2,2,0,0,0,.19-.86,2.08,2.08,0,0,0-.12-.71,1.74,1.74,0,0,0-.39-.58,2.08,2.08,0,0,0-.69-.39,3.28,3.28,0,0,0-1-.14H9.86Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,514.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,518a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,514.75Zm6.71-.82a2.76,2.76,0,0,0-.67.07,2.2,2.2,0,0,0-.48.18l0,0a1.51,1.51,0,0,1,.5-1,1.53,1.53,0,0,1,1-.32,3.69,3.69,0,0,1,.72.08,4,4,0,0,1,.65.17v-1.26a3.18,3.18,0,0,0-.6-.18,3.84,3.84,0,0,0-.83-.09,3.1,3.1,0,0,0-1.35.27,2.58,2.58,0,0,0-.92.74,3,3,0,0,0-.52,1.1,5,5,0,0,0-.17,1.36,4,4,0,0,0,.18,1.26,2.68,2.68,0,0,0,.52.91,2.11,2.11,0,0,0,.78.54,2.49,2.49,0,0,0,1,.18,2.77,2.77,0,0,0,.94-.15,2,2,0,0,0,.73-.43,1.83,1.83,0,0,0,.46-.66,2.35,2.35,0,0,0,.16-.86,2,2,0,0,0-.15-.78,1.77,1.77,0,0,0-.42-.59,1.92,1.92,0,0,0-.64-.37A2.39,2.39,0,0,0,285.6,513.93Zm-.26,2.69a.74.74,0,0,1-.36-.09.78.78,0,0,1-.29-.25,1.16,1.16,0,0,1-.2-.4,1.78,1.78,0,0,1-.08-.53,2.05,2.05,0,0,1,.41-.16,1.72,1.72,0,0,1,.44-.06.92.92,0,0,1,.63.2.66.66,0,0,1,.21.5.79.79,0,0,1-.21.59A.76.76,0,0,1,285.34,516.62Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,529.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,533a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,529.75Zm7.05-1.75-2.27,4.77h1.61l2.39-5v-1h-4.35V528Z" fill="#1d1d1b"/>
				<path transform="translate(0 15)" d="M278.89,544.75a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.49,6.49,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.46,6.46,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45A6.37,6.37,0,0,1,291,548a6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.57,6.57,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.16,6.16,0,0,1,278.89,544.75Zm6.51-3.21a3.52,3.52,0,0,0-1,.12,2.2,2.2,0,0,0-.72.35,1.63,1.63,0,0,0-.45.53,1.58,1.58,0,0,0-.15.69,1.32,1.32,0,0,0,.25.83,1.71,1.71,0,0,0,.67.54v0a2.1,2.1,0,0,0-.42.22,1.6,1.6,0,0,0-.33.31,1.53,1.53,0,0,0-.22.42,1.51,1.51,0,0,0-.08.53,1.65,1.65,0,0,0,.16.75,1.6,1.6,0,0,0,.47.57,2.23,2.23,0,0,0,.76.35,4.11,4.11,0,0,0,2.05,0,2.23,2.23,0,0,0,.76-.35,1.6,1.6,0,0,0,.47-.57,1.65,1.65,0,0,0,.16-.75,1.78,1.78,0,0,0-.08-.53,1.53,1.53,0,0,0-.22-.42,1.6,1.6,0,0,0-.33-.31,2.32,2.32,0,0,0-.41-.22v0a1.68,1.68,0,0,0,.66-.54,1.32,1.32,0,0,0,.25-.83,1.58,1.58,0,0,0-.15-.69,1.52,1.52,0,0,0-.45-.53,2.1,2.1,0,0,0-.72-.35A3.5,3.5,0,0,0,285.4,541.54Zm0,5.11a1,1,0,0,1-.64-.19.56.56,0,0,1-.21-.44.72.72,0,0,1,.24-.57,1.41,1.41,0,0,1,.61-.31,1.43,1.43,0,0,1,.6.31.72.72,0,0,1,.24.57.56.56,0,0,1-.21.44A1,1,0,0,1,285.4,546.65Zm0-2.52a1,1,0,0,1-.56-.31.74.74,0,0,1-.17-.43.53.53,0,0,1,.06-.26.6.6,0,0,1,.16-.19.7.7,0,0,1,.23-.11,1,1,0,0,1,.55,0,.7.7,0,0,1,.23.11.6.6,0,0,1,.16.19.53.53,0,0,1,.06.26.74.74,0,0,1-.17.43A1,1,0,0,1,285.4,544.13Z" fill="#1d1d1b"/>
			</g>
		</svg>

		<Tooltip anchorId="tt1" place="right" float>
			<span>1. Premie zorgverzekering wettelijke eigen bijdragen en eigen risico</span>
		</Tooltip>

		<Tooltip anchorId="tt2" place="right" float>
			<span>2. Belastingen en inkomensafhankelijke premies</span>
		</Tooltip>

		<Tooltip anchorId="tt3" place="left" float>
			<span>3. Verblijfsvergoeding hospice, eigen betalingen</span>
		</Tooltip>

		<Tooltip anchorId="tt4" place="right" float>
			<span>4. Rijksbijdragen</span>
		</Tooltip>

		<Tooltip anchorId="tt5" place="right" float>
			<span>5. Subsidie</span>
		</Tooltip>

		<Tooltip anchorId="tt6" place="right" float>
			<span>6. Subsidie</span>
		</Tooltip>

		<Tooltip anchorId="tt7" place="left" float>
			<span>7. Subsidie</span>
		</Tooltip>

		<Tooltip anchorId="tt7b" place="left" float>
			<span>7. Vergoeding voor consultatie</span>
		</Tooltip>

		<Tooltip anchorId="tt8" place="right" float>
			<span>8. Betaling voor zorg en ondersteuning</span>
		</Tooltip>
	</div>
);

export default GeldstromenZVW;


