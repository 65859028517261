import React from 'react';
import LineChart from '../../../components/LineChart/LineChart';
import { formatLocale } from 'd3';

const HoeveelHuisartsen = () => {

	const margin = {top: 60, right: 30, bottom: 90, left: 45};

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);

	const data = [
			{
				name: 'gebruikers',
				values: [
					{ x: 1990, y: 6799 },
					{ x: 1991, y: 6855 },
					{ x: 1992, y: 6901 },
					{ x: 1993, y: 6961 },
					{ x: 1994, y: 7016 },
					{ x: 1995, y: 7123 },
					{ x: 1996, y: 7196 },
					{ x: 1997, y: 7385 },
					{ x: 1998, y: 7463 },
					{ x: 1999, y: 7605 },
					{ x: 2000, y: 7769 },
					{ x: 2001, y: 7822 },
					{ x: 2002, y: 7970 },
					{ x: 2003, y: 8130 },
					{ x: 2004, y: 8306 },
					{ x: 2005, y: 8490 },
					{ x: 2006, y: 8615 },
					{ x: 2007, y: 8734 },
					{ x: 2008, y: 8846 },
					{ x: 2009, y: 8900 },
					{ x: 2010, y: 9004 },
					{ x: 2011, y: 8946 },
					{ x: 2012, y: 9010 },
					{ x: 2013, y: 9087 },
					{ x: 2014, y: 9244 },
					{ x: 2015, y: 9616 },
					{ x: 2016, y: 9798 }
				]
			}
		];


	return (

		<div className="vis-hoeveel-huisartsen">

			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="x 1000"
				domainY={[6000, 10000]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatY}
			/>

			<div className="vis-credit">
				<p>Aantal regulier gevestigde huisartsen</p>
				<p>Bron: NIVEL Databank</p>
			</div>

		</div>
);
}

export default HoeveelHuisartsen;


