import {COLOR_MAGENTA} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"colofon-ondersteuning-op-school",
  titel:"Colofon",
  kleur: COLOR_MAGENTA,
  tekst: "",
  links: []
}
