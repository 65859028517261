import React from 'react';
import { ReactComponent as TheSvg } from './chronische-ziektes.svg'

const ChronischeZiektes = () => {
    return (
        <div style={{padding: "50px 0"}} className="vis basis">
            <TheSvg />
            <div className="vis-euros-vws">
                <div className="vis-credit">
                    <p>Percentage mannen en vrouwen met twee of meer chronische ziekten, voor enkele leeftijdscategorieën, in 2021.</p>
                    <p>Bron: VZInfo, 2022.</p>
                </div>
            </div>
        </div>
    );
}

export default ChronischeZiektes;
