import React from 'react';
import { Link } from 'react-router-dom';
import Links from '../Links/Links';
import { Highlight } from 'react-highlighter-ts';

import icons from '../Icons/icons';

import './Intro.scss';

const Intro = (props) => {
  const {titel , text, categorie, links, pages, searchstring} = props;
  const titleClass = categorie !== 'basis' ? 'title special' : 'title';
  const icon = categorie === 'basis' ? null :
    <Link to={'/' + categorie} title={'Voorpagina ' + categorie}>{icons[categorie]}</Link>;

  return (
    <div className='intro'>
      <div className={titleClass}>
      	<Highlight search={searchstring}>{titel}</Highlight>
        {icon}
    	</div>
    	<div className='intro-tekst'>
    		<Highlight search={searchstring}>{text}</Highlight>
  		</div>
      <Links categorie={categorie} links={links} pages={pages} />
    </div>
    )
}

export default Intro;
