import React from 'react';

import BarChart from '../../../components/BarChart/BarChart';

const FysiekeBeperkingen = () => {

	const data = [
			{
	        "key": "Geen fysieke beperkingen",
	        "value": 2,
	    },
	    {
	        "key": "Lichte fysieke beperkingen",
	        "value": 5,
	    },
	    {
	        "key": "Matige fysieke beperkingen",
	        "value": 12,
	    },
	    {
	        "key": "Ernstige fysieke beperkingen",
	        "value": 44,
	    },
	    {
	        "key": "Zeer ernstige fysieke beperkingen",
	        "value": 37,
	    },

	];


	const dataBarchart = data.map(item => {
		item.key = item.key.replace(' fysieke', '');
		return item;
	})

	const formatValueTooltip = val => val + '%';
	const formatValueY = val => val + '%';


	return (
		<div className="vis-fysieke-beperkingen">

      <BarChart
        data={dataBarchart}
        margin={{ top: 30, right: 0, bottom: 10, left: 240 }}
        barHeight={30}
        domain={[0,45]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        marginBeforeValue={-60}
        animation={true}
      />

			<div className="vis-credit">
				<p>Cijfers dateren uit 2016.</p>
				<p>Bron: Sociaal en Cultureel Planbureau (2017)</p>
			</div>

		</div>
	);
}

export default FysiekeBeperkingen;


