import React from 'react';
import BarChart from '../../../../../components/BarChart/BarChart';

const Employment = () => {
  const formatValueTooltip = (val) => `${val}%`;
  const formatValueY = (val) => `${val}%`;

  const data = [
    { key: 'Nursing and care ', value:  35 },
    { key: 'General and other hospitals', value:  17 },
    { key: 'Disabled services', value: 15 },
    { key: 'Miscellaneous care and welfare', value: 10 },
    { key: 'Mental healthcare', value: 8 },
    { key: 'University medical centres', value: 6 },
    { key: 'Social work', value:  4 },
    { key: 'GPs and health centres', value: 3 },
    { key: 'Youth health services', value:  2 },
  ];

  return (
    <div className="vis-employment">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 300 }}
        barHeight={30}
        domain={[0,25]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <div className="vis-credit">
        <p>Breakdown of healthcare workers in 2020.</p>
        <p>Source: AZWinfo (Webpage). Arbeidsmarkt zorg en welzijn.</p>
      </div>

    </div>
  );
}

export default Employment;
