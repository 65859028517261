import React from 'react';

const Kruisje = ({kleur}) => (
	<svg viewBox="0 0 30 30" className="af-kruis" stroke={kleur}>
		<polyline points="6,15 24,15"  fill="none" strokeWidth="2" ></polyline>
		<polyline points="15,6 15,24" className="staander" fill="none" strokeWidth="2" ></polyline>
	</svg>
);

export default Kruisje;
