import intro from './intro'
import watIsGeboortezorg from './wat-is-geboortezorg'
import aantalBevallingen from './aantal-bevallingen'
import uitgavenGeboortezorg from './uitgaven-geboortezorg'
import colofon from './colofon'

export default {
  intro,
  watIsGeboortezorg,
  aantalBevallingen,
  uitgavenGeboortezorg,
  colofon,
}
