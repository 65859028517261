import React from "react";
import BarChart from "../../../../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const VolwasseneZorguitgaven = () => {
    const localeFormatter = formatLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "comma": true,
    });

    const formatValueTooltip = val =>
        localeFormatter.format(",.0f")(val) + " euro";
    const formatValueY = val => localeFormatter.format(",.0f")(val);

    const data = [
        { key: "Inkomensafhankelijke bijdrage", value: 2045 },
        { key: "Belasting", value: 2018 },
        { key: "Nominale premie", value: 1649 },
        { key: "Premie Wet langdurige zorg", value: 1081 },
        { key: "Eigen betalingen", value: 381 },
        { key: "Zorgtoeslag", value: -573 }
    ];

    return (
        <div className="vis-euros-volwassene">
            <BarChart
                data={data}
                margin={{ top: 30, right: 0, bottom: 10, left: 255 }}
                barHeight={30}
                domain={[-381, 1750]}
                color={{ fill: "#333", stroke: "#fff" }}
                formatValueTooltip={formatValueTooltip}
                formatValueY={formatValueY}
                padding={0.2}
                ticks={0}
                marginBeforeValue={-50}
            />

            <div className="vis-total">
                <p className="groot">6.601</p>
                <p>euro per volwassene in 2023</p>
            </div>

            <div className="vis-credit">
                <p>Zorgkosten die voortvloeien uit de Rijksbegroting, per volwassen Nederlander in euro in 2023.</p>
                <p>Rijksbegroting 2023, rijksfinanciën, 2022.</p>
            </div>
        </div>
    );
};

export default VolwasseneZorguitgaven;
