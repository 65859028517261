import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_MAGENTA} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "arbeidsmarkt",
    titel: "Hoe ziet de arbeidsmarkt eruit?",
    kleur: COLOR_MAGENTA,
    tekst: "Meer dan 1,4 miljoen mensen werken in de zorg. Waar werken zij zoal en hoe staat het met de vacatures in de zorg?",
    links:
      [
        'aantal-werkenden',
        'plek-werkenden',
        'vacatures'
      ]
  },
  {
    template: "visualisatie",
    naam: "aantal-werkenden",
    titel: "Hoeveel mensen werken in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['plek-werkenden']
  },
  {
    template: "visualisatie",
    naam: "plek-werkenden",
    titel: "Waar werken mensen in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['vacatures']
  },
  {
    template: "visualisatie",
    naam: "vacatures",
    titel: "Hoeveel openstaande vacatures zijn er in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['aantal-werkenden']
  }
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
