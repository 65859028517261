import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_GREEN} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "gedachte-doelen",
    titel: "Wat zijn de gedachte en doelen van het zorgstelsel?",
    kleur: COLOR_GREEN,
    tekst: "",
    links:
      [
        'gedachte',
        'doelen',
        'preventie-vormen',
      ]
  },
  {
    template: "visualisatie",
    naam: "gedachte",
    titel: "Wat is de gedachte achter het zorgstelsel?",
    kleur: COLOR_GREEN,
    links: ['doelen']
  },
  {
    template: "harmonica",
    naam: "doelen",
    titel: "Wat zijn de doelen van ons zorgstelsel?",
    kleur: COLOR_GREEN,
    links: ['preventie-vormen']
  },
  {
    template: "harmonica",
    naam: "preventie-vormen",
    titel: "Welke vormen van preventie zijn er?",
    kleur: COLOR_GREEN,
    links: ['preventie-wetten']
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
