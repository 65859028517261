import {COLOR_MAGENTA} from "../../../../vocabulary";

export default {
    template: "intro",
    naam: "mini-ondersteuning-op-school",
    titel: "Zó werkt de zorg-mini: Zorg en ondersteuning op school",
    kleur: COLOR_MAGENTA,
    tekst:
        "Om het onderwijs te kunnen volgen hebben sommige kinderen (extra) ondersteuning nodig. In 2014 is het 'passend onderwijs' ingevoerd met als doel dat alle kinderen een onderwijsplek krijgen die past bij hun ondersteuningsbehoefte. Of dit nu op een reguliere of speciale school is. In deze mini lees je in het kort hoe ondersteuning op school in Nederland is geregeld. 'Ondersteuning' omvat in deze mini: zorg- en onderwijsondersteuning op school en tijdens schooltijd. Waar ouder(s) staat, bedoelen we ook verzorger(s).",
    links: [
        'wat-is-ondersteuning-op-school',
        'rollen-ondersteuning-op-school',
        'bekostiging-ondersteuning-op-school',
        'colofon-ondersteuning-op-school'
    ]
}
