import React from 'react';

const Ster = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
		<g fill="#FFF" fillRule="nonzero">
			<path d="M45.5 29.5v21h-21v-21h21m2-2h-25v25h25v-25Z"/>
			<path d="M45.5 52v21h-21V52h21m2-2h-25v25h25V50ZM75.5 22.5v21h-21v-21h21m2-2h-25v25h25v-25Z"/>
			<path d="M69 52v21H48V52h21m2-2H46v25h25V50Z"/>
		</g>
	</svg>
);

export default Ster;



