import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const Studenten = () => {
	const margin = { top: 10, right: 12, bottom: 30, left: 12 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format(',.0f')(val);

	const formatValueTooltip1 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 20673)}
		</span>
	);

	const data1 = [
		{
			name: "Helpende zorg en welzijn  mbo niveau 1 en 2 ",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 89.8563 },
				{ x: 2014, y: 76.0461},
				{ x: 2015, y: 59.4640 }
			]
		}
	];


	const formatValueTooltip2 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 23474)}
		</span>
	);

	const data2 = [
		{
			name: "Verzorgende mbo niveau 3",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 106.1430},
				{ x: 2014, y: 102.8031},
				{ x: 2015, y: 101.0309 }
			]
		}
	];


	const formatValueTooltip3 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 18873)}
		</span>
	);

	const data3 = [
		{
			name: "Mbo-verpleegkundige mbo niveau 4",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 104.8800},
				{ x: 2014, y: 107.0683},
				{ x: 2015, y: 115.0374 }
			]
		}
	];


	const formatValueTooltip4 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 13847)}
		</span>
	);

	const data4 = [
		{
			name: "Hbo-verpleegkundige hbo niveau 6",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 117.2817		},
				{ x: 2014, y: 118.0545},
				{ x: 2015, y: 131.0248 }
			]
		}
	];


	const formatValueTooltip5 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 101)}
		</span>
	);

	const data5 = [
		{
			name: "Klinisch geriater wo",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 117.8218},
				{ x: 2014, y: 125.7426},
				{ x: 2015, y: 134.6535 }
			]
		}
	];

	const formatValueTooltip6 = value => (
		<span>
			<br />indexcijfer {formatY(value)}
			<br />aantal {localeFormatter.format(",.0f")(value / 100 * 245)}
		</span>
	);

	const data6 = [
		{
			name: "Specialist ouderengeneeskunde wo",
			values: [
				{ x: 2012, y: 100},
				{ x: 2013, y: 111.8367},
				{ x: 2014, y: 129.3878},
				{ x: 2015, y: 140.8163 }
			]
		}
	];


	return (
		<div className="vis-studenten">

			<div className="toelichting">Ontwikkeling in indexcijfers, 2012=100.</div>

			<div className="chartBlock">
				<h4>Helpende zorg en welzijn mbo niveau 1 en 2</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data1}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip1}
				/>
			</div>

			<div className="chartBlock">
				<h4>Verzorgende mbo niveau 3</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data2}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip2}
				/>
			</div>

			<div className="chartBlock">
				<h4>Mbo-verpleegkundige mbo niveau 4</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data3}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip3}
				/>
			</div>

			<div className="chartBlock">
				<h4>Hbo-verpleegkundige hbo niveau 6</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data4}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip4}
				/>
			</div>

			<div className="chartBlock">
				<h4>Klinisch geriater wo</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data5}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip5}
				/>
			</div>

			<div className="chartBlock">
				<h4>Specialist ouderengeneeskunde wo</h4>
				<LineChart
					margin={margin}
					colorLines={["#000"]}
					colorAxis="#333"
					labelY="aantal"
					domainY={[50, 160]}
					data={data6}
					height={300}
					formatY={formatY}
					formatValueTooltip={formatValueTooltip6}
				/>
			</div>

			<div className="vis-credit">
				<p>Aantal studenten dat een opleiding voor een beroep in de ouderenzorg volgt. Ontwikkeling ten opzichte van 2012 in indexcijfers en absolute aantallen.</p>
				<p>Bron: Prismant (2018). Databewerking.</p>
			</div>
		</div>
	);
};

export default Studenten;
