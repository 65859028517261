import React from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import Pijl from "../Icons/Pijl";
import Hamburger from "../Icons/Hamburger";
import Logo from "../Icons/Logo";
import Searchbar from "../Searchbar/Searchbar";

import "./Header.scss";

const Header = (props) => {
	const navigate = useNavigate()

	const { menuopen, toggleMenu } = props

	const statusMenu = menuopen ? "open" : "dicht";

	return (
		<div className="header">
			<div className="menu-bar">
				<h1>Zo werkt de Zorg</h1>
				<Link title="Voorpagina" to="/">
					<Logo />
				</Link>
				<span className="page-back" onClick={() => navigate(-1)}>
					<Pijl />
				</span>
				<span className={"page-hamburger " + statusMenu} onClick={toggleMenu}>
					<Hamburger />
				</span>
			</div>
			<Searchbar {...props} />
		</div>
	);
};

export default Header;
