import React from "react";
import "./Colophon.scss";

const Colophon = props => {
	return (
		<div className="colofonkolom">
			<div className="kop">Colofon</div>
			<p className="introductie">
				De Zó werkt de zorg-app is een product van het Platform Zó werkt de zorg. Dit platform is een samenwerkingsverband van partijen uit de zorg met als missie: heldere en neutrale informatie over de zorg. Voor zorgprofessionals, bestuurders, beleidsmakers, patiënten(verenigingen) en andere geïnteresseerden.
			</p>
			<p>
				De app bevat informatie uit het boek Zó werkt de zorg in Nederland dat op 5 juni 2018 is verschenen. Het boek is gemaakt op basis van literatuuronderzoek en een serie denksessies met experts uit de zorgsector.
			</p>
			<p>
				De app bevat ook verdiepende specials over de zorg. De inhoud van deze specials is tot stand gekomen door Platform Zó werkt de zorg in samenwerking met diverse specialpartners.
			</p>
			<p><span className="nadruk">Zó werkt de huisartsenzorg </span>is mogelijk gemaakt door:<br/>
				LHV, PROFclass en Stichting SBOH</p>
			<p><span className="nadruk">Zó werkt de ouderenzorg </span>is mogelijk gemaakt door:<br/>
				AAG, Actiz, ANBO, Prismant, SBOH, SOON, Verenso, Vilans en VWS</p>
			<p><span className="nadruk">Zó werkt publieke gezondheidszorg </span>is mogelijk gemaakt door:<br/>
				GGD GHOR Nederland, KAMG, RIVM, SBOH en VWS</p>
			<p><span className="nadruk">Zó werkt de zorgverzekering </span>is mogelijk gemaakt door:<br/>
				Consumentenbond, Nederlandse Zorgautoriteit, Patiëntenfederatie Nederland, Vektis, VvAA en VWS</p>
			<p><span className="nadruk">Zó werkt de geneesmiddelenzorg </span>is mogelijk gemaakt door:<br/>
				CBG, ESHPM, HollandBIO, KNMP, NFU, NZa, Vereniging Innovatieve Geneesmiddelen, VvAA, ministerie van VWS, ZN en Zorginstituut Nederland</p>
			<p>
				<span className="nadruk">
					Zó werkt de gehandicaptenzorg </span>is mogelijk gemaakt door:<br/>
					AAG, Cello, Firevaned, Ieder(In), NVAVG, Philadelphia, ‘s Heeren Loo, SVB, SWZ, Vilans, VNG, ministerie van VWS, VGN en ZN
			</p>

			<p>
				<span className="nadruk">
					Zó werkt de ggz </span>is mogelijk gemaakt door:<br/>
				VWS, DJI, JenV,NIP, NZa, Verslavingskunde Nederland, MIND, SBOH, VNG, LVVP, Levvel, Kwintes, P3NL, de Nederlandse ggz, V&VN, FGzPt en ZN
			</p>

			<p>
				<span className="nadruk">
					Zó werkt het sociaal domein </span>is mogelijk gemaakt door:<br/>
				 is mogelijk gemaakt door: VNG, G40, LCGW, ministeries BZK, SZW en VWS, Movisie, SCP, Sociaal Werk Nederland en UWV
			</p>

			<p>
				De Argumentenfabriek is als uitvoerend partner van Platform Zó werkt de
				zorg verantwoordelijk voor de inhoud van deze app. Een aantal maal per
				jaar krijgt deze app een update.
			</p>
			<h4>Meer weten of reageren?</h4>
			<p>
				Heb je vragen of opmerkingen over de inhoud van de app? We stellen feedback zeer op prijs. Stuur een mail naar <a href="mailto:info@zwdz.nl">info@zwdz.nl</a>. Op dit adres kun je ook terecht voor vragen over <a href="https://www.zowerktdezorg.nl/" target="_blank" rel="noopener noreferrer">Platform Zó werkt de zorg</a>
			</p>
			<h4>Versie</h4>
			<p>Dit is versie { process.env.REACT_APP_VERSION } van de Zó werkt de zorg-app.</p>
		</div>
	);
};

export default Colophon;
