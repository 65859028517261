import React from 'react';

import StappenNieuwGeneesmiddel from './visualizations/stappen-nieuw-geneesmiddel.js';
import PlaatsVerstrekking from './visualizations/plaats-verstrekking.js';
import HoeveelGebruikers from './visualizations/hoeveel-gebruikers.js';
import Meestgebruikt from './visualizations/meestgebruikt.js';
import WieGebruiktGeneesmiddelen from './visualizations/wie-gebruikt-geneesmiddelen.js';
import ZiekenhuisopnameGeneesmiddelen from './visualizations/ziekenhuisopname-geneesmiddelen.js';
import PreferentiePerVerzekeraar from './visualizations/preferentie-per-verzekeraar.js';
import Geneesmiddelentekort from './visualizations/geneesmiddelentekort.js';
import OplossingenTekort from './visualizations/oplossingen-tekort.js';
import UitgavenGeneesmiddelen from './visualizations/euros-geneesmiddelen.js';
import KostenDureGeneesmiddelen from './visualizations/kosten-dure-geneesmiddelen.js';
import KostenInternationaal from './visualizations/kosten-internationaal.js';
import GeldstromenZvw from './visualizations/geldstromen-zvw.js';


const vissen = {
	stappen_nieuw_geneesmiddel: <StappenNieuwGeneesmiddel />,
	plaats_verstrekking: <PlaatsVerstrekking />,
	hoeveel_gebruikers: <HoeveelGebruikers />,
	meestgebruikt: <Meestgebruikt />,
	wie_gebruikt_geneesmiddelen: <WieGebruiktGeneesmiddelen />,
	ziekenhuisopname_geneesmiddelen: <ZiekenhuisopnameGeneesmiddelen />,
	preferentie_per_verzekeraar: <PreferentiePerVerzekeraar />,
	geneesmiddelentekort: <Geneesmiddelentekort />,
	oplossingen_tekort: <OplossingenTekort />,
	uitgaven_geneesmiddelen: <UitgavenGeneesmiddelen />,
	kosten_dure_geneesmiddelen: <KostenDureGeneesmiddelen />,
	kosten_internationaal: <KostenInternationaal />,
	geldstromen_zvw: <GeldstromenZvw />
};

export default vissen;