import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const Tevredenheid = () => {

  const formatValueTooltip = val => val + ' procent';
  const formatValueY = val => `${val}%`;

  const dataA = [
    {key: 'Algemene bevolking', tooltip: '', value: 77},
    {key: 'Mensen met psychische problemen', tooltip: '', value: 39},
  ];

  const dataB = [
    {key: 'Algemene bevolking', tooltip: '', value: 80},
    {key: 'Mensen met psychische problemen', tooltip: '', value: 58},
  ];

  return (
    <div className="vis-tevredenheid">

      <h4>Ik heb de mogelijkheid te leven zoals ik wil</h4>

      <BarChart
        data={dataA}
        margin={{top: 10, right: 0, bottom: 10, left: 240}}
        barHeight={30}
        domain={[0, 85]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <h4>Ik heb het gevoel dat ik meetel in de maatschappij</h4>

      <BarChart
        data={dataB}
        margin={{top: 10, right: 0, bottom: 10, left: 240}}
        barHeight={30}
        domain={[0, 85]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      <div className="vis-credit">
        <p>Tevredenheid van mensen met psychische problemen over hun leven, afgezet tegen de rest van de Nederlandse bevolking.</p>
        <p>Bron: Nivel, 2019</p>
      </div>

    </div>
  )
}

export default Tevredenheid;
