import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import {formatLocale} from 'd3';


const UitgavenGeboortezorg = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
  });

  const formatValueTooltip = val => localeFormatter.format(',.1f')(val) + ' miljoen euro';
  const formatValueY = val => localeFormatter.format(',.1f')(val);

  const data = [
    {key: 'Ziekenhuiszorg', value: 1084.4},
    {key: 'Kraamzorg', value: 299.2},
    {key: 'Eerstelijnszorg', value: 278.4},
    {key: 'Genees en hulpmiddelen', value: 49.9},
    {key: 'Overig (inclusief beheer)', value: 111.2},
  ];

  return (
    <div className="vis-uitgaven-geboortezorg">
      <BarChart
        data={data}
        margin={{top: 30, right: 0, bottom: 10, left: 230}}
        barHeight={30}
        domain={[0, 1800]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        marginBeforeValue={-60}
        ticks={0}
      />

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">1,8</p>
        <p>miljard euro in 2017</p>
      </div>

      <div className="vis-credit">
        <p>Uitgaven aan geboortezorg in 2017, in miljoen euro.</p>
        <p>Bron: RIVM, CBS en ErasmusMC, 2019 Kosten van ziekten</p>
      </div>
    </div>
  )
}

export default UitgavenGeboortezorg;
