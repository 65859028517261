import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_CYAAN} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "bekostiging",
    titel: "Hoe lopen geldstromen in de zorg?",
    kleur: COLOR_CYAAN,
    tekst: "Soms betaalt een burger direct voor zorg aan een zorgaanbieder. Meestal gaat dit met wat meer omwegen. Hoe de geldstromen in de zorg lopen vind je hieronder in heldere visualisaties met korte toelichting.",
    links:
      [
        "wie-betaalt-zvw",
        "geldstromen-zvw",
        "wie-betaalt-wlz",
        "geldstromen-wlz",
        "wie-betaalt-wmo",
        "geldstromen-wmo",
        "wie-betaalt-jw",
        "geldstromen-jw",
        "wie-betaalt-wpg",
        "geldstromen-wpg",
      ]
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-zvw",
    titel: "Wie betaalt wat in de Zorgverzekeringswet?",
    kleur: COLOR_CYAAN,
    links: ['geldstromen-zvw']
  },
  {
    template: "visualisatie",
    naam: "geldstromen-zvw",
    titel: "Hoe lopen de geldstromen in de Zorgverzekeringswet?",
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-zvw']
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-wlz",
    titel: "Wie betaalt wat in de Wet langdurige zorg?",
    kleur: COLOR_CYAAN,
    links: ['geldstromen-wlz']
  },
  {
    template: "visualisatie",
    naam: "geldstromen-wlz",
    titel: "Hoe lopen de geldstromen in de Wet langdurige zorg?",
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wlz']
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-wmo",
    titel: "Wie betaalt wat in de Wet maatschappelijke ondersteuning?",
    kleur: COLOR_CYAAN,
    links: ['geldstromen-wmo']
  },
  {
    template: "visualisatie",
    naam: "geldstromen-wmo",
    titel: "Hoe lopen de geldstromen in de Wet maatschappelijke ondersteuning?",
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wmo']
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-jw",
    titel: "Wie betaalt wat in de Jeugdwet?",
    kleur: COLOR_CYAAN,
    links: ['geldstromen-jw']
  },
  {
    template: "visualisatie",
    naam: "geldstromen-jw",
    titel: "Hoe lopen de geldstromen in de Jeugdwet?",
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wpg']
  },
  {
    template: "harmonica",
    naam: "wie-betaalt-wpg",
    titel: "Wie betaalt wat in de Wet publieke gezondheid?",
    kleur: COLOR_CYAAN,
    links: ['geldstromen-wpg']
  },
  {
    template: "visualisatie",
    naam: "geldstromen-wpg",
    titel: "Hoe lopen de geldstromen in de Wet publieke gezondheid?",
    kleur: COLOR_CYAAN,
    links: ['wie-betaalt-wpg']
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
