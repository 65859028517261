import React from 'react';

import Hoofdrolspelers from './visualizations/hoofdrolspelers';
import Geldstromen from './visualizations/geldstromen';

const vissen = {
  hoofdrolspelers: <Hoofdrolspelers/>,
  geldstromen: <Geldstromen/>,
};

export default vissen;
