import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const SocialeWijkteams = () => {
    const formatValueTooltip = (val) => `${val}%`;
    const formatValueY = (val) => `${val}%`;

    const data = [
        { key: 'Vraag verhelderen', value:  90 },
        { key: 'Casusregie voeren', value:  86 },
        { key: 'Plan maken met cliënt', value: 83 },
        { key: 'Problemen en risico\'s signaleren', value: 75 },
        { key: 'Kortdurende ondersteuning verlenen', value: 70 },
        { key: 'Ondersteuning toewijzen (beschikking afgeven)', value: 70 },
        { key: 'Ondersteuning toewijzen', value: 11 },
        { key: 'Preventie-activiteiten organisatie', value: 9 },
    ];

    return (
        <div className="vis-sociale-wijkteams">
            <BarChart
                data={data}
                margin={{ top: 30, right: 0, bottom: 10, left: 350 }}
                barHeight={30}
                domain={[0,100]}
                color={ {fill: '#333', stroke: '#fff'} }
                formatValueTooltip={formatValueTooltip}
                formatValueY={formatValueY}
                padding={0.2}
                ticks={0}
                marginBeforeValue={-40}
            />

            <div className="vis-credit">
                <p>Percentage wijkteams dat een bepaalde taak uitvoert in 2019. </p>
                <p>Bron: Movisie, 2020</p>
            </div>

        </div>
    );
}

export default SocialeWijkteams;
