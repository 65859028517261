import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie:"mini",
  template:"harmonica",
  naam:"delen",
  titel:"Wanneer mogen medische gegevens gedeeld worden en in welke gevallen is hiervoor toestemming van de patiënt vereist?",
  kleur: COLOR_YELLOW,
  tekst: "",
  links: [
    'verzamelen',
    'bereidheid',
  ]
}
