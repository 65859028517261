import {COLOR_YELLOW} from "../../../../vocabulary";

export default {
  categorie: "mini",
  template: "intro",
  naam:"mini-a-infectieziekte",
  titel:"Zó werkt de zorg-mini: A-infectieziekte",
  kleur: COLOR_YELLOW,
  tekst:
    "Door de uitbraak van covid-19 staat de bestrijding van A-infectieziekten volop in de aandacht. Wat is een A-infectieziekte? Wat is de rol van de overheid bij de bestrijding ervan? En welke (dwingende) maatregelen kan de overheid nemen? Je leest het in deze mini.",
  links:
    [
      'wat-is-een-a-infectieziekte',
      'wie-doet-wat',
      'dwingende-maatregelen',
      'colofon-a-infectieziekte'
    ]
}
