import React from "react";
import BarChart from "../../../components/BarChart/BarChart";
import { formatLocale } from "d3";

const ZorgkostenLeeftijdsgroep = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val =>
		localeFormatter.format(",.0f")(val);
	const formatValueY = val => localeFormatter.format(",.0f")(val);

	const data = [
{key:"0 jaar",value:3484},
{key:"1 tot 5 jaar",value:1102},
{key:"5 tot 10 jaar",value:874},
{key:"10 tot 15 jaar",value:829},
{key:"15 tot 20 jaar",value:1001},
{key:"20 tot 25 jaar",value:1124},
{key:"25 tot 30 jaar",value:1426},
{key:"30 tot 35 jaar",value:1662},
{key:"35 tot 40 jaar",value:1577},
{key:"40 tot 45 jaar",value:1526},
{key:"45 tot 50 jaar",value:1698},
{key:"50 tot 55 jaar",value:2047},
{key:"55 tot 60 jaar",value:2456},
{key:"60 tot 65 jaar",value:3037},
{key:"65 tot 70 jaar",value:3686},
{key:"70 tot 75 jaar",value:4652},
{key:"75 tot 80 jaar",value:5743},
{key:"80 tot 85 jaar",value:6981},
{key:"85 jaar of ouder",value:8337},
	];

	return (
		<div className="vis-zorgkosten-leeftijdsgroep">
			<BarChart
				data={data}
				margin={{ top: 30, right: 0, bottom: 10, left: 160 }}
				barHeight={30}
				domain={[0, 8337]}
				color={{ fill: "#333", stroke: "#fff" }}
				formatValueTooltip={formatValueTooltip}
				formatValueY={formatValueY}
				padding={0.2}
				ticks={0}
				marginBeforeValue={-55}
			/>

			<div className="vis-credit">
				<p>
					Gemiddelde zorgkosten in euro per persoon in 2015 volgens de Zorgverzekeringswet.
				</p>
				<p>Bron: RIVM.</p>
			</div>
		</div>
	);
};

export default ZorgkostenLeeftijdsgroep;
