import React from 'react';
import { Link } from 'react-router-dom';


const Rationale = () => (
	<div className="vis-rationale svg-container">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMin meet"
			viewBox="0 0 660 440">

			<g className="teksten">
				<text transform="translate(158.46 57.45)">… in which people <tspan x="0" y="16.05">help themselves </tspan><tspan x="0" y="32.1">first …</tspan></text>
				<text transform="translate(22.04 41.48)">The government <tspan x="0" y="16.05">creates conditions </tspan><tspan x="0" y="32.1">for good public </tspan><tspan x="0" y="48.15">health … </tspan></text>
				<text transform="translate(290.88 57.45)">… with support from <tspan x="0" y="16.05">friends and family </tspan><tspan x="0" y="32.1">if possible … </tspan></text>
				<text transform="translate(411.25 73.5)">… with support <tspan x="0" y="16.05">from caregivers …</tspan></text>
				<text transform="translate(571.76 73.5)">… longterm <tspan x="0" y="16.05">if necessary.</tspan></text>
			</g>

			<Link to={{ pathname: '/gidsen/laws', hash: '#PublicHealthAct' }} >
				<path className="kader" d="M18,366.07H138.4a0,0,0,0,1,0,0v48.15a0,0,0,0,1,0,0H10a0,0,0,0,1,0,0V374.1A8,8,0,0,1,18,366.07Z" strokeWidth="4.01"/>
				<text transform="translate(25 385)">Public Health</text>
				<text transform="translate(65 405)">Act</text>
			</Link>

			<Link to={{ pathname: '/gidsen/laws', hash: '#HealthInsuranceAct' }} >
				<path className="kader" d="M393.2,269.77H513.57a8,8,0,0,1,8,8v40.13a0,0,0,0,1,0,0H393.2a0,0,0,0,1,0,0V269.77A0,0,0,0,1,393.2,269.77Z" strokeWidth="4.01"/>
				<text transform="translate(435 292)">Health</text>
				<text transform="translate(410 305)">Insurance Act</text>
			</Link>

			<Link to={{ pathname: '/gidsen/laws', hash: '#SocialSupportAct' }} >
				<rect className="kader" x="393.2" y="317.92" width="128.4" height="48.15" fill="none" strokeWidth="4.01"/>
				<text transform="translate(410 340)">Social Support</text>
				<text transform="translate(445 355)">Act</text>
			</Link>

			<Link to={{ pathname: '/gidsen/laws', hash: '#YouthAct' }} >
				<rect className="kader" x="393.2" y="366.07" width="128.4" height="48.15" strokeWidth="4.01"/>
				<text transform="translate(422.57 398.17)">Youth Act</text>
			</Link>

			<Link to={{ pathname: '/gidsen/laws', hash: '#Long-TermCareAct' }} >
				<path className="kader" d="M521.6,366.07H642a8,8,0,0,1,8,8v40.13a0,0,0,0,1,0,0H521.6a0,0,0,0,1,0,0V366.07A0,0,0,0,1,521.6,366.07Z" strokeWidth="4.01"/>
				<text transform="translate(550 388)">Long-Term</text>
				<text transform="translate(560 405)">Care Act</text>
			</Link>

			<g className="kaders">
				<path className="kader" d="M264.8,173.47H385.17a8,8,0,0,1,8,8V414.22a0,0,0,0,1,0,0H264.8a0,0,0,0,1,0,0V173.47A0,0,0,0,1,264.8,173.47Z" fill="#fff" strokeWidth="4.01"/>
				<path className="kader" d="M144.42,125.67H256.77a8,8,0,0,1,8,8V414.57a0,0,0,0,1,0,0H136.39a0,0,0,0,1,0,0V133.69a8,8,0,0,1,8-8Z" fill="#fff" strokeWidth="4.01"/>
				<text transform="translate(175 250)">Private</text>
				<text transform="translate(165 270)">Individuals</text>
				<text transform="translate(297.91 260)">Network</text>
			</g>

		</svg>
	</div>
);

export default Rationale;


