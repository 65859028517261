import React from 'react';
import WaarWanneer from './visualizations/waar-wanneer.js';
import SamenwerkingEersteLijn from './visualizations/samenwerking-eerste-lijn.js';
import SamenwerkingTweedeLijn from './visualizations/samenwerking-tweede-lijn.js';
import HoeveelHuisartsen from './visualizations/hoeveel-huisartsen.js';
import VrouwelijkeHuisartsen from './visualizations/vrouwelijke-huisartsen.js';
import Praktijkvormen from './visualizations/praktijkvormen.js';
import KostenPerPersoon from './visualizations/kosten-per-persoon.js';
import KostenSegmenten from './visualizations/kosten-segmenten.js';
import Inkomen from './visualizations/inkomen.js';
import GeldstromenZvw from './visualizations/geldstromen-zvw.js';

const vissen = {
	waar_wanneer: <WaarWanneer />,
	samenwerking_eerste_lijn: <SamenwerkingEersteLijn />,
	samenwerking_tweede_lijn: <SamenwerkingTweedeLijn />,
	hoeveel_huisartsen: <HoeveelHuisartsen />,
	vrouwelijke_huisartsen: <VrouwelijkeHuisartsen />,
	praktijkvormen: <Praktijkvormen />,
	kosten_per_persoon: <KostenPerPersoon />,
	kosten_segmenten: <KostenSegmenten />,
	inkomen: <Inkomen />,
	geldstromen_zvw: <GeldstromenZvw />
};

export default vissen;
