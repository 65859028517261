export default {"tekst": "Hoe ziet de controle van huisartsdeclaraties eruit?","items": [{
    "tekst": "Procedure",
    "items": [{
        "tekst": "Het ministerie van Volksgezondheid, Welzijn en Sport, de Nederlandse Zorgautoriteit (NZa) en Zorginstituut Nederland schetsen het kader voor declaraties van huisartsen."
    }, {
        "tekst": "De huisartsenpraktijk declareert bij de zorgverzekeraar de zorg die is geleverd."
    }, {
        "tekst": "De zorgverzekeraar controleert of de zorg vergoed mag worden en tegen welk tarief (formele controle)."
    }, {
        "tekst": "Na de betaling kan de zorgverzekeraar controleren of de zorg echt geleverd is (materiële controle)."
    }, {
        "tekst": "De NZa ziet erop toe dat zorgverzekeraars declaraties controleren en grijpt zo nodig in."
    }]
}]}