import React, {Component} from 'react';
import Links from '../Links/Links';
import { Highlight } from 'react-highlighter-ts';

import icons from '../Icons/icons';
import restricted from '../../data/restricted';

import './SpecialFrontpage.scss';

class SpecialFrontpage extends Component {
  UNSAFE_componentWillMount() {
    const {access, history, categorie} = this.props;
    const auth = restricted.indexOf(categorie) === -1 || access.indexOf(categorie) > -1;
    if (!auth) history.push('/specials');
  }

  render() {
    const {title, text, categorie, links, pages, searchstring} = this.props;

    return (
      <div className="special">
        <div className="special-kolom-links">
          {icons[categorie]}
        </div>
        <div className="special-kolom-rechts">
          <div className="titel">
          	<Highlight search={searchstring}>{title}</Highlight>
        	</div>
        	<div className="special-tekst">
        		<Highlight search={searchstring}>{text}</Highlight>
      		</div>
          <Links categorie={categorie} links={links} pages={pages} />
        </div>
      </div>
      )
  }
}

export default SpecialFrontpage;
