import React from "react";
import LineChart from "../../../components/LineChart/LineChart";
import { formatLocale } from 'd3';

const HoeveelGebruikers = () => {
	const margin = { top: 60, right: 30, bottom: 90, left: 45 };

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatY = (val) => localeFormatter.format('.1f')(val/1000);
	
	const formatValueTooltip = value => formatY(value) + " miljoen gebruikers";

	const data = [
		{
			name: "aantalGebruikers",
			values: [
				{ x: 2013, y: 11349},
				{ x: 2014, y: 11279},
				{ x: 2015, y: 11391},
				{ x: 2016, y: 11586},
				{ x: 2017, y: 11391}
			]
		},
	];

	return (
		<div className="vis-aantal-gebruikers">
			<LineChart
				margin={margin}
				colorLines={["#000"]}
				colorAxis="#333"
				labelY="aantal"
				domainY={[10000, 12000]}
				data={data}
				height={400}
				formatY={formatY}
				formatValueTooltip={formatValueTooltip}
			/>

			<div className="vis-credit">
				<p>Aantal Nederlanders (x 1 miljoen) dat voorgeschreven geneesmiddelen verstrekt krijgt door een openbare apotheek.</p>
				<p>Bron: GIPdatabank, 2019.</p>
			</div>
		</div>
	);
};

export default HoeveelGebruikers;
