import React from 'react';

const Facebook = () => (
	<svg viewBox="0 0 44 44" className="icon">
		<path
			d="M32,10.66H30.1a2.17,2.17,0,0,0-2.36,2.49v2.49H32v3.29H27.74V37h-4.3V18.92H21V15.63h2.44v-2.7C23.45,9.86,25.3,7,29.3,7H32Z"
			fill="none"
			strokeMiterlimit="10"
			/>
	</svg>
);

export default Facebook;

