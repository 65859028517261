import React from 'react';

import Kaders from './visualizations/kaders';
import WieWatWanneer from './visualizations/wie-wat-wanneer';

const vissen = {
	kaders: <Kaders />,
	wie_wat_wanneer: <WieWatWanneer />,
};

export default vissen;
