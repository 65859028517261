import React from 'react';
import { Link } from 'react-router-dom';
import Pijl from '../Icons/Pijl';
import './Menu.scss';

const Menu = (props) => {

	return (
		<ul className="menu-items" onClick={props.toggleMenu}>
			<Link to="/"><li>Naar de voorpagina<Pijl /></li></Link>
			<Link to="/sitemap"><li>Overzicht van de app<Pijl /></li></Link>
			<Link to="/begrippen"><li>Lijst met afkortingen<Pijl /></li></Link>
			<Link to="/platform"><li>Over het Platform Zó werkt de zorg<Pijl /></li></Link>
			<a href="https://www.argumentenfabriek.nl/products/zo-werkt-de-zorg-in-nederland/" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de zorg in Nederland (editie 2023)<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zwdg" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de geneesmiddelenzorg<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zo-werkt-de-zorgverzekering" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de zorgverzekering<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zo-werkt-publieke-gezondheidszorg" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt publieke gezondheidszorg<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zo-werkt-de-ouderenzorg" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de ouderenzorg<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/het-huisartsenzorgboek" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de huisartsenzorg<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zowerktdegehandicaptenzorg" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de gehandicaptenzorg</li></a>
			<a href="https://www.argumentenfabriek.nl/nl/producten-kopen-of-gratis-downloaden/zowerktdeggz" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt de geestelijke gezondheidszorg<Pijl /></li></a>
			<a href="https://www.argumentenfabriek.nl/products/zo-werkt-het-sociaal-domein/" target="_blank" rel="noopener noreferrer"><li>Boek Zó werkt het sociaal domein<Pijl /></li></a>

			<Link to="/colofon"><li>Colofon<Pijl /></li></Link>
		</ul>
	)
}

export default Menu;
