import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_MAGENTA} from "../../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "belangrijkste",
    titel: "Wat doen de belangrijkste spelers?",
    kleur: COLOR_MAGENTA,
    tekst: "Een aantal spelers is in het bijzonder bepalend voor hoe het zorgstelsel eruitziet. We zetten deze spelers uiteen en belichten hun rol.",
    links:
      [
        'hoofdrolspelers',
        'rol-hoofdrolspelers',
        'zorginkopers',
        'markten',
        'andere-spelers',
        'rol-andere-spelers',
        'toezicht',
      ]
  },
  {
    template: "visualisatie",
    naam: "hoofdrolspelers",
    titel: "Wie zijn de hoofdrolspelers in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['rol-hoofdrolspelers']
  },
  {
    template: "harmonica",
    naam: "rol-hoofdrolspelers",
    titel: "Wat doen de hoofdrolspelers in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['andere-spelers']
  },
  {
    template: "visualisatie",
    naam: "zorginkopers",
    titel: "Wie vervult voor welke wet de rol van zorginkoper?",
    kleur: COLOR_MAGENTA,
    links: ['markten']
  },
  {
    template: "visualisatie",
    naam: "markten",
    titel: "Welke markten zijn er in de curatieve zorg?",
    kleur: COLOR_MAGENTA,
    links: ['zorginkopers']
  },
  {
    template: "visualisatie",
    naam: "andere-spelers",
    titel: "Wie zijn andere belangrijke spelers in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['rol-andere-spelers']
  },
  {
    template: "harmonica",
    naam: "rol-andere-spelers",
    titel: "Wat doen de andere belangrijke spelers in de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['toezicht']
  },
  {
    template: "harmonica",
    naam: "toezicht",
    titel: "Wie houden toezicht op de zorg?",
    kleur: COLOR_MAGENTA,
    links: ['andere-spelers']
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  pagina.categorie = 'basis'

  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
