import React from 'react';
import { formatLocale } from 'd3';

import BarChart from '../../../../../components/BarChart/BarChart';

const Dementie = () => {
	const dataVerpleging = [
		{ key: "2015", value: 76000 },
		{ key: "2040", value: 165000 },
	];

	const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatValueTooltip = val => localeFormatter.format(',.0f')(val/1000) + ' duizend';
	const formatValueY = val => localeFormatter.format(',.0f')(val);

	const createTable = (data, className) => (
		<table className="tabel">
			<tbody>
				{data.map(row => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
			</tbody>
		</table>);

	return (
		<div className="vis-dementie">

			<h4>+117%</h4>

		<BarChart
			data={dataVerpleging}
			margin={{ top: 10, right: 0, bottom: 10, left: 200 }}
			barHeight={30}
			domain={[0,175000]}
			color={ {fill: '#333', stroke: '#fff'} }
			formatValueTooltip={formatValueTooltip}
			formatValueY={formatValueY}
			padding={0.2}
			ticks={0}
			marginBeforeValue={-65}
			animation={true}
		/>

      {createTable(dataVerpleging)}

		<div className="vis-credit">
			<p>Aantal mensen met dementie met indicatie voor verpleeghuiszorg.</p>
			<p>Bron: CBS Statline, 2023</p>
		</div>

	</div>
	);
}

export default Dementie;
