import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";

const GeldstromenWPG = () => (
    <div className="vis-geldstromen-wpg svg-container">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMin meet"
            viewBox="0 0 660 800">

            <g className="geldstromen">
                <path data-tooltip-content="react-tooltip" id="tt1" d="M256.38,86.88H235.06c-14.7,0-31.51,11.15-38.3,25.41L65.61,388.05c-6,12.66-10.73,33.59-10.73,47.57V470.5h0l7.74,7.75,7.76-7.75h0V435.62c0-11.62,4.22-30.39,9.23-40.91L210.77,119c4.19-8.83,15.5-16.57,24.29-16.57h21.32l-7.76-7.76Z"/>
                <polygon data-tooltip-content="react-tooltip" id="tt2" points="337.75 265.13 337.75 265.13 337.75 265.13 337.75 125.63 330 133.38 322.25 125.63 337.75 125.63 337.75 125.63 322.25 125.63 322.25 265.13 322.25 265.13 330 272.88 337.75 265.13"/>
                <path data-tooltip-content="react-tooltip" id="tt3" d="M605.12,470.5h0V435.62c0-14-4.71-34.87-10.73-47.57L463.24,112.29c-6.79-14.2-23.6-25.41-38.3-25.41H403.62v15.5h0V86.88l7.76,7.74-7.76,7.76h21.32c8.74,0,20.1,7.74,24.29,16.57L580.39,394.71c5,10.52,9.23,29.29,9.23,40.91V470.5h0l7.76,7.75Z"/>
                <polygon data-tooltip-content="react-tooltip" id="tt4" points="512.13 509.25 504.38 501.5 504.38 501.5 504.38 501.5 147.88 501.5 147.88 501.5 155.63 509.25 147.88 517 147.88 517 504.38 517 504.38 517 504.38 517 512.13 509.25"/>
            </g>

            <Link to={{ pathname: '/wie-betaalt-wpg', hash: '#Rijk' }} >
                <g className="kader link">
                    <rect x="261.54" y="68.79" width="136.92" height="51.67" rx="10.33" ry="10.33" fill="#fff"/>
                    <text className="tekst centreer" transform="matrix(1 0 0 1 330 101)">Rijk</text>
                </g>
            </Link>

            <Link to={{ pathname: '/wie-betaalt-wpg', hash: '#RIVM' }} >
                <g className="kader link">
                    <rect x="261.54" y="278.04" width="136.92" height="51.67" rx="10.33" ry="10.33" fill="#fff"/>
                    <text className="tekst centreer" transform="matrix(1 0 0 1 330 311	)">RIVM</text>
                </g>
            </Link>

            <Link to={{ pathname: '/wie-betaalt-wpg', hash: '#Gemeenten' }} >
                <g className="kader link">
                    <rect x="5.79" y="483.42" width="136.92" height="51.67" rx="10.33" ry="10.33" fill="#fff"/>
                    <text className="tekst centreer" transform="matrix(1 0 0 1 73 516)">Gemeenten</text>
                </g>
            </Link>

            <g className="kader">
                <rect x="517.29" y="483.42" width="136.92" height="51.67" rx="10.33" ry="10.33" fill="#fff"/>
                <text className="tekst centreer" transform="matrix(1 0 0 1 585 507)">
                    <tspan x="0" y="0">GGD'en en andere</tspan>
                    <tspan x="0" y="18">zorgaanbieders</tspan>
                </text>
            </g>

            <g className="tekst links regular">
                <text transform="matrix(1 0 0 1 64.0001 621.0001)">
                    <tspan x="0" y="0">Stortingen in Gemeentefonds</tspan>
                    <tspan x="0" y="18">Rijksbijdrage</tspan>
                    <tspan x="319.5" y="0">Rijkssubsidies</tspan>
                    <tspan x="319.5" y="18">Betaling voor geleverde zorg</tspan>
                </text>
            </g>

            <g className="tekstlabels wit">
                <path d="M321.6,201a8.44,8.44,0,0,1,.3-2.23,9,9,0,0,1,.85-2,7.73,7.73,0,0,1,1.3-1.71,8.56,8.56,0,0,1,1.7-1.29,8.14,8.14,0,0,1,2-.84,8.36,8.36,0,0,1,4.44,0,8.21,8.21,0,0,1,5,3.86,8.07,8.07,0,0,1,.84,2,8.4,8.4,0,0,1,0,4.45,8.14,8.14,0,0,1-.84,2,8.41,8.41,0,0,1-3,3,8.57,8.57,0,0,1-2,.84,8.46,8.46,0,0,1-2.24.3,8.31,8.31,0,0,1-2.22-.3,8.62,8.62,0,0,1-3.68-2.12,8,8,0,0,1-1.29-1.71,8.7,8.7,0,0,1-.86-2A8,8,0,0,1,321.6,201Zm8.31-4.14a5.35,5.35,0,0,0-2.36.55v1.71a4.76,4.76,0,0,1,2-.58c.74,0,1.1.27,1.1.82a1.73,1.73,0,0,1-.2.77,6.25,6.25,0,0,1-.62.9c-.32.4-.66.78-1,1.14l-1.42,1.42v1.29H333v-1.65h-3.11L331.2,202a9.47,9.47,0,0,0,.88-1,4.1,4.1,0,0,0,.53-1,2.87,2.87,0,0,0,.18-1,2.06,2.06,0,0,0-.81-1.68,2.52,2.52,0,0,0-.91-.42A3.77,3.77,0,0,0,329.91,196.85Z"/>
                <path d="M154.9,201a8.06,8.06,0,0,1,.31-2.23,9,9,0,0,1,.84-2,8,8,0,0,1,1.29-1.71,7.68,7.68,0,0,1,1.71-1.29,8.16,8.16,0,0,1,2-.84,8.44,8.44,0,0,1,4.46,0,8.21,8.21,0,0,1,5,3.86,8.46,8.46,0,0,1,.84,2,8.68,8.68,0,0,1,0,4.45,8.14,8.14,0,0,1-.84,2,8.48,8.48,0,0,1-3,3,8.64,8.64,0,0,1-2,.84,8.48,8.48,0,0,1-4.47,0,9,9,0,0,1-2-.85,8.24,8.24,0,0,1-1.7-1.3,8.56,8.56,0,0,1-1.29-1.7,8.14,8.14,0,0,1-.84-2A7.79,7.79,0,0,1,154.9,201Zm5.79,2.25v1.65h5.54v-1.65h-1.67V197H162.9l-2.34.83v1.69l2-.59v4.35Z"/>
                <path d="M487.94,201a8.44,8.44,0,0,1,.3-2.23,9,9,0,0,1,.84-2,8.34,8.34,0,0,1,1.29-1.71,7.68,7.68,0,0,1,1.71-1.29,8.16,8.16,0,0,1,2-.84,8.46,8.46,0,0,1,2.24-.3,8.37,8.37,0,0,1,2.22.3,8.21,8.21,0,0,1,5,3.86,8.46,8.46,0,0,1,.84,2,8.4,8.4,0,0,1,0,4.45,8.14,8.14,0,0,1-.84,2,8.41,8.41,0,0,1-3,3,8.64,8.64,0,0,1-2,.84,8.48,8.48,0,0,1-4.47,0,9,9,0,0,1-2-.85,8.24,8.24,0,0,1-1.7-1.3,8.56,8.56,0,0,1-1.29-1.7,8.27,8.27,0,0,1-1.12-4.28Zm11.28-2a1.86,1.86,0,0,0-.76-1.55,3.61,3.61,0,0,0-2.21-.58,6.77,6.77,0,0,0-2.05.33v1.67a4.47,4.47,0,0,1,.86-.26,4.6,4.6,0,0,1,.91-.09,1.82,1.82,0,0,1,.86.18.64.64,0,0,1,.34.57.69.69,0,0,1-.12.39,1,1,0,0,1-.28.26,2.16,2.16,0,0,1-.43.14,4.51,4.51,0,0,1-.52,0h-.76v1.56h1.51a1.36,1.36,0,0,1,.4.14.7.7,0,0,1,.28.26.93.93,0,0,1,.09.4.77.77,0,0,1-.37.67,1.89,1.89,0,0,1-1.07.25,5.87,5.87,0,0,1-1-.1,5.74,5.74,0,0,1-.8-.26v1.68A5.52,5.52,0,0,0,496,205a4,4,0,0,0,2.58-.67,2.18,2.18,0,0,0,.86-1.83,1.73,1.73,0,0,0-.36-1.14,1.69,1.69,0,0,0-.91-.59h0A1.81,1.81,0,0,0,499,200a2.24,2.24,0,0,0,.2-1Z"/>
                <path d="M321.6,509.25a8.31,8.31,0,0,1,.3-2.22,9.2,9.2,0,0,1,.85-2,8.24,8.24,0,0,1,1.3-1.7,8.56,8.56,0,0,1,1.7-1.29,8.14,8.14,0,0,1,2-.84,8.36,8.36,0,0,1,4.44,0,8.62,8.62,0,0,1,3.72,2.15,9.36,9.36,0,0,1,1.29,1.71,8.16,8.16,0,0,1,.84,2,8.48,8.48,0,0,1,0,4.47,8.16,8.16,0,0,1-.84,2,8.62,8.62,0,0,1-1.29,1.71,8.34,8.34,0,0,1-1.71,1.29,9,9,0,0,1-2,.85,8.44,8.44,0,0,1-2.23.3,7.94,7.94,0,0,1-2.22-.31,8.14,8.14,0,0,1-2-.84,8.56,8.56,0,0,1-1.7-1.29,7.73,7.73,0,0,1-1.3-1.71,9,9,0,0,1-.85-2A8.66,8.66,0,0,1,321.6,509.25Zm9.18-3.87h-2l-2,5v1.29h3.32v1.53H332v-1.53H333v-1.6H332V508.9h-1.91v1.19h-1.36a12.86,12.86,0,0,0,.77-1.64Z"/>
            </g>

            <g className="tekstlabels zwart">
                <path d="M43.7,615.57c0-0.64,0.08-1.25,0.25-1.84c0.17-0.59,0.4-1.14,0.7-1.66c0.3-0.52,0.66-0.98,1.08-1.41 s0.89-0.78,1.41-1.09s1.07-0.54,1.66-0.7c0.59-0.16,1.2-0.25,1.84-0.25c0.64,0,1.25,0.08,1.84,0.25c0.59,0.17,1.14,0.4,1.66,0.7 s0.98,0.66,1.41,1.08s0.78,0.89,1.09,1.41c0.3,0.52,0.54,1.07,0.7,1.66s0.25,1.2,0.25,1.84c0,0.64-0.08,1.25-0.25,1.84 s-0.4,1.14-0.7,1.66c-0.3,0.52-0.66,0.98-1.09,1.41s-0.89,0.78-1.41,1.09c-0.52,0.3-1.07,0.54-1.66,0.7 c-0.59,0.16-1.2,0.25-1.84,0.25c-0.64,0-1.25-0.08-1.84-0.25c-0.59-0.17-1.14-0.4-1.66-0.7c-0.52-0.3-0.98-0.66-1.41-1.08 s-0.78-0.89-1.08-1.41c-0.3-0.52-0.53-1.07-0.7-1.66C43.79,616.82,43.7,616.21,43.7,615.57z M48.48,617.43v1.36h4.58v-1.36h-1.38 v-5.19H50.3l-1.92,0.68v1.4l1.65-0.49v3.6H48.48z"/>
                <path d="M43.7,633.57c0-0.64,0.08-1.25,0.25-1.84c0.17-0.59,0.4-1.14,0.7-1.66c0.3-0.52,0.66-0.98,1.08-1.41 s0.89-0.78,1.41-1.09s1.07-0.54,1.66-0.7c0.59-0.16,1.2-0.25,1.84-0.25c0.64,0,1.25,0.08,1.84,0.25c0.59,0.17,1.14,0.4,1.66,0.7 s0.98,0.66,1.41,1.08s0.78,0.89,1.09,1.41c0.3,0.52,0.54,1.07,0.7,1.66s0.25,1.2,0.25,1.84c0,0.64-0.08,1.25-0.25,1.84 s-0.4,1.14-0.7,1.66c-0.3,0.52-0.66,0.98-1.09,1.41s-0.89,0.78-1.41,1.09c-0.52,0.3-1.07,0.54-1.66,0.7 c-0.59,0.16-1.2,0.25-1.84,0.25c-0.64,0-1.25-0.08-1.84-0.25c-0.59-0.17-1.14-0.4-1.66-0.7c-0.52-0.3-0.98-0.66-1.41-1.08 s-0.78-0.89-1.08-1.41c-0.3-0.52-0.53-1.07-0.7-1.66C43.79,634.82,43.7,634.21,43.7,633.57z M50.57,630.15 c-0.7,0-1.35,0.16-1.95,0.46v1.41c0.66-0.32,1.22-0.48,1.67-0.48c0.6,0,0.91,0.23,0.91,0.69c0,0.19-0.06,0.4-0.17,0.62 c-0.11,0.22-0.28,0.48-0.51,0.75c-0.22,0.28-0.5,0.59-0.84,0.93s-0.73,0.73-1.17,1.17v1.09h4.63v-1.36h-1.57 c-0.32,0-0.65,0.02-1,0.05l-0.01-0.02c0.39-0.36,0.73-0.69,1.03-0.99c0.29-0.29,0.54-0.57,0.73-0.84 c0.19-0.27,0.34-0.53,0.43-0.79c0.1-0.26,0.14-0.54,0.14-0.83c0-0.31-0.06-0.58-0.18-0.81c-0.12-0.23-0.28-0.43-0.49-0.58 c-0.21-0.16-0.45-0.27-0.74-0.35C51.2,630.19,50.9,630.15,50.57,630.15z"/>
                <path transform="translate(319.5 -36)" d="M43.7,651.57c0-0.64,0.08-1.25,0.25-1.84c0.17-0.59,0.4-1.14,0.7-1.66c0.3-0.52,0.66-0.98,1.08-1.41 s0.89-0.78,1.41-1.09s1.07-0.54,1.66-0.7c0.59-0.16,1.2-0.25,1.84-0.25c0.64,0,1.25,0.08,1.84,0.25c0.59,0.17,1.14,0.4,1.66,0.7 s0.98,0.66,1.41,1.08s0.78,0.89,1.09,1.41c0.3,0.52,0.54,1.07,0.7,1.66s0.25,1.2,0.25,1.84c0,0.64-0.08,1.25-0.25,1.84 s-0.4,1.14-0.7,1.66c-0.3,0.52-0.66,0.98-1.09,1.41s-0.89,0.78-1.41,1.09c-0.52,0.3-1.07,0.54-1.66,0.7 c-0.59,0.16-1.2,0.25-1.84,0.25c-0.64,0-1.25-0.08-1.84-0.25c-0.59-0.17-1.14-0.4-1.66-0.7c-0.52-0.3-0.98-0.66-1.41-1.08 s-0.78-0.89-1.08-1.41c-0.3-0.52-0.53-1.07-0.7-1.66C43.79,652.82,43.7,652.21,43.7,651.57z M53.02,649.91 c0-0.54-0.21-0.96-0.64-1.28c-0.42-0.32-1.03-0.48-1.82-0.48c-0.55,0-1.11,0.09-1.69,0.27v1.38c0.22-0.09,0.46-0.16,0.71-0.21 c0.25-0.05,0.5-0.07,0.75-0.07c0.29,0,0.53,0.05,0.71,0.15c0.18,0.1,0.27,0.25,0.27,0.47c0,0.12-0.03,0.23-0.09,0.32 c-0.06,0.09-0.14,0.16-0.24,0.21s-0.22,0.09-0.35,0.12s-0.27,0.04-0.42,0.04h-0.63v1.3h0.84c0.15,0,0.28,0.01,0.41,0.03 s0.23,0.06,0.33,0.11s0.17,0.12,0.22,0.21c0.05,0.09,0.08,0.2,0.08,0.33c0,0.23-0.1,0.42-0.29,0.55s-0.49,0.2-0.89,0.2 c-0.3,0-0.58-0.03-0.84-0.09c-0.26-0.06-0.49-0.13-0.67-0.21v1.39c0.48,0.18,1.01,0.27,1.59,0.27c0.92,0,1.62-0.18,2.09-0.55 c0.47-0.37,0.71-0.87,0.71-1.51c0-0.39-0.1-0.71-0.29-0.95s-0.45-0.4-0.75-0.49v-0.03c0.31-0.15,0.54-0.35,0.69-0.61 C52.95,650.53,53.02,650.23,53.02,649.91z"/>
                <path transform="translate(319.5 -36)" d="M43.7,669.57c0-0.64,0.08-1.25,0.25-1.84c0.17-0.59,0.4-1.14,0.7-1.66c0.3-0.52,0.66-0.98,1.08-1.41 s0.89-0.78,1.41-1.09s1.07-0.54,1.66-0.7c0.59-0.16,1.2-0.25,1.84-0.25c0.64,0,1.25,0.08,1.84,0.25c0.59,0.17,1.14,0.4,1.66,0.7 s0.98,0.66,1.41,1.08s0.78,0.89,1.09,1.41c0.3,0.52,0.54,1.07,0.7,1.66s0.25,1.2,0.25,1.84c0,0.64-0.08,1.25-0.25,1.84 s-0.4,1.14-0.7,1.66c-0.3,0.52-0.66,0.98-1.09,1.41s-0.89,0.78-1.41,1.09c-0.52,0.3-1.07,0.54-1.66,0.7 c-0.59,0.16-1.2,0.25-1.84,0.25c-0.64,0-1.25-0.08-1.84-0.25c-0.59-0.17-1.14-0.4-1.66-0.7c-0.52-0.3-0.98-0.66-1.41-1.08 s-0.78-0.89-1.08-1.41c-0.3-0.52-0.53-1.07-0.7-1.66C43.79,670.82,43.7,670.21,43.7,669.57z M51.28,666.34h-1.66l-1.62,4.15v1.04 h2.74v1.26h1.58v-1.26h0.78v-1.33h-0.78v-0.98h-1.58v0.98h-0.24c-0.34,0-0.63,0.01-0.88,0.04l-0.01-0.02 c0.16-0.25,0.37-0.71,0.63-1.35L51.28,666.34z"/>
            </g>
        </svg>

        <ReactTooltip anchorId="tt1" place="right" float>
            <span>1. Stortingen in Gemeentefonds</span>
        </ReactTooltip>

        <ReactTooltip anchorId="tt2" place="right" float>
            <span>2. Rijksbijdrage</span>
        </ReactTooltip>

        <ReactTooltip anchorId="tt3" place="right" float>
            <span>3. Rijkssubsidies</span>
        </ReactTooltip>

        <ReactTooltip anchorId="tt4" place="right" float>
            <span>4. Betaling voor geleverde zorg</span>
        </ReactTooltip>
    </div>
);

export default GeldstromenWPG;
