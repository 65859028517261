import React from 'react';

const Appel = () => (
	<svg viewBox="0 0 100 100" className="af-icon">
        <path fill="#FFF" fillRule="nonzero" d="M56.25 81.25h-12.5v-25h-25v-12.5h25v-25h12.5v25h25v12.5h-25v25Zm-10-2.5h7.5v-25h25v-7.5h-25v-25h-7.5v25h-25v7.5h25v25Z"/>
	</svg>
);

export default Appel;


