import React from 'react';
import { formatLocale } from 'd3';

import Donut from '../../../components/Donut/Donut';

const AantalJw = () => {
	const data = [
		{
	        "label": "Jeugdhulp zonder verblijf",
	        "value": 391305,
	    },
		{
			"label": "Jeugdreclassering",
			"value": 8515,
		},
		{
	        "label": "Jeugdhulp met verblijf",
	        "value": 42100,
	    },
		{
	        "label": "Jeugdbescherming",
	        "value": 41050,
	    },
	];

  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
    "grouping": [3],
    "comma": true,
  });

	const formatLabel = item => {
		const shortLabel = item.label;
		return shortLabel + ' ' + localeFormatter.format(',.0f')(item.value) + '';
	}

	const formatTotal = () => (
		<text textAnchor="middle" fontFamily="din-regular">
		  <tspan x={0} dy={-20}>Totaal</tspan>
		  <tspan x={0} dy={25}>429.215</tspan>
		  <tspan x={0} dy={23}>jeugdigen</tspan>
    	</text>
	);

	const formatTooltip = item => item.label + ' ' + localeFormatter.format(',.0f')(item.value);

	return (
		<div className="vis-aantal-jw">
			<Donut
				data={data}
				height={300}
				fontFamily="din-regular"
				fontSize="13px"
				formatLabel={formatLabel}
				formatTotal={formatTotal}
				formatTooltip={formatTooltip}
				unit="miljoen"
				animation={true}
			/>

			<div className="vis-credit">
				<p>Aantal kinderen en jongeren dat gebruikmaakt van een of meer vormen van jeugdhulp, jeugdbescherming of jeugdreclassering in 2020.</p>
				<p>De optelling van het aan jeugdigen met een vorm van jeudgzorg is groter dan het totaal zelf. Een jeugdige kan meerdere vormen van jeugdzorg ontvangen.</p>
				<p>Bron: CBS Statline, 2021</p>
			</div>
		</div>
	);
}

export default AantalJw;


