import React from 'react';

import GezondeLevensverwachting from './visualizations/gezonde-levensverwachting.js';
import Hoofdrolspelers from './visualizations/hoofdrolspelers.js';
import GGDregios from './visualizations/ggd-regios.js';
import EurosWPG from './visualizations/euros-wpg.js';
import GeldstromenWPG from '../basis/visualizations/geldstromen-wpg.js';
import GeldstromenGGD from './visualizations/geldstromen-ggd.js';
import GeldstromenRijksvaccinatieprogramma from './visualizations/geldstromen-rijksvaccinatieprogramma.js';
import GeldstromenBevolkingsonderzoek from './visualizations/geldstromen-bevolkingsonderzoek.js';

const vissen = {
	gezonde_levensverwachting: <GezondeLevensverwachting />,
	hoofdrolspelers: <Hoofdrolspelers />,
	ggd_regios: <GGDregios />,
	euros_wpg: <EurosWPG />,
	geldstromen_wpg: <GeldstromenWPG />,
	geldstromen_ggd: <GeldstromenGGD />,
	geldstromen_rijksvaccinatieprogramma: <GeldstromenRijksvaccinatieprogramma />,
	geldstromen_bevolkingsonderzoek: <GeldstromenBevolkingsonderzoek />,
};

export default vissen;
