import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GeldstromenGGD = () => (

<div className="vis-geldstromen-ggd svg-container">
<svg
	xmlns="http://www.w3.org/2000/svg"
	preserveAspectRatio="xMidYMin meet"
	viewBox="90 30 520 575">
<g className="geldstromen">
	<path data-tooltip-content="react-tooltip" id="tt1" d="M291,129H246c-12.25,0-25.85,8.72-31,19.86l-102,222.28c-4.51,9.84-8,26-8,36.86v27h0l6,6,6-6h0V408c0-9.05,3.19-23.64,7-31.86L226,153.86C229.09,147,238.46,141,246,141h45l-6-6,6-6Z"/>
	<path data-tooltip-content="react-tooltip" id="tt3" d="M579,435V408c0-10.76-3.21-27-7.31-36.93L480.4,149.36c-4.7-11.42-18-20.36-30.4-20.36H405v12h0V129l6,6-6,6h45c7.45,0,16.47,6,19.31,12.93L560.6,375.64c3.47,8.43,6.4,23.25,6.4,32.36v27h0l6,6,6-6Z"/>
	<path data-tooltip-content="react-tooltip" id="tt2" d="M291,147H270c-13.23,0-24,8.75-24,19.5V207h0l6,6,6-6h0V166.5c0-3.55,4.93-7.5,12-7.5h21l-6-6,6-6Z"/>
	<path data-tooltip-content="react-tooltip" id="tt4" d="M450,207V166.5c0-10.75-10.77-19.5-24-19.5H405v12h0V147l6,6-6,6h21c7.07,0,12,3.95,12,7.5V207h0l6,6,6-6Z"/>
	<polygon data-tooltip-content="react-tooltip" id="tt6" points="489 474 483 468 483 468 483 468 207 468 207 468 213 474 207 480 207 480 483 480 483 480 483 480 489 474"/>
	<path data-tooltip-content="react-tooltip" id="tt7" d="M489,456l-6-6H270a12,12,0,0,1-12-12V345l-6,6-6-6h0v93a24,24,0,0,0,24,24H483Z"/>
	<path data-tooltip-content="react-tooltip" id="tt5" d="M561,435V408c0-10.76-3.21-27-7.31-36.93L504.4,251.36C499.7,239.94,488.32,231,478.5,231H465v12h0V231l6,6-6,6h13.5c4.48,0,11.76,5.53,14.81,12.93L542.6,375.64c3.47,8.43,6.4,23.25,6.4,32.36v27h0l6,6,6-6Z"/>
	<path data-tooltip-content="react-tooltip" id="tt9" d="M543,435V396a24,24,0,0,0-24-24H462c-6.62,0-12-4.71-12-10.5V345l-6,6-6-6h0v16.5c0,12.41,10.77,22.5,24,22.5h57a12,12,0,0,1,12,12v39h0l6,6,6-6Z"/>
	<path data-tooltip-content="react-tooltip" id="tt8" d="M525,435V396a6,6,0,0,0-6-6H366a12,12,0,0,1-12-12V345l-6,6-6-6h0v33a24,24,0,0,0,24,24H513v33h0l6,6,6-6Z"/>
</g>

<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#Rijk' }} >
	<g className="kader link">
		<rect x="294" y="123" width="108" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(350 150)">Rijk</text>
	</g>
</Link>
<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#RIVM' }} >
	<g className="kader link">
		<rect x="234" y="216" width="72" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(270 243)">RIVM</text>
	</g>
</Link>
<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#ZonMw' }} >
	<g className="kader link">
		<rect x="390" y="216" width="72" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(427 243)">ZonMw</text>
	</g>
</Link>
<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#Gemeenten' }} >
	<g className="kader link">
		<rect x="96" y="444" width="108" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(150 470)">Gemeenten</text>
	</g>
</Link>

	<g className="kader">
		<rect x="492" y="444" width="108" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(545 470)">GGD</text>
	</g>

<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#Bedrijveneninstellingen' }} >
	<g className="kader link">
		<rect x="205" y="300" width="94" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(250 318)">Bedrijven &<tspan x="1.63" y="16">instellingen</tspan></text>
	</g>
</Link>
<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#Burgers' }} >
	<g className="kader link">
		<rect x="309" y="300" width="78" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(350 324)">Burgers</text>
	</g>
</Link>
<Link to={{ pathname: '/publieke-gezondheidszorg/wie-betaalt-ggd', hash: '#FondsenenEuropeseUnie' }} >
	<g className="kader link">
		<rect x="405" y="300" width="78" height="42" rx="9" fill="#fff"/>
		<text className="tekst centreer" transform="translate(444 318)">Fondsen<tspan x="-3" y="16"> & EU</tspan></text>
	</g>
</Link>


<g className="tekst links regular">
	<text transform="translate(114 515.56)">
		Stortingen in Gemeentefonds
		<tspan x="0" y="15">Rijksbijdrage</tspan>
		<tspan x="0" y="30">Rijkssubsidie</tspan>
		<tspan x="0" y="45">Rijksbijdrage</tspan>
		<tspan x="0" y="60">Projectsubsidie</tspan>
		<tspan x="249" y="0">Inwonersbijdrage en betaling voor </tspan>
		<tspan x="249" y="15">aanvullende taken</tspan>
		<tspan x="249" y="30">Betaling voor geleverde zorg of advies</tspan>
		<tspan x="249" y="45">Betaling voor toegediende reizigersvaccinaties</tspan>
		<tspan x="249" y="60">Subsidie voor preventieprogramma’s</tspan>
	</text>
</g>

<g className="tekstlabels wit">
		<path d="M245.62,175.35a6.23,6.23,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.17,6.17,0,0,1,1.32,1,6.29,6.29,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.18,6.18,0,0,1,.23,1.73,6.11,6.11,0,0,1-.23,1.72,6.34,6.34,0,0,1-.65,1.56,6.85,6.85,0,0,1-1,1.32,6.94,6.94,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,6.53,6.53,0,0,1-4.55-4.55A6.11,6.11,0,0,1,245.62,175.35Zm6.44-3.21a4.22,4.22,0,0,0-1.84.43v1.32a3.8,3.8,0,0,1,1.57-.45c.57,0,.85.21.85.64a1.28,1.28,0,0,1-.16.59,4.41,4.41,0,0,1-.48.7,11,11,0,0,1-.78.88l-1.1,1.1v1h4.34v-1.28H253c-.3,0-.61,0-.93,0v0c.36-.34.69-.65,1-.92a7.62,7.62,0,0,0,.69-.79,3,3,0,0,0,.4-.74,2.21,2.21,0,0,0,.14-.78,1.58,1.58,0,0,0-.63-1.3,2,2,0,0,0-.69-.33A3.43,3.43,0,0,0,252.06,172.14Z" fill="#fff"/>
		<path d="M202.9,175.35a6.62,6.62,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.68,6.68,0,0,1,1.56-.65,6.58,6.58,0,0,1,3.45,0,6.71,6.71,0,0,1,1.56.66,6.11,6.11,0,0,1,1.31,1,6,6,0,0,1,1,1.32,6.89,6.89,0,0,1,.66,1.55,6.57,6.57,0,0,1,.23,1.73,6.49,6.49,0,0,1-.23,1.72,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.87,6.87,0,0,1-1.31,1,6.68,6.68,0,0,1-1.56.65,6.13,6.13,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,7.17,7.17,0,0,1-1.56-.66,6.53,6.53,0,0,1-3-3.89A6.49,6.49,0,0,1,202.9,175.35Zm4.48,1.74v1.28h4.29v-1.28h-1.29v-4.86h-1.3l-1.8.64v1.31l1.55-.46v3.37Z" fill="#fff"/>
		<path d="M478.22,175.35a6.23,6.23,0,0,1,.24-1.73,6.44,6.44,0,0,1,.65-1.55,6,6,0,0,1,1-1.32,6.15,6.15,0,0,1,1.31-1,6.68,6.68,0,0,1,1.56-.65,6.23,6.23,0,0,1,1.73-.23,6.15,6.15,0,0,1,1.72.23,6.53,6.53,0,0,1,1.56.66,6.43,6.43,0,0,1,1.32,1,6.24,6.24,0,0,1,1,1.32,6.08,6.08,0,0,1,.66,1.55,6.57,6.57,0,0,1,.23,1.73,6.49,6.49,0,0,1-.23,1.72,6,6,0,0,1-.66,1.56,6.8,6.8,0,0,1-1,1.32,7.27,7.27,0,0,1-1.32,1,6.51,6.51,0,0,1-1.56.65,6.11,6.11,0,0,1-1.72.23,6.62,6.62,0,0,1-1.73-.23,7.17,7.17,0,0,1-1.56-.66,6.5,6.5,0,0,1-2.33-2.33,6.34,6.34,0,0,1-.65-1.56A6.11,6.11,0,0,1,478.22,175.35Zm8.74-1.56a1.43,1.43,0,0,0-.6-1.2,2.77,2.77,0,0,0-1.71-.45,5.27,5.27,0,0,0-1.58.26v1.29a3.6,3.6,0,0,1,.66-.2,4.27,4.27,0,0,1,.71-.07,1.45,1.45,0,0,1,.67.14.47.47,0,0,1,.25.44.48.48,0,0,1-.08.3.76.76,0,0,1-.23.2,1.28,1.28,0,0,1-.33.11,2.34,2.34,0,0,1-.39,0h-.59v1.21h.79a2.34,2.34,0,0,1,.38,0,1,1,0,0,1,.31.11.48.48,0,0,1,.2.2.57.57,0,0,1,.07.31.61.61,0,0,1-.27.52,1.52,1.52,0,0,1-.83.19,4,4,0,0,1-.79-.08,3.48,3.48,0,0,1-.62-.2v1.3a4.12,4.12,0,0,0,1.49.26,3.12,3.12,0,0,0,2-.52,1.69,1.69,0,0,0,.66-1.42,1.29,1.29,0,0,0-1-1.34v0a1.38,1.38,0,0,0,.64-.57A1.65,1.65,0,0,0,487,173.79Z" fill="#fff"/>
		<path d="M501,275.18a6.16,6.16,0,0,1,.24-1.72,6.68,6.68,0,0,1,.65-1.56,6.5,6.5,0,0,1,2.33-2.33,6.31,6.31,0,0,1,1.56-.66,6.55,6.55,0,0,1,1.72-.23,6.18,6.18,0,0,1,1.73.24,6.68,6.68,0,0,1,1.56.65,6.5,6.5,0,0,1,2.33,2.33,6.43,6.43,0,0,1,.89,3.28,6.62,6.62,0,0,1-.23,1.73,6.71,6.71,0,0,1-.66,1.56,6.72,6.72,0,0,1-2.33,2.33,7,7,0,0,1-1.56.66,6.51,6.51,0,0,1-1.73.23,6.16,6.16,0,0,1-1.72-.24,6.68,6.68,0,0,1-1.56-.65,6.72,6.72,0,0,1-2.33-2.33,6.68,6.68,0,0,1-.65-1.56A6.23,6.23,0,0,1,501,275.18Zm6.34-1.75h2.23v-1.27h-3.49l-.49,3.43a5.69,5.69,0,0,1,1-.09,3.21,3.21,0,0,1,.67.05,1.09,1.09,0,0,1,.42.14.54.54,0,0,1,.24.23.62.62,0,0,1,.07.31.69.69,0,0,1-.09.36.78.78,0,0,1-.26.25,1,1,0,0,1-.36.15,2,2,0,0,1-.43.05,4.07,4.07,0,0,1-.71-.07,4.44,4.44,0,0,1-.68-.22v1.32a3.46,3.46,0,0,0,.64.19,4.51,4.51,0,0,0,.87.07,3.64,3.64,0,0,0,1.09-.15,2.55,2.55,0,0,0,.82-.44,1.9,1.9,0,0,0,.52-.68,1.86,1.86,0,0,0,.19-.85,2.08,2.08,0,0,0-.12-.71,1.36,1.36,0,0,0-.39-.58,1.78,1.78,0,0,0-.69-.39,3,3,0,0,0-1-.14h-.17Z" fill="#fff"/>
		<path d="M437.18,175.35a6.62,6.62,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.15,6.15,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.23,6.46,6.46,0,0,1,1.55.66,6.43,6.43,0,0,1,2.34,2.33,6.89,6.89,0,0,1,.66,1.55,6.57,6.57,0,0,1,.23,1.73,6.49,6.49,0,0,1-.23,1.72,6.78,6.78,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.64,6.64,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.23,6.55,6.55,0,0,1-1.72-.23,6.53,6.53,0,0,1-4.55-4.55A6.49,6.49,0,0,1,437.18,175.35Zm7.1-3h-1.55l-1.52,3.89v1h2.57v1.18h1.48v-1.18H446v-1.25h-.74V175h-1.48v.91h-.22c-.32,0-.6,0-.83,0v0a9.34,9.34,0,0,0,.6-1.27Z" fill="#fff"/>
		<path d="M340.75,474a6.16,6.16,0,0,1,.24-1.72,6.75,6.75,0,0,1,.65-1.56,6.85,6.85,0,0,1,1-1.32,6.64,6.64,0,0,1,1.32-1,6.44,6.44,0,0,1,1.55-.65,6.23,6.23,0,0,1,1.73-.23,6.55,6.55,0,0,1,1.72.23,6.65,6.65,0,0,1,2.88,1.67,7.27,7.27,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.62,6.62,0,0,1,0,3.46,6.44,6.44,0,0,1-.65,1.55,6.64,6.64,0,0,1-1,1.32,6.47,6.47,0,0,1-1.32,1,6.89,6.89,0,0,1-1.55.66,6.57,6.57,0,0,1-1.73.23,6.23,6.23,0,0,1-1.73-.24,6.86,6.86,0,0,1-1.55-.65,6,6,0,0,1-1.32-1,6.29,6.29,0,0,1-1-1.32,6.86,6.86,0,0,1-.65-1.55A6.23,6.23,0,0,1,340.75,474Zm6.71-.82a3.18,3.18,0,0,0-.66.07,2.11,2.11,0,0,0-.49.18l0,0a1.6,1.6,0,0,1,.5-1,1.55,1.55,0,0,1,1-.31,3.56,3.56,0,0,1,.71.07,6.26,6.26,0,0,1,.66.17v-1.25a2.77,2.77,0,0,0-.61-.19,3.7,3.7,0,0,0-.83-.09,2.91,2.91,0,0,0-1.34.28,2.4,2.4,0,0,0-.92.74,3,3,0,0,0-.53,1.1,5.31,5.31,0,0,0-.17,1.35,3.81,3.81,0,0,0,.19,1.27,2.46,2.46,0,0,0,.51.9,2.11,2.11,0,0,0,.78.54,2.56,2.56,0,0,0,1,.18,2.83,2.83,0,0,0,.95-.15,2.14,2.14,0,0,0,.72-.42,2,2,0,0,0,.46-.67,2.11,2.11,0,0,0,.16-.85,1.94,1.94,0,0,0-.15-.78,1.59,1.59,0,0,0-.42-.59,1.84,1.84,0,0,0-.64-.38A2.66,2.66,0,0,0,347.46,473.19Zm-.25,2.69a.78.78,0,0,1-.37-.09.86.86,0,0,1-.29-.25,1.07,1.07,0,0,1-.2-.4,1.7,1.7,0,0,1-.07-.53,1.67,1.67,0,0,1,.41-.16,1.61,1.61,0,0,1,.44-.06.89.89,0,0,1,.62.2.66.66,0,0,1,.21.51.82.82,0,0,1-.2.58A.77.77,0,0,1,347.21,475.88Z" fill="#fff"/>
		<path d="M245.5,394.73a6.15,6.15,0,0,1,.23-1.72,6.65,6.65,0,0,1,1.67-2.88,7.27,7.27,0,0,1,1.32-1,6.34,6.34,0,0,1,1.56-.65,6.11,6.11,0,0,1,1.72-.23,6.62,6.62,0,0,1,1.73.23,6.89,6.89,0,0,1,1.55.66,6.51,6.51,0,0,1,1.32,1,6.85,6.85,0,0,1,1,1.32,6.44,6.44,0,0,1,.65,1.55,6.62,6.62,0,0,1,0,3.46,6.44,6.44,0,0,1-.65,1.55,6.29,6.29,0,0,1-1,1.32,6.21,6.21,0,0,1-1.32,1,6.44,6.44,0,0,1-1.55.65,6.18,6.18,0,0,1-1.73.24,6.11,6.11,0,0,1-1.72-.24,6.75,6.75,0,0,1-1.56-.65,6.47,6.47,0,0,1-1.32-1,6.24,6.24,0,0,1-1-1.32,6.89,6.89,0,0,1-.66-1.55A6.23,6.23,0,0,1,245.5,394.73Zm7.05-1.75-2.27,4.77h1.61l2.38-5v-1h-4.35V393Z" fill="#fff"/>
		<path d="M370,396a6.16,6.16,0,0,1,.24-1.72,6.47,6.47,0,0,1,3-3.89,6.31,6.31,0,0,1,1.56-.66,6.58,6.58,0,0,1,3.45,0,7.17,7.17,0,0,1,1.56.66,6.5,6.5,0,0,1,2.33,2.33A6.43,6.43,0,0,1,383,396a6.62,6.62,0,0,1-.23,1.73,6.71,6.71,0,0,1-.66,1.56,6.72,6.72,0,0,1-2.33,2.33,7,7,0,0,1-1.56.66,6.49,6.49,0,0,1-1.72.23,6.18,6.18,0,0,1-1.73-.24,6.68,6.68,0,0,1-1.56-.65,6.72,6.72,0,0,1-2.33-2.33,6.68,6.68,0,0,1-.65-1.56A6.23,6.23,0,0,1,370,396Zm6.51-3.21a3.5,3.5,0,0,0-1,.13,2.18,2.18,0,0,0-.72.34,1.74,1.74,0,0,0-.45.53,1.6,1.6,0,0,0-.15.7,1.36,1.36,0,0,0,.25.83,1.65,1.65,0,0,0,.66.53v0a1.77,1.77,0,0,0-.41.21,1.66,1.66,0,0,0-.33.32,1.4,1.4,0,0,0-.22.42,1.48,1.48,0,0,0-.08.53,1.64,1.64,0,0,0,.16.74,1.6,1.6,0,0,0,.47.57,2.06,2.06,0,0,0,.76.36,4.44,4.44,0,0,0,2.05,0,2.06,2.06,0,0,0,.76-.36,1.6,1.6,0,0,0,.47-.57,1.64,1.64,0,0,0,.16-.74,1.74,1.74,0,0,0-.08-.53,1.4,1.4,0,0,0-.22-.42,1.66,1.66,0,0,0-.33-.32,1.77,1.77,0,0,0-.41-.21v0a1.65,1.65,0,0,0,.66-.53,1.36,1.36,0,0,0,.25-.83,1.6,1.6,0,0,0-.15-.7,1.74,1.74,0,0,0-.45-.53,2.09,2.09,0,0,0-.72-.34A3.48,3.48,0,0,0,376.49,392.79Zm0,5.11a1,1,0,0,1-.64-.18.57.57,0,0,1-.21-.45.75.75,0,0,1,.24-.57,1.67,1.67,0,0,1,.61-.31,1.71,1.71,0,0,1,.6.31.75.75,0,0,1,.24.57.57.57,0,0,1-.21.45A1,1,0,0,1,376.49,397.9Zm0-2.51a1,1,0,0,1-.56-.32.69.69,0,0,1-.17-.42.6.6,0,0,1,.06-.27.74.74,0,0,1,.16-.19l.23-.11a1.27,1.27,0,0,1,.28,0l.27,0,.23.11a.74.74,0,0,1,.16.19.6.6,0,0,1,.06.27.69.69,0,0,1-.17.42A1,1,0,0,1,376.49,395.39Z" fill="#fff"/>
		<path d="M481.24,378.1a6.62,6.62,0,0,1,.23-1.73,6.89,6.89,0,0,1,.66-1.55,6.24,6.24,0,0,1,1-1.32,6.47,6.47,0,0,1,1.32-1,6.78,6.78,0,0,1,1.56-.66,6.55,6.55,0,0,1,1.72-.23,6.23,6.23,0,0,1,1.73.24,6.44,6.44,0,0,1,1.55.65,6.56,6.56,0,0,1,2.34,2.34,6.89,6.89,0,0,1,.66,1.55,6.58,6.58,0,0,1,0,3.45,7,7,0,0,1-.66,1.56,6.47,6.47,0,0,1-1,1.32,6.24,6.24,0,0,1-1.32,1,6.08,6.08,0,0,1-1.55.66,6.58,6.58,0,0,1-3.45,0,6.42,6.42,0,0,1-3.89-3,7,7,0,0,1-.66-1.56A6.55,6.55,0,0,1,481.24,378.1Zm5.85,1.86a4.34,4.34,0,0,1-.64-.06,3.3,3.3,0,0,1-.74-.22V381a3.74,3.74,0,0,0,.65.19,4.79,4.79,0,0,0,.88.08,3.2,3.2,0,0,0,1.43-.28,2.37,2.37,0,0,0,.93-.74,2.82,2.82,0,0,0,.5-1.08,5.53,5.53,0,0,0,.15-1.31,3.22,3.22,0,0,0-.66-2.19,2.32,2.32,0,0,0-1.86-.76,2.52,2.52,0,0,0-.89.15,2.18,2.18,0,0,0-.69.43,2,2,0,0,0-.46.67,2.2,2.2,0,0,0-.17.86,1.92,1.92,0,0,0,.17.83,1.52,1.52,0,0,0,.44.58,1.75,1.75,0,0,0,.63.34,2.65,2.65,0,0,0,.74.11,2.79,2.79,0,0,0,.64-.07,1.7,1.7,0,0,0,.55-.19l0,0a1.52,1.52,0,0,1-.52,1A1.83,1.83,0,0,1,487.09,380Zm.76-2.3a.85.85,0,0,1-.6-.2.76.76,0,0,1-.2-.55.84.84,0,0,1,0-.27.7.7,0,0,1,.13-.24.66.66,0,0,1,.23-.17.81.81,0,0,1,.33-.06.79.79,0,0,1,.69.34,1.74,1.74,0,0,1,.28.89.59.59,0,0,1-.19.11,1.84,1.84,0,0,1-.25.09l-.25,0Z" fill="#fff"/>

</g>

<g className="tekstlabels zwart">
	<path d="M96.44,512.17A4,4,0,0,1,96.6,511a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,512.17Zm3,1.16v.85h2.86v-.85h-.86v-3.24h-.86l-1.2.42v.87l1-.3v2.25Z"/>
	<path d="M96.44,527.17A4,4,0,0,1,96.6,526a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,527.17Zm4.29-2.14a2.67,2.67,0,0,0-1.22.29v.87a2.49,2.49,0,0,1,1-.3c.37,0,.56.15.56.43a.77.77,0,0,1-.11.39,2.51,2.51,0,0,1-.31.47c-.14.18-.32.37-.53.59l-.73.73v.68h2.9v-.85h-1l-.63,0v0l.64-.61a6.65,6.65,0,0,0,.46-.53,2.19,2.19,0,0,0,.27-.49,1.5,1.5,0,0,0,.09-.52,1.07,1.07,0,0,0-.12-.51,1,1,0,0,0-.3-.36,1.4,1.4,0,0,0-.46-.22A2.15,2.15,0,0,0,100.73,525Z"/>
	<path d="M96.44,542.17A4,4,0,0,1,96.6,541a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,542.17Zm5.82-1a.94.94,0,0,0-.39-.8,1.89,1.89,0,0,0-1.14-.3,3.31,3.31,0,0,0-1.06.17v.86a2.23,2.23,0,0,1,.44-.13,2.61,2.61,0,0,1,.48,0,.93.93,0,0,1,.44.09.33.33,0,0,1,.17.3.34.34,0,0,1-.06.2.39.39,0,0,1-.15.13.63.63,0,0,1-.22.07,1.09,1.09,0,0,1-.26,0h-.39v.81h.52a1.06,1.06,0,0,1,.26,0,.43.43,0,0,1,.2.07.35.35,0,0,1,.14.13.41.41,0,0,1,.05.21.39.39,0,0,1-.18.34,1,1,0,0,1-.56.13,2.87,2.87,0,0,1-.52,0,2.35,2.35,0,0,1-.42-.13v.86a2.64,2.64,0,0,0,1,.18,2.09,2.09,0,0,0,1.31-.35,1.11,1.11,0,0,0,.44-.94.9.9,0,0,0-.18-.59.89.89,0,0,0-.47-.31v0a1,1,0,0,0,.43-.38A1.12,1.12,0,0,0,102.26,541.13Z"/>
	<path d="M96.44,557.17A4,4,0,0,1,96.6,556a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,557.17Zm4.74-2h-1l-1,2.59v.65h1.71v.79h1v-.79h.49v-.83h-.49V557h-1v.61h-.15a4.44,4.44,0,0,0-.55,0v0a7.31,7.31,0,0,0,.39-.84Z"/>
	<path d="M96.44,572.17A4,4,0,0,1,96.6,571a4.55,4.55,0,0,1,.43-1,4.37,4.37,0,0,1,1.56-1.56,4.58,4.58,0,0,1,1-.44,4.37,4.37,0,0,1,1.15-.15,4,4,0,0,1,1.15.16,4.48,4.48,0,0,1,1,.43,4.22,4.22,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,4.37,4.37,0,0,1-1.56,1.56,4.1,4.1,0,0,1-1,.44,4.48,4.48,0,0,1-2.3,0,4.58,4.58,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,4.22,4.22,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,96.44,572.17Zm4.22-1.17h1.49v-.85H99.82l-.32,2.28a5.14,5.14,0,0,1,.66,0,2.68,2.68,0,0,1,.44,0,.9.9,0,0,1,.29.09.36.36,0,0,1,.15.15.41.41,0,0,1,0,.21.43.43,0,0,1-.06.24.46.46,0,0,1-.17.17.64.64,0,0,1-.24.1,1.36,1.36,0,0,1-.29,0,2.42,2.42,0,0,1-.47,0,3,3,0,0,1-.45-.15v.88a2.79,2.79,0,0,0,.43.13,3.1,3.1,0,0,0,.57,0,2.33,2.33,0,0,0,.73-.11,1.69,1.69,0,0,0,.55-.29,1.52,1.52,0,0,0,.35-.45,1.39,1.39,0,0,0,.12-.57,1.51,1.51,0,0,0-.08-.48,1.08,1.08,0,0,0-.26-.38,1.21,1.21,0,0,0-.46-.26,2.15,2.15,0,0,0-.67-.1h-.12Z"/>
	<path d="M345.44,512.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,4,4,0,0,1,1.15.16,4.67,4.67,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.4,4.4,0,0,1-1.91,1.12,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,345.44,512.17Zm4.47-.55a2,2,0,0,0-.44.05,1.07,1.07,0,0,0-.32.12l0,0a1.1,1.1,0,0,1,.33-.68,1.05,1.05,0,0,1,.67-.21,2.8,2.8,0,0,1,.48.05,2.21,2.21,0,0,1,.43.12v-.84a1.61,1.61,0,0,0-.4-.12,2.53,2.53,0,0,0-.56-.06,2,2,0,0,0-.89.18,1.67,1.67,0,0,0-.62.49,2.11,2.11,0,0,0-.35.74,3.7,3.7,0,0,0-.11.9,2.66,2.66,0,0,0,.12.84,1.91,1.91,0,0,0,.35.61,1.43,1.43,0,0,0,.52.36,1.66,1.66,0,0,0,.64.12,2.08,2.08,0,0,0,.63-.1,1.43,1.43,0,0,0,.48-.29,1.25,1.25,0,0,0,.31-.44,1.48,1.48,0,0,0,.1-.57,1.24,1.24,0,0,0-.1-.52,1,1,0,0,0-.27-.39,1.31,1.31,0,0,0-.43-.25A1.63,1.63,0,0,0,349.91,511.62Zm-.17,1.79a.48.48,0,0,1-.24-.06.62.62,0,0,1-.2-.16.85.85,0,0,1-.13-.27,1.29,1.29,0,0,1-.05-.35,1.53,1.53,0,0,1,.27-.11,1,1,0,0,1,.3,0,.56.56,0,0,1,.41.13.42.42,0,0,1,.14.34.53.53,0,0,1-.13.39A.51.51,0,0,1,349.74,513.41Z"/>
	<path d="M345.44,542.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,4,4,0,0,1,1.15.16,4.67,4.67,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.4,4.4,0,0,1-1.91,1.12,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,345.44,542.17Zm4.7-1.17-1.51,3.18h1.07l1.59-3.35v-.68h-2.9V541Z"/>
	<path d="M345.44,557.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,4,4,0,0,1,1.15.16,4.67,4.67,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.4,4.4,0,0,1-1.91,1.12,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,345.44,557.17Zm4.34-2.14a2.14,2.14,0,0,0-.65.08,1.34,1.34,0,0,0-.48.23,1.1,1.1,0,0,0-.3.35,1.2,1.2,0,0,0-.1.47.92.92,0,0,0,.17.55,1.09,1.09,0,0,0,.44.35v0a1.71,1.71,0,0,0-.27.14.88.88,0,0,0-.22.21.8.8,0,0,0-.15.29,1,1,0,0,0-.06.35,1.18,1.18,0,0,0,.11.5,1.1,1.1,0,0,0,.31.37,1.34,1.34,0,0,0,.51.24,2.65,2.65,0,0,0,1.37,0,1.34,1.34,0,0,0,.51-.24,1.1,1.1,0,0,0,.31-.37,1.18,1.18,0,0,0,.11-.5,1,1,0,0,0-.06-.35.8.8,0,0,0-.15-.29.88.88,0,0,0-.22-.21,1.71,1.71,0,0,0-.27-.14v0a1.09,1.09,0,0,0,.44-.35.92.92,0,0,0,.17-.55,1.2,1.2,0,0,0-.1-.47,1.1,1.1,0,0,0-.3-.35,1.34,1.34,0,0,0-.48-.23A2.12,2.12,0,0,0,349.78,555Zm0,3.4a.67.67,0,0,1-.43-.12.39.39,0,0,1-.14-.3.48.48,0,0,1,.16-.38,1.32,1.32,0,0,1,.41-.21,1.27,1.27,0,0,1,.4.21.48.48,0,0,1,.16.38.39.39,0,0,1-.14.3A.66.66,0,0,1,349.78,558.43Zm0-1.67a.7.7,0,0,1-.38-.22.42.42,0,0,1-.07-.46.43.43,0,0,1,.11-.12.41.41,0,0,1,.15-.07.58.58,0,0,1,.19,0l.18,0a.41.41,0,0,1,.15.07.43.43,0,0,1,.11.12.42.42,0,0,1-.07.46A.71.71,0,0,1,349.78,556.76Z"/>
	<path d="M345.44,572.17a4,4,0,0,1,.16-1.15,4.55,4.55,0,0,1,.43-1,4.14,4.14,0,0,1,.68-.88,4.49,4.49,0,0,1,.88-.68,4.7,4.7,0,0,1,1-.44,4.45,4.45,0,0,1,1.16-.15,4,4,0,0,1,1.15.16,4.67,4.67,0,0,1,1,.43,4.49,4.49,0,0,1,.88.68,4.14,4.14,0,0,1,.68.88,4.26,4.26,0,0,1,.44,1,4.52,4.52,0,0,1,0,2.31,4.1,4.1,0,0,1-.44,1,3.91,3.91,0,0,1-.68.88,4.4,4.4,0,0,1-1.91,1.12,4.37,4.37,0,0,1-1.15.15,4.45,4.45,0,0,1-1.16-.15,4.7,4.7,0,0,1-1-.44,4.14,4.14,0,0,1-.88-.68,3.91,3.91,0,0,1-.68-.88,4.48,4.48,0,0,1-.43-1A4,4,0,0,1,345.44,572.17Zm3.9,1.24a2.35,2.35,0,0,1-.42,0,2.21,2.21,0,0,1-.5-.15v.87a1.7,1.7,0,0,0,.44.12,2.58,2.58,0,0,0,.58.06,2.13,2.13,0,0,0,1-.19,1.67,1.67,0,0,0,.62-.49,2.16,2.16,0,0,0,.33-.72,3.57,3.57,0,0,0,.1-.87,2.21,2.21,0,0,0-.44-1.47,1.59,1.59,0,0,0-1.24-.5,1.64,1.64,0,0,0-.6.1,1.38,1.38,0,0,0-.46.29,1.23,1.23,0,0,0-.3.44,1.38,1.38,0,0,0-.11.57,1.22,1.22,0,0,0,.11.56,1.05,1.05,0,0,0,.29.39,1.2,1.2,0,0,0,.42.22,1.61,1.61,0,0,0,.49.07,2,2,0,0,0,.43,0,1.24,1.24,0,0,0,.37-.13h0a1,1,0,0,1-.35.7A1.23,1.23,0,0,1,349.34,573.41Zm.51-1.54a.55.55,0,0,1-.4-.13.5.5,0,0,1-.14-.37.57.57,0,0,1,0-.18.46.46,0,0,1,.09-.15.44.44,0,0,1,.15-.12.67.67,0,0,1,.22,0,.54.54,0,0,1,.46.23,1.15,1.15,0,0,1,.19.59.57.57,0,0,1-.13.08l-.17.05a.45.45,0,0,1-.16,0Z"/>
</g>

</svg>

		<ReactTooltip anchorId="tt1" place="right" float>
			<span>1. Stortingen in Gemeentefonds</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt2" place="right" float>
			<span>2. Rijksbijdrage</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt3" place="right" float>
			<span>3. Rijkssubsidies</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt4" place="right" float>
			<span>4. Rijksbijdrage</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt5" place="right" float>
			<span>5. Projectsubsidie</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt6" place="right" float>
			<span>6. Inwonersbijdrage en betaling voor aanvullende taken</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt7" place="right" float>
			<span>7. Betaling voor geleverde zorg of advies</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt8" place="right" float>
			<span>8. Betaling voor toegediende reizigersvaccinaties</span>
		</ReactTooltip>
		<ReactTooltip anchorId="tt9" place="right" float>
			<span>9. Subsidie voor preventieprogramma’s</span>
		</ReactTooltip>


	</div>
);

export default GeldstromenGGD;


