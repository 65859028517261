import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";

const GeldstromenZvw = () => (

<div className="vis-geldstromen-zvw svg-container">
<svg
	preserveAspectRatio="xMidYMin meet"
	viewBox="65 0 520 600">
	<g className="geldstromen">
		<path data-tooltip-content="react-tooltip" id="tt9" d="M249,30H231c-12.37,0-25.67,9-30.28,20.46l-91.57,227.6c-4,10-7.15,26.19-7.15,36.94v93l6-6,6,6V315c0-9.13,2.88-24,6.28-32.46l91.57-227.6C214.62,48.05,223.57,42,231,42h18l6-6Z"/>
		<polygon data-tooltip-content="react-tooltip" id="tt6" points="291 54 285 48 279 54 279 54 279 54 279 177 285 171 291 177 291 54 291 54 291 54"/>
		<polygon data-tooltip-content="react-tooltip" id="tt7" points="321 225 315 231 309 225 309 225 309 243 309 243 315 249 321 243 321 243 321 225 321 225"/>
		<path data-tooltip-content="react-tooltip" id="tt8" d="M249,276l6-6H195c-11.65,0-26.69,6.95-34.23,15.83L133.09,318.4C125.75,327,120,342.67,120,354v48h0l6,6,6-6h0V354c0-8.41,4.78-21.42,10.23-27.83l27.68-32.57c5.26-6.18,17-11.6,25.09-11.6h60Z"/>
		<path data-tooltip-content="react-tooltip" id="tt12" d="M249,384l6-6H162a6,6,0,0,0-6,6v18h0l6,6,6-6h0V390h87Z"/>
		<path data-tooltip-content="react-tooltip" id="tt13" d="M432,345l6-6H345a6,6,0,0,0-6,6v9h0l6,6,6-6h0v-3h87Z"/>
		<path data-tooltip-content="react-tooltip" id="tt5" d="M532.07,242.13,435.87,28.94C430.83,17.76,417.27,9,405,9H372l6,6-6,6h33c7.52,0,16.84,6,19.93,12.88l96.2,213.18c3.72,8.26,6.87,22.88,6.87,31.94v24h0l6,6,6-6h0V279C540,268.18,536.51,252,532.07,242.13Z"/>
		<path data-tooltip-content="react-tooltip" id="tt16" d="M576,330H561l6,6-6,6h9v27h-3l-6,6,6,6h9a6,6,0,0,0,6-6V336A6,6,0,0,0,576,330Z"/>
		<path data-tooltip-content="react-tooltip" id="tt17" d="M576,387h-9l-6,6,6,6h3v27h-9l6,6-6,6h15a6,6,0,0,0,6-6V393A6,6,0,0,0,576,387Z"/>
		<polygon data-tooltip-content="react-tooltip" id="tt14" points="432 384 438 378 375 378 375 378 369 384 375 390 375 390 438 390 438 390 432 384"/>
		<path data-tooltip-content="react-tooltip" id="tt15" d="M432,423l6-6H351v-3h0l-6-6-6,6h0v9a6,6,0,0,0,6,6h93Z"/>
		<polygon data-tooltip-content="react-tooltip" id="tt2" points="309 48 303 54 297 48 297 48 297 171 297 171 303 177 309 171 309 171 309 48 309 48"/>
		<polygon data-tooltip-content="react-tooltip" id="tt2" points="351.19 135 351 135 345 141 339.19 135.19 339.19 170.91 339 171 345 177 351 171 351.19 171 351.19 135"/>
		<path data-tooltip-content="react-tooltip" id="tt10" d="M432,321H189c-11.49,0-26.85,6.36-35,14.49L152.49,337c-8.13,8.12-14.49,23.48-14.49,35v36l6-6,6,6V372c0-8.26,5.13-20.64,11-26.49l1.54-1.54c5.85-5.84,18.23-11,26.49-11H432l6-6Z"/>
		<polygon data-tooltip-content="react-tooltip" id="tt11" points="432 435 432 435 186 435 192 441 186 447 186 447 432 447 432 447 438 441 432 435"/>
		<path data-tooltip-content="react-tooltip" id="tt134" d="M249,18l6-6H225.37c-16.6,0-29.7,9.28-36,25.45L91.25,278.07C87.18,288,84,304.25,84,315v87h0l6,6,6-6h0V315c0-9.12,2.91-24,6.36-32.4l98.2-240.67s0-.08,0-.12C205,30.33,213.81,24,225.37,24H255Z"/>
	</g>

	<g>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Burgers' }} >
			<g className="kader link">
				<rect x="262" y="4" width="106" height="40" rx="8"/>
				<text transform="translate(295 27)">Burgers</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Zorgverzekeraars' }} >
			<g className="kader link">
				<rect x="70" y="409" width="115" height="40" rx="8"/>
				<text transform="translate(79 432)">Zorgverzekeraars</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Groothandels' }} >
			<g className="kader link">
				<rect x="445" y="361" width="115" height="40" rx="8"/>
				<text transform="translate(468 384)">Groothandels</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Ziekenhuizen' }} >
			<g className="kader link">
				<rect x="445" y="409" width="115" height="40" rx="8"/>
				<text transform="translate(470 432)">Ziekenhuizen</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Openbareapotheken' }} >
			<g className="kader link">
				<rect x="445" y="313" width="115" height="40" rx="8"/>
				<text transform="translate(475 330)">Openbare</text>
				<text transform="translate(472.56 342)">apotheken</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#WerkgeversenUitkeringsverstrekkers' }} >
			<g className="kader link">
				<rect x="317.5" y="91" width="109" height="40" rx="8"/>
				<text transform="translate(332 104)">Werkgevers en</text>
				<text transform="translate(342 116)">Uitkerings-</text>
				<text transform="translate(337 126)">verstrekkers</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Rijk' }} >
			<g className="kader link">
				<rect x="262" y="181" width="106" height="40" rx="8"/>
				<text transform="translate(305 204)">Rijk</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#ZorginstituutNederland' }} >
			<g className="kader link">
				<path d="M270,293.07a8,8,0,0,1-8-8v-24a8,8,0,0,1,8-8l90-.06a8,8,0,0,1,8,8v24a8,8,0,0,1-8,8l-90,.06Z"/>
				<text transform="translate(285.83 276.49)">Zorginstituut</text>
			</g>
		</Link>
		<Link to={{ pathname: '/geneesmiddelenzorg/wie-betaalt-zvw', hash: '#Geneesmiddelenfabrikanten' }} >
			<g className="kader link">
				<rect x="262" y="361.07" width="106" height="40" rx="8"/>
				<text transform="translate(268 379)">Geneesmiddelen-</text>
				<text transform="translate(285 391)">fabrikanten</text>
			</g>
		</Link>
</g>


<g className="tekst links regular">
		<text transform="translate(85 491.56)">
			Nominale premie
			<tspan x="0" y="15">Inkomensafhankelijke bijdrage & belasting</tspan>
			<tspan x="0" y="30">Premie aanvullende verzekering</tspan>
			<tspan x="0" y="45">Eigen risico</tspan>
			<tspan x="0" y="60">Eigen betalingen en bijdragen</tspan>
			<tspan x="0" y="75">Zorgtoeslag</tspan>
			<tspan x="0" y="90">Rijksbijdrage</tspan>
			<tspan x="0" y="105">Risicoverevening Kinderpremie</tspan>
			<tspan x="0" y="120">Betaling voor geleverde geneesmiddelen</tspan>
			<tspan x="249" y="0">Betaling voor geleverde geneesmiddelenzorg</tspan>
			<tspan x="249" y="15">Betaling voor geleverde geneesmiddelenzorg</tspan>
			<tspan x="249" y="30">Verrekening lagere prijs</tspan>
			<tspan x="249" y="45">Inkoop geneesmiddelen</tspan>
			<tspan x="249" y="60">Inkoop geneesmiddelen</tspan>
			<tspan x="249" y="75">Inkoop geneesmiddelen</tspan>
			<tspan x="249" y="90">Inkoop geneesmiddelen</tspan>
			<tspan x="249" y="105">Inkoop geneesmiddelen</tspan>
		</text>

</g>

<g className="tekstlabels wit">
		<path d="M279.72,69.69a4.84,4.84,0,0,1,.19-1.38,5.32,5.32,0,0,1,.52-1.25,5.24,5.24,0,0,1,1.87-1.87,5,5,0,0,1,1.24-.52,4.94,4.94,0,0,1,1.38-.19,4.84,4.84,0,0,1,1.38.19,5.32,5.32,0,0,1,1.25.52,5.46,5.46,0,0,1,1.87,1.87,5.42,5.42,0,0,1,.52,1.24,4.86,4.86,0,0,1,.19,1.39,4.94,4.94,0,0,1-.19,1.38,5.42,5.42,0,0,1-.52,1.24,5.46,5.46,0,0,1-1.87,1.87,5,5,0,0,1-1.24.52,5.14,5.14,0,0,1-2.77,0,5.42,5.42,0,0,1-1.24-.52,5.24,5.24,0,0,1-1.87-1.87,5.42,5.42,0,0,1-.52-1.24A4.94,4.94,0,0,1,279.72,69.69Zm5.37-.66a2.23,2.23,0,0,0-.54.06,1.51,1.51,0,0,0-.38.14l0,0a1.12,1.12,0,0,1,1.2-1.07,2.91,2.91,0,0,1,.57.06,4.25,4.25,0,0,1,.52.14v-1a1.82,1.82,0,0,0-.48-.15,2.93,2.93,0,0,0-.67-.07,2.38,2.38,0,0,0-1.07.22,2,2,0,0,0-.74.59,2.48,2.48,0,0,0-.42.88,4.21,4.21,0,0,0-.13,1.08,3.07,3.07,0,0,0,.15,1,1.93,1.93,0,0,0,.41.73,1.58,1.58,0,0,0,.62.43,2,2,0,0,0,.77.14,2.29,2.29,0,0,0,.76-.12,1.63,1.63,0,0,0,.57-.34,1.34,1.34,0,0,0,.37-.53,1.65,1.65,0,0,0,.13-.68,1.61,1.61,0,0,0-.12-.63,1.43,1.43,0,0,0-.33-.47,1.58,1.58,0,0,0-.52-.3A1.93,1.93,0,0,0,285.09,69Zm-.21,2.15a.59.59,0,0,1-.29-.07.63.63,0,0,1-.23-.2,1,1,0,0,1-.16-.32,1.42,1.42,0,0,1-.06-.42,1.2,1.2,0,0,1,.33-.13,1.34,1.34,0,0,1,.35-.05.7.7,0,0,1,.5.16.53.53,0,0,1,.17.4.63.63,0,0,1-.17.47A.58.58,0,0,1,284.88,71.18Z"/>
		<path d="M309.77,237a5.26,5.26,0,0,1,1.52-3.68,5.14,5.14,0,0,1,3.68-1.52,4.84,4.84,0,0,1,1.38.19,5.11,5.11,0,0,1,2.3,1.33,5.41,5.41,0,0,1,1.34,2.3,5.38,5.38,0,0,1,0,2.76,5.26,5.26,0,0,1-1.34,2.3,5,5,0,0,1-2.3,1.34,5.13,5.13,0,0,1-4-.53,4.89,4.89,0,0,1-1.06-.81,5.11,5.11,0,0,1-1.33-2.3A4.84,4.84,0,0,1,309.77,237Zm5.64-1.4-1.82,3.82h1.29l1.91-4v-.82h-3.48v1Z"/>
		<path d="M310.56,327a4.84,4.84,0,0,1,.19-1.38,5.5,5.5,0,0,1,.52-1.25,5.88,5.88,0,0,1,.81-1.05,5.27,5.27,0,0,1,1.06-.82,5.42,5.42,0,0,1,1.24-.52,5.33,5.33,0,0,1,1.38-.18,5.22,5.22,0,0,1,1.38.18,5.34,5.34,0,0,1,1.25.53,4.6,4.6,0,0,1,1.05.81,5.51,5.51,0,0,1,.82,1.05,5.32,5.32,0,0,1,.52,1.25,5.11,5.11,0,0,1,0,2.76,5.15,5.15,0,0,1-1.34,2.3,4.9,4.9,0,0,1-1.05.81,5.45,5.45,0,0,1-1.24.53,5.3,5.3,0,0,1-1.39.18,4.94,4.94,0,0,1-1.38-.19,5.42,5.42,0,0,1-1.24-.52,5.22,5.22,0,0,1-1.06-.81,5.61,5.61,0,0,1-.81-1.06,5.24,5.24,0,0,1-.52-1.24A4.84,4.84,0,0,1,310.56,327Zm2,1.39v1h3.07v-1h-.87v-3.89h-1l-1.27.51v1.05l1.08-.37v2.7Zm3.32-1.41a4.13,4.13,0,0,0,.13,1.11,2.47,2.47,0,0,0,.37.8,1.63,1.63,0,0,0,.58.47,1.6,1.6,0,0,0,.73.16,1.66,1.66,0,0,0,.73-.16,1.6,1.6,0,0,0,.57-.47,2.47,2.47,0,0,0,.37-.8,4.13,4.13,0,0,0,.13-1.11,4.23,4.23,0,0,0-.13-1.13,2.32,2.32,0,0,0-.37-.79,1.5,1.5,0,0,0-.57-.47,1.66,1.66,0,0,0-.73-.16,1.6,1.6,0,0,0-.73.16,1.53,1.53,0,0,0-.58.47,2.32,2.32,0,0,0-.37.79A4.23,4.23,0,0,0,315.88,327Zm2.39,0a5.74,5.74,0,0,1,0,.6,2.56,2.56,0,0,1-.1.47.81.81,0,0,1-.18.32.37.37,0,0,1-.27.11.38.38,0,0,1-.28-.11.81.81,0,0,1-.18-.32,2.56,2.56,0,0,1-.1-.47c0-.19,0-.39,0-.6s0-.42,0-.61a2.56,2.56,0,0,1,.1-.47.81.81,0,0,1,.18-.32.38.38,0,0,1,.28-.11.37.37,0,0,1,.27.11.81.81,0,0,1,.18.32,2.56,2.56,0,0,1,.1.47A5.79,5.79,0,0,1,318.27,327Z"/>
		<path d="M201.57,384.2a4.94,4.94,0,0,1,.19-1.38,5.24,5.24,0,0,1,.52-1.24,5.61,5.61,0,0,1,.81-1.06,5.22,5.22,0,0,1,1.06-.81,5.45,5.45,0,0,1,1.24-.53,5.33,5.33,0,0,1,1.38-.18,4.84,4.84,0,0,1,1.38.19,5.32,5.32,0,0,1,1.25.52,5.18,5.18,0,0,1,2.39,3.11,5.11,5.11,0,0,1,0,2.76,5.32,5.32,0,0,1-.52,1.25,5.21,5.21,0,0,1-.82,1.05,5,5,0,0,1-1,.82,5.42,5.42,0,0,1-1.24.52,5.14,5.14,0,0,1-2.77,0,5.45,5.45,0,0,1-1.24-.53,4.89,4.89,0,0,1-1.06-.81,5.54,5.54,0,0,1-.81-1.05,5.32,5.32,0,0,1-.52-1.25A4.84,4.84,0,0,1,201.57,384.2Zm1.89,1.4v1h3.07v-1h-.88v-3.89h-1l-1.27.51v1l1.08-.36v2.7Zm4.93-4A3.06,3.06,0,0,0,207,382V383a2.8,2.8,0,0,1,1.21-.37c.43,0,.65.17.65.52a1.13,1.13,0,0,1-.12.47,3.34,3.34,0,0,1-.37.56c-.16.21-.36.44-.6.7l-.84.88v.82h3.33v-1h-1.13l-.72,0v0c.29-.27.53-.52.74-.74a5.54,5.54,0,0,0,.53-.63,2.1,2.1,0,0,0,.31-.59,1.8,1.8,0,0,0,.11-.62,1.48,1.48,0,0,0-.13-.61,1.26,1.26,0,0,0-.36-.43,1.47,1.47,0,0,0-.53-.27A2.71,2.71,0,0,0,208.39,381.64Z"/>
		<path d="M402.38,384.2a5.33,5.33,0,0,1,.18-1.38,5.27,5.27,0,0,1,.53-1.24,4.89,4.89,0,0,1,.81-1.06,5.14,5.14,0,0,1,3.68-1.52,4.84,4.84,0,0,1,1.38.19,5.5,5.5,0,0,1,1.25.52,5.88,5.88,0,0,1,1.05.81,5.41,5.41,0,0,1,1.34,2.3,5.38,5.38,0,0,1,0,2.76,5,5,0,0,1-.53,1.25,4.83,4.83,0,0,1-.81,1.05,5,5,0,0,1-2.3,1.34,5.13,5.13,0,0,1-4-.53,4.89,4.89,0,0,1-1.06-.81,4.83,4.83,0,0,1-.81-1.05,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,402.38,384.2Zm2,1.4v1h3.07v-1h-.88v-3.89h-1l-1.27.51v1l1.07-.36v2.7Zm5.74-3.82H408.9l-1.16,3.11v.78h2v.95h1.14v-.95h.56v-1h-.56v-.74h-1.14v.74h-.17a5.85,5.85,0,0,0-.64,0v0a9.68,9.68,0,0,0,.46-1Z"/>
		<path d="M571.16,353.87a4.84,4.84,0,0,1,.19-1.38,5.15,5.15,0,0,1,1.34-2.3,4.6,4.6,0,0,1,1-.81,5.34,5.34,0,0,1,1.25-.53,5.22,5.22,0,0,1,1.38-.18,4.94,4.94,0,0,1,1.38.19,5,5,0,0,1,1.24.52,4.6,4.6,0,0,1,1,.81,5.27,5.27,0,0,1,.82,1.06,5.16,5.16,0,0,1,.52,4,5.08,5.08,0,0,1-.52,1.25,5.51,5.51,0,0,1-.82,1.05,4.6,4.6,0,0,1-1,.81,5,5,0,0,1-1.24.53,5.38,5.38,0,0,1-2.76,0,5.34,5.34,0,0,1-1.25-.53,4.6,4.6,0,0,1-1-.81,5,5,0,0,1-1.34-2.3A4.84,4.84,0,0,1,571.16,353.87Zm2,1.4v1h3.07v-1h-.88v-3.9h-1l-1.27.51v1.05l1.08-.37v2.71Zm5.27-2.06a2,2,0,0,0-.51.06,1.69,1.69,0,0,0-.37.14h0a1.27,1.27,0,0,1,.39-.82,1.12,1.12,0,0,1,.76-.25,2.64,2.64,0,0,1,.55.06,2.74,2.74,0,0,1,.5.14v-1a2.23,2.23,0,0,0-.46-.15,3.39,3.39,0,0,0-.64-.07,2.3,2.3,0,0,0-1,.22,2,2,0,0,0-.71.59,2.53,2.53,0,0,0-.4.88,4.23,4.23,0,0,0-.13,1.09,3.12,3.12,0,0,0,.14,1,2.08,2.08,0,0,0,.4.72,1.5,1.5,0,0,0,.59.44,2,2,0,0,0,.74.14,2.11,2.11,0,0,0,.73-.12,1.55,1.55,0,0,0,.55-.34,1.51,1.51,0,0,0,.36-.53,1.92,1.92,0,0,0,.12-.69,1.75,1.75,0,0,0-.11-.62,1.45,1.45,0,0,0-.32-.47,1.39,1.39,0,0,0-.5-.3A1.81,1.81,0,0,0,578.38,353.21Zm-.19,2.15a.59.59,0,0,1-.29-.07.66.66,0,0,1-.22-.19,1.35,1.35,0,0,1-.15-.32,1.44,1.44,0,0,1-.06-.43,1.53,1.53,0,0,1,.32-.13,1.24,1.24,0,0,1,.33,0,.69.69,0,0,1,.48.15.54.54,0,0,1,.16.41.64.64,0,0,1-.15.46A.57.57,0,0,1,578.19,355.36Z"/>
		<path d="M571.16,411.34a4.84,4.84,0,0,1,.19-1.38,5,5,0,0,1,1.34-2.3,5,5,0,0,1,1-.82,5.32,5.32,0,0,1,1.25-.52,5.22,5.22,0,0,1,1.38-.18,5.33,5.33,0,0,1,1.38.18,5.45,5.45,0,0,1,1.24.53,4.6,4.6,0,0,1,1,.81,5.51,5.51,0,0,1,.82,1,4.93,4.93,0,0,1,.52,1.25,5.22,5.22,0,0,1-.52,4A5.27,5.27,0,0,1,580,415a4.9,4.9,0,0,1-1,.81,5.45,5.45,0,0,1-1.24.53,5.22,5.22,0,0,1-1.38.18,4.84,4.84,0,0,1-1.38-.19,5.32,5.32,0,0,1-1.25-.52,4.9,4.9,0,0,1-1-.81,5.15,5.15,0,0,1-1.34-2.3A4.84,4.84,0,0,1,571.16,411.34Zm2,1.39v1h3.07v-1h-.88v-3.89h-1l-1.27.51v1l1.08-.37v2.7Zm5.15-2.79-1.74,3.81h1.23l1.83-4v-.82H576.3v1Z"/>
		<path d="M402.38,344.9a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.26,5.26,0,0,1-2.3,1.34,5.13,5.13,0,0,1-4-.53,5,5,0,0,1-1.87-1.87,5,5,0,0,1-.71-2.62Zm2.06,1.39v1h3.07v-1h-.88V342.4h-1l-1.27.51v1l1.08-.37v2.7Zm6.5-2.64a1.17,1.17,0,0,0-.45-1,2.12,2.12,0,0,0-1.32-.36,3.81,3.81,0,0,0-1.21.21v1a2.76,2.76,0,0,1,.51-.16,3.29,3.29,0,0,1,.54-.05,1.07,1.07,0,0,1,.51.11.37.37,0,0,1,.2.35.38.38,0,0,1-.07.24.52.52,0,0,1-.17.16.81.81,0,0,1-.25.09l-.3,0h-.46v1h.61l.29,0a.85.85,0,0,1,.24.09.39.39,0,0,1,.15.16.44.44,0,0,1,.06.25.48.48,0,0,1-.21.41,1.1,1.1,0,0,1-.64.15,2.91,2.91,0,0,1-.6-.06,2.56,2.56,0,0,1-.49-.16v1a3.09,3.09,0,0,0,1.15.21,2.39,2.39,0,0,0,1.51-.41,1.4,1.4,0,0,0,.51-1.14,1.13,1.13,0,0,0-.21-.71,1.06,1.06,0,0,0-.55-.37v0a1.07,1.07,0,0,0,.5-.46A1.4,1.4,0,0,0,410.94,343.65Z"/>
		<path d="M402.38,423a5.33,5.33,0,0,1,.18-1.38,5.45,5.45,0,0,1,.53-1.24,4.89,4.89,0,0,1,.81-1.06,5.61,5.61,0,0,1,1.06-.81,5,5,0,0,1,2.62-.71A4.84,4.84,0,0,1,409,418a5.5,5.5,0,0,1,1.25.52,5.88,5.88,0,0,1,1.05.81,4.89,4.89,0,0,1,.81,1.06,5,5,0,0,1,.53,1.24,5.38,5.38,0,0,1,0,2.76,5,5,0,0,1-.53,1.25,4.6,4.6,0,0,1-.81,1.05A5,5,0,0,1,409,428a4.84,4.84,0,0,1-1.38.19,5.15,5.15,0,0,1-2.62-.72,4.89,4.89,0,0,1-1.06-.81,4.6,4.6,0,0,1-.81-1.05,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,402.38,423Zm2,1.4v1h3.07v-1h-.88v-3.89h-1l-1.28.51v1.05l1.08-.37v2.7Zm4.82-2.8h1.71v-1h-2.68l-.37,2.75a4,4,0,0,1,.76-.07,3.68,3.68,0,0,1,.51,0,1.82,1.82,0,0,1,.33.11.44.44,0,0,1,.17.19.51.51,0,0,1,.06.25.54.54,0,0,1-.07.29.64.64,0,0,1-.2.2,1,1,0,0,1-.28.12,1.65,1.65,0,0,1-.32,0,2.38,2.38,0,0,1-.55-.06,2.86,2.86,0,0,1-.52-.17v1.05a2.11,2.11,0,0,0,.5.15,3.1,3.1,0,0,0,.66.06,2.75,2.75,0,0,0,.84-.12,2.14,2.14,0,0,0,.63-.35,1.52,1.52,0,0,0,.4-.54,1.74,1.74,0,0,0,.14-.69,1.79,1.79,0,0,0-.09-.57,1.31,1.31,0,0,0-.3-.46,1.52,1.52,0,0,0-.53-.31,2.18,2.18,0,0,0-.77-.12h-.14Z"/>
		<path d="M310.56,441a4.84,4.84,0,0,1,.19-1.38,5.5,5.5,0,0,1,.52-1.25,5.88,5.88,0,0,1,.81-1.05,4.89,4.89,0,0,1,1.06-.81,5,5,0,0,1,1.24-.53,5.33,5.33,0,0,1,1.38-.18,5.22,5.22,0,0,1,1.38.18,5.34,5.34,0,0,1,1.25.53,4.6,4.6,0,0,1,1.05.81,5.51,5.51,0,0,1,.82,1.05,5.32,5.32,0,0,1,.52,1.25,5.11,5.11,0,0,1,0,2.76,5.15,5.15,0,0,1-1.34,2.3,4.6,4.6,0,0,1-1.05.81,5.45,5.45,0,0,1-1.24.53,5.3,5.3,0,0,1-1.39.18,4.94,4.94,0,0,1-1.38-.19,5.42,5.42,0,0,1-1.24-.52,4.89,4.89,0,0,1-1.06-.81,5.61,5.61,0,0,1-.81-1.06,5.24,5.24,0,0,1-.52-1.24A4.84,4.84,0,0,1,310.56,441Zm2.06,1.39v1h3.07v-1h-.88v-3.89h-1l-1.27.51v1.05l1.07-.37v2.7Zm3.37,0v1h3.07v-1h-.87v-3.89h-1l-1.27.51v1.05l1.07-.37v2.7Z"/>
		<path d="M480.6,153.68a5.33,5.33,0,0,1,.18-1.38,5.45,5.45,0,0,1,.53-1.24,4.89,4.89,0,0,1,.81-1.06,5.88,5.88,0,0,1,1.05-.81,5.08,5.08,0,0,1,1.25-.52,5.11,5.11,0,0,1,2.76,0,5.24,5.24,0,0,1,1.24.52,5.61,5.61,0,0,1,1.06.81,5.22,5.22,0,0,1,.81,1.06,5.45,5.45,0,0,1,.53,1.24,5.38,5.38,0,0,1,0,2.76,5.34,5.34,0,0,1-.53,1.25,4.9,4.9,0,0,1-.81,1.05,5.15,5.15,0,0,1-2.3,1.34,4.84,4.84,0,0,1-1.38.19,5.22,5.22,0,0,1-1.38-.19,6,6,0,0,1-1.25-.52,5.51,5.51,0,0,1-1.05-.82,4.6,4.6,0,0,1-.81-1.05,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,480.6,153.68Zm5.06-1.4h1.79v-1h-2.79l-.39,2.75a4.29,4.29,0,0,1,.79-.07,3.89,3.89,0,0,1,.53,0,1.11,1.11,0,0,1,.34.12.42.42,0,0,1,.19.18.51.51,0,0,1,.06.25.57.57,0,0,1-.28.49,1.1,1.1,0,0,1-.29.12,1.86,1.86,0,0,1-.34,0,2.62,2.62,0,0,1-.57-.06,3.32,3.32,0,0,1-.54-.17V156a2.35,2.35,0,0,0,.51.16,4.58,4.58,0,0,0,.7,0,3,3,0,0,0,.87-.12,2,2,0,0,0,.65-.35,1.57,1.57,0,0,0,.57-1.23,1.8,1.8,0,0,0-.1-.57,1.17,1.17,0,0,0-.31-.46,1.61,1.61,0,0,0-.55-.31,2.4,2.4,0,0,0-.81-.11h-.14Z"/>
		<path d="M156.31,164.2a5.22,5.22,0,0,1,.19-1.38,6,6,0,0,1,.52-1.25,5.51,5.51,0,0,1,.82-1,4.6,4.6,0,0,1,1-.81,5,5,0,0,1,1.25-.53,5.22,5.22,0,0,1,1.38-.18,5.33,5.33,0,0,1,1.38.18,5.45,5.45,0,0,1,1.24.53,4.89,4.89,0,0,1,1.06.81,5.88,5.88,0,0,1,.81,1,5.08,5.08,0,0,1,.52,1.25,5.22,5.22,0,0,1-.52,4,5.61,5.61,0,0,1-.81,1.06,4.89,4.89,0,0,1-1.06.81,5,5,0,0,1-1.24.53,5.38,5.38,0,0,1-2.76,0,5.34,5.34,0,0,1-1.25-.53,4.6,4.6,0,0,1-1-.81,5.27,5.27,0,0,1-.82-1.06,5.69,5.69,0,0,1-.52-1.24A5.22,5.22,0,0,1,156.31,164.2Zm4.68,1.49a2.7,2.7,0,0,1-.51-.05,2.48,2.48,0,0,1-.59-.17v1a2.89,2.89,0,0,0,.52.14,3.14,3.14,0,0,0,.7.07,2.62,2.62,0,0,0,1.15-.22,1.9,1.9,0,0,0,.74-.59,2.33,2.33,0,0,0,.41-.87,4.44,4.44,0,0,0,.12-1,2.6,2.6,0,0,0-.53-1.75,1.91,1.91,0,0,0-1.5-.61,1.78,1.78,0,0,0-.7.13,1.55,1.55,0,0,0-.93.88,1.65,1.65,0,0,0-.13.68,1.54,1.54,0,0,0,.13.67,1.37,1.37,0,0,0,.35.46,1.56,1.56,0,0,0,.51.27,2,2,0,0,0,.59.09,3.05,3.05,0,0,0,.52-.05,1.75,1.75,0,0,0,.44-.16l0,0a1.26,1.26,0,0,1-.41.83A1.5,1.5,0,0,1,161,165.69Zm.61-1.84a.64.64,0,0,1-.47-.16.59.59,0,0,1-.17-.44.83.83,0,0,1,0-.22.72.72,0,0,1,.11-.19.59.59,0,0,1,.18-.13.72.72,0,0,1,.27-.05.63.63,0,0,1,.55.27,1.36,1.36,0,0,1,.22.71.38.38,0,0,1-.15.09l-.2.07-.2,0Z"/>
		<path d="M147.35,143.41a5.22,5.22,0,0,1,.19-1.38,5.34,5.34,0,0,1,.53-1.25,4.6,4.6,0,0,1,.81-1.05,5,5,0,0,1,1.05-.82,5.32,5.32,0,0,1,1.25-.52,4.84,4.84,0,0,1,1.38-.19,5.34,5.34,0,0,1,1.38.19,5.45,5.45,0,0,1,1.24.53,4.89,4.89,0,0,1,1.06.81,5.24,5.24,0,0,1,.81,1.05,5.45,5.45,0,0,1,.53,1.24,5.42,5.42,0,0,1,0,2.77,5.45,5.45,0,0,1-.53,1.24,5,5,0,0,1-1.87,1.87,5,5,0,0,1-1.24.52,5.11,5.11,0,0,1-2.76,0,5.32,5.32,0,0,1-1.25-.52,5.24,5.24,0,0,1-1.05-.81,4.89,4.89,0,0,1-.81-1.06,5.45,5.45,0,0,1-.53-1.24A5.34,5.34,0,0,1,147.35,143.41Zm3.58,1.39v1h3.44v-1h-1v-3.89h-1l-1.44.51v1.05l1.23-.37v2.7Z"/>
		<path d="M130.47,184.76a5.34,5.34,0,0,1,.19-1.38,5.45,5.45,0,0,1,.53-1.24,4.6,4.6,0,0,1,.81-1,5.21,5.21,0,0,1,1.05-.82,4.93,4.93,0,0,1,1.25-.52,4.84,4.84,0,0,1,1.38-.19,4.94,4.94,0,0,1,1.38.19,5.15,5.15,0,0,1,2.3,1.34,4.9,4.9,0,0,1,.81,1,5.45,5.45,0,0,1,.53,1.24,5.38,5.38,0,0,1,0,2.76,5.34,5.34,0,0,1-.53,1.25,4.9,4.9,0,0,1-.81,1.05,5.27,5.27,0,0,1-1.06.82,5.16,5.16,0,0,1-4,.52,5.32,5.32,0,0,1-1.25-.52,5.21,5.21,0,0,1-1.05-.82,4.6,4.6,0,0,1-.81-1.05,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,130.47,184.76Zm5.69-2.42h-1.24l-1.22,3.12v.78h2.06v.94h1.18v-.94h.59v-1h-.59v-.73h-1.18v.73h-.18a6.12,6.12,0,0,0-.66,0v0a6.76,6.76,0,0,0,.47-1Z"/>
		<path d="M139,164.2a4.84,4.84,0,0,1,.19-1.38,5.24,5.24,0,0,1,.52-1.24,5.61,5.61,0,0,1,.81-1.06,5.41,5.41,0,0,1,2.3-1.34,5.33,5.33,0,0,1,1.38-.18,5.22,5.22,0,0,1,1.38.18,5.34,5.34,0,0,1,1.25.53,4.83,4.83,0,0,1,1,.81,5.17,5.17,0,0,1,1.34,5.06,5,5,0,0,1-1.34,2.3,4.83,4.83,0,0,1-1,.81,5,5,0,0,1-1.24.53,5.42,5.42,0,0,1-2.77,0,5.27,5.27,0,0,1-1.24-.53,4.89,4.89,0,0,1-1.06-.81,5.88,5.88,0,0,1-.81-1,5.5,5.5,0,0,1-.52-1.25A4.84,4.84,0,0,1,139,164.2Zm7-1.25a1.14,1.14,0,0,0-.48-1,2.24,2.24,0,0,0-1.37-.36,4.06,4.06,0,0,0-1.26.21v1a3,3,0,0,1,.53-.16,3.43,3.43,0,0,1,.56-.05,1.2,1.2,0,0,1,.54.11.37.37,0,0,1,.2.35.43.43,0,0,1-.06.24.6.6,0,0,1-.19.16.88.88,0,0,1-.26.09,1.55,1.55,0,0,1-.31,0h-.48v1H144l.31,0a.92.92,0,0,1,.25.09.41.41,0,0,1,.16.16.44.44,0,0,1,.06.25.47.47,0,0,1-.22.41,1.21,1.21,0,0,1-.67.15,3.21,3.21,0,0,1-.63-.06,2.68,2.68,0,0,1-.5-.16v1a3.29,3.29,0,0,0,1.2.21,2.58,2.58,0,0,0,1.57-.41,1.39,1.39,0,0,0,.53-1.14,1.13,1.13,0,0,0-.22-.71,1.16,1.16,0,0,0-.57-.37v0a1.12,1.12,0,0,0,.52-.46A1.29,1.29,0,0,0,146,163Z"/>
		<path d="M216.94,276.36a5.22,5.22,0,0,1,.18-1.38,5.34,5.34,0,0,1,.53-1.25,4.6,4.6,0,0,1,.81-1.05,5.21,5.21,0,0,1,1-.82,4.93,4.93,0,0,1,1.25-.52,4.84,4.84,0,0,1,1.38-.19,4.94,4.94,0,0,1,1.38.19,5.15,5.15,0,0,1,2.3,1.34,4.9,4.9,0,0,1,.81,1.05,5.45,5.45,0,0,1,.53,1.24,5.42,5.42,0,0,1,0,2.77,5.45,5.45,0,0,1-.53,1.24,5.22,5.22,0,0,1-.81,1.06,5.61,5.61,0,0,1-1.06.81,5.16,5.16,0,0,1-4,.52,5.32,5.32,0,0,1-1.25-.52,5.54,5.54,0,0,1-1-.81,4.89,4.89,0,0,1-.81-1.06,5.45,5.45,0,0,1-.53-1.24A5.33,5.33,0,0,1,216.94,276.36Zm5.2-2.57a2.68,2.68,0,0,0-.78.1,1.67,1.67,0,0,0-.57.27,1.18,1.18,0,0,0-.48,1,1.13,1.13,0,0,0,.2.67,1.31,1.31,0,0,0,.53.42v0a1.73,1.73,0,0,0-.33.17,1,1,0,0,0-.26.25,1.06,1.06,0,0,0-.18.33,1.43,1.43,0,0,0-.07.43,1.38,1.38,0,0,0,.13.59,1.29,1.29,0,0,0,.38.46,1.86,1.86,0,0,0,.6.29,3.5,3.5,0,0,0,.83.1,3.35,3.35,0,0,0,.82-.1,2,2,0,0,0,.61-.29,1.31,1.31,0,0,0,.5-1.05A1.44,1.44,0,0,0,224,277a1.74,1.74,0,0,0-.18-.33,1.27,1.27,0,0,0-.27-.25,1.29,1.29,0,0,0-.33-.17v0a1.34,1.34,0,0,0,.54-.42,1.13,1.13,0,0,0,.2-.67,1.12,1.12,0,0,0-.13-.55,1.21,1.21,0,0,0-.35-.43,1.64,1.64,0,0,0-.58-.27A2.57,2.57,0,0,0,222.14,273.79Zm0,4.08a.84.84,0,0,1-.51-.14.48.48,0,0,1-.17-.36.53.53,0,0,1,.2-.45.59.59,0,0,1,1,0,.56.56,0,0,1,.2.45.48.48,0,0,1-.17.36A.84.84,0,0,1,222.14,277.87Zm0-2a.81.81,0,0,1-.45-.25.56.56,0,0,1-.13-.34.36.36,0,0,1,.05-.21.37.37,0,0,1,.13-.15.51.51,0,0,1,.18-.09.78.78,0,0,1,.22,0l.21,0a.48.48,0,0,1,.19.09.47.47,0,0,1,.13.15.46.46,0,0,1,.05.21.58.58,0,0,1-.14.34A.78.78,0,0,1,222.14,275.86Z"/>
		<path d="M297.88,69.68a5.34,5.34,0,0,1,.19-1.38,5.45,5.45,0,0,1,.53-1.24,4.89,4.89,0,0,1,.81-1.06,5.54,5.54,0,0,1,1-.81,4.93,4.93,0,0,1,1.25-.52,4.84,4.84,0,0,1,1.38-.19,4.94,4.94,0,0,1,1.38.19,5.24,5.24,0,0,1,1.24.52,5.61,5.61,0,0,1,1.06.81,5.22,5.22,0,0,1,.81,1.06,5.45,5.45,0,0,1,.53,1.24,5.38,5.38,0,0,1,0,2.76,5.34,5.34,0,0,1-.53,1.25,4.9,4.9,0,0,1-.81,1,5.27,5.27,0,0,1-1.06.82,5.16,5.16,0,0,1-4,.52,5.32,5.32,0,0,1-1.25-.52,5.21,5.21,0,0,1-1-.82,4.6,4.6,0,0,1-.81-1,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,297.88,69.68ZM303,67.12a3.31,3.31,0,0,0-1.47.34v1.06a3,3,0,0,1,1.25-.36c.46,0,.68.17.68.51a1.09,1.09,0,0,1-.12.47,3.64,3.64,0,0,1-.39.57,8.58,8.58,0,0,1-.63.7l-.87.87v.82H305v-1h-1.17l-.75,0h0l.77-.74c.22-.23.4-.44.55-.63a2.65,2.65,0,0,0,.32-.59,1.64,1.64,0,0,0,.11-.63,1.26,1.26,0,0,0-.13-.6,1.32,1.32,0,0,0-.37-.44,1.45,1.45,0,0,0-.56-.26A2.48,2.48,0,0,0,303,67.12Z"/>
		<path d="M339.88,153.68a5.34,5.34,0,0,1,.19-1.38,5.45,5.45,0,0,1,.53-1.24,4.89,4.89,0,0,1,.81-1.06,5.54,5.54,0,0,1,1-.81,4.93,4.93,0,0,1,1.25-.52,4.84,4.84,0,0,1,1.38-.19,4.94,4.94,0,0,1,1.38.19,5.24,5.24,0,0,1,1.24.52,5.61,5.61,0,0,1,1.06.81,5.22,5.22,0,0,1,.81,1.06,5.45,5.45,0,0,1,.53,1.24,5.38,5.38,0,0,1,0,2.76,5.34,5.34,0,0,1-.53,1.25,4.9,4.9,0,0,1-.81,1.05,5.27,5.27,0,0,1-1.06.82,5.16,5.16,0,0,1-4,.52,5.32,5.32,0,0,1-1.25-.52,5.21,5.21,0,0,1-1-.82,4.6,4.6,0,0,1-.81-1.05,5.34,5.34,0,0,1-.53-1.25A5.22,5.22,0,0,1,339.88,153.68Zm5.16-2.56a3.31,3.31,0,0,0-1.47.34v1.06a3,3,0,0,1,1.25-.36c.46,0,.68.17.68.51a1.09,1.09,0,0,1-.12.47,3.64,3.64,0,0,1-.39.57,8.58,8.58,0,0,1-.63.7l-.87.87v.82H347v-1h-1.17l-.75,0h0l.77-.74c.22-.23.4-.44.55-.63a2.65,2.65,0,0,0,.32-.59,1.64,1.64,0,0,0,.11-.63,1.26,1.26,0,0,0-.13-.6,1.32,1.32,0,0,0-.37-.44,1.45,1.45,0,0,0-.56-.26A2.48,2.48,0,0,0,345,151.12Z"/>
</g>

<g className="tekstlabels zwart" transform="translate(-20 0)">
			<path d="M87.53,488.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm3.58,1.39v1h3.43v-1h-1V485.7h-1l-1.44.51v1.05l1.24-.37v2.7Z"/>
			<path d="M87.53,503.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.15-2.57a3.32,3.32,0,0,0-1.47.35v1a3.05,3.05,0,0,1,1.26-.36c.45,0,.68.17.68.52a1.07,1.07,0,0,1-.13.46,4.32,4.32,0,0,1-.38.57c-.17.21-.38.44-.63.7l-.88.88v.81h3.48v-1H93.43c-.24,0-.49,0-.75,0v0l.77-.74a4.94,4.94,0,0,0,.55-.63,3.21,3.21,0,0,0,.33-.59,1.79,1.79,0,0,0,.1-.62,1.24,1.24,0,0,0-.5-1,1.86,1.86,0,0,0-.55-.26A2.61,2.61,0,0,0,92.68,500.63Z"/>
			<path d="M87.53,518.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm7-1.25a1.14,1.14,0,0,0-.48-1,2.24,2.24,0,0,0-1.37-.36,4.06,4.06,0,0,0-1.26.21v1a2.74,2.74,0,0,1,.53-.16,3.43,3.43,0,0,1,.56-.05,1.2,1.2,0,0,1,.54.11.37.37,0,0,1,.2.35.4.4,0,0,1-.07.24.47.47,0,0,1-.18.16.88.88,0,0,1-.26.09,1.66,1.66,0,0,1-.32,0h-.47v1h.63l.31,0a.85.85,0,0,1,.24.09.43.43,0,0,1,.23.41.49.49,0,0,1-.22.41,1.21,1.21,0,0,1-.67.15,3.21,3.21,0,0,1-.63-.06,2.45,2.45,0,0,1-.5-.16v1a3.29,3.29,0,0,0,1.2.21,2.53,2.53,0,0,0,1.56-.41,1.36,1.36,0,0,0,.53-1.14,1.08,1.08,0,0,0-.22-.71,1.09,1.09,0,0,0-.56-.37v0a1.09,1.09,0,0,0,.51-.46A1.3,1.3,0,0,0,94.52,517Z"/>
			<path d="M87.53,533.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.68-2.42H92l-1.21,3.11v.78h2v.94H94v-.94h.58v-1H94v-.73H92.81v.73h-.18a6.38,6.38,0,0,0-.66,0h0a8,8,0,0,0,.48-1Z"/>
			<path d="M87.53,548.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.07-1.4h1.78v-1H91.59l-.39,2.74a4.29,4.29,0,0,1,.79-.07,2.66,2.66,0,0,1,.53,0,1,1,0,0,1,.35.11.47.47,0,0,1,.18.19.49.49,0,0,1,.06.25.51.51,0,0,1-.07.28.7.7,0,0,1-.21.21.83.83,0,0,1-.29.11,1.27,1.27,0,0,1-.34,0,3.45,3.45,0,0,1-.57,0,3.42,3.42,0,0,1-.54-.18v1.06a3.09,3.09,0,0,0,.51.15,3.58,3.58,0,0,0,.7.06,2.65,2.65,0,0,0,.87-.13,1.89,1.89,0,0,0,.66-.35,1.63,1.63,0,0,0,.46-1.79A1.11,1.11,0,0,0,94,548a1.4,1.4,0,0,0-.55-.32,2.69,2.69,0,0,0-.81-.11h-.14Z"/>
			<path d="M87.53,563.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.36-.66a2.2,2.2,0,0,0-.53.06,2.49,2.49,0,0,0-.39.14v0a1.17,1.17,0,0,1,.4-.81,1.21,1.21,0,0,1,.79-.25,2.93,2.93,0,0,1,.58.06,3,3,0,0,1,.52.14v-1a2.77,2.77,0,0,0-.48-.15,3.71,3.71,0,0,0-.67-.07,2.53,2.53,0,0,0-1.08.22,2,2,0,0,0-.73.59,2.56,2.56,0,0,0-.42.88,4.2,4.2,0,0,0-.14,1.08,3.13,3.13,0,0,0,.15,1,2.09,2.09,0,0,0,.41.72,1.71,1.71,0,0,0,.63.44,2.1,2.1,0,0,0,.76.14,2.24,2.24,0,0,0,.76-.12,1.59,1.59,0,0,0,.58-.34,1.54,1.54,0,0,0,.37-.53,1.93,1.93,0,0,0,.13-.69,1.52,1.52,0,0,0-.12-.62,1.24,1.24,0,0,0-.34-.47,1.44,1.44,0,0,0-.51-.3A2,2,0,0,0,92.89,562.54Zm-.2,2.15a.57.57,0,0,1-.29-.07.64.64,0,0,1-.24-.2,1.06,1.06,0,0,1-.16-.32,1.34,1.34,0,0,1,0-.42,1.79,1.79,0,0,1,.32-.13,1.48,1.48,0,0,1,.35,0,.73.73,0,0,1,.5.16.52.52,0,0,1,.17.41.61.61,0,0,1-.16.46A.6.6,0,0,1,92.69,564.69Z"/>
			<path d="M87.53,578.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.64-1.4-1.82,3.81h1.29l1.91-4v-.82H91.07v1Z"/>
			<path d="M87.53,593.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm5.2-2.57a2.94,2.94,0,0,0-.77.1,1.83,1.83,0,0,0-.58.27,1.3,1.3,0,0,0-.35.43,1.16,1.16,0,0,0-.13.56,1.1,1.1,0,0,0,.2.66,1.42,1.42,0,0,0,.53.43v0a1.41,1.41,0,0,0-.32.17,1.27,1.27,0,0,0-.27.25,1,1,0,0,0-.18.34,1.42,1.42,0,0,0-.06.42,1.32,1.32,0,0,0,.13.6,1.15,1.15,0,0,0,.37.45,1.73,1.73,0,0,0,.61.29,3.41,3.41,0,0,0,1.64,0,1.73,1.73,0,0,0,.61-.29,1.27,1.27,0,0,0,.38-.45,1.46,1.46,0,0,0,.12-.6,1.42,1.42,0,0,0-.06-.42,1,1,0,0,0-.18-.34,1.07,1.07,0,0,0-.27-.25,1.41,1.41,0,0,0-.32-.17v0a1.42,1.42,0,0,0,.53-.43,1.1,1.1,0,0,0,.2-.66,1.28,1.28,0,0,0-.12-.56,1.48,1.48,0,0,0-.36-.43,1.83,1.83,0,0,0-.58-.27A2.94,2.94,0,0,0,92.73,590.63Zm0,4.09a.78.78,0,0,1-.51-.15.48.48,0,0,1-.17-.36.58.58,0,0,1,.2-.45.59.59,0,0,1,1,0,.58.58,0,0,1,.2.45.48.48,0,0,1-.17.36A.78.78,0,0,1,92.73,594.72Zm0-2a.79.79,0,0,1-.44-.26.55.55,0,0,1-.14-.33.48.48,0,0,1,0-.22.47.47,0,0,1,.13-.15.51.51,0,0,1,.18-.09.82.82,0,0,1,.44,0,.51.51,0,0,1,.18.09.47.47,0,0,1,.13.15.48.48,0,0,1,0,.22.53.53,0,0,1-.13.33A.82.82,0,0,1,92.73,592.71Z"/>
			<path d="M87.53,608.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1,.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm4.68,1.49a2.56,2.56,0,0,1-.51-.05,2.11,2.11,0,0,1-.59-.18v1a2.89,2.89,0,0,0,.52.14,3.08,3.08,0,0,0,.7.07,2.58,2.58,0,0,0,1.14-.22,2,2,0,0,0,.75-.59,2.32,2.32,0,0,0,.4-.87,4.44,4.44,0,0,0,.12-1,2.6,2.6,0,0,0-.53-1.75,1.89,1.89,0,0,0-1.49-.61,1.8,1.8,0,0,0-.71.13,1.59,1.59,0,0,0-.56.34,1.61,1.61,0,0,0-.36.53,1.73,1.73,0,0,0-.13.69,1.54,1.54,0,0,0,.13.67,1.15,1.15,0,0,0,.35.46,1.38,1.38,0,0,0,.51.27,1.92,1.92,0,0,0,.59.09,2.94,2.94,0,0,0,.51,0,1.62,1.62,0,0,0,.44-.16l0,0a1.31,1.31,0,0,1-.42.83A1.47,1.47,0,0,1,92.21,609.69Zm.61-1.84a.65.65,0,0,1-.48-.16.59.59,0,0,1-.17-.44.85.85,0,0,1,0-.22.69.69,0,0,1,.1-.19.53.53,0,0,1,.19-.13.53.53,0,0,1,.26-.05.62.62,0,0,1,.55.27,1.36,1.36,0,0,1,.23.71.42.42,0,0,1-.16.09l-.2.07-.19,0Z"/>
			<path d="M336.53,488.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2,1.39v1h3.07v-1h-.88V485.7h-1l-1.27.51v1.05l1.07-.37v2.7Zm3.32-1.41a4.13,4.13,0,0,0,.13,1.11,2.37,2.37,0,0,0,.37.8,1.5,1.5,0,0,0,.57.47,1.63,1.63,0,0,0,.73.16,1.6,1.6,0,0,0,.73-.16,1.5,1.5,0,0,0,.57-.47,2.38,2.38,0,0,0,.38-.8,4.57,4.57,0,0,0,.13-1.11,4.68,4.68,0,0,0-.13-1.13,2.42,2.42,0,0,0-.38-.79,1.5,1.5,0,0,0-.57-.47,1.6,1.6,0,0,0-.73-.16,1.63,1.63,0,0,0-.73.16,1.5,1.5,0,0,0-.57.47,2.41,2.41,0,0,0-.37.79A4.23,4.23,0,0,0,341.85,488.18Zm2.38,0a5.74,5.74,0,0,1,0,.6,2.62,2.62,0,0,1-.09.47,1,1,0,0,1-.18.32.38.38,0,0,1-.28.11.41.41,0,0,1-.28-.11.81.81,0,0,1-.17-.32,1.94,1.94,0,0,1-.1-.47,6.12,6.12,0,0,1,0-1.21,1.94,1.94,0,0,1,.1-.47.81.81,0,0,1,.17-.32.41.41,0,0,1,.28-.11.38.38,0,0,1,.28.11,1,1,0,0,1,.18.32,2.62,2.62,0,0,1,.09.47A5.79,5.79,0,0,1,344.23,488.18Z"/>
			<path d="M336.53,503.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2.05,1.39v1h3.07v-1h-.87V500.7h-1l-1.28.51v1.05l1.08-.37v2.7Zm3.38,0v1H345v-1h-.87V500.7h-1l-1.27.51v1.05l1.07-.37v2.7Z"/>
			<path d="M336.53,518.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm1.88,1.39v1h3.07v-1h-.87V515.7h-1l-1.27.51v1l1.08-.37v2.7Zm4.94-4a3.19,3.19,0,0,0-1.41.35v1a2.91,2.91,0,0,1,1.21-.36c.43,0,.65.17.65.52a1.05,1.05,0,0,1-.12.46,4.15,4.15,0,0,1-.37.57c-.16.2-.36.44-.6.7l-.84.88v.81h3.33v-1h-1.13c-.23,0-.47,0-.72,0v0c.28-.28.53-.52.74-.74a5.54,5.54,0,0,0,.53-.63,2.59,2.59,0,0,0,.31-.59,1.79,1.79,0,0,0,.1-.62,1.31,1.31,0,0,0-.13-.61,1.24,1.24,0,0,0-.35-.44,1.75,1.75,0,0,0-.53-.26A2.29,2.29,0,0,0,343.35,515.63Z"/>
			<path d="M336.53,533.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2.06,1.39v1h3.07v-1h-.88V530.7h-1l-1.28.51v1l1.08-.37v2.7Zm6.5-2.64a1.17,1.17,0,0,0-.45-1,2.12,2.12,0,0,0-1.32-.36,3.81,3.81,0,0,0-1.21.21v1a2.76,2.76,0,0,1,.51-.16,3.29,3.29,0,0,1,.54-.05,1.07,1.07,0,0,1,.51.11.37.37,0,0,1,.2.35.4.4,0,0,1-.07.24.52.52,0,0,1-.17.16.81.81,0,0,1-.25.09l-.3,0h-.46v1h.61l.29,0a.85.85,0,0,1,.24.09.39.39,0,0,1,.15.16.44.44,0,0,1,.06.25.48.48,0,0,1-.21.41,1.1,1.1,0,0,1-.64.15,2.91,2.91,0,0,1-.6-.06,2.24,2.24,0,0,1-.48-.16v1a3,3,0,0,0,1.14.21,2.39,2.39,0,0,0,1.51-.41,1.4,1.4,0,0,0,.51-1.14,1.13,1.13,0,0,0-.21-.71,1.06,1.06,0,0,0-.55-.37v0a1.07,1.07,0,0,0,.5-.46A1.4,1.4,0,0,0,345.09,532Z"/>
			<path d="M336.53,548.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2,1.39v1h3.07v-1h-.88V545.7h-1l-1.27.51v1l1.07-.37v2.7Zm5.74-3.81h-1.19l-1.16,3.11v.78h2v.94H345v-.94h.56v-1H345v-.73h-1.14v.73h-.17a6,6,0,0,0-.64,0h0a10.77,10.77,0,0,0,.46-1Z"/>
			<path d="M336.53,563.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2,1.39v1h3.07v-1h-.88V560.7h-1l-1.27.51v1l1.07-.37v2.7Zm4.82-2.79h1.71v-1h-2.68l-.37,2.74a4,4,0,0,1,.76-.07,2.51,2.51,0,0,1,.51,0,1,1,0,0,1,.33.11.39.39,0,0,1,.17.19.49.49,0,0,1,.06.25.51.51,0,0,1-.07.28.67.67,0,0,1-.2.21.78.78,0,0,1-.28.11,1.12,1.12,0,0,1-.32,0,3.21,3.21,0,0,1-.55,0,2.93,2.93,0,0,1-.52-.18v1.06a2.71,2.71,0,0,0,.5.15,3.1,3.1,0,0,0,.66.06,2.47,2.47,0,0,0,.84-.13,1.93,1.93,0,0,0,.63-.35,1.52,1.52,0,0,0,.4-.54,1.69,1.69,0,0,0,.14-.68,1.79,1.79,0,0,0-.09-.57,1.31,1.31,0,0,0-.3-.46,1.39,1.39,0,0,0-.53-.32,2.46,2.46,0,0,0-.77-.11h-.14Z"/>
			<path d="M336.53,578.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm1.94,1.39v1h3.07v-1h-.88V575.7h-1l-1.27.51v1l1.07-.37v2.7Zm5.28-2.05a2,2,0,0,0-.52.06,1.92,1.92,0,0,0-.37.14v0a1.24,1.24,0,0,1,.38-.81,1.14,1.14,0,0,1,.76-.25,2.74,2.74,0,0,1,.56.06,3,3,0,0,1,.5.14v-1a2.37,2.37,0,0,0-.47-.15,3.29,3.29,0,0,0-.63-.07,2.35,2.35,0,0,0-1,.22,1.83,1.83,0,0,0-.7.59,2.55,2.55,0,0,0-.41.88,4.21,4.21,0,0,0-.13,1.08,3.13,3.13,0,0,0,.15,1,1.91,1.91,0,0,0,.39.72,1.61,1.61,0,0,0,.6.44,2,2,0,0,0,.74.14,2,2,0,0,0,.72-.12,1.59,1.59,0,0,0,.56-.34,1.49,1.49,0,0,0,.35-.53,1.92,1.92,0,0,0,.12-.69,1.52,1.52,0,0,0-.11-.62,1.31,1.31,0,0,0-.32-.47,1.44,1.44,0,0,0-.49-.3A1.81,1.81,0,0,0,343.75,577.54Zm-.2,2.15a.55.55,0,0,1-.28-.07.62.62,0,0,1-.23-.2,1.06,1.06,0,0,1-.15-.32,1.73,1.73,0,0,1-.05-.42,1.69,1.69,0,0,1,.31-.13,1.33,1.33,0,0,1,.34,0,.69.69,0,0,1,.48.16.58.58,0,0,1,.16.41.61.61,0,0,1-.16.46A.54.54,0,0,1,343.55,579.69Z"/>
			<path d="M336.53,593.2a5.22,5.22,0,0,1,.18-1.38,5.53,5.53,0,0,1,.53-1.25,5.08,5.08,0,0,1,.81-1.05,5.41,5.41,0,0,1,2.3-1.34,5.38,5.38,0,0,1,2.76,0,5.53,5.53,0,0,1,1.25.53,5.23,5.23,0,0,1,2.39,3.11,5.38,5.38,0,0,1,0,2.76,5.41,5.41,0,0,1-1.34,2.3,5.08,5.08,0,0,1-1.05.81,5.53,5.53,0,0,1-1.25.53,5.22,5.22,0,0,1-1.38.18,5.15,5.15,0,0,1-4.49-2.58,5,5,0,0,1-.71-2.62Zm2,1.39v1h3.07v-1h-.88V590.7h-1l-1.27.51v1l1.07-.37v2.7Zm5.16-2.79-1.75,3.81h1.24l1.83-4v-.82h-3.34v1Z"/>
</g>

</svg>

	<ReactTooltip anchorId="tt134" place="right" float>
		<ul style={{ listStyle: 'none', margin: 0, padding: 0, textAlign: 'left' }}>
			<li>1. Nominale premie</li>
			<li>3. Premie aanvullende verzekering</li>
			<li>4. Eigen risico</li>
		</ul>
	</ReactTooltip>
	<ReactTooltip anchorId="tt2" place="right" float>
		<span>2. Inkomensafhankelijke bijdrage en belasting</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt5" place="right" float>
		<span>5. Eigen betalingen en bijdragen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt6" place="right" float>
		<span>6. Zorgtoeslag</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt7" place="right" float>
		<span>7. Rijksbijdrage</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt8" place="right" float>
		<span>8. Risicoverevening Kinderpremie</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt9" place="right" float>
		<span>9. Betaling voor geleverde geneesmiddelen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt10" place="right" float>
		<span>10. Betaling voor geleverde geneesmiddelenzorg</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt11" place="right" float>
		<span>11. Betaling voor geleverde geneesmiddelenzorg</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt12" place="right" float>
		<span>12. Verrekening lagere prijs</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt13" place="right" float>
		<span>13. Inkoop geneesmiddelen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt14" place="right" float>
		<span>14. Inkoop geneesmiddelen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt15" place="right" float>
		<span>15. Inkoop geneesmiddelen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt16" place="right" float>
		<span>16. Inkoop geneesmiddelen</span>
	</ReactTooltip>
	<ReactTooltip anchorId="tt17" place="right" float>
		<span>17. Inkoop geneesmiddelen</span>
	</ReactTooltip>
</div>
);

export default GeldstromenZvw;


