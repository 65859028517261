import React from 'react';
import BarChart from '../../../../../components/BarChart/BarChart';

const AgeHealth = () => {
	const formatValueTooltip = val => `: ${val} years`;
	const formatValueY = val => val;

	const dataMen = [
      { key: 'High level of formal education', tooltip: 'In perceived good health', value: 82.9 },
        { key: 'Intermediate level of formal education', tooltip: 'In perceived good health', value: 80.4 },
        { key: 'Low level of formal education', tooltip: 'In perceived good health', value: 76.6 },
	];

	const partsMen = [
		{ key: 'High level of formal education', tooltip: 'At birth', value: 71 },
        { key: 'Intermediate level of formal education', tooltip: 'At birth', value: 65.3 },
        { key: 'Low level of formal education', tooltip: 'At birth', value:  57 },
	];

  const dataWomen = [
      { key: 'High level of formal education', tooltip: 'In perceived good health', value: 85.3 },
      { key: 'Intermediate level of formal education', tooltip: 'In perceived good health', value: 84.3 },
      { key: 'Low level of formal education', tooltip: 'In perceived good health', value: 81.4 },
  ];

  const partsWomen = [
      { key: 'High level of formal education', tooltip: 'At birth', value: 70.6 },
      { key: 'Intermediate level of formal education', tooltip: 'At birth', value: 64.3 },
      { key: 'Low level of formal education', tooltip: 'At birth', value:  56.5 },
  ];

	return (
		<div className="vis-age-health">
      <h4>Men</h4>

      <BarChart
        data={dataMen}
        margin={{ top: 10, right: 0, bottom: 10, left: 280 }}
        barHeight={30}
        domain={[0,85.3]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        parts={partsMen}
      />

      <h4>Women</h4>

      <BarChart
        data={dataWomen}
        margin={{ top: 10, right: 0, bottom: 10, left: 280 }}
        barHeight={30}
        domain={[0,85.3]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
        parts={partsWomen}
      />

      <div className="vis-credit">
        <p>Life expectancy in years at birth and life expectancy in perceived good health, by formal education level and gender, 2018.        </p>
        <p>Source: De staat van volksgezondheid en zorg (Web page). Levensverwachting in goed ervaren gezondheid.</p>
      </div>
	</div>
	)
}

export default AgeHealth;
