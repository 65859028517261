import React from 'react';
import { Link } from 'react-router-dom';

const Hoofdrolspelers = () => (
	<div className="vis-hoofdrolspelers svg-container">
<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 660 600"
		preserveAspectRatio="xMidYMin meet">

		<g className="geldstroom">
			<path d="M348.21,527.74h-267A17.25,17.25,0,0,1,64,510.53V457.19c0-9.46,3.18-24.27,7.08-32.89L212.92,110.11c3.9-8.63,14.83-15.69,24.29-15.69H424.79c9.46,0,20.39,7.06,24.29,15.69L590.91,424.3c3.9,8.62,7.08,23.43,7.08,32.89v53.34a17.25,17.25,0,0,1-17.21,17.21Z"/>
			<path d="M341.17,313.58v149a18.05,18.05,0,0,0,18,18H594"/>
			<path d="M119,480.58H304.17a18.05,18.05,0,0,0,18-18v-149"/>
			<line x1="331" y1="95.76" x2="331" y2="311.75"/>
		</g>


		<Link to={{ pathname: '/publieke-gezondheidszorg/wat-doen-hoofdrolspelers', hash: '#Rijk' }} >
			<g className="kader">
				<rect x="271" y="70.26" width="120" height="45" rx="9" fill="none" stroke="#1d1d1b" strokeWidth="2"/>
				<text className="tekst-centreer" x="320" y="97.75" >Rijk</text>
			</g>
		</Link>

		<Link to={{ pathname: '/publieke-gezondheidszorg/wat-doen-hoofdrolspelers', hash: '#RIVM' }} >
			<g className="kader">
				<rect x="271" y="288.42" width="120" height="46.67" rx="9" fill="none" stroke="#1d1d1b" strokeWidth="2"/>
				<text className="tekst-centreer" x="313" y="320" >RIVM</text>
			</g>
		</Link>

		<Link to={{ pathname: '/publieke-gezondheidszorg/wat-doen-hoofdrolspelers', hash: '#Gemeente' }} >
			<g className="kader">
				<rect x="3.01" y="456.96" width="120" height="45" rx="9" fill="none" stroke="#1d1d1b" strokeWidth="2"/>
				<text className="tekst-centreer" x="30" y="485.85" >Gemeente</text>
			</g>
		</Link>

		<Link to={{ pathname: '/publieke-gezondheidszorg/wat-doen-hoofdrolspelers', hash: '#GGD' }} >
			<g className="kader">
				<rect x="538.99" y="456.96" width="120" height="45" rx="9" fill="none" stroke="#1d1d1b" strokeWidth="2"/>
				<text className="tekst-centreer" x="581.34" y="485.85" >GGD</text>
			</g>
		</Link>
		</svg>
	</div>
);

export default Hoofdrolspelers;
