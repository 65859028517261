import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';
import { formatLocale } from 'd3';

const UitgavenCuratiefLangdurig = () => {
  const localeFormatter = formatLocale({
    "decimal": ",",
    "thousands": ".",
  });

  const formatValueTooltip = val => {
      if (val < 1) {
          return localeFormatter.format(',.1f')(val * 1000) + ' miljoen euro';
      } else {
          return localeFormatter.format(',.1f')(val) + ' miljard euro';
      }
  }
  const formatValueY = val => localeFormatter.format(',.1f')(val)
  
  const createTable = (data, className) => (
    <table className="tabel">
      <tbody>
        {data.map((row) => <tr key={row.key}><td>{row.key}</td><td>{formatValueY(row.value)}</td></tr>)}
      </tbody>
    </table>
  );

  const data = [
      { key: 'Specialistische ggz', value: 3.7 },
      { key: 'Wet langdurige zorg', value: 0.498 },
      { key: 'Generalistische basis-ggz', value: 0.226 },
      { key: 'POH-GGZ', value: 0.186 },
  ];

  return (
    <div className="vis-uitgaven-curatief-langdurig">
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, bottom: 10, left: 210 }}
        barHeight={30}
        domain={[0,4]}
        color={ {fill: '#333', stroke: '#fff'} }
        formatValueTooltip={formatValueTooltip}
        formatValueY={formatValueY}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-total">
        <p>Totaal</p>
        <p className="groot">4,7</p>
        <p>miljard euro in 2019</p>
      </div>

      <div className="vis-credit">
        <p>Uitgaven aan curatieve en langdurige geestelijke gezondheidszorg in 2019.</p>
        <p>Bron: Vektis</p>
      </div>
    </div>
  )
}

export default UitgavenCuratiefLangdurig;
