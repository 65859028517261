import React from 'react';
import ColumnChart from '../../../components/ColumnChart/ColumnChart';

const HoeveelWerknemers = () => {
  const margin = {top: 90, right: 0, bottom: 90, left: 0};
  const formatY = value => value;
  const formatValueToolTip = d => (<span>{d.category}<br/>{d.data.value} duizend</span>);

  const data = [
      {
          "year": "2017",
          "Verpleging en verzorging": 245,
          "Thuiszorg": 146,
      },
      {
          "year": "2018",
          "Verpleging en verzorging": 257,
          "Thuiszorg": 141,
      },
      {
          "year": "2019",
          "Verpleging en verzorging": 272,
          "Thuiszorg": 144,
      },
      {
          "year": "2020",
          "Verpleging en verzorging": 303,
          "Thuiszorg": 130,
      },

  ];

  return (
    <div className="vis-hoeveel-werknemers">

    <ColumnChart
      margin={margin}
      colorBars={["#333","#ddd"]}
      colorAxis="#333"
      labelY="x 1000"
      height={450}
      domain={[0, 400]}
      columnPadding={0.5}
      formatY={formatY}
      formatValueToolTip={formatValueToolTip}
      data={data}
      stacked={true}
    />

    <div className="vis-credit">
      <p>Aantal zorgprofessionals, werkzaam in de ouderenzorg (x 1.000).</p>
      <p>Het cijfer voor 2020 is voorlopig.</p>
      <p>Bron: AZWinfo.</p>
    </div>

  </div>
  );
}
export default HoeveelWerknemers;
