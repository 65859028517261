import React from 'react';
import BarChart from '../../../components/BarChart/BarChart';

const Verzuim = () => {
  const formatValueTooltip = val => `${val} werkdagen`

  const createTable = (data) => (
    <table className="tabel">
      <tbody>
      {data.map((row) => <tr key={row.key}>
        <td>{row.key}</td>
        <td>{formatValueTooltip(row.value)}</td>
      </tr>)}
      </tbody>
    </table>
  );

  const data = [
    {key: 'Psychische klachten, overspannenheid, burn-out', value: 59},
    {key: 'Klachten hart- en vaatstelsel', value: 47},
    {key: 'Klachten heup, benen, knieën, voeten', value: 27},
    {key: 'Klachten nek, schouders, armen, polsen, handen', value: 26},
    {key: 'Klachten in verband met zwangerschap', value: 22},
    {key: 'Rugklachten', value: 17},
    {key: 'Vermoeidheid of concentratieproblemen', value: 17},
    {key: 'Klachten luchtwegen', value: 13},
    {key: 'Klachten oren of ogen', value: 12},
    {key: 'Klachten buik, maag of darmen', value: 6},
    {key: 'Huidklachten', value: 6},
    {key: 'Hoofdpijn', value: 4},
    {key: 'Griep of verkoudheid', value: 3},
  ];

  return (
    <div className="vis-verzuim">
      <BarChart
        data={data}
        margin={{top: 10, right: 0, bottom: 10, left: 330}}
        barHeight={30}
        domain={[0, 59]}
        color={{fill: '#333', stroke: '#fff'}}
        formatValueTooltip={formatValueTooltip}
        formatValueY={(a) => a}
        padding={0.2}
        ticks={0}
      />

      {createTable(data)}

      <div className="vis-credit">
        <p>Aantal werkdagen naar klacht waarop in 2018 de laatste keer werd verzuimd.</p>
        <p>Bron: CBS/TNO, 2020</p>
      </div>

    </div>
  )
}

export default Verzuim;
