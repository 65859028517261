import { combineReducers } from 'redux';

import pages from './pages';
import menuopen from './menu';
import searchstring from './searchstring';
import access from './access';
import organisation from './organisation';

const rootReducer = combineReducers({
	pages,
	menuopen,
	searchstring,
	access,
	organisation
});

export default rootReducer;
