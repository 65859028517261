import * as harmonicas from './harmonicas';
import vissen from './visualizations';
import {COLOR_GREEN, COLOR_YELLOW} from "../../../../vocabulary";

const pages = [
  {
    template: "intro",
    naam: "gids-quick-guide",
    titel: "Quick Guide to Dutch Healthcare",
    kleur: COLOR_YELLOW,
    tekst:
      "How is the Dutch healthcare system organized? What makes the Dutch healthcare system unique? Here you find answers to these questions.",
    links:
      [
        'overview',
        'figures',
        'outcome'
      ]
  },
  {
    template: "intro",
    naam: "overview",
    titel: "Overview of the Dutch healthcare system",
    kleur: COLOR_GREEN,
    tekst:
      "The topics below give an overview of the basics of Dutch Healthcare, from laws and players, to the rationale en principles.",
    links:
      [
        'rationale',
        'laws',
        'principles',
        'key-players',
      ]
  },
  {
    template: "visualisatie",
    naam: "rationale",
    titel: "What is the rationale behind the Dutch healthcare system?",
    kleur: COLOR_GREEN,
    links: [
      'laws'
    ]
  },
  {
    template: "harmonica",
    naam: "laws",
    titel: "What are the five laws governing Dutch healthcare?",
    kleur: COLOR_GREEN,
  },
  {
    template: "harmonica",
    naam: "principles",
    titel: "What are the principles of Dutch healthcare?",
    kleur: COLOR_GREEN,
  },
  {
    template: "visualisatie",
    naam: "key-players",
    titel: "Who are the key players in the Dutch healthcare sector?",
    kleur: COLOR_GREEN,
    links: [
      'principles'
    ]
  },
  {
    template: "intro",
    naam: "figures",
    titel: "Key figures of the Dutch healthcare system",
    kleur: COLOR_GREEN,
    tekst:
        "A good way to get a quick overview of Dutch Healthcare is by its key figures. You can find these under the buttons below.",
    links:
        [
          'funds',
          'average',
          'size',
          'employment',
        ]
  },
  {
    template: "visualisatie",
    naam: "funds",
    titel: "How do the Dutch spend their healthcare funds?",
    kleur: COLOR_GREEN,
    links: [
      'average'
    ]
  },
  {
    template: "visualisatie",
    naam: "average",
    titel: "How much does the average European spend on healthcare?",
    kleur: COLOR_GREEN,
    links: [
      'size'
    ]
  },
  {
    template: "visualisatie",
    naam: "size",
    titel: "What is the size of the Dutch healthcare system?",
    kleur: COLOR_GREEN,
    links: [
      'employment'
    ]
  },
  {
    template: "visualisatie",
    naam: "employment",
    titel: "Where are the Dutch healthcare workers employed?",
    kleur: COLOR_GREEN,
    links: [
      'funds'
    ]
  },
  {
    template: "intro",
    naam: "outcome",
    titel: "Outcome of the Dutch healthcare system",
    kleur: COLOR_GREEN,
    tekst:
        "What are the results of the Dutch healthcare system? What's its score compared to other countries? And how long do the Dutch live? Read about it below.",
    links:
        [
          'score',
          'age-health',
        ]
  },
  {
    template: "visualisatie",
    naam: "score",
    titel: "How does Dutch healthcare score compared to other countries?",
    kleur: COLOR_GREEN,
    links: [
      'age-health'
    ]
  },
  {
    template: "visualisatie",
    naam: "age-health",
    titel: "How long do the Dutch live and how long do the Dutch feel healthy?",
    kleur: COLOR_GREEN,
    links: [
      'score'
    ]
  },
]

// loop over pages to add harmonicas and visualizations
pages.map(pagina => {
  if (pagina.template === "harmonica") {
    const harmonicaNaam = pagina.naam.replace(/-/g, "_");
    pagina.harmonica = harmonicas[harmonicaNaam];
  }
  if (pagina.template === "visualisatie") {
    const visualisatieNaam = pagina.naam.replace(/-/g, "_");
    pagina.vis = vissen[visualisatieNaam];
  }
  return pagina;
});

export default pages;
